import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import paramBuilder from "@/utils/network/paramBuilder";
import useCurrentUser from "@/hooks/useCurrentUser";
import useTrackVisit from "@/hooks/useTrackVisit";
import { pluralize } from "@/utils/string";
import CenterCard from "./CenterCard";
import UsersList from "@/components/shared/UsersList";
import Filters from "./Filters";
import NoResults from "@/components/shared/NoResults";
import LoadingMembers from "./LoadingMembers";

const fetchUsers = ({ pageParam = 0, queryKey }) => {
  const baseUrl = `/api/users`;
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const filters = queryKey[1];
  const params = paramBuilder({
    include: ["focusAreas"],
    fields: {
      users: [
        "firstName",
        "lastName",
        "createdAt",
        "updatedAt",
        "fullName",
        "role",
        "digestedJobTitle",
        "title",
        "centerId",
        "centerNameOrInstitution",
        "email",
        "focusAreas",
        "slug",
        "centerSlug",
      ],
      focusAreas: ["name"],
    },
    filter: {
      suspended: [false],
      archived: [false],
    },
  });
  const url = `${baseUrl}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

const fetchCenters = () => {
  const baseUrl = `/api/centers`;
  const params = paramBuilder({
    fields: {
      centers: ["shorthand", "location", "activeMembersCount", "slug"],
    },
  });
  const url = `${baseUrl}?${params}`;
  return fetcher(url);
};

function MembersPage() {
  useTrackVisit("directory");
  const { search } = useLocation();
  const currentUser = useCurrentUser();
  const [filters, setFilters] = useState(search.slice(1));
  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["users", filters], fetchUsers, {
    getNextPageParam: apiCanFetch,
  });
  const { data: members, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("section-router");

  const { isLoading: isLoadingCenters, data: centers } = useQuery(
    ["centers"],
    fetchCenters,
  );

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const recordCount = isFetching ? "" : meta.recordCount || 0;

  return (
    <section className="margin-safe xl:flex xl:flex-col pb-4 sm:pb-8 xl:pb-12">
      <header className="md:flex md:items-center md:justify-between pt-5 pb-7.5 md:py-10 border-b border-lines">
        <div className="flex items-center">
          <h1 className="font-h2 xl:font-h1 font-bold text-dark-100">
            Members
          </h1>
        </div>
        {currentUser?.attributes.isStaff && (
          <a
            href="/admin/people?mode=new"
            className="btn-primary btn-sm mt-4 md:mt-0"
          >
            + Add a New Member
          </a>
        )}
      </header>
      <div className="flex pt-7.5 lg:pt-10 w-full flex-col lg:flex-row-reverse sm:mb-0">
        <section className="lg:ml-3.5 flex flex-row lg:flex-col overflow-x-auto lg:overflow-x-visible">
          {!isLoadingCenters ? (
            centers.data.map(center => (
              <CenterCard key={center.id} center={center} />
            ))
          ) : (
            <>
              {[1, 2, 3, 4, 5].map(x => (
                <div
                  key={x}
                  className="animate-pulse card-center bg-dark-25 mr-5 lg:mb-7 lg:mr-0 rounded"
                ></div>
              ))}
            </>
          )}
        </section>
        <section className="w-full mt-7 lg:mt-0">
          <section>
            <Filters setFilters={setFilters} />
            <div className="font-h3 text-byte-dark">
              {isFetching
                ? "Retrieving Items..."
                : `Displaying ${recordCount} ${pluralize(
                    recordCount,
                    "Member",
                  )}`}
            </div>
          </section>
          <section className="lg:pr-3.5 pt-5">
            {members.length > 0 && <UsersList members={members} />}
            {!isFetching && members.length === 0 && (
              <NoResults entityName="members" icon="ri-user-3-line" />
            )}
            {(isFetching || isFetchingNextPage) && <LoadingMembers />}
          </section>
        </section>
      </div>
    </section>
  );
}

export default MembersPage;
