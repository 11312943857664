import React, { MouseEventHandler } from "react";
import clsx from "clsx";
import ButtonIcon from "@/components/shared/ButtonIcon";

const SpeakerItemRow = ({
  className = "",
  user,
  displayDelete = false,
  onDeleteClick = undefined,
}: {
  className?: string;
  user: any;
  displayDelete?: boolean;
  onDeleteClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}) => (
  <div className={clsx("group flex items-center", className)}>
    <div className="flex">
      <img src={user.avatarUrl} className="h-10 w-10 rounded-full" />
      <div className="ml-3">
        <p className="font-meta-bold text-dark-100">{user.fullName}</p>
        <p className="font-micro text-dark-75 line-clamp-1" title={user.title}>
          {user.title}
        </p>
      </div>
    </div>
    {displayDelete && (
      <ButtonIcon
        className="hidden group-hover:!block"
        iconClassName="ri-delete-bin-line"
        onClick={onDeleteClick}
      />
    )}
  </div>
);

export default SpeakerItemRow;
