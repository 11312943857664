import React from "react";
import clsx from "clsx";

type ToggleButtonProps = {
  active?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  dataCy?: string;
};

function ToggleButton({
  active = false,
  disabled = false,
  onClick,
  dataCy = "",
}: ToggleButtonProps) {
  return (
    <div className="flex justify-center">
      <button
        data-cy={dataCy}
        className={clsx(
          "rounded-3xl border-0 h-7 w-16 flex",
          "items-center justify-around font-bold text-xs",
          {
            "bg-primary border-primary text-white fill-current": active,
            "bg-tint border-1 border-dark-25": !active,
            "opacity-50 cursor-default": disabled,
          },
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <i className="ri-check-line"></i>
        {active ? "ON" : "OFF"}
      </button>
    </div>
  );
}

export default ToggleButton;
