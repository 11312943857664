import React, { useState, useEffect } from "react";
import clsx from "clsx";
import FullWideForm from "@/components/shared/FullWideForm";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import { Form, Formik } from "formik-latest";
import BasicDetailForm from "./BasicDetailForm";
import ImageGalleryForm from "./ImageGalleryForm";
import { isEqual } from "lodash";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import { useQueryClient } from "@tanstack/react-query";
import useFlashMessage from "@/hooks/useFlashMessage";
import { RetreatSchema, mapResponse, mapRequest } from "./defaults";
import ToogleButton from "@/components/shared/ToogleButton";
import ArticleCards from "./ArticleCards";
import paramBuilder from "@/utils/network/paramBuilder";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";
import SkeletonForm from "./SkeletonForm";

const RETREATS_VIEW_URL = "/admin/retreats";

const fetchRetreat = async id => {
  const baseUrl = `/api/retreats/${id}`;
  const params = paramBuilder({
    include: ["articles"],
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function RetreatsForm() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openUnsaveModal, setOpenUnsaveModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [articleToDelete, setArticleToDelete] = useState<number>(0);
  const { slug } = useParams();

  const title = slug ? "Edit Retreat" : "New Retreat";

  const [initialData, setInitialData] = useState<any>({});

  const handleBackButtonPressed = () => {
    navigate(RETREATS_VIEW_URL);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const retreatRequest = mapRequest(values);
    saveResource("retreats", retreatRequest)
      .then(res => showFlash({ success: res.ok }))
      .catch(_ => {
        showFlash({ success: false });
        setSubmitting(false);
      })
      .finally(() => {
        queryClient.invalidateQueries(["admin_retreats"]);
        handleBackButtonPressed();
      });
  };

  const showFlash = useFlashMessage({
    message: `Your Retreat was ${slug ? "updated" : "added"}!`,
    errorMessage: `Your Retreat could not be ${
      slug ? "updated" : "added"
    } due to an error.`,
  });

  const showDeleteArticleFlash = useFlashMessage({
    message: "Article was deleted.",
    errorMessage: "There was an error deleteing an Article",
  });

  useEffect(() => {
    if (slug) {
      fetchRetreat(slug).then((res: any) => {
        const retreatData = mapResponse(res);
        setInitialData(retreatData);
        setIsLoading(false);
      });
    } else {
      setInitialData({
        quarter: "",
        galleryImages: [],
      });
      setIsLoading(false);
    }
  }, [slug]);

  const onLeaveForm = hasNoChanges =>
    hasNoChanges ? handleBackButtonPressed() : setOpenUnsaveModal(true);

  const onNewArticleClick = () => {
    if (slug) {
      navigate(`/admin/retreats/${slug}/articles/new`);
    }
  };

  const onAskDeleteArticleClick = id => {
    setArticleToDelete(id);
    setOpenDeleteModal(true);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonForm />
      ) : (
        <Formik
          initialValues={initialData}
          onSubmit={handleSubmit}
          validationSchema={RetreatSchema}
        >
          {({ values, isValid, isSubmitting, setFieldValue }) => {
            const hasNoChanges = isEqual(values, initialData);
            const submitDisabled = !isValid || isSubmitting || hasNoChanges;

            const handleTooglePublish = () => {
              setFieldValue("published", !values.published);
            };

            const handleDeleteArticle = () => {
              fetch(`/api/articles/${articleToDelete}`, { method: "DELETE" })
                .then(resp => {
                  setFieldValue(
                    "articles",
                    values.articles.filter(
                      article => article.id !== articleToDelete,
                    ),
                  );
                  showDeleteArticleFlash({ success: resp.ok });
                })
                .catch(_ => showDeleteArticleFlash({ success: false }))
                .finally(() => setOpenDeleteModal(false));
            };

            return (
              <FullWideForm>
                <FullWideForm.header>
                  <button
                    type="button"
                    className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
                    onClick={() => onLeaveForm(hasNoChanges)}
                  >
                    <i className="ri-arrow-left-s-line ri-lg text-dark-75 mr-1"></i>
                    <span className="text-14 font-lato">All Retreats</span>
                  </button>

                  <div className="admin-intro ellipsis mx-auto w-full flex max-w-[964px] py-4">
                    <h1 className="text-22 font-bold flex-1">{title}</h1>
                    {slug && (
                      <div className="flex">
                        <label className="flex font-h3 mr-2 text-dark-100 font-body-bold">
                          Publish
                        </label>
                        <ToogleButton
                          active={values.published}
                          onClick={handleTooglePublish}
                        />
                      </div>
                    )}
                  </div>
                </FullWideForm.header>
                <Form>
                  <FullWideForm.body>
                    <BasicDetailForm values={values} onChange={setFieldValue} />
                    <ImageGalleryForm
                      values={values}
                      onChange={setFieldValue}
                    />
                    {slug && (
                      <ArticleCards
                        values={values}
                        onNewArticleClick={onNewArticleClick}
                        onDeleteArticleClick={onAskDeleteArticleClick}
                      />
                    )}
                  </FullWideForm.body>
                  <FullWideForm.footer>
                    <div className="mx-auto w-full max-w-[964px]">
                      <div className="ml-72">
                        <button
                          type="submit"
                          className={clsx(
                            "btn-white btn-md cursor-pointer mr-2",
                            {
                              "bg-primary text-white hover:border-primary-darker hover:bg-primary-darker active:bg-primary-darkest": isValid,
                              "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none": submitDisabled,
                            },
                          )}
                          disabled={submitDisabled}
                        >
                          {slug ? "Save Changes" : "Add Retreat"}
                        </button>
                        <button
                          type="button"
                          className="btn-md bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => onLeaveForm(hasNoChanges)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </FullWideForm.footer>
                </Form>
                <DeleteConfirmationModal
                  open={openDeleteModal}
                  setOpen={setOpenDeleteModal}
                  onAccept={handleDeleteArticle}
                  resource="article"
                  message="Members will no longer be able to use or edit this article once it's deleted."
                  className="w-105"
                />
              </FullWideForm>
            );
          }}
        </Formik>
      )}

      <ConfirmationModal
        isOpen={openUnsaveModal}
        onAccept={() => handleBackButtonPressed()}
        onClose={() => setOpenUnsaveModal(false)}
        setOpen={() => setOpenUnsaveModal(false)}
      />
    </>
  );
}

export default RetreatsForm;
