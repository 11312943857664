import * as React from "react";
import clsx from "clsx";

function Progress({
  steps = [],
  currentStep,
}: {
  steps: any[];
  currentStep: Number;
}) {
  return (
    <div className="flex">
      {steps.map(step => {
        return (
          <div key={step} className="flex items-center justify-center">
            <div
              className={clsx(
                "w-8 h-8 border-2 rounded-2xl font-bold flex items-center justify-center",
                {
                  "border-primary": step <= currentStep,
                  "bg-primary": step < currentStep,
                  "border-dark-25": step > currentStep,
                },
              )}
            >
              {step < currentStep ? (
                <i className="ri-check-line ri-lg text-white" />
              ) : (
                <>
                  {step === currentStep && (
                    <div className="h-2 w-2 rounded-full bg-primary" />
                  )}
                </>
              )}
            </div>
            {step < steps.length && (
              <div
                className={clsx("w-20 h-0.5", {
                  "bg-dark-25": step >= currentStep,
                  "bg-primary": step < currentStep,
                })}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Progress;
