export enum KeySection {
  LargeHeader = "LargeHeader",
  SmallHeader = "SmallHeader",
  ReferenceText = "ReferenceText",
  ImageFullWidth = "ImageFullWidth",
  ImageTwoColumns = "ImageTwoColumns",
  BasicTable = "BasicTable",
}

export enum SectionMode {
  View,
  Edit,
  Preview,
}
