import * as React from "react";

type ResultListProps = {
  list: any[];
  onDelete: (item: any, index: number) => void;
  attributeToShow: string;
  secondaryAttributeToShow?: string;
};

function ResultList({
  list,
  onDelete,
  attributeToShow = "content",
  secondaryAttributeToShow,
}: ResultListProps) {
  return (
    <div className="mt-2">
      {list.map((item, index) => {
        return (
          <div
            className="flex items-center bg-blue-2 px-3 py-2 mb-1"
            key={index}
          >
            <div className="flex-1 font-meta mr-4">
              <span>{item[attributeToShow]}</span>
              {secondaryAttributeToShow && (
                <span className="italic ml-3 text-dark-50">
                  {item[secondaryAttributeToShow]}
                </span>
              )}
            </div>

            <button
              type="button"
              onClick={() => onDelete(item, index)}
              className="ml-auto"
            >
              <i className="ri-delete-bin-line font-body text-dark-50" />
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default ResultList;
