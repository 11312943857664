import * as React from "react";
import { useState, useLayoutEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import Tabs from "@/components/shared/Tabs";
import { FilterOption } from "@/types";
import Aside from "./Aside";
import OverviewTab from "./OverviewTab";
import ClinicalProgressTab from "./ClinicalProgressTab";
import MembersTab from "./MembersTab";
import UpdatesTab from "./UpdatesTab";
import RelatedProjectsTab from "./RelatedProjectsTab";
import NotFoundPage from "@/components/App/NotFoundPage";
import Breadcrumb from "@/components/shared/Breadcrumb";

const defaultTabs = [{ id: "overview", name: "Overview" }];

const fetchProject = ({ queryKey }) => {
  const slug = queryKey[1];
  if (slug) {
    const baseUrl = `/api/projects/${slug}`;
    const params = paramBuilder({
      include: [
        "externalUserMembers",
        "focusAreas",
        "members",
        "projectManagers",
        "relatedProjects",
      ],
      fields: {
        projects: [
          "agreementUploadMetadata",
          "agreementUploadUrl",
          "clinicalSitesCount",
          "codename",
          "description",
          "externalUserMembers",
          "featured",
          "focusAreas",
          "identifier",
          "lastPeriodicUpdate",
          "members",
          "name",
          "projectManagers",
          "readableType",
          "subtitle",
          "trialStatus",
          "type",
          "updatedAt",
          "relatedProjects",
          "relatedFocusAreas",
          "salesforceId",
        ],
        focusAreas: ["name"],
        users: ["centerNameOrInstitution", "fullName", "title"],
        externalUsers: ["center", "name"],
      },
    });
    const url = `${baseUrl}?${params}`;
    return denormalizedFetcher(url);
  }
};

const TabSection = props => {
  switch (props.tab) {
    case "overview":
      return <OverviewTab project={props.project} />;
    case "clinical_progress":
      return <ClinicalProgressTab project={props.project} />;
    case "updates":
      return <UpdatesTab project={props.project} />;
    case "members":
      return <MembersTab project={props.project} />;
    case "relatedProjects":
      return <RelatedProjectsTab project={props.project} />;
    default:
      return null;
  }
};

function ProjectDetail() {
  const [searchParams] = useSearchParams();
  const currentTabName = searchParams.get("tab");
  const { slug } = useParams();
  const { data }: { data: any } = useQuery(["project", slug], fetchProject);
  const project = data?.data;

  const [tabsLoading, setTabsLoading] = useState(true);
  const [tabs, setTabs] = useState<FilterOption[]>(defaultTabs);
  const [selectedTab, setSelectedTab] = useState<FilterOption>(tabs[0]);

  const currentTab = tabs.find(tab => tab.id === currentTabName);
  if (currentTab && currentTab?.id !== selectedTab?.id) {
    setSelectedTab(currentTab);
  }

  useLayoutEffect(() => {
    if (project) {
      const members = project.relationships.members.data;
      const relatedProjects = project.relationships.relatedProjects.data;
      const externalMembers = project.relationships.externalUserMembers.data;
      const allMembers = [...members, ...externalMembers];
      const newTabs: FilterOption[] = [];

      if (allMembers.length > 0) {
        newTabs.push({ id: "members", name: "Team Members" });
      }
      if (relatedProjects.length > 0) {
        newTabs.push({ id: "relatedProjects", name: "Related Projects" });
      }

      setTabs([...defaultTabs, ...newTabs]);
      setTabsLoading(false);
    }
  }, [project]);

  if (data?.errors) return <NotFoundPage />;

  return (
    <div className="margin-safe">
      <Breadcrumb to="/projects" text="Back to Projects" />
      {project && (
        <div className="mb-10 lg:flex">
          <div className="lg:flex-grow overflow-auto px-1 -mx-1 py-1 -my-1">
            <div className="mt-7.5 sm:mt-4.5">
              {!tabsLoading && (
                <Tabs {...{ tabs, selectedTab, setSelectedTab }} />
              )}
            </div>
            <section className="py-7.5 sm:py-10">
              {selectedTab && project && (
                <TabSection tab={selectedTab.id} project={project} />
              )}
            </section>
          </div>
          <Aside project={project} />
        </div>
      )}
    </div>
  );
}

export default ProjectDetail;
