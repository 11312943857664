import * as React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Table from "@/components/shared/Table";
import Tooltip from "@/components/shared/Tooltip";
import ToogleButton from "@/components/shared/ToogleButton";

function PublicButton({ value, refetch }) {
  function handleFeatured() {
    fetch(`/api/news-feeds/${value.id}/toggle`, {
      method: "GET",
    }).then(refetch);
  }
  return (
    <ToogleButton active={value.attributes.isPublic} onClick={handleFeatured} />
  );
}
const NewsFeedItem = ({ newsItem, refetch }) => {
  console.log("newsItem", newsItem);

  const publishedAt = newsItem?.attributes.publishedAt;
  const createdAt = format(
    new Date(newsItem?.attributes.createdAt),
    "MMMM dd, yyyy",
  );

  const isScheduled =
    !newsItem?.attributes.publishedAt && newsItem?.attributes.sendNewsFeedDate;
  return (
    <Table.Row className="divide-x divide-lines">
      <Table.Cell className="pl-3.5 pr-3.5 pb-3.5 pt-3.5">
        <div className="flex flex-col justify-center w-36 sm:w-32 xl:w-auto">
          {publishedAt ? (
            <Link
              to={newsItem.links.slug}
              className="link text-dark-100 font-body-bold"
            >
              Daily News Feed for {createdAt}
            </Link>
          ) : (
            <div className="text-dark-75 font-bold">
              Daily News Feed for{" "}
              <span className="text-orange">[{createdAt}]</span>
            </div>
          )}
        </div>
      </Table.Cell>
      <Table.Cell className="pl-3.5 pr-3.5 pb-3.5 pt-3.5">
        <div className="flex flex-col justify-center w-36 sm:w-32 xl:w-auto">
          <p className="font-body-bold truncate text-dark-100">
            {newsItem.relationships.author.data.attributes.fullName}
          </p>
        </div>
      </Table.Cell>
      <Table.Cell expand={false} className=" pl-3.5 pr-3.5 pb-3.5 pt-3.5">
        {publishedAt ? (
          <div className="text-dark-75 whitespace-nowrap">
            {format(new Date(publishedAt), "MMMM dd, yyyy")}
          </div>
        ) : (
          <div className="flex items-center">
            <div className="text-orange">Draft</div>
            {isScheduled && (
              <span className="ml-2 text-xs"> ( scheduled )</span>
            )}
          </div>
        )}
      </Table.Cell>
      <Table.Cell
        expand={false}
        className="pl-3.5 pr-3.5 pb-3.5 pt-3.5 text-center"
      >
        <PublicButton {...{ value: newsItem, refetch }} />
      </Table.Cell>
      <Table.Cell expand={false} className="pl-3.5 pr-3.5 pb-3.5 pt-3.5">
        <Link className="btn-white btn-sm" to={newsItem.links.edit}>
          Edit
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};
function DailyNewsFeedTable({ newsFeed, refetch }) {
  return (
    <Table className="border border-transparent">
      <Table.Header className="bg-transparent text-dark-50">
        <Table.HeaderCell>TITLE</Table.HeaderCell>
        <Table.HeaderCell>AUTHOR</Table.HeaderCell>
        <Table.HeaderCell>DATE PUBLISHED</Table.HeaderCell>
        <Table.HeaderCell className="pl-3.5 pr-3.5">
          <div className="flex items-center gap-2">
            <span>PUBLIC</span>
            <Tooltip>
              <Tooltip.Trigger>
                <i className="ri-question-mark bg-white rounded-full w-4 h-4"></i>
              </Tooltip.Trigger>
              <Tooltip.Content>
                <span>
                  If this is on, then this content will display on the PICI
                  public site (www.parkerici.org). If it's off, then it will not
                  display publicly.
                </span>
              </Tooltip.Content>
            </Tooltip>
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell>EDIT</Table.HeaderCell>
      </Table.Header>
      <Table.Body className="border border-lines border-l-2">
        {newsFeed.map(newsItem => (
          <NewsFeedItem
            newsItem={newsItem}
            refetch={refetch}
            key={newsItem.id}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

export default DailyNewsFeedTable;
