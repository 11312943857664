import React, { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import ResearchTable from "./ResearchTable";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import ResearchFilter from "./ResearchFilter";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";

const fetchResearch = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const baseUrl = `/api/research-documents`;
  const params = paramBuilder({
    include: ["authors", "readers"],
    fields: {
      "research-documents": [
        "title",
        "status",
        "createdAt",
        "authors",
        "sortedAuthors",
        "readers",
        "sortedReaders",
        "readableType",
        "publication",
        "publicationLink",
      ],
      users: ["fullName", "slug", "projectsCount"],
    },
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `${baseUrl}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

function ResearchPage() {
  const [filters, setFilters] = useState("");
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["admin_research_documents", filters], fetchResearch, {
    getNextPageParam: apiCanFetch,
  });
  const { data: researchDocuments, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("admin-section-navigation");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  return (
    <div className="bg-tint h-screen">
      <AdminHeader
        title="Research"
        description="Manage the library of early-stage and published research available to members."
        className="!h-[106px]"
        linkText="+ New Research"
        linkTo="/research_documents/new"
        linkTarget="_blank"
      >
        <ResearchFilter
          total={meta?.recordCount}
          isLoading={isFetching}
          setFilters={setFilters}
        />
      </AdminHeader>
      <article
        id="article-container"
        className="overflow-x-hidden h-vh-60 px-7.5 bg-primary-lighter"
      >
        {
          <ResearchTable
            researchItems={researchDocuments}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        }
      </article>
    </div>
  );
}

export default ResearchPage;
