import React from "react";
import clsx from "clsx";
import TextAreaAutosize from "react-autosize-textarea";

function TextArea({ error = false, ...props }) {
  return (
    <TextAreaAutosize
      {...props}
      className={clsx(
        "shadow-sm py-2.5 px-3.5 border border-dark-25 text-17 text-dark-75 w-full rounded-md",
        props.className,
        error
          ? "focus:ring-error focus:border-error ring-error border-error"
          : "focus:ring-primary focus:border-primary",
      )}
    />
  );
}

export default TextArea;
