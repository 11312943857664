import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import BasicDetails from "./BasicDetails";
import Appeareance from "./Appeareance";
import ContactPoints from "./ContactPoints";
import { Form, Formik } from "formik-latest";
import { useParams } from "react-router-dom";
import { mapResponseToCenter, mapCenterToRequest } from "./mapCenter";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import clsx from "clsx";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { requestValidation } from "@/utils/requestValidation";
import { saveResource, fetcher } from "@/utils/api";

type typeMode = "new" | "edit";

type CentersFormProps = {
  mode?: typeMode;
};

const fetchTimeZone = async () => {
  const response = await fetcher("/api/centers/time_zones", {
    method: "GET",
  });
  return response;
};

const fetchCenterBySlug = async (slug: string | undefined) => {
  const response = await fetcher(`/api/centers/${slug}`, {
    method: "GET",
  });
  return response;
};

const INITIAL_CENTER_DATA = {
  timeZones: [],
  banner: {},
  background: {},
  attributes: { slug: null, usersList: [] },
  logo: {},
  usersList: [],
};

const NEW_FORM_TITLE = "New Center";

const saveCenter = center =>
  saveResource("centers", {
    data: center,
  });

const CentersForm = ({ mode = "new" }: CentersFormProps) => {
  const { slug } = useParams();
  const [centerData, setCenterData] = useState(INITIAL_CENTER_DATA);
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({ id: null });
  const [contactList, setContactList] = useState([]);
  const [usersSuggestions, setUsersSuggestions] = useState([]);
  const [title, setTitle] = useState(mode === "new" ? NEW_FORM_TITLE : "Edit");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleBackButtonPressed = () => {
    navigate("/admin/centers");
  };
  const handleDeleteButtonPressed = () => {
    setModalOpen(true);
  };
  const handleSubmit = values => {
    const centerRequest = mapCenterToRequest(
      values,
      initialValues.id,
      centerData.usersList,
      contactList,
    );

    saveCenter(centerRequest).then(_ => handleBackButtonPressed());
  };

  const CenterSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    shorthand: Yup.string()
      .required("Short name is required.")
      .max(15, "The text has exceeded the 15 character limit."),
    description: Yup.string().max(
      175,
      "The text has exceeded the 175 character limit.",
    ),
    slug: Yup.string()
      .required("Vanity url is required.")
      .test("valid-title", "This URL is already taken.", function(value) {
        if (mode === "edit" && value === centerData.attributes.slug) {
          return true;
        }
        return requestValidation("centers", "slug", "unique", value);
      }),
  });

  const setEditMode = (data, timeZones) => {
    const center = data.attributes;
    const users = data.attributes.users;
    setTitle(`Edit: ${center.name}`);
    setContactList(
      center.usersList.length === 0 ? [{ role: "" }] : center.usersList,
    );
    setUsersSuggestions(users);
    setInitialValues(mapResponseToCenter(data));
    setCenterData({ ...data, timeZones });
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchTimeZone().then(timeZones => {
      if (mode === "edit") {
        fetchCenterBySlug(slug).then(({ data }) => {
          setEditMode(data, timeZones);
        });
      } else {
        setCenterData({ ...INITIAL_CENTER_DATA, timeZones });
        setIsLoaded(true);
      }
    });
  }, [mode, slug]);

  return (
    <section className="admin-form-content relative pb-28 pt-15 bg-tint-7 w-full z-0">
      <header className="fixed top-0 left-0 z-200 p-0 bg-white shadow-md border-b w-full">
        <button
          type="button"
          className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
          onClick={handleBackButtonPressed}
        >
          <i className="ri-arrow-left-s-line ri-lg text-dark-75 mr-1"></i>
          <span className="text-14 font-lato">All Centers</span>
        </button>
        <div className="admin-intro ellipsis mx-auto w-full flex max-w-5xl py-4">
          <h1 className="text-22 font-bold">{title}</h1>
        </div>
      </header>
      {isLoaded && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={CenterSchema}
        >
          {({ values, isValid, isSubmitting, setFieldValue }) => {
            const hasNoChanges = isEqual(
              {
                ...values,
                userList: contactList.filter(
                  contact => contact["user_id"] && contact["role"],
                ),
              },
              {
                ...initialValues,
                userList: centerData.attributes.usersList || [],
              },
            );
            const submitDisabled = !isValid || isSubmitting || hasNoChanges;
            return (
              <Form>
                <article className="relative z-100 py-5 px-4">
                  <section className="mx-auto w-full max-w-5xl bg-white border border-lines shadow">
                    <article className="">
                      <BasicDetails
                        values={values}
                        timeZones={centerData.timeZones}
                        setFieldValue={setFieldValue}
                      />
                      <Appeareance
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      {mode === "edit" && (
                        <ContactPoints
                          contactList={contactList}
                          setContactList={setContactList}
                          usersSuggestions={usersSuggestions}
                        />
                      )}
                    </article>
                  </section>
                </article>
                <footer className="fixed bg-white bottom-0 left-0 z-200 w-full border-lines border-t p-3">
                  <div className="mx-auto w-full max-w-5xl">
                    <div className="ml-72">
                      <button
                        type="submit"
                        className={clsx("btn-primary btn-xs px-5 mr-2", {
                          "bg-indigo-100 hover:bg-indigo-100": submitDisabled,
                        })}
                        disabled={submitDisabled}
                      >
                        {mode === "new" ? "Add Center" : "Save Changes"}
                      </button>
                      <button
                        type="button"
                        onClick={handleBackButtonPressed}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                      {mode === "edit" && (
                        <button
                          type="button"
                          onClick={handleDeleteButtonPressed}
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right"
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </footer>
              </Form>
            );
          }}
        </Formik>
      )}
      <ConfirmationModal
        title="You are not able to remove this center"
        description={
          <p>
            Contact{"  "}
            <a
              className="text-indigo-500"
              href="mailto: membercenter@parkerici.org"
            >
              membercenter@parkerici.org
            </a>
            {"  "}
            to remove a center.
          </p>
        }
        isOpen={modalOpen}
        closeLabel="Cancel"
        className="w-125"
        onClose={() => setModalOpen(false)}
        setOpen={() => setModalOpen(false)}
        hideAcceptButton
      />
    </section>
  );
};

export default CentersForm;
