import * as React from "react";

type FullWideProps = {
  children: React.ReactNode;
  className?: string;
};

function FullWideForm({ children, className = "" }: FullWideProps) {
  return (
    <section
      className={`admin-form-content relative pb-15 pt-15 bg-primary-lighter w-full z-0 ${className}`}
    >
      {children}
    </section>
  );
}

FullWideForm.header = ({ children, className = "" }: FullWideProps) => {
  return (
    <header
      className={`fixed top-0 left-0 z-200 p-0 bg-white shadow-xs border-b w-full ${className}`}
    >
      {children}
    </header>
  );
};

FullWideForm.body = ({ children, className = "" }: FullWideProps) => {
  return (
    <section className="relative z-100 py-5 px-4 ">
      <div
        className={`mx-auto w-full max-w-[964px] bg-white border border-lines shadow ${className}`}
      >
        {children}
      </div>
    </section>
  );
};

FullWideForm.footer = ({ children, className = "" }: FullWideProps) => {
  return (
    <footer
      className={`fixed bg-white bottom-0 left-0 z-200 w-full border-lines border-t p-3 ${className}`}
    >
      {children}
    </footer>
  );
};

export default FullWideForm;
