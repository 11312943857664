import { denormalizedFetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import DetailedOverview from "../DetailedOverview";
import { calculateOverdue } from "../calculateOverdue";
import * as React from "react";

const TODAY = new Date();

const fetchNationalPatentPending = () => {
  const baseUrl = "/api/inventions/patent_filters/?filters=national_pending";
  return denormalizedFetcher(baseUrl);
};

function NationalPatentPending({ id }) {
  const { data, isFetching } = useQuery(
    ["patent_deadline", id],
    fetchNationalPatentPending,
  );
  const patents = React.useMemo(() => {
    const dayIn90days = TODAY.setDate(TODAY.getDate() + 90);
    return calculateOverdue(data?.data, dayIn90days);
  }, [data]);

  return <DetailedOverview data={patents} isFetching={isFetching} />;
}

export default NationalPatentPending;
