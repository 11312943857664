import * as React from "react";
import Modal from "@/components/shared/Modal";

function OptModal({ isOpen, onClose, onAccept }) {
  return (
    <Modal open={isOpen} setOpen={onClose}>
      <Modal.Header>Opt-in To View Pre-Published Research</Modal.Header>
      <Modal.Body>
        <p className="font-body-large text-dark-75">
          By opting in, you acknowledge that viewing pre-published materials may
          disqualify you as a potential reviewer for some journals. You also
          agree to treat all pre-published materials as PICI Confidential
          Information and acknowledge that such materials are not intended for
          public disclosure or distribution.
          <br />
          <br />
          Pre-Published materials posted through the PICI Member Center will be
          accessible only to PICI Members who have agreed to these terms and
          conditions.
        </p>
      </Modal.Body>
      <Modal.Footer className="flex pt-6">
        <button
          type="button"
          onClick={onAccept}
          className="btn-primary btn-sm font-meta-bold"
        >
          I Agree
        </button>
        <button
          type="button"
          onClick={onClose}
          className="btn-white btn-sm ml-2.5 font-meta-bold"
        >
          I Do Not Agree
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default OptModal;
