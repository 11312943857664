import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "@/components/shared/ButtonIcon";
import ReactTooltip from "react-tooltip";

function ArticleCards({ values, onNewArticleClick, onDeleteArticleClick }) {
  const navigate = useNavigate();

  const handleClickEditArticle = article =>
    navigate(
      `/admin/retreats/${article.attributes.retreatId}/articles/${article.id}/edit`,
    );

  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Articles</h3>
        <p className="text-14 text-dark-75">
          Articles associated to the Retreat
        </p>
      </header>
      <section className="flex-1">
        <div className="flex-1 text-right pb-6">
          <button
            type="button"
            className="btn-white btn-md text-14 h-8 px-3"
            onClick={onNewArticleClick}
          >
            <i className="ri-add-line ri-lg text-dark-75 mr-1"></i>
            <span className="text-14 font-lato">Add new Article</span>
          </button>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px]">
          {values.articles.map(article => (
            <article
              key={article.id}
              className="bg-white h-60 rounded-lg shadow-md"
            >
              <div
                className="flex h-32 bg-cover w-full rounded-t-md"
                style={{
                  backgroundImage: `url(${article.attributes.thumbnailImageUrl})`,
                }}
              >
                <div className="flex-1">
                  {!article.attributes.published && (
                    <>
                      <i
                        data-for={article.id}
                        data-tip="This Article is not able to be seen in the Member center until their publication."
                        className="ri-error-warning-line mr-2 text-orange text-xl ml-2 mt-4"
                      />
                      <ReactTooltip id={article.id} multiline={true} />
                    </>
                  )}
                </div>
                <div className="flex p-2">
                  <ButtonIcon
                    title="Delete Article"
                    className="btn-white btn-md h-8 px-2"
                    iconClassName="ri-delete-bin-line"
                    onClick={() => onDeleteArticleClick(article.id)}
                  />
                  <ButtonIcon
                    title="Edit Article"
                    className="btn-white btn-md h-8 px-2"
                    iconClassName="ri-pencil-line"
                    onClick={() => handleClickEditArticle(article)}
                  />
                </div>
              </div>
              <header className="p-4">
                <h2 className="font-h2 line-clamp-2 mb-1 group-hover:underline">
                  {article.attributes.title}
                </h2>
              </header>
            </article>
          ))}
        </div>
      </section>
    </article>
  );
}

export default ArticleCards;
