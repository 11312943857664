import NoResults from "@/components/shared/NoResults";
import * as React from "react";

function BiographyTab({ user }) {
  const { firstName, bio } = user.attributes;

  return (
    <div>
      <h2 className="font-h2 mb-5 text-byte-dark">{firstName}'s Biography</h2>
      {bio && bio !== "" ? (
        <div className="border border-lines rounded-md shadow-sm px-6 py-4">
          <p className="font-body text-dark-75">{bio}</p>
        </div>
      ) : (
        <NoResults
          fullText={`${firstName} doesn’t have any bio information available`}
          entityName="bio"
          icon="ri-article-line"
        />
      )}
    </div>
  );
}

export default BiographyTab;
