import * as React from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

const settings = [
  {
    label: "Profile information",
    items: [
      { name: "Basic Info", path: "/settings/basic-info" },
      { name: "Professional Info", path: "/settings/professional-info" },
      { name: "Publication History", path: "/settings/publication-history" },
    ],
  },
  {
    label: "My PICI activity",
    items: [
      { name: "Projects", path: "/settings/projects" },
      { name: "Research", path: "/settings/research" },
    ],
  },
  {
    label: "Notifications",
    items: [
      { name: "Email Notifications", path: "/settings/email-notifications" },
    ],
  },
  {
    label: "Support",
    items: [
      { name: "Member Center Help", path: "/settings/help-request" },
      { name: "Version", path: "/settings/version" },
    ],
  },
];

function Sidebar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onSelectChange = path => navigate(path);

  return (
    <>
      <div className="hidden md:block">
        <div className="border border-lines space-y-5 divide-y divide-lines rounded-lg p-6 font-body text-dark-75 w-full md:min-w-[250px] lg:w-80">
          {settings.map(section => (
            <div key={section.label} className="block pt-5 first:pt-0">
              <h3 className="font-label text-dark-75">{section.label}</h3>
              <div className="flex flex-col mt-5 space-y-2">
                {section.items.map(item => (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    className={({ isActive }) =>
                      clsx(
                        "py-2",
                        isActive
                          ? "bg-lines px-3 rounded-md font-body-bold text-dark-100"
                          : "text-dark-75 hover:bg-lines hover:rounded-md hover:px-3",
                      )
                    }
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="block md:hidden">
        <select
          value={pathname}
          onChange={event => onSelectChange(event.target.value)}
          className="block w-full pl-3 pr-10 py-2 border-dark-25 text-dark-100 font-body-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          {settings.map(setting =>
            setting.items.map(item => (
              <option key={item.path} value={item.path}>
                {item.name}
              </option>
            )),
          )}
        </select>
      </div>
    </>
  );
}

function AccountSettingsPage() {
  return (
    <div className="margin-safe pb-20">
      <h1 className="text-dark-100 font-h2 sm:font-h1 pt-7.5 md:py-10 md:border-b md:border-lines">
        My Account
      </h1>
      <div className="mt-5 md:mt-10 md:flex">
        <aside className="pb-7.5 border-b border-lines md:border-none md:pb-0">
          <Sidebar />
        </aside>
        <section className="w-full mt-7.5 md:mt-0 md:ml-7.5">
          <Outlet />
        </section>
      </div>
    </div>
  );
}

export default AccountSettingsPage;
