import React from "react";
import clsx from "clsx";
import DatePicker from "react-datepicker";

type DatePickerProps = {
  id: string;
  dateFormat?: string;
  selected?: Date | string;
  onChange: (value: string) => void;
  onKeyDown?: Function;
  className?: string;
  errors?: string;
  wrapperClassName?: string;
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  styleAs?: "base" | "small"; // We can't put size as a props because size is an existing props of HTML Input Element
};

// For more props look into this page
//github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md

function InputDate({
  id,
  dateFormat = "MMM dd, yyyy",
  selected,
  onChange,
  className,
  errors,
  wrapperClassName,
  autoComplete = "off",
  styleAs = "base",
  placeholder,
  disabled = false,
  ...props
}: DatePickerProps) {
  const datePickerClassnames = clsx(
    `border border-dark-25 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary`,
    styleAs === "base" && "h-12 px-3.5 py-2.5 text-17 rounded-md",
    styleAs === "small" && "h-10 text-15 rounded",
    className,
    errors
      ? "focus:ring-error focus:border-error ring-error border-error"
      : "focus:ring-primary focus:border-primary",
  );

  return (
    <DatePicker
      id={id}
      dateFormat={dateFormat}
      selected={selected}
      onChange={onChange}
      className={datePickerClassnames}
      wrapperClassName={wrapperClassName}
      autoComplete={autoComplete}
      placeholderText={placeholder}
      disabled={disabled}
      {...props}
    />
  );
}

export default InputDate;
