import * as React from "react";
import SVG from "react-inlinesvg";
import useCurrentUser from "@/hooks/useCurrentUser";
import centerBannerPlaceholder from "@/images/center-banner-placeholder.png";
import Tabs from "@/components/shared/Tabs";

function Header({ center, tabs, selectedTab, setSelectedTab }) {
  const currentUser = useCurrentUser();

  const { name, location } = center?.attributes;
  const { bannerImage, logoImage } = center?.links;
  const banner = bannerImage || centerBannerPlaceholder;

  return (
    <div className="flex flex-col h-450px">
      <div className="relative overflow-hidden flex flex-grow items-end sm:border-b border-dark-75">
        <div className="absolute inset-0">
          <img
            className="object-cover sm:object-fill w-full min-h-full min-w-full"
            src={banner}
            alt=""
          />
          <div
            className="absolute inset-0 bg-gradient-to-t from-dark-100"
            aria-hidden="true"
          />
        </div>
        <div className="text-white relative margin-safe sm:mb-12">
          <div className="text-center sm:text-left sm:flex">
            {logoImage && (
              <div className="sm:mt-2 sm:pr-12 sm:mr-10 sm:border-r sm:border-lines sm:border-opacity-50">
                <div className="px-8 pb-6 mb-6 inline-block border-b border-lines border-opacity-50 sm:border-b-0 sm:px-0 sm:pb-0 sm:mb-0">
                  <SVG
                    src={logoImage}
                    preserveAspectRatio="xMidYMid meet"
                    className="svg-white"
                  />
                </div>
              </div>
            )}
            <div className="max-w-xl mx-auto sm:ml-0 sm:max-w-3xl">
              <h1 className="mb-3 font-h1 sm:font-h1-xl">PICI @ {name}</h1>
              <p className="font-body flex items-center justify-center sm:justify-start">
                <i className="ri-map-pin-line ri-lg text-dark-25" />
                <span className="font-semibold ml-2.5">{location}</span>
              </p>
              {currentUser?.attributes.isStaff && (
                <a
                  href={center.links.edit}
                  className="font-meta-bold mb-4 sm:mb-0 mt-4 btn btn-sm border-white"
                >
                  Edit center details
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-dark-100">
        <Tabs
          {...{ tabs, selectedTab, setSelectedTab }}
          palette="dark"
          className="margin-safe pb-4 sm:pb-0"
        />
      </div>
    </div>
  );
}

export default Header;
