import React, { useState } from "react";
import clsx from "clsx";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import { SectionMode } from "../defaults";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import RichText from "@/components/shared/RichText";

function ImageSelector({
  section,
  values,
  onChange,
  className = "",
  imageNumber = 1,
}) {
  const [urlImage, setUrlImage] = useState();

  const handleAddSigned = newSignedId => {
    onChange("sectionImages", [
      ...values.sectionImages,
      { signedId: newSignedId },
    ]);
    onChange(
      `articleSections[${section.position}].content.signedId${imageNumber}`,
      newSignedId,
    );
  };
  const currentImage = section.content[`signedId${imageNumber}`]
    ? values.sectionImages.find(
        image => image.signed_id === section.content[`signedId${imageNumber}`],
      )
    : {};

  const isEditMode = section.viewMode === SectionMode.Edit;
  return (
    <div>
      {isEditMode ? (
        <>
          <div
            className={clsx(
              "bg-dark-100 h-72 lg:h-[440px] p-3 bg-cover",
              className,
            )}
            style={{
              backgroundImage: `url(${currentImage?.image_url || urlImage})`,
            }}
          >
            <div className="flex justify-center mt-36">
              <FilePickerAdmin
                name={`image-picker-position${section.position}-number${imageNumber}`}
                className="w-80"
                setSignedId={signedId => handleAddSigned(signedId)}
                attachment={{
                  signedId: values.coverImage,
                  metadata: values.coverImageMetadata,
                  imageUrl: values.coverImageUrl,
                }}
                onLoadFile={value => {
                  setUrlImage(value.result);
                }}
              />
            </div>
          </div>
          <RichTextEditor
            className="mt-3"
            value={section.content[`description${imageNumber}`]}
            onChange={value =>
              onChange(
                `articleSections[${section.position}].content.description${imageNumber}`,
                value,
              )
            }
          />
        </>
      ) : (
        <>
          <img
            src={`${currentImage?.image_url || urlImage}`}
            className="h-auto text-center max-w-full justify-center mx-auto"
          />
          {section.content[`description${imageNumber}`] && (
            <RichText
              className="pt-3.75 font-micro text-dark-50"
              value={section.content[`description${imageNumber}`]}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ImageSelector;
