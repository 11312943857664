import * as React from "react";
import Table from "@/components/shared/Table";
import PopoverMenu from "@/components/shared/PopoverMenu";
import NoResults from "@/components/shared/NoResults";
import useToast from "@/hooks/useToast";

function itemValue(item, column) {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item.attributes[column.name];
  }
}

function render(value, column, item) {
  if (column.render) {
    return column.render(value, item);
  } else {
    return value;
  }
}
const ResourceRowSkeleton = ({ columns }) => {
  return (
    <Table.Row className="divide-x divide-lines animate-pulse">
      {columns.map((column, j) =>
        column.name != "actions" ? (
          <Table.Cell
            key={j}
            className={column.td?.className}
            expand={j < columns.length - 1 ? true : false}
          >
            <div className="h-2 w-3/4 bg-dark-25 rounded-md" />
          </Table.Cell>
        ) : (
          <Table.Cell
            key={j}
            className={column.td?.className}
            expand={j < columns.length - 1 ? true : false}
          >
            <div className="flex gap-2.5">
              <div className="h-2 w-1/2 bg-dark-25 rounded-md" />
              <div className="h-2 w-1/2 bg-dark-25 rounded-md" />
            </div>
          </Table.Cell>
        ),
      )}
    </Table.Row>
  );
};
function ResourcesTable({
  resources,
  tabs,
  isFetching,
  isFetchingNextPage,
  openForm,
}) {
  const showToast = useToast({
    successText: (
      <>
        A link to the resource has been copied to your clipboard. <br />
        Click ⌘ + v or Ctrl + v to paste.
      </>
    ),
    errorText: "Could not copy the resource link to your clipboard.",
  });
  const columns = [
    {
      label: "Resource Title",
      name: "title",
      th: { className: "pt-1 pl-4 text-11" },
      td: {
        className:
          "max-w-[300px]  pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden font-bold text-dark-75",
      },
    },
    {
      label: "Category",
      name: "categoryId",
      th: { width: "20%", className: "pt-1 pl-4 text-11" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
      render: categoryId => (
        <>{tabs.find(category => category.id === categoryId).name}</>
      ),
    },
    {
      label: "Type",
      name: "shortType",
      th: { className: "pt-1 pl-4 text-11" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
    },
    {
      label: "Details",
      name: "link",
      th: { className: "pt-1 pl-4 text-11" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden max-w-[250px]",
      },
    },
    {
      label: "File Size",
      name: "fileSize",
      th: { className: "pt-1 pl-4 pr-2 text-11 w-[90px]" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 pr-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
    },
    {
      label: "Actions",
      name: "actions",
      th: { className: "text-left pt-1 pl-4 text-11 w-[110px]" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 pr-4 whitespace-nowrap text-center",
      },
      render: (_, item) => (
        <div className="flex gap-2.5">
          <button
            className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-0.5 px-3.75"
            onClick={() => openForm(item)}
            data-testid={item.attributes.name}
          >
            Edit
          </button>
          <React.StrictMode>
            <PopoverMenu resource={item} {...{ showToast }}>
              <button className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-0.5 px-3.75">
                Share
              </button>
            </PopoverMenu>
          </React.StrictMode>
          {item.attributes.fileUrl && (
            <a
              href={item.attributes.fileUrl}
              className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-0.5 px-3.75"
              target="_blank"
            >
              {" "}
              View
            </a>
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      {!isFetching && resources.length === 0 ? (
        <NoResults entityName="resources" icon="ri-search-line" />
      ) : (
        <Table className="border border-transparent text-sm">
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, i) => (
              <Table.HeaderCell
                {...column.th}
                key={i}
                className={column.th?.className}
              >
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body className="border border-lines">
            {resources.length > 0 &&
              resources.map((item, i) => (
                <Table.Row key={i} className="divide-x divide-lines">
                  {columns.map((column, j) => (
                    <Table.Cell
                      key={j}
                      className={column.td?.className}
                      expand={j < columns.length - 1 ? true : false}
                    >
                      {render(itemValue(item, column), column, item)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => (
                <ResourceRowSkeleton columns={columns} key={x} />
              ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default ResourcesTable;
