import React from "react";
import clsx from "clsx";
import { SectionMode, KeySection } from "../defaults";
import Table from "@/components/shared/Table";
import ButtonIcon from "@/components/shared/ButtonIcon";
import Input from "@/components/shared/Input";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import RichText from "@/components/shared/RichText";
import useViewMode from "./useViewMode";
import useWindowDimensions from "@/hooks/useWindowDimensions";

function TableSectionEditor({ section, className = "", values, onChange }) {
  const { width } = useWindowDimensions();
  const fixedTable = width > 1024;

  const {
    isEditMode,
    isPreviewMode,
    isViewOrPreview,
    isEditOrPreview,
  } = useViewMode(section.viewMode);

  const handleDeleteSection = () => {
    const updatedSections = values.articleSections
      .filter(sectionItem => sectionItem.position !== section.position)
      .map((sectionItem, index) => ({ ...sectionItem, position: index }));
    onChange("articleSections", updatedSections);
  };

  const handlePreviewMode = () => {
    onChange(
      `articleSections[${section.position}].viewMode`,
      SectionMode.Preview,
    );
  };

  const handleAddColumnRight = () => {
    onChange(`articleSections[${section.position}].content.headers`, [
      ...section.content.headers,
      "",
    ]);
    onChange(
      `articleSections[${section.position}].content.body`,
      section.content.body.map(row => [...row, null]),
    );
  };

  const handleRemoveColumnRight = () => {
    onChange(
      `articleSections[${section.position}].content.headers`,
      section.content.headers.slice(0, -1),
    );
    onChange(
      `articleSections[${section.position}].content.body`,
      section.content.body.map(row => row.slice(0, -1)),
    );
  };

  const handleAddRow = () => {
    onChange(`articleSections[${section.position}].content.body`, [
      ...section.content.body,
      new Array(section.content.headers.length).fill(null),
    ]);
  };

  const handleRemoveLastRow = () => {
    onChange(
      `articleSections[${section.position}].content.body`,
      section.content.body.slice(0, -1),
    );
  };

  const handleEditMode = () => {
    onChange(`articleSections[${section.position}].viewMode`, SectionMode.Edit);
  };

  const handleChangeTextHeader = (text, columnIndex) => {
    onChange(
      `articleSections[${section.position}].content.headers[${columnIndex}]`,
      text,
    );
  };

  const handleChangeTextCell = (text, rowIndex, columnIndex) => {
    onChange(
      `articleSections[${section.position}].content.body[${rowIndex}][${columnIndex}]`,
      text,
    );
  };

  return (
    <div
      className={clsx(
        "relative group border-2 border-transparent pb-3 lg:pb-0",
        {
          "bg-tint border rounded-lg mb-3 pt-7 px-4": isEditMode,
          "hover:border-2 hover:border-zinc-200 hover:rounded-lg hover:border-dashed p-2 mb-3": isPreviewMode,
        },
        className,
      )}
    >
      {isEditOrPreview && (
        <div
          className={clsx(
            "absolute bg-slate-700 text-white right-1/2 -top-3",
            "px-4 rounded-lg hidden group-hover:block",
          )}
        >
          {section.keySection}
        </div>
      )}
      <div
        className={clsx("absolute right-4 top-1 group-hover:block", {
          hidden: isPreviewMode,
          block: isEditMode,
        })}
      >
        {isPreviewMode && (
          <ButtonIcon
            title="Set Edit mode"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-pencil-line"
            onClick={handleEditMode}
          />
        )}

        {isEditMode && (
          <>
            <ButtonIcon
              title="Set Preview mode"
              className="btn-white btn-md h-8 px-2"
              iconClassName="ri-eye-line"
              onClick={handlePreviewMode}
            />
            <ButtonIcon
              title="Add column right"
              disabled={section.content.headers.length >= 4}
              className="btn-white btn-md h-8 px-2"
              iconClassName="ri-insert-column-right"
              onClick={handleAddColumnRight}
            />
            <ButtonIcon
              title="Remove column right"
              disabled={section.content.headers.length <= 1}
              className="btn-white btn-md h-8 px-2"
              iconClassName="ri-delete-column"
              onClick={handleRemoveColumnRight}
            />
            <ButtonIcon
              title="Add row"
              className="btn-white btn-md h-8 px-2"
              iconClassName="ri-insert-row-bottom"
              onClick={handleAddRow}
            />
            <ButtonIcon
              title="Remove last row"
              disabled={section.content.body.length <= 1}
              className="btn-white btn-md h-8 px-2"
              iconClassName="ri-delete-row"
              onClick={handleRemoveLastRow}
            />
          </>
        )}
        {isEditOrPreview && (
          <ButtonIcon
            title="Delete Section"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-delete-bin-line"
            onClick={handleDeleteSection}
          />
        )}
      </div>
      <Table
        className={clsx("border border-transparent rounded", {
          "max-w-[870px]": isEditMode,
        })}
        fixed={fixedTable}
        isOverflowY
      >
        <Table.Header className="bg-tint text-dark-75 border">
          {section.content.headers.map((header, columnIndex) => (
            <Table.HeaderCell
              key={columnIndex}
              className={clsx("!text-12 !leading-4 font-inter", {
                "!px-2": isEditMode,
              })}
            >
              <>
                {isViewOrPreview ? (
                  <span>{header}</span>
                ) : (
                  <Input
                    styleAs="small"
                    value={header}
                    onChange={event =>
                      handleChangeTextHeader(event.target.value, columnIndex)
                    }
                    className="font-bold"
                  />
                )}
              </>
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body className="border border-lines">
          {section.content.body.map((rowItem, rowIndex) => (
            <Table.Row key={rowIndex} className="divide-lines">
              {section.content.headers.map((_, columnIndex) => (
                <Table.Cell
                  key={`key_${rowIndex}_${columnIndex}`}
                  className={clsx("whitespace-pre-wrap min-w-224px", {
                    "!px-2": isEditMode,
                  })}
                >
                  {
                    <>
                      {isViewOrPreview ? (
                        <RichText
                          className={clsx("text-dark-75 break-words", {
                            "font-body":
                              section.keySection !== KeySection.ReferenceText,
                            "font-micro":
                              section.keySection === KeySection.ReferenceText,
                          })}
                          value={rowItem[columnIndex]}
                        />
                      ) : (
                        <RichTextEditor
                          value={rowItem[columnIndex]}
                          onChange={value =>
                            handleChangeTextCell(value, rowIndex, columnIndex)
                          }
                        />
                      )}
                    </>
                  }
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <>
        {isEditMode ? (
          <>
            <RichTextEditor
              value={section.content.description}
              onChange={value =>
                onChange(
                  `articleSections[${section.position}].content.description`,
                  value,
                )
              }
            />
          </>
        ) : (
          <>
            <RichText
              className={clsx("text-dark-50 text-11 lg:font-micro mt-3")}
              value={section.content.description}
            />
          </>
        )}
      </>
    </div>
  );
}

export default TableSectionEditor;
