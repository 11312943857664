import * as React from "react";

const LoadingMember = () => {
  return (
    <div className="animate-pulse flex space-x-4 px-4 py-4 sm:px-6">
      <div className="rounded-full bg-dark-25 h-10 w-10"></div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
          <div className="h-4 bg-dark-25 rounded-full w-3/4 sm:w-1/2"></div>
          <div className="h-4 bg-dark-25 rounded-full w-1/3"></div>
        </div>
      </div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
          <div className="h-4 bg-dark-25 rounded-full w-full sm:w-2/3"></div>
          <div className="h-4 bg-dark-25 rounded-full w-3/5 sm:w-1/3"></div>
        </div>
      </div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2 flex flex-col items-end">
          <div className="h-4 bg-dark-25 rounded-full w-1/3"></div>
          <div className="h-4 bg-dark-25 rounded-full w-1/2"></div>
        </div>
      </div>
    </div>
  );
};

function LoadingMembers() {
  return (
    <div className="overflow-x-auto border border-lines rounded-lg">
      <div className="bg-white table-shadow overflow-hidden sm:rounded-lg w-585px sm:w-full">
        <div className="divide-y divide-gray-25 border-gray-25">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => (
            <LoadingMember key={x} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default LoadingMembers;
