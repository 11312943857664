import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import Filter from "@/components/shared/Filter";
import FilterTags from "@/components/shared/FilterTags";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import { FilterOption } from "@/types";

const sortOptions = [
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "First Name", id: "firstName" },
  { name: "Last Name", id: "lastName" },
];

const FiltersSkeleton = () => (
  <div className="animate-pulse pr-3.5 mb-10">
    <div className="flex gap-4">
      <div className="bg-dark-25 h-10.5 lg:h-12.5 w-1/3 md:w-1/6 rounded-md" />
      <div className="bg-dark-25 h-10.5 lg:h-12.5 w-1/3 md:w-1/6 rounded-md" />
      <div className="bg-dark-25 h-10.5 lg:h-12.5 w-1/3 md:w-1/6 rounded-md mr-auto" />
      <div className="bg-dark-25 h-10.5 lg:h-12.5 sm:w-1/3 md:w-1/5 rounded-md hidden sm:block" />
    </div>
  </div>
);

function Filters({ setFilters }) {
  const { isLoading, data: filtersData } = useQuery(["userFilters"], () =>
    fetcher("/api/users/filters"),
  );

  const [selectedCenters, setSelectedCenters] = useState<FilterOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<FilterOption[]>([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [centerFocus, setCenterFocus] = useState(false);
  const [roleFocus, setRoleFocus] = useState(false);
  const [focusAreaFocus, setFocusAreaFocus] = useState(false);

  const params = paramBuilder({
    filter: {
      centers: selectedCenters.map(x => x.id),
      role: selectedRoles.map(x => x.id),
      focusAreas: selectedFocusAreas.map(x => x.id),
    },
    sort: [selectedSort.id],
  });

  useEffect(() => {
    setFilters(params);
  }, [params, setFilters]);
  return (
    <>
      {isLoading ? (
        <FiltersSkeleton />
      ) : (
        <div>
          <div className="flex flex-wrap">
            <div className="flex flex-wrap w-full sm:w-auto sm:mr-auto">
              <Filter
                name="center"
                items={filtersData?.centers}
                selectedItems={selectedCenters}
                setSelectedItems={setSelectedCenters}
                setFocus={setCenterFocus}
                className="flex-1 mr-4 mb-4"
              >
                <Filter.Label>Center</Filter.Label>
                <Filter.Content>
                  <CheckboxGroup
                    options={filtersData?.centers}
                    selectedOptions={selectedCenters}
                    setSelectedOptions={setSelectedCenters}
                    className="filter-checkbox-group"
                  />
                </Filter.Content>
              </Filter>
              <Filter
                name="role"
                items={filtersData?.roles}
                selectedItems={selectedRoles}
                setSelectedItems={setSelectedRoles}
                setFocus={setRoleFocus}
                className="flex-1 mr-4 mb-4"
              >
                <Filter.Label>Role</Filter.Label>
                <Filter.Content>
                  <CheckboxGroup
                    options={filtersData?.roles}
                    selectedOptions={selectedRoles}
                    setSelectedOptions={setSelectedRoles}
                    className="filter-checkbox-group"
                  />
                </Filter.Content>
              </Filter>
              <Filter
                name="focusArea"
                items={filtersData?.focusAreas}
                selectedItems={selectedFocusAreas}
                setSelectedItems={setSelectedFocusAreas}
                setFocus={setFocusAreaFocus}
                className="flex-grow flex-shrink-0 sm:flex-1"
              >
                <Filter.Label>Focus Area</Filter.Label>
                <Filter.Content>
                  <CheckboxGroup
                    options={filtersData?.focusAreas}
                    selectedOptions={selectedFocusAreas}
                    setSelectedOptions={setSelectedFocusAreas}
                    className="focus-area-checkbox-group"
                    labelClassName="sm:w-325px"
                  />
                </Filter.Content>
              </Filter>
            </div>
            <div className="mb-4 hidden sm:flex sm:items-center">
              <span className="hidden md:block lg:hidden xl:block mr-4 text-dark-100 font-body-bold">
                Sort by:
              </span>
              <Filter
                name="sort"
                items={sortOptions}
                selectedItems={selectedSort}
                setSelectedItems={setSelectedSort}
                className="mr-3.5"
                closeOnSelect
              >
                <Filter.Label className="flex">
                  {selectedSort.name}
                </Filter.Label>
                <Filter.Content>
                  <SelectGroup
                    options={sortOptions}
                    selectedOption={selectedSort}
                    setSelectedOption={setSelectedSort}
                  />
                </Filter.Content>
              </Filter>
            </div>
          </div>
          <div className="flex flex-wrap mt-4 mb-6">
            <FilterTags
              options={selectedCenters}
              setOptions={setSelectedCenters}
              isFocused={centerFocus}
            />
            <FilterTags
              options={selectedRoles}
              setOptions={setSelectedRoles}
              isFocused={roleFocus}
            />
            <FilterTags
              options={selectedFocusAreas}
              setOptions={setSelectedFocusAreas}
              isFocused={focusAreaFocus}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Filters;
