import React from "react";
import { BarChart, Bar, Tooltip, Cell, XAxis, YAxis } from "recharts";
import CustomTooltip from "@/components/shared/ChartsTooltip";
import COLORS from "./Colors";

const CHART_WIDTH = 846;
const CHART_HEIGHT = 449;

const CustomLabel = ({
  y,
  value,
  height,
  total,
}: {
  y?: any;
  value?: any;
  height?: any;
  total: any;
}) => {
  const labelY = y + height / 2;
  return (
    <text
      x={CHART_WIDTH}
      y={labelY}
      textAnchor="end"
      dominantBaseline="central"
      className="font-label"
    >
      {`${value} / ${total}`}
    </text>
  );
};

const CustomTick = ({ y, payload }: { y?: any; payload?: any }) => {
  const label = payload.value.split(" ");
  const dy = 20;
  const labelY = y - dy * (0.5 + label.length / 2);

  return (
    <text x={0} y={labelY} dominantBaseline="central" className="font-label">
      {label.map((part, index) => (
        <tspan key={index} x={0} dy={dy}>
          {part}
        </tspan>
      ))}
    </text>
  );
};

function FullsizeBarChart({ data, total, title }) {
  return (
    <>
      <h2 className="font-h2">{title}</h2>
      <BarChart
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        layout="vertical"
        data={data}
        margin={{
          top: 18,
          bottom: 0,
          left: 36,
          right: 80,
        }}
      >
        <XAxis type="number" hide />
        <YAxis
          type="category"
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={<CustomTick />}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        {data && (
          <Bar
            dataKey="count"
            barSize={72}
            background={{ fill: "#eaecf0" }}
            label={<CustomLabel total={total} />}
            isAnimationActive={false}
          >
            {data.map((el, index) => (
              <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        )}
      </BarChart>
    </>
  );
}

export default FullsizeBarChart;
