import Modal from "@/components/shared/Modal";
import * as React from "react";

function DeleteConfirmationModal({
  open,
  setOpen,
  onAccept,
  resource,
  message = "The content and any attachments will no longer be available.",
  className = "",
  footerClassName = "w-full flex justify-between mt-5",
}: {
  open: boolean;
  setOpen: any;
  onAccept: () => void;
  resource: string;
  message?: string;
  className?: string;
  footerClassName?: string;
}) {
  return (
    <Modal open={open} setOpen={setOpen} className={className}>
      <Modal.Header>
        Are you sure you want to delete this {resource}?
      </Modal.Header>
      <Modal.Body className="text-dark-75 font-body-large">
        {message}
      </Modal.Body>
      <Modal.Footer className={footerClassName}>
        <button
          type="button"
          className="btn-white btn-sm font-meta-bold"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button
          type="button"
          className="btn-md bg-red-350 text-white hover:bg-red-450 active:bg-red-650"
          onClick={onAccept}
        >
          Yes, Delete <span className="capitalize">{resource}</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
