import React from "react";
import clsx from "clsx";
import Dropdown from "@/components/shared/Dropdown";
import SelectGroup from "@/components/shared/SelectGroup";

const _accessor = option => option.name;

function Select({
  selected,
  setSelected,
  options,
  placeholder = "Select an option",
  className = "",
  classNameContainer = "",
  classNameSelectGroup = "",
  accessor = _accessor,
  error = false,
  placement = "",
  ...props
}) {
  const selectedOption = options?.find(
    option => String(option.id) === String(selected),
  );

  return (
    <div className="flex flex-col gap-1">
      <Dropdown className={classNameContainer} placementOpt={placement}>
        <Dropdown.Label
          className={clsx(
            "rounded-md px-4 font-h3 w-full",
            error
              ? "focus:ring-error focus:border-error ring-error border-error"
              : "focus:ring-primary focus:border-primary",
            className,
          )}
          {...props}
        >
          <span className="mr-4 truncate">
            {selectedOption ? accessor(selectedOption) : placeholder}
          </span>
          <i className="ri-code-line ri-sm rotate-90 text-dark-50 leading-none" />
        </Dropdown.Label>
        <Dropdown.Content>
          <SelectGroup
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={option => setSelected(option.id)}
            accessor={accessor}
            className={classNameSelectGroup}
          />
        </Dropdown.Content>
      </Dropdown>
    </div>
  );
}

export default Select;
