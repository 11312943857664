import React from "react";
import FullWideForm from "@/components/shared/FullWideForm";

function SkeletonForm() {
  return (
    <FullWideForm className="animate-pulse">
      <FullWideForm.header>
        <button
          type="button"
          className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
        >
          <p className="h-2 bg-dark-25 block rounded w-[100px]"></p>
        </button>
        <div className="admin-intro ellipsis mx-auto w-full flex justify-between max-w-7xl py-4">
          <h3 className="w-[160px] my-2 h-4 bg-dark-25 rounded"></h3>
          <button type="button" className="btn-white btn-md text-14 h-8 px-3">
            <p className="h-2 bg-dark-25 block rounded w-[100px]"></p>
          </button>
        </div>
      </FullWideForm.header>
      <FullWideForm.body className="!max-w-7xl">
        <div className="flex gap-7.5">
          <article className="flex p-7 pr-0 pb-10 border-b w-[900px]">
            <section className="w-full">
              <div className="w-full h-100 bg-dark-25 mb-6 rounded"></div>
              <div className="w-2/3 h-8 bg-dark-25 mb-3 rounded"></div>
              <button
                type="button"
                className="btn-white btn-md text-14 h-8 px-3 ml-[750px] mb-2"
              >
                <p className="h-2 bg-dark-25 block rounded w-[100px]"></p>
              </button>
              <div className="w-[100px] h-2 bg-dark-25 mb-2 rounded"></div>
              <div className="h-8 bg-dark-25 rounded mb-2"></div>
              <div className="w-[100px] h-2 bg-dark-25 mb-2 rounded"></div>
              <div className="h-8 bg-dark-25 rounded"></div>
            </section>
          </article>
          <aside className="w-[260px] p-7 pb-10 pl-0 border-b flex flex-col gap-6">
            <section className="flex flex-col gap-6 p-6 bg-dark-25 rounded">
              <div>
                <div className=" w-[160px] h-4 bg-dark-50 rounded" />
                <div className="mb-2 rounded flex mt-3.5 gap-2">
                  <div className="h-10 w-10 rounded-full bg-dark-50" />
                  <div className="flex flex-col flex-1 h-full gap-2">
                    <div className="w-2/3 h-3 rounded bg-dark-50" />
                    <div className="w-1/3 h-2 rounded bg-dark-50" />
                  </div>
                </div>
              </div>
              <div>
                <div className=" w-[160px] h-4 mb-2 bg-dark-50 rounded" />
                <div className="flex flex-col flex-1 h-full gap-2">
                  <div className="w-2/3 h-3 rounded bg-dark-50" />
                  <div className="w-1/3 h-2 rounded bg-dark-50" />
                </div>
              </div>
            </section>
            <section className="flex flex-col gap-6 p-6 bg-dark-25 rounded">
              <div>
                <div className=" w-[160px] h-4 mb-2 bg-dark-50 rounded" />
                <div className="flex flex-col flex-1 h-full gap-2">
                  <div className="w-2/3 h-3 rounded bg-dark-50" />
                  <div className="w-1/3 h-2 rounded bg-dark-50" />
                </div>
              </div>
            </section>
            <section className="flex flex-col gap-6 p-6 bg-dark-25 rounded">
              <div>
                <div className=" w-[160px] h-4 bg-dark-50 rounded" />
                <div className="mb-2 rounded flex mt-3.5 gap-2">
                  <div className="h-10 w-10 rounded-full bg-dark-50" />
                  <div className="flex flex-col flex-1 h-full gap-2">
                    <div className="w-2/3 h-3 rounded bg-dark-50" />
                    <div className="w-1/3 h-2 rounded bg-dark-50" />
                  </div>
                </div>
              </div>
            </section>
          </aside>
        </div>
      </FullWideForm.body>
      <FullWideForm.footer>
        <div className="mx-auto w-full max-w-[964px]">
          <div className="ml-72 flex">
            <p className="h-4 mb-2 bg-dark-25 block rounded w-[90px] mr-4"></p>
            <p className="h-4 mb-2 bg-dark-25 block rounded w-[90px]"></p>
            <p className="h-4 mb-2 bg-dark-25 block rounded w-[90px] ml-auto"></p>
          </div>
        </div>
      </FullWideForm.footer>
    </FullWideForm>
  );
}

export default SkeletonForm;
