import * as React from "react";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import Table from "@/components/shared/Table";

const MembersCard = ({
  member,
  showCenter = true,
}: {
  member: any;
  showCenter?: boolean;
}) => {
  const {
    fullName,
    centerContactRole,
    title,
    centerNameOrInstitution,
    createdAt,
    digestedJobTitle,
  } = member.attributes;
  const { show, avatar } = member.links;
  const formattedCreatedAt = format(
    parseISO(createdAt.slice(0, 10)),
    "MMM d, yyyy",
  );

  const centerOrInstitution = showCenter && centerNameOrInstitution;

  return (
    <Table.Row>
      <Table.Cell expand={false} className="pr-2">
        <div className="flex items-center">
          {avatar && (
            <Link to={show} className="flex min-w-max">
              <img
                src={avatar}
                alt=""
                className="h-10 w-10 rounded-full flex-shrink-0"
              />
            </Link>
          )}
          <div className="flex flex-col justify-center w-32 sm:w-36 ml-4 mr-1">
            <Link to={show} className="link">
              <p className="truncate text-dark-100 font-body-bold">
                {fullName}
              </p>
            </Link>
            <p className="font-meta truncate">
              {centerContactRole || digestedJobTitle}
            </p>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {title || centerOrInstitution ? (
          <div className="flex flex-col justify-center w-64 sm:w-48 md:w-72 lg:w-48 xl:w-80">
            <p className="font-body text-dark-100 truncate">{title}</p>
            <p className="font-meta truncate">{centerOrInstitution}</p>
          </div>
        ) : (
          <div className="flex flex-col justify-center">
            <p className="font-label"> --- </p>
          </div>
        )}
      </Table.Cell>
      <Table.Cell className="text-right">
        <p className="font-meta">Joined</p>
        <p className="font-meta">{formattedCreatedAt}</p>
      </Table.Cell>
    </Table.Row>
  );
};

function UsersList({
  members,
  showCenter = true,
}: {
  members: any;
  showCenter?: boolean;
}) {
  return (
    <Table>
      <Table.Body>
        {members.map(member => (
          <MembersCard
            member={member}
            key={member.id}
            showCenter={showCenter}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

export default UsersList;
