import * as React from "react";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import FormBlock from "@/components/shared/FormBlock";
import SectionGroup from "@/components/shared/Admin/SectionGroup";

function Appeareance({ values, setFieldValue }) {
  return (
    <SectionGroup
      title="Appearance"
      description="How this center should look."
      className="border-t"
    >
      <FormBlock
        label="Banner Image"
        name="banner_image"
        className="mb-6"
        optional
      >
        <FilePickerAdmin
          setSignedId={val => setFieldValue("banner_image", { signedId: val })}
          attachment={{
            signedId: values.banner_image?.signedId,
            metadata: values.banner_image?.metadata,
            imageUrl: values.banner_image?.url,
          }}
        />
      </FormBlock>
      <FormBlock
        label="Background Image"
        name="center_image"
        className="mb-6"
        optional
      >
        <FilePickerAdmin
          setSignedId={val => setFieldValue("center_image", { signedId: val })}
          attachment={{
            signedId: values.center_image?.signedId,
            metadata: values.center_image?.metadata,
            imageUrl: values.center_image?.url,
          }}
        />
      </FormBlock>
      <FormBlock label="Logo Image" name="logo_image" className="mb-6" optional>
        <FilePickerAdmin
          setSignedId={val => setFieldValue("logo_image", { signedId: val })}
          attachment={{
            signedId: values.logo_image?.signedId,
            metadata: values.logo_image?.metadata,
            imageUrl: values.logo_image?.url,
          }}
        />
      </FormBlock>
    </SectionGroup>
  );
}

export default Appeareance;
