import React from "react";
import clsx from "clsx";
import { Formik } from "formik-latest";
import { denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { useQuery } from "@tanstack/react-query";
import preferences from "@/components/pages/AccountSettingsPage/EmailNotificationsTab/preferences";
import FormBlock from "@/components/shared/FormBlock";
import Checkbox from "@/components/shared/Checkbox";

function Skeleton() {
  return (
    <div className="animate-pulse">
      {[1, 2, 3, 4, 5].map(index => (
        <div key={index} className="mb-8">
          <div className="bg-dark-25 rounded-full h-4 w-1/6 mb-2" />
          <div className="bg-dark-25 h-3 rounded-full w-2/4 mt-3 mb-5" />
          <div className="bg-dark-25 h-3 rounded-full w-2/4 mt-3 mb-5" />
          <div className="bg-dark-25 h-3 rounded-full w-2/4 mt-3 mb-5" />
        </div>
      ))}
    </div>
  );
}

const fetchEmailPreferences = () => {
  const params = paramBuilder({
    fields: {
      emailPreferences: [
        "notifyNewUserJoinedPici",
        "notifyNewAnnouncementPost",
        "notifyStaffAcountsChanges",
        "notifyNonStaffAcountsChanges",
        "notifyCenterMemberAcountsChanges",
        "notifyNewResearchDocumentUploaded",
        "notifyNewResourceCreated",
        "notifyDailyNewsFeed",
        "notifyNewInventionCreated",
        "notifyInventionReviewUpdated",
        "notifyMonthlyDigest",
        "notifyWeeklyIpSummary",
        "notifyAllClinicalUpdates",
        "notifyResearchDocumentDailyDigest",
        "notifyResearchDocumentComment",
        "notifyResearchDocumentOtherCommunications",
      ],
    },
  });

  const url = `/api/email-preferences/me?${params}`;
  return denormalizedFetcher(url);
};

function EmailPreferences({ user }) {
  const userId = user?.id;
  const {
    data,
    isLoading,
  }: {
    data: any;
    isLoading: boolean;
  } = useQuery(["emailPreferences", userId], fetchEmailPreferences);

  const preferencesInfo = preferences({
    centerId: user?.center,
    isStaff: true,
    centerNameOrInstitution: user?.centerNameOrInstitution || "",
  });

  return (
    <section className="gap-5.5 px-8">
      {isLoading ? (
        <Skeleton />
      ) : (
        <Formik
          initialValues={data?.data?.attributes}
          onSubmit={() => {}}
          enableReinitialize
        >
          <>
            {preferencesInfo.map(
              option =>
                option.visible && (
                  <div key={option.section}>
                    <h3 className="font-h3 text-dark-100">{option.section}</h3>
                    <div className="mt-3 mb-5 ">
                      {option.items.map(
                        item =>
                          !item.hidden && (
                            <FormBlock key={item.name} name={item.name}>
                              <Checkbox
                                disabled={true}
                                childrenClassname="text-dark-75 mp"
                                value={data?.data?.attributes[item.name]}
                              >
                                <span
                                  className={clsx(
                                    item.disabled &&
                                      "line-through !text-gray-300",
                                  )}
                                >
                                  {item.label}
                                </span>
                              </Checkbox>
                            </FormBlock>
                          ),
                      )}
                    </div>
                  </div>
                ),
            )}
          </>
        </Formik>
      )}
    </section>
  );
}

export default EmailPreferences;
