import * as React from "react";
import Hero from "../../shared/Hero";
import ChartPage from "../../Examples/ChartPage";
import InsightCard from "../../shared/InsightCard";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import SimpleAreaChart from "@/components/shared/Charts/SimpleAreaChart";
import PieChart from "@/components/shared/Charts/PieChart";
import HeaderCards from "../../shared/HeaderCard/HeaderCards";
import BubbleChart from "@/components/shared/Charts/BubbleChart";
import InsightLineChart from "@/components/shared/Charts/LinesChart";
import InsightScatterChart from "@/components/shared/Charts/ScatterChart/InsightScatterChart";
import InsightBarChart from "@/components/shared/Charts/BarChart/InsightBarChart";
import InsightSkeleton from "@/components/pages/InsightsPage/InsightPage/InsightSkeleton";

function Inventions() {
  const { data, isLoading } = useQuery(["inventionsInsights"], () =>
    fetcher("/api/inventions/insights").then(json => {
      return json;
    }),
  );
  const inventionsByIPTypeTotal = data?.inventions_by_IP_type_total || [];
  const dataLines = React.useMemo(
    () =>
      inventionsByIPTypeTotal.map(inventionByIp => ({
        dataKey: inventionByIp.id,
        dataLabel: inventionByIp.name,
        totalCount: inventionByIp.count,
      })),
    [inventionsByIPTypeTotal],
  );
  return (
    <div>
      <Hero
        title="Inventions"
        description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
              nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus
              omnis, molestiae esse placeat, exercitationem magnam quod
              molestias quia aspernatur venenatis diam."
      >
        <ChartPage />
      </Hero>
      {isLoading ? (
        <InsightSkeleton />
      ) : (
        <div className="margin-safe p-6 lg:p-10 lg:pb-10 min-h-full">
          <div className="masonry md:masonry-md xl:masonry-xl">
            <InsightCard size="sm">
              <HeaderCards
                title="Total Numbers of Inventions"
                subtitle={data?.inventions_size || "--"}
              />
              <SimpleAreaChart
                dataKey="name"
                data={data?.inventions_by_year || []}
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Inventions by Status" />
              <PieChart
                data={data?.inventions_by_status || []}
                dataKey="count"
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards
                title="Average Number of Inventors"
                titleClassname="mb-6"
              />
              <BubbleChart data={data?.average_number_of_inventors || []} />
            </InsightCard>
            <InsightCard size="sm">
              <HeaderCards
                title="Inventions by Status"
                titleClassname="mb-10"
              />
              <div className="mt-5">
                <InsightBarChart
                  dataKey="name"
                  data={data?.inventions_by_status_per_year || []}
                  multipleBarDataKeys={["pending", "accepted", "declined"]}
                  multipleBarDataLabels={["Pending", "Accepted", "Declined"]}
                  multipleBarColorLabels={["#3B82F6", "#047857", "#685BC7"]}
                  multipleBar
                />
              </div>
            </InsightCard>
            <InsightCard size="xmd">
              <HeaderCards title="Inventions by IP Type" />
              {data && (
                <InsightLineChart
                  data={data?.inventions_by_IP_type || []}
                  dataLines={dataLines}
                  xDataKey="created_year"
                />
              )}
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards title="Disclosure Date" titleClassname="mb-3.75" />
              <InsightScatterChart
                data={data?.inventions_by_disclosure_date || []}
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Inventions by Center" />
              <PieChart
                data={data?.inventions_by_center || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards title="Review Date" titleClassname="mb-3.75" />
              <InsightScatterChart
                data={data?.inventions_by_review_date || []}
              />
            </InsightCard>
          </div>
        </div>
      )}
    </div>
  );
}

export default Inventions;
