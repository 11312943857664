import { useState, useLayoutEffect } from "react";

// this window check is required because js-dom doesn't have support for
// browser scroll functions yet
const getElement = elementId =>
  document.querySelector(`#${elementId}`) || document.body;

const getElementScroll = elementId => getElement(elementId).scrollTop;

const getElementHeight = elementId => getElement(elementId).scrollHeight;

const isScrolledToBottom = (elementId, bottomGap) => {
  const totalHeight = getElementHeight(elementId);
  const scrolledHeight = getElementScroll(elementId);
  const visibleHeight = window.innerHeight;
  return scrolledHeight + visibleHeight >= totalHeight - bottomGap;
};

function useScrollPosition(elementId = "root", bottomGap = 100) {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleScroll = () => {
    setIsAtBottom(isScrolledToBottom(elementId, bottomGap));
  };

  useLayoutEffect(() => {
    const element = getElement(elementId);
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [setIsAtBottom]);

  return { isAtBottom };
}

export { useScrollPosition };
