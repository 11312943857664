import * as React from "react";
import clsx from "clsx";
import { Form, Formik } from "formik-latest";
import * as Yup from "yup";
import { fetcher } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCSRFToken from "@/hooks/useCSRFToken";
import useFlashMessage from "@/hooks/useFlashMessage";
import FilePicker from "@/components/shared/FilePicker";
import TextArea from "@/components/shared/TextArea";
import FormBlock from "@/components/shared/FormBlock";

const HelpRequestSchema = Yup.object().shape({
  detail: Yup.string()
    .required("Details are required.")
    .max(
      5000,
      "Details are too long. It should have 5000 characters or fewer.",
    ),
});

function MemberCenterHelpTab() {
  const currentUser = useCurrentUser();
  const { token } = useCSRFToken();

  const showFlash = useFlashMessage({
    message: "Thank you! Your message was sent.",
    errorMessage: "Your message could not be send due to an error.",
  });

  const helpRequest = (item, { setSubmitting, resetForm }) => {
    item.userId = currentUser?.id;
    const request = {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    };
    fetcher("/settings/help-request", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <div>
      <div className="md:border-b md:border-lines pb-5">
        <h2 className="hidden md:block text-dark-100 font-h2 mb-2">
          Member Center Help
        </h2>
        <p className="font-meta text-dark-75 max-w-xl">
          Let us know about something that's not working, or how we can improve.
          Complete this form or contact us directly at{" "}
          <a href="mailto:pici-admins@able.co" className="link-primary">
            pici-admins@able.co
          </a>
          .
        </p>
      </div>

      <div className="mt-0 md:mt-7.5 text-dark-75 font-body">
        <Formik
          initialValues={{ detail: "", file: "" }}
          onSubmit={helpRequest}
          validationSchema={HelpRequestSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, ...props }) => (
            <Form>
              <div className="space-y-5 sm:space-y-7.5">
                <div>
                  <FormBlock
                    label="Details"
                    name="detail"
                    labelClassName="font-body-bold"
                  >
                    <TextArea rows={4} />
                  </FormBlock>
                  <p className="font-meta text-dark-50 mt-2">
                    Please include as much information as possible
                  </p>
                </div>

                <FormBlock
                  label="Upload Screenshot"
                  name="file"
                  type="file"
                  optional
                  labelClassName="font-body-bold"
                >
                  <FilePicker />
                </FormBlock>

                <button
                  type="submit"
                  disabled={
                    !props.dirty || !props.isValid || props.isSubmitting
                  }
                  className={clsx(
                    "btn-lg btn-primary w-full md:w-auto",
                    (!props.dirty || !props.isValid || props.isSubmitting) &&
                      "bg-dark-50 hover:bg-dark-50 cursor-default",
                  )}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default MemberCenterHelpTab;
