import React, { useState, useMemo } from "react";
import { format, parseISO } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { denormalizedFetcher, fetcher, saveResource } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import SectionGroup from "@/components/shared/Admin/SectionGroup";
import ResultList from "@/components/shared/ResultList";
import SearchBar from "@/components/shared/SearchBar";
import useFlashMessage from "@/hooks/useFlashMessage";

const parsedDestinataries = destinataries => {
  if (!destinataries) return [];
  return destinataries.data.map(destinatary => ({
    id: destinatary.id,
    userId: destinatary.attributes.user.id,
    name: destinatary.attributes.user.full_name,
    email: destinatary.attributes.user.email,
  }));
};

const parsedManagers = users => {
  return users?.data.map(user => {
    return {
      id: user.id,
      name: user.attributes.fullName,
    };
  });
};

const fetchUsers = () => {
  const params = paramBuilder({
    fields: {
      users: ["fullName", "centerNameOrInstitution"],
    },
    preload: ["center", "institution"],
    exclude_links: true,
  });
  const url = `/api/users?${params}`;
  return denormalizedFetcher(url);
};

const fetchPatentDestinataries = () => {
  const url = "/api/patent-deadline-destinataries";
  return denormalizedFetcher(url);
};

function NotificationEmail({ upcomingDate }) {
  const queryClient = useQueryClient();
  const showFlash = useFlashMessage({
    message: "A sample email was sent to you.",
    errorMessage: "There was an error, please try later.",
  });

  const fetchSendSampleEmail = () =>
    fetcher("/api/inventions/send_patent_deadlines_sample")
      .then(_ => showFlash({ success: true }))
      .catch(_ => showFlash({ success: false }));

  const deleteDestinatary = destinataryId => {
    fetch(`/api/patent-deadline-destinataries/${destinataryId}`, {
      method: "DELETE",
    }).then(_ =>
      queryClient.invalidateQueries(["patent-deadline-destinataries"]),
    );
  };

  const saveDestinatary = userId => {
    saveResource("patent-deadline-destinataries", {
      data: {
        type: "patent-deadline-destinataries",
        attributes: { user_id: userId },
      },
    }).then(_ =>
      queryClient.invalidateQueries(["patent-deadline-destinataries"]),
    );
  };

  const { data: users }: { data: any; isLoading: boolean } = useQuery(
    ["users"],
    fetchUsers,
  );
  const { data: destinataries }: { data: any } = useQuery(
    ["patent-deadline-destinataries"],
    fetchPatentDestinataries,
  );

  const destinatariesOptions = parsedDestinataries(destinataries);
  const managerOptions = useMemo(() => parsedManagers(users), [users]);
  const [memberText, setMemberText] = useState<string>("");

  const onManagerSelect = value => {
    saveDestinatary(value.id);
    setMemberText("");
  };
  return (
    <>
      <SectionGroup title="Upcoming Regular Email Date" description="">
        <>
          <span>{`${upcomingDate &&
            format(parseISO(upcomingDate), "LLL d, YYY")}`}</span>
          <button
            type="button"
            className="btn-primary btn-sm font-meta-bold rounded-sm ml-3"
            onClick={fetchSendSampleEmail}
          >
            Send me a sample email now
          </button>
        </>
      </SectionGroup>
      <SectionGroup
        title="Destinatary Management"
        description=""
        className="border-t"
      >
        <SearchBar
          options={managerOptions || []}
          placeholder="Type member's name"
          accessor={item => item.name}
          onTextChange={item => setMemberText(item)}
          matchCriteria={(input, itemSearchable) =>
            itemSearchable
              .toLocaleLowerCase()
              .includes(input.toLocaleLowerCase())
          }
          onSelect={user => onManagerSelect(user)}
          selected={memberText}
        >
          {({ item }) => (
            <div className="px-6 py-4 cursor-pointer flex sm:space-x-5 hover:bg-gray-50 hover:text-indigo-500">
              <span className="font-body max-w-md">{item.name}</span>
            </div>
          )}
        </SearchBar>
        <ResultList
          list={destinatariesOptions}
          onDelete={destinatary => deleteDestinatary(destinatary.id)}
          attributeToShow="name"
          secondaryAttributeToShow="email"
        />
      </SectionGroup>
    </>
  );
}

export default NotificationEmail;
