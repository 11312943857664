import * as React from "react";
import { useEffect, useState } from "react";
import clsx from "clsx";
import * as R from "ramda";
import FormatBytes from "@/utils/formatBytes";
import useActiveStorage from "@/hooks/useActiveStorage";

function AttachmentDisplay({ metadata, borderClassName }) {
  return (
    <div
      className={clsx(
        "relative flex flex-col justify-center items-center p-7 w-full rounded-md",
        borderClassName,
      )}
    >
      <i className="ri-file-line text-38 leading-10	text-dark-25" />
      <p className="font-body text-dark-75">{metadata.filename}</p>
      <p className="font-micro text-dark-50">
        {FormatBytes(metadata.byteSize)}
      </p>
    </div>
  );
}

function FilePicker({
  attachment,
  value,
  onChange = _ => {},
  error = false,
  getStatus = _ => {},
  borderClassName = "bg-tint",
  allowedFilesTitle = "PNG, JPG, GIF up to 50MB",
}: {
  value?: string;
  attachment?: any;
  onChange?: (_: string) => void;
  error?: boolean;
  getStatus?: (_: string) => void;
  borderClassName?: string;
  allowedFilesTitle?: string;
}) {
  const [initialMetadata, setInitialMetadata] = useState(attachment);
  const {
    uploadFile,
    progress,
    metadata,
    file,
    status,
    setStatus,
  } = useActiveStorage({ value });

  //this will com in handy later
  const cleanFile = () => {
    setInitialMetadata(null);
    setStatus("initial");
    getStatus("");
    onChange("");
  };

  useEffect(() => {
    if (!R.isEmpty(metadata)) {
      getStatus(status);
      onChange(metadata.signedId);
    }
  }, [metadata]);

  const fileExists = (status === "uploaded" && file) || initialMetadata;

  return (
    <div
      className={clsx(
        "rounded-md relative",
        fileExists ? "" : "border-dashed border-2",
        error ? "border-error" : "border-dark-25",
      )}
      data-testid="file-picker"
    >
      {fileExists && (
        <i
          onClick={cleanFile}
          className="ri-close-line absolute right-5 top-5 text-dark-50 cursor-pointer z-10"
          data-testid="clean-file-button"
        />
      )}
      <label className="flex justify-center relative">
        <input
          type="file"
          onChange={uploadFile}
          className="w-full h-full opacity-0 absolute cursor-pointer"
        />
        {initialMetadata && status === "initial" ? (
          <AttachmentDisplay
            metadata={initialMetadata}
            borderClassName={borderClassName}
          />
        ) : (
          <>
            {status === "initial" && (
              <div className="flex flex-col justify-center items-center p-7">
                <i className="ri-image-line text-38 leading-10 text-dark-25"></i>
                <p className="font-body text-dark-75">
                  <span className="font-body-bold text-primary">
                    Upload a file
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="font-micro text-dark-50">{allowedFilesTitle}</p>
              </div>
            )}
            {status === "uploading" && (
              <div className="relative p-6.5 w-full">
                <div className="flex flex-col justify-center items-center px-7 pb-3.5">
                  <i className="ri-image-line text-38 leading-10 text-dark-25"></i>
                  <p className="font-micro text-dark-50">
                    Uploading your file...
                  </p>
                </div>
                <div className="overflow-hidden h-4 text-xs flex rounded bg-dark-25">
                  <div
                    style={{ width: `${progress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-secondary"
                  ></div>
                </div>
              </div>
            )}
            {status === "uploaded" && (
              <AttachmentDisplay
                metadata={metadata}
                borderClassName={borderClassName}
              />
            )}
          </>
        )}
      </label>
    </div>
  );
}

export default FilePicker;
