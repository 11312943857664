import * as React from "react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import AnnouncementTable from "./AnnouncementTable";
import AnnouncementForm from "./AnnouncementForm";
import AdminHeader from "@/components/shared/Admin/AdminHeader";

const fetchAnnouncements = () => {
  const baseUrl = "/api/announcements";
  const params = paramBuilder({
    fields: {
      announcements: [
        "subject",
        "userFullname",
        "userProfile",
        "readersData",
        "content",
        "createdAt",
        "isFeatured",
        "displaySubjectInContent",
        "imageUrl",
        "image",
        "imageMetadata",
        "sendAnnouncementDate",
      ],
    },
    sort: ["-createdAt"],
  });
  const url = `${baseUrl}?${params}`;
  return fetcher(url);
};

function AnnouncementsPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const { isLoading, data: announcements } = useQuery(
    ["announcements"],
    fetchAnnouncements,
  );
  const openForm = (announcement = null) => {
    setCurrentAnnouncement(announcement);
    setIsOpen(true);
  };

  return (
    <div className="bg-tint h-screen">
      <AdminHeader
        title="Announcements"
        description="Manage staff announcements."
        linkText="+ New Staff Announcement"
        linkTo={() => openForm()}
      />
      <article id="article-container" className="overflow-x-hidden h-vh-60">
        <div className="flex-1 h-full py-3.75 px-7.5 overflow-x-hidden bg-tint">
          {!isLoading && (
            <AnnouncementTable data={announcements.data} openForm={openForm} />
          )}
        </div>
      </article>

      <AnnouncementForm
        open={isOpen}
        setOpen={setIsOpen}
        announcement={currentAnnouncement}
      />
    </div>
  );
}

export default AnnouncementsPage;
