import * as React from "react";
import { FormikProps } from "formik-latest";
import clsx from "clsx";
import Input from "@/components/shared/Input";
import SlideOver from "@/components/shared/SlideOver";
import FormBlock from "@/components/shared/FormBlock";

function EventsSlider({
  formikProps,
  openForm,
  editing,
  resource,
  close,
  setShowArchiveModal,
  setShowDeleteModal,
}: {
  formikProps: FormikProps<{
    title: string;
    link: string;
    location: string;
    hostedAt: string;
  }>;
  openForm: boolean;
  setOpenForm: () => void;
  editing: boolean;
  setEditing: () => void;
  resource: any;
  close: () => void;
  setShowArchiveModal: (value: boolean) => void;
  setShowDeleteModal: (value: boolean) => void;
}) {
  const { isValid, isSubmitting, touched, values, submitForm } = formikProps;

  return (
    <>
      <SlideOver open={openForm} setOpen={close}>
        <SlideOver.Header>
          <div className="px-2">
            <h1 className="font-h2 font-medium text-dark-100">
              {editing ? "Edit" : "New"} Event
            </h1>
            <p className="font-micro text-dark-50 font-thin">
              Event information that is provided below will be displayed on the
              Member Center Dashboard.
            </p>
          </div>
        </SlideOver.Header>
        <SlideOver.Body className="font-lato flex flex-col gap-5 !px-8">
          <FormBlock
            label="Title"
            name="title"
            labelClassName="font-body-bold text-dark-75"
          >
            <Input
              className={clsx(
                "block w-full py-9px px-3.5 h-10 rounded-3px text-15 mt-2.5",
                "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75",
              )}
              value={values.title}
            />
          </FormBlock>
          <FormBlock
            label="Date(s)"
            name="hostedAt"
            labelClassName="font-body-bold text-dark-75"
            optional
          >
            <Input
              className={clsx(
                "block w-full py-9px px-3.5 h-10 rounded-3px text-15 mt-2.5",
                "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75",
              )}
              value={values.hostedAt}
            />
          </FormBlock>
          <FormBlock
            label="Location"
            name="location"
            labelClassName="font-body-bold text-dark-75"
            optional
          >
            <Input
              className={clsx(
                "block w-full py-9px px-3.5 h-10 rounded-3px text-15 mt-2.5",
                "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75",
              )}
              value={values.location}
            />
          </FormBlock>
          <FormBlock
            label="Link"
            name="link"
            labelClassName="font-body-bold text-dark-75"
            optional
          >
            <Input
              placeholder="e.g. http://www.google.com"
              className={clsx(
                "block w-full py-9px px-3.5 h-10 rounded-3px text-15 mt-2.5",
                "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75",
              )}
              value={values.link}
            />
          </FormBlock>
        </SlideOver.Body>
        <SlideOver.Footer className="flex justify-start !px-8">
          <button
            className={clsx(
              "btn-sm btn-primary rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium py-9px px-7.5 font-lato",
              {
                "bg-primary text-white hover:border-primary-darker hover:bg-primary-darker active:bg-primary-darkest": isValid,
                "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none":
                  isSubmitting ||
                  !isValid ||
                  Object.entries(touched).length === 0,
              },
            )}
            onClick={submitForm}
            type="button"
            data-cy="submit"
          >
            {editing ? "Save changes" : "Add Event"}
          </button>
          <button
            className={clsx(
              "border border-lines rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
              "bg-tint text-dark-75 hover:bg-white py-9px px-7.5 font-lato active:bg-lines",
            )}
            type="button"
            onClick={() => close()}
          >
            Cancel
          </button>
          <div className="flex-grow" />
          {editing && (
            <>
              <button
                className={clsx(
                  "border border-primary rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
                  "bg-white text-primary hover:bg-primary hover:text-white py-9px px-7.5 font-lato active:bg-lines transition-colors duration-250 delay-75",
                )}
                type="button"
                onClick={() => setShowArchiveModal(true)}
              >
                {resource.attributes.archived ? "Active" : "Archive"}
              </button>
              <button
                className={clsx(
                  "border border-lines rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
                  "bg-tint text-dark-75 hover:bg-white py-9px px-7.5 font-lato active:bg-lines",
                )}
                type="button"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </button>
            </>
          )}
        </SlideOver.Footer>
      </SlideOver>
    </>
  );
}

export default EventsSlider;
