import * as R from "ramda";

const ResearchResource = (research, users, focusAreasOptions) => {
  const focusAreasNames = research.relationships.focusAreas.data.map(
    focusArea => focusArea.attributes.name,
  );
  const internalUsers = research.attributes.sortedAuthors.filter(
    user => user.type === "users",
  );
  const externalUsers = research.attributes.sortedAuthors.filter(
    user => user.type === "externalUsers",
  );
  const internalAuthors = users.filter(
    user =>
      R.find(R.propEq("id", user.id))(internalUsers) && user.external == false,
  );
  const externalAuthors = users.filter(
    user =>
      R.find(R.propEq("id", user.id))(externalUsers) && user.external == true,
  );
  const focusAreas = focusAreasOptions.filter(focusArea =>
    focusAreasNames.includes(focusArea.name),
  );
  const relatedProjects = research.attributes.projects;

  return {
    ...research.attributes,
    internalAuthors,
    externalAuthors,
    focusAreas,
    relatedProjects,
  };
};

export default ResearchResource;
