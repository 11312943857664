import { useQuery } from "@tanstack/react-query";
import fetch from "cross-fetch";

function fetchCurrentUser() {
  const fields: string[] = [
    "id",
    "email",
    "fullName",
    "role",
    "avatarUrl",
    "avatar",
    "slug",
    "isStaff",
    "isLegal",
    "isBoardMember",
    "firstName",
    "lastName",
    "phoneNumber",
    "centerId",
    "centerNameOrInstitution",
    "googleScholarUrl",
    "cvOrBiosketch",
    "guidedTourCompleted",
    "didCloseBanner",
    "digestedJobTitle",
    "type",
  ];
  const options = "&preload=none";
  return fetch(
    `/api/users/me?fields[users]=${fields.join(",")}${options}`,
  ).then(res => {
    if (res?.ok) return res.json();
  });
}

function useCurrentUser() {
  const { data } = useQuery(["current-user"], fetchCurrentUser);

  return data?.data;
}

export default useCurrentUser;
