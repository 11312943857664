import * as React from "react";
import { useSlate } from "slate-react";
import { Editor, Transforms, Element as SlateElement } from "slate";
import {
  BoldIcon,
  UnderlinedIcon,
  ItalicIcon,
  ListIcon,
  OrderListIcon,
} from "@/components/shared/RichTextEditor/icons";

const LIST_TYPES = ["numbered-list", "bulleted-list", "list-number"];

const renderElement = ({ attributes, children, element }) => {
  switch (element.type) {
    case "bulleted-list":
      return (
        <ul {...attributes} className="list-disc list-inside">
          {children}
        </ul>
      );
    case "list-item":
      return (
        <li {...attributes} className="text-sm leading-6">
          {children}
        </li>
      );
    case "list-number":
      return (
        <ol {...attributes} className="list-decimal list-outside">
          {children}
        </ol>
      );
    default:
      return null;
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
      ),
    split: true,
  });

  let newType = isList ? "list-item" : format;
  newType = isActive ? "paragraph" : newType;

  const newProperties = { type: newType };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

function BlockButton({ format, children, ...props }) {
  const editor = useSlate();

  return (
    <button
      type="button"
      onMouseDown={() => toggleBlock(editor, format)}
      {...props}
    >
      {children}
    </button>
  );
}

function MarkButton({ format, children, ...props }) {
  const editor = useSlate();

  return (
    <button
      type="button"
      onMouseDown={() => toggleMark(editor, format)}
      {...props}
    >
      {children}
    </button>
  );
}

function BoldButton({ ...props }) {
  return (
    <MarkButton format="bold" {...props}>
      <BoldIcon />
    </MarkButton>
  );
}

function ItalicButton({ ...props }) {
  return (
    <MarkButton format="italic" {...props}>
      <ItalicIcon />
    </MarkButton>
  );
}

function UnderlinedButton({ ...props }) {
  return (
    <MarkButton format="underline" {...props}>
      <UnderlinedIcon />
    </MarkButton>
  );
}

function BulletedListButton({ ...props }) {
  return (
    <BlockButton format="bulleted-list" {...props}>
      <ListIcon />
    </BlockButton>
  );
}

function NumberListButton({ ...props }) {
  return (
    <BlockButton format="list-number" {...props}>
      <OrderListIcon />
    </BlockButton>
  );
}

const Formattable = {
  actions: [
    {
      name: "Bold",
      Button: props => <BoldButton {...props} />,
      hotkey: { "mod+b": editor => toggleMark(editor, "bold") },
      isActive: editor => isMarkActive(editor, "bold"),
    },
    {
      name: "Italic",
      Button: props => <ItalicButton {...props} />,
      hotkey: { "mod+i": editor => toggleMark(editor, "italic") },
      isActive: editor => isMarkActive(editor, "italic"),
    },
    {
      name: "Underline",
      Button: props => <UnderlinedButton {...props} />,
      hotkey: { "mod+u": editor => toggleMark(editor, "underline") },
      isActive: editor => isMarkActive(editor, "underline"),
    },
    {
      name: "List",
      Button: props => <BulletedListButton {...props} />,
      isActive: editor => isBlockActive(editor, "underline"),
    },
    {
      name: "Order List",
      Button: props => <NumberListButton {...props} />,
      isActive: editor => isBlockActive(editor, "underline"),
    },
  ],
  renderElement,
};

export default Formattable;
