import * as React from "react";
import { useContext, createContext, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";

const ModalContext = createContext({
  setOpen: (_a: boolean) => {},
});

function Modal({
  open,
  setOpen,
  children,
  className,
}: {
  open: boolean;
  setOpen: any;
  children: any;
  className?: string;
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        open={open}
        onClose={setOpen}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-4 text-center h-full sm:h-auto sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                "inline-block align-middle bg-white rounded-lg text-left shadow-xl transition-all sm:my-8 overflow-hidden",
                className ? className : "h-full w-full min-w-min max-w-2xl ",
              )}
            >
              <div className="flex flex-col p-7.5 h-full sm:h-auto">
                <ModalContext.Provider value={{ setOpen: setOpen }}>
                  {children}
                </ModalContext.Provider>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.Header = ({ children, className = "" }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setOpen } = useContext(ModalContext);

  return (
    <div
      className={clsx(
        "flex items-center justify-between pb-4 sm:pb-6",
        className,
      )}
    >
      <h2 className="font-h2 text-dark-100">{children}</h2>
      <button
        type="button"
        onClick={() => setOpen(false)}
        className="text-dark-75 focus:outline-none"
        data-testid="closeActionButton"
      >
        <i className="ri-close-line ri-lg" />
      </button>
    </div>
  );
};

Modal.Body = ({ children, className = "" }) => {
  return (
    <div
      className={clsx("h-full overflow-y-auto overflow-x-hidden", className)}
    >
      {children}
    </div>
  );
};

Modal.Footer = ({ children, className = "" }) => {
  return <div className={clsx(className, "pt-3")}>{children}</div>;
};
export default Modal;
