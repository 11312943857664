import React, { useRef, useState, useLayoutEffect } from "react";
import useRerender from "@/hooks/useRerender";

const degToRad = degrees => {
  return degrees * (Math.PI / 180);
};

function createCustomizedLabel(chartWidth) {
  return React.memo(function({
    cx,
    cy,
    midAngle,
    outerRadius,
    payload,
    dataKey = "name",
  }: {
    cx?: any;
    cy?: any;
    midAngle?: any;
    outerRadius?: any;
    payload?: any;
    dataKey?: any;
  }) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef();

    const linePadding = 8;
    const dy = 12;
    const x = cx + outerRadius * Math.cos(degToRad(-midAngle));
    const y = cy + outerRadius * Math.sin(degToRad(-midAngle));
    const leftSide = midAngle > 90 && midAngle <= 270;
    const textX = leftSide ? 0 : chartWidth;

    useLayoutEffect(() => {
      const initialBbox = textRef.current?.getBBox();
      const initialLineX =
        textX + (leftSide ? 1 : -1) * (initialBbox?.width + linePadding);
      setIsOverflowing(leftSide ? initialLineX > x : initialLineX < x);
    }, []);

    useRerender();

    const bbox = textRef.current?.getBBox();
    const lineX = textX + (leftSide ? 1 : -1) * (bbox?.width + linePadding);
    const label = isOverflowing
      ? payload[dataKey].split(" ")
      : [payload[dataKey]];
    const labelY = y - dy * (0.5 + label.length / 2);

    return (
      <g>
        {bbox && (
          <line
            x1={x}
            y1={y}
            x2={lineX}
            y2={y}
            className="stroke-current text-dark-25"
          />
        )}
        <text
          x={textX}
          y={labelY}
          dominantBaseline="central"
          ref={textRef}
          textAnchor={leftSide ? "start" : "end"}
          className="font-label text-dark-75"
        >
          {label.map((part, index) => {
            const labelText =
              part.length > 20 ? `${part.slice(0, 15)}...` : part;
            return (
              <tspan key={index} x={textX} dy={dy}>
                {labelText}
              </tspan>
            );
          })}
        </text>
      </g>
    );
  });
}

export default createCustomizedLabel;
