function computeTotal(data) {
  return data.reduce((prev, current) => {
    Object.entries(current).forEach(([key, value]) => {
      if (prev[key] === undefined) {
        prev[key] = 0;
      }
      prev[key] += value;
    });
    return prev;
  }, {});
}

export default computeTotal;
