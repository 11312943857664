import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import authorsToDisplay from "@/utils/authorsToDisplay";
import { denormalizedFetcher } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import Table from "@/components/shared/Table";
import AuthorList from "@/components/shared/AuthorList";

const fetchResearchDocuments = ({ queryKey = [] }) => {
  const [_, userId] = queryKey;
  const baseUrl = `/api/research-documents`;
  const params = paramBuilder({
    preload: ["authors"],
    include: ["authors"],
    fields: {
      researchDocuments: [
        "title",
        "status",
        "createdAt",
        "authors",
        "sortedAuthors",
        "readableType",
        "publication",
        "publicationLink",
      ],
      users: ["fullName", "slug", "projectsCount"],
    },
    filter: { userId: [userId] },
  });

  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

const LoadingResearchs = () => {
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Title</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {[1, 2].map(x => (
          <Table.Row key={x}>
            <Table.Cell expand={false}>
              <div className="flex md:flex-col lg:flex-row gap-5 animate-pulse">
                <div className="flex flex-col sm:flex-row gap-5 flex-grow">
                  <div className="flex-grow flex flex-col gap-2">
                    <div className="h-5 rounded-full w-full bg-dark-25 mt-1.5" />
                    <div className="bg-dark-25 h-5 w-3/4 rounded-full" />
                    <div className="bg-dark-25 h-4 w-1/2 rounded-full" />
                    <div className="bg-dark-25 h-4 w-20 rounded-full" />
                  </div>
                  <div className="sm:self-center">
                    <div className="h-9 w-28 bg-dark-25 rounded-md" />
                  </div>
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const ResearchRow = research => {
  const { title, status, createdAt, sortedAuthors } = research.attributes;
  const formattedUptateDate = format(new Date(createdAt), "MMM dd, yyyy");
  const authors = research.relationships.authors?.data;

  return (
    <Table.Row>
      <Table.Cell>
        <div className="whitespace-normal w-[624px] md:w-auto max-w-[624px] flex flex-col">
          <h3>
            <Link to={research.links.show} className="font-h3 link-primary">
              {title}
            </Link>
          </h3>
          {sortedAuthors.length > 0 && (
            <p className="font-meta text-dark-75 mt-1">
              <AuthorList authors={authorsToDisplay(sortedAuthors, authors)} />
            </p>
          )}
          <span
            className={clsx(
              "mt-2.5 inline-flex items-center px-3 py-0.5 rounded-full uppercase font-label w-min whitespace-nowrap",
              status === "Published or presented"
                ? "bg-secondary-light text-secondary-dark"
                : "bg-lines text-dark-75",
            )}
          >
            {status}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell
        expand={false}
        className="font-meta text-dark-75 text-center whitespace-nowrap"
      >
        {formattedUptateDate}
      </Table.Cell>
    </Table.Row>
  );
};

function ResearchTab() {
  const currentUser = useCurrentUser();
  const {
    isLoading,
    data: userResearch,
  }: { data: any; isLoading: boolean } = useQuery(
    ["userResearchDocuments", currentUser?.id],
    fetchResearchDocuments,
  );

  return (
    <>
      <div className="flex justify-between gap-2.5 items-center pb-5 md:border-b md:border-lines">
        <h2 className="hidden md:block text-dark-100 font-h2">Research</h2>
        <Link
          to="/research_documents/new"
          className="flex gap-2.5 btn-primary btn-md"
        >
          <i className="ri-add-line"></i>
          New Research
        </Link>
      </div>
      <div className="mt-0 md:mt-7.5 text-dark-75 font-body">
        {isLoading ? (
          <LoadingResearchs />
        ) : (
          <>
            {userResearch?.data.length > 0 ? (
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell className="text-center whitespace-nowrap">
                    Date published
                  </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {userResearch.data.map(research => (
                    <ResearchRow key={research.id} {...research} />
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <article className="bg-tint rounded-lg flex flex-col items-center text-center py-24 px-6">
                <i className="ri-article-line ri-3x leading-none text-dark-25" />
                <p className="mt-8 font-body text-dark-75">
                  You don't have any research documents yet.
                </p>
              </article>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default ResearchTab;
