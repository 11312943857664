import * as React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import * as Yup from "yup";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useQueryClient } from "@tanstack/react-query";
import { Formik, Form } from "formik-latest";
import { saveResource } from "@/utils/api";
import useFlashMessage from "@/hooks/useFlashMessage";
import UserOnboarding from "@/components/shared/OnboardingLayout";
import FormBlock from "@/components/shared/FormBlock";
import FilePicker from "@/components/shared/FilePicker";
import Input from "@/components/shared/Input";

const ProfessionalInfoSchema = Yup.object().shape({
  googleScholarUrl: Yup.string()
    .trim()
    .matches(
      /^https?:\/\/scholar\.google\.com\/citations\?(\w+=[\w\-]+&)*user=[\w\-]+(&\w+=[\w\-]+)*$/,
      "Enter a valid Google Scholar profile URL.",
    ),
});

const mapRequest = (values, id = null) => {
  return {
    data: {
      id: id,
      type: "users",
      attributes: {
        googleScholarUrl: values.googleScholarUrl,
        cvOrBiosketch: values.cvOrBiosketch,
      },
    },
  };
};

const mapInitialValues = user => {
  return {
    googleScholarUrl: user.attributes.googleScholarUrl || "",
    cvOrBiosketch: user.attributes.cvOrBiosketch || null,
  };
};

function OnboardingProfessional() {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const showFlash = useFlashMessage({
    message: "",
    errorMessage: "Your changes could not be saved due to an error.",
  });

  const onSubmit = (item, { setSubmitting }) => {
    const request = mapRequest(item, currentUser?.id);
    saveResource("users", request)
      .then(res => {
        if (!res.ok) showFlash({ success: false });
      })
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        queryClient.invalidateQueries(["users", currentUser?.id]);
        navigate("/");
      });
  };

  return (
    <UserOnboarding>
      <UserOnboarding.Header>
        <h2 className="pt-0.25 text-dark-100 font-h2 pb-2">
          Professional Background
        </h2>
        <p className="font-body text-dark-75">
          Provide details about your background and experience. This information
          will allow other PICI members to discover you based on your experience
          and areas of expertise.
        </p>
      </UserOnboarding.Header>
      <UserOnboarding.Body>
        <Formik
          initialValues={mapInitialValues(currentUser)}
          onSubmit={onSubmit}
          validationSchema={ProfessionalInfoSchema}
          enableReinitialize
        >
          {({ isSubmitting, isValid }) => {
            const isDisabled = isSubmitting || !isValid;
            return (
              <Form>
                <div className="flex flex-wrap gap-x-2.5 gap-y-5 md:gap-y-6">
                  <div className="w-full">
                    <FormBlock
                      label="Google Scholar"
                      name="googleScholarUrl"
                      optional
                      labelClassName="font-body-bold"
                    >
                      <Input />
                    </FormBlock>
                  </div>
                  <div className="w-full">
                    <FormBlock
                      label="CV or Biosketch"
                      name="cvOrBiosketch"
                      type="file"
                      optional
                      labelClassName="font-body-bold"
                    >
                      <FilePicker
                        attachment={
                          currentUser.attributes.cvOrBiosketchMetadata
                        }
                        borderClassName="border-dashed border-2 bg-white"
                        allowedFilesTitle="PDF, PNG, JPG, GIF up to 50MB"
                      />
                    </FormBlock>
                  </div>
                  <button
                    className={clsx(
                      "btn-xl btn-primary font-lato w-full h-9.5 mt-3.5",
                      {
                        "btn-disabled": isDisabled,
                      },
                    )}
                    type="submit"
                    disabled={isDisabled}
                  >
                    I'm Done
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </UserOnboarding.Body>
    </UserOnboarding>
  );
}

export default OnboardingProfessional;
