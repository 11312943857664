import * as React from "react";
import Hero from "../../shared/Hero";
import ChartPage from "../../Examples/ChartPage";
import InsightCard from "../../shared/InsightCard";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import SimpleAreaChart from "@/components/shared/Charts/SimpleAreaChart";
import PieChart from "@/components/shared/Charts/PieChart";
import InsightBarChart from "@/components/shared/Charts/BarChart/InsightBarChart";
import HeaderCards from "../../shared/HeaderCard/HeaderCards";
import InsightLineChart from "@/components/shared/Charts/LinesChart";
import InsightSkeleton from "@/components/pages/InsightsPage/InsightPage/InsightSkeleton";

const ROLES = {
  investigator_full: {
    text: "Investigator Full",
    color: "#685BC7",
    size: 0,
  },
  investigator_project: {
    text: "Investigator Project",
    color: "#FBBF24",
    size: 0,
  },
  center_admin: {
    text: "Center Admin",
    color: "#17B583",
    size: 0,
  },
  pici_admin: {
    text: "Pici Admin",
    color: "#ff966b",
    size: 0,
  },
  employee: {
    text: "Employees",
    color: "#3B82F6",
    size: 0,
  },
  guest: {
    text: "Guests",
    color: "#4D90B7",
    size: 0,
  },
};

const DATE_JOINED = {
  "1M": {
    text: "This Month",
    color: "#f37a7a",
    size: 0,
  },
  "3M": { text: "Last 1-3 Months", color: "#ff966b", size: 0 },
  YTD: { text: "Last 3-12 Months", color: "#0277bd", size: 0 },
  "1Y": { text: "1-2 Years", color: "#3ab5ae", size: 0 },
  "2Y+": { text: "2+ Years", color: "#014975", size: 0 },
};

const RoleInformation = ({ information, membersSize }) => {
  const percentage = (information.size * 100) / membersSize;
  return (
    <li style={{ color: information.color }} className="font-body-bold mb-2">
      <div className="flex text-black">
        <header className="flex-1">
          <h4>{information.text}</h4>
          <p className="font-meta">{information.size} members</p>
        </header>
        <div>{percentage.toFixed(1)} %</div>
      </div>
    </li>
  );
};

const DateJoinedInformation = ({ information }) => {
  return (
    <li style={{ color: information.color }} className="font-body-bold mb-2">
      <div className="flex text-black">
        <header className="flex-1">
          <h4>{information.text}</h4>
        </header>
        <div>{information.size}</div>
      </div>
    </li>
  );
};

const CustomProjectTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { name, count } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          <span className="font-bold">{count}</span> Projects with{" "}
          <span className="font-bold">{name}</span> members
        </p>
      </div>
    );
  }
  return null;
};

const CustomInventionTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { name, count } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          <span className="font-bold">{count}</span> Inventions with{" "}
          <span className="font-bold">{name}</span> members
        </p>
      </div>
    );
  }
  return null;
};
const dataLinesMembersByRole = [
  {
    dataKey: "investigator_full",
    dataLabel: "Investigator Full",
    color: ROLES["investigator_full"].color,
  },
  {
    dataKey: "investigator_project",
    dataLabel: "Investigator Project",
    color: ROLES["investigator_project"].color,
  },
  {
    dataKey: "center_admin",
    dataLabel: "Center Admin",
    color: ROLES["center_admin"].color,
  },
  {
    dataKey: "pici_admin",
    dataLabel: "Pici Admin",
    color: ROLES["pici_admin"].color,
  },
  {
    dataKey: "employee",
    dataLabel: "Employee",
    color: ROLES["employee"].color,
  },
  {
    dataKey: "guest",
    dataLabel: "Gues",
    color: ROLES["guest"].color,
  },
];

function Members() {
  const { data, isLoading } = useQuery(["membersInsights"], () =>
    fetcher("/api/users/insights").then(json => {
      return json;
    }),
  );

  const membersByRole = data?.members_by_role || [];
  const membersByDateJoined = data?.members_by_date_joined || [];

  const membersByRoleInfo = React.useMemo(() => {
    const keys = Object.keys(ROLES);
    return membersByRole.reduce((acc, curr) => {
      keys.forEach(rol => {
        acc[rol].size = curr[rol] + acc[rol].size;
      });
      return acc;
    }, ROLES);
  }, [membersByRole]);

  const membersByDateJoinedInfo = React.useMemo(() => {
    const information = DATE_JOINED;
    membersByDateJoined.forEach(
      info => (information[info.name].size = info.count),
    );
    return information;
  }, [membersByDateJoined]);

  return (
    <div>
      <Hero
        title="Members"
        description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
              nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus
              omnis, molestiae esse placeat, exercitationem magnam quod
              molestias quia aspernatur venenatis diam."
      >
        <ChartPage />
      </Hero>
      {isLoading ? (
        <InsightSkeleton />
      ) : (
        <div className="margin-safe p-6 lg:p-10 lg:pb-10 min-h-full">
          <div className="masonry md:masonry-md xl:masonry-xl">
            <InsightCard size="sm">
              <HeaderCards
                title="Total Numbers of Members"
                subtitle={data?.members_size || "--"}
              />
              <SimpleAreaChart
                dataKey="name"
                data={data?.members_by_year || []}
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Members by Focus Area" />
              <PieChart
                data={data?.members_by_focus_area || []}
                dataKey="count"
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards
                title="Member Invention Involvement"
                titleClassname="mb-4"
              />
              <InsightBarChart
                data={data?.members_by_invention}
                dataKey="name"
                tooltip={CustomInventionTooltip}
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards
                title="Member Project Involvement"
                titleClassname="mb-4"
              />
              <InsightBarChart
                data={data?.members_by_project}
                dataKey="name"
                tooltip={CustomProjectTooltip}
              />
            </InsightCard>
            <InsightCard size="xxl">
              <HeaderCards title="Member by Role" titleClassname="mb-4" />
              {membersByRoleInfo && (
                <ul className="list-disc ml-4 mb-6">
                  {Object.entries(membersByRoleInfo).map((entry, index) => {
                    const [_, info] = entry;
                    return (
                      <RoleInformation
                        key={index}
                        information={info}
                        membersSize={data?.members_size}
                      />
                    );
                  })}
                </ul>
              )}
              <InsightLineChart
                data={data?.members_by_role}
                showLegend={false}
                dataLines={dataLinesMembersByRole}
                xDataKey="created_year"
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Member Per Center" />
              <PieChart
                data={data?.members_by_center || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>

            <InsightCard size="l">
              <HeaderCards
                title="Member by Date Joined"
                titleClassname="mb-4"
              />
              <ul className="list-disc ml-4 mb-6">
                {Object.entries(membersByDateJoinedInfo).map((entry, index) => {
                  const [_, info] = entry;
                  return (
                    <DateJoinedInformation key={index} information={info} />
                  );
                })}
              </ul>
              <InsightBarChart
                data={data?.members_by_date_joined}
                dataKey="name"
                colors={DATE_JOINED}
              />
            </InsightCard>
          </div>
        </div>
      )}
    </div>
  );
}

export default Members;
