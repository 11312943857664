import * as React from "react";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import clsx from "clsx";
import { Link } from "react-router-dom";
import ResearchCardSkeleton from "./ResearchCardSkeleton";
import AuthorList from "@/components/shared/AuthorList";
import authorsToDisplay from "@/utils/authorsToDisplay";
import NoResults from "@/components/shared/NoResults";

function ResearchCard({ research, widthTitle }) {
  const {
    title,
    status,
    readableType,
    createdAt,
    publication,
    publicationLink,
    sortedAuthors,
  } = research.attributes;

  const formattedUptateDate = format(new Date(createdAt), "MMM dd, yyyy");
  const authors = research.relationships.authors?.data;
  return (
    <>
      <Table.Row>
        <Table.Cell expand={false}>
          <div className="max-w-lg" style={{ minWidth: widthTitle }}>
            <h3 className="mb-1">
              <Link to={research.links.show} className="link-primary font-h3">
                {title}
              </Link>
            </h3>
            {sortedAuthors.length > 0 && (
              <p className="font-meta text-dark-75 mb-2.5">
                <AuthorList
                  authors={authorsToDisplay(sortedAuthors, authors)}
                />
              </p>
            )}
            <span
              className={clsx(
                "inline-flex items-center px-3 py-0.5 rounded-full uppercase font-label",
                status === "Published or presented"
                  ? "bg-secondary-light text-secondary-dark"
                  : "bg-lines text-dark-75",
              )}
            >
              {status}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell expand={false} style={{ minWidth: 144 }}>
          <div className="font-meta text-dark-75 w-24 truncate">
            {publication ? (
              <a
                href={publicationLink}
                target="_blank"
                className="link truncate"
              >
                {publication}
              </a>
            ) : (
              <span>---</span>
            )}
          </div>
        </Table.Cell>
        <Table.Cell className="font-meta text-dark-75">
          {readableType}
        </Table.Cell>
        <Table.Cell
          expand={false}
          className="font-meta text-dark-75 text-right"
          style={{ minWidth: 160 }}
        >
          {formattedUptateDate}
        </Table.Cell>
      </Table.Row>
    </>
  );
}

function ResearchTable({
  researchDocuments,
  isFetching,
  isFetchingNextPage,
  widthTitle,
}) {
  return (
    <>
      {!isFetching && researchDocuments.length === 0 ? (
        <NoResults entityName="research documents" icon="ri-search-line" />
      ) : (
        <Table>
          <Table.Header>
            <Table.HeaderCell>title</Table.HeaderCell>
            <Table.HeaderCell>publication</Table.HeaderCell>
            <Table.HeaderCell>type</Table.HeaderCell>
            <Table.HeaderCell className="text-right">
              date uploaded
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {researchDocuments.length > 0 &&
              researchDocuments.map(researchDocument => (
                <ResearchCard
                  widthTitle={widthTitle}
                  key={researchDocument.id}
                  research={researchDocument}
                />
              ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => (
                <ResearchCardSkeleton key={x} widthTitle={widthTitle} />
              ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default ResearchTable;
