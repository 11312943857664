import React from "react";

const Row = () => (
  <tr className="divide-x divide-lines">
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 !whitespace-normal font-bold text-dark-75 max-w-[240px]">
      <div className="h-3 w-[200px] bg-slate-200 bg-dark-25 rounded mb-2"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-3 w-[90px] bg-slate-200 bg-dark-25 rounded"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-3 w-[90px] bg-slate-200 bg-dark-25 rounded"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-3 w-[90px] bg-slate-200 bg-dark-25 rounded"></div>
    </td>
  </tr>
);

function Skeleton() {
  return (
    <>
      <div className="max-w-full overflow-x-auto border border-transparent text-sm  mb-8 px-5 mt-4">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden">
            <table className="w-full">
              <thead className="bg-transparent text-dark-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium uppercase tracking-wider pt-1 pl-4 text-11"
                  >
                    Inventor
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium uppercase tracking-wider pt-1 pl-4 text-11"
                  >
                    Number of Inventions
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium uppercase tracking-wider pt-1 pl-4 text-11"
                  >
                    Inventions
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left font-medium uppercase tracking-wider pt-1 pl-4 text-11"
                  >
                    Center/Institution
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white text-dark-75 divide-y divide-lines border border-lines">
                <Row />
                <Row />
                <Row />
                <Row />
                <Row />
                <Row />
                <Row />
                <Row />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skeleton;
