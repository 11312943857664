import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import useCurrentUser from "@/hooks/useCurrentUser";
import paramBuilder from "@/utils/network/paramBuilder";
import { fetcher } from "@/utils/api";
import Table from "@/components/shared/Table";
import { Link } from "react-router-dom";

const fetchPublications = ({ queryKey }) => {
  const [_, userId] = queryKey;
  const baseUrl = `/api/publications`;
  const params = paramBuilder({
    filter: { userId: [userId] },
  });

  const url = `${baseUrl}?${params}`;
  return fetcher(url);
};

const LoadingPublications = () => {
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell className="w-96">Title</Table.HeaderCell>
        <Table.HeaderCell className="text-center whitespace-nowrap">
          publication
        </Table.HeaderCell>
        <Table.HeaderCell className="text-center whitespace-nowrap">
          year
        </Table.HeaderCell>
        <Table.HeaderCell children={""} />
      </Table.Header>
      <Table.Body>
        {[1, 2].map(x => (
          <Table.Row key={x}>
            <Table.Cell>
              <div className="w-96 flex md:flex-col lg:flex-row gap-5 animate-pulse">
                <div className="flex flex-col sm:flex-row gap-5 flex-grow">
                  <div className="flex-grow flex flex-col gap-2">
                    <div className="h-5 rounded-full w-full bg-dark-25 mt-1.5" />
                    <div className="bg-dark-25 h-5 w-3/4 rounded-full" />
                    <div className="bg-dark-25 h-4 w-1/2 rounded-full" />
                    <div className="bg-dark-25 h-4 w-20 rounded-full" />
                  </div>
                </div>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="bg-dark-25 h-5 w-24 rounded-full" />
            </Table.Cell>
            <Table.Cell>
              <div className="bg-dark-25 h-5 w-24 rounded-full" />
            </Table.Cell>
            <Table.Cell>
              <div className="bg-tint h-5 w-16 rounded-full" />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const PublicationRow = ({ publication, fullName }) => {
  const {
    title,
    authors,
    journal,
    link,
    publicationYear,
  } = publication.attributes;
  const { edit } = publication.links;
  return (
    <Table.Row>
      <Table.Cell>
        <div className="whitespace-normal flex flex-col gap-[5px] min-w-[250px]">
          {link ? (
            <a
              href={link}
              target="_blank"
              className="font-h3 text-primary-dark hover:underline"
            >
              {title}
            </a>
          ) : (
            <h3 className="font-h3 text-primary-dark">{title}</h3>
          )}
          <p className="font-meta text-dark-75">
            {authors && (
              <>
                {authors}
                <span className="text-dark-50"> and </span>
              </>
            )}
            {fullName}
            <span className="text-dark-50">
              {authors ? " are authors." : " is the author."}
            </span>
          </p>
        </div>
      </Table.Cell>
      <Table.Cell
        expand={false}
        className="font-meta text-dark-75 italic text-center max-w-[91px] break-words"
      >
        {journal ? journal : "---"}
      </Table.Cell>
      <Table.Cell
        expand={false}
        className="font-meta text-dark-75 text-center whitespace-nowrap w-min"
      >
        {publicationYear ? publicationYear : "---"}
      </Table.Cell>
      <Table.Cell>
        <Link to={edit} className="btn-white btn-md">
          edit
        </Link>
      </Table.Cell>
    </Table.Row>
  );
};

function PublicationHistory() {
  const currentUser = useCurrentUser();
  const {
    isLoading,
    data: userPublications,
  }: { data: any; isLoading: boolean } = useQuery(
    ["userPublications", currentUser?.id],
    fetchPublications,
  );
  const { fullName } = currentUser?.attributes;
  return (
    <>
      <div className="flex justify-between gap-2.5 items-center pb-5 md:border-b md:border-lines">
        <h2 className="hidden md:block text-dark-100 font-h2 ">
          Publication History
        </h2>
        <Link
          to="/settings/publication-history/new"
          className="flex gap-2.5 btn-primary btn-md"
        >
          <i className="ri-add-line"></i>
          New Publication
        </Link>
      </div>
      <div className="pb-7.5 md:py-7.5">
        {isLoading ? (
          <LoadingPublications />
        ) : (
          <>
            {userPublications?.data.length > 0 ? (
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell className="text-center whitespace-nowrap">
                    publication
                  </Table.HeaderCell>
                  <Table.HeaderCell className="text-center whitespace-nowrap">
                    year
                  </Table.HeaderCell>
                  <Table.HeaderCell children={""} />
                </Table.Header>
                <Table.Body>
                  {userPublications.data.map(publication => (
                    <PublicationRow
                      key={publication.id}
                      fullName={fullName}
                      publication={publication}
                    />
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <article className="bg-tint rounded-lg flex flex-col items-center text-center py-24 px-6">
                <i className="ri-article-line ri-3x leading-none text-dark-25" />
                <p className="mt-8 font-body text-dark-75">
                  You don't have any publications yet.
                </p>
              </article>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default PublicationHistory;
