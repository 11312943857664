import clsx from "clsx";
import * as React from "react";
import { Link } from "react-router-dom";
import ActivityTitles from "../ActivityTitles";
import { camelCaseToDash, humanize } from "@/utils/string";

const UserCard = ({ ownerData, action }) => {
  return (
    <div>
      <p className="font-meta text-dark-50">
        {ActivityTitles[action.toUpperCase()]}
      </p>
      <Link
        to={ownerData.url}
        className="font-mobile-body-bold text-primary hover:underline"
      >
        {ownerData.name}
      </Link>
    </div>
  );
};

const EngagementCard = ({ ownerData, trackableData, action }) => {
  return (
    <div>
      <p className="font-meta text-dark-50">
        {ActivityTitles[action.toUpperCase()]}{" "}
        <Link to={ownerData.url} className="hover:underline">
          {ownerData.name}
        </Link>
      </p>
      <Link
        to={trackableData.url}
        className="font-mobile-body-bold text-dark-100 hover:underline"
      >
        {trackableData.subject}
      </Link>
    </div>
  );
};

const ProjectCard = ({ ownerData, trackableData, action }) => {
  const trackableType = humanize(camelCaseToDash(trackableData.type));
  const ownerType = humanize(camelCaseToDash(ownerData.role));

  return (
    <div>
      {
        {
          "project.created": (
            <>
              <p className="font-meta text-dark-50">
                New {trackableType} added by{" "}
                <Link to={ownerData.url} className="hover:underline">
                  {ownerData.name}
                </Link>
              </p>
              <Link
                to={trackableData.url}
                className="font-mobile-body-bold text-dark-100 hover:underline"
              >
                {trackableData.name}
              </Link>
            </>
          ),
          "project.joined": (
            <>
              <p className="font-meta text-dark-50">
                New {ownerType} joined a Project
              </p>
              <Link
                to={ownerData.url}
                className="font-mobile-body-bold text-primary hover:underline"
              >
                {ownerData.name}
              </Link>
              <p className="font-meta text-dark-75 mb-3">
                {ownerData.organization}
              </p>
              <Link
                to={trackableData.url}
                className="font-meta-bold hover:underline"
              >
                {trackableData.name}
              </Link>
            </>
          ),
          "project.left": (
            <>
              <p className="font-meta text-dark-50">
                {ownerType} left a Project
                <Link
                  to={ownerData.url}
                  className="font-mobile-body-bold text-primary hover:underline"
                >
                  {ownerData.name}
                </Link>
                <p>{ownerData.organization}</p>
                <Link
                  to={trackableData.url}
                  className="font-meta-bold hover:underline"
                >
                  {trackableData.name}
                </Link>
              </p>
            </>
          ),
        }[action]
      }
    </div>
  );
};

const ResearchCard = ({ ownerData, trackableData, action }) => {
  return (
    <div>
      <p className="font-meta text-dark-50">
        {ActivityTitles[action.toUpperCase()]}{" "}
        <Link to={ownerData.url} className="hover:underline">
          {ownerData.name}
        </Link>
      </p>
      <p className="font-mobile-body-bold text-dark-100">
        {trackableData.title}
      </p>
    </div>
  );
};
const ResourceCard = ({ ownerData, trackableData }) => {
  const trackableType = humanize(camelCaseToDash(trackableData.type));
  return (
    <div>
      <p className="font-meta text-dark-50">
        New {trackableType} added by{" "}
        <Link to={ownerData.url} className="hover:underline">
          {ownerData.name}
        </Link>
      </p>
      <p className="font-mobile-body-bold text-dark-100">
        {trackableData.title}
      </p>
    </div>
  );
};

const ActivityCard = ({ activity }) => {
  const {
    creationDay,
    creationTime,
    key,
    ownerData,
    trackableData,
  } = activity.attributes;
  const activityResource = key.split(".")[0];

  return (
    <article className="flex">
      <div
        className={clsx(
          "w-[100px] text-[11px] text-dark-75 flex flex-col items-end",
          " pb-5 pr-4 mr-5 border-r relative",
        )}
      >
        <div className="uppercase leading-none">{creationDay}</div>
        <div className="text-dark-50">{creationTime}</div>
        <div className="absolute top-[-3px] right-[-8px] w-4 h-4 border-[3px] bg-tint border-tint content-none">
          <div className="bg-dark-50 rounded-lg w-full h-full"></div>
        </div>
      </div>
      <div className="pb-5 flex-1">
        <div className="flex py-5.5 px-5 bg-white border rounded shadow flex-1 max-w-[613px]">
          <Link to={ownerData.url} className="flex min-w-max pr-5">
            <img
              src={ownerData.avatarUrl}
              alt=""
              className="h-9 w-9 rounded-full flex-shrink-0"
            />
          </Link>
          {
            {
              user: <UserCard ownerData={ownerData} action={key} />,
              announcement: (
                <EngagementCard
                  ownerData={ownerData}
                  trackableData={trackableData}
                  action={key}
                />
              ),
              project: (
                <ProjectCard
                  ownerData={ownerData}
                  trackableData={trackableData}
                  action={key}
                />
              ),
              research_document: (
                <ResearchCard
                  ownerData={ownerData}
                  trackableData={trackableData}
                  action={key}
                />
              ),
              resource: (
                <ResourceCard
                  ownerData={ownerData}
                  trackableData={trackableData}
                />
              ),
            }[activityResource]
          }
        </div>
      </div>
    </article>
  );
};

function ActivityList({ activities }) {
  return (
    <section>
      {activities.map((activity, index) => (
        <ActivityCard activity={activity} key={index} />
      ))}
    </section>
  );
}

export default ActivityList;
