import * as React from "react";
import SVG from "react-inlinesvg";
import { parseISO, formatDistanceToNow } from "date-fns";
import Table from "@/components/shared/Table";
import Tooltip from "../Tooltip";

function CenterInfo({ site }) {
  const { banner, logo, name } = site;
  const activatedAt = site.activated_at
    ? `Active for ${formatDistanceToNow(
        parseISO(site.activated_at.slice(0, 10)),
      )}`
    : "Not active";

  return (
    <div className="flex items-center">
      <div className="relative overflow-hidden w-100px h-14 rounded flex-shrink-0">
        {banner ? (
          <div className="absolute inset-0">
            <img
              src={banner}
              className="object-cover sm:object-fill w-full min-w-full min-h-full"
            />
            <div
              className="absolute inset-0 bg-gradient-to-t from-dark-100"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="absolute bg-white border border-dark-25 h-full w-full rounded"></div>
        )}
        <div className="absolute flex h-full w-full items-center justify-center">
          {logo ? (
            <SVG
              src={logo}
              preserveAspectRatio="xMidYMid meet"
              className="fill-current text-white h-6"
            />
          ) : (
            <i className="ri-building-line text-dark-50 ri-xl" />
          )}
        </div>
      </div>
      <div className="m-4">
        <a className="font-body-bold text-dark-100">{name}</a>
        <p className="font-meta">{activatedAt}</p>
      </div>
    </div>
  );
}

function EnrollmentTable({ periodicUpdate }) {
  const {
    totalCohorts,
    currentSites,
    currentPatientsEnrolled,
    targetPatientsEnrolled,
  } = periodicUpdate.attributes;

  const tableIsEmpty =
    totalCohorts.length === 0 ||
    totalCohorts.every(
      cohort => cohort.total_enrollment === 0 && cohort.target_enrollment === 0,
    );

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Center name</Table.HeaderCell>
        {totalCohorts.map(cohort => (
          <Table.HeaderCell key={cohort.cohort_name} className="text-center">
            <Tooltip>
              <Tooltip.Trigger className="whitespace-nowrap mx-auto uppercase tracking-wider">
                {cohort.cohort_name}
              </Tooltip.Trigger>
              <Tooltip.Content>{cohort.notes}</Tooltip.Content>
            </Tooltip>
          </Table.HeaderCell>
        ))}
        <Table.HeaderCell className="text-center">Total</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {currentSites.map((site, index) => (
          <Table.Row
            className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
            key={index}
          >
            <Table.Cell>
              <CenterInfo {...{ site }} />
            </Table.Cell>
            {site.cohorts.map(cohort => (
              <Table.Cell
                key={cohort.name}
                className="font-body-bold text-center"
              >
                {tableIsEmpty ? (
                  <span className="text-dark-75">- - -</span>
                ) : (
                  <span className="text-dark-100">{cohort.enroll}</span>
                )}
              </Table.Cell>
            ))}
            <Table.Cell className="font-body-bold text-center">
              {tableIsEmpty ? (
                <span className="text-dark-75">- - -</span>
              ) : (
                <span className="text-dark-100">{site.enrollment}</span>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell className="font-body-bold text-dark-100">
            Total:
          </Table.Cell>
          {totalCohorts.map(cohort => (
            <Table.Cell
              className="font-body-bold text-center"
              key={cohort.cohort_name}
            >
              {tableIsEmpty ? (
                <span className="text-dark-75">- - -</span>
              ) : (
                <>
                  <span className="text-dark-100">
                    {cohort.total_enrollment}
                  </span>
                  <span className="text-dark-50">
                    /{cohort.target_enrollment}
                  </span>
                </>
              )}
            </Table.Cell>
          ))}
          <Table.Cell className="font-body-bold text-center">
            {tableIsEmpty ? (
              <span className="text-dark-75">- - -</span>
            ) : (
              <>
                <span className="text-dark-100">{currentPatientsEnrolled}</span>
                <span className="text-dark-50">/{targetPatientsEnrolled}</span>
              </>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default EnrollmentTable;
