import * as React from "react";
import Modal from "@/components/shared/Modal";

function NewProjectModal({ newProjectModal, setNewProjectModal }) {
  return (
    <Modal
      open={newProjectModal}
      setOpen={setNewProjectModal}
      className="w-420px"
    >
      <Modal.Header className="pr-4">
        What type of project do you want to add?
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4 p-1">
          <a
            href="/admin/projects/new?type=clinical_trial"
            target="_blank"
            className="btn-white btn-lg text-primary-dark"
          >
            Clinical Trial
          </a>
          <a
            href="/admin/projects/new?type=research_study"
            target="_blank"
            className="btn-white btn-lg text-primary-dark"
          >
            Research Study
          </a>
          <a
            href="/admin/initiatives/new"
            target="_blank"
            className="btn-white btn-lg text-primary-dark"
          >
            Initiative
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default NewProjectModal;
