import React from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import Input from "@/components/shared/Input";
import clsx from "clsx";

const ExternalMemberSchema = Yup.object().shape({
  memberOrganization: Yup.string()
    .trim()
    .required("Center or Institution is required."),
});

function ExternalMemberForm({ memberName, onChange, onCancel }) {
  return (
    <Formik
      initialValues={{ memberOrganization: "", memberEmail: "" }}
      validationSchema={ExternalMemberSchema}
    >
      {({ isValid, values }) => {
        return (
          <>
            <Field name="memberOrganization">
              {({ field, form }) => (
                <>
                  <Input
                    placeholder="Member's Center or Institution"
                    styleAs="small"
                    wrapperClassName="mt-4 mb-4"
                    {...field}
                  />
                  {form.touched && form.error && (
                    <div className="text-error text-14 mt-5px">
                      {form.errors?.memberOrganization}
                    </div>
                  )}
                </>
              )}
            </Field>
            <Field name="memberEmail">
              {({ field, form }) => (
                <>
                  <Input
                    placeholder="Member's email"
                    styleAs="small"
                    wrapperClassName="mb-4"
                    {...field}
                  />
                  {form.touched && form.error && (
                    <div className="text-error text-14 mt-5px">
                      {form.error?.memberEmail}
                    </div>
                  )}
                </>
              )}
            </Field>
            <button
              type="button"
              onClick={() =>
                onChange({
                  attributes: {
                    name: memberName,
                    center: values.memberOrganization,
                    email: values.memberEmail,
                    projectLead: false,
                    projectManager: false,
                  },
                  name: memberName,
                })
              }
              className={clsx("btn-white btn-xs cursor-pointer mr-2", {
                "bg-primary text-white hover:border-primary-darker hover:bg-primary-darker active:bg-primary-darkest": isValid,
                "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none": !isValid,
              })}
              disabled={!isValid}
            >
              Add Project Member
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="btn-white btn-xs"
            >
              Cancel
            </button>
          </>
        );
      }}
    </Formik>
  );
}

export default ExternalMemberForm;
