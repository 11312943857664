import React from "react";
import ButtonIcon from "@/components/shared/ButtonIcon";

function ResourceLinkRow({
  resourceLinkItem,
  onDeleteClick = () => {},
  readOnly = false,
}) {
  return (
    <div className="group flex">
      <i className="ri-file-line mr-2 text-xl text-dark-50"></i>
      <a
        className="link-primary font-body-bold line-clamp-1"
        target="_blank"
        href={resourceLinkItem.link}
      >
        {resourceLinkItem.name}
      </a>
      {!readOnly && (
        <ButtonIcon
          className="hidden group-hover:!block"
          iconClassName="ri-delete-bin-line"
          onClick={onDeleteClick}
        />
      )}
    </div>
  );
}

export default ResourceLinkRow;
