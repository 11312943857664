import denormalizer from "@/utils/network/denormalizer";
import fetch from "cross-fetch";

const fetcher = (...args) => fetch(...args).then(res => res.json());

const saveResource = (resource: string, request) => {
  const { id } = request.data;
  let baseUrl = `/api/${resource}`;
  if (id) {
    baseUrl += `/${id}`;
  }
  return fetch(baseUrl, {
    method: id ? "PATCH" : "POST",
    headers: { "Content-Type": "application/vnd.api+json" },
    body: JSON.stringify(request),
  });
};

const stiMap = {
  centerAdmins: "users",
  piciAdmins: "users",
  employees: "users",
  guests: "users",
  boardMembers: "users",
  investigatorFulls: "users",
  investigatorProjects: "users",
  investigatorEmrs: "users",
  resourceCategories: "categories",
  linkResources: "mediaContents",
  fileResources: "mediaContents",
  clinicalTrials: "projects",
  researchStudies: "projects",
  initiatives: "projects",
  newsStories: "newsItems",
  newsFiles: "newsItems",
  newsLinks: "newsItems",
  announcements: "posts",
};

const denormalizedFetcher = (...args) =>
  fetch(...args)
    .then(res => res.json())
    .then(json => new Promise(resolve => resolve(denormalizer(json, stiMap))));

// input: "?status=reviewed&class=discovery"
// output: { status: "reviewed", class: "discovery" }
const splitQueryParams = params => {
  let paramsObj = {};

  if (params !== "") {
    params
      .replace("?", "")
      .split("&")
      .forEach(param => {
        const values = param.split("=");
        paramsObj[values[0]] = values[1];
      });
  }

  return paramsObj;
};

// input: { status: "reviewed", class: "discovery" }
// output: "?status=reviewed&class=discovery"
const buildQueryParams = params => {
  const strParams = Object.keys(params)
    .filter(key => params[key] !== "")
    .map(key => `${key}=${params[key]}`)
    .join("&");
  return strParams !== "" ? `?${strParams}` : "";
};

export {
  fetcher,
  saveResource,
  denormalizedFetcher,
  splitQueryParams,
  buildQueryParams,
};
