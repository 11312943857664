import * as React from "react";
import { Link } from "react-router-dom";
import FormBlock from "@/components/shared/FormBlock";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import { denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { useQuery } from "@tanstack/react-query";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import { useEffect, useState } from "react";
import SearchBar from "@/components/shared/SearchBar";
import ResultList from "@/components/shared/ResultList";
import { isEqual } from "lodash";

const parsedProjects = projects => {
  return projects?.data.map(project => ({
    id: project.id,
    content: project.attributes.name,
  }));
};

const fetchFocusAreas = () => {
  const params = paramBuilder({
    fields: {
      focusAreas: ["name"],
    },
  });
  const url = `/api/focus-areas?${params}`;
  return denormalizedFetcher(url);
};

const fetchProjects = () => {
  const params = paramBuilder({
    fields: {
      projects: ["name"],
    },
    page: {
      size: 500,
    },
    exclude_links: true,
  });
  const url = `/api/projects?${params}`;
  return denormalizedFetcher(url);
};

function ProjectDetailsForm({ values, isStaff = true, onChange }) {
  const [selectedProjects, setSelectedProjects] = useState(
    values.relatedProjects,
  );
  const [relatedProjectName, setRelatedProjectName] = useState<string>("");

  const { data: projects }: { data: any } = useQuery(
    ["related_projects"],
    fetchProjects,
  );
  const { data: focusAreas }: { data: any } = useQuery(
    ["focusAreas"],
    fetchFocusAreas,
  );

  const projectOptions = React.useMemo(() => parsedProjects(projects), [
    projects,
  ]);

  const projectOptionsFiltered = React.useMemo(() => {
    return projectOptions?.filter(project => {
      const projectNames = selectedProjects?.map(({ content }) => content);
      return !projectNames.includes(project?.content);
    });
  }, [selectedProjects, projectOptions]);

  const addProject = value => {
    setSelectedProjects([...selectedProjects, value]);
    setRelatedProjectName("");
  };

  const removeProject = index => {
    setSelectedProjects([
      ...selectedProjects.slice(0, index),
      ...selectedProjects.slice(index + 1),
    ]);
  };

  useEffect(() => {
    if (!isEqual(selectedProjects, values.relatedProjects)) {
      onChange("relatedProjects", selectedProjects);
    }
  }, [selectedProjects, values.relatedProjects]);

  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Project Details</h3>
        <p className="text-14 text-dark-75">
          General context around the project
        </p>
      </header>
      <section className="flex-1">
        <FormBlock
          label="Project Description"
          name="description"
          labelClassName="font-body-bold"
          className="mb-6"
        >
          <RichTextEditor
            value={values.notes}
            dataTestId="rte-description"
            className="max-w-[652px]"
            onChange={value => onChange("description", value)}
          />
        </FormBlock>
        <FormBlock
          label="Focus Areas"
          name="focusAreas"
          description="Current science and technology areas"
          labelClassName="font-body-bold"
        >
          <CheckboxGroup
            options={focusAreas?.data}
            selectedOptions={values.focusAreas}
            setSelectedOptions={areas => onChange("focusAreas", areas)}
            accessor={option => option.attributes.name}
            className="flex flex-col h-auto flex-nowrap w-full sm:flex-wrap sm:h-[550px] rounded"
            labelClassName="px-0"
          />
        </FormBlock>
        {isStaff && (
          <Link
            to="/admin/focus_areas"
            className="mt-2 btn-md btn-white !border-indigo-600 !text-indigo-600 hover:bg-indigo-600 hover:!text-white"
          >
            <i className="ri-add-line mr-2 " />
            Create New Focus Area
          </Link>
        )}
        <FormBlock
          label="Related Projects"
          name="projects"
          optional
          className="mt-6"
          labelClassName="font-body-bold"
        >
          <SearchBar
            options={projectOptionsFiltered || []}
            placeholder="Search by project name"
            accessor={item => item.content}
            onTextChange={item => setRelatedProjectName(item)}
            matchCriteria={(input, itemSearchable) =>
              itemSearchable
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            onSelect={addProject}
            selected={relatedProjectName}
          >
            {({ item }) => (
              <div className="px-6 py-4 cursor-pointer flex sm:space-x-5 hover:bg-gray-50 hover:text-indigo-500">
                <span className="font-body max-w-md">{item.content}</span>
              </div>
            )}
          </SearchBar>
        </FormBlock>
        {selectedProjects.length > 0 && (
          <ResultList
            list={selectedProjects}
            onDelete={(_, index) => removeProject(index)}
            attributeToShow="content"
          />
        )}
      </section>
    </article>
  );
}

export default ProjectDetailsForm;
