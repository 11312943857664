import * as React from "react";
import * as R from "ramda";
import clsx from "clsx";
import SearchBar from "@/components/shared/SearchBar";
import { useFormikContext } from "formik-latest";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { addExternalAuthor } from "../ResearchDocumentForm/data";
import { XIcon } from "@heroicons/react/outline";

function AuthorsPicker({ users }) {
  const {
    values,
    setFieldValue,
  }: { values: any; setFieldValue: any } = useFormikContext();
  const internalAuthors = values.internalAuthors;
  const externalAuthors = values.externalAuthors;
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [currentSearchBarValue, setCurrentSearchBarValue] = useState<
    string | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      // Query Invalidations
      queryClient.invalidateQueries(["authors"]);
      queryClient.invalidateQueries(["external-authors"]);
    },
  });
  const authors = users.filter(user =>
    R.or(
      R.find(R.propEq("id", user.id))(internalAuthors) &&
        user.external == false,
      R.find(R.propEq("id", user.id))(externalAuthors) && user.external == true,
    ),
  );
  const createExternalUser = async name => {
    const externalAuthor: any = await mutateAsync(
      addExternalAuthor(name, () => {}),
    );
    const newAuthor = {
      id: externalAuthor.id,
      name: externalAuthor.attributes.name,
      authorType: "author",
      external: true,
    };
    return newAuthor;
  };

  useEffect(() => {
    const filteredAuthors = users.filter(
      user =>
        !R.or(
          R.find(R.propEq("id", user.id))(internalAuthors) &&
            user.external == false,
          R.find(R.propEq("id", user.id))(externalAuthors) &&
            user.external == true,
        ),
    );
    setFilteredUsers(filteredAuthors);
  }, [internalAuthors, externalAuthors, users]);

  return (
    <div>
      <div>
        <SearchBar
          options={filteredUsers}
          onSelect={async item => {
            if (!item.name) {
              item = await createExternalUser(item);
            }
            item.external
              ? setFieldValue("externalAuthors", externalAuthors.concat(item))
              : setFieldValue("internalAuthors", internalAuthors.concat(item));

            setCurrentSearchBarValue("");
          }}
          onTextChange={value => setCurrentSearchBarValue(value)}
          selected={currentSearchBarValue}
          placeholder="Enter author name..."
          accessor={item => item.name}
          displayNewExternal
        >
          {({ item, index }) => {
            return index === "external" ? (
              <div className="p-3 cursor-pointer flex flex-col">
                <span className="text-dark-100">
                  + Add "{item}" as an Author
                </span>
              </div>
            ) : (
              <div className="p-3 cursor-pointer flex flex-col">
                <span className="text-dark-100">{item.name}</span>
                <span className="text-dark-50">{item.email}</span>
              </div>
            );
          }}
        </SearchBar>
      </div>
      {authors.length > 0 && (
        <div className="flex flex-wrap pt-5">
          {authors.map(author => (
            <div
              key={author.name}
              className={clsx(
                "flex items-center mr-4 py-0.25 pl-2.5 pr-2 rounded-xl font-label uppercase mb-2.5 border border-transparent bg-lines text-dark-75",
              )}
            >
              <span>{author.name}</span>
              <button
                onClick={() => {
                  author.external
                    ? setFieldValue(
                        "externalAuthors",
                        externalAuthors.filter(user => user.id !== author.id),
                      )
                    : setFieldValue(
                        "internalAuthors",
                        internalAuthors.filter(user => user.id !== author.id),
                      );
                }}
                className="pl-0.5 cursor-pointer bg-transparent border-0"
                data-testid={`tag-${author.name}`}
                type="button"
              >
                <XIcon className="fill-current text-dark-50 w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AuthorsPicker;
