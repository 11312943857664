import Table from "@/components/shared/Table";
import React from "react";

function CenterSkeletonTable() {
  return (
    <Table className="border border-transparent text-sm table-fixed mb-8 animate-pulse">
      <Table.Header className="bg-transparent text-dark-50">
        <Table.HeaderCell className="pt-9px pb-9px">
          Center name
        </Table.HeaderCell>
        <Table.HeaderCell className="pt-9px pb-9px">Location</Table.HeaderCell>
        <Table.HeaderCell className="pt-9px pb-9px">Members</Table.HeaderCell>
        <Table.HeaderCell className="pt-9px pb-9px">Public</Table.HeaderCell>
        <Table.HeaderCell className="pt-9px pb-9px">Actions</Table.HeaderCell>
      </Table.Header>
      <Table.Body className="border border-lines">
        {[1, 2, 3, 4].map(x => (
          <Table.Row key={x} className="divide-x divide-lines">
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell className="w-[100px]">
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default CenterSkeletonTable;
