import fetch from "./csrf-fetch";

const TIME_BETWEEN_REQUESTS = 500; // milliseconds

const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const buildStorage = () => {
  let contents = {};
  return {
    set: function(key, value) {
      contents[key] = value;
    },
    get: function(key) {
      return contents[key];
    },
  };
};

const validations = buildStorage();

const requestBuilder = (resource, field, validates, value) => {
  return {
    type: resource,
    attributes: [
      {
        name: field,
        validates: validates,
        value: value,
      },
    ],
  };
};

const findMatch = (a, b) => {
  return a.name === b.name && a.validates === b.validates;
};

const findInList = (list, attr) => {
  let found = undefined;
  list.forEach(listAttr => {
    if (findMatch(attr, listAttr)) {
      found = listAttr;
    }
  });
  return found;
};

const requestMerger = (first, last) => {
  const attributes = [];
  first.attributes.forEach(attr => {
    const match = findInList(last.attributes, attr);
    attributes.push(match ? match : attr);
  });
  last.attributes.forEach(attr => {
    const match = findInList(attributes, attr);
    if (!match) {
      attributes.push(attr);
    }
  });
  return {
    type: first.type,
    attributes,
  };
};

const perform = (requestBody, resource, field, validates) => {
  const request = { method: "POST", body: JSON.stringify(requestBody) };
  fetch("/api/validations", request)
    .then(r => r.json())
    .then(json => {
      const response = json.fields[field];
      if (response.success === true) {
        validations.set(`${resource}${field}`, true);
      }
      if (response.errors && response.errors[validates] === true) {
        validations.set(`${resource}${field}`, false);
      }
    });
};

const fastPerform = debounce(perform, TIME_BETWEEN_REQUESTS);

const requestValidation = (resource, field, validates, value) => {
  const params = [resource, field, validates, value];
  const stored = validations.get(resource);
  let request;
  if (stored !== undefined) {
    request = requestMerger(stored, requestBuilder.apply(null, params));
  } else {
    request = requestBuilder.apply(null, params);
  }
  validations.set(resource, request);
  fastPerform(request, resource, field, validates);
  const item = validations.get(`${resource}${field}`);
  return item !== undefined ? item : true;
};

export { debounce, requestBuilder, requestMerger, requestValidation };
