import * as React from "react";
import { useState } from "react";
import Preview from "../Preview";
import PreviewContext from "../Preview/PreviewContext";
import Header from "./Header";

function ResearchCard({ research }) {
  const [researchPreviewIsOpen, setResearchPreviewIsOpen] = useState(false);

  return (
    <section className="max-w-7xl mx-auto">
      <PreviewContext.Provider
        value={{
          researchPreviewIsOpen: researchPreviewIsOpen,
          setResearchPreviewIsOpen: setResearchPreviewIsOpen,
        }}
      >
        <main className="p-6 bg-white shadow flex justify-center rounded-lg lg:mb-6 max-w-3xl">
          <Preview
            fileType={research.attributes.fileType}
            viewLink={research.links.view}
            downloadLink={research.links.download}
          />
        </main>
      </PreviewContext.Provider>
    </section>
  );
}
function OverviewTab({ research }) {
  return (
    <div className="flex flex-col">
      <Header research={research} />
      <div className="rounded-md max-w-full">
        <ResearchCard research={research} />
      </div>
    </div>
  );
}

export default OverviewTab;
