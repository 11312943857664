import React from "react";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import {
  LinkableNameColumn,
  ActionButtonsColumn,
  itemValue,
  render,
} from "@/components/shared/Admin/Columns";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import ToogleButton from "@/components/shared/ToogleButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import NoResults from "@/components/shared/NoResults";
import RetreatSkeletonTable from "./RetreatSkeletonTable";
import ReactTooltip from "react-tooltip";

const PublishColumn = ({ value }) => {
  const isPublished = value.attributes.published;
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admin_retreats"]);
    },
    onMutate: async () => {
      const oldPagedData: any = queryClient.getQueryData(["admin_retreats"]);
      oldPagedData.pages.forEach(page => {
        const updatedRetreat = page.data.find(
          retreat => retreat.id === value.id,
        );
        if (updatedRetreat) {
          updatedRetreat.attributes.published = !isPublished;
          return true;
        }
      });
    },
  });
  const handlePublic = () => {
    mutateAsync(fetch(`/api/retreats/${value.id}/toggle`));
  };
  return <ToogleButton active={isPublished} onClick={handlePublic} />;
};

function RetreatsTable({ retreats, isFetching, isFetchingNextPage }) {
  const columns = [
    {
      name: "coverImageUrl",
      label: "COVER",
      td: { className: "w-[40px] pt-9px pb-9px" },
      render: value => {
        return <img className="h-10 w-10 rounded-full" src={value} />;
      },
    },
    {
      name: "quarter",
      label: "QUARTER",
      td: { className: "pt-9px pb-9px" },
      render: value => {
        return <span className="text-dark-75 font-meta-bold">{value}</span>;
      },
    },
    {
      name: "title",
      label: "TITLE",
      td: { className: "pt-9px pb-9px" },
      render: (value, item) => {
        const textTooltip = item.attributes.published
          ? "This Retreat is able to see en Member Center."
          : "This Retreat can not be seen in the Member Center until their publication.";

        return (
          <>
            <div className="flex" data-for={item.id} data-tip={textTooltip}>
              {item.attributes.published ? (
                <i className="ri-checkbox-circle-line mr-2 text-green-700 text-base" />
              ) : (
                <i className="ri-error-warning-line mr-2 text-orange text-base" />
              )}
              {item.attributes.published ? (
                <LinkableNameColumn
                  text={value}
                  to={`/retreats/${item.id}`}
                  isBlank
                />
              ) : (
                <p className="text-dark-75 font-meta-bold whitespace-normal">
                  {value}
                </p>
              )}
            </div>
            <ReactTooltip id={item.id} multiline={true} />
          </>
        );
      },
    },
    {
      name: "location",
      label: "LOCATION",
      td: { className: "pt-9px pb-9px" },
      render: value => {
        return <span className="text-dark-75 font-meta-bold">{value}</span>;
      },
    },
    {
      name: "startDate",
      label: "START DATE",
      td: { className: "max-w-[120px] pt-9px pb-9px" },
      render: value => (
        <span className="font-meta">
          {format(new Date(value), "MMM dd, yyyy")}
        </span>
      ),
    },
    {
      name: "endDate",
      label: "END DATE",
      td: { className: "max-w-[120px] pt-9px pb-9px" },
      render: value => (
        <span className="font-meta">
          {format(new Date(value), "MMM dd, yyyy")}
        </span>
      ),
    },
    {
      name: "published",
      label: "Publish",
      td: { className: "w-100px pt-9px pb-9px" },
      accessor: item => item,
      render: value => <PublishColumn value={value} />,
    },
    {
      label: "Actions",
      td: { className: "w-[80px] pt-9px pb-9px pr-2 pl-3 text-center" },
      accessor: item => item,
      render: value => {
        const items: ActionButtonItemType[] = [
          {
            text: "Edit",
            to: `/admin/retreats/${value.id}/edit`,
            name: value.attributes.title,
            variant: "btn-admin",
            isBlank: false,
            isSPA: true,
          },
        ];
        return <ActionButtonsColumn items={items} />;
      },
    },
  ];

  return (
    <>
      {!isFetching && retreats.length === 0 ? (
        <NoResults entityName="retreats" icon="ri-search-line" />
      ) : (
        <Table
          className="border border-transparent text-sm table-fixed mb-8"
          data-testid="initiatives-table"
        >
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, index) => (
              <Table.HeaderCell key={index} className="text-11 pb-2">
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body className="border border-lines">
            {retreats.map((item, index) => (
              <Table.Row key={index} className="divide-x divide-lines">
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={column.td?.className}
                    expand
                  >
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => <RetreatSkeletonTable key={x} />)}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default RetreatsTable;
