import React from "react";
import {
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import StackedTooltip from "./StackedTooltip";
import COLORS from "./Colors";
import useWindowDimensions from "@/hooks/useWindowDimensions";

function HorizontalStackedBarChart({ data, centers, openModal, title = "" }) {
  const { width } = useWindowDimensions();

  return data.length > 0 ? (
    <ResponsiveContainer width={width > 640 ? 232 : "100%"} height={232}>
      <BarChart
        data={data}
        margin={{
          top: 56,
          right: 32,
          bottom: 24,
          left: 32,
        }}
        className="bg-tint"
        onClick={width > 640 ? openModal : null}
        cursor="pointer"
        layout="vertical"
      >
        <text
          x={width > 640 ? "17" : "50%"}
          y="28"
          textAnchor={width > 640 ? "start" : "middle"}
          className="font-h3 sm:font-meta-bold"
        >
          {title}
        </text>
        <Tooltip content={<StackedTooltip />} />
        <XAxis type="number" hide />
        <YAxis type="category" dataKey="name" hide />
        {centers.map((center, index) => (
          <Bar
            dataKey={center}
            key={center}
            stackId="center"
            fill={COLORS[index]}
            barSize={32}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <></>
  );
}

export default HorizontalStackedBarChart;
