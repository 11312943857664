import React from "react";
import Table from "@/components/shared/Table";
import MembersGroup from "@/components/shared/MembersGroup";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { LinkableNameColumn } from "@/components/shared/Admin/Columns";

function InitiativesTable({ initiatives }) {
  return (
    <Table
      className="border border-transparent text-sm table-fixed mb-8"
      data-testid="initiatives-table"
    >
      <Table.Header className="bg-transparent text-dark-50">
        <Table.HeaderCell className="text-11 pb-2 !px-3 w-[30px] text-center">
          ID
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3 w-[25.5%]">
          Title
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Date added
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Leads
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Centers
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Members
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3 w-[100px]">
          Actions
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body className="border border-lines">
        {initiatives.map(initiative => {
          const { attributes, links, relationships } = initiative;
          const projectLeadsIds = relationships.projectLeads.data.map(
            user => user.id,
          );
          const members = relationships.members.data.filter(member => {
            return !projectLeadsIds.includes(member.id);
          });
          return (
            <Table.Row className="divide-x divide-lines" key={initiative.id}>
              <Table.Cell className="!px-3 w-[30px] text-center">
                <span className="text-dark-75 font-meta-bold">
                  {initiative.id}
                </span>
              </Table.Cell>
              <Table.Cell
                className="!whitespace-normal"
                data-testid="initiatives-title"
              >
                <LinkableNameColumn
                  text={attributes.name}
                  to={links.slug}
                  isBlank
                  isArchived={attributes.archived}
                />
              </Table.Cell>
              <Table.Cell
                className="!px-3"
                data-testid="initiatives-date-added"
              >
                {format(new Date(attributes.createdAt), "MMMM dd, yyyy")}
              </Table.Cell>
              <Table.Cell className="!px-3" data-testid="initiatives-centers">
                <MembersGroup
                  members={relationships.projectLeads.data}
                  groupId={initiative.id}
                />
              </Table.Cell>
              <Table.Cell className="!px-3">
                <ul>
                  {attributes.centersAndInstitutions.map((center, i) => (
                    <li key={i}>{center}</li>
                  ))}
                </ul>
              </Table.Cell>
              <Table.Cell className="!px-3">
                <MembersGroup members={members} groupId={initiative.id} />
              </Table.Cell>
              <Table.Cell className="text-center !px-3 !py-3">
                <Link
                  className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-1 px-3.75"
                  to={`/admin/initiatives/${initiative.id}/edit`}
                >
                  Edit
                </Link>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default InitiativesTable;
