import { DirectUpload } from "activestorage";
import { useEffect, useState } from "react";

type BlobType = {
  byte_size: number;
  filename: string;
  content_type: string;
  signed_id: string;
};

const endpoint = "/rails/active_storage/direct_uploads";

const STATUSES = {
  initial: "initial",
  uploading: "uploading",
  uploaded: "uploaded",
  error: "error",
};

function useActiveStorage({
  value,
  url = endpoint,
}: {
  value?: string;
  url?: string;
}) {
  const [status, setStatus] = useState(STATUSES.initial);
  const [progress, setProgress] = useState(0);
  const [metadata, setMetadata] = useState({});
  const [file, setFile] = useState(null);

  const clearCurrentFile = () => {
    setProgress(0);
    setMetadata({});
    setFile(null);
  };

  useEffect(() => {
    if (value === "") {
      setStatus(STATUSES.initial);
      clearCurrentFile();
    }
  }, [value]);

  const uploadFile = uploadEvent => {
    // Checks if the user closes the file browser prompt without selecting a file
    if (uploadEvent.target.files.length === 0) return;

    const file = uploadEvent.target.files[0];
    clearCurrentFile();
    setStatus(STATUSES.uploading);

    const upload = new DirectUpload(file, url, {
      directUploadWillStoreFileWithXHR: request => {
        request.upload.addEventListener("progress", ({ loaded, total }) => {
          setProgress((loaded / total) * 100);
        });
      },
    });

    const process = new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          setStatus(STATUSES.error);
          reject(error);
        } else {
          setStatus(STATUSES.uploaded);
          setFile(file);
          resolve(blob);
        }
      });
    });

    process.then((blob: BlobType) => {
      const { byte_size, filename, content_type, signed_id } = blob;
      setMetadata({
        filename,
        signedId: signed_id,
        byteSize: byte_size,
        contentType: content_type,
      });
    });
  };

  return {
    uploadFile,
    status,
    progress,
    metadata,
    file,
    setStatus,
  };
}

export default useActiveStorage;
