import * as React from "react";
import { createContext, useContext } from "react";
import clsx from "clsx";
import { Dialog, Transition } from "@headlessui/react";

const SlideOverContext = createContext({
  setOpen: (_: boolean) => {},
});

function SlideOver({
  open,
  setOpen,
  children,
  className = "",
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
  children: any;
  className?: string;
}) {
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-50"
        open={open}
        onClose={() => setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={React.Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-dark-75 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 sm:pl-10 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={clsx(
                  "w-screen max-w-3xl bg-white shadow-xl flex flex-col",
                  className,
                )}
              >
                <SlideOverContext.Provider value={{ setOpen: setOpen }}>
                  {children}
                </SlideOverContext.Provider>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SlideOver.Header = ({ children, className = "", buttonClassName = "" }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setOpen } = useContext(SlideOverContext);
  return (
    <div
      className={clsx(
        "px-4 py-6 border-b border-lines sm:px-6 flex items-center justify-between space-x-3",
        className,
      )}
    >
      <Dialog.Title className="font-h2 text-dark-100">{children}</Dialog.Title>
      <button
        type="button"
        className={clsx(
          "flex rounded-md text-dark-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
          buttonClassName,
        )}
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close panel</span>
        <i className="ri-close-line ri-lg leading-none" aria-hidden="true" />
      </button>
    </div>
  );
};

SlideOver.Body = ({ children, className = "" }) => {
  return (
    <div
      className={clsx(
        "bg-tint font-body flex-1 overflow-x-hidden py-6 px-4 sm:px-6 h-vh-150",
        className,
      )}
    >
      {children}
    </div>
  );
};

SlideOver.Footer = ({ children, className = "" }) => {
  return (
    <div
      className={clsx(
        "flex-shrink-0 w-full space-x-3 flex justify-start px-4 pb-5 pt-3 sm:px-6 self-end",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default SlideOver;
