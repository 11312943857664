import * as React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { denormalizedFetcher } from "@/utils/api";
import { formatDistanceToNow, parseISO } from "date-fns";
import paramBuilder from "@/utils/network/paramBuilder";
import RichText from "@/components/shared/RichText";
import Breadcrumb from "@/components/shared/Breadcrumb";
import PdfViewer from "@/components/shared/PdfViewer/PdfViewer";
import clsx from "clsx";

const DownloadAttachment = ({ urlAttachment = "", className = "" }) => {
  const fileName = urlAttachment.split("/").pop();
  return (
    <div className={clsx("mt-5 text-primary-dark font-bold", className)}>
      <a href={urlAttachment} target="_BLANK" className="link-primary">
        <i className="ri-download-line mr-2.5"></i>
        Download Attachment: {decodeURI(fileName || "")}
      </a>
    </div>
  );
};

const PreviewAttachment = ({ urlAttachment = "" }) => {
  const extensionFile = urlAttachment
    .split(".")
    .pop()
    ?.toLocaleLowerCase();
  switch (extensionFile) {
    case "jpg":
    case "png":
    case "gif":
    case "jpeg":
      return <img src={urlAttachment} />;
    case "pdf":
      return <PdfViewer pdfFilePath={urlAttachment} />;
    default:
      return null;
  }
};

const Body = ({ announcement }) => {
  const { subject, content, imageUrl } = announcement.attributes;
  return (
    <div className="pb-10 md:flex-grow">
      <header className="mb-7.5 md:mb-10">
        <h1 className="font-h2 sm:font-h1 text-dark-100 mb-5">{subject}</h1>
      </header>
      <div className="py-2.5 sm:py-0 font-body text-dark-75 mb-4">
        <RichText value={content} />
      </div>

      {imageUrl !== null && (
        <>
          <DownloadAttachment urlAttachment={imageUrl} className="mb-6" />
          <PreviewAttachment urlAttachment={imageUrl} />
        </>
      )}
    </div>
  );
};

const Aside = ({ announcement }) => {
  const { createdAt } = announcement.attributes;
  const { fullName } = announcement.relationships.user.data.attributes;
  const { avatar, show } = announcement.relationships.user.data.links;
  const readers = announcement.relationships.readers.data;

  const filteredReaders = readers.filter(
    reader => reader.id !== announcement.relationships.user.data.id,
  );

  const formattedCreatedAt = formatDistanceToNow(parseISO(createdAt), {
    addSuffix: true,
  });

  return (
    <aside className="ml-0 md:ml-7.5 mb-7.5 md:mb-0 mt-4 md:mt-0">
      <section className="bg-tint rounded-lg p-6 md:w-320px">
        <p className="font-body-bold text-dark-100 pb-3.5">Author:</p>
        <div className="flex gap-3">
          <Link to={show} className="min-w-max">
            <img src={avatar} alt="avatar" className="rounded-full w-10 h-10" />
          </Link>
          <div className="flex flex-col">
            <Link to={show} className="link font-meta-bold text-dark-100">
              {fullName}
            </Link>
            <p className="font-micro text-dark-75">
              Posted {formattedCreatedAt}
            </p>
          </div>
        </div>
        {filteredReaders.length > 0 && (
          <>
            <hr className="my-6" />
            <div>
              <h4 className="font-body-bold text-dark-100">Read by:</h4>
              <div className="mt-3.5 space-y-5">
                {readers.map(reader => (
                  <div key={reader.id} className="flex items-center">
                    <img
                      src={reader.links.avatar}
                      className="h-10 w-10 rounded-full"
                    />
                    <div className="ml-3">
                      <Link to={reader.links.show} className="link">
                        <p className="font-meta-bold text-dark-100">
                          {reader.attributes.fullName}
                        </p>
                      </Link>
                      <p className="font-micro text-dark-75">
                        {reader.attributes.jobTitle}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </section>
    </aside>
  );
};

const fetchAnnouncement = ({ queryKey }) => {
  const id = queryKey[1];
  const baseUrl = `/api/announcements/${id}`;
  const params = paramBuilder({
    include: ["user", "readers"],
    fields: {
      announcements: [
        "subject",
        "content",
        "createdAt",
        "imageUrl",
        "user",
        "readers",
      ],
      users: ["fullName", "avatarUrl", "jobTitle"],
    },
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function AnnouncementPage() {
  const { id: announcementId } = useParams();
  const {
    isLoading,
    data: announcement,
  }: { isLoading: boolean; data: any } = useQuery(
    ["announcement", announcementId],
    fetchAnnouncement,
  );

  return (
    <div className="margin-safe">
      <Breadcrumb to="/" text="Back to Dashboard" />
      {!isLoading && (
        <div className="py-7.5 sm:py-10 md:flex">
          <Body announcement={announcement.data} />
          <Aside announcement={announcement.data} />
        </div>
      )}
    </div>
  );
}

export default AnnouncementPage;
