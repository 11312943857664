import React from "react";
import clsx from "clsx";
import { KeySection, SectionMode } from "../defaults";
import ImageSelector from "./ImageSelector";
import ButtonIcon from "@/components/shared/ButtonIcon";
import useViewMode from "./useViewMode";

function ImageSectionEditor({ section, className = "", values, onChange }) {
  const { isEditMode, isPreviewMode, isNotViewMode } = useViewMode(
    section.viewMode,
  );

  const handleDeleteSection = () => {
    const updatedSections = values.articleSections
      .filter(sectionItem => sectionItem.position !== section.position)
      .map((sectionItem, index) => ({ ...sectionItem, position: index }));
    onChange("articleSections", updatedSections);
  };

  const handleEditMode = () => {
    onChange(`articleSections[${section.position}].viewMode`, SectionMode.Edit);
  };

  const handlePreviewMode = () => {
    onChange(
      `articleSections[${section.position}].viewMode`,
      SectionMode.Preview,
    );
  };

  return (
    <div
      className={clsx(
        "relative group border-2 border-transparent pb-3 lg:pb-0 lg:flex lg:flex-col",
        {
          "bg-tint border rounded-lg mb-3 pt-7 px-4": isEditMode,
          "hover:border-2 hover:border-zinc-200 hover:rounded-lg hover:border-dashed p-2 mb-3": isPreviewMode,
        },
        className,
      )}
    >
      {isNotViewMode && (
        <div
          className={clsx(
            "absolute bg-slate-700 text-white right-1/2 -top-3",
            "px-4 rounded-lg hidden group-hover:block",
          )}
        >
          {section.keySection}
        </div>
      )}
      <div
        className={clsx("absolute right-4 top-1 group-hover:block", {
          hidden: isPreviewMode,
          block: isEditMode,
        })}
      >
        {isPreviewMode && (
          <ButtonIcon
            title="Set Edit mode"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-pencil-line"
            onClick={handleEditMode}
          />
        )}
        {isEditMode && (
          <ButtonIcon
            title="Set Preview mode"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-eye-line"
            onClick={handlePreviewMode}
          />
        )}
        {isNotViewMode && (
          <ButtonIcon
            title="Delete Section"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-delete-bin-line"
            onClick={handleDeleteSection}
          />
        )}
      </div>
      <div
        className={clsx({
          "grid grid-cols-1 lg:grid-cols-2 gap-8":
            section.keySection === KeySection.ImageTwoColumns,
        })}
      >
        <ImageSelector
          section={section}
          values={values}
          onChange={onChange}
          imageNumber={1}
        />
        {section.keySection === KeySection.ImageTwoColumns && (
          <ImageSelector
            section={section}
            values={values}
            onChange={onChange}
            imageNumber={2}
          />
        )}
      </div>
    </div>
  );
}

export default ImageSectionEditor;
