import * as React from "react";
import { useState, useEffect, createContext, useContext } from "react";
import { useLayer } from "react-laag";
import clsx from "clsx";

type DropdownContextType = {
  open: boolean;
  setOpen: (_: boolean) => void;
  closeOnSelect: boolean;
  renderLayer: any;
  triggerProps: any;
  layerProps: any;
};

const DropdownContext = createContext<DropdownContextType>({
  open: false,
  setOpen: null,
  closeOnSelect: true,
  renderLayer: null,
  triggerProps: null,
  layerProps: null,
});

function Dropdown({
  children,
  closeOnSelect = true,
  onToggle = () => {},
  className = "",
  placementOpt = "",
}: {
  children: any;
  closeOnSelect?: boolean;
  onToggle?: (_: boolean) => void;
  className?: string;
  placementOpt?: string;
}) {
  const [open, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: open,
    onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: placementOpt || "bottom-start", // we prefer to place the menu "top-end"
    possiblePlacements: ["bottom-start", "bottom-end", "bottom-center"],
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  useEffect(() => {
    onToggle(open);
  }, [open]);

  return (
    <div className={className}>
      <DropdownContext.Provider
        value={{
          open,
          setOpen,
          closeOnSelect,
          renderLayer,
          triggerProps,
          layerProps,
        }}
      >
        {children}
      </DropdownContext.Provider>
    </div>
  );
}

function DropdownLabel({
  children,
  className = "",
  disabled = false,
  ...props
}) {
  const { open, setOpen, triggerProps } = useContext(DropdownContext);
  const toggle = () => setOpen(!open);

  return (
    <button
      type="button"
      onClick={toggle}
      className={clsx(
        "h-12 flex items-center justify-between rounded border border-dark-25 shadow-sm text-center select-none focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary",
        disabled
          ? "bg-tint text-dark-75 cursor-default"
          : "bg-white text-dark-100 cursor-pointer",
        className,
      )}
      disabled={disabled}
      {...triggerProps}
      {...props}
    >
      {children}
    </button>
  );
}

function DropdownContent({ children, className = "" }) {
  const { open, setOpen, closeOnSelect, renderLayer, layerProps } = useContext(
    DropdownContext,
  );
  const isFn = typeof children === "function";

  return (
    <>
      {open &&
        renderLayer(
          <div
            {...layerProps}
            onClick={() => closeOnSelect && setOpen(false)}
            className={clsx(
              "bg-white border border-dark-25 shadow-lg rounded",
              className,
            )}
            style={{
              // minWidth: triggerBounds.width,
              ...layerProps.style,
            }}
          >
            {isFn && children({ setOpen })}
            {!isFn && children}
          </div>,
        )}
    </>
  );
}

Dropdown.Label = DropdownLabel;
Dropdown.Content = DropdownContent;

export default Dropdown;
