import React from "react";
import clsx from "clsx";

function SimpleTags({ tags, className = "", theme = "" }) {
  if (!tags || tags.length === 0) return null;

  return (
    <div className={clsx("flex flex-wrap gap-2.5", className)}>
      {tags.map((tag, index) => {
        return (
          <span
            key={index}
            className={clsx(
              "px-2.5 py-0.5 rounded font-bold text-13",
              theme === "dark"
                ? "text-white bg-white bg-opacity-20"
                : "bg-primary-light text-primary-dark",
            )}
          >
            {tag}
          </span>
        );
      })}
    </div>
  );
}

export default SimpleTags;
