import * as React from "react";
import { useState, useEffect } from "react";
import Label from "@/components/shared/Label";
import SearchBar from "@/components/shared/SearchBar";
import Table from "@/components/shared/Table";
import clsx from "clsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addExternalAuthor, mapExternalAuthor } from "./data";
import * as R from "ramda";
import Select from "@/components/shared/Select";
import { humanize } from "@/utils/string";

const SORTED_AUTHOR_TYPES = [
  "lead_author",
  "senior_author",
  "author",
  "coauthor",
  "contributor",
];

const selectedAuthorsSorter = selectedAuthors => {
  const byType = R.groupBy(author => author.authorType);
  const authorsGroupedByType = byType(selectedAuthors);
  const orderByType = type =>
    authorsGroupedByType[type] ? authorsGroupedByType[type] : [];
  return R.chain(orderByType, SORTED_AUTHOR_TYPES);
};

function AuthorTable({ selectedAuthors, removeAuthor }) {
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Name</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {selectedAuthors.map(author => (
          <Table.Row key={author.id}>
            <Table.Cell className="flex w-full justify-between items-center py-4 px-6.5">
              <div className="flex">
                <img src={author.avatar} className="h-10 w-10 rounded-full" />
                <div className="pl-4">
                  <span className="text-dark-100 font-bold text-15">
                    {author.name}
                  </span>
                  <br />
                  <span className="text-dark-75 font-normal text-14">
                    {humanize(author.authorType)}
                  </span>
                </div>
              </div>
              <button type="button" onClick={() => removeAuthor(author)}>
                <i className="ri-delete-bin-line ri-lg text-dark-50" />
              </button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function AuthorListCreator({
  onAddAuthor,
  onRemoveAuthor,
  initialSelectedAuthors,
  authors,
  authorTypes,
}) {
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedAuthors, setSelectedAuthors] = useState(
    selectedAuthorsSorter(initialSelectedAuthors),
  );
  const [selectedType, setSelectedType] = useState(null);

  const [filteredAuthors, setFilteredAuthors] = useState(authors);
  const [currentSearchBarValue, setCurrentSearchBarValue] = useState<
    string | undefined
  >(undefined);
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      // Query Invalidations
      queryClient.invalidateQueries(["authors"]);
      queryClient.invalidateQueries(["external-authors"]);
    },
  });

  const addAuthor = ({ author, type }) => {
    const newAuthor = {
      id: author.id,
      name: author.name,
      authorType: type || "author",
      external: author.external,
      avatar: author.avatar,
    };
    setSelectedAuthors(previous => {
      onAddAuthor(newAuthor, previous);
      return selectedAuthorsSorter([...previous, newAuthor]);
    });
  };

  const addAuthorClick = async () => {
    if (selectedAuthor === null) {
      const externalAuthor = await mutateAsync(
        addExternalAuthor(currentSearchBarValue, setSubmitting),
      );
      setSubmitting(false);
      addAuthor({
        author: mapExternalAuthor(externalAuthor),
        type: selectedType,
      });
    } else {
      addAuthor({ author: selectedAuthor, type: selectedType });
    }
    setSelectedAuthor(null);
    setSelectedType(null);
    setCurrentSearchBarValue(undefined);
  };

  const removeAuthor = removedAuthor => {
    const remainingAuthors = selectedAuthors.filter(
      author =>
        author.external !== removedAuthor.external ||
        author.id !== removedAuthor.id,
    );
    setSelectedAuthors(selectedAuthorsSorter(remainingAuthors));
    onRemoveAuthor(removedAuthor);
  };

  useEffect(() => {
    const filteredAuthors = authors.filter(author => {
      return !selectedAuthors.find(selectedAuthor => {
        return (
          selectedAuthor.id == author.id &&
          selectedAuthor.external == author.external
        );
      });
    });
    setFilteredAuthors(filteredAuthors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuthors]);

  useEffect(() => {
    if (currentSearchBarValue) {
      const authorName = currentSearchBarValue.trim().length > 0;
      setCanSubmit(authorName && !submitting);
    } else {
      setCanSubmit(false);
    }
  }, [currentSearchBarValue, submitting]);

  useEffect(() => {
    if (filteredAuthors.length === 0 && authors.length > 0) {
      setFilteredAuthors(authors);
    }
  }, [authors, filteredAuthors]);

  return (
    <div className="space-y-7.5 w-full">
      <div>
        <Label name="Add Authors" optional={true} />
        <SearchBar
          options={filteredAuthors}
          onSelect={item => {
            setSelectedAuthor(item);
            setCurrentSearchBarValue(item.name);
          }}
          onTextChange={value => setCurrentSearchBarValue(value)}
          selected={currentSearchBarValue}
          placeholder="Enter author name..."
          accessor={item => item.name}
        >
          {({ item }) => (
            <div className="p-3 cursor-pointer flex flex-col">
              <span className="text-dark-100">{item.name}</span>
              <span className="text-dark-50">{item.email}</span>
            </div>
          )}
        </SearchBar>
      </div>
      <Select
        selected={selectedType}
        setSelected={setSelectedType}
        options={authorTypes}
        placeholder="Select author type..."
        data-cy="author-type"
      />
      <button
        className={clsx("btn-sm", {
          "btn-primary": canSubmit,
          "bg-dark-25 text-white cursor-default": !canSubmit,
        })}
        onClick={addAuthorClick}
        type="button"
        disabled={!canSubmit}
      >
        Add Author
      </button>
      {selectedAuthors.length > 0 && (
        <AuthorTable
          selectedAuthors={selectedAuthors}
          removeAuthor={removeAuthor}
        />
      )}
    </div>
  );
}

export default AuthorListCreator;
