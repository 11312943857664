import * as React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import centerBannerPlaceholder from "@/images/center-banner-placeholder.png";

function CenterCard({ center }) {
  const { slug, shorthand, location, activeMembersCount } = center.attributes;
  const { centerImage, logoImage } = center.links;

  return (
    <article className="relative card-center mr-5 lg:mb-7 lg:mr-0">
      <Link
        to={`/centers/${slug}`}
        className="relative block overflow-hidden rounded max-h-full"
      >
        <picture>
          <img
            src={centerImage || centerBannerPlaceholder}
            className="block w-full card-center bg-dark-25 mr-5 lg:mb-7 lg:mr-0 rounded"
          />
        </picture>
        <div className="flex w-full h-full absolute bottom-0 left-0 linear-gradient">
          <div className="p-4 w-full h-full flex flex-col">
            <div className="">
              <SVG
                src={logoImage}
                className="max-h-12 svg-white"
                preserveAspectRatio="xMinYMid meet"
              />
            </div>
            <div className="mt-auto text-white">
              <span className="block font-h3">
                {shorthand === "PICI" ? "PICI Central" : `PICI @ ${shorthand}`}
              </span>
              <span className="font-body">{location}</span>
              <span className="font-body mx-2.5">|</span>
              <span className="font-body">{activeMembersCount} Members</span>
            </div>
          </div>
        </div>
      </Link>
    </article>
  );
}

export default CenterCard;
