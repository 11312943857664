import * as React from "react";
import Table from "@/components/shared/Table";
import { InventionTableType } from "./InventionTableType";

export type InventionSkeletonProps = {
  tabType: InventionTableType;
};

function InventionSkeleton({ tabType }: InventionSkeletonProps) {
  let extraColumns;
  if (tabType === "all") {
    extraColumns = (
      <>
        <Table.Cell>
          <div className="bg-dark-25 h-2 w-full rounded-full" />
        </Table.Cell>
        <Table.Cell>
          <div className="bg-dark-25 h-2 w-full rounded-full" />
        </Table.Cell>
      </>
    );
  }
  return (
    <Table.Row className="animate-pulse divide-x divide-lines">
      <Table.Cell expand={false}>
        <div className="flex flex-col gap-2">
          <div className="bg-dark-25 h-2 rounded-full w-full" />
          <div className="bg-dark-25 h-2 w-3/4 rounded-full" />
          <div className="bg-dark-25 h-2 w-20 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell expand={false}>
        <div className="bg-dark-25 h-2 w-full rounded-full" />
      </Table.Cell>
      <Table.Cell>
        <div className="flex flex-col gap-2">
          <div className="bg-dark-25 h-2 rounded-full w-full" />
          <div className="bg-dark-25 h-2 w-3/4 rounded-full" />
          <div className="bg-dark-25 h-2 w-20 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex">
          <div className="bg-dark-25 h-6 w-6 mr-1 rounded-full" />
          <div className="bg-dark-25 h-6 w-6 mr-1 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell expand={false}>
        <div className="bg-dark-25 h-2 w-full rounded-full" />
      </Table.Cell>
      {extraColumns}
      <Table.Cell>
        <div className="bg-dark-25 h-8 rounded mr-3" />
      </Table.Cell>
    </Table.Row>
  );
}

export default InventionSkeleton;
