import * as React from "react";
import { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { pluralize } from "@/utils/string";
import Filters from "@/components/shared/Inventions/Filters";
import InventionsTable from "@/components/shared/InventionsTable";

const fetchInventions = ({ pageParam = 0, queryKey }) => {
  const [_, centerId, filters] = queryKey;
  const params = paramBuilder({
    include: ["users", "externalUsers", "focusAreas"],
    fields: {
      inventions: [
        "title",
        "abstractNotes",
        "notes",
        "disclosure",
        "licensees",
        "review",
        "ipType",
        "inventorsList",
        "users",
        "externalUsers",
        "focusAreas",
        "caseStatusAtAffiliatedResearchInstitute",
        "updatedAt",
      ],
      users: ["fullName", "slug", "projectsCount"],
      externalUsers: ["name"],
      focusAreas: ["name"],
    },
  });

  const centerFilter = `filter[centers]=${centerId}`;
  const pagination = `page[number]=${pageParam + 1}&page[size]=5`;
  const url = `/api/inventions?${pagination}&${params}&${centerFilter}&${filters}`;

  return denormalizedFetcher(url);
};

function InventionTab({ center }) {
  const [filters, setFilters] = useState("");

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["centerInventions", center.id, filters],
    fetchInventions,
    {
      getNextPageParam: apiCanFetch,
    },
  );
  const { data: inventions, included, meta } = useInfiniteQueryResult(
    data?.pages,
  );
  const { isAtBottom } = useScrollPosition("section-router");

  useEffect(() => {
    if (isFetching) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetching, fetchNextPage, hasNextPage]);

  const recordCount = isFetching ? "" : meta.recordCount || 0;

  return (
    <div>
      <Filters
        setFilters={setFilters}
        recordCount={recordCount}
        filters={filters}
      />
      <div className="mt-7">
        <h3 className="text-byte-dark font-h3 mb-5">
          {isFetching
            ? "Retrieving inventions..."
            : `Displaying ${recordCount} ${pluralize(
                recordCount,
                "Invention",
              )}`}
        </h3>
        <div className="space-y-5 md:space-y-10">
          <InventionsTable
            widthTitle={500}
            {...{ inventions, included, isFetching, isFetchingNextPage }}
          />
        </div>
      </div>
    </div>
  );
}

export default InventionTab;
