import * as React from "react";
import { Routes, Route } from "react-router-dom";
import useCurrentUser from "@/hooks/useCurrentUser";
import AdminCenterNavigation from "./navigation/AdminCenterNavigation";
import NotFoundPage from "@/components/App/NotFoundPage";
import AdminDashboardPage from "@/components/pages/admin/AdminDashboardPage";
import MediaResourcesPage from "@/components/pages/admin/MediaResourcesPage";
import CentersPage from "@/components/pages/admin/CentersPage";
import FocusAreasPage from "@/components/pages/admin/FocusAreasPage";
import DailyNewsDetail from "@/components/pages/admin/DailyNewsPage/DailyNewsDetail";
import DailyNewsPage from "@/components/pages/admin/DailyNewsPage";
import AnnouncementsPage from "@/components/pages/admin/AnnouncementsPage";
import EventsPage from "@/components/pages/admin/EventsPage";
import CentersForm from "@/components/pages/admin/CentersPage/CentersForm";
import ResearchPage from "@/components/pages/admin/ResearchPage";
import InitiativesPage from "@/components/pages/admin/InitiativesPage";
import ResearchStudiesPage from "@/components/pages/admin/ResearchStudiesPage";
import ClinicalTrialsPage from "@/components/pages/admin/ClinicalTrialsPage";
import PeoplesPage from "@/components/pages/admin/PeoplesPage";
import InitiativeForm from "@/components/pages/admin/InitiativesPage/InitiativeForm";
import PatentDeadlinesPage from "@/components/pages/admin/PatentDeadlinesPage";
import InventionsPage from "@/components/pages/admin/InventionsPage";
import RetreatsPage from "@/components/pages/admin/RetreatsPage";
import RetreatsForm from "@/components/pages/admin/RetreatsPage/RetreatsForm";
import ArticlesPage from "@/components/pages/admin/ArticlesPage";
import ArticlesForm from "@/components/pages/admin/ArticlesPage/ArticlesForm";
import TopInventorsPage from "../pages/admin/TopInventorsPage/TopInventorsPage";

function AdminRouter() {
  const currentUser = useCurrentUser();
  return (
    <div
      id="admin-router"
      className="overflow-y-hidden min-h-screen w-full flex"
    >
      <Routes>
        {currentUser?.attributes.isStaff && (
          <>
            <Route path="admin" element={<AdminCenterNavigation />}>
              <Route index element={<AdminDashboardPage />} />
              <Route path="centers" element={<CentersPage />} />
              <Route path="research_documents" element={<ResearchPage />} />
              <Route
                path="research_studies"
                element={<ResearchStudiesPage />}
              />
              <Route path="focus_areas" element={<FocusAreasPage />} />
              <Route path="initiatives" element={<InitiativesPage />} />
              <Route path="inventions" element={<InventionsPage />} />
              <Route path="daily-news">
                <Route index element={<DailyNewsPage />} />
                <Route path=":id" element={<DailyNewsDetail />} />
              </Route>
              <Route path="announcements" element={<AnnouncementsPage />} />
              <Route path="events" element={<EventsPage />} />
              <Route path="retreats" element={<RetreatsPage />} />
              <Route path="articles" element={<ArticlesPage />} />
              <Route path="media_resources" element={<MediaResourcesPage />} />
              <Route path="clinical_trials" element={<ClinicalTrialsPage />} />
              <Route path="people" element={<PeoplesPage />} />
              <Route
                path="patent_deadlines"
                element={<PatentDeadlinesPage />}
              />
              <Route path="top_inventors" element={<TopInventorsPage />} />
            </Route>
            <Route path="admin">
              <Route path="centers">
                <Route path="new" element={<CentersForm />} />
                <Route path=":slug">
                  <Route path="edit" element={<CentersForm mode="edit" />} />
                </Route>
              </Route>
              <Route path="initiatives">
                <Route path="new" element={<InitiativeForm />} />
                <Route path=":slug">
                  <Route path="edit" element={<InitiativeForm editing />} />
                </Route>
              </Route>
              <Route path="retreats">
                <Route path="new" element={<RetreatsForm />} />
                <Route path=":slug">
                  <Route path="edit" element={<RetreatsForm />} />
                  <Route path="articles">
                    <Route path="new" element={<ArticlesForm />} />
                    <Route path=":id">
                      <Route path="edit" element={<ArticlesForm />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="articles">
                <Route path="new" element={<ArticlesForm />} />
              </Route>
            </Route>
          </>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default AdminRouter;
