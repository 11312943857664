import * as React from "react";
import clsx from "clsx";
import Input from "@/components/shared/Input";
import Autocomplete from "@/components/shared/Autocomplete";
import { Draggable } from "react-beautiful-dnd";

function ContactPointItem({
  index,
  userContact,
  updateUserContact,
  removeUserContact,
  usersSuggestions,
  className,
}) {
  const handleChangeRole = event => {
    const contactUpdated = { ...userContact, role: event.target.value };
    updateUserContact(contactUpdated, index);
  };

  const handleChangeUser = user => {
    const contactUpdated = { ...userContact, user_id: user?.id, user: user };
    updateUserContact(contactUpdated, index);
  };

  return (
    <Draggable draggableId={`${index}`} index={index} key={index}>
      {provided => (
        <div
          className={clsx("bg-tint-10 flex p-2", className)}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="text-xl my-auto px-2" {...provided.dragHandleProps}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 1024 1024"
              className="fill-current"
            >
              <path d="M273.066 204.8c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM750.933 204.8c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM273.066 614.4c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM750.933 614.4c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM273.066 1024c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM750.933 1024c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4z"></path>
            </svg>
          </div>
          <Autocomplete
            selected={userContact.user}
            suggestions={usersSuggestions}
            setSelected={handleChangeUser}
            className="w-1/2"
          />
          <Input
            wrapperClassName="w-1/2"
            className="h-[40px] ml-3"
            value={userContact.role || ""}
            onChange={handleChangeRole}
            placeholder="Center role (e.g. Center Director)"
          />
          <button
            type="button"
            className="text-xl ml-3"
            onClick={() => removeUserContact(index)}
          >
            <i className="ri-delete-bin-6-line" />
          </button>
        </div>
      )}
    </Draggable>
  );
}

export default ContactPointItem;
