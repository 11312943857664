import React from "react";
import clsx from "clsx";
import Table from "@/components/shared/Table";
import { render } from "@/components/shared/Admin/Columns";
import ReactTooltip from "react-tooltip";

const tooltipContent = (subjectIp, peripheralip, bothIp) =>
  `${subjectIp} Subject IP<br />${peripheralip} Peripheral IP<br />${bothIp} Subject & Peripheral`;

const columns = [
  {
    name: "name",
    label: "Center",
    accessor: item => item[0],
    render: value => value.name,
    td: { className: "!py-0.5 font-bold" },
    th: {},
  },
  {
    name: "pending",
    label: "Pending",
    accessor: item => item[0],
    render: (value, [_, item], rowIndex) => {
      const tooltipId = `pending_${rowIndex}`;
      return (
        <>
          <div
            className="cursor-pointer"
            data-tip={tooltipContent(
              item?.pending.subject_ip,
              item?.pending.peripheral_ip,
              item?.pending.both,
            )}
            data-for={tooltipId}
          >
            {value.pending}
          </div>
          <ReactTooltip id={tooltipId} multiline={true} />
        </>
      );
    },
    td: { className: "!py-0.5 text-right hover:bg-tertiary-light" },
    th: { className: "text-right" },
  },
  {
    name: "accepted",
    label: "Accepted",
    accessor: item => item[0],
    render: (value, [_, item], rowIndex) => {
      const tooltipId = `accepted${rowIndex}`;
      return (
        <>
          <div
            className="cursor-pointer"
            data-tip={tooltipContent(
              item?.accepted.subject_ip,
              item?.accepted.peripheral_ip,
              item?.accepted.both,
            )}
            data-for={tooltipId}
          >
            {value.accepted}
          </div>
          <ReactTooltip id={tooltipId} multiline={true} />
        </>
      );
    },
    td: { className: "!py-0.5 text-right hover:bg-tertiary-light" },
    th: { className: "text-right" },
  },
  {
    name: "declined",
    label: "Declined",
    accessor: item => item[0],
    render: (value, [_, item], rowIndex) => {
      const tooltipId = `declined_${rowIndex}`;
      return (
        <>
          <div
            className="cursor-pointer"
            data-tip={tooltipContent(
              item?.declined.subject_ip,
              item?.declined.peripheral_ip,
              item?.declined.both,
            )}
            data-for={tooltipId}
          >
            {value.declined}
          </div>
          <ReactTooltip id={tooltipId} multiline={true} />
        </>
      );
    },
    td: { className: "!py-0.5 text-right hover:bg-tertiary-light" },
    th: { className: "text-right" },
  },
  {
    name: "name",
    label: "Total",
    accessor: item => item[0],
    render: (value, [_, item], rowIndex) => {
      const tooltipId = `total_${rowIndex}`;
      const totalSubjectIp =
        item?.pending.subject_ip +
        item?.accepted.subject_ip +
        item?.declined.subject_ip;
      const totalPeripheralIp =
        item?.pending.peripheral_ip +
        item?.accepted.peripheral_ip +
        item?.declined.peripheral_ip;
      const totalBoth =
        item?.pending.both + item?.accepted.both + item?.declined.both;
      const tooltipContent = `${totalSubjectIp} Subject IP<br />${totalPeripheralIp} Peripheral IP<br />${totalBoth} Subject & Peripheral`;
      return (
        <>
          <div
            className="cursor-pointer"
            data-tip={tooltipContent}
            data-for={tooltipId}
          >
            {value.pending + value.accepted + value.declined}
          </div>
          <ReactTooltip id={tooltipId} multiline={true} />
        </>
      );
    },
    td: { className: "!py-0.5 font-bold text-right hover:bg-tertiary-light" },
    th: { className: "text-right" },
  },
];

const itemValue = (item, column) => {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item[column.name];
  }
};

const RowSkeleton = ({ className = "" }) => (
  <tr className={clsx("!border-0", className)}>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 !whitespace-normal font-bold text-dark-75 max-w-[240px]">
      <div className="h-2 w-[200px] bg-slate-200 bg-dark-25 rounded"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-2 w-[50px] bg-slate-200 bg-dark-25 rounded ml-auto"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-2 w-[50px] bg-slate-200 bg-dark-25 rounded ml-auto"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-2 w-[50px] bg-slate-200 bg-dark-25 rounded ml-auto"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-2 w-[50px] bg-slate-200 bg-dark-25 rounded ml-auto"></div>
    </td>
  </tr>
);

function InventionsHistory({ data, className, isFetching }) {
  return (
    <div
      className={clsx("bg-white border border-lines rounded shadow", className)}
    >
      <header className="px-5 pt-3.75">
        <h2 className="text-dark-100 text-22">Inventions History</h2>
      </header>
      <Table className="border border-transparent mb-3 px-5">
        <Table.Header className="bg-transparent text-dark-50">
          {columns.map((column, i) => (
            <Table.HeaderCell
              key={i}
              className={clsx(
                "text-11 pb-1 border-b border-lines",
                column.th?.className,
              )}
            >
              {column.label}
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body>
          {isFetching ? (
            <>
              <RowSkeleton />
              <RowSkeleton className="bg-tint-10" />
              <RowSkeleton />
              <RowSkeleton className="bg-tint-10" />
              <RowSkeleton />
              <RowSkeleton />
              <RowSkeleton className="bg-tint-10" />
              <RowSkeleton />
              <RowSkeleton className="bg-tint-10" />
            </>
          ) : (
            data &&
            data.map((item, rowIndex) => (
              <Table.Row
                key={rowIndex}
                className={clsx("!border-0", { "bg-tint-10": rowIndex % 2 })}
              >
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={column.td?.className}
                    expand
                  >
                    {render(itemValue(item, column), column, item, rowIndex)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
      <div className="text-dark-50 mb-8 pl-10 text-14">
        Note: Some inventions have more than one participating
        center/institution
      </div>
    </div>
  );
}

export default InventionsHistory;
