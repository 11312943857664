import * as React from "react";
import { Text } from "slate";
import escapeHtml from "escape-html";

type LeafType = {
  text: string;
  [key: string]: any;
};

type ElementType = {
  type: string;
  children: (ElementType | LeafType)[];
  [key: string]: any;
};

type SlateType = ElementType[];

function Serialize({ node, specialLinkColor = false }) {
  if (Text.isText(node)) {
    let el: any = node.text;
    if (node.bold) el = <b>{el}</b>;
    if (node.italic) el = <i>{el}</i>;
    if (node.underline) el = <u>{el}</u>;
    return el;
  }

  const children = node.children.map((child, index) => (
    <Serialize key={index} node={child} specialLinkColor={specialLinkColor} />
  ));

  switch (node.type) {
    case "paragraph":
      return (
        <p>
          {children}
          <br />
        </p>
      );
    case "link":
      return (
        <a
          href={escapeHtml(node.url)}
          className={`link-primary ${specialLinkColor ? "text-blue-400" : ""}`}
        >
          {children}
        </a>
      );
    case "bulleted-list":
      return <ul className="list-disc list-inside">{children}</ul>;
    case "list-number":
      return <ol className="list-decimal list-outside ml-4">{children}</ol>;
    case "list-item":
      return <li>{children}</li>;
    default:
      return children;
  }
}

// This function receives a json with slate format
// and convert it to jsx
// input:
// [
//   { type: "paragraph", children: [{ text: "Some" }, { text: "text", bold: true, italic: true }] },
//   { type: "paragraph", children: [{ text: "Other" }, { text: "text", underline: true }] }
// ]
// output: <p>Some<b><i>text</i></b></p><p>Other<u>text</u></p>

function RichText({
  value,
  className = "",
  textRef,
  specialLinkColor = false,
}: {
  value: SlateType;
  className?: string;
  textRef?: any;
  specialLinkColor?: boolean;
}) {
  if (!value) return null;
  return (
    <div className={className} ref={textRef}>
      {value.map((node, index) => (
        <Serialize
          key={index}
          node={node}
          specialLinkColor={specialLinkColor}
        />
      ))}
    </div>
  );
}

export default RichText;
