import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import csrfFetch from "@/utils/csrf-fetch";
import LoginLayout from "@/components/shared/LoginLayout";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Your email address is required."),
});

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const resetPassword = (item, { setSubmitting }) => {
    const request = {
      method: "POST",
      body: JSON.stringify(item),
    };

    csrfFetch("/passwords", request).then(async response => {
      if (response?.ok) {
        navigate("/check-your-inbox");
      } else {
        const obj = await response?.json();
        setError(obj?.message);
        setSubmitting(false);
      }
    });
  };

  return (
    <LoginLayout>
      <h2 className="text-dark-100 font-h2 mb-2">Forgot Password</h2>
      <p className="font-body text-dark-75">
        Please enter the email address of your PICI account. We will send you an
        email with instructions to create a new password.
      </p>
      {error && (
        <div className="mt-4 bg-red-100 text-error py-2.5 px-4 rounded flex space-x-2.5">
          <i className="ri-error-warning-line font-body-large leading-tight" />
          <p className="font-meta ">{error}</p>
        </div>
      )}
      <section className="mt-6">
        <Formik
          initialValues={{ email: "" }}
          onSubmit={resetPassword}
          validationSchema={ForgotPasswordSchema}
          enableReinitialize
        >
          {({ ...props }) => (
            <Form>
              <div className="mb-0.25">
                <FormBlock name="email">
                  <Input type="email" placeholder="Email Address" />
                </FormBlock>
              </div>
              <button
                type="submit"
                disabled={props.isSubmitting}
                className={clsx(
                  "mt-8.5 btn-md w-full",
                  props.isSubmitting ? "btn-disabled" : "btn-primary",
                )}
              >
                Send Password Reset Instructions
              </button>
            </Form>
          )}
        </Formik>
      </section>
    </LoginLayout>
  );
}

export default ForgotPasswordPage;
