import React from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { Link } from "react-router-dom";

type LinkableNameColumnProps = {
  text: React.ReactNode;
  to: string;
  isBlank: boolean;
  className?: string;
  classNameLink?: string;
  isArchived?: boolean;
};

export const LinkableNameColumn = ({
  text,
  to,
  isBlank,
  className = "",
  classNameLink = "link text-dark-75 font-meta-bold whitespace-normal",
  isArchived = false,
}: LinkableNameColumnProps) => {
  return (
    <div className={clsx("", { className, "flex items-center": !isArchived })}>
      <a
        className={classNameLink}
        href={to}
        target={isBlank ? "_blank" : "_self"}
      >
        {text}
      </a>
      {isArchived && (
        <label className="ml-2 font-label text-[10px] leading-[10px] px-1.5 py-0.5 bg-gold-1 text-gold-2 max-w-min rounded-3px">
          Archived
        </label>
      )}
    </div>
  );
};

type MembersColumnProps = {
  id: number;
  memberList: any[];
  maxAvatar?: number;
};

export const MembersColumn = ({
  id,
  memberList,
  maxAvatar = 10,
}: MembersColumnProps) => {
  const members = memberList.slice(0, maxAvatar);
  const tooltipList = memberList.slice(maxAvatar);
  return (
    <div className="flex flex-wrap">
      {members.map((member, index) => (
        <a
          href={member.links.profile}
          target="_BLANK"
          data-tip={member.attributes.fullName}
          key={member.id || index}
        >
          <img
            src={member.links.avatar}
            className="h-6 w-6 rounded-full align-middle"
          ></img>
        </a>
      ))}
      <ReactTooltip />
      {tooltipList.length > 0 && (
        <>
          <a
            data-tip
            data-for={`${id}-memberslist`}
            className="h-6 w-6 rounded-full bg-lines text-11 flex items-center justify-center"
          >
            +{tooltipList.length}
          </a>
          <ReactTooltip
            id={`${id}-memberslist`}
            effect="solid"
            backgroundColor="#000000"
            delayHide={100}
            delayUpdate={100}
          >
            <div className="overflow-y-auto max-h-48 scrollbar-hide ">
              {tooltipList.map(member => (
                <div key={member.id}>
                  <a
                    href={member.links.profile}
                    target="_BLANK"
                    className="hover:text-primary"
                  >
                    {member.attributes.fullName}
                  </a>
                </div>
              ))}
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export type ActionButtonItemType = {
  text: string;
  to?: string;
  name?: string;
  isBlank?: boolean;
  variant?:
    | "btn-primary"
    | "btn-white"
    | "btn-warning"
    | "btn-admin"
    | "btn-primary-outline";
  onClick?: () => void;
  isSPA?: boolean;
};

type ActionButtonsColumnProps = {
  items: ActionButtonItemType[];
};

export const ActionButtonsColumn = ({ items }: ActionButtonsColumnProps) => {
  return (
    <>
      {items.map((item, index) => {
        if (item.isSPA) {
          return (
            <Link
              key={index}
              to={item.to || ""}
              target={item.isBlank ? "_blank" : "_self"}
              data-testid={item.name}
              onClick={item.onClick}
              className={clsx(
                "border border-lines font-bold py-5px px-3.75 text-center inline-grid",
                { "mr-2": items.length !== index + 1 },
                `${item.variant}`,
              )}
            >
              {item.text}
            </Link>
          );
        }
        return (
          <a
            key={index}
            className={clsx(
              "border border-lines font-bold py-5px px-3.75 text-center inline-grid",
              { "mr-2": items.length !== index + 1 },
              `${item.variant}`,
            )}
            href={item.to}
            target={item.isBlank ? "_blank" : "_self"}
            data-testid={item.name}
            onClick={item.onClick}
          >
            {item.text}
          </a>
        );
      })}
    </>
  );
};

export const CentersColumn = ({
  centerList,
  testid = "",
}: {
  centerList: any[];
  testid?: string;
}) => (
  <ul data-testid={testid}>
    {centerList.map((center, i) => (
      <li key={i}>{center}</li>
    ))}
  </ul>
);

export const itemValue = (item, column) => {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item.attributes[column.name];
  }
};

export const render = (value, column, item, rowIndex = null) => {
  if (column.render) {
    return column.render(value, item, rowIndex);
  } else {
    return value;
  }
};
