import React from "react";
import { format } from "date-fns";
import Table from "@/components/shared/Table";
import { Events, SortMovement } from "./interfaces";
import { isEmpty } from "lodash";

type ActiveEventsTableProps = {
  events: Events[];
  tableTitle: string;
  disabledSorting?: boolean;
  showDisplay?: boolean;
  displayActions?: (element: Events, movement: SortMovement) => void;
  openForm: Function;
};

type EventsTableProps = {
  event: Events;
  index: number;
  eventLength: number;
  disabledSorting?: boolean;
  showDisplay?: boolean;
  displayActions?: (element: Events, movement: SortMovement) => void;
  openForm: Function;
};

const Title = ({ title, link }) => {
  let content = title;
  if (link) {
    content = (
      <a className="hover:text-indigo-600" href={link} target="_blank">
        {title}
      </a>
    );
  }
  return content;
};

const TableRow = ({
  event,
  index,
  showDisplay,
  disabledSorting,
  eventLength,
  displayActions,
  openForm,
}: EventsTableProps) => {
  const { attributes, relationships } = event;

  return (
    <Table.Row
      key={event.id}
      className="divide-x divide-lines"
      data-sorted-item-id={event.id}
      data-sorted-item-position={attributes.position}
    >
      {showDisplay && displayActions && (
        <Table.Cell className="flex items-center !px-3 !py-2">
          <div className="flex flex-col mr-4">
            <button
              className="h-[14px] text-xl leading-none text-gray-400 disabled:text-gray-300"
              disabled={index === 0 || disabledSorting}
              onClick={() => displayActions(event, "move_higher")}
            >
              <i className="ri-arrow-up-s-fill" />
            </button>
            <button
              className="h-[14px] text-xl leading-none text-gray-400 disabled:text-gray-300"
              disabled={index === eventLength - 1 || disabledSorting}
              onClick={() => displayActions(event, "move_lower")}
            >
              <i className="ri-arrow-down-s-fill" />
            </button>
          </div>
          <p className="font-bold"> {attributes.position}</p>
        </Table.Cell>
      )}
      <Table.Cell className="font-bold">
        <Title title={attributes.title} link={attributes.link} />
      </Table.Cell>
      <Table.Cell>
        {isEmpty(relationships?.user?.data) ? (
          "- -"
        ) : (
          <a
            className="hover:text-indigo-600"
            href={relationships?.user?.data.links.show}
          >
            {relationships?.user?.data.attributes.fullName}
          </a>
        )}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(attributes.createdAt), "MMMM dd, yyyy")}
      </Table.Cell>
      <Table.Cell>{attributes.hostedAt}</Table.Cell>
      <Table.Cell className="w-[100px]">
        <button
          className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-0.5 px-3.75"
          onClick={() => openForm(event, true)}
          data-testid={attributes.title}
        >
          Edit
        </button>
      </Table.Cell>
    </Table.Row>
  );
};

function ActiveEventsTable({
  events,
  showDisplay,
  tableTitle,
  displayActions,
  disabledSorting,
  openForm,
}: ActiveEventsTableProps) {
  return (
    <>
      <h2 className="flex mt-5 ml-2 text-22 font-medium text-dark-100">
        <i className="ri-calendar-todo-line text-dark-50 mr-3" />
        {tableTitle}
      </h2>
      <Table className="border border-transparent text-sm table-fixed mb-8">
        <Table.Header className="bg-transparent text-dark-50">
          {showDisplay && (
            <Table.HeaderCell className="text-11 pb-2">
              Display
            </Table.HeaderCell>
          )}
          <Table.HeaderCell className="text-11 pb-2">Title</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Author</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">
            Date posted
          </Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Date(s)</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Actions</Table.HeaderCell>
        </Table.Header>
        <Table.Body className="border border-lines">
          {events.map((event, key) => {
            return (
              <TableRow
                key={key}
                event={event}
                index={key}
                showDisplay={showDisplay}
                disabledSorting={disabledSorting}
                eventLength={events.length}
                displayActions={displayActions}
                openForm={openForm}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

export default ActiveEventsTable;
