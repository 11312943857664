import * as React from "react";
import clsx from "clsx";
import logoLogin from "@/images/logo-login@2x.png";

function LoginLayout({ children }) {
  return (
    <div
      className={clsx(
        "w-full min-h-screen h-auto bg-login-image bg-cover bg-center",
        "flex flex-col justify-center items-center",
      )}
    >
      <div className="max-w-[410px] bg-tint rounded p-10 m-4">
        <header className="flex w-full justify-center mb-10">
          <img src={logoLogin} alt="" className="h-12" />
        </header>
        <main>
          <section>{children}</section>
        </main>
      </div>
    </div>
  );
}

export default LoginLayout;
