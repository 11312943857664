import * as React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import fetch from "cross-fetch";
import ReactTooltip from "react-tooltip";
import Tooltip from "@/components/shared/Tooltip";
import Table from "@/components/shared/Table";
import ToogleButton from "@/components/shared/ToogleButton";

function itemValue(item, column) {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item.attributes[column.name];
  }
}

function render(value, column, item) {
  if (column.render) {
    return column.render(value, item);
  } else {
    return value;
  }
}

function EditButton({ item, openForm }) {
  return (
    <div
      className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-5px px-3.75 text-center cursor-pointer"
      onClick={() => {
        openForm(item);
      }}
      data-testid={item.attributes.name}
    >
      Edit
    </div>
  );
}

function FeaturedHeader() {
  return (
    <>
      <div className="flex flex-nowrap justify-between items-center">
        <span>Featured </span>
        <Tooltip>
          <Tooltip.Trigger>
            <i className="ri-question-mark bg-white rounded-full w-4 h-4 flex items-center justify-center"></i>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <span>
              An Announcement can be featured at the top of the Member Center
              Dashboard. Only one Announcement can be featured at any given
              time.
            </span>
          </Tooltip.Content>
        </Tooltip>
      </div>
    </>
  );
}

function FeaturedButton({ value }) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["announcements"]);
    },
  });

  const handleFeatured = () => {
    if (value.attributes.isFeatured) {
      mutateAsync(
        fetch(`/api/announcements/${value.id}/clear_featured`, {
          method: "PATCH",
        }),
      );
    }
    mutateAsync(
      fetch(`/api/announcements/${value.id}/featured`, {
        method: "PATCH",
      }),
    );
  };
  return (
    <ToogleButton
      active={value.attributes.isFeatured}
      onClick={handleFeatured}
    />
  );
}

function AnnouncementTable({ data, openForm }) {
  const columns = [
    {
      label: "Subject",
      name: "subject",
      td: { className: "min-w-400px max-w-574px pt-9px pb-9px" },
      render: (value, item) => (
        <div className="flex items-center">
          <i className="ri-links-line text-primary pr-1"></i>
          <a
            className="link text-dark-75 font-meta-bold whitespace-normal"
            href={`/announcements/${item.id}`}
            target="blank"
          >
            {value}
          </a>
        </div>
      ),
    },
    {
      label: "Author",
      name: "userFullname",
      td: { className: "pt-9px pb-9px" },
      render: (_, item) => (
        <Link
          className="link text-dark-75 font-meta-bold"
          to={item.attributes.userProfile}
        >
          {item.attributes.userFullname}
        </Link>
      ),
    },
    {
      label: "Date Posted",
      name: "lastUpdatedBy",
      td: { className: "pt-9px pb-9px" },
      accessor: item => item.attributes.createdAt,
      render: value => (
        <p className="font-meta">{formatDistanceToNow(new Date(value))}</p>
      ),
    },
    {
      label: "Read by",
      name: "readersData",
      td: { className: "pt-9px pb-9px" },
      accessor: item => item.attributes.readersData,
      render: value => (
        <div className="flex flex-wrap">
          {value.map(reader => (
            <a
              href={reader.profile}
              target="blank"
              data-tip={reader.name}
              data-effect="solid"
              key={reader.name}
            >
              <img
                src={reader.avatar_url}
                className="h-6 w-6 rounded-full align-middle"
              ></img>
            </a>
          ))}
          <ReactTooltip />
        </div>
      ),
    },
    {
      name: "featured",
      label: <FeaturedHeader />,
      td: { className: "w-100px pt-9px pb-9px" },
      accessor: item => item,
      render: value => <FeaturedButton value={value} />,
    },
    {
      name: "actions",
      label: "Actions",
      td: { className: "w-[90px] pt-9px pb-9px" },
      accessor: item => item,
      render: value => <EditButton item={value} openForm={openForm} />,
    },
  ];

  return (
    <Table className="border border-transparent">
      <Table.Header className="bg-transparent text-dark-50">
        {columns.map((column, i) => (
          <Table.HeaderCell key={i} className="text-11 pb-2 pl-4 pr-4">
            {column.label}
          </Table.HeaderCell>
        ))}
      </Table.Header>
      <Table.Body className="border border-lines">
        {data.map((item, i) => (
          <Table.Row key={i} className="divide-x divide-lines">
            {columns.map((column, j) => (
              <Table.Cell
                key={j}
                className={column.td?.className}
                expand={false}
              >
                {render(itemValue(item, column), column, item)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default AnnouncementTable;
