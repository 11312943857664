import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import csrfFetch from "@/utils/csrf-fetch";
import LoginLayout from "@/components/shared/LoginLayout";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid e-mail.")
    .required("E-mail is required."),
  password: Yup.string().required("Password is required."),
});

function LoginPage() {
  const [error, setError] = useState(null);

  const login = (item, { setSubmitting }) => {
    const request = {
      method: "POST",
      body: JSON.stringify(item),
    };

    csrfFetch("/session", request)
      .then(res => {
        if (res?.ok) {
          // When the Login action has been executed from the Discourse Connect URL("/discourse/start/)"
          // The redirection after log in should be modified to return to the Discourse Forum Dashboard page.
          if (res.url.includes("discourseconnect/start")) {
            // This redirection will execute a new Log in into Discourse,
            // Discourse will validate again if the user is log in
            // And since the user is alredy log in, the SSO functionality
            // Will be executed and the user will have access to discourse.
            window.location.replace(
              `${process.env.VITE_DISCOURSE_APP_URL}/session/sso?return_path=${process.env.VITE_DISCOURSE_APP_URL}`,
            );
          } else {
            window.location.replace(res.url);
          }
        } else {
          return res?.json();
        }
      })
      .then(obj => {
        setError(obj?.message);
        setSubmitting(false);
      });
  };

  return (
    <LoginLayout>
      <h2 className="text-dark-100 font-h2 mb-2">Member Center</h2>
      <p className="font-body text-dark-75">
        Welcome to the Parker Institute for Cancer Immunotherapy. The PICI
        Member Center's tools and resources enable participating researchers to
        connect, communicate and collaborate.
      </p>
      {error && (
        <div className="mt-4 bg-red-100 text-error py-2.5 px-4 rounded flex space-x-2.5">
          <i className="ri-error-warning-line font-body-large" />
          <p className="font-meta ">{error}</p>
        </div>
      )}
      <section className="mt-6">
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={login}
          validationSchema={LoginSchema}
          enableReinitialize
        >
          {({ ...props }) => (
            <Form>
              <div className="space-y-3 mb-2">
                <FormBlock name="email">
                  <Input type="email" placeholder="Email Address" />
                </FormBlock>
                <FormBlock name="password">
                  <Input type="password" placeholder="Password" />
                </FormBlock>
              </div>
              <Link
                to="/forgot-password"
                className="text-dark-50 font-meta link"
              >
                Forgot password?
              </Link>
              <button
                type="submit"
                disabled={props.isSubmitting}
                className={clsx(
                  "mt-7.5 btn-md w-full",
                  props.isSubmitting ? "btn-disabled" : "btn-primary",
                )}
              >
                Log In
              </button>
            </Form>
          )}
        </Formik>
      </section>
    </LoginLayout>
  );
}

export default LoginPage;
