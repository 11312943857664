export function calculateOverdue(dates, overdueDate) {
  if (dates == undefined) {
    return [];
  }
  return dates.map(patent => {
    patent.pctOverdue = false;
    patent.nationalOverdue = false;
    // it could be null
    if (patent.pctConversionDeadline) {
      const pctDate = new Date(patent.pctConversionDeadline).getTime();
      if (pctDate < overdueDate) {
        patent.pctOverdue = true;
      }
    }

    if (patent.nationalStageDeadline) {
      const nationalDate = new Date(patent.nationalStageDeadline).getTime();
      if (nationalDate < overdueDate) {
        patent.nationalOverdue = true;
      }
    }

    return patent;
  });
}
