import React, { ReactNode } from "react";
import clsx from "clsx";
import { isString } from "lodash";
import { Link } from "react-router-dom";

type AdminHeaderProp = {
  title: string;
  description?: React.ReactNode;
  children?: ReactNode;
  toolbar?: ReactNode;
  className?: string;
  linkText?: string;
  linkTo: string | Function;
  linkTarget?: string;
  linkReloadDocument?: boolean;
  disabledLinkTo?: boolean;
  exportText?: string;
  exportLinkTo?: string;
  spa?: boolean;
};

const AdminHeader = ({
  title,
  description,
  children = null,
  toolbar = null,
  className,
  linkText,
  linkTo,
  linkReloadDocument = false,
  disabledLinkTo = false,
  linkTarget = "_self",
  exportText,
  exportLinkTo,
  spa = true,
}: AdminHeaderProp) => {
  const linkProps: {
    className: string;
    target: string;
    reloadDocument?: boolean;
    to: any;
    onClick?: any;
  } = {
    className: clsx("btn-primary px-5 py-1.5 font-meta-bold rounded-sm", {
      "opacity-50 cursor-default": disabledLinkTo,
      "focus:outline-none focus:ring-0": !disabledLinkTo,
    }),
    target: linkTarget,
    to: linkTo,
    onClick: linkTo,
  };
  if (isString(linkTo)) {
    delete linkProps.onClick;
    linkProps.reloadDocument = linkReloadDocument;
  }
  if (disabledLinkTo) {
    delete linkProps.onClick;
    linkProps.to = "";
  }
  return (
    <header
      className={clsx(
        "h-15 w-full bg-white shadow-sm inset-0 sticky",
        className,
      )}
    >
      <div className="flex flex-wrap items-center py-3 px-7.5">
        <div className="flex flex-1 pr-3.75 items-baseline flex-wrap">
          <h1 className="text-dark-100 font-h2 m-0 pr-3.75">{title}</h1>
          <p className="font-meta m-0 text-dark-50">{description}</p>
        </div>
        <div className="mr-7">{toolbar}</div>
        {exportText && exportLinkTo && (
          <div className="text-primary mr-7.5 font-meta flex">
            <i className="ri-download-2-line mr-2" />
            <a className="hover:underline" href={exportLinkTo}>
              {exportText}
            </a>
          </div>
        )}
        {spa ? (
          <Link {...linkProps}>{linkText}</Link>
        ) : (
          <a className={linkProps.className} href={linkProps.to}>
            {linkText}
          </a>
        )}
      </div>
      {!!children && (
        <div className="w-full bg-transparent z-0">{children}</div>
      )}
    </header>
  );
};

export default AdminHeader;
