import { useLayoutEffect } from "react";

export function useTextareaAutosize($element, minHeight, value) {
  useLayoutEffect(() => {
    if ($element && $element.current && minHeight !== false) {
      $element.current.style.height = 0;
      const newHeight = Math.max($element.current.scrollHeight, minHeight);
      $element.current.style.height = newHeight + "px";
    }
  }, [minHeight, $element, value]);
}
