import * as React from "react";
import { useEffect, useState } from "react";
import InitiativesTable from "./InitiativesTable";
import InitiativesSkeletonTable from "./InitiativesSkeletonTable";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import Pill from "@/components/shared/Pill";
import InitiativesFilter from "./InitiativesFilter";

const fetchProjects = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const params = paramBuilder({
    include: ["projectLeads", "members"],
    fields: {
      initiatives: [
        "name",
        "createdAt",
        "centersAndInstitutions",
        "members",
        "projectLeads",
        "featured",
        "archived",
      ],
      users: ["fullName", "slug", "avatarUrl"],
    },
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `/api/initiatives?${pagination}&${params}&${filters}`;

  return denormalizedFetcher(url);
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function InitiativesPage() {
  const [filters, setFilters] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [debouncedTitleFilter] = useDebounce(searchText, 500);
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["projects", filters], fetchProjects, {
    getNextPageParam: apiCanFetch,
  });

  let query = useQuery();

  const { data: projects, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("initiatives");

  const selectedStatus = query.get("archived");

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        centers: [query.get("centers")],
        name: debouncedTitleFilter,
        ...(selectedStatus &&
          selectedStatus !== "all" && { archived: selectedStatus }),
      },
      sort: [query.get("sort")],
    });
    setFilters(filters);
  }, [query, debouncedTitleFilter]);

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  return (
    <div className="bg-primary-lighter h-screen overflow-hidden">
      <AdminHeader
        title="Initiatives"
        description="Potential projects under consideration, internal projects, and other initiatives at PICI."
        className="!h-[106px]"
        linkText="+ New Initiative"
        linkTo="/admin/initiatives/new"
        exportText="Download CSV"
        exportLinkTo="/admin/projects/export.csv?type=Initiative"
      >
        <div className="bg-tint flex py-2 px-7.5 h-12">
          <Pill classNames="mr-5 flex-none">
            {isFetching ? (
              <i className="animate-spin absolute ri-loader-line left-11"></i>
            ) : (
              meta?.recordCount || "--"
            )}
          </Pill>
          <InitiativesFilter setSearchText={setSearchText} />
        </div>
      </AdminHeader>
      <article
        id="initiatives"
        className="px-6 overflow-x-hidden h-vh-60 bg-primary-lighter"
      >
        {isFetching && !isFetchingNextPage ? (
          <InitiativesSkeletonTable />
        ) : (
          <InitiativesTable initiatives={projects} />
        )}
      </article>
    </div>
  );
}

export default InitiativesPage;
