import * as React from "react";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ellipsis } from "ellipsed";
import * as R from "ramda";
import RichText from "../RichText/RichText";

const MENTION_TYPES = {
  lead_author: { singular: "is a lead author", plural: "are lead authors" },
  senior_author: {
    singular: "is a senior author",
    plural: "are senior authors",
  },
  author: { singular: "is an author", plural: "are authors" },
  coauthor: { singular: "is a coauthor", plural: "are coauthors" },
  contributor: { singular: "is a contributor", plural: "are contributors" },
  featured: { singular: "is featured", plural: "are featured" },
  interviewed: { singular: "is interviewed", plural: "are interviewed" },
  quoted: { singular: "is quoted", plural: "are quoted" },
  mentioned: { singular: "is mentioned", plural: "are mentioned" },
};

const andOrComma = (size, index) => {
  if (index === size - 2) return " and ";
  if (index === size - 1) return "";
  return ", ";
};

const byMentionType = R.groupBy(mention => mention.type);

function AvatarList({ mentions }) {
  const totalAvatars = mentions.length;

  return (
    <div className="flex self-baseline min-w-max">
      {totalAvatars > 3 ? (
        <>
          <img className="rounded-full h-8 w-8" src={mentions[0].linkAvatar} />
          <img className="rounded-full h-8 w-8" src={mentions[1].linkAvatar} />
          <span className="font-meta-bold text-dark-75 flex justify-center items-center rounded-full bg-dark-25 h-8 w-8 flex-shrink-0">
            +{totalAvatars - 2}
          </span>
        </>
      ) : (
        <span className="flex gap-1">
          {mentions?.map((mention, index) => (
            <img
              className="rounded-full h-8 w-8"
              src={mention.linkAvatar}
              key={index}
            />
          ))}
        </span>
      )}
    </div>
  );
}

function MentionList({ mentions }) {
  const mentionsByType = byMentionType(mentions);
  const mentionTypes = Object.keys(mentionsByType);

  return (
    <div className="flex flex-wrap items-center text-dark-75 font-meta gap-1">
      {mentionTypes?.map((type, index) => {
        const authors = mentionsByType[type];
        return (
          <React.Fragment key={type}>
            {authors?.map((author, index) => (
              <span key={author.id}>
                <Link
                  className="link font-meta-bold text-dark-100"
                  to={author.linkProfile}
                >
                  {author.fullName}
                </Link>
                {andOrComma(authors.length, index)}
              </span>
            ))}
            <span>
              {mentionsByType[type].length === 1
                ? MENTION_TYPES[type].singular
                : MENTION_TYPES[type].plural}
              {index < mentionTypes.length - 1 ? "," : ""}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
}

function NewsItem({ news, parentSlug, prev }) {
  const descriptionRef = useRef();
  const replaceString = `... <a href=${parentSlug} class="text-dark-100 font-body-bold hover:underline">Read more</a>`;

  useEffect(() => {
    if (prev) {
      const descriptionParagraph = descriptionRef.current;
      descriptionParagraph &&
        ellipsis(descriptionParagraph, 3, {
          replaceStr: replaceString,
        });
    }
  }, [replaceString, prev]);

  return (
    <article className="py-5">
      <h2 className="font-h3 text-primary-dark">
        {news.link ? (
          <a href={news.link} target="_blank" className="link-primary">
            {news.title}
            <i className="hidden sm:inline-block ri-external-link-line font-normal text-dark-50 ml-1" />
          </a>
        ) : (
          news.title
        )}
      </h2>
      {news.publishedFrom && (
        <p className="font-body mt-1 text-dark-50">{news.publishedFrom}</p>
      )}
      {news.mentions.length > 0 && (
        <div className="flex items-center mt-3.5 gap-3">
          <AvatarList mentions={news.mentions} />
          <MentionList mentions={news.mentions} />
        </div>
      )}
      <div className="mt-3.5 text-dark-75 font-body">
        <RichText value={news.description} textRef={descriptionRef} />
      </div>
      {news.fileUrl && (
        <div className="flex">
          <a
            className="btn-md btn-white space-x-2"
            href={news.fileUrl}
            target="_blank"
          >
            <i className="ri-download-line text-dark-50 font-body"></i>
            <div>Download</div>
          </a>
        </div>
      )}
    </article>
  );
}

export default NewsItem;
