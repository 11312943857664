import { SectionMode } from "@/components/shared/Retreat/Article/defaults";
import { format } from "date-fns";

const parseArticleResponse = articleResponse => {
  if (articleResponse && !articleResponse.errors) {
    const attributes = articleResponse.data.attributes;
    const articleSpeakers = articleResponse.included
      ? articleResponse.included
          .filter(item => item.type === "articleSpeakers")
          .map(speaker => ({
            articleId: speaker.attributes.articleId,
            avatarUrl: speaker.attributes.avatarUrl,
            fullName: speaker.attributes.fullName,
            title: speaker.attributes.title,
            userId: speaker.attributes.userId,
          }))
      : [];
    const articleSections = articleResponse.included
      ? articleResponse.included
          .filter(item => item.type === "articleSections")
          .map(section => ({
            id: section.id,
            keySection: section.attributes.keySection,
            articleId: section.attributes.articleId,
            position: section.attributes.position,
            content: section.attributes.content,
            viewMode: SectionMode.View,
          }))
          .sort((a, b) => a.position - b.position)
      : [];
    return {
      id: articleResponse.data.id,
      title: attributes.title,
      retreatId: attributes.retreatId,
      quarterName: attributes.quarterName,
      slideshowImagesUrl: attributes.slideshowImagesUrl.map(url => ({ url })),
      presentationDate: format(
        new Date(attributes.presentationDate),
        "MM/dd/yyyy",
      ),
      resourceLinks: attributes.resourceLinks,
      sectionImages: attributes.sectionImages,
      articleSpeakers,
      articleSections,
    };
  } else {
    return {};
  }
};

export default parseArticleResponse;
