import * as React from "react";
import useCurrentUser from "@/hooks/useCurrentUser";
import projectPhotoPlaceholder2x from "@/images/project-photo-placeholder@2x.png";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import PreviewText from "@/components/shared/PreviewText";
import clsx from "clsx";

const projectAdminURL = (type: string, id: string): string => {
  switch (type) {
    case "researchStudies":
      return `/admin/projects/${id}/edit?type=research_study`;
    case "initiatives":
      return `/admin/initiatives/${id}/edit`;
    default:
      return `/admin/projects/${id}/edit?type=clinical_trial`;
  }
};

function ProjectBanner({ project }) {
  const currentUser = useCurrentUser();
  const {
    identifier,
    codename,
    name,
    readableType,
    featured,
  } = project.attributes;
  const { bannerImage } = project.links;
  const banner = bannerImage || projectPhotoPlaceholder2x;
  const focusAreas = project.relationships.focusAreas.data;
  const truncatedIdentifier =
    identifier?.length > 8 ? `${identifier.slice(0, 8)}...` : identifier;

  return (
    <div className="avoid-margin-safe mx-0 sm:mx-auto relative bg-dark-100 h-full sm:rounded-lg overflow-hidden">
      <div className="absolute inset-0">
        <img
          src={banner}
          className={clsx(
            bannerImage ? "object-cover w-full" : "object-fit h-full",
            "min-h-full min-w-full",
          )}
        />
        <div
          className={clsx("absolute inset-0", bannerImage && "linear-gradient")}
          aria-hidden="true"
        />
      </div>
      <div className="min-h-520px sm:min-h-462px flex flex-col relative text-white p-3 sm:p-10">
        <div className="mb-auto">
          {featured && (
            <span className="inline-flex items-center px-2.5 py-1 rounded-full uppercase font-label bg-dark-100 text-white">
              Featured
            </span>
          )}
        </div>
        <div className="mt-12">
          <div className="font-label flex items-center gap-2.5 mb-2">
            <span>
              {truncatedIdentifier}
              {identifier && codename && ": "}
              {codename}
            </span>
            <span className="bg-white w-1.5 h-1.5 rounded-full block" />
            <span>{readableType}</span>
          </div>
          <h1 className="font-h2 mb-4 sm:font-h1">{name}</h1>
          <FocusAreaTags focusAreas={focusAreas} theme="dark" />
          {currentUser?.attributes.isStaff && (
            <a
              href={projectAdminURL(project.type, project.id)}
              className="font-meta-bold mt-5 btn btn-sm border-white"
            >
              Edit Project Details
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function OverviewTab({ project }) {
  const { description, subtitle } = project.attributes;

  return (
    <>
      <ProjectBanner {...{ project }} />
      <div className="mt-7.5 sm:mt-10 p-6 bg-lines rounded-r-lg border-dark-100 border-l-8">
        <h3 className="text-dark-100 font-h3">Project Aim</h3>
        <PreviewText
          text={subtitle}
          className="mt-1 font-body-large text-dark-100"
        />
      </div>
      <div className="mt-7.5 sm:mt-10">
        <h3 className="font-h3 text-dark-100 mb-3.5">Study Description</h3>
        <PreviewText
          text={description}
          className="font-body-large text-dark-75"
        />
      </div>
    </>
  );
}

export default OverviewTab;
