import React, { useState, useMemo } from "react";
import AsideComponent from "@/components/shared/Aside";
import FormBlock from "@/components/shared/FormBlock";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import Input from "@/components/shared/Input";
import TextArea from "@/components/shared/TextArea";
import { format } from "date-fns";
import SearchBar from "@/components/shared/SearchBar";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import SpeakerItemRow from "./SpeakerItemRow";
import { parseUsers } from "../defaults";
import ButtonIcon from "@/components/shared/ButtonIcon";
import ResourceLinkFormModal from "./ResourceLinkFormModal";
import ResourceLinkRow from "./ResourceLinkRow";

const fetchUsers = () => {
  const params = paramBuilder({
    fields: {
      users: ["fullName", "title", "avatarUrl"],
    },
    exclude_links: true,
  });
  const url = `/api/users?${params}`;
  return denormalizedFetcher(url);
};

function ArticlesAside({ values, onChange, className = "" }) {
  const [openResourceLinkModal, setOpenResourceLinkModal] = useState<Boolean>(
    false,
  );
  const [isEditingDate, setIsEditingDate] = useState<Boolean>(false);
  const [isAddingSpeaker, setIsAddingSpeaker] = useState<Boolean>(false);
  const [userInfoText, setUserInfoText] = useState<string>("");

  const { data: users }: { data: any; isLoading: boolean } = useQuery(
    ["users"],
    fetchUsers,
  );

  const userOptions = useMemo(() => parseUsers(users, values.articleSpeakers), [
    users,
    values.articleSpeakers,
  ]);

  const onUserSelect = user => {
    const speakers = [
      ...values.articleSpeakers,
      {
        articleId: values.id,
        avatarUrl: user.avatarUrl,
        fullName: user.fullName,
        title: user.title,
        userId: user.id,
      },
    ];
    onChange("articleSpeakers", speakers);
    setIsAddingSpeaker(false);
    setUserInfoText("");
  };

  const handleDeleteSpeaker = userId => {
    const speakers = values.articleSpeakers.filter(
      speaker => speaker.userId !== userId,
    );
    onChange("articleSpeakers", speakers);
  };

  const handleAddResourceLink = resourceLinkItem => {
    onChange("resourceLinks", [...values.resourceLinks, resourceLinkItem]);
    setOpenResourceLinkModal(false);
  };

  const handleDeleteResourceLink = index => {
    const newLinks = values.resourceLinks.filter(
      (_, indexLink) => index !== indexLink,
    );
    onChange("resourceLinks", newLinks);
  };

  return (
    <aside className={clsx("space-y-7.5 ml-0 mb-7.5", className)}>
      <AsideComponent>
        <AsideComponent.Section>
          <AsideComponent.Content>
            <AsideComponent.Title
              title={
                <span className="flex">
                  Speaker Info:
                  {!isAddingSpeaker && (
                    <ButtonIcon
                      iconClassName="ri-add-circle-line"
                      onClick={() => setIsAddingSpeaker(true)}
                    />
                  )}
                </span>
              }
            />
            <AsideComponent.Body className="mt-3.5 space-y-2">
              {values.articleSpeakers.map(speaker => (
                <SpeakerItemRow
                  key={speaker.userId}
                  user={speaker}
                  displayDelete
                  className="mb-3"
                  onDeleteClick={() => handleDeleteSpeaker(speaker.userId)}
                />
              ))}
              {isAddingSpeaker && (
                <div className="flex">
                  <SearchBar
                    className="bg-white"
                    options={userOptions || []}
                    placeholder="Type Speaker's name"
                    accessor={item => item.fullName}
                    onTextChange={item => setUserInfoText(item)}
                    matchCriteria={(input, itemSearchable) =>
                      itemSearchable
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase())
                    }
                    onSelect={user => onUserSelect(user)}
                    selected={userInfoText}
                  >
                    {({ item }) => (
                      <SpeakerItemRow
                        user={item}
                        className="p-3 cursor-pointer hover:bg-slate-200"
                      />
                    )}
                  </SearchBar>
                  <ButtonIcon
                    iconClassName="ri-close-circle-line"
                    onClick={() => setIsAddingSpeaker(false)}
                  />
                </div>
              )}
            </AsideComponent.Body>
          </AsideComponent.Content>
          <AsideComponent.Content>
            {isEditingDate ? (
              <div className="flex">
                <FormBlock
                  label="Presentation Date:"
                  name="presentationDate"
                  labelClassName="font-body-bold"
                >
                  <Input
                    styleAs="small"
                    className="w-36"
                    placeholder="MM / DD / YYYY"
                    autoComplete="off"
                  />
                </FormBlock>
                <ButtonIcon
                  className="mt-6"
                  iconClassName="ri-checkbox-circle-line"
                  onClick={() => setIsEditingDate(false)}
                />
              </div>
            ) : (
              <>
                <AsideComponent.Title title="Presentation Date:" />
                <AsideComponent.Body className="space-y-2">
                  <p className="font-meta text-dark-100">
                    {values.presentationDate &&
                      format(
                        new Date(values.presentationDate),
                        "MMMM dd, yyyy",
                      )}
                    <ButtonIcon
                      iconClassName="ri-pencil-line"
                      onClick={() => setIsEditingDate(true)}
                    />
                  </p>
                </AsideComponent.Body>
              </>
            )}
          </AsideComponent.Content>
        </AsideComponent.Section>
        <AsideComponent.Section>
          <AsideComponent.Content>
            <AsideComponent.Title
              title={
                <span className="flex">
                  Resources Links
                  <ButtonIcon
                    iconClassName="ri-add-circle-line"
                    onClick={() => setOpenResourceLinkModal(true)}
                  />
                </span>
              }
            />
            <AsideComponent.Body className="space-y-2">
              {values.resourceLinks.map((linkItem, index) => (
                <ResourceLinkRow
                  key={index}
                  resourceLinkItem={linkItem}
                  onDeleteClick={() => handleDeleteResourceLink(index)}
                />
              ))}
            </AsideComponent.Body>
          </AsideComponent.Content>
        </AsideComponent.Section>
      </AsideComponent>
      <div className="w-full pb-2 border-b">
        <h3 className="font-h3">Article information</h3>
      </div>
      <FormBlock
        label="Thumbnail Image"
        name="thumbnailImage"
        labelClassName="font-body-bold"
        className="mt-6"
      >
        <FilePickerAdmin
          name="thumbnailImage"
          setSignedId={val => onChange("thumbnailImage", val)}
          attachment={{
            signedId: values.thumbnailImage,
            metadata: values.thumbnailImageMetadata,
            imageUrl: values.thumbnailImageUrl,
          }}
          hideButtonWhenUpload
        />
      </FormBlock>
      <FormBlock
        label="Description"
        name="description"
        labelClassName="font-body-bold"
        optionalLabel="(a brief description)"
        optional
        className="mt-6"
      >
        <TextArea rows={4} className="text-dark-100" />
      </FormBlock>
      <ResourceLinkFormModal
        isOpenModal={openResourceLinkModal}
        setOpenModal={setOpenResourceLinkModal}
        handleSubmit={handleAddResourceLink}
      />
    </aside>
  );
}

export default ArticlesAside;
