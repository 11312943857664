import * as React from "react";
import { Link } from "react-router-dom";
import Table from "@/components/shared/Table";

const InventorItem = ({ inventor }) => {
  const {
    fullName,
    name,
    centerNameOrInstitution,
    center,
  } = inventor.attributes;
  const { show, avatar, centerShow } = inventor.links;

  return (
    <Table.Row key={inventor.id}>
      <Table.Cell className="pr-2">
        <div className="flex items-center">
          {show ? (
            <>
              <Link to={show} className="flex min-w-max">
                <img
                  src={avatar}
                  alt=""
                  className="h-10 w-10 rounded-full flex-shrink-0"
                />
              </Link>
              <Link to={show} className="ml-4 link">
                <p className="truncate text-dark-100 font-body-bold">
                  {fullName}
                </p>
              </Link>
            </>
          ) : (
            <>
              <span className="flex min-w-max">
                <img
                  src={avatar}
                  alt=""
                  className="h-10 w-10 rounded-full flex-shrink-0"
                />
              </span>
              <span className="ml-4">
                <p className="truncate text-dark-100 font-body-bold">{name}</p>
              </span>
            </>
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {centerShow ? (
          <Link to={inventor.links.centerShow} className="link">
            <p className="font-body text-dark-100">
              {centerNameOrInstitution || center}
            </p>
          </Link>
        ) : (
          <p className="font-body text-dark-100">
            {centerNameOrInstitution || center}
          </p>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

function Inventors({ invention }) {
  const users = invention.relationships.users.data;
  const externalUsers = invention.relationships.externalUsers.data;
  const inventors = users.concat(externalUsers);

  return (
    <div className="flex flex-col gap-10 overflow-auto">
      {inventors.length > 0 && (
        <Table>
          <Table.Header>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {inventors.map(inventor => (
              <InventorItem inventor={inventor} key={inventor.id} />
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

export default Inventors;
