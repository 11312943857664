import React, { useMemo } from "react";
import clsx from "clsx";
import Pill from "@/components/shared/Pill";

function SummaryStatus({ statuses, className }) {
  const total = useMemo(
    () =>
      Object.values(statuses).reduce<number>(
        (sum: number, acc: number) => sum + acc,
        0,
      ),
    [statuses],
  );

  return (
    <div
      className={clsx("bg-white border border-lines rounded shadow", className)}
    >
      <header className="px-5 py-3 border-b border-lines">
        <h2 className="text-dark-100">
          <em className="text-30 font-normal mr-1 not-italic">{total}</em>
          <span className="text-17 font-bold">Clinical Trials</span>
        </h2>
      </header>
      <ul className="px-5 py-3 text-dark-75">
        <li className="flex">
          <Pill>{statuses.in_development}</Pill>
          <span className="ml-1.5">In Development</span>
        </li>
        <li className="flex mt-2.5">
          <Pill>{statuses.pending_activation}</Pill>
          <span className="ml-1.5">Pending Activation</span>
        </li>
        <li className="flex mt-2.5">
          <Pill>{statuses.enrolling_patients}</Pill>
          <span className="ml-1.5">Enrolling Patients</span>
        </li>
        <li className="flex mt-2.5">
          <Pill>{statuses.enrollment_complete}</Pill>
          <span className="ml-1.5">Enrollment Complete</span>
        </li>
        <li className="flex mt-2.5">
          <Pill>{statuses.study_closed}</Pill>
          <span className="ml-1.5">Study Closed</span>
        </li>
      </ul>
    </div>
  );
}

export default SummaryStatus;
