import React, { useEffect, useState } from "react";
import Hero from "../../shared/Hero";
import ChartPage from "../../Examples/ChartPage";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import InsightCard from "../../shared/InsightCard";
import HeaderCards from "../../shared/HeaderCard/HeaderCards";
import SimpleAreaChart from "@/components/shared/Charts/SimpleAreaChart";
import PieChart from "@/components/shared/Charts/PieChart";
import InsightBarChart from "@/components/shared/Charts/BarChart/InsightBarChart";
import InsightSkeleton from "@/components/pages/InsightsPage/InsightPage/InsightSkeleton";

function ProjectsInsight() {
  const { data, isLoading } = useQuery(["researchInsight"], () =>
    fetcher("/api/projects/insights").then(json => {
      return json;
    }),
  );
  const [showMore, setShowMore] = useState(false);
  const projectsByTag = data?.projects_by_tag || [];
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if (showMore) {
      setTagList(projectsByTag);
    } else {
      setTagList(projectsByTag.slice(0, 10));
    }
  }, [projectsByTag, showMore]);

  return (
    <>
      <Hero
        title="Projects"
        description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
        nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus
        omnis, molestiae esse placeat, exercitationem magnam quod
        molestias quia aspernatur venenatis diam."
      >
        <ChartPage />
      </Hero>
      {isLoading ? (
        <InsightSkeleton />
      ) : (
        <div className="margin-safe p-6 lg:p-10 lg:pb-10 min-h-full">
          <div className="masonry md:masonry-md xl:masonry-xl">
            <InsightCard size="sm">
              <HeaderCards
                title="Total Numbers of Projects"
                subtitle={data?.projects_size || "--"}
              />
              <SimpleAreaChart
                dataKey="name"
                data={data?.projects_by_year || []}
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Projects by Focus Area" />
              <PieChart
                data={data?.projects_by_focus_area || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards title="Date Last Updated" />
              <ul className="mt-4 mb-6 text-15">
                {data &&
                  data?.projects_date_last_updated.map((item, index) => (
                    <li className="flex justify-between mb-3" key={index}>
                      <div>{item.name}</div>
                      <div>{item.count}</div>
                    </li>
                  ))}
              </ul>
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Projects by Center" titleClassname="mb-4" />
              <PieChart
                data={data?.projects_by_center || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>
            <InsightCard size={showMore ? "max" : "lg"}>
              <HeaderCards title="Projects by Tag" titleClassname="mb-3.5" />
              <ul className="flex flex-col gap-3">
                {tagList.map((tag: any) => (
                  <li
                    key={tag.id}
                    className="flex justify-between font-body-bold text-dark-75"
                  >
                    <span>{tag.name}</span>
                    <span>{tag.count}</span>
                  </li>
                ))}
                <button
                  className="font-body-bold text-primary-dark max-w-max hover:underline"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  <span>{`Show ${showMore ? "Less" : "More"}`}</span>
                </button>
              </ul>
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards title="Projects by Type" titleClassname="mb-4" />
              <InsightBarChart
                data={data?.projects_by_type}
                dataKey="created_year"
                multipleBarDataKeys={[
                  "clinical_trial",
                  "research_study",
                  "initiative",
                ]}
                multipleBarDataLabels={[
                  "Clinical Trial",
                  "Research Study",
                  "Initiative",
                ]}
                multipleBarColorLabels={["#3B82F6", "#047857", "#685BC7"]}
                multipleBar
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards
                title="Total Number of Members on Projects"
                titleClassname="mb-4"
              />
              <SimpleAreaChart
                dataKey="name"
                data={data?.total_number_of_members_on_projects || []}
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Clinical Trial Project Status" />
              <PieChart
                data={data?.projects_status || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectsInsight;
