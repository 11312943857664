import * as React from "react";
import { useEffect, useState } from "react";
import { denormalizedFetcher } from "@/utils/api";
import { useLocation } from "react-router-dom";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import paramBuilder from "@/utils/network/paramBuilder";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { pluralize } from "@/utils/string";
import Filters from "@/components/shared/Inventions/Filters";
import InventionsTable from "@/components/shared/InventionsTable";

const fetchInventions = ({ pageParam = 0, queryKey = [] }) => {
  const key = queryKey[0];
  const filters = queryKey[1];
  const params = paramBuilder({
    include: ["users", "externalUsers", "focusAreas"],
    fields: {
      inventions: [
        "identifier",
        "title",
        "abstractNotes",
        "notes",
        "disclosure",
        "licensees",
        "review",
        "ipType",
        "inventorsList",
        "users",
        "externalUsers",
        "focusAreas",
        "caseStatusAtAffiliatedResearchInstitute",
        "updatedAt",
        "updatedStatus",
      ],
      users: ["fullName", "slug", "projectsCount"],
      externalUsers: ["name"],
      focusAreas: ["name"],
    },
  });

  const pagination = `page[number]=${pageParam + 1}&page[size]=15`;
  const url = `/api/${key}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

function InventionsPage() {
  const { search } = useLocation();
  const [filters, setFilters] = useState(search.slice(1));
  const [sort, setSort] = useState("-disclosure");
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["inventions", filters], fetchInventions, {
    getNextPageParam: apiCanFetch,
  });
  const { data: inventions, included, meta } = useInfiniteQueryResult(
    data?.pages,
  );
  const { isAtBottom } = useScrollPosition("section-router");

  useEffect(() => {
    if (isFetching) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetching, fetchNextPage, hasNextPage]);

  const recordCount = isFetching ? undefined : meta.recordCount || 0;

  return (
    <section className="pb-10">
      <header className="lg:flex lg:items-center lg:justify-between py-5 lg:py-10">
        <div className="flex items-center">
          <h1 className="font-h2 xl:font-h1 font-bold text-dark-100">
            Inventions
          </h1>
        </div>
      </header>

      <Filters
        setFilters={setFilters}
        recordCount={recordCount}
        filters={filters}
        setSort={setSort}
      />

      <h3 className="text-byte-dark font-h3 mb-5" data-testid="invention-count">
        {isFetching
          ? "Retrieving inventions..."
          : `Displaying ${recordCount} ${pluralize(
              recordCount,
              "Invention Item",
            )}`}
      </h3>

      <InventionsTable
        widthTitle={460}
        {...{ inventions, included, isFetching, isFetchingNextPage, sort }}
      />
    </section>
  );
}

export default InventionsPage;
