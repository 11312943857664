import React from "react";
import ImageGallery from "@/components/shared/Retreat/ImageGallery";

function ImageGalleryForm({ values, onChange }) {
  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Gallery Images</h3>
        <p className="text-14 text-dark-75">
          Photos that were taken on-site by the photographer.
        </p>
      </header>
      <section className="w-[700px]">
        <ImageGallery values={values} onChange={onChange} editable />
      </section>
    </article>
  );
}

export default ImageGalleryForm;
