import * as Yup from "yup";
import { format, parse } from "date-fns";

const RetreatSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Retreat title is required."),
  location: Yup.string()
    .trim()
    .required("Retreat location is required."),
  startDate: Yup.string()
    .required("Start Date is required.")
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/im, {
      message: "Date format is MM/DD/YYYY",
      excludeEmptyString: true,
    }),
  endDate: Yup.string()
    .required("End Date is required.")
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/im, {
      message: "Date format is MM/DD/YYYY",
      excludeEmptyString: true,
    }),
  quarter: Yup.string().required("Quarter is required."),
  coverImage: Yup.string().required("Cover Image is required."),
});

const mapRequest = request => {
  return {
    data: {
      ...(request.id ? { id: request.id } : {}),
      type: "retreats",
      attributes: {
        title: request.title,
        location: request.location,
        startDate: parse(request.startDate, "MM/dd/yyyy", new Date()),
        endDate: parse(request.endDate, "MM/dd/yyyy", new Date()),
        quarter: request.quarter,
        coverImage: request.coverImage,
        published: request.published,
        galleryImages: request.galleryImages.map(
          imageItem => imageItem.signed_id,
        ),
      },
    },
  };
};

const mapResponse = response => {
  const retreatAttributes = response.data.attributes;
  const articles = response.included
    ? response.included.filter(item => item.type === "articles")
    : [];
  return {
    id: response.data.id,
    title: retreatAttributes.title,
    location: retreatAttributes.location,
    quarter: retreatAttributes.quarter,
    startDate:
      retreatAttributes.startDate &&
      format(new Date(retreatAttributes.startDate), "MM/dd/yyyy"),
    endDate:
      retreatAttributes.endDate &&
      format(new Date(retreatAttributes.endDate), "MM/dd/yyyy"),
    published: retreatAttributes.published,
    coverImage: retreatAttributes.coverImage,
    coverImageUrl: retreatAttributes.coverImageUrl,
    coverImageMetadata: retreatAttributes.coverImageMetadata,
    galleryImages: retreatAttributes.galleryImages,
    articles,
  };
};

const makeDataToOptions = (data, name) => {
  const options = data?.map(item => {
    return {
      name: item.attributes[name],
      id: item.id,
    };
  });
  return options || [];
};

export { RetreatSchema, mapResponse, mapRequest, makeDataToOptions };
