import React from "react";
import ReactTooltip from "react-tooltip";

const NUM_MAX_OF_VISIBLE_MEMBERS = 10;

function MembersGroup({ members, groupId, size = NUM_MAX_OF_VISIBLE_MEMBERS }) {
  const data = members.slice(0, size);
  const tooltipList = members.slice(size);
  return (
    <div className="flex flex-wrap">
      {data.map(member => (
        <a
          href={member.links.profile}
          target="_BLANK"
          data-tip={member.attributes.fullName}
          key={member.id}
        >
          <img
            src={member.links.avatar}
            className="h-6 w-6 rounded-full align-middle"
          ></img>
        </a>
      ))}
      <ReactTooltip />
      {tooltipList.length > 0 && (
        <>
          <a
            data-tip
            data-for={`${groupId}-memberslist`}
            className="h-6 w-6 rounded-full bg-lines text-11 flex items-center justify-center"
          >
            +{tooltipList.length}
          </a>
          <ReactTooltip
            id={`${groupId}-memberslist`}
            effect="solid"
            backgroundColor="#000000"
            delayHide={100}
            delayUpdate={100}
          >
            <div className="overflow-y-auto max-h-48 scrollbar-hide ">
              {tooltipList.map(member => (
                <div key={member.id}>
                  <a
                    href={member.links.profile}
                    target="_BLANK"
                    className="hover:text-primary"
                  >
                    {member.attributes.fullName}
                  </a>
                </div>
              ))}
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  );
}

export default MembersGroup;
