import React, { useState, useEffect } from "react";
import { denormalizedFetcher } from "@/utils/api";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import paramBuilder from "@/utils/network/paramBuilder";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import ClinicalTrialsTable from "./ClinicalTrialsTable";
import ClinicalTrialsFilter from "./ClinicalTrialsFilter";

const fetchClinicalTrials = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const params = paramBuilder({
    fields: {
      clinicalTrials: [
        "name",
        "createdAt",
        "formattedIdentifier",
        "formattedCodename",
        "formattedTrialStatus",
        "formattedTrialPhase",
        "compliant",
        "centersAndInstitutions",
        "isPublic",
        "featured",
        "trialStatus",
        "trialPhase",
        "archived",
      ],
    },
  });

  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `/api/clinical-trials?${pagination}&${params}&${filters}`;

  return denormalizedFetcher(url);
};

function AllTab() {
  const [filters, setFilters] = useState("sort=-createdAt");
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["clinical-trials", filters], fetchClinicalTrials, {
    getNextPageParam: apiCanFetch,
  });
  const { data: clinicalTrials, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("clinical-trial-section");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  return (
    <>
      <div className="sticky top-[106px] border-t border-lines">
        <ClinicalTrialsFilter
          total={meta?.recordCount}
          isLoading={isFetching}
          queryFilter={filters}
          setFilters={setFilters}
        />
      </div>
      <article
        id="clinical-trial-section"
        className="overflow-x-hidden h-vh-60 px-7.5 bg-primary-lighter"
      >
        <ClinicalTrialsTable
          clinicalTrials={clinicalTrials}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          filters={filters}
        />
      </article>
    </>
  );
}

export default AllTab;
