import * as React from "react";
import InsightCard from "../../shared/InsightCard";

function InsightSkeleton() {
  return (
    <div className="margin-safe p-6 lg:p-10 lg:pb-10 min-h-full">
      <div className="masonry md:masonry-md xl:masonry-xl animate-pulse">
        <InsightCard size="sm">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-[240px] h-[240px] rounded-full ml-12" />
        </InsightCard>
        <InsightCard size="md">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-full h-[290px]" />
        </InsightCard>
        <InsightCard size="md">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-[280px] h-[280px] rounded-full ml-8" />
        </InsightCard>
        <InsightCard size="sm">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-fukk h-[240px]" />
        </InsightCard>
        <InsightCard size="xmd">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-[280px] h-[280px] rounded-full ml-8" />
        </InsightCard>
        <InsightCard size="xs">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-full h-[180px]" />
        </InsightCard>
        <InsightCard size="md">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-[280px] h-[280px] rounded-full ml-8" />
        </InsightCard>
        <InsightCard size="xs">
          <div className="bg-dark-25 h-4 w-3/4 rounded-full mb-6" />
          <div className="bg-dark-25 w-full h-[180px]" />
        </InsightCard>
      </div>
    </div>
  );
}

export default InsightSkeleton;
