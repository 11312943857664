import * as React from "react";
import CenterForm from "./CenterForm";
import UserForm from "./UserForm";

function ProjectParticipantsForm({ values, onChange, onTouched, users }) {
  return (
    <article className="flex p-7 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">
          Project Participants
        </h3>
        <p className="text-14 text-dark-75">
          Individuals and organizations associated with the project.
        </p>
      </header>
      <section className="flex-1">
        <CenterForm values={values} onChange={onChange} />
        <UserForm
          values={values}
          onChange={onChange}
          onTouched={onTouched}
          users={users}
        />
      </section>
    </article>
  );
}

export default ProjectParticipantsForm;
