import * as React from "react";
import { useState, useEffect } from "react";
import paramBuilder from "@/utils/network/paramBuilder";
import Filter from "@/components/shared/Filter";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import FilterTags from "@/components/shared/FilterTags";
import { FilterOption } from "@/types";

const sortOptions = [
  { name: "Date, Last Updated", id: "-updatedAt" },
  { name: "Date, Oldest First", id: "updatedAt" },
  { name: "Title, A to Z", id: "name" },
  { name: "Title, Z to A", id: "-name" },
];

function Filters({ data, setFilters }) {
  const [selectedTypes, setSelectedTypes] = useState<FilterOption[]>([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  const [typesFocus, setTypesFocus] = useState(false);
  const [focusAreasFocus, setFocusAreasFocus] = useState(false);

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        type: selectedTypes.map(t => t.id),
        focusAreas: selectedFocusAreas.map(f => f.id),
        archived: false,
      },
      sort: [selectedSort?.id],
    });
    setFilters(filters);
  }, [setFilters, selectedTypes, selectedFocusAreas, selectedSort]);

  return (
    <>
      <div className="flex">
        <div className="flex space-x-4 w-full sm:w-auto">
          <Filter
            name="project_type"
            items={data?.types}
            selectedItems={selectedTypes}
            setSelectedItems={setSelectedTypes}
            highlight={typesFocus}
            setFocus={setTypesFocus}
            className="flex-1"
          >
            <Filter.Label>Project Type</Filter.Label>
            <Filter.Content>
              <CheckboxGroup
                options={data?.types}
                selectedOptions={selectedTypes}
                setSelectedOptions={setSelectedTypes}
                className="filter-checkbox-group"
              />
            </Filter.Content>
          </Filter>
          <Filter
            name="focus_area"
            items={data?.focusAreas}
            selectedItems={selectedFocusAreas}
            setSelectedItems={setSelectedFocusAreas}
            highlight={focusAreasFocus}
            setFocus={setFocusAreasFocus}
            className="flex-1"
          >
            <Filter.Label>Focus Area</Filter.Label>
            <Filter.Content>
              <CheckboxGroup
                options={data?.focusAreas}
                selectedOptions={selectedFocusAreas}
                setSelectedOptions={setSelectedFocusAreas}
                className="focus-area-checkbox-group"
                labelClassName="sm:w-325px"
              />
            </Filter.Content>
          </Filter>
        </div>
        <div className="hidden sm:flex sm:ml-auto sm:items-center">
          <span className="mr-4 text-dark-100 font-body-bold">Sort by:</span>
          <Filter
            name="sort"
            items={sortOptions}
            selectedItems={selectedSort}
            setSelectedItems={setSelectedSort}
            closeOnSelect
          >
            <Filter.Label>{selectedSort?.name || "Loading"}</Filter.Label>
            <Filter.Content>
              <SelectGroup
                options={sortOptions}
                selectedOption={selectedSort}
                setSelectedOption={setSelectedSort}
              />
            </Filter.Content>
          </Filter>
        </div>
      </div>
      <div className="flex flex-wrap mt-4 mb-6">
        <FilterTags
          options={selectedTypes}
          setOptions={setSelectedTypes}
          isFocused={typesFocus}
          setFocus={setTypesFocus}
        />
        <FilterTags
          options={selectedFocusAreas}
          setOptions={setSelectedFocusAreas}
          isFocused={focusAreasFocus}
          setFocus={setFocusAreasFocus}
        />
      </div>
    </>
  );
}

export default Filters;
