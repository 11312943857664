function filterByid(collection, selectedItems) {
  return collection.filter(
    (item: { id: number }) => !selectedItems.includes(item.id),
  );
}

function filterByParam(collection, selectedItems, param) {
  return collection?.filter(item => {
    const itemParams = selectedItems?.map(item => item[param]);
    return !itemParams.includes(item[param]);
  });
}
export { filterByid, filterByParam };
