import * as React from "react";
import { useCallback, useState, useLayoutEffect, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { denormalizedFetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import fetch from "cross-fetch";
import paramBuilder from "@/utils/network/paramBuilder";
import Tabs from "@/components/shared/Tabs";
import Overview from "./OverviewTab";
import Viewers from "./ViewersTab";
import RelatedProjectsTab from "./RelatedProjectsTab";
import OptModal from "./OptModal";
import Aside from "./Aside";
import useCurrentUser from "@/hooks/useCurrentUser";
import NotFoundPage from "@/components/App/NotFoundPage";
import Breadcrumb from "@/components/shared/Breadcrumb";

const defaultTabs = [{ id: "overview", name: "Overview" }];

const fetchResearch = ({ queryKey }) => {
  const [_key, slug] = queryKey;

  const params = paramBuilder({
    include: [
      "poster",
      "focusAreas",
      "authors",
      "readers",
      "posts",
      "relatedProjects",
    ],
    fields: {
      researchDocuments: [
        "title",
        "poster",
        "status",
        "readableType",
        "createdAt",
        "focusAreas",
        "canDownload",
        "authors",
        "sortedAuthors",
        "readers",
        "posts",
        "fileType",
        "relatedProjects",
      ],
      users: [
        "fullName",
        "slug",
        "centerNameOrInstitution",
        "centerShorthandOrInstitution",
        "projectsCount",
      ],
      focusAreas: ["name"],
      projects: ["name", "relatedFocusAreas", "identifier", "codename"],
    },
  });
  const url = `/api/research-documents/${slug}?${params}`;
  return denormalizedFetcher(url);
};

const TabSection = props => {
  switch (props.tab) {
    case "overview":
      return <Overview research={props.research} />;
    case "viewers":
      return <Viewers research={props.research} />;
    case "relatedProjects":
      return <RelatedProjectsTab research={props.research} />;
    default:
      return null;
  }
};

function ResearchDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [optModalIsOpen, setOptModalIsOpen] = useState(false);
  const [tabsLoading, setTabsLoading] = useState(true);
  const [tabs, setTabs] = useState<FilterOption[]>(defaultTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const currentUser = useCurrentUser();
  const { data }: { data: any } = useQuery(
    ["researchDocument", id],
    fetchResearch,
  );
  const research = data?.data;

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["researchDocument"]);
    },
  });

  const poster = research?.relationships.poster.data;
  const readers = research?.relationships.readers.data;
  const readersCount = readers?.length;

  const onAcceptOptModal = () => {
    fetch(research.links.opt_in, {
      method: "GET",
    }).then(response => {
      if (response.ok) {
        setOptModalIsOpen(false);
        registerView();
      }
    });
  };

  const registerView = useCallback(() => {
    mutateAsync({
      mutationFn: fetch(research.links.register_view),
    });
  }, [mutateAsync, research]);

  useLayoutEffect(() => {
    if (research) {
      const relatedProjects = research.relationships.relatedProjects.data;
      const newTabs: FilterOption[] = [];

      if (readersCount > 0) newTabs.push({ id: "viewers", name: "Viewers" });
      if (relatedProjects.length > 0) {
        newTabs.push({ id: "relatedProjects", name: "Related Projects" });
      }
      setTabs([...defaultTabs, ...newTabs]);
      setTabsLoading(false);
    }
  }, [research, readersCount]);

  useEffect(() => {
    if (research) {
      if (research.attributes.canDownload) {
        if (
          currentUser.id !== poster?.id &&
          !readers?.find(reader => reader.id === currentUser.id)
        ) {
          registerView();
        }
      } else {
        setOptModalIsOpen(true);
      }
    }
  }, [currentUser, registerView, research, readers, poster]);

  if (data?.errors) return <NotFoundPage />;

  return (
    <div className="margin-safe">
      <Breadcrumb to="/research_documents" text="Back to Research" />
      {research && (
        <div className="mb-5 lg:flex">
          <div className="lg:flex-grow overflow-auto px-1 -mx-1 py-1 -my-1">
            <div className="mt-7.5 sm:mt-4.5">
              {!tabsLoading && (
                <Tabs {...{ tabs, selectedTab, setSelectedTab }} />
              )}
            </div>
            <section className="py-7.5 sm:py-10">
              {selectedTab && research && (
                <TabSection tab={selectedTab.id} research={research} />
              )}
            </section>
          </div>
          <Aside research={research} />
        </div>
      )}
      <OptModal
        isOpen={optModalIsOpen}
        onAccept={onAcceptOptModal}
        onClose={() => navigate("/research_documents")}
      />
    </div>
  );
}

export default ResearchDetail;
