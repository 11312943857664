import * as React from "react";
import NoResults from "@/components/shared/NoResults";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import Tooltip from "@/components/shared/Tooltip";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PeopleCardSkeleton from "./PeopleCardSkeleton";
import ToogleButton from "@/components/shared/ToogleButton";

function itemValue(item, column) {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item.attributes[column.name];
  }
}

function render(value, column, item) {
  if (column.render) {
    return column.render(value, item);
  } else {
    return value;
  }
}
const PublicAtColumn = ({ peopleItem, value, filters }) => {
  const { archived, suspended } = peopleItem.attributes;
  const disabledToogle = archived || suspended;
  const isPublic = !!value;
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admin_people", filters]);
    },
    onMutate: async () => {
      const oldPagedData: any = queryClient.getQueryData([
        "admin_people",
        filters,
      ]);
      oldPagedData.pages.forEach(page => {
        const updatedPeople = page.data.find(
          people => people.id === peopleItem.id,
        );
        if (updatedPeople) {
          updatedPeople.attributes.isPublic = !isPublic;
          updatedPeople.attributes.publicAt = !isPublic;
          return true;
        }
      });
    },
  });

  const handlePublic = () => {
    mutateAsync(fetch(`/api/users/${peopleItem.id}/toggle`));
  };

  return (
    <ToogleButton
      active={isPublic}
      disabled={disabledToogle}
      onClick={handlePublic}
    />
  );
};

const CompliantTag = (ipDisclosure, participationAgreement) => {
  let docs: string[] = [];
  if (!ipDisclosure) {
    docs.push("IP Encumbrances & Disclosures");
  }
  if (!participationAgreement) {
    docs.push("Participation Agreement");
  }
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <span className="bg-yellow-300 rounded-full w-[6px] h-[6px] mx-2" />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <span>Missing {docs.join(" and ")}.</span>
      </Tooltip.Content>
    </Tooltip>
  );
};
function PeopleTable({
  people,
  openForm,
  isFetching,
  isFetchingNextPage,
  filters,
}) {
  const columns = [
    {
      label: "Name/E-mail",
      name: "fullName",
      th: { width: "28%", className: "pt-1 pl-4" },
      td: {
        className:
          "pt-2.5 pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden font-bold text-dark-75",
      },
      accessor: item => item,
      render: item => {
        const {
          fullName,
          email,
          compliance,
          ipDisclosure,
          participationAgreement,
        } = item?.attributes;
        return (
          <div className="flex">
            {compliance ? (
              CompliantTag(ipDisclosure, participationAgreement)
            ) : (
              <div className="w-[11px] h-[16px] m-2" />
            )}
            <div className="flex flex-col">
              <Link to={item.links.show} className="link">
                <h2>{fullName}</h2>
              </Link>
              <span className="whitespace-nowrap overflow-ellipsis overflow-hidden font-bold text-dark-50">
                {email}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      label: "Role",
      name: "digestedJobTitle",
      th: { width: "12%", className: "pt-1 pl-4" },
      td: {
        className: "!whitespace-normal pt-2.5 pl-4 overflow-hidden",
      },
      accessor: item => item?.attributes,
      render: ({ digestedJobTitle, suspended }) => (
        <div className="flex flex-col gap-1">
          <p>{digestedJobTitle}</p>
          {suspended && (
            <p className="font-label text-[10px] leading-[10px] px-1.5 py-0.5 bg-gold-1 text-gold-2 max-w-min rounded-3px">
              Suspended
            </p>
          )}
        </div>
      ),
    },
    {
      label: "INSTITUTION",
      name: "centerNameOrInstitution",
      th: { width: "18%", className: "pt-1 pl-4" },
      td: {
        className:
          "pt-2.5 pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
    },
    {
      label: "FOCUS AREAS",
      name: "focusArea",
      th: { className: "pt-1 pl-4" },
      td: {
        className:
          "pt-2.5 pl-3 !whitespace-normal overflow-ellipsis overflow-hidden",
      },
      accessor: item => item.relationships.focusAreas.data,
      render: focusAreas => (
        <ul className="">
          {focusAreas.map((el, index) => (
            <li className="overflow-hidden overflow-ellipsis" key={index}>
              {el.attributes.name}
              {focusAreas.length > index + 1 && ","}
            </li>
          ))}
        </ul>
      ),
    },
    {
      label: "LAST LOGIN",
      name: "lastLoggedInAgo",
      th: { className: "pt-1 pl-4" },
      td: {
        className:
          "pt-2.5 pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
    },
    {
      label: "MEMBER SINCE",
      name: "createdAt",
      th: { className: "pt-1 pl-4" },
      td: {
        className:
          "pt-2.5 pl-4 !whitespace-normal overflow-ellipsis overflow-hidden",
      },
      render: item => {
        const formattedDate = format(new Date(item), "MMMM d, yyyy");
        return <span>{formattedDate}</span>;
      },
    },
    {
      label: "PUBLIC",
      name: "publicAt",
      th: { width: "101", className: "pt-1 pl-4" },
      td: {
        className:
          "pt-2.5 pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
      render: (value, peopleItem) => (
        <PublicAtColumn
          peopleItem={peopleItem}
          value={value}
          filters={filters}
        />
      ),
    },
    {
      label: "Actions",
      name: "actions",
      th: { width: "90", className: "text-center pt-1 pl-4 w-[100px]" },
      td: {
        className: "pt-2.5 pl-4 pr-4 whitespace-nowrap text-center",
      },
      render: (_, item) => (
        <button
          className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-0.5 px-3.75"
          onClick={() => openForm(item, true)}
          data-testid={item.attributes.name}
        >
          Edit
        </button>
      ),
    },
  ];
  return (
    <>
      {!isFetching && people.length === 0 ? (
        <NoResults entityName="resources" icon="ri-search-line" />
      ) : (
        <Table className="border border-transparent text-sm w-full" fixed>
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, i) => (
              <Table.HeaderCell
                width={column.th.width}
                {...column.th}
                key={i}
                className={column.th?.className}
              >
                {column.label != "PUBLIC" ? (
                  column.label
                ) : (
                  <div className="flex justify-center gap-1">
                    {column.label}
                    <Tooltip>
                      <Tooltip.Trigger className="h-3 w-3">
                        <i className="ri-information-line text-dark-50 h-3 w-3" />
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        If this is on, then this content will display on the
                        PICI public site (www.parkerici.org). If it's off, then
                        it will not display publicly.
                      </Tooltip.Content>
                    </Tooltip>
                  </div>
                )}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body className="border border-lines">
            {people.length > 0 &&
              people.map((item, i) => (
                <Table.Row key={i} className="divide-x divide-lines">
                  {columns.map((column, j) => (
                    <Table.Cell
                      key={j}
                      className={column.td?.className}
                      expand={j < columns.length - 1 ? true : false}
                    >
                      {render(itemValue(item, column), column, item)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => <PeopleCardSkeleton key={x} />)}
          </Table.Body>
        </Table>
      )}
    </>
  );
}
export default PeopleTable;
