import React from "react";
import { Link } from "react-router-dom";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import { denormalizedFetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import Tooltip from "@/components/shared/Tooltip/Tooltip";
import Table from "@/components/shared/Table";
import NoResults from "@/components/shared/NoResults/NoResults";
import Skeleton from "./Skeleton";

const fetchPatentDeadline = () => {
  const baseUrl = "/api/inventions/top_inventors";
  return denormalizedFetcher(baseUrl);
};

function itemValue(item, column) {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item[column.name];
  }
}

function render(value, column, item) {
  if (column.render) {
    return column.render(value, item);
  } else {
    return value;
  }
}
const columns = [
  {
    label: "Inventor",
    name: "name",
    th: { className: "pt-1 pl-4 text-11 " },
    td: {
      className:
        "pt-[10px] pb-[10px] pl-4 !whitespace-normal font-bold text-dark-75 min-w-[225px]",
    },
    accessor: item => item,
    render: value => (
      <div>
        <p>
          {`${value.name} `}
          {value.slug && (
            <Link
              to={`/profile/${value.slug}`}
              target="_blank"
              className="link-primary"
            >
              (profile)
            </Link>
          )}
        </p>
        <span className="font-micro text-dark-50 ml-0.25">
          {value.role ? value.role : "Inventor Only"}
        </span>
      </div>
    ),
  },
  {
    label: "Number of Inventions",
    name: "count",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 whitespace-nowrap",
    },
    render: value => <span className="text-sm text-dark-50">{value}</span>,
  },
  {
    label: "Inventions",
    name: "iventions",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 !whitespace-normal min-w-[300px]",
    },
    render: value => {
      const inventionsIdentifiers = value.split(",");
      return (
        <span className="text-sm text-dark-50">
          {inventionsIdentifiers.map((invention, index) => {
            const [identifier, id] = invention.split("/");
            return (
              <Link
                to={`/inventions/${id}`}
                target="_blank"
                className="link-primary"
                key={index}
              >
                {identifier}
                {index + 1 !== inventionsIdentifiers.length && ", "}
              </Link>
            );
          })}
        </span>
      );
    },
  },
  {
    label: "Center/Institution",
    name: "centers",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 whitespace-nowrap",
    },
    render: value => <span className="text-sm text-dark-50">{value}</span>,
  },
];

function TopInventorsPage() {
  const { data, isFetching }: { data: any; isFetching: boolean } = useQuery(
    ["patent_deadline"],
    fetchPatentDeadline,
  );

  return (
    <>
      <AdminHeader
        title="Top Inventors"
        description={<span>Top 25 Inventors across all of PICI</span>}
        linkText="New Invention"
        linkTo="/admin/inventions/new"
        linkReloadDocument
      />
      <div className="overflow-x-hidden h-vh-60 bg-primary-lighter">
        <div className="px-8 pt-4 pb-8">
          <div className="max-w-[1200px]">
            <div className="bg-white border border-lines rounded shadow w-full  mt-4">
              {/* Hide per request https://linear.app/pici/issue/PIC-804/top-inventors-copy-changes */}
              {/* <header className="px-5 py-3">
                <h2 className="text-dark-100 text-22 font-bold flex items-center">
                  List{" "}
                  {
                    <Tooltip>
                      <Tooltip.Trigger className="ml-2 text-11 h-4 w-4 bg-gray-200 rounded-full ">
                        <i className="ri-question-mark font-bold h-2 w-4 flex items-center justify-center"></i>
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        <span>Condition: Must be a full name coincidense</span>
                      </Tooltip.Content>
                    </Tooltip>
                  }
                </h2>
              </header> */}
              {isFetching && <Skeleton />}
              {!isFetching && data.data.length == 0 && (
                <NoResults
                  className="!bg-white"
                  fullText="There are no records for this section"
                  icon="ri-search-line"
                />
              )}
              {!isFetching && data.data.length > 0 && (
                <Table className="border border-transparent text-sm  mb-8 px-5 mt-4">
                  <Table.Header className="bg-transparent text-dark-50">
                    {columns.map((column, i) => (
                      <Table.HeaderCell
                        {...column.th}
                        width={column["width"]}
                        key={i}
                        className={column.th?.className}
                      >
                        {column.label}
                      </Table.HeaderCell>
                    ))}
                  </Table.Header>
                  <Table.Body className="border border-lines">
                    {data.data.map((item, i) => (
                      <Table.Row key={i} className="divide-x divide-lines">
                        {columns.map((column, j) => (
                          <Table.Cell key={j} className={column.td?.className}>
                            {render(itemValue(item, column), column, item)}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopInventorsPage;
