import * as React from "react";
import ProjectsTable from "@/components/shared/ProjectsTable";

function RelatedProjectsTab({ project }) {
  const relatedProjects = project.relationships.relatedProjects.data;

  return (
    <div
      className="flex flex-col gap-10 overflow-auto"
      data-testid="members-table"
    >
      <ProjectsTable
        projects={relatedProjects}
        widthTitle={290}
        isLoading={false}
      />
    </div>
  );
}

export default RelatedProjectsTab;
