import * as React from "react";
import { useState } from "react";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import Filter from "@/components/shared/Filter";
import FilterTags from "@/components/shared/FilterTags";
import { FilterOption } from "@/types";

const categoriesOptions = [
  { name: "Member", id: "members" },
  { name: "Project", id: "projects" },
  { name: "Research Item", id: "research" },
  { name: "Resource", id: "resources" },
  { name: "Invention", id: "inventions" },
  { name: "Daily News Item", id: "daily_news_feed" },
];

function Filters({ focusAreaOptions }) {
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedCategories, setSelectedCategories] = useState<FilterOption[]>(
    [],
  );
  const [focusAreasFocus, setFocusAreasFocus] = useState(false);
  const [categoryFocus, setCategoryfocus] = useState(false);

  return (
    <>
      <div className="flex space-x-4 py-10">
        <Filter
          name="category"
          items={categoriesOptions}
          selectedItems={selectedCategories}
          setSelectedItems={setSelectedCategories}
          highlight={categoryFocus}
          setFocus={setCategoryfocus}
        >
          <Filter.Label>Types</Filter.Label>
          <Filter.Content>
            <CheckboxGroup
              options={categoriesOptions}
              selectedOptions={selectedCategories}
              setSelectedOptions={setSelectedCategories}
              className="py-1 flex flex-col w-56"
            />
          </Filter.Content>
        </Filter>
        <Filter
          name="focusAreas"
          items={focusAreaOptions}
          selectedItems={selectedFocusAreas}
          setSelectedItems={setSelectedFocusAreas}
          highlight={focusAreasFocus}
          setFocus={setFocusAreasFocus}
          className="flex-1"
        >
          <Filter.Label>Focus Area</Filter.Label>
          <Filter.Content>
            <CheckboxGroup
              options={focusAreaOptions}
              selectedOptions={selectedFocusAreas}
              setSelectedOptions={setSelectedFocusAreas}
              className="focus-area-checkbox-group"
              labelClassName="sm:w-325px"
            />
          </Filter.Content>
        </Filter>
      </div>
      <div className="flex flex-wrap mt-4 mb-6">
        <FilterTags
          options={selectedCategories}
          setOptions={setSelectedCategories}
          isFocused={categoryFocus}
          setFocus={setCategoryfocus}
        />
        <FilterTags
          options={selectedFocusAreas}
          setOptions={setSelectedFocusAreas}
          isFocused={focusAreasFocus}
          setFocus={setFocusAreasFocus}
        />
      </div>
    </>
  );
}

export default Filters;
