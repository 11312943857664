import * as React from "react";
import clsx from "clsx";
import { useField } from "formik-latest";
import { ReactElement } from "react";
import { useDebounce } from "use-debounce";

function FormBlock({
  name,
  label,
  hint = "",
  optional = false,
  optionalLabel = "(optional)",
  active = true,
  labelClassName = "",
  description = null,
  type = "text",
  children,
  leftChildren = null,
  className = "",
  classNameChildren = "",
}: {
  name: string;
  label?: string;
  hint?: string;
  optional?: boolean;
  optionalLabel?: string;
  active?: boolean;
  labelClassName?: string;
  type?: string;
  children: ReactElement | ReactElement[];
  leftChildren?: null | ReactElement;
  className?: string;
  classNameChildren?: string;
  description?: string | null;
}) {
  const [field, meta, helpers] = useField(name);
  const [debounceTouched] = useDebounce(meta.touched, 100);
  const error = debounceTouched && meta.error;
  const formOnChange =
    type === "file" ? value => helpers.setValue(value) : field.onChange;

  let newChildren = React.cloneElement(children, {
    error: !!error,
    id: name,
    name: name,
    onChange: children.props.onChange || formOnChange,
    onBlur: field.onBlur,
    value: field.value || "",
    checked: field.value || "",
  });

  return (
    <div className={clsx("flex flex-col", className)}>
      {label && (
        <>
          <label
            htmlFor={name}
            className={clsx(
              "flex font-h3 mb-1 text-dark-100",
              !active && "text-gray-400",
              labelClassName,
            )}
          >
            {label}{" "}
            {optional && (
              <span
                className={clsx(
                  "font-body ml-1 self-center",
                  active ? "text-gray-500" : "text-gray-400",
                )}
              >
                {optionalLabel}
              </span>
            )}
          </label>
          {description && (
            <span className="font-body -mt-1 text-gray-500">{description}</span>
          )}
        </>
      )}
      <div className={classNameChildren}>
        {leftChildren}
        {newChildren}
      </div>
      {error && <div className="text-error">{meta.error}</div>}
      {hint && <p className="text-14 mt-1 text-dark-50">{hint}</p>}
    </div>
  );
}
export default FormBlock;
