// If an empty value is passed then an '&option=' wouldn't be created
const simpleValue = (option, value) => {
  if (option == "filter[archived]") {
    return `${option}=${value}`;
  } else {
    return !!value ? `${option}=${value}` : "";
  }
};

const simpleArray = (option, array) =>
  array.length > 0 ? `${option}=${array.join(",")}` : "";

// keyBasedArray supports passing an array of values or a single value in each key
// For example: paramBuilder({ filter: { status: [1,2], name: "A text" } })
// And it also supports passing an empty array or an empty string
// without the need of worrying about it, because it won't create
// an empty parameter: '&optionA=&optionB...'
const keyBasedArray = (option, object) =>
  Object.keys(object)
    .map(key => {
      return Array.isArray(object[key])
        ? simpleArray(`${option}[${key}]`, object[key])
        : simpleValue(`${option}[${key}]`, object[key]);
    })
    .filter(param => param !== "")
    .join("&");

const actionsMap = {
  include: simpleArray,
  sort: simpleArray,
  filter: keyBasedArray,
  fields: keyBasedArray,
  page: keyBasedArray,
  override_records: simpleValue,
  preload: simpleArray,
  exclude_links: simpleValue,
};

// ParamBuilder also receives arbitrary keys e.g.
// const input = { category: ["users", "research"] }
// paramBuilder(input) -> "category=users,research"
const paramBuilder = options => {
  return Object.keys(options)
    .map(key => {
      const generator = actionsMap[key] || simpleArray;
      return generator(key, options[key]);
    })
    .filter(param => param.length > 0)
    .join("&");
};

export default paramBuilder;
