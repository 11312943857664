import * as React from "react";
import clsx from "clsx";
import { Form, Formik } from "formik-latest";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import useCurrentUser from "@/hooks/useCurrentUser";
import useFlashMessage from "@/hooks/useFlashMessage";
import Checkbox from "@/components/shared/Checkbox";
import FormBlock from "@/components/shared/FormBlock";
import preferences from "./preferences";

const fetchEmailPreferences = () => {
  const params = paramBuilder({
    fields: {
      emailPreferences: [
        "notifyNewUserJoinedPici",
        "notifyNewAnnouncementPost",
        "notifyStaffAcountsChanges",
        "notifyNonStaffAcountsChanges",
        "notifyCenterMemberAcountsChanges",
        "notifyNewResearchDocumentUploaded",
        "notifyNewResourceCreated",
        "notifyDailyNewsFeed",
        "notifyNewInventionCreated",
        "notifyInventionReviewUpdated",
        "notifyMonthlyDigest",
        "notifyWeeklyIpSummary",
        "notifyAllClinicalUpdates",
        "notifyResearchDocumentDailyDigest",
        "notifyResearchDocumentComment",
        "notifyResearchDocumentOtherCommunications",
      ],
    },
  });

  const url = `/api/email-preferences/me?${params}`;
  return denormalizedFetcher(url);
};

const mapRequest = (values, id) => {
  return {
    data: {
      id: id,
      type: "email-preferences",
      attributes: values,
    },
  };
};

function EmailNotificationsTab() {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const {
    id,
    isStaff,
    centerId,
    centerNameOrInstitution,
  } = currentUser.attributes;

  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery(
    ["emailPreferences", id],
    fetchEmailPreferences,
  );
  const emailPreferences = data?.data;

  const showFlash = useFlashMessage({
    message: "Your changes have been saved!",
    errorMessage: "Your changes could not be saved due to an error.",
  });

  const updateEmailNotificationsPreferences = (item, { setSubmitting }) => {
    const request = mapRequest(item, emailPreferences?.id);
    saveResource("email-preferences", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        queryClient.invalidateQueries(["emailPreferences", id]);
      });
  };

  const options = preferences({
    isStaff,
    centerId,
    centerNameOrInstitution,
  });

  return (
    <div>
      <h2 className="hidden md:block text-dark-100 font-h2 pb-5 border-b border-lines">
        Email Notifications
      </h2>
      <div className="mt-0 md:mt-7.5 text-dark-75 font-body">
        {!isLoading && (
          <Formik
            initialValues={emailPreferences.attributes}
            onSubmit={updateEmailNotificationsPreferences}
            enableReinitialize
          >
            {({ values, setFieldValue, ...props }) => (
              <Form>
                <div className="space-y-10.5">
                  {options.map(
                    option =>
                      option.visible && (
                        <div key={option.section}>
                          <h3 className="font-h3 text-dark-100">
                            {option.section}
                          </h3>
                          <div className="mt-5.5 space-y-4">
                            {option.items.map(
                              item =>
                                !item.hidden && (
                                  <FormBlock key={item.name} name={item.name}>
                                    <Checkbox disabled={item.disabled}>
                                      {item.label}
                                    </Checkbox>
                                  </FormBlock>
                                ),
                            )}
                          </div>
                        </div>
                      ),
                  )}
                  <button
                    type="submit"
                    disabled={
                      !props.dirty || !props.isValid || props.isSubmitting
                    }
                    className={clsx(
                      "btn-lg btn-primary w-full md:w-auto",
                      (!props.dirty || !props.isValid || props.isSubmitting) &&
                        "bg-dark-50 hover:bg-dark-50 cursor-default",
                    )}
                  >
                    Save changes
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default EmailNotificationsTab;
