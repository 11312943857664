const ActivityTitles = {
  "USER.CREATED": "Account created",
  "USER.LOGGED_IN": "Logged in",
  "USER.PASSWORD_RESET": "Password reset",
  "USER.REACTIVATED": "Account reactivated",
  "USER.SUSPENDED": "Account suspended",
  "USER.UPDATED": "Account updated",
  "POST.CREATED": "Project Post posted by",
  "POST.DOWNLOAD": "Project Post downloaded by",
  "POST.READ": "Project Post viewed by",
  "ANNOUNCEMENT.CREATED": "Announcement posted by",
  "ANNOUNCEMENT.DOWNLOAD": "Announcement downloaded by",
  "ANNOUNCEMENT.READ": "Announcement read by",
  "RESEARCH_DOCUMENT.CREATED": "New Research created by",
  "RESEARCH_DOCUMENT.DOWNLOAD": "Research downloaded by",
  "RESEARCH_DOCUMENT.VIEW": "Research viewed by",
  "RESEARCH_DOCUMENT.UPDATED": "Research updated by",
};

export default ActivityTitles;
