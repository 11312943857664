import * as React from "react";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { parseISO, formatDistanceToNow } from "date-fns";
import { denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import useCurrentUser from "@/hooks/useCurrentUser";
import useFlashMessage from "@/hooks/useFlashMessage";
import Table from "@/components/shared/Table";
import Modal from "@/components/shared/Modal";
import projectPhotoPlaceholder from "@/images/project-photo-placeholder.png";
import clsx from "clsx";

const fetchUser = ({ queryKey }) => {
  const [_, userId] = queryKey;

  const params = paramBuilder({
    preload: ["project"],
    include: ["project"],
    fields: {
      projects: ["name"],
      projectMemberships: ["createdAt", "project"],
    },
    filter: {
      userId: userId,
    },
  });

  const url = `/api/project-memberships?${params}`;
  return denormalizedFetcher(url);
};

function LeaveProjectModal({ isOpen, setIsOpen, onAccept }) {
  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      className="w-full min-w-min max-w-2xl"
    >
      <Modal.Header>Are you sure you want to leave this project?</Modal.Header>
      <Modal.Body className="text-dark-75 font-body-large">
        You will no longer be able to receive project-related notifications.
      </Modal.Body>
      <Modal.Footer className="flex flex-wrap gap-2.5 pt-6">
        <button
          type="button"
          onClick={onAccept}
          className="btn-primary btn-sm font-meta-bold"
        >
          Yes, Leave Project
        </button>
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="btn-white btn-sm font-meta-bold"
        >
          No, Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function LoadingProjects() {
  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Project title</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {[1, 2].map(x => (
          <Table.Row key={x}>
            <Table.Cell expand={false}>
              <div className="flex md:flex-col lg:flex-row gap-5 animate-pulse">
                <div className="hidden sm:flex sm:flex-shrink-0 w-50 h-28 bg-dark-25 rounded" />
                <div className="flex flex-col sm:flex-row gap-5 flex-grow">
                  <div className="flex-grow">
                    <div className="h-5 rounded-full w-full bg-dark-25 mt-1.5" />
                    <div className="h-5 rounded-full w-full bg-dark-25 mt-1.5" />
                    <div className="h-5 rounded-full w-2/3 bg-dark-25 mt-1.5" />
                    <div className="h-3 rounded-full w-1/2 bg-dark-25 mt-1.5" />
                  </div>
                  <div className="sm:self-center">
                    <div className="h-9 w-28 bg-dark-25 rounded-md" />
                  </div>
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function ProjectRow({ membership, openDeleteModal }) {
  const project = membership.relationships.project.data;
  const { projectBanner } = project.links;
  const projectPhoto = projectBanner || projectPhotoPlaceholder;

  return (
    <Table.Row>
      <Table.Cell expand={false}>
        <div className="flex md:flex-col lg:flex-row gap-5">
          <div className="relative hidden sm:flex sm:flex-shrink-0 w-50 h-28 rounded bg-dark-100 overflow-hidden">
            <img
              src={projectPhoto}
              className={clsx(
                projectBanner
                  ? "object-cover w-full h-full"
                  : "object-fit h-full",
                "min-h-full min-w-full",
              )}
            />
            <div
              className={clsx(
                "absolute inset-0",
                projectBanner && "linear-gradient",
              )}
              aria-hidden="true"
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-5 flex-grow">
            <div className="flex-grow">
              <Link to={project.links.slug} className="font-h3 link-primary">
                {project.attributes.name}
              </Link>
              <p className="font-meta text-dark-75 mt-1.5">
                Joined{" "}
                {formatDistanceToNow(
                  parseISO(membership.attributes.createdAt),
                  { addSuffix: true },
                )}
              </p>
            </div>
            <div className="sm:self-center">
              <button
                type="button"
                onClick={() => openDeleteModal(membership.id)}
                className="btn-md btn-white"
              >
                Leave Project
              </button>
            </div>
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

function ProjectsTab() {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery(
    ["projectMemberships", currentUser?.id],
    fetchUser,
  );
  const projectMemberships = data?.data;
  const [membershipId, setMembershipId] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const openDeleteModal = id => {
    setDeleteModalOpen(true);
    setMembershipId(id);
  };

  const showFlash = useFlashMessage({
    message: "You have left the project.",
    errorMessage: "Your changes could not be saved due to an error.",
  });

  const leaveProject = () => {
    fetch(`/api/project-memberships/${membershipId}`, {
      method: "DELETE",
    })
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setDeleteModalOpen(false);
        queryClient.invalidateQueries(["projectMemberships", currentUser?.id]);
      });
  };

  return (
    <div>
      <h2 className="hidden md:block text-dark-100 font-h2 pb-5 border-b border-lines">
        Projects
      </h2>
      <div className="mt-0 md:mt-7.5 text-dark-75 font-body">
        {isLoading ? (
          <LoadingProjects />
        ) : (
          <>
            {projectMemberships?.length > 0 ? (
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Project title</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {projectMemberships.map(membership => (
                    <ProjectRow
                      key={membership.id}
                      {...{ membership, openDeleteModal }}
                    />
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <article className="bg-tint rounded-lg flex flex-col items-center text-center py-24 px-6">
                <i className="ri-article-line ri-3x leading-none text-dark-25" />
                <p className="mt-8 font-body text-dark-75">
                  You don't have any projects yet.
                </p>
              </article>
            )}
          </>
        )}
      </div>
      <LeaveProjectModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onAccept={leaveProject}
      />
    </div>
  );
}

export default ProjectsTab;
