import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { fetcher, denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { pluralize } from "@/utils/string";
import Filters from "./Filters";
import ProjectsTable from "@/components/shared/ProjectsTable";

const fetchProjects = ({ pageParam = 0, queryKey }) => {
  const [_, userId, filters] = queryKey;
  const params = paramBuilder({
    include: ["focusAreas"],
    fields: {
      projects: ["codename", "identifier", "name", "focusAreas"],
      focusAreas: ["name"],
    },
  });

  const memberFilter = `filter[memberships]=${userId}`;
  const pagination = `page[number]=${pageParam + 1}&page[size]=5`;
  const url = `/api/projects?${pagination}&${params}&${memberFilter}&${filters}`;

  return denormalizedFetcher(url);
};

function ProjectsTab({ user }) {
  const [filters, setFilters] = useState("");

  const { data: filtersData } = useQuery(["projectsFilters"], () =>
    fetcher("/api/projects/filters"),
  );

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["userProjects", user.id, filters], fetchProjects, {
    getNextPageParam: apiCanFetch,
  });
  const { data: projects, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("section-router");

  useEffect(() => {
    if (isFetching) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetching, fetchNextPage, hasNextPage]);

  const recordCount = isFetching ? "" : meta.recordCount || 0;

  return (
    <div>
      <Filters data={filtersData} setFilters={setFilters} />
      <div className="mt-7">
        <h3 className="text-byte-dark font-h3 mb-5">
          {isFetching
            ? "Retrieving projects..."
            : `Displaying ${recordCount} ${pluralize(recordCount, "Project")}`}
        </h3>
        <div className="space-y-5 md:space-y-10">
          <ProjectsTable
            projects={projects}
            widthTitle={290}
            isLoading={isFetching || isFetchingNextPage}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectsTab;
