import React from "react";

const Tooltip = ({
  active,
  payload,
  countRequired = false,
}: {
  active?: boolean;
  payload?: any;
  countRequired?: boolean;
}) => {
  if (active && payload !== undefined) {
    const { name, percentage, count } = payload[0].payload;
    return (
      <div className="bg-tint py-2 px-3">
        <p className="font-meta-bold">{name}</p>
        <p className="font-meta-bold">{`${(percentage * 100).toFixed(1)}%${
          countRequired ? ` - ${count}` : ""
        }`}</p>
      </div>
    );
  }
  return null;
};

export default Tooltip;
