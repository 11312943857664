import { fetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { Link } from "react-router-dom";
import ChartExample from "./Examples/ChartExample";
import ChartExampleThree from "./Examples/ChartExampleThree";
import ChartExampleTwo from "./Examples/ChartExampleTwo";

function Header() {
  return (
    <header className="flex flex-col items-start gap-4 md:flex-row justify-between pb-7.5 md:pb-10 border-b border-lines mb-10">
      <h1 className="font-h2 md:font-h1 text-dark-100 font-bold">Insights</h1>
    </header>
  );
}

function InsightSummary({ title, subTitle, description, linkTo, children }) {
  return (
    <article className="h-[480px] text-center relative">
      <div className="w-[240px] h-[240px] z-10 absolute m-auto left-0 right-0">
        {children}
      </div>
      <div className="bg-gray-50 p-[24px] pt-[120px] h-[calc(100%-140px)] absolute top-[140px] font-body">
        <h2 className="font-h3">{title}</h2>
        <h3 className="font-body-bold mt-2">{subTitle}</h3>
        <p className="mt-2">{description} </p>
        <Link
          to={linkTo}
          className="btn-sm btn-white w-full font-meta-bold mt-4"
        >
          Full Breakdown
        </Link>
      </div>
    </article>
  );
}

function InsightsPage() {
  const { data } = useQuery(["Insight"], () =>
    fetcher("/api/insight").then(json => {
      return json;
    }),
  );

  return (
    <div className="margin-safe py-6 lg:py-10 font-lato text-dark-100 min-h-full mb-8">
      <Header />
      <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        <InsightSummary
          title="Members"
          subTitle={`Total members: ${data?.members_size || "--"}`}
          description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem anim."
          linkTo="/insights/members"
        >
          <ChartExampleTwo />
        </InsightSummary>
        <InsightSummary
          title="Projects"
          subTitle={`Total Projects: ${data?.projects_size || "--"}`}
          description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem anim."
          linkTo="/insights/projects"
        >
          <ChartExample />
        </InsightSummary>
        <InsightSummary
          title="Inventions"
          subTitle={`Total Inventions: ${data?.inventions_size || "--"}`}
          description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem anim."
          linkTo="/insights/inventions"
        >
          <ChartExampleTwo />
        </InsightSummary>
        <InsightSummary
          title="Research"
          subTitle={`Total Topics: ${data?.research_size || "--"}`}
          description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem anim."
          linkTo="/insights/research"
        >
          <ChartExample />
        </InsightSummary>
        <InsightSummary
          title="Centers"
          subTitle={`Total Centers: ${data?.centers_size || "--"}`}
          description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem anim."
          linkTo="/insights/centers"
        >
          <ChartExampleThree />
        </InsightSummary>
      </section>
    </div>
  );
}

export default InsightsPage;
