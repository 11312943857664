import * as React from "react";
import clsx from "clsx";

function Checkbox({
  children,
  className = "",
  error = false,
  childrenClassname = "",
  disabled = false,
  ...props
}) {
  return (
    <label className={clsx("flex items-center cursor-pointer", className)}>
      <input
        type="checkbox"
        {...props}
        disabled={disabled}
        className={clsx(
          "h-4 w-4 rounded text-primary",
          error
            ? "border-error focus:ring-error"
            : "border-dark-50 focus:ring-primary",
          disabled && "opacity-25 cursor-none",
        )}
      />
      <span
        className={clsx("pl-3.5 text-dark-100 font-body", childrenClassname)}
      >
        {children}
      </span>
    </label>
  );
}

export default Checkbox;
