import * as React from "react";

function GuidedSteps() {
  const steps = [
    {
      selector: ".first-step",
      content: ({ currentStep, setCurrentStep }) => (
        <div className="flex flex-col gap-3.75">
          <span className="font-micro text-dark-50">
            Step {currentStep + 1} of {steps.length}
          </span>
          <h6 className="font-meta-bold">Exploring the PICI Community</h6>
          <p className="font-micro">
            The PICI community has a lot to explore, including members,
            projects, centers, resources and research. Use this area to navigate
            around the community.
          </p>
          <button
            className="btn-primary btn-lg font-meta w-min focus:ring-0"
            onClick={() => setCurrentStep(1)}
          >
            Next
          </button>
        </div>
      ),
    },
    {
      selector: ".second-step",
      content: ({ currentStep, setCurrentStep }) => (
        <div className="flex flex-col gap-3.75">
          <span className="font-micro text-dark-50">
            Step {currentStep + 1} of {steps.length}
          </span>
          <h6 className="font-meta-bold">Your Profile</h6>
          <p className="font-micro">
            Your PICI profile is your area to shine. Other members can explore
            your experience, areas of research, etc. The more complete you make
            your profile, the easier it is for other members to collaborate with
            you. Manage your profile in your Account.
          </p>
          <button
            className="btn-primary btn-lg font-meta w-min focus:ring-0"
            onClick={() => setCurrentStep(2)}
          >
            Next
          </button>
        </div>
      ),
    },
    {
      selector: ".third-step",
      content: ({ currentStep, setCurrentStep }) => (
        <div className="flex flex-col gap-3.75">
          <span className="font-micro text-dark-50">
            Step {currentStep + 1} of {steps.length}
          </span>
          <h6 className="font-meta-bold">Daily News</h6>
          <p className="font-micro">
            Here is where you'll find the latest news and updates from the PICI
            community.
          </p>
          <button
            className="btn-primary btn-lg font-meta w-min focus:ring-0"
            onClick={() => setCurrentStep(3)}
          >
            Next
          </button>
        </div>
      ),
    },
    {
      selector: ".last-step",
      content: ({ currentStep, setIsOpen }) => (
        <div className="flex flex-col gap-3.75">
          <span className="font-micro text-dark-50">
            Step {currentStep + 1} of {steps.length}
          </span>
          <h6 className="font-body-bold">Projects</h6>
          <p className="font-meta">
            Projects are a great way to collaborate and engage with other
            like-minded PICI members.
          </p>
          <button
            className="btn-primary btn-lg font-meta w-min focus:ring-0"
            onClick={() => setIsOpen(false)}
          >
            Complete Tour & Get Started
          </button>
        </div>
      ),
    },
  ];
  return steps;
}

export default GuidedSteps;
