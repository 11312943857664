import * as React from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import csrfFetch from "@/utils/csrf-fetch";
import useFlashMessage from "@/hooks/useFlashMessage";
import LoginLayout from "@/components/shared/LoginLayout";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";

const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      "Invalid password, your password should have at least 8 characters",
    ),
  password_confirmation: Yup.string()
    .required("Password Confirmation is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

function ResetPasswordPage() {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const showFlash = useFlashMessage({
    message:
      "Your password has been reset successfully. Use your new login details to log in.",
  });

  const onSubmit = (values, { setSubmitting }) => {
    const body = {
      password_reset: { password: values.password },
    };
    const request = {
      method: "PATCH",
      body: JSON.stringify(body),
    };

    csrfFetch(`/users/${id}/password`, request)
      .then(response => {
        if (response?.ok) {
          showFlash({ success: true });
          navigate("/login");
        } else {
          return response?.json();
        }
      })
      .then(obj => {
        setError(obj?.message);
        setSubmitting(false);
      });
  };

  return (
    <LoginLayout>
      <h2 className="text-dark-100 font-h2 mb-2">Reset Password</h2>
      <p className="font-body text-dark-75">
        Please create a new password below.
      </p>
      {error && (
        <div className="mt-4 bg-red-100 text-error py-2.5 px-4 rounded flex space-x-2.5">
          <i className="ri-error-warning-line font-body-large" />
          <p className="font-meta ">{error}</p>
        </div>
      )}
      <section className="mt-6">
        <Formik
          initialValues={{
            password: "",
            password_confirmation: "",
          }}
          onSubmit={onSubmit}
          validationSchema={ResetPasswordSchema}
        >
          {({ isSubmitting, isValid, dirty }) => {
            const isDisabled = isSubmitting || !isValid || !dirty;
            return (
              <Form>
                <div className="flex flex-wrap gap-x-2.5 gap-y-5 md:gap-y-6">
                  <FormBlock
                    label="Password"
                    name="password"
                    className="w-full"
                    labelClassName="md:mb-2 font-body-bold"
                  >
                    <Input
                      placeholder="Your new Password"
                      data-cy="user-password"
                      type="password"
                      minLength="8"
                    />
                  </FormBlock>
                  <FormBlock
                    label="Confirm Password"
                    name="password_confirmation"
                    className="w-full"
                    labelClassName="md:mb-2 font-body-bold"
                  >
                    <Input
                      placeholder="Confirm your password"
                      data-cy="user-passwordConfirmation"
                      type="password"
                    />
                  </FormBlock>
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className={clsx(
                      "mt-7.5 btn-md w-full",
                      isDisabled ? "btn-disabled" : "btn-primary",
                    )}
                  >
                    Create New Password
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </section>
    </LoginLayout>
  );
}

export default ResetPasswordPage;
