import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { denormalizedFetcher } from "@/utils/api";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { pluralize } from "@/utils/string";
import useTrackVisit from "@/hooks/useTrackVisit";
import useCurrentUser from "@/hooks/useCurrentUser";
import paramBuilder from "@/utils/network/paramBuilder";
import ProjectsTable from "@/components/shared/ProjectsTable";
import NewProjectModal from "./NewProjectModal";
import Filters from "./Filters";
import Slider from "./Slider";

const fetchProjects = ({ pageParam = 0, queryKey }) => {
  const filters = queryKey[1];
  const params = paramBuilder({
    include: ["focusAreas"],
    fields: {
      projects: [
        "id",
        "name",
        "identifier",
        "updatedAt",
        "focusAreas",
        "codename",
        "featured",
        "createdAt",
      ],
    },
    focusAreas: ["id", "name"],
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `/api/projects?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

const fetchFeaturedProjects = () => {
  const params = paramBuilder({
    include: ["focusAreas"],
    fields: {
      projects: [
        "id",
        "name",
        "identifier",
        "updatedAt",
        "focusAreas",
        "codename",
        "featured",
      ],
    },
    focusAreas: ["id", "name"],
    filter: {
      featured: [true],
    },
  });
  const url = `api/projects?${params}`;
  return denormalizedFetcher(url);
};

function SlideSkeleton() {
  return (
    <div className="flex w-full">
      <div className="rounded-lg flex-1 h-56 lg:h-80 animate-pulse mr-4">
        <div className="h-full bg-dark-100 rounded-lg overflow-hidden">
          <div className="h-full flex flex-col items-start p-5 lg:p-10 opacity-20">
            <div className="inline-flex py-1 mb-9 lg:mb-24 bg-dark-25 h-4 w-20 rounded-full" />
            <div className="mt-auto mb-2 bg-dark-25 h-4 w-1/4 rounded-full" />
            <div className="bg-dark-25 h-9 w-full sm:w-3/4 rounded-full" />
            <div className="flex items-center w-full mt-2">
              <div className="bg-dark-25 h-4 w-1/4 sm:w-1/5 rounded-full" />
              <div className="ml-2 bg-dark-25 h-4 w-1/4 sm:w-1/5 rounded-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-lg w-1/12 h-56 lg:h-80 animate-pulse">
        <div className="h-full bg-dark-100 rounded-l-lg overflow-hidden">
          <div className="h-full p-5 lg:p-10 opacity-20" />
        </div>
      </div>
    </div>
  );
}

function Header({ currentUser, setNewProjectModal }) {
  return (
    <header className="flex flex-col items-start gap-4 md:flex-row justify-between pb-7.5 md:pb-10 border-b border-lines mb-10">
      <h1 className="font-h2 md:font-h1 text-dark-100 font-bold">Projects</h1>
      {currentUser?.attributes.isStaff && (
        <button
          className="btn-sm btn-primary"
          onClick={() => setNewProjectModal(true)}
        >
          <i className="ri-add-line mr-2" />
          Add New Project
        </button>
      )}
    </header>
  );
}

function ProjectsPage() {
  useTrackVisit("projects");
  const currentUser = useCurrentUser();
  const { search } = useLocation();
  const [filters, setFilters] = useState(search.slice(1));
  const [newProjectModal, setNewProjectModal] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["projects", filters], fetchProjects, {
    getNextPageParam: apiCanFetch,
  });

  const { data: projects, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("section-router");
  const { data: featuredProjects }: { data: any } = useQuery(
    ["featured-projects"],
    fetchFeaturedProjects,
  );

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  // These two useEffect were created two avoid the Carousel component to broke
  // When rendering the Carousel component, the component lose the widthPort
  // These useEffects are a workaround to re-render the component correctly.
  useEffect(() => {
    setShowCarousel(false);
  }, [newProjectModal, isAtBottom]);

  useEffect(() => {
    if (!showCarousel) setShowCarousel(true);
  }, [showCarousel]);

  const recordCount = isFetching ? "" : meta.recordCount || 0;

  return (
    <div className="margin-safe pb-4 sm:pb-8 xl:pb-12 pt-6 lg:pt-10 font-lato text-dark-100 min-h-full">
      <Header
        currentUser={currentUser}
        setNewProjectModal={setNewProjectModal}
      />
      <div className="flex flex-col-reverse sm:flex-col">
        <Filters setFilters={setFilters} />
        {!filters.includes("filter") &&
          (isFetching || !showCarousel ? (
            <div className="mb-10 overflow-x-hidden">
              <SlideSkeleton />
            </div>
          ) : (
            <>
              {featuredProjects?.data.length > 0 && showCarousel && (
                <div className="mb-10 overflow-x-hidden">
                  <Slider featuredList={featuredProjects?.data} />
                </div>
              )}
            </>
          ))}
      </div>
      <h3 className="text-byte-dark font-h3 mb-5" data-testid="projects-count">
        {isFetching
          ? "Retrieving projects..."
          : `Displaying ${recordCount} ${pluralize(recordCount, "Project")}`}
      </h3>
      <ProjectsTable
        projects={projects}
        widthTitle={512}
        isLoading={isFetching || isFetchingNextPage}
        showLastUpdate
      />
      <NewProjectModal {...{ newProjectModal, setNewProjectModal }} />
    </div>
  );
}

export default ProjectsPage;
