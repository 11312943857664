import React, { useState, useEffect } from "react";
import { denormalizedFetcher } from "@/utils/api";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import paramBuilder from "@/utils/network/paramBuilder";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import InventionsTable from "../InventionsTable";
import InventionsFilter from "../InventionsFilter";
import { InventionTableType } from "../InventionTableType";
import InternalReviewModal from "../InternalReviewModal";

const fetchAllInventions = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const params = paramBuilder({
    include: ["users", "centers", "institutions"],
    fields: {
      inventions: [
        "identifier",
        "title",
        "ipType",
        "centers",
        "abstractNotes",
        "institutions",
        "inventorsList",
        "users",
        "externalUsers",
        "disclosure",
        "review",
        "updatedAt",
        "updatedStatus",
      ],
      users: ["fullName"],
    },
  });

  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `/api/inventions?${pagination}&${params}&${filters}`;

  return denormalizedFetcher(url);
};

export type AllTabProps = {
  tabType: InventionTableType;
};

function AllTab({ tabType }: AllTabProps) {
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [currentInvention, setCurrentInvention] = useState({ attributes: {} });
  const [filters, setFilters] = useState("sort=-disclosure");
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["inventions", filters], fetchAllInventions, {
    getNextPageParam: apiCanFetch,
  });
  const { data: inventions, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("all-content");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  return (
    <>
      <div className="sticky top-[106px] border-t border-lines">
        <InventionsFilter
          total={meta?.recordCount}
          isLoading={isFetching}
          queryFilter={filters}
          setFilters={setFilters}
          tabType={tabType}
        />
      </div>
      <article
        id="all-content"
        className="overflow-x-hidden h-vh-60 px-7.5 bg-primary-lighter"
      >
        <InventionsTable
          inventions={inventions}
          tabType={tabType}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          setCurrentInvention={setCurrentInvention}
          setIsOpenReviewModal={setIsOpenReviewModal}
        />
      </article>
      <InternalReviewModal
        isOpenReviewModal={isOpenReviewModal}
        setIsOpenReviewModal={setIsOpenReviewModal}
        invention={currentInvention}
      />
    </>
  );
}

export default AllTab;
