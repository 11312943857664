import * as React from "react";
import SectionGroup from "@/components/shared/Admin/SectionGroup";
import ContactPointItem from "./ContactPointItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

function ContactPoints({ contactList, setContactList, usersSuggestions }) {
  const handleUpdateConcat = (contact, index) => {
    const clonedList = [...contactList];
    clonedList.splice(index, 1, contact);
    setContactList(clonedList);
  };

  const handleRemoveContact = index => {
    const clonedList = [...contactList];
    clonedList.splice(index, 1);
    setContactList(clonedList);
  };

  const handleAddContact = _ => {
    const newContact = { sort_position: contactList.length };
    const clonedList = [...contactList, newContact];
    setContactList(clonedList);
  };

  const onDragEnd = result => {
    const { destination, source } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newContactList = [...contactList];
    newContactList.splice(source.index, 1);
    newContactList.splice(destination.index, 0, contactList[source.index]);
    newContactList.forEach((_contact, index) => {
      _contact.sort_position = index;
    });
    setContactList(newContactList);
  };

  return (
    <SectionGroup
      title="Contact Information"
      description="How users will be able to get in touch with this center."
      className="border-t min-h-[120px]"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {contactList.map((contact, index) => (
                <ContactPointItem
                  key={index}
                  index={index}
                  userContact={contact}
                  updateUserContact={handleUpdateConcat}
                  removeUserContact={handleRemoveContact}
                  usersSuggestions={usersSuggestions}
                  className="mb-5px"
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        type="button"
        className="btn-sm text-indigo-600 hover:text-indigo-500"
        onClick={handleAddContact}
      >
        <i className="ri-add-line mr-2" />
        New Point of Contact
      </button>
    </SectionGroup>
  );
}

export default ContactPoints;
