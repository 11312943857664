import * as React from "react";
import { Field } from "formik-latest";
import Select from "@/components/shared/Select";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { filterByid, filterByParam } from "../filters";
import SearchBar from "@/components/shared/SearchBar";
import ResultList from "@/components/shared/ResultList";
import { isEqual } from "lodash";

type Options = {
  id?: string;
  name: string;
};

const parsedCenters = centers => {
  let centerItems: Options[] = [];
  if (centers) {
    centerItems = centers?.data.map(center => ({
      id: center.id,
      name: center.attributes.name,
    }));
    centerItems.push({ id: "other", name: "Other" });
  }

  return centerItems;
};

const parsedInstitutions = institutions => {
  return institutions?.data.map(institute => ({
    id: institute.id,
    name: institute.attributes.name,
  }));
};

const fetchCenters = () => {
  const params = paramBuilder({
    fields: {
      centers: ["name", "shorthand", "slug"],
    },
    exclude_links: true,
  });
  const url = `/api/centers?${params}`;
  return denormalizedFetcher(url);
};

const fetchInstitutes = () => {
  const url = "/api/institutions?";
  return denormalizedFetcher(url);
};

function CenterForm({ values, onChange }) {
  const { data: centers }: { data: any } = useQuery(["centers"], fetchCenters);
  const { data: institutions }: { data: any } = useQuery(
    ["institutions"],
    fetchInstitutes,
  );
  const [selectedCenters, setSelectedCenters] = useState<Options[]>(
    values.centers,
  );

  const [selectedInstitutions, setSelectedInstitutions] = useState<Options[]>(
    values.institutions,
  );
  const [showInstitutions, setShowInstitutions] = useState<boolean>(false);
  const [centerText, setCenterText] = useState<string | null>("");
  const [institutionText, setInstitutionText] = useState<string>("");

  const centerOptions: Options[] = React.useMemo(() => parsedCenters(centers), [
    centers,
  ]);
  const institutionsOptions: Options[] = React.useMemo(
    () => parsedInstitutions(institutions),
    [institutions],
  );

  const centerOptionsFiltered = React.useMemo(
    () => filterByid(centerOptions, selectedCenters),
    [centerOptions, selectedCenters],
  );

  const institutionsOptionsFiltered = React.useMemo(
    () => filterByParam(institutionsOptions, selectedInstitutions, "name"),
    [selectedInstitutions, institutionsOptions],
  );

  const selectedCentersData = React.useMemo(() => {
    return centerOptions.filter(center => selectedCenters.includes(center.id));
  }, [centerOptions, selectedCenters]);

  const onCenterSelect = value => {
    if (value === "other") {
      setShowInstitutions(true);
      setCenterText("other");
    } else {
      setSelectedCenters([...selectedCenters, value]);
      centerText && setCenterText(null);
      showInstitutions && setShowInstitutions(false);
    }
  };

  const onInstitutionSelect = value => {
    if (!value.name) {
      setSelectedInstitutions([...selectedInstitutions, { name: value }]);
    } else {
      setSelectedInstitutions([...selectedInstitutions, value]);
    }
    setShowInstitutions(false);
    setInstitutionText("");
    setCenterText("");
  };

  const removeCenters = index => {
    setSelectedCenters([
      ...selectedCenters.slice(0, index),
      ...selectedCenters.slice(index + 1),
    ]);
  };

  const removeInstitutions = index => {
    setSelectedInstitutions([
      ...selectedInstitutions.slice(0, index),
      ...selectedInstitutions.slice(index + 1),
    ]);
  };

  useEffect(() => {
    if (!isEqual(selectedCenters, values.centers)) {
      onChange("centers", selectedCenters);
    }
  }, [selectedCenters]);

  useEffect(() => {
    if (!isEqual(selectedInstitutions, values.institutions)) {
      onChange("institutions", selectedInstitutions);
    }
  }, [selectedInstitutions]);

  return (
    <>
      <Field name="centers">
        {({ field, form }) => {
          const { touched, errors } = form;
          return (
            <>
              <label className="font-body-bold mb-1">
                Center(s) or Institution(s)
              </label>
              <Select
                selected={centerText}
                setSelected={(centerId: number) => onCenterSelect(centerId)}
                options={centerOptionsFiltered}
                placeholder="Select a new Center"
                {...field}
              />
              {touched?.centers && errors?.centers && (
                <div className="text-error text-14 mt-5px">
                  {errors?.centers}
                </div>
              )}
              {showInstitutions && (
                <SearchBar
                  className="mt-4"
                  options={institutionsOptionsFiltered || []}
                  matchCriteria={(input, itemSearchable) =>
                    itemSearchable
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onTextChange={item => setInstitutionText(item)}
                  placeholder="Type Institutions name"
                  accessor={item => item.name}
                  onSelect={institute => onInstitutionSelect(institute)}
                  displayNewExternal
                  selected={institutionText}
                >
                  {({ item, index }) => {
                    return index === "external" ? (
                      <div className="p-3 cursor-pointer flex flex-col">
                        <span className="text-dark-100">
                          + Add "{item}" Insitutions
                        </span>
                      </div>
                    ) : (
                      <div className="px-6 py-4 cursor-pointer flex sm:space-x-5 hover:bg-gray-50 hover:text-indigo-500">
                        <span className="font-body max-w-md">{item.name}</span>
                      </div>
                    );
                  }}
                </SearchBar>
              )}
            </>
          );
        }}
      </Field>
      {selectedCenters.length > 0 && (
        <ResultList
          list={selectedCentersData}
          onDelete={(_, index) => removeCenters(index)}
          attributeToShow="name"
        />
      )}
      {selectedInstitutions.length > 0 && (
        <ResultList
          list={selectedInstitutions}
          onDelete={(_, index) => removeInstitutions(index)}
          attributeToShow="name"
        />
      )}
    </>
  );
}

export default CenterForm;
