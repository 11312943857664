import { useQuery } from "@tanstack/react-query";
// @ts-ignore
import { fetcher } from "@/utils/api.ts";

function usePageView() {
  return useQuery(
    ["user-page-views"],
    () => fetcher("/api/user-page-views/me"),
    {
      refetchInterval: 15000,
    },
  );
}

export default usePageView;
