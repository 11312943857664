import * as React from "react";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NewsItemCard from "./NewsItemCard";
import useOptimicticUpdate from "@/hooks/useOptimistic";
import { saveResource } from "@/utils/api";

const reorder = (list, startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const optimisticNewsFeed = (oldState, payload) => {
  return {
    ...oldState,
    data: {
      ...oldState.data,
      attributes: {
        ...oldState.data.attributes,
        newsItemPositions: payload,
      },
    },
  };
};

function NewsItemList({
  itemList,
  categories,
  newsFeed,
  setShowDeleteModal,
  setCurrentNewsItem,
  setFormIsOpen,
}) {
  const newsFeedId = newsFeed.id;

  const updateNewsFeedRequest = payload => {
    const request = {
      data: {
        type: "news-feeds",
        id: newsFeedId,
        attributes: {
          newsItemPositions: payload,
        },
      },
    };
    return saveResource("news-feeds", request);
  };

  const mutation = useOptimicticUpdate({
    queryKey: ["news-feeds", newsFeedId],
    mutationFn: updateNewsFeedRequest,
    optmisticNewValues: optimisticNewsFeed,
  });

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedNewsItems = reorder(
      itemList,
      result.source.index,
      result.destination.index,
    ).map(newsItem => newsItem.id);

    mutation.mutate(reorderedNewsItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={clsx(
              "shadow rounded-lg divide-y-2 divide-dashed",
              snapshot.isDraggingOver ? "bg-gray-200" : "bg-white",
            )}
          >
            {itemList.map((newsItem, index) => (
              <Draggable
                draggableId={newsItem.id}
                index={index}
                key={`newsItem-${newsItem.id}`}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={
                      snapshot.isDragging
                        ? "bg-yellow-100 border-2 border-yellow-300 border-dashed"
                        : "bg-white"
                    }
                  >
                    <NewsItemCard
                      setFormIsOpen={setFormIsOpen}
                      isDraft={newsFeed.attributes.status === "draft"}
                      {...{
                        newsFeedId,
                        newsItem,
                        categories,
                        setShowDeleteModal,
                        setCurrentNewsItem,
                      }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default NewsItemList;
