import * as React from "react";
import { useState, createContext, useContext } from "react";
import clsx from "clsx";
import { useURLFilter } from "@/hooks/useURLFilter";
import Dropdown from "@/components/shared/Dropdown";

const FilterContext = createContext({
  clear: () => {},
  showClear: false,
  setFocus: (_: boolean) => {},
  closeOnSelect: false,
  isOpen: false,
  highlight: false,
});

// selectedItems can be an array of objects (e.g. checkbox filters)
// or a single object (e.g. select dropdown filters)
function Filter({
  children,
  name,
  items,
  selectedItems,
  setSelectedItems,
  closeOnSelect = false,
  setFocus = () => {},
  highlight = false,
  className = "",
}: {
  children: any;
  name: string;
  items: any;
  selectedItems: any;
  setSelectedItems: any;
  closeOnSelect?: boolean;
  setFocus?: any;
  highlight?: boolean;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isArray = Array.isArray(selectedItems);
  const showClear = isArray ? selectedItems.length > 0 : selectedItems !== null;
  const clear = () => (isArray ? setSelectedItems([]) : setSelectedItems(null));
  useURLFilter(name, items, selectedItems, setSelectedItems);

  return (
    <Dropdown
      closeOnSelect={closeOnSelect}
      onToggle={setIsOpen}
      className={className}
    >
      <FilterContext.Provider
        value={{
          clear: clear,
          showClear: showClear,
          setFocus: setFocus,
          closeOnSelect: closeOnSelect,
          isOpen: isOpen,
          highlight: highlight,
        }}
      >
        {children}
      </FilterContext.Provider>
    </Dropdown>
  );
}

Filter.Label = ({ children, className = "", ...props }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setFocus, highlight } = useContext(FilterContext);

  return (
    <Dropdown.Label
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
      className={clsx(
        "font-body-bold md:font-h3 pl-3 pr-1 lg:pl-4 lg:pr-2 w-full sm:w-max",
        {
          "outline-none border border-dark-100": highlight,
          "!h-12 !text-17": props.size === "base",
          "!h-30px !text-14 !font-light": props.size === "sm",
        },
        className,
      )}
      {...props}
    >
      {children}
      <i className="ri-arrow-drop-down-line ri-2x text-dark-100 font-normal ml-auto sm:ml-1 leading-none" />
    </Dropdown.Label>
  );
};

Filter.Content = ({ children, className = "" }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { clear, showClear, closeOnSelect } = useContext(FilterContext);

  return (
    <Dropdown.Content
      className={clsx("font-lato w-11/12 sm:w-max min-w-224px", className)}
    >
      {({ setOpen }) => (
        <>
          {children}
          {!closeOnSelect && (
            <div className="p-4 flex border-t border-dark-25">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="btn-primary btn-xs"
              >
                Done
              </button>
              {showClear && (
                <button
                  type="button"
                  onClick={clear}
                  className="ml-2.5 cursor-pointer btn-white btn-xs"
                >
                  Clear
                </button>
              )}
            </div>
          )}
        </>
      )}
    </Dropdown.Content>
  );
};

export default Filter;
