import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import Filter from "@/components/shared/Filter";
import FilterTags from "@/components/shared/FilterTags";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import { FilterOption } from "@/types";

const sortOptions = [
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "First Name", id: "firstName" },
  { name: "Last Name", id: "lastName" },
];

function Filters({ setFilters }) {
  const { data: roleOptions } = useQuery(["filter_roles"], () =>
    fetcher("/api/users/role_filters"),
  );
  const { data: focusAreasOptions } = useQuery(["filter_focus_areas"], () =>
    fetcher("/api/users/focus_areas_filters"),
  );
  const [selectedRoles, setSelectedRoles] = useState<FilterOption[]>([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [roleFocus, setRoleFocus] = useState(false);
  const [focusAreaFocus, setFocusAreaFocus] = useState(false);

  const params = paramBuilder({
    filter: {
      role: selectedRoles.map(x => x.id),
      focusAreas: selectedFocusAreas.map(x => x.id),
    },
    sort: [selectedSort?.id],
  });

  useEffect(() => {
    setFilters(params);
  }, [params, setFilters]);

  return (
    <div>
      <div className="flex">
        <div className="flex space-x-4 w-full sm:w-auto">
          <Filter
            name="role"
            items={roleOptions?.data}
            selectedItems={selectedRoles}
            setSelectedItems={setSelectedRoles}
            setFocus={setRoleFocus}
            className="flex-1"
          >
            <Filter.Label>Role</Filter.Label>
            <Filter.Content>
              <CheckboxGroup
                options={roleOptions?.data}
                selectedOptions={selectedRoles}
                setSelectedOptions={setSelectedRoles}
                className="filter-checkbox-group"
              />
            </Filter.Content>
          </Filter>
          <Filter
            name="focusArea"
            items={focusAreasOptions?.data}
            selectedItems={selectedFocusAreas}
            setSelectedItems={setSelectedFocusAreas}
            setFocus={setFocusAreaFocus}
            className="flex-1"
          >
            <Filter.Label>Focus Area</Filter.Label>
            <Filter.Content>
              <CheckboxGroup
                options={focusAreasOptions?.data}
                selectedOptions={selectedFocusAreas}
                setSelectedOptions={setSelectedFocusAreas}
                className="focus-area-checkbox-group"
                labelClassName="sm:w-325px"
              />
            </Filter.Content>
          </Filter>
        </div>
        <div className="hidden sm:flex sm:items-center sm:ml-auto">
          <span className="mr-4 text-dark-100 font-body-bold">Sort by:</span>
          <Filter
            name="sort"
            items={sortOptions}
            selectedItems={selectedSort}
            setSelectedItems={setSelectedSort}
            closeOnSelect
          >
            <Filter.Label className="flex">{selectedSort?.name}</Filter.Label>
            <Filter.Content>
              <SelectGroup
                options={sortOptions}
                selectedOption={selectedSort}
                setSelectedOption={setSelectedSort}
              />
            </Filter.Content>
          </Filter>
        </div>
      </div>
      <div className="flex flex-wrap xs:mt-5 xs:mb-2.5 mt-6 mb-4">
        <FilterTags
          options={selectedRoles}
          setOptions={setSelectedRoles}
          isFocused={roleFocus}
        />
        <FilterTags
          options={selectedFocusAreas}
          setOptions={setSelectedFocusAreas}
          isFocused={focusAreaFocus}
        />
      </div>
    </div>
  );
}

export default Filters;
