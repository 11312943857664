import { useMutation, useQueryClient } from "@tanstack/react-query";

// mutationFn: Is the function that will do the request and wil return a promise.
// optmisticNewValues: This callback will get the previous data and it will
//                     should return the new data.

function useOptimistic({
  queryKey,
  mutationFn,
  optmisticNewValues,
  onErrorCb = () => {},
  onSuccessCb = () => {},
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation(mutationFn, {
    onMutate: async payload => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(queryKey);
      // Snapshot the previous value
      const previousValues: any = queryClient.getQueryData(queryKey);
      // Optimistically update to the new value
      const newValues = optmisticNewValues(previousValues, payload);
      queryClient.setQueryData(queryKey, newValues);

      return { previousValues };
    },

    onError: async (_err, _variables, context: any) => {
      onErrorCb();
      await queryClient.setQueryData(queryKey, context.previousValues);
    },

    onSuccess: async (dataReponse: any) => {
      // Query Invalidations
      await queryClient.invalidateQueries(queryKey);
      dataReponse.ok ? onSuccessCb() : onErrorCb();
    },
  });

  return mutation;
}

export default useOptimistic;
