import * as React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import csrfFetch from "@/utils/csrf-fetch";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCSRFToken from "@/hooks/useCSRFToken";
import UserOnboarding from "@/components/shared/OnboardingLayout";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";

const OnboardingStartSchema = Yup.object({
  new_password: Yup.string()
    .required("Password is required")
    .min(
      8,
      "Invalid password, your password should have at least 8 characters",
    ),
  password_confirmation: Yup.string()
    .required("Password Confirmation is required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const mapInitialValues = {
  new_password: "",
  password_confirmation: "",
};

function OnboardingStart() {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setToken } = useCSRFToken();

  const onSubmit = values => {
    const request = {
      method: "POST",
      body: JSON.stringify(values),
    };
    csrfFetch("/user_onboarding/password", request).then(async response => {
      if (response?.ok) {
        const obj = await response?.json();
        if (obj?.token) setToken(obj.token);
        queryClient.invalidateQueries(["users", currentUser?.id]);
        navigate("/user_onboarding/picture");
      }
    });
  };

  return (
    <UserOnboarding>
      <UserOnboarding.Header>
        <h2 className="pt-0.25 text-dark-100 font-h2 pb-2">
          Set Your Password
        </h2>
        <p className="font-body text-dark-75">
          Congratulations and welcome to PICI! Below is the information you’ll
          use when logging into the member center. Create a password to complete
          your membership.
        </p>
      </UserOnboarding.Header>
      <UserOnboarding.Body>
        <Formik
          initialValues={mapInitialValues}
          onSubmit={onSubmit}
          validationSchema={OnboardingStartSchema}
        >
          {({ isSubmitting, isValid, dirty }) => {
            const isDisabled = isSubmitting || !isValid || !dirty;
            return (
              <Form>
                <div className="flex flex-wrap gap-x-2.5 gap-y-5 md:gap-y-6">
                  <FormBlock
                    label="Password"
                    name="new_password"
                    className="w-full"
                    labelClassName="md:mb-2 font-body-bold"
                  >
                    <Input
                      placeholder="Your new Password"
                      data-cy="user-password"
                      type="password"
                      minLength="8"
                    />
                  </FormBlock>
                  <FormBlock
                    label="Confirm Password"
                    name="password_confirmation"
                    className="w-full"
                    labelClassName="md:mb-2 font-body-bold"
                  >
                    <Input
                      placeholder="Confirm your password"
                      data-cy="user-passwordConfirmation"
                      type="password"
                    />
                  </FormBlock>
                  <button
                    className={clsx(
                      "btn-xl btn-primary font-lato w-full h-9.5 mt-3.5",
                      {
                        "btn-disabled": isDisabled,
                      },
                    )}
                    type="submit"
                    disabled={isDisabled}
                  >
                    Save and Continue
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </UserOnboarding.Body>
    </UserOnboarding>
  );
}

export default OnboardingStart;
