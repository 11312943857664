import * as React from "react";
import striptags from "striptags";
import { format, parseISO } from "date-fns";
import Table from "@/components/shared/Table";
import Tooltip from "@/components/shared/Tooltip";

const FileItem = ({ file }) => {
  const fileMetadata =
    file.file_metadata.filename.split(".")[1] +
    " | " +
    file.file_metadata.byte_size;
  const fileMetadataDate =
    file.file_metadata.created_at &&
    parseISO(file.file_metadata.created_at.slice(0, 10));
  const formattedDate = format(fileMetadataDate, "MMM d, yyyy");

  return (
    <Table.Row key={file.id}>
      <Table.Cell className="pr-2 lg:max-w-md">
        <div className="flex items-center">
          <i className="ri-file-line text-dark-75"></i>
          <a
            href={file.file_url}
            target="_blank"
            className="link font-body-bold text-dark-100 pl-2.5 truncate"
          >
            {file.title}
          </a>
        </div>
      </Table.Cell>
      <Table.Cell>
        <span className="uppercase font-meta">{fileMetadata}</span>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <span className="font-meta">{formattedDate}</span>
      </Table.Cell>
    </Table.Row>
  );
};

const LinkItem = ({ link }) => {
  return (
    <Table.Row key={link.id}>
      <Table.Cell className="pr-2 lg:max-w-md">
        <div className="flex items-center">
          <i className="ri-links-line text-dark-75"></i>
          <a
            href={link.url}
            target="_blank"
            className="link font-body-bold text-dark-100 pl-2.5 truncate"
          >
            {link.title}
          </a>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const REGEX = /&nbsp;/gm;

function ReferenceMaterial({ invention }) {
  const referenceMaterialFiles = invention.attributes.referenceMaterial.filter(
    referenceMaterial => referenceMaterial.type === "file",
  );
  const { identificationNumbers, notes } = invention.attributes;
  const formattedFiles =
    referenceMaterialFiles.length > 0
      ? referenceMaterialFiles.map(file => {
          return {
            ...file,
            ...invention.attributes.referenceMaterialFiles.find(rf => {
              // eyJfcmFpbHM--0b0e80aac ==> eyJfcmFpbHM
              const idRegex = /.+?(?=--)/;
              return (
                file.signedId === rf.file ||
                file.signedId.match(idRegex)[0] === rf.file.match(idRegex)[0] ||
                file.url.includes(encodeURI(rf.file_metadata.filename))
              );
            }),
          };
        })
      : [];
  const referenceMaterialLinks = invention.attributes.referenceMaterial.filter(
    referenceMaterial => referenceMaterial.type === "link",
  );
  const referenceMaterialCounter =
    referenceMaterialFiles.length + referenceMaterialLinks.length;

  const isEmptyNotes =
    striptags(invention.attributes.notes)
      .replace(REGEX, "")
      .trim().length === 0;

  return (
    <div className="flex flex-col gap-10 overflow-auto">
      {referenceMaterialCounter > 0 && (
        <div className="mb-2">
          <h3 className="font-h3 text-dark-100 mb-4">Uploaded Documents</h3>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
              <Table.HeaderCell className="flex justify-end whitespace-nowrap">
                Date Uploaded
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {formattedFiles.map((file, index) => (
                <FileItem file={file} key={`file${index}`} />
              ))}
              {referenceMaterialLinks.map((link, index) => (
                <LinkItem link={link} key={`link${index}`} />
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
      {identificationNumbers.length > 0 && (
        <div className="my-2">
          <h3 className="font-h3 text-dark-100 mb-4">ID Numbers</h3>
          <Table>
            <Table.Header>
              <Table.HeaderCell>ID Number</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {identificationNumbers.map((number, index) => {
                const { numbers, notes, type } = number;
                return (
                  <Table.Row key={index}>
                    <Table.Cell className="pr-2 font-body-bold text-dark-100">
                      <div className="flex">
                        {numbers}
                        {notes && (
                          <div className="ml-1.5">
                            <Tooltip>
                              <Tooltip.Trigger className="h-6 w-6">
                                <i className="ri-information-line ri-xl text-dark-75" />
                              </Tooltip.Trigger>
                              <Tooltip.Content>{notes}</Tooltip.Content>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell className="font-meta text-dark-75">
                      {type}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
      {notes && !isEmptyNotes && (
        <div className="my-2">
          <h3 className="font-h3 text-dark-100 mb-4">Notes</h3>
          <div
            className="bg-gray-50 p-4 border-lines border rounded"
            dangerouslySetInnerHTML={{
              __html: notes,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ReferenceMaterial;
