import fetch from "cross-fetch";

function csrfToken() {
  const csrfMeta: HTMLMetaElement | null = document.querySelector(
    "meta[name='csrf-token']",
  );
  return csrfMeta?.content || new Error("No CSRF Token found.");
}

function csrfFetch(url, options) {
  const csrfHeaders = {
    "X-CSRF-Token": csrfToken(),
    "Content-Type": "Application/JSON",
  };
  const headers = Object.assign({}, csrfHeaders, options.headers);
  const defaultOptions = { credentials: "same-origin" };

  let finalOptions = Object.assign({}, defaultOptions, options, {
    headers: headers,
  });

  return fetch(url, finalOptions).catch(error => {
    console.warn(
      "There has been a problem with your fetch operation: " + error.message,
    );
  });
}

export default csrfFetch;
