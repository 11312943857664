import * as React from "react";
import fetch from "cross-fetch";
import { Form, Formik } from "formik-latest";
import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { saveResource } from "@/utils/api";
import useFlashMessage from "@/hooks/useFlashMessage";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";
import EventsSlider from "./EventsSlider";
import UpdateConfirmationModal from "./UpdateConfirmationModal";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";

const eventSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Title is required."),
  link: Yup.string()
    .trim()
    .url("Link needs to be a valid URL. Ex: https:// http://")
    .nullable(),
});

function EventsForm({ openForm, setOpenForm, resource, editing, setEditing }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      showFlash({ success: true });
      queryClient.invalidateQueries(["events"]);
    },
    onError: () => showFlash({ success: false }),
  });
  const showFlash = useFlashMessage({
    message: `The event has been ${editing ? "updated" : "created"}.`,
  });
  const showDeleteFlash = useFlashMessage({
    message: "The event has been deleted.",
  });
  const close = useCallback(() => {
    setOpenForm(false);
    setEditing(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setShowArchiveModal(false);
  }, [setEditing, setOpenForm]);

  const save = useCallback(
    async values => {
      await mutateAsync(
        saveResource("events", {
          data: {
            type: "events",
            id: resource?.id,
            attributes: {
              title: values.title,
              link: values.link,
              location: values.location,
              hostedAt: values.hostedAt,
            },
          },
        }),
      );
      close();
    },
    [close, mutateAsync, resource],
  );

  const submit = useCallback(
    (values, { resetForm }) => {
      if (editing) {
        setShowEditModal(true);
      } else {
        save(values);
        resetForm();
      }
    },
    [editing, save],
  );

  const onDelete = useCallback(() => {
    fetch(resource.links.self, { method: "DELETE" }).then(res => {
      showDeleteFlash({ success: res.ok });
      if (res.ok) {
        queryClient.invalidateQueries(["events"]);
      }
      close();
    });
  }, [close, queryClient, resource]);

  const onArchived = useCallback(() => {
    fetch(resource.links.archive).then(res => {
      setShowArchiveModal(false);
      if (res.ok) {
        queryClient.invalidateQueries(["events"]);
      }
      close();
    });
  }, [close, queryClient, resource]);

  return (
    <Formik
      initialValues={resource.attributes}
      validationSchema={eventSchema}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => {
        return (
          <Form className="space-y-5 p-4">
            <EventsSlider
              formikProps={formikProps}
              openForm={openForm}
              setOpenForm={setOpenForm}
              close={close}
              editing={editing}
              setEditing={setEditing}
              resource={resource}
              setShowArchiveModal={setShowArchiveModal}
              setShowDeleteModal={setShowDeleteModal}
            />
            <UpdateConfirmationModal
              open={showEditModal}
              setOpen={setShowEditModal}
              onAccept={() => save(formikProps.values)}
            />
            <ArchiveConfirmationModal
              open={showArchiveModal}
              setOpen={setShowArchiveModal}
              onAccept={onArchived}
              archived={resource.attributes.archived}
            />
            <DeleteConfirmationModal
              open={showDeleteModal}
              setOpen={setShowDeleteModal}
              onAccept={onDelete}
              resource="event"
              message="The event information will no longer be available to the PICI network. It will also be removed from the Admin Center."
              className="w-105"
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default EventsForm;
