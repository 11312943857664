import * as React from "react";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import Label from "@/components/shared/Label";
import Checkbox from "@/components/shared/Checkbox";

function BasicDetailsForm({ values, onChange }) {
  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Basic Details</h3>
        <p className="text-14 text-dark-75">
          General context around the project
        </p>
      </header>
      <section className="flex-1">
        <FormBlock
          label="Project Title"
          name="name"
          className="w-full mb-6"
          labelClassName="font-body-bold"
        >
          <Input styleAs="small" />
        </FormBlock>
        <div className="flex justify-between mb-6">
          <FormBlock
            label="Project ID"
            name="identifier"
            className="w-1/2 mr-3"
            labelClassName="font-body-bold"
          >
            <Input styleAs="small" />
          </FormBlock>
          <FormBlock
            label="Project Code Name"
            name="codename"
            className="w-1/2 ml-3"
            labelClassName="font-body-bold"
            optional
          >
            <Input styleAs="small" />
          </FormBlock>
        </div>
        <FormBlock
          label="Project Aim"
          name="subtitle"
          labelClassName="font-body-bold"
          className="mb-6"
          optionalLabel="In a sentence or two, what is this initiative aiming to accomplish?"
          optional
        >
          <RichTextEditor
            value={values.subtitle}
            dataTestId="rte-subtitle"
            className="max-w-[652px]"
            onChange={value => onChange("subtitle", value)}
          />
        </FormBlock>
        <FormBlock
          label="Project Banner"
          name="banner"
          labelClassName="font-body-bold"
          className="mb-6"
        >
          <FilePickerAdmin
            name="banner"
            setSignedId={val => onChange("banner", val)}
            attachment={{
              signedId: values.banner,
              metadata: values.bannerMetadata,
              imageUrl: values.bannerUrl,
            }}
          />
        </FormBlock>
        <Label name="Is a featured project?" />
        <Checkbox
          checked={values.featured}
          onChange={() => onChange("featured", !values.featured)}
          className="mt-2"
        >
          Featured
        </Checkbox>
      </section>
    </article>
  );
}

export default BasicDetailsForm;
