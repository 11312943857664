import * as React from "react";
import Hero from "../../shared/Hero";
import ChartPage from "../../Examples/ChartPage";
import InsightCard from "../../shared/InsightCard";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import HeaderCards from "../../shared/HeaderCard/HeaderCards";
import SimpleAreaChart from "@/components/shared/Charts/SimpleAreaChart";
import PieChart from "@/components/shared/Charts/PieChart";
import InsightBarChart from "@/components/shared/Charts/BarChart/InsightBarChart";
import InsightMultiLevelPieChart from "@/components/shared/Charts/InsightMultiLevelPieChart";
import InsightPieChartWithSelectCenter from "@/components/shared/Charts/InsightPieChartWithSelectCenter";
import InsightSkeleton from "@/components/pages/InsightsPage/InsightPage/InsightSkeleton";

const CustomCenterTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { name, count } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          {name}: <span className="font-meta-bold">{count}</span>
        </p>
      </div>
    );
  }
  return null;
};

function Centers() {
  const { data, isLoading } = useQuery(["centersInsights"], () =>
    fetcher("/api/centers/insights").then(json => {
      return json;
    }),
  );
  const centersByNumberOfInventions =
    data?.centers_by_number_of_inventions || [];
  const dataLineCenterByNumberOfInventions = React.useMemo(
    () =>
      centersByNumberOfInventions.map(type => ({
        dataKey: type.id,
        dataLabel: type.name,
        totalCount: type.count,
      })),
    [centersByNumberOfInventions],
  );
  const centersByNumbersOfProjects = data?.centers_by_numbers_of_projects || [];
  const dataLinecentersByNumbersOfProjects = React.useMemo(
    () =>
      centersByNumbersOfProjects.map(type => ({
        dataKey: type.id,
        dataLabel: type.name,
        totalCount: type.count,
      })),
    [centersByNumbersOfProjects],
  );
  return (
    <div>
      <Hero
        title="Centers"
        description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
        nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus
        omnis, molestiae esse placeat, exercitationem magnam quod
              molestias quia aspernatur venenatis diam."
      >
        <ChartPage />
      </Hero>
      {isLoading ? (
        <InsightSkeleton />
      ) : (
        <div className="margin-safe p-6 lg:p-10 lg:pb-10 min-h-full">
          <div className="masonry md:masonry-md xl:masonry-xl">
            <InsightCard size="sm">
              <HeaderCards
                title="Total Numbers of Centers"
                subtitle={data?.centers_size || "--"}
              />
              <SimpleAreaChart
                dataKey="name"
                data={data?.centers_by_year || []}
              />
            </InsightCard>
            <InsightCard>
              <HeaderCards
                title="Centers by Project Type"
                titleClassname="mb-3.75"
              />
              {data && (
                <InsightMultiLevelPieChart
                  dataKey="count"
                  data={data?.projects_by_type}
                  centers={data?.all_centers_by_list}
                  customSearch="project_type_by_center"
                  entity="projects"
                />
              )}
            </InsightCard>
            <InsightCard>
              <HeaderCards
                title="Centers by Clinical Trial Status"
                titleClassname="mb-3.75"
              />
              {data && (
                <InsightMultiLevelPieChart
                  dataKey="count"
                  centers={data?.all_centers_by_list}
                  data={data?.clinical_trial_by_status || []}
                  customSearch="clincal_trial_status_by_center"
                  entity="Clinical Trials"
                />
              )}
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Centers by Member Total" />
              <PieChart
                dataKey="count"
                data={data?.centers_by_member_total || []}
              />
            </InsightCard>
            <InsightCard>
              <HeaderCards
                title="Centers by Project Focus Area"
                titleClassname="mb-3.75"
              />
              {data && (
                <InsightPieChartWithSelectCenter
                  dataKey="count"
                  centers={data?.all_centers_by_list}
                  data={data?.centers_by_project_focus_area || []}
                  customSearch="project_focus_area_by_center"
                  entity="Focus Areas"
                />
              )}
            </InsightCard>
            <InsightCard size="xxxl">
              <HeaderCards
                title="Center by Number of Inventions"
                titleClassname="mb-4"
              />
              <InsightBarChart
                data={data?.centers_by_number_of_inventions}
                dataKey="shorthand"
                showList={true}
                showXAxisLabel={false}
                dataLines={dataLineCenterByNumberOfInventions}
                tooltip={CustomCenterTooltip}
              />
            </InsightCard>
            <InsightCard size="xxxl">
              <HeaderCards
                title="Center by Number of Projects"
                titleClassname="mb-4"
              />
              <InsightBarChart
                data={data?.centers_by_numbers_of_projects}
                dataKey="shorthand"
                showList={true}
                showXAxisLabel={false}
                dataLines={dataLinecentersByNumbersOfProjects}
                tooltip={CustomCenterTooltip}
              />
            </InsightCard>
            <InsightCard>
              <HeaderCards title="Center by Research Item Total" />
              {data && (
                <InsightPieChartWithSelectCenter
                  dataKey="count"
                  centers={data?.all_centers_by_list}
                  data={data?.centers_by_research_item_total || []}
                  customSearch="research_document_type_by_center"
                  entity="Research Documents"
                  showLabelPorcentajae={true}
                  innerRadius={0}
                  showTotal={true}
                />
              )}
            </InsightCard>
          </div>
        </div>
      )}
    </div>
  );
}

export default Centers;
