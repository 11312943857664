import React from "react";
import {
  BarChart,
  Bar,
  Tooltip,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip from "@/components/shared/ChartsTooltip";
import COLORS from "./Colors";
import useWindowDimensions from "@/hooks/useWindowDimensions";

function ThumbnailBarChart({ data, openModal, title }) {
  const { width } = useWindowDimensions();
  return (
    <ResponsiveContainer width={width > 640 ? 232 : "100%"} height={232}>
      <BarChart
        onClick={width > 640 ? openModal : null}
        layout="vertical"
        data={data}
        margin={{
          top: 48,
          right: 32,
          bottom: 16,
          left: 32,
        }}
        className="bg-tint"
        cursor="pointer"
      >
        <text
          x={width > 640 ? "17" : "50%"}
          y="28"
          textAnchor={width > 640 ? "start" : "middle"}
          className="font-h3 sm:font-meta-bold"
        >
          {title}
        </text>
        <XAxis type="number" hide />
        <YAxis type="category" dataKey="name" hide />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        {data && (
          <Bar dataKey="count" barSize={16} background={{ fill: "#eaecf0" }}>
            {data.map((el, index) => (
              <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ThumbnailBarChart;
