import React from "react";
import Table from "@/components/shared/Table";

function InitiativesSkeletonTable() {
  return (
    <Table
      className="border border-transparent text-sm table-fixed mb-8 animate-pulse"
      data-testid="initiatives-table-skeleton"
    >
      <Table.Header className="bg-transparent text-dark-50">
        <Table.HeaderCell className="text-11 pb-2 !px-3 w-[30px] text-center">
          ID
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3 w-[25.5%]">
          Title
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Date added
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Leads
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Centers
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3">
          Members
        </Table.HeaderCell>
        <Table.HeaderCell className="text-11 pb-2 !px-3 w-[100px]">
          Actions
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body className="border border-lines">
        {[1, 2, 3, 4].map(x => (
          <Table.Row key={x} className="divide-x divide-lines">
            <Table.Cell className="!px-3 w-[30px] text-center">
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell>
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
            <Table.Cell className="w-[100px]">
              <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default InitiativesSkeletonTable;
