import * as React from "react";

function ProjectsInfo({ user }) {
  return (
    <div className="flex flex-col divide-y px-8">
      {user && user.projectsMembership.length > 0 ? (
        user.projectsMembership.map((projectMembership, index) => (
          <div key={index} className="py-3.75 space-y-1">
            <p className="font-meta-bold text-dark-100">
              {projectMembership.name}
            </p>
            <span className="font-meta text-dark-50">{`Joined ${projectMembership.timeAgo}`}</span>
          </div>
        ))
      ) : (
        <article className="bg-tint rounded-lg flex flex-col flex-grow justify-center items-center text-center h-125">
          <p className="font-body text-dark-75">
            {`${user.fullName} hasn't joined any projects yet`}
          </p>
        </article>
      )}
    </div>
  );
}

export default ProjectsInfo;
