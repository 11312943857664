import React from "react";

function Loading() {
  return (
    <div className="block relative m-auto w-20 h-20 pt-10">
      <div className="absolute w-3 h-3 bg-primary animate-ping rounded-full"></div>
      <div className="absolute w-3 h-3 bg-primary animate-ping rounded-full left-8"></div>
      <div className="absolute w-3 h-3 bg-primary animate-ping rounded-full left-16"></div>
    </div>
  );
}

export default Loading;
