import { createMachine } from "xstate";

const stepsMachine = createMachine({
  id: "formSteps",
  initial: "step1",
  states: {
    step1: {
      on: {
        NEXT: "step2",
      },
    },
    step2: {
      on: {
        NEXT: "step3",
        PREV: "step1",
      },
    },
    step3: {
      on: {
        NEXT: "formCompleted",
        PREV: "step2",
      },
    },
    formCompleted: {},
  },
});

export default stepsMachine;
