import React, { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import paramBuilder from "@/utils/network/paramBuilder";
import dateGroupFormat from "@/components/shared/Retreat/RetreatHero/dateFormat";
import Select from "@/components/shared/Select";
import { makeDataToOptions } from "@/components/pages/admin/RetreatsPage/RetreatsForm/defaults";
import ArticleCard from "@/components/shared/Retreat/ArticleCard";
import RetreatSkeletonPage from "./RetreatSkeletonPage";
import ImageGallery from "@/components/shared/Retreat/ImageGallery";
import clsx from "clsx";
import NoResults from "@/components/shared/NoResults";

const MAX_RETREAT_IMAGES = 8;
const DEFAULT_ARTICLES_TO_SHOW = 9;

const fetchArticles = ({ id }) => {
  const baseUrl = "/api/articles";
  const params = paramBuilder({
    fields: {
      articles: ["title", "retreatId", "thumbnailImageUrl", "description"],
    },
  });
  const url = `${baseUrl}?${params}&filter[retreat_id]=${id}`;
  return denormalizedFetcher(url);
};

const fetchRetreat = () => {
  const baseUrl = "/api/retreats";
  const params = paramBuilder({
    fields: {
      retreats: [
        "galleryImages",
        "title",
        "startDate",
        "endDate",
        "location",
        "quarter",
        "published",
      ],
    },
  });
  const url = `${baseUrl}?${params}&filter[published]=true`;
  return denormalizedFetcher(url);
};

function Header({ onRetreatChange, options, id, retreat }) {
  const selected = options.find(option => option.id === id);
  const { title, startDate, endDate, location } = retreat?.attributes;
  return (
    <header className="flex flex-col items-start md:items-end gap-4 md:flex-row justify-between pb-4 mb-10">
      <h1 className="font-h2 md:font-h1 text-dark-100 font-bold">
        {title}
        <p className="font-body display-block w-full mt-2 text-dark-75">
          {dateGroupFormat(startDate, endDate)} | {location}
        </p>
      </h1>
      <div className="w-full md:w-auto">
        <Select
          selected={selected}
          setSelected={onRetreatChange}
          options={options}
          placeholder={selected?.name}
        />
      </div>
    </header>
  );
}

function RetreatPage() {
  const [displayAllImageRetreat, setDisplayAllImageRetreat] = useState(false);
  const [displayAllArticles, setdisplayAllArticles] = useState(false);
  const navigate = useNavigate();
  const { id: retreatId } = useParams();
  const { isLoading: isRetreatsLoading, data: retreats } = useQuery(
    ["retreats"],
    () => fetchRetreat(),
  );

  const {
    data,
    isLoading: isArticlesLoading,
  }: { data: any; isLoading: boolean } = useQuery({
    queryKey: [`Retreats-${retreatId}`],
    queryFn: () => fetchArticles({ id: retreatId }),
  });

  const quartersOptions = useMemo(
    () =>
      isEmpty(retreats?.data)
        ? []
        : makeDataToOptions(
            retreats?.data.filter(
              retreat => retreat.attributes.published === true,
            ),
            "quarter",
          ),
    [retreats],
  );

  const handleRetreatSelection = quarter => navigate(`/retreats/${quarter}`);

  const currentRetreat = isEmpty(retreats?.data)
    ? null
    : retreats?.data.find(retreat => retreat.id === retreatId);
  const galleryImages = isEmpty(currentRetreat)
    ? []
    : currentRetreat.attributes.galleryImages;
  const noRetreatAvailable = !isRetreatsLoading && !currentRetreat;
  const retreatArticles = useMemo(() => {
    if (data) {
      const parseArticles = data.data.map(article => {
        const { attributes } = article;
        return {
          id: article.id,
          title: attributes.title,
          retreatId: attributes.retreatId,
          thumbnailImageUrl: attributes.thumbnailImageUrl,
          description: attributes.description,
          galleryImages: attributes.galleryImages,
        };
      });
      return parseArticles;
    } else {
      return [];
    }
  }, [data]);

  const handleViewMoreImagesRetreat = () => {
    setDisplayAllImageRetreat(!displayAllImageRetreat);
  };

  if (noRetreatAvailable) {
    return (
      <NoResults
        entityName="Retreat"
        icon="ri-search-line"
        className="!bg-white !my-auto !mt-32"
      />
    );
  }

  const articles = displayAllArticles
    ? retreatArticles
    : retreatArticles.slice(0, DEFAULT_ARTICLES_TO_SHOW);

  return (
    <>
      {isArticlesLoading || isRetreatsLoading ? (
        <RetreatSkeletonPage />
      ) : (
        <div className="margin-safe py-6 lg:py-10 font-lato text-dark-100 min-h-full mb-8">
          <Header
            id={retreatId}
            onRetreatChange={handleRetreatSelection}
            options={quartersOptions}
            retreat={currentRetreat}
          />
          <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px] mb-12">
            {articles?.map(article => (
              <ArticleCard key={article.id} article={article} />
            ))}
          </section>
          {retreatArticles.length > DEFAULT_ARTICLES_TO_SHOW && (
            <div className="text-center w-full">
              <button
                onClick={() => {
                  setdisplayAllArticles(!displayAllArticles);
                }}
                className="btn my-8 btn-white px-5 py-2 w-full max-w-[380px] mt-12 mb-20 font-meta-bold rounded-md"
              >
                {displayAllArticles ? "View less" : "View more"}
              </button>
            </div>
          )}

          {!isEmpty(galleryImages) && (
            <>
              <h1 className="font-h2 md:font-h1 text-dark-100 font-bold mb-10 mt-4 text-center w-full">
                Images from the Retreat
              </h1>

              <ImageGallery
                values={{
                  galleryImages: displayAllImageRetreat
                    ? galleryImages
                    : galleryImages.slice(0, MAX_RETREAT_IMAGES),
                }}
                onChange={() => {}}
              />
            </>
          )}

          {galleryImages.length > MAX_RETREAT_IMAGES && (
            <div className="text-center w-full">
              <button
                className={clsx(
                  "btn my-8 btn-white px-5 py-2 mt-12 mb-20",
                  "max-w-[380px] w-full font-meta-bold rounded-md",
                )}
                onClick={handleViewMoreImagesRetreat}
              >
                {displayAllImageRetreat ? "View less" : "View more"}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default RetreatPage;
