import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import Table from "@/components/shared/Table";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import NoResults from "@/components/shared/NoResults";
import projectPhotoPlaceholder from "@/images/project-photo-placeholder.png";
import { useSearchParams } from "react-router-dom";

function ProjectSkeleton({ showLastUpdate, widthTitle }) {
  return (
    <Table.Row className="animate-pulse">
      <Table.Cell expand={false}>
        <div className="flex gap-5 w-full">
          <div className="hidden lg:flex flex-col gap-2">
            <div
              className="bg-dark-25 mr-2"
              style={{ width: 200, height: 114 }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div
              className="bg-dark-25 h-4 rounded-full"
              style={{ minWidth: widthTitle }}
            />
            <div className="bg-dark-25 h-4 w-3/4 rounded-full" />
            <div className="bg-dark-25 h-4 w-20 rounded-full" />
          </div>
        </div>
      </Table.Cell>
      <Table.Cell expand={false} style={{ minWidth: 144 }}>
        <div className="bg-dark-25 h-4 w-20 rounded-full" />
      </Table.Cell>
      {showLastUpdate && (
        <Table.Cell>
          <div className="bg-dark-25 h-4 w-20 rounded-full ml-auto" />
        </Table.Cell>
      )}
    </Table.Row>
  );
}

function ProjectRow({
  project,
  showLastUpdate,
  widthTitle,
  isSortingByCreation = false,
}) {
  const { identifier, name, codename, updatedAt } = project.attributes;
  const focusAreas =
    project.relationships?.focusAreas?.data ||
    project.attributes.relatedFocusAreas;
  const bannerImage = project.links.bannerImage;
  const projectLink = `${project.links.slug}?tab=overview`;

  return (
    <Table.Row>
      <Table.Cell expand={false} className="pr-2">
        <div className="flex gap-5 w-full">
          <div className="hidden lg:block flex-shrink-0 relative inset-0 rounded overflow-hidden w-50 h-114px">
            <img
              src={bannerImage || projectPhotoPlaceholder}
              className={clsx(
                bannerImage ? "object-cover w-full" : "object-fit h-full",
              )}
            />
            <div
              className={clsx(
                "absolute inset-0",
                bannerImage && "linear-gradient",
              )}
              aria-hidden="true"
            />
          </div>
          <div
            className="flex flex-col justify-center max-w-lg"
            style={{ minWidth: widthTitle }}
          >
            <Link to={projectLink} className="mb-2.5 link-primary">
              <h4 className="font-h3">{name}</h4>
            </Link>
            <FocusAreaTags focusAreas={focusAreas} />
          </div>
        </div>
      </Table.Cell>
      <Table.Cell expand={false}>
        <p className="w-44 truncate">
          {identifier}
          {identifier && codename ? ": " : ""}
          {codename}
        </p>
      </Table.Cell>
      {showLastUpdate && (
        <Table.Cell expand={false}>
          <span className="block text-right whitespace-nowrap">
            {format(
              parseISO(
                isSortingByCreation ? project.attributes.createdAt : updatedAt,
              ),
              "MMM dd, yyyy",
            )}
          </span>
        </Table.Cell>
      )}
    </Table.Row>
  );
}

function ProjectsTable({
  projects,
  isLoading,
  widthTitle,
  showLastUpdate = false,
}: {
  projects: any[];
  isLoading: boolean;
  widthTitle: number;
  showLastUpdate?: boolean;
}) {
  const [searchParams] = useSearchParams();
  const isSortingByCreation = ["createdAt", "-createdAt"].includes(
    searchParams.get("sort"),
  );
  return (
    <>
      {!isLoading && projects.length === 0 ? (
        <NoResults entityName="projects" icon="ri-search-line" />
      ) : (
        <Table>
          <Table.Header>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell className="whitespace-nowrap">
              Project ID
            </Table.HeaderCell>
            {showLastUpdate && (
              <Table.HeaderCell className="text-right">
                {isSortingByCreation ? "Date Created" : "Last Update"}
              </Table.HeaderCell>
            )}
          </Table.Header>
          <Table.Body>
            {projects?.map(project => (
              <ProjectRow
                key={project.id}
                project={project}
                widthTitle={widthTitle}
                showLastUpdate={showLastUpdate}
                isSortingByCreation={isSortingByCreation}
              />
            ))}
            {isLoading &&
              [1, 2, 3, 4, 5].map((_x, index) => (
                <ProjectSkeleton
                  key={index}
                  showLastUpdate={showLastUpdate}
                  widthTitle={widthTitle}
                />
              ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default ProjectsTable;
