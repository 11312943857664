import * as React from "react";
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import useCurrentUser from "@/hooks/useCurrentUser";
import { Form, Formik } from "formik-latest";
import { useQueryClient } from "@tanstack/react-query";
import { saveResource } from "@/utils/api";
import SlideOver from "@/components/shared/SlideOver/SlideOver";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";
import useFlashMessage from "@/hooks/useFlashMessage";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";
import DatePicker from "@/components/shared/DatePicker";
import Checkbox from "@/components/shared/Checkbox";
import { format } from "date-fns";

const AnnouncementSchema = Yup.object().shape({
  subject: Yup.string().required("Subject is required."),
  content: Yup.string().required("Content is required."),
});

const dateIsBeforeCurrentDate = date => {
  if (date === null) return true;
  const today = new Date();
  const dateToCompare = new Date(date);
  today.setHours(12, 0, 0);
  return dateToCompare.getTime() < today.getTime();
};

const formatDate = date => {
  return format(new Date(date), "MM/dd/yyyy");
};

const mapRequest = (item, id = null) => {
  let date = null;
  let isoDate = null;
  if (item.sendAnnouncementDate) {
    date = new Date(item.sendAnnouncementDate);
    isoDate = date.toISOString();
  }

  const announcementDate = date ? isoDate.substring(0, 10) : null;

  const request = {
    data: {
      type: "announcements",
      attributes: { ...item, sendAnnouncementDate: announcementDate },
      ...(id && { id: id }),
    },
  };
  return request;
};

const mapCurrentItems = (current, userId) => {
  return {
    userId: userId,
    subject: current?.subject || "",
    content: current?.content || "",
    image: current?.image || null,
    imageMetadata: current?.imageMetadata || null,
    imageUrl: current?.imageUrl || null,
    displaySubjectInContent: current ? current.displaySubjectInContent : true,
    sendAnnouncementDate: current?.sendAnnouncementDate
      ? formatDate(current?.sendAnnouncementDate)
      : null,
  };
};

function AnnouncementForm({ open, setOpen, announcement = null }) {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isEdit = !!announcement;

  const showFlash = useFlashMessage({
    message: `Your announcement was ${isEdit ? "updated" : "added"}!`,
    errorMessage: `Your announcement could not be ${
      isEdit ? "updated" : "added"
    } due to an error.`,
  });
  const showDeleteFlash = useFlashMessage({
    message: "The announcement has been deleted.",
    errorMessage: `Your announcement could not be deleted due to an error.`,
  });

  const handleSubmit = (item: any, { setSubmitting }: any) => {
    const request = mapRequest(item, announcement?.id);
    delete request.data.attributes["imageMetadata"];
    delete request.data.attributes["imageUrl"];
    saveResource("announcements", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        setOpen(false);
        queryClient.invalidateQueries(["announcements"]);
      });
  };

  const handleDelete = () => {
    fetch(announcement.links.self, { method: "DELETE" }).then(res => {
      showDeleteFlash({ success: res.ok });
      if (res.ok) {
        queryClient.invalidateQueries(["announcements"]);
      }
      setShowDeleteModal(false);
      setOpen(false);
    });
  };

  const disabledAnnouncementDate = !!announcement
    ? dateIsBeforeCurrentDate(announcement?.attributes.sendAnnouncementDate)
    : false;

  return (
    <SlideOver {...{ open, setOpen }}>
      <Formik
        initialValues={mapCurrentItems(
          announcement?.attributes,
          currentUser?.id,
        )}
        validationSchema={AnnouncementSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty, isValid, ...props }) => {
          return (
            <Form>
              <SlideOver.Header>
                <span className="text-dark-100 font-medium">
                  {announcement
                    ? `Edit ${announcement?.attributes.subject}`
                    : "New Announcement"}
                </span>
              </SlideOver.Header>

              <SlideOver.Body>
                <div className="space-y-6">
                  <div>
                    <FormBlock
                      label="Subject"
                      name="subject"
                      labelClassName="font-body-bold text-dark-75"
                    >
                      <Input
                        value={values.subject}
                        data-cy="item-subject"
                        styleAs="small"
                      />
                    </FormBlock>
                  </div>
                  <Checkbox
                    checked={values.displaySubjectInContent}
                    onChange={() =>
                      setFieldValue(
                        "displaySubjectInContent",
                        !values.displaySubjectInContent,
                      )
                    }
                    className="mt-2"
                  >
                    Display Subject and Date in Content
                  </Checkbox>
                  <div>
                    <FormBlock
                      label="Content"
                      name="content"
                      labelClassName="font-body-bold text-dark-75"
                    >
                      <RichTextEditor
                        value={values.content}
                        onChange={value => setFieldValue("content", value)}
                        placeholder="Type here..."
                      />
                    </FormBlock>
                  </div>
                  <div>
                    <label className="flex font-h3  font-body-bold mb-1 text-dark-75">
                      Attachment
                    </label>
                    <div className="mt-1">
                      <FilePickerAdmin
                        setSignedId={val => setFieldValue("image", val)}
                        attachment={{
                          signedId: values.image,
                          metadata: values.imageMetadata,
                          imageUrl: values.imageUrl,
                        }}
                      />
                      {props.touched.image && props.errors.image && (
                        <p className="mt-1 text-sm text-red-400">
                          {props.errors.image}
                        </p>
                      )}
                    </div>
                  </div>
                  <FormBlock
                    label="Date to send the announcement"
                    name="sendAnnouncementDate"
                    labelClassName="font-body-bold text-dark-75"
                    optional
                  >
                    <DatePicker
                      styleAs="small"
                      dateFormat="MM/dd/yyyy"
                      id="sendAnnouncementDate"
                      placeholder="MM/DD/YYYY"
                      onChange={date =>
                        setFieldValue(
                          "sendAnnouncementDate",
                          formatDate(new Date(date)),
                        )
                      }
                      disabled={disabledAnnouncementDate}
                    />
                  </FormBlock>
                </div>
                <DeleteConfirmationModal
                  open={showDeleteModal}
                  setOpen={setShowDeleteModal}
                  onAccept={handleDelete}
                  resource="Announcement"
                />
              </SlideOver.Body>
              <SlideOver.Footer className="flex justify-start">
                <button
                  type="submit"
                  data-cy="submit"
                  disabled={props.isSubmitting || !dirty || !isValid}
                  className={clsx(
                    "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-3px text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                    props.isSubmitting || !dirty || !isValid
                      ? "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none"
                      : "bg-primary hover:bg-primary-dark",
                  )}
                >
                  {isEdit ? "Save changes" : "Create Announcement"}
                </button>
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-3px shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <div className="flex-grow" />
                {isEdit && (
                  <button
                    type="button"
                    className={clsx(
                      "border border-lines rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
                      "bg-tint text-dark-75 hover:bg-white py-9px px-7.5 font-lato active:bg-lines",
                    )}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Announcement
                  </button>
                )}
              </SlideOver.Footer>
            </Form>
          );
        }}
      </Formik>
    </SlideOver>
  );
}

export default AnnouncementForm;
