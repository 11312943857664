import React from "react";
import PropTypes from "prop-types";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";

const VanityText = ({ text, name, label, optional, placeholder }) => (
  <fieldset className="field w-full">
    <div className="flex mt-2 w-full">
      <FormBlock
        label={label}
        name={name}
        className="w-full"
        classNameChildren="flex"
        leftChildren={
          <label
            className="flex-auto w-1/2 text-dark-75 bg-tint-50 border-dark-25 border border-r-0 rounded-l px-4 py-2"
            htmlFor={name}
          >
            {text}
          </label>
        }
        optional={optional}
      >
        <Input
          wrapperClassName="w-1/2"
          className="rounded-l-none flex-auto"
          data-cy={`item-${name}`}
          type="text"
          id={name}
          placeholder={placeholder}
        />
      </FormBlock>
    </div>
  </fieldset>
);

VanityText.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default VanityText;
