import * as React from "react";
import Modal from "@/components/shared/Modal";
import clsx from "clsx";

function ConfirmationModal({
  title = "You have unsaved changes",
  description = "Are you sure you want to leave this page? You’ll lose all of your unsaved changes.",
  isOpen,
  acceptLabel = "Leave This Page",
  disabledAcceptButton = false,
  hideAcceptButton = false,
  typeAcceptButton = "button",
  closeLabel = "Nevermind",
  className = "",
  formIdAcceptButton = undefined,
  onAccept,
  onClose,
  setOpen,
}: {
  title?: string;
  description?: React.ReactNode;
  isOpen: boolean;
  acceptLabel?: string;
  disabledAcceptButton?: boolean;
  typeAcceptButton?: "button" | "submit";
  formIdAcceptButton?: string;
  hideAcceptButton?: boolean;
  closeLabel?: string;
  className?: string;
  onAccept?: () => void;
  onClose?: () => void;
  setOpen: () => void;
}) {
  return (
    <Modal className={clsx("w-105", className)} open={isOpen} setOpen={setOpen}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body className="text-dark-75 font-body-large">
        {description}
      </Modal.Body>
      <Modal.Footer className="flex pt-6 space-x-2.5">
        {!hideAcceptButton && (
          <button
            onClick={onAccept}
            className={clsx("btn-primary btn-sm font-meta-bold", {
              "opacity-30 cursor-auto": disabledAcceptButton,
            })}
            type={typeAcceptButton}
            form={formIdAcceptButton}
            disabled={disabledAcceptButton}
          >
            {acceptLabel}
          </button>
        )}
        <button
          type="button"
          onClick={onClose}
          className="btn-white btn-sm font-meta-bold"
        >
          {closeLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
