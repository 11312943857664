import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Form, Formik } from "formik-latest";
import FullWideForm from "@/components/shared/FullWideForm";
import ArticlesAside from "./ArticleAside";
import ArticlesSlideshow from "./ArticlesSlideshow";
import { format } from "date-fns";
import FormBlock from "@/components/shared/FormBlock";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import { isEqual } from "lodash";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import {
  articleSchema,
  mapRequest,
  mapResponse,
  parseRetreatsFilter,
} from "./defaults";
import { SectionMode } from "@/components/shared/Retreat/Article/defaults";
import useFlashMessage from "@/hooks/useFlashMessage";
import paramBuilder from "@/utils/network/paramBuilder";
import { useQuery } from "@tanstack/react-query";
import SearchBar from "@/components/shared/SearchBar";
import ArticleTitle from "@/components/shared/Retreat/Article/ArticleTitle";
import ArticleSections from "./ArticleSections";
import ToogleButton from "@/components/shared/ToogleButton";
import SkeletonForm from "./SkeletonForm";

const fetchRetreats = () => {
  const params = paramBuilder({
    fields: {
      retreats: ["title", "quarter", "coverImageUrl"],
    },
    exclude_links: true,
  });
  const url = `/api/retreats?${params}`;
  return denormalizedFetcher(url);
};

const fetchArticle = async id => {
  const baseUrl = `/api/articles/${id}`;
  const params = paramBuilder({
    include: ["articleSpeakers", "articleSections"],
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function ArticlesForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initiativeData, setInitiativeData] = useState<any>({});
  const { slug, id: articleId } = useParams();
  const [retreatTitle, setRetreatTitle] = useState<string>("");

  const showFlash = useFlashMessage({
    message: `Your Article was ${articleId ? "updated" : "added"}!`,
    errorMessage: `Your Article could not be ${
      slug ? "updated" : "added"
    } due to an error.`,
  });

  const { data: retreats }: { data: any; isLoading: boolean } = useQuery(
    ["retreats_filter"],
    fetchRetreats,
  );

  const retreatOptions = useMemo(() => parseRetreatsFilter(retreats), [
    retreats,
  ]);

  const title = articleId ? "Edit Article" : "New Article";
  const [openUnsaveModal, setOpenUnsaveModal] = useState<boolean>(false);
  const handleBackButtonPressed = () => {
    if (slug) {
      navigate(`/admin/retreats/${slug}/edit`);
    } else {
      navigate(`/admin/articles`);
    }
  };

  useEffect(() => {
    if (articleId) {
      fetchArticle(articleId).then((res: any) => {
        const articleData = mapResponse(res);
        setInitiativeData(articleData);
        setIsLoading(false);
      });
    } else {
      setInitiativeData({
        presentationDate: format(new Date(), "MM/dd/yyyy"),
        slideshowImages: [null],
        slideshowImagesUrl: [null],
        sectionImages: [],
        articleSpeakers: [],
        articleSections: [],
        resourceLinks: [],
        retreatId: slug,
      });
      setIsLoading(false);
    }
  }, [articleId, slug]);

  const onLeaveForm = hasNoChanges =>
    hasNoChanges ? handleBackButtonPressed() : setOpenUnsaveModal(true);

  const handleSubmit = (values, { setSubmitting }) => {
    const articleRequest = mapRequest(values);
    saveResource("articles", articleRequest)
      .then(res => showFlash({ success: res.ok }))
      .catch(_ => {
        showFlash({ success: false });
        setSubmitting(false);
      })
      .finally(() => {
        handleBackButtonPressed();
      });
  };

  const backTextHeader = slug ? "Retreat" : "All Articles";

  return (
    <>
      {isLoading ? (
        <SkeletonForm />
      ) : (
        <Formik
          initialValues={initiativeData}
          onSubmit={handleSubmit}
          validationSchema={articleSchema}
        >
          {({ values, isValid, isSubmitting, setFieldValue }) => {
            const hasNoChanges = isEqual(values, initiativeData);
            const submitDisabled = !isValid || isSubmitting || hasNoChanges;
            const onRetreatSelect = retreat => {
              setRetreatTitle(retreat.title);
              setFieldValue("retreatId", retreat.id);
            };

            const handleTooglePublish = () => {
              setFieldValue("published", !values.published);
            };

            return (
              <>
                <FullWideForm>
                  <FullWideForm.header>
                    <button
                      type="button"
                      className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
                      onClick={handleBackButtonPressed}
                    >
                      <i className="ri-arrow-left-s-line ri-lg text-dark-75 mr-1"></i>
                      <span className="text-14 font-lato">
                        {backTextHeader}
                      </span>
                    </button>
                    <div className="admin-intro ellipsis mx-auto w-full flex max-w-7xl py-4">
                      <h1 className="text-22 font-bold flex-1">{title}</h1>
                      {slug && (
                        <div className="flex">
                          <label className="flex font-h3 mr-2 text-dark-100 font-body-bold">
                            Publish
                          </label>
                          <ToogleButton
                            active={values.published}
                            onClick={handleTooglePublish}
                          />
                        </div>
                      )}
                    </div>
                  </FullWideForm.header>
                  <Form>
                    <FullWideForm.body className="!max-w-7xl p-7">
                      <section className="flex w-full gap-7.5">
                        <div className="flex flex-col w-full max-w-[872px]">
                          <ArticlesSlideshow
                            values={values}
                            onChange={setFieldValue}
                            className="mb-6"
                          />
                          {!slug && (
                            <FormBlock
                              label="Retreat"
                              className="mb-6"
                              name="retreat_id"
                            >
                              <SearchBar
                                className="bg-white"
                                options={retreatOptions || []}
                                placeholder="Type Retreat's title"
                                accessor={item => item.title}
                                onTextChange={text => setRetreatTitle(text)}
                                matchCriteria={(input, itemSearchable) =>
                                  itemSearchable
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                onSelect={retreat => onRetreatSelect(retreat)}
                                selected={retreatTitle}
                              >
                                {({ item }) => (
                                  <div className="flex items-center p-3 cursor-pointer">
                                    <div className="flex">
                                      <img
                                        src={item.coverImageUrl}
                                        className="h-10 w-10 rounded-full"
                                      />
                                      <div className="ml-3">
                                        <p className="font-meta-bold text-dark-100">
                                          {item.title}
                                        </p>
                                        <p
                                          className="font-micro text-dark-75 line-clamp-1"
                                          title={item.quarter}
                                        >
                                          {item.quarter}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </SearchBar>
                            </FormBlock>
                          )}
                          <ArticleTitle
                            values={values}
                            viewMode={SectionMode.Edit}
                          />
                          <ArticleSections
                            values={values}
                            onChange={setFieldValue}
                          />
                        </div>
                        <ArticlesAside
                          values={values}
                          onChange={setFieldValue}
                        />
                      </section>
                    </FullWideForm.body>
                    <FullWideForm.footer>
                      <div className="mx-auto w-full max-w-[964px]">
                        <div className="ml-72">
                          <button
                            type="submit"
                            className={clsx(
                              "btn-white btn-md cursor-pointer mr-2",
                              {
                                "bg-primary text-white hover:border-primary-darker hover:bg-primary-darker active:bg-primary-darkest": isValid,
                                "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none": submitDisabled,
                              },
                            )}
                            disabled={submitDisabled}
                          >
                            {slug ? "Save Changes" : "Add Article"}
                          </button>
                          <button
                            type="button"
                            className="btn-md bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => onLeaveForm(hasNoChanges)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </FullWideForm.footer>
                  </Form>
                </FullWideForm>
              </>
            );
          }}
        </Formik>
      )}
      <ConfirmationModal
        isOpen={openUnsaveModal}
        onAccept={() => handleBackButtonPressed()}
        onClose={() => setOpenUnsaveModal(false)}
        setOpen={() => setOpenUnsaveModal(false)}
      />
    </>
  );
}

export default ArticlesForm;
