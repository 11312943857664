import * as React from "react";
import { useState } from "react";
import clsx from "clsx";
import SearchBar from "@/components/shared/SearchBar";
import { useFormikContext } from "formik-latest";
import { useProjectOptions } from "../ResearchDocumentForm/data";
import projectPhotoPlaceholder from "@/images/project-photo-placeholder.png";
import { XIcon } from "@heroicons/react/outline";

function RelatedProjectPicker() {
  const {
    values,
    setFieldValue,
  }: { values: any; setFieldValue: any } = useFormikContext();
  const relatedProjects = values.relatedProjects;
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const projects = useProjectOptions(projectNameFilter);
  const projectOptionsFiltered = values => {
    return projects?.filter(project => {
      const projectNames = values.map(({ content }) => content);
      return !projectNames.includes(project.content);
    });
  };

  return (
    <div className="mb-7.5">
      <div>
        <SearchBar
          options={projectOptionsFiltered(relatedProjects)}
          placeholder="Search by project name"
          accessor={item => item.content}
          matchCriteria={(input, itemSearchable) =>
            itemSearchable
              .toLocaleLowerCase()
              .includes(input.toLocaleLowerCase())
          }
          onSelect={project => {
            setFieldValue("relatedProjects", [...relatedProjects, project]);
          }}
          onTextChange={item => setProjectNameFilter(item)}
        >
          {({ item }) => {
            return (
              <div className="px-6 py-4 cursor-pointer flex sm:space-x-5">
                <div className="relative hidden sm:block w-22 h-12 rounded bg-dark-100 overflow-hidden">
                  <img
                    src={item.banner || projectPhotoPlaceholder}
                    className={clsx(
                      item.banner ? "object-cover w-full" : "object-fit h-full",
                      "min-h-full min-w-full",
                    )}
                  />
                  <div
                    className={clsx(
                      "absolute inset-0",
                      item.banner && "linear-gradient",
                    )}
                    aria-hidden="true"
                  />
                </div>
                <span className="text-dark-100 text-15 font-bold max-w-md">
                  {item.content}
                </span>
              </div>
            );
          }}
        </SearchBar>
      </div>
      {relatedProjects.length > 0 && (
        <div className="flex flex-wrap pt-5">
          {relatedProjects.map(project => (
            <div
              key={project.content}
              className={clsx(
                "flex items-center mr-4 py-0.25 pl-2.5 pr-2 rounded-xl font-label uppercase mb-2.5 border border-transparent bg-lines text-dark-75",
              )}
            >
              <span>{project.content}</span>
              <button
                onClick={() =>
                  setFieldValue(
                    "relatedProjects",
                    relatedProjects.filter(
                      relatedProject => relatedProject.id != project.id,
                    ),
                  )
                }
                className="pl-0.5 cursor-pointer bg-transparent border-0"
                data-testid={`tag-${project.content}`}
                type="button"
              >
                <XIcon className="fill-current text-dark-50 w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default RelatedProjectPicker;
