import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik-latest";
import * as Yup from "yup";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import useCurrentUser from "@/hooks/useCurrentUser";
import useFlashMessage from "@/hooks/useFlashMessage";
import Input from "@/components/shared/Input";
import TextArea from "@/components/shared/TextArea";
import FormBlock from "@/components/shared/FormBlock";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import FilePicker from "@/components/shared/FilePicker";

const ProfessionalInfoSchema = Yup.object().shape({
  googleScholarUrl: Yup.string()
    .trim()
    .matches(
      /^https?:\/\/scholar\.google\.com\/citations\?(\w+=[\w\-]+&)*user=[\w\-]+(&\w+=[\w\-]+)*$/,
      "Enter a valid Google Scholar profile URL.",
    ),
});

const fetchUser = ({ queryKey }) => {
  const [key, slug] = queryKey;

  const params = paramBuilder({
    include: ["focusAreas"],
    fields: {
      users: [
        "bio",
        "title",
        "focusAreas",
        "googleScholarUrl",
        "cvOrBiosketch",
        "cvOrBiosketchMetadata",
      ],
      focusAreas: ["name"],
    },
  });

  const url = `/api/${key}/${slug}?${params}`;
  return denormalizedFetcher(url);
};

const fetchFocusAreas = () => {
  const params = paramBuilder({
    fields: {
      focusAreas: ["name"],
    },
  });
  const url = `/api/focus-areas?${params}`;
  return denormalizedFetcher(url);
};

const mapRequest = (values, id = null) => {
  return {
    data: {
      id: id,
      type: "users",
      attributes: {
        bio: values.bio,
        title: values.title,
        googleScholarUrl: values.googleScholarUrl,
        cvOrBiosketch: values.cvOrBiosketch ? values.cvOrBiosketch : null,
        focusAreas: values.focusAreas,
      },
    },
  };
};

const mapInitialValues = user => {
  return {
    bio: user.attributes.bio || "",
    title: user.attributes.title || "",
    focusAreas: user.relationships.focusAreas.data || [],
    googleScholarUrl: user.attributes.googleScholarUrl || "",
    cvOrBiosketch: user.attributes.cvOrBiosketch || "",
  };
};

function ProfessionalInfoTab() {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  const { data }: { data: any } = useQuery(
    ["users", currentUser?.id],
    fetchUser,
  );
  const user = data?.data;

  const { data: focusAreas }: { data: any } = useQuery(
    ["focusAreas"],
    fetchFocusAreas,
  );

  const showFlash = useFlashMessage({
    message: "Your changes have been saved!",
    errorMessage: "Your changes could not be saved due to an error.",
  });

  const updateInfo = (item, { setSubmitting }) => {
    const request = mapRequest(item, currentUser?.id);
    saveResource("users", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        queryClient.invalidateQueries(["users", currentUser?.id]);
      });
  };

  return (
    <div>
      <h2 className="hidden md:block text-dark-100 font-h2 pb-5 border-b border-lines">
        Professional Info
      </h2>
      <div className="mt-0 md:mt-7.5 text-dark-75 font-body">
        {user && (
          <Formik
            initialValues={mapInitialValues(user)}
            onSubmit={updateInfo}
            validationSchema={ProfessionalInfoSchema}
            enableReinitialize
          >
            {({ values, setFieldValue, ...props }) => (
              <Form>
                <div className="space-y-5 sm:space-y-7.5">
                  <FormBlock
                    label="Title"
                    name="title"
                    optional
                    labelClassName="font-body-bold"
                  >
                    <Input />
                  </FormBlock>

                  <div>
                    <FormBlock
                      label="Bio"
                      name="bio"
                      optional
                      labelClassName="font-body-bold"
                    >
                      <TextArea rows={4} />
                    </FormBlock>
                    <p className="font-meta text-dark-50 mt-2">
                      Write a few sentences about yourself.
                    </p>
                  </div>

                  <div>
                    {focusAreas?.data && (
                      <FormBlock
                        label="Focus Areas"
                        name="focusAreas"
                        optional
                        labelClassName="font-body-bold"
                      >
                        <CheckboxGroup
                          options={focusAreas.data}
                          selectedOptions={values.focusAreas}
                          setSelectedOptions={areas =>
                            setFieldValue("focusAreas", areas)
                          }
                          accessor={option => option.attributes.name}
                          className="flex flex-col h-auto flex-nowrap w-full sm:flex-wrap sm:h-[550px] rounded"
                          labelClassName="px-0"
                        />
                      </FormBlock>
                    )}
                    {currentUser?.attributes.isStaff && (
                      <Link
                        to="/admin/focus_areas"
                        className="btn-md btn-white mt-2.5"
                      >
                        <i className="ri-add-line text-dark-50 mr-2" />
                        Create New Focus Area
                      </Link>
                    )}
                  </div>

                  <FormBlock
                    label="Google Scholar"
                    name="googleScholarUrl"
                    optional
                    labelClassName="font-body-bold"
                  >
                    <Input />
                  </FormBlock>

                  <FormBlock
                    label="CV or Biosketch"
                    name="cvOrBiosketch"
                    type="file"
                    optional
                    labelClassName="font-body-bold"
                  >
                    <FilePicker
                      attachment={user.attributes.cvOrBiosketchMetadata}
                      allowedFilesTitle="PDF, PNG, JPG, GIF up to 50MB"
                    />
                  </FormBlock>

                  <button
                    type="submit"
                    disabled={
                      !props.dirty || !props.isValid || props.isSubmitting
                    }
                    className={clsx(
                      "btn-lg btn-primary w-full md:w-auto",
                      (!props.dirty || !props.isValid || props.isSubmitting) &&
                        "bg-dark-50 hover:bg-dark-50 cursor-default",
                    )}
                  >
                    Save changes
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default ProfessionalInfoTab;
