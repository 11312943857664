import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const COLORS = [
  "#EC4899",
  "#3B82F6",
  "#829F37",
  "#6366F1",
  "#67E8F9",
  "#494487",
  "#E45C53",
  "#3FB5AE",
  "#FE6A36",
  "#EF4444",
];

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { name, count } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          {name}: <span className="font-meta-bold">{count}</span>
        </p>
      </div>
    );
  }
  return null;
};

function InsightPieChart({ data, dataKey, innerRadius = 80 }) {
  return (
    <PieChart
      width={350}
      height={350}
      margin={{ top: -30, left: 0, right: 0, bottom: 0 }}
    >
      <Pie
        data={data}
        dataKey={dataKey}
        innerRadius={innerRadius}
        fill="#8884d8"
        paddingAngle={1}
      >
        {data.map((el, index) => (
          <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  );
}

export default InsightPieChart;
