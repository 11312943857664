import { fetcher, saveResource } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

const AUTHOR_TYPES = [
  { id: "lead_author", name: "Lead author" },
  { id: "senior_author", name: "Senior author" },
  { id: "author", name: "Author" },
  { id: "coauthor", name: "Coauthor" },
  { id: "contributor", name: "Contributor" },
];

const parsedProjects = projects => {
  return projects.data.map(project => ({
    id: project.id,
    content: project.attributes.name,
    label: project.attributes.identifier,
    banner: project.links.bannerImage,
  }));
};

const mapAuthor = external => {
  return author => ({
    id: author.id,
    name:
      author.attributes.fullName ||
      `${author.attributes.firstName} ${author.attributes.lastName}`,
    external: external,
    email: author.attributes.email,
    avatar: author.links.avatar,
  });
};

const mapExternalAuthor = author => {
  return {
    name: author.attributes.name,
    external: true,
    id: author.id,
    avatar: author.links.avatar,
  };
};

const parseAuthors = (authors, externalAuthors) => {
  authors = authors.map(mapAuthor(false));
  externalAuthors = externalAuthors.map(mapAuthor(true));
  return authors
    .concat(externalAuthors)
    .sort((a, b) => a.name.localeCompare(b.name));
};

const addExternalAuthor = (name, setSubmitting) => {
  const request = {
    data: {
      type: "external-users",
      attributes: {
        location: "ResearchDocument",
        name,
      },
    },
  };
  setSubmitting(true);
  return saveResource("external-users", request)
    .then(res => res.json())
    .then(json => json.data);
};

const mapRequest = values => {
  const focusAreas = values.focusAreas.map(area => ({
    type: "focus-areas",
    id: Number(area.id),
  }));
  const relatedProjects = values.projects.map(project => ({
    type: "projects",
    id: Number(project.id),
  }));

  const publication =
    values.status === "Published or presented" && values.publication !== ""
      ? values.publication
      : null;

  const publicationLink =
    values.status === "Published or presented" && values.publicationLink !== ""
      ? values.publicationLink
      : null;

  const request = {
    data: {
      type: "research-documents",
      attributes: {
        title: values.title,
        researchType: values.researchType,
        status: values.status,
        publication: publication,
        publicationLink: publicationLink,
        file: values.researchFile,
        authors: values.authors,
        externalUsers: values.externalAuthors,
      },
      relationships: {
        focusAreas: { data: focusAreas },
        relatedProjects: { data: relatedProjects },
      },
    },
  };
  return request;
};

function useAuthorsAndAuthorTypes() {
  const params = paramBuilder({
    fields: {
      users: ["firstName", "lastName", "fullName", "email"],
      focusAreas: ["name"],
    },
  });
  const [authors, setAuthors] = useState([]);
  const {
    data: users,
    isFetching: isFetchingAuthors,
    error: authorFetchError,
  } = useQuery(["authors"], () => fetcher(`/api/users?${params}`));
  const {
    data: externalAuthors,
    isFetching: isFetchingExternalAuthors,
    error: externalAuthorFetchError,
  } = useQuery(["external-authors"], () => fetcher("/api/external-users"));

  useEffect(() => {
    if (isFetchingAuthors || isFetchingExternalAuthors) return;
    if (authorFetchError || externalAuthorFetchError) {
      setAuthors([]);
    } else {
      const authors = parseAuthors(users.data, externalAuthors.data);
      setAuthors(authors);
    }
  }, [
    isFetchingAuthors,
    authorFetchError,
    isFetchingExternalAuthors,
    externalAuthorFetchError,
    users,
    externalAuthors,
  ]);

  return { authors, authorTypes: AUTHOR_TYPES };
}

function useProjectOptions(nameFilter = "") {
  const params = paramBuilder({
    fields: {
      projects: ["identifier", "name", "bannerUrl"],
    },
    filter: {
      type: ["ClinicalTrial", "Initiative", "ResearchStudy"],
      name: nameFilter,
    },
  });
  const [projectOptions, setProjectOptions] = useState([]);
  const { data: projects, isFetching, error, refetch } = useQuery(
    ["member_center_projects"],
    () => fetcher(`/api/projects?${params}`),
  );
  useEffect(() => {
    if (isFetching) return;
    if (error) {
      setProjectOptions([]);
    } else {
      setProjectOptions(parsedProjects(projects));
    }
  }, [isFetching, error, projects]);
  useEffect(() => {
    refetch();
  }, [nameFilter, refetch]);

  return projectOptions;
}

export {
  useAuthorsAndAuthorTypes,
  useProjectOptions,
  addExternalAuthor,
  mapExternalAuthor,
  mapRequest,
};
