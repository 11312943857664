import * as React from "react";
import { useEffect } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher, fetcher, saveResource } from "@/utils/api";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { format } from "date-fns";
import Tooltip from "@/components/shared/Tooltip";
import DailyNewsTable from "./DailyNewsTable";
import { useNavigate } from "react-router";
import AdminHeader from "@/components/shared/Admin/AdminHeader";

const fetchDailyNews = ({ pageParam = 0, queryKey }) => {
  const key = queryKey[0];
  const params = queryKey[1];
  const url = `/api/${key}?page[size]=20&page[number]=${pageParam +
    1}&${params}`;
  return denormalizedFetcher(url);
};
const Stats = ({ stats }) => {
  if (stats == undefined) return <></>;
  const {
    newsFeedCount,
    firstNewsFeedDate,
    optedIn,
    optedOut,
    suspended,
  } = stats?.data;
  return (
    <section className="pb-7.5">
      <h2 className="mt-2.5 mb-5.5 text-22 font-medium text-dark-100">
        <i className="ri-bar-chart-box-line text-dark-50" /> Statistics
      </h2>
      <div className="flex gap-3">
        <div className="bg-white text-dark-100 rounded shadow pt-2.5 px-6.5 pb-4.5">
          <p className="text-22 font-normal mb-2.5">{newsFeedCount}</p>
          <p className="font-h3">Published Daily News Feeds</p>
          <p className="font-meta mt-1.5">
            Since{" "}
            {stats
              ? format(new Date(firstNewsFeedDate), "MMMM dd, yyyy")
              : "--"}
          </p>
        </div>
        <div className="bg-white text-dark-100 rounded shadow flex-1">
          <div className="pt-2.5 px-6.5 pb-3 flex items-baseline">
            <p className="text-22 font-normal mr-4">{optedIn}</p>
            <span className="font-h3">Current Email Recipients</span>
          </div>
          <ul className="list-none py-4.5 px-6.5 flex border-t border-tint">
            <li className="text-dark-75 text-14 flex-1">
              <span className="text-dark-100 mr-3 font-h3">{optedIn}</span>
              Opted-in
            </li>
            <li className="text-dark-75 text-14 flex-1">
              <span className="text-dark-100 mr-3 font-h3">{optedOut}</span>
              Opted-out
            </li>
            <li className="text-dark-75 text-14 flex flex-1">
              <span className="text-dark-100 mr-3 font-h3">{suspended}</span>
              <Tooltip>
                <Tooltip.Trigger>
                  <span>Past Recipients *</span>
                </Tooltip.Trigger>
                <Tooltip.Content>Suspended Accounts</Tooltip.Content>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
function DailyNewsFeedPage() {
  const navigate = useNavigate();
  const createNewsFeed = () => {
    const request = {
      data: {
        type: "news-feeds",
      },
    };
    if (request.data) {
      saveResource("news-feeds", request).then(res =>
        res.json().then(response => {
          navigate(response.data.links.edit);
        }),
      );
    }
  };
  const params = paramBuilder({
    include: ["author"],
    fields: {
      newsFeeds: [
        "title",
        "publishedAt",
        "createdAt",
        "canPublish",
        "author",
        "isPublic",
        "sendNewsFeedDate",
      ],
      users: ["fullName"],
    },
    sort: ["-publishedAt"],
  });

  const { data: stats } = useQuery(["news-feeds", "stats"], () =>
    fetcher("/api/news-feeds/stats"),
  );

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["news-feeds", params], fetchDailyNews, {
    getNextPageParam: apiCanFetch,
  });
  const { data: newsFeeds } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("article-container");

  useEffect(() => {
    if (isFetching) return;
    if (isAtBottom && hasNextPage) fetchNextPage();
  }, [isAtBottom, isFetching, fetchNextPage, hasNextPage]);

  return (
    <div className="bg-tint h-screen">
      <AdminHeader
        title="Daily News Feed"
        description="Create and edit Daily News Feeds for the PICI network."
        linkText={stats && "+ New Daily News Feed"}
        linkTo={createNewsFeed}
        disabledLinkTo={stats && stats.data.draftExists}
      />
      <article
        id="article-container"
        className="px-5 pt-4 overflow-x-hidden h-vh-60"
      >
        <div className="mr-auto ml-auto max-w-5xl">
          <Stats stats={stats} />
          <section className="pb-7.5">
            <h2 className="mt-2.5 mb-5.5 text-22 font-medium text-dark-100">
              <i className="ri-calendar-todo-line text-dark-50 mr-2.5" />
              All Daily News Feeds
            </h2>
            <DailyNewsTable newsFeed={newsFeeds} refetch={refetch} />
          </section>
        </div>
      </article>
    </div>
  );
}

export default DailyNewsFeedPage;
