import * as React from "react";
import Breadcrumb from "@/components/shared/Breadcrumb";

function Hero({ title, description, children }) {
  return (
    <section className="w-full bg-gray-50 h-[570px]">
      <div className="flex flex-col margin-safe px-6 pb-6 lg:px-10 lg:pb-10 font-lato text-dark-100 min-h-full">
        <Breadcrumb to="/insights" text="Back to Insights" />
        <div className="flex-grow items-center font-body justify-between flex flex-col md:flex-row pt-6 md:pt-0">
          <div className="w-full md:w-[450px]">
            <h1 className="font-h1 md:font-h1-xl">{title}</h1>
            <p className="mt-2 md:mt-4">{description}</p>
          </div>
          <div className="flex-grow md:max-w-[654px] w-full  h-[355px] text-center">
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
