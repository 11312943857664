import React, { useEffect, useState } from "react";
import OutsideClickHandler from "@/utils/deps/react-outside-click-handler";
import clsx from "clsx";

const _matchCriteria = (input, itemSearchable) =>
  itemSearchable.toLowerCase().indexOf(input.toLowerCase()) === 0;

const _accessor = item => item.value;

function SearchBar({
  placeholder,
  options = [],
  onSelect,
  onTextChange = _ => {},
  accessor = _accessor,
  matchCriteria = _matchCriteria,
  children,
  selected = "",
  displayNewExternal = false,
  className = "",
}: {
  placeholder?: string;
  options: any[];
  onSelect?: (item: any) => void;
  onTextChange?: (item: string) => void;
  accessor?: (item: any) => any;
  matchCriteria?: (input: string, itemSearchable: string) => boolean;
  children: Function;
  selected: any;
  displayNewExternal?: boolean;
  className?: string;
}) {
  const [focused, setFocus] = useState(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    selected,
  );
  const [filtered, setFiltered] = useState<any[]>([]);

  useEffect(() => {
    const matches = options.filter(option => {
      return matchCriteria((currentValue || "").trim(), accessor(option));
    });
    setFiltered(matches);
  }, [accessor, matchCriteria, currentValue, options]);

  useEffect(() => {
    setCurrentValue(selected);
  }, [selected]);

  return (
    <div className={clsx("inline-block w-full relative", className)}>
      <OutsideClickHandler onOutsideClick={() => setFocus(false)}>
        <div
          className={clsx(
            "relative h-12 flex items-center text-15 rounded-md",
            "border-dark-25 border focus-within:border-primary focus-within:ring-primary focus-within:ring-1",
          )}
        >
          <i
            className={clsx(
              "ri-search-line ri-lg absolute h-4 w-4 left-3 top-0 bottom-0 pointer-events-none ease-in-out",
              "transition-all duration-250 m-auto flex justify-center text-dark-100",
              { "opacity-0": focused || currentValue },
            )}
          />
          <input
            type="text"
            placeholder={placeholder}
            onChange={e => {
              setCurrentValue(e.target.value);
              onTextChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            value={currentValue}
            className={clsx(
              "w-full outline-none border-0 bg-transparent pr-4 relative transform transition-all duration-250 ease-in-out",
              "focus:outline-none focus:ring-0",
              focused || currentValue ? "pl-4" : "pl-10",
            )}
            data-cy="search-bar-input"
          />
        </div>
        {focused && filtered.length > 0 && (
          <ul
            className={clsx(
              "w-full border border-dark-25 rounded overflow-x-hidden absolute left-0 z-50 max-h-50 mt-1",
              "bg-white divide-y divide-lines overflow-y-auto",
            )}
            style={{
              transition: "visibility 0.3s, opacity 0.3s, transform 0.3s",
            }}
          >
            {filtered.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  setFocus(false);
                  if (onSelect) {
                    onSelect(item);
                  }
                }}
              >
                {children({ item, index })}
              </li>
            ))}
          </ul>
        )}
        {focused && filtered.length === 0 && displayNewExternal && (
          <ul
            className={clsx(
              "w-full border border-dark-25 rounded overflow-x-hidden absolute left-0 z-50 max-h-50 mt-1",
              "bg-white divide-y divide-lines overflow-y-auto",
            )}
            style={{
              transition: "visibility 0.3s, opacity 0.3s, transform 0.3s",
            }}
          >
            <li
              onClick={() => {
                setFocus(false);
                if (onSelect) {
                  onSelect(selected);
                }
              }}
            >
              {children({ item: selected, index: "external" })}
            </li>
          </ul>
        )}
      </OutsideClickHandler>
    </div>
  );
}

export default SearchBar;
