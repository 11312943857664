import * as React from "react";

function BoldIcon(props) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
    </svg>
  );
}

function ItalicIcon(props) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4h-8z" />
    </svg>
  );
}

function UnderlinedIcon(props) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z" />
    </svg>
  );
}

function ListIcon(props) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z" />
    </svg>
  );
}

function OrderListIcon(props) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="bi bi-list-ol"
      {...props}
    >
      <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z" />
    </svg>
  );
}

function LinkIcon(props) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2zm-3-4h8v2H8z" />
    </svg>
  );
}

export {
  BoldIcon,
  ItalicIcon,
  UnderlinedIcon,
  ListIcon,
  OrderListIcon,
  LinkIcon,
};
