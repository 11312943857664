import * as React from "react";
import { useRef, useCallback, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Field, FormikProps } from "formik-latest";
import fetch from "cross-fetch";
import clsx from "clsx";
import { saveResource } from "@/utils/api";
import { useTextareaAutosize } from "@/hooks/useTextareaAutosize";
import useFlashMessage from "@/hooks/useFlashMessage";
import Input from "@/components/shared/Input";
import Label from "@/components/shared/Label";
import SlideOver from "@/components/shared/SlideOver";
import UpdateConfirmationModal from "./UpdateConfirmationModal";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";

function FocusSlider({
  formikProps,
  openForm,
  setOpenForm,
  editing,
  setEditing,
  resource,
}: {
  formikProps: FormikProps<{ name: string; description: string }>;
  openForm: boolean;
  setOpenForm: Function;
  editing: boolean;
  setEditing: Function;
  resource: any;
}) {
  const textareaRef = useRef();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryClient = useQueryClient();

  const showFlash = useFlashMessage({
    message: `The focus area has been ${editing ? "updated" : "created"}.`,
  });
  const showDeleteFlash = useFlashMessage({
    message: "The focus area has been deleted.",
  });

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      showFlash({ success: true });
      queryClient.invalidateQueries(["focus-areas"]);
    },
    onError: () => showFlash({ success: false }),
  });

  const { isValid, isSubmitting, touched, resetForm, values } = formikProps;

  useTextareaAutosize(textareaRef, 75, values.description);

  const close = useCallback(() => {
    setOpenForm(false);
    setEditing(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    resetForm();
  }, [resetForm, setEditing, setOpenForm]);

  const save = useCallback(async () => {
    await mutateAsync(
      saveResource("focus-areas", {
        data: {
          type: "focus-areas",
          id: resource?.id,
          attributes: {
            name: values.name,
            description: values.description,
          },
        },
      }),
    );
    close();
  }, [close, mutateAsync, resource, values]);

  const submit = useCallback(() => {
    if (editing) {
      setShowEditModal(true);
    } else {
      save();
    }
  }, [editing, save]);

  const onDelete = useCallback(() => {
    fetch(resource.links.self, { method: "DELETE" }).then(res => {
      showDeleteFlash({ success: res.ok });
      if (res.ok) {
        queryClient.invalidateQueries(["focus-areas"]);
      }
      close();
    });
  }, [close, queryClient, resource, showDeleteFlash]);
  const modalTitle = editing
    ? `Edit ${resource.attributes.name}`
    : "New Focus Area";
  return (
    <>
      <SlideOver open={openForm} setOpen={close}>
        <SlideOver.Header>{modalTitle}</SlideOver.Header>
        <SlideOver.Body className="font-lato">
          <Field name="name">
            {({ field, meta }) => (
              <>
                <Label htmlFor="name" name="Focus Area Title" />
                <Input
                  className={clsx(
                    "block w-full py-9px px-3.5 h-10 rounded-3px text-15 mt-2.5",
                    "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75",
                  )}
                  data-cy="name"
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="text-error text-14 mt-5px">{meta.error}</div>
                )}
              </>
            )}
          </Field>
          <Field name="description">
            {({ field, meta }) => (
              <div className="mt-5">
                <Label
                  htmlFor="description"
                  name="Description"
                  optional={true}
                />
                <textarea
                  className={clsx(
                    "block w-full py-9px px-3.5 rounded-3px text-15 mt-2.5 border border-dark-25 max-h-screen resize-none",
                    "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75 min-h-75px leading-5",
                  )}
                  ref={textareaRef}
                  data-cy="description"
                  {...field}
                />
                {meta.touched && meta.error && (
                  <div className="text-error text-14 mt-5px">{meta.error}</div>
                )}
              </div>
            )}
          </Field>
          <UpdateConfirmationModal
            open={showEditModal}
            setOpen={setShowEditModal}
            onAccept={save}
          />
          <DeleteConfirmationModal
            open={showDeleteModal}
            setOpen={setShowDeleteModal}
            onAccept={onDelete}
            resource="focus area"
            message="Members will no longer be able to use this label to discover projects
            and research once it's deleted."
            className="w-105"
          />
        </SlideOver.Body>
        <SlideOver.Footer className="flex justify-start ">
          <button
            className={clsx(
              "btn-sm btn-primary rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium py-9px px-7.5 font-lato",
              {
                "bg-primary text-white hover:border-primary-darker hover:bg-primary-darker active:bg-primary-darkest": isValid,
                "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none":
                  isSubmitting ||
                  !isValid ||
                  Object.entries(touched).length === 0,
              },
            )}
            onClick={submit}
            data-cy="submit"
          >
            {editing ? "Save changes" : "Create Focus Area"}
          </button>
          <button
            className={clsx(
              "border border-tint rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
              "bg-tint text-dark-50 hover:bg-white py-9px px-7.5 font-lato active:bg-lines",
            )}
            onClick={() => close()}
          >
            Cancel
          </button>
          <div className="flex-grow" />
          {editing && (
            <button
              className={clsx(
                "border border-lines rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
                "bg-tint text-dark-75 hover:bg-white py-9px px-7.5 font-lato active:bg-lines",
              )}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Focus Area
            </button>
          )}
        </SlideOver.Footer>
      </SlideOver>
    </>
  );
}

export default FocusSlider;
