import * as React from "react";
import useCurrentUser from "@/hooks/useCurrentUser";
import centerBannerPlaceholder from "@/images/center-banner-placeholder.png";
import Tabs from "@/components/shared/Tabs";

function Header({ user, tabs, selectedTab, setSelectedTab }) {
  const currentUser = useCurrentUser();

  const {
    fullName,
    firstName,
    title,
    centerShorthandOrInstitution,
  } = user.attributes;
  const center = user.relationships.center.data;
  const { avatar } = user.links;
  const banner = center?.links.bannerImage || centerBannerPlaceholder;

  return (
    <div className="flex flex-col h-450px sm:h-340px">
      <div className="relative overflow-hidden flex flex-grow items-end sm:border-b border-dark-75">
        <div className="absolute inset-0">
          <img
            className="object-cover sm:object-fill w-full min-h-full min-w-full"
            src={banner}
            alt=""
          />
          <div
            className="absolute inset-0 bg-dark-100 opacity-50"
            aria-hidden="true"
          />
          <div
            className="absolute inset-0 bg-gradient-to-t from-dark-100"
            aria-hidden="true"
          />
        </div>
        <div className="text-white relative margin-safe sm:mb-10">
          <div className="text-center sm:text-left sm:flex sm:items-center">
            {avatar && (
              <div className="px-6 pb-6 mb-6 inline-block border-b border-lines border-opacity-50 sm:border-b-0 sm:px-0 sm:pb-0 sm:mb-0">
                <img src={avatar} className="rounded-full h-100px sm:h-120px" />
              </div>
            )}
            <div className="sm:pt-1 sm:pb-4 sm:pl-10 sm:ml-9 sm:border-l sm:border-lines sm:border-opacity-50">
              <div className="max-w-xl mx-auto sm:ml-0 sm:max-w-3xl">
                <h1 className="font-h1 sm:font-h1-xl">{fullName}</h1>
                <p className="text-dark-25 mb-2">{title}</p>
                <p className="flex items-center justify-center sm:justify-start">
                  <i className="ri-map-pin-line ri-lg text-dark-25" />
                  <span className="font-body-bold ml-2.5">
                    PICI @ {centerShorthandOrInstitution}
                  </span>
                </p>
                {currentUser?.attributes.isStaff && (
                  <a
                    href={user.links.edit}
                    className="mb-4 sm:mb-0 mt-6 btn btn-sm border-white"
                  >
                    Edit {firstName}'s Profile
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-dark-100">
        <Tabs
          {...{ tabs, selectedTab, setSelectedTab }}
          palette="dark"
          className="margin-safe pb-4 sm:pb-0"
        />
      </div>
    </div>
  );
}

export default Header;
