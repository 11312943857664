import React, { useState, useEffect } from "react";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import ButtonIcon from "@/components/shared/ButtonIcon";
import clsx from "clsx";

const NOT_INDEX_SELECTED: number = -1;

const ImageSelector = ({ values, onChange }) => {
  const [lastSignedId, setLastSignedId] = useState<any>();
  const [lastUrlImage, setLastUrlImage] = useState<any>();

  const handleAddSigned = signedId => {
    if (signedId) {
      setLastSignedId(signedId);
    }
  };

  const handleLoadImage = value => {
    setLastUrlImage(value.result);
  };

  useEffect(() => {
    if (lastSignedId && lastUrlImage) {
      onChange(`galleryImages[${values.galleryImages.length}]`, {
        signed_id: lastSignedId,
        image_url: lastUrlImage,
      });
      setLastSignedId(null);
      setLastUrlImage(null);
    }
  }, [lastSignedId, lastUrlImage, onChange, values.galleryImages.length]);

  return (
    <div className="p-4 rounded-lg flex items-center justify-center shadow-lg min-h-75px hover:bg-slate-100">
      <FilePickerAdmin
        name="gallery-image-picker-1"
        className="w-80 bg-transparent border-0 flex-col"
        setSignedId={signedId => handleAddSigned(signedId)}
        attachment={{}}
        onLoadFile={handleLoadImage}
        hideButtonWhenUpload
        showPreview={false}
      />
    </div>
  );
};

function ImageGallery({ values, onChange, editable = false }) {
  const [isLightboxActive, setLightboxActive] = useState<boolean>(false);
  const [indexImageSelected, setIndexImageSelected] = useState<number>(
    NOT_INDEX_SELECTED,
  );
  const handleClickImage = imageIndex => {
    setIndexImageSelected(imageIndex);
    setLightboxActive(true);
  };

  function cancelCurrentView() {
    setLightboxActive(false);
    setIndexImageSelected(NOT_INDEX_SELECTED);
  }

  const handlePreviousImage = event => {
    event.stopPropagation();
    if (indexImageSelected === 0) {
      cancelCurrentView();
    } else {
      setIndexImageSelected(indexImageSelected - 1);
    }
  };

  const handleNextImage = event => {
    event.stopPropagation();
    if (indexImageSelected === values.galleryImages.length - 1) {
      cancelCurrentView();
    } else {
      setIndexImageSelected(indexImageSelected + 1);
    }
  };

  const handleDeleteImage = (event, imageIndex) => {
    event.stopPropagation();
    const galleryImages = values.galleryImages.filter(
      (_, index) => imageIndex !== index,
    );
    onChange("galleryImages", galleryImages);
  };

  return (
    <>
      <div
        className={clsx("grid grid-cols-2 md:grid-cols-3 gap-5", {
          "lg:grid-cols-3": editable,
          "lg:grid-cols-4": !editable,
        })}
      >
        {values.galleryImages.map((image, index) => (
          <div
            key={index}
            className="relative rounded-lg shadow-lg h-[215px] overflow-hidden"
            onClick={() => handleClickImage(index)}
          >
            {editable && (
              <div className="absolute right-4 top-1 z-1">
                <ButtonIcon
                  title="Delete Section"
                  className="btn-white btn-md h-8 px-2"
                  iconClassName="ri-delete-bin-line"
                  onClick={event => handleDeleteImage(event, index)}
                />
              </div>
            )}
            <div
              className={clsx(
                "cursor-pointer h-full w-full bg-center bg-cover rounded-t-md",
                "transition-all duration-300 ease-linear transform scale-100 hover:scale-125",
              )}
              style={{
                backgroundImage: `url('${image.image_url}')`,
              }}
            />
          </div>
        ))}
        {editable && <ImageSelector values={values} onChange={onChange} />}
      </div>
      {isLightboxActive && (
        <div
          className="z-200 flex fixed bg-gray-600 bg-opacity-80 top-0 left-0 w-full h-full items-center justify-between"
          onClick={() => {
            setLightboxActive(false);
          }}
        >
          <button
            type="button"
            className="absolute btn-white btn-md"
            onClick={handlePreviousImage}
          >
            <i className="ri-arrow-left-s-line text-22 md:text-38" />
          </button>
          {indexImageSelected >= 0 && (
            <img
              className="m-auto"
              src={values.galleryImages[indexImageSelected].image_url}
            ></img>
          )}
          <button
            type="button"
            className="absolute right-0 btn-white btn-md"
            onClick={handleNextImage}
          >
            <i className="ri-arrow-right-s-line text-22 md:text-38" />
          </button>
        </div>
      )}
    </>
  );
}

export default ImageGallery;
