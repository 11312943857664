import React, { useMemo } from "react";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import Breadcrumb from "@/components/shared/Breadcrumb";
import AsideComponent from "@/components/shared/Aside";
import Slideshow from "@/components/shared/Slideshow";
import { useParams } from "react-router-dom";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { useQuery } from "@tanstack/react-query";
import ResourceLinkRow from "../admin/ArticlesPage/ArticlesForm/ArticleAside/ResourceLinkRow";
import SpeakerItemRow from "../admin/ArticlesPage/ArticlesForm/ArticleAside/SpeakerItemRow";
import { KeySection } from "@/components/shared/Retreat/Article/defaults";
import ArticleTitle from "@/components/shared/Retreat/Article/ArticleTitle";
import TextSectionEditor from "@/components/shared/Retreat/Article/SectionEditors/TextSectionEditor";
import TableSectionEditor from "@/components/shared/Retreat/Article/SectionEditors/TableSectionEditor";
import ImageSectionEditor from "@/components/shared/Retreat/Article/SectionEditors/ImageSectionEditor";
import parseArticleResponse from "./default";
import { SkeletonArticle } from "./RetreatSkeletonPage";
import ArticleCard from "@/components/shared/Retreat/ArticleCard";
import NoResults from "@/components/shared/NoResults";
import NotFoundPage from "@/components/App/NotFoundPage";

const ReferenceLinks = ({ classNames, resourceLinks = [] }) => {
  return (
    <AsideComponent className={`${classNames} w-full`}>
      <AsideComponent.Section>
        <AsideComponent.Content>
          <AsideComponent.Title title="Resource Links" />
          <AsideComponent.Body className="mt-[9px] flex flex-col gap-[0.7rem]">
            {resourceLinks.map((linkItem, index) => (
              <ResourceLinkRow
                key={index}
                resourceLinkItem={linkItem}
                readOnly
              />
            ))}
          </AsideComponent.Body>
        </AsideComponent.Content>
      </AsideComponent.Section>
    </AsideComponent>
  );
};

const fetchArticle = async id => {
  const baseUrl = `/api/articles/${id}`;
  const params = paramBuilder({
    include: ["articleSpeakers", "articleSections"],
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function ArticlePage() {
  const { id: articleId } = useParams();
  const {
    data: articleResponse,
    isLoading,
  }: { data: any; isLoading: boolean } = useQuery(
    ["RetreatArticle", articleId],
    () => fetchArticle(articleId),
  );

  const {
    data: highlights,
    isLoading: isLoadingHighlights,
  }: { data: any; isLoading: boolean } = useQuery(
    ["RetreatArticle-highlights", articleId],
    () => fetcher(`/api/articles/${articleId}/highlights`),
    {
      enabled: articleResponse?.data !== undefined,
    },
  );

  const article = useMemo(
    () => (articleResponse ? parseArticleResponse(articleResponse) : {}),
    [articleResponse],
  );

  if (!isLoading && articleResponse.errors) {
    return (
      <NoResults
        entityName="Article"
        icon="ri-search-line"
        className="!bg-white !my-auto !mt-32"
      />
    );
  }

  if (articleResponse?.errors) return <NotFoundPage />;
  return (
    !isLoading && (
      <>
        <div className="margin-safe mb-12">
          <Breadcrumb
            to={`/retreats/${article.retreatId}`}
            text={`Back to ${article.quarterName} PICI Retreat`}
          />
          <section className="w-full grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_350px] lg:gap-y-7.5">
            <Slideshow
              classNames="col-span-3 mt-[25px] h-auto  w-full"
              imgClassName="rounded-lg min-h-[439px]"
              slides={article.slideshowImagesUrl}
            />
            <AsideComponent className="space-y-7.5 lg:ml-7.5 mt-[25px] mb-7.5 row-span-3 max-w-xs">
              <AsideComponent.Section>
                <AsideComponent.Content>
                  <AsideComponent.Title title="Speaker Info:" />
                  <AsideComponent.Body className="mt-3.5 space-y-2">
                    {article.articleSpeakers &&
                      article.articleSpeakers.map(speaker => (
                        <SpeakerItemRow
                          key={speaker.userId}
                          user={speaker}
                          displayDelete={false}
                        />
                      ))}
                  </AsideComponent.Body>
                </AsideComponent.Content>
                <AsideComponent.Content>
                  <AsideComponent.Title
                    className={"font-meta-bold"}
                    title="Presentation Date:"
                  />
                  <AsideComponent.Body className="mt-1">
                    <p className="font-meta text-dark-75">
                      {article.presentationDate &&
                        format(
                          new Date(article.presentationDate),
                          "MMMM dd, yyyy",
                        )}
                    </p>
                  </AsideComponent.Body>
                </AsideComponent.Content>
              </AsideComponent.Section>
              {article.resourceLinks.length > 0 && (
                <ReferenceLinks
                  classNames="hidden lg:block"
                  resourceLinks={article.resourceLinks}
                />
              )}
            </AsideComponent>
            <ArticleTitle values={article} className="col-span-3" />
            {article.articleSections.map(section => {
              switch (section.keySection) {
                case KeySection.SmallHeader:
                case KeySection.LargeHeader:
                case KeySection.ReferenceText:
                  return (
                    <TextSectionEditor
                      key={section.position}
                      section={section}
                      className="col-span-3"
                      values={[]}
                      onChange={() => {}}
                    />
                  );
                case KeySection.BasicTable:
                  return (
                    <TableSectionEditor
                      key={section.position}
                      section={section}
                      className="col-span-3"
                      values={[]}
                      onChange={() => {}}
                    />
                  );
                case KeySection.ImageFullWidth:
                case KeySection.ImageTwoColumns:
                  return (
                    <ImageSectionEditor
                      key={section.position}
                      section={section}
                      className="col-span-3"
                      values={article}
                      onChange={() => {}}
                    />
                  );
                default:
                  return null;
              }
            })}
            {article.resourceLinks.length > 0 && (
              <ReferenceLinks
                classNames="lg:hidden"
                resourceLinks={article.resourceLinks}
              />
            )}
          </section>
        </div>
        {!isLoadingHighlights &&
          !isEmpty(highlights) &&
          highlights?.data.length > 0 && (
            <section className="bg-tint py-[60px]">
              <div className="margin-safe">
                <h1 className="font-h1 md:text-center mb-10">
                  {" "}
                  More Highlights
                </h1>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px]">
                  {isLoadingHighlights
                    ? [1, 2, 3].map(element => (
                        <SkeletonArticle key={element} showInMobile={false} />
                      ))
                    : highlights?.data.map(highlight => (
                        <ArticleCard key={highlight.id} article={highlight} />
                      ))}
                </div>
              </div>
            </section>
          )}
      </>
    )
  );
}

export default ArticlePage;
