import * as React from "react";
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import Filter from "@/components/shared/Filter";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import Input from "@/components/shared/Input";

const sortOptions = [
  { name: "Date, Newest first", id: "-createdAt" },
  { name: "Date, Oldest first", id: "createdAt" },
  { name: "Title, A-Z", id: "name" },
  { name: "Title, Z-A", id: "-name" },
];

const statusOptions = [
  { name: "Archived", id: "true" },
  { name: "Not Archived", id: "false" },
  { name: "All", id: "all" },
];

const makeDataToOptions = data => {
  return data?.map(item => {
    return { name: item.attributes.shorthand, id: item.id };
  });
};

function InitiativesFilter({ setSearchText }) {
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedCenter, setSelectedCenter] = useState([]);
  const [typesFocus, setTypesFocus] = useState(false);
  const { data: centers } = useQuery(["centerFilters"], () =>
    fetcher("/api/centers"),
  );
  const centerOptions = useMemo(() => makeDataToOptions(centers?.data), [
    centers?.data,
  ]);

  const handleOnChangeTextFilter = event => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <div className="flex flex-1">
        <div className="flex mr-5">
          <i className="ri-filter-line text-xl mr-2.5 text-black-placeholder" />
          <Filter
            name="centers"
            items={centerOptions}
            selectedItems={selectedCenter}
            setSelectedItems={setSelectedCenter}
            highlight={typesFocus}
            setFocus={setTypesFocus}
          >
            <Filter.Label className="!h-[30px] !text-14 !font-light ">
              All Centers
            </Filter.Label>
            <Filter.Content>
              <CheckboxGroup
                options={centerOptions}
                selectedOptions={selectedCenter}
                setSelectedOptions={setSelectedCenter}
                className="filter-checkbox-group"
              />
            </Filter.Content>
          </Filter>
        </div>
        <div className="flex">
          <i className="ri-arrow-up-down-line text-xl mr-2.5 text-black-placeholder"></i>
          <Filter
            name="sort"
            items={sortOptions}
            selectedItems={selectedSort}
            setSelectedItems={setSelectedSort}
            closeOnSelect
          >
            <Filter.Label className="!h-[30px] !text-14 !font-light mr-5">
              {selectedSort?.name}
            </Filter.Label>
            <Filter.Content>
              <SelectGroup
                options={sortOptions}
                selectedOption={selectedSort}
                setSelectedOption={setSelectedSort}
              />
            </Filter.Content>
          </Filter>
        </div>
        <div className="flex mr-5">
          <Filter
            name="archived"
            items={statusOptions}
            selectedItems={selectedStatus}
            setSelectedItems={setSelectedStatus}
            closeOnSelect
          >
            <Filter.Label className="!h-[30px] !text-14 !font-light mr-5">
              Visibility
            </Filter.Label>
            <Filter.Content>
              <SelectGroup
                options={statusOptions}
                selectedOption={selectedStatus}
                setSelectedOption={setSelectedStatus}
              />
            </Filter.Content>
          </Filter>
        </div>
      </div>
      <div className="self-end relative flex-none">
        <Input
          placeholder="Find by title"
          className="h-30px !text-14 rounded-2xl pr-7"
          onChange={handleOnChangeTextFilter}
        />
        <i className="ri-search-line absolute right-3 top-1 text-black-placeholder"></i>
      </div>
    </>
  );
}

export default InitiativesFilter;
