import React, { useState, useEffect } from "react";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import Tabs from "@/components/pages/admin/MediaResourcesPage/Tabs";
import { FilterOption } from "@/types";
import SummaryTab from "./SummaryTab/SummaryTab";
import AllTab from "./AllTab/AllTab";
import { useLocation } from "react-router-dom";

const TABS: FilterOption[] = [
  {
    id: "1",
    name: "All",
    slug: "all",
  },
];

function ClinicalTrialsPage() {
  const [selectedTab, setSelectedTab] = useState<FilterOption | undefined>(
    undefined,
  );
  const { hash } = useLocation();

  useEffect(() => {
    setSelectedTab(TABS.find(tab => tab.slug == hash.slice(1)));
  }, [hash]);

  return (
    <div className="bg-tint min-h-screen overflow-hidden">
      <AdminHeader
        title="Clinical Trials"
        description="All Clinical Trial projects at PICI"
        className="!h-[106px] "
        linkText="+ New Clinical Trial"
        linkTo="/admin/projects/new?type=clinical_trial"
        linkReloadDocument
        exportText="Download CSV"
        exportLinkTo="/admin/projects/export.csv?type=ClinicalTrial"
      >
        <Tabs
          defaultTabLabel="Summary"
          tabs={TABS}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="pb-0 px-7.5 border-t border-lines"
        />
      </AdminHeader>
      {!selectedTab && <SummaryTab />}
      {selectedTab?.slug === "all" && <AllTab />}
    </div>
  );
}

export default ClinicalTrialsPage;
