import React, { useState, useEffect } from "react";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import Tabs from "@/components/pages/admin/MediaResourcesPage/Tabs";
import { FilterOption } from "@/types";
import { Menu, Transition } from "@headlessui/react";
import AllTab from "./TabContent";
import SummaryTab from "./SummaryTab";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const TABS: FilterOption[] = [
  {
    id: "1",
    name: "All",
    slug: "all",
  },
  {
    id: "2",
    name: "Pending Disclosures",
    slug: "pending",
  },
  {
    id: "3",
    name: "Accepted",
    slug: "accepted",
  },
  {
    id: "4",
    name: "Declined",
    slug: "declined",
  },
];

function InventionsPage() {
  const [selectedTab, setSelectedTab] = useState<FilterOption | undefined>(
    undefined,
  );
  const { hash } = useLocation();

  const toolbar = (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex mt-2">
        <i className="ri-download-2-line mr-2 text-primary" />
        <span className="font-meta text-primary mt-0.5">Export Options</span>
        <i className="ri-arrow-drop-down-line text-2xl text-dark-100 font-normal ml-auto sm:ml-1 leading-none"></i>
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            "absolute border right-0 mt-2 w-48 origin-top-right divide-y",
            "divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none",
            "px-5 py-3 text-14 text-dark-75",
          )}
        >
          <div className="px-1 py-1 ">
            <Menu.Item>
              <a
                href="/api/inventions/export.csv"
                className="py-1 flex flex-wrap cursor-pointer items-center hover:text-primary"
                data-track-event="Click Download CSV on Inventions page"
              >
                Download CSV
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                href="/api/inventions/portfolio_export.csv"
                className="py-1 flex flex-wrap cursor-pointer items-center hover:text-primary"
                data-track-event="Click Portfolio Download CSV on Inventions page"
              >
                IP Portfolio CSV
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                href="/api/inventions/licenses_export.csv"
                className="py-1 flex flex-wrap cursor-pointer items-center hover:text-primary"
                data-track-event="Click License Report IP Download CSV on Inventions page"
              >
                License Report IP CSV
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                href="/api/inventions/patent_expenses.csv"
                className="py-1 flex flex-wrap cursor-pointer items-center hover:text-primary"
                data-track-event="Click Portfolio Download CSV on Inventions page"
              >
                Patent Expenses
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                href="/api/inventions/invention_all_fields_export.csv"
                className="py-1 flex flex-wrap cursor-pointer items-center hover:text-primary"
                data-track-event="Click All Fields CSV on Inventions page"
              >
                All Fields
              </a>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  useEffect(() => {
    setSelectedTab(TABS.find(tab => tab.slug == hash.slice(1)));
  }, [hash]);
  return (
    <div className="bg-tint min-h-screen">
      <AdminHeader
        title="Inventions"
        className="!h-[106px] z-1"
        linkText="+ New Invention"
        linkTo="/admin/inventions/new"
        toolbar={toolbar}
        spa={false}
      >
        <Tabs
          defaultTabLabel="Summary"
          tabs={TABS}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="pb-0 px-7.5 border-t border-lines"
        />
      </AdminHeader>
      {!selectedTab ? <SummaryTab /> : <AllTab tabType={selectedTab?.slug} />}
    </div>
  );
}

export default InventionsPage;
