import React from "react";
import { Link } from "react-router-dom";

function ArticleCard({ article }) {
  return (
    <Link
      to={`/retreats/article/${article.id}`}
      key={article.id}
      className="group"
    >
      <article className="bg-white h-auto lg:h-[390px] retreat-shadow">
        <div className="h-[215px] w-full overflow-hidden">
          <div
            className="h-full w-full rounded-t-md transition-all duration-300 ease-linear transform bg-center bg-cover scale-100 group-hover:scale-125"
            style={{
              backgroundImage: `url('${article.thumbnailImageUrl}')`,
            }}
          />
        </div>
        <header className="p-[30px]">
          <h2 className="font-h2 line-clamp-2 mb-1 group-hover:underline">
            {article.title}
          </h2>
          <p className="font-body line-clamp-2">{article.description || ""}</p>
        </header>
      </article>
    </Link>
  );
}

export default ArticleCard;
