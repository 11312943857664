import React from "react";
import { BarChart, Bar, Tooltip, XAxis, YAxis, CartesianGrid } from "recharts";
import StackedTooltip from "./StackedTooltip";
import computeTotal from "./computeTotal";
import COLORS from "./Colors";
import * as R from "ramda";

const CHART_WIDTH = 878;
const CHART_HEIGHT = 578;
const rowItems = 5;

const XTick = props => {
  return (
    <text {...props} className="font-label">
      {props.payload.value}
    </text>
  );
};

const YTick = props => {
  return (
    <text {...props} textAnchor="start">
      <tspan dx="-36px" dy="0.335em">
        {props.payload.value}
      </tspan>
    </text>
  );
};

const Legend = ({ centers }) => {
  return (
    <div className="w-150 box-content ml-120px mt-9">
      {R.splitEvery(rowItems, centers).map((row, i) => (
        <ul className="flex list-none p-0 mt-0 mb-3.5 gap-10" key={i}>
          {row.map((center, index) => (
            <li className="flex items-center font-meta gap-1" key={index}>
              <div
                className="w-6 h-1 inline-block"
                style={{ backgroundColor: COLORS[i * rowItems + index] }}
              ></div>
              {center}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

function FullSize({ data, centers }) {
  const total = computeTotal(data);
  const inventions = [...data, { ...total, name: "Total" }];

  return (
    <>
      <h2 className="font-h2">Accepted/Declined</h2>
      <BarChart
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        data={inventions}
        margin={{
          top: 56,
          bottom: 12,
        }}
      >
        <CartesianGrid vertical={false} stroke="#CFD3DA" />
        <Tooltip content={<StackedTooltip />} />
        <YAxis axisLine={false} tickLine={false} tick={<YTick />} />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tickMargin={24}
          tick={<XTick />}
        />
        {centers.map((center, index) => (
          <Bar
            dataKey={center}
            key={center}
            stackId="center"
            fill={COLORS[index]}
            barSize={72}
          />
        ))}
      </BarChart>
      <Legend centers={centers} />
    </>
  );
}

export default FullSize;
