import * as React from "react";
import Breadcrumb from "@/components/shared/Breadcrumb";

function ConfidentialityPage() {
  return (
    <div className="margin-safe">
      <Breadcrumb to="/" text="Back to Dashboard" />
      <div className="py-7.5 sm:py-10 md:flex">
        <div className="pb-10 md:flex-grow">
          <header className="mb-7.5 md:mb-10">
            <h1 className="font-h2 sm:font-h1 text-dark-100 mb-5">
              CONFIDENTIALITY TERMS AND CONDITIONS
            </h1>
          </header>
          <div className="py-2.5 sm:py-0 font-body text-dark-75 mb-4">
            <ol className="list-decimal pl-4 [counter-reset:section]">
              <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                <h2 className="uppercase font-bold ml-4">
                  CONFIDENTIAL INFORMATION.
                </h2>
                <p className="ml-4">
                  <strong>“Confidential Information”</strong> means any
                  information or material disclosed or shared by PICI to you
                  (“Recipient’) through the PICI Member Center, which PICI
                  treats as confidential or proprietary, including without
                  limitation any data or pre-publication manuscripts.
                </p>
              </li>
              <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                <h2 className="uppercase font-bold ml-4">YOUR OBLIGATIONS.</h2>
                <ol className="list-decimal pl-12 [counter-reset:section]">
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">
                        Nonuse and Nondisclosure.
                      </strong>{" "}
                      You agree to keep confidential and not publish, make
                      available or otherwise disclose Confidential Information
                      to any third party. You agree not to use Confidential
                      Information for any purpose other than the limited extent
                      necessary in connection with access to and use of the PICI
                      Member Center as a part of PICI’s network and as permitted
                      by PICI.
                    </p>
                  </li>
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">Compliance with Law.</strong> In
                      the event that any Confidential Information is required to
                      be disclosed by you pursuant to law, regulation or valid
                      court order, you are permitted to make such disclosure;
                      provided, however, that to the extent legally permitted
                      (a) you promptly notify PICI of that fact in writing to
                      permit PICI the reasonable opportunity to appear in any
                      judicial proceeding involved or otherwise act to preserve
                      its rights and the parties will cooperate to a reasonable
                      extent with each other in such proceedings, and (b) such
                      disclosure is not greater than what was required to be
                      compliant with such law, regulation or order and you
                      cooperate with PICI to obtain confidential treatment, to
                      the extent possible, with respect to the Confidential
                      Information so disclosed.
                    </p>
                  </li>
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">Standard of Care.</strong> You
                      will protect all Confidential Information from disclosure
                      and unauthorized use in the same manner that you protect
                      your own proprietary and confidential information of like
                      nature, but in no event shall such standard of care be
                      less than reasonable care.
                    </p>
                  </li>
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">Notification.</strong> You agree
                      to notify PICI immediately and cooperate fully at PICI’s
                      reasonable request, upon your discovery of any
                      unauthorized use, disclosure, loss, or other compromise of
                      the Confidential Information.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                <h2 className="uppercase font-bold ml-4">OTHER TERMS.</h2>
                <ol className="list-decimal pl-12 [counter-reset:section]">
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">Ownership.</strong> All
                      Confidential Information remains the property of PICI or
                      PICI’s affiliated partners. No license or conveyance of
                      any patent, copyright, trade secret, trademark or other
                      intellectual property rights in the Confidential
                      Information is granted to you or implied under these
                      terms.
                    </p>
                  </li>
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">No Warranty.</strong> ALL
                      CONFIDENTIAL INFORMATION IS PROVIDED “AS IS”. PICI MAKES
                      NO WARRANTIES, EXPRESS, IMPLIED OR OTHERWISE, REGARDING
                      THE ACCURACY, COMPLETENESS OR PERFORMANCE OF CONFIDENTIAL
                      INFORMATION DISCLOSED. PICI shall not have any liability,
                      direct or indirect, to you for any damages that may arise
                      as a result of your access or use of Confidential
                      Information or any errors therein or omissions therefrom.
                    </p>
                  </li>
                  <li className="[counter-increment:section] marker:[content:counters(section,'.')]">
                    <p className="pl-2">
                      <strong className="pr-4">Equitable Relief.</strong> A
                      breach of these terms by you may cause irreparable damage
                      for which PICI will not be adequately compensated by
                      monetary damages. In the event of such a breach, or
                      threatened breach, PICI shall be entitled to seek
                      equitable relief from any court of competent jurisdiction,
                      whether preliminary or permanent.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfidentialityPage;
