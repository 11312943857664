const validTypes = {
  staticImages: ["image/jpeg", "image/png"],
  images: ["image/jpeg", "image/png", "image/gif", "image/svg+xml"],
  pdf: ["application/pdf"],
};

const fileTypeMessages = {
  staticImages: "File must be an static image",
  images: "File must be an image",
  pdf: "File must be a PDF",
};

const isImage = type => validTypes.images.includes(type);

const toValidFileTypes = file => {
  let mimeTypeText = file;
  if (file.includes("WORDPROCESSINGML")) {
    mimeTypeText = "DOCX";
  } else if (file.includes("PRESENTATIONML")) {
    mimeTypeText = "PPTX";
  } else if (file.includes("SVG")) {
    mimeTypeText = "SVG";
  } else if (file.includes("SPREADSHEETML")) {
    mimeTypeText = "XLSX";
  } else if (file.includes("MSWORD")) {
    mimeTypeText = "DOC";
  }
  return mimeTypeText;
};

const calculateSize = (size, magnitude = 0) => {
  if (size < 900) return [size, magnitude];
  return calculateSize(size / 1024, magnitude + 1);
};

const hasValidType = (fileType, acceptedTypes = "") => {
  if (!acceptedTypes) return true;
  return validTypes[acceptedTypes].some(type => type === fileType);
};

const hasValidSize = (fileSize, maxSize) => {
  const [size, unit] = calculateSize(fileSize);
  return !(size > maxSize && unit >= 2);
};

const readableMimeType = mimetype => {
  let mimeTypeText = mimetype
    .split("/")
    .pop()
    .toUpperCase();
  return toValidFileTypes(mimeTypeText);
};

const readableSize = sizeInBytes => {
  const units = ["Bytes", "KB", "MB", "GB"];
  const [size, magnitude] = calculateSize(sizeInBytes);
  return `${parseFloat(size).toFixed(2)} ${units[magnitude]}`;
};

export {
  toValidFileTypes,
  calculateSize,
  hasValidType,
  isImage,
  fileTypeMessages,
  hasValidSize,
  readableMimeType,
  readableSize,
};
