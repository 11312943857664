import React, { useEffect } from "react";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import RetreatsTable from "./RetreatsTable";

const fetchRetreats = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const baseUrl = "/api/retreats";
  const params = paramBuilder({
    fields: {
      retreats: [
        "title",
        "location",
        "startDate",
        "endDate",
        "published",
        "quarter",
        "coverImageUrl",
      ],
    },
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `${baseUrl}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

function RetreatsPage() {
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["admin_retreats"], fetchRetreats, {
    getNextPageParam: apiCanFetch,
  });
  const { data: retreats } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("retreat-section-navigation");
  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);
  return (
    <div className="bg-tint h-screen overflow-hidden">
      <AdminHeader
        title="Retreats"
        linkText="+ New Retreat"
        linkTo="/admin/retreats/new"
        description="Manage the needed information and media needed for displaying the Retreat Recaps and their presentations for the members"
      />
      <article
        id="retreat-section-navigation"
        className="px-6 overflow-x-hidden h-vh-60 bg-primary-lighter"
      >
        <RetreatsTable
          retreats={retreats}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
        />
      </article>
    </div>
  );
}

export default RetreatsPage;
