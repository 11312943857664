import clsx from "clsx";
import * as React from "react";
import { useEffect, useState } from "react";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik-latest";
import { isEqual } from "lodash";
import FullWideForm from "@/components/shared/FullWideForm";
import BasicDetailsForm from "./BasicDetailsForm";
import ProjectParticipantsForm from "./ProjectParticipantsForm";
import ProjectDetailsForm from "./ProjectDetailsForm";
import SalesforceForm from "./SalesforceForm";
import TimelineForm from "./TimelineForm";
import SkeletonForm from "./SkeletonForm";
import {
  INITIAL_VALUES,
  InitiativeSchema,
  mapInitiativeRequest,
  mapResponse,
} from "./defaults";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import useFlashMessage from "@/hooks/useFlashMessage";
import paramBuilder from "@/utils/network/paramBuilder";

const INITIATIVE_VIEW_URL = "/admin/initiatives?order=Date|desc&tab=all";
const PROJECTS_CENTER = "/projects?sort=-updatedAt";

const fetchInitiative = async id => {
  const baseUrl = `/api/projects/${id}`;
  const params = paramBuilder({
    include: [
      "focusAreas",
      "relatedProjects",
      "centers",
      "institutions",
      "projectMemberships",
      "projectExternalMemberships",
    ],
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

const fetchUsers = () => {
  const params = paramBuilder({
    fields: {
      users: ["fullName", "centerNameOrInstitution"],
    },
    preload: ["center", "institution"],
    exclude_links: true,
  });
  const url = `/api/users?${params}`;
  return denormalizedFetcher(url);
};

const fetchExternalUsers = () => {
  const params = paramBuilder({
    fields: {
      externalUsers: ["name", "email", "center"],
    },
    exclude_links: true,
  });
  const url = `/api/external-users?${params}`;
  return denormalizedFetcher(url);
};

function InitiativeForm({ editing = false }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { slug } = useParams();

  const title = editing ? "Edit Initiative" : "New Initiative";

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openArchivedModal, setOpenArhivedModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initiativeData, setInitiativeData] = useState<any>(
    INITIAL_VALUES.attributes,
  );

  const {
    data: users,
    isLoading: isUsersLoading,
  }: { data: any; isLoading: boolean } = useQuery(["users"], fetchUsers);
  const {
    data: externalUsers,
    isLoading: isExternalUsersLoading,
  }: { data: any; isLoading: boolean } = useQuery(
    ["external-users"],
    fetchExternalUsers,
  );

  useEffect(() => {
    if (editing) {
      if (!isUsersLoading && !isExternalUsersLoading) {
        fetchInitiative(slug).then(async res => {
          const initiativeData = await mapResponse(res, users, externalUsers);
          setInitiativeData(initiativeData.attributes);
          setIsLoading(false);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, [
    editing,
    isUsersLoading,
    isExternalUsersLoading,
    slug,
    users,
    externalUsers,
  ]);

  const showFlash = useFlashMessage({
    message: `Your initiative was ${editing ? "updated" : "added"}!`,
    errorMessage: `Your initiative could not be ${
      editing ? "updated" : "added"
    } due to an error.`,
  });

  const handleBackButtonPressed = () => {
    navigate(INITIATIVE_VIEW_URL);
  };

  const handleArchivedProject = async id => {
    fetch(`/api/projects/${id}/archived_project`, {
      method: "PATCH",
    }).then(() => navigate(PROJECTS_CENTER));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const inititativeRequest = mapInitiativeRequest(values, editing, slug);
    saveResource("initiatives", inititativeRequest)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => {
        showFlash({ success: false });
        setSubmitting(false);
      })
      .finally(() => {
        queryClient.invalidateQueries(["initiatives"]);
        queryClient.invalidateQueries(["users"]);
        queryClient.invalidateQueries(["external-users"]);
        handleBackButtonPressed();
      });
  };

  const onLeaveForm = hasNoChanges =>
    hasNoChanges ? navigate(INITIATIVE_VIEW_URL) : setOpenModal(true);

  return (
    <>
      {isLoading || isUsersLoading || isExternalUsersLoading ? (
        <SkeletonForm />
      ) : (
        <>
          <Formik
            initialValues={initiativeData}
            onSubmit={handleSubmit}
            validationSchema={InitiativeSchema}
          >
            {({
              values,
              isValid,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => {
              const hasNoChanges = isEqual(values, initiativeData);
              const submitDisabled = !isValid || isSubmitting || hasNoChanges;
              return (
                <FullWideForm>
                  <FullWideForm.header>
                    <button
                      type="button"
                      className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
                      onClick={() => onLeaveForm(hasNoChanges)}
                    >
                      <i className="ri-arrow-left-s-line ri-lg text-dark-75 mr-1"></i>
                      <span className="text-14 font-lato">All Initiatives</span>
                    </button>
                    <div className="admin-intro ellipsis mx-auto w-full flex max-w-[964px] py-4">
                      <h1 className="text-22 font-bold">{title}</h1>
                    </div>
                  </FullWideForm.header>
                  <Form>
                    <FullWideForm.body>
                      <BasicDetailsForm
                        values={values}
                        onChange={setFieldValue}
                      />
                      <ProjectDetailsForm
                        values={values}
                        onChange={setFieldValue}
                      />
                      <ProjectParticipantsForm
                        values={values}
                        onChange={setFieldValue}
                        onTouched={setFieldTouched}
                        users={users}
                      />
                      <SalesforceForm />
                      <TimelineForm onChange={setFieldValue} />
                    </FullWideForm.body>
                    <FullWideForm.footer>
                      <div className="mx-auto w-full max-w-[964px]">
                        <div className="ml-72">
                          <button
                            type="submit"
                            className={clsx(
                              "btn-white btn-md cursor-pointer mr-2",
                              {
                                "bg-primary text-white hover:border-primary-darker hover:bg-primary-darker active:bg-primary-darkest": isValid,
                                "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none": submitDisabled,
                              },
                            )}
                            disabled={submitDisabled}
                          >
                            {editing ? "Save Changes" : "Add Initiative"}
                          </button>
                          <button
                            type="button"
                            className="btn-md bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => onLeaveForm(hasNoChanges)}
                          >
                            Cancel
                          </button>

                          <button
                            type="button"
                            className="float-right btn-md bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpenArhivedModal(true)}
                          >
                            {values.archived ? "Unarchive" : "Archive"}
                          </button>
                        </div>
                      </div>
                    </FullWideForm.footer>
                  </Form>
                </FullWideForm>
              );
            }}
          </Formik>
          <ConfirmationModal
            isOpen={openModal}
            onAccept={() => navigate(INITIATIVE_VIEW_URL)}
            onClose={() => setOpenModal(false)}
            setOpen={() => setOpenModal(false)}
          />
          <ConfirmationModal
            isOpen={openArchivedModal}
            title={`${
              initiativeData.archived
                ? "Do you want to Unarchive this project?"
                : "Do you want to Archive this project?"
            }`}
            description={`${
              initiativeData.archived
                ? "By doing this, all the users of the Member Center will be able to see this project again."
                : "By doing this, this project will be only viewable on the Admin Center."
            }`}
            acceptLabel={`${
              initiativeData.archived ? "Yes, unarchive it" : "Yes, archive it"
            }`}
            onAccept={() => handleArchivedProject(slug)}
            onClose={() => setOpenArhivedModal(false)}
            setOpen={() => setOpenArhivedModal(false)}
          />
        </>
      )}
    </>
  );
}

export default InitiativeForm;
