import * as React from "react";
import { useState } from "react";
import AttributesTable from "@/components/PatternLibrary/Table";
import Select from "@/components/shared/Select";
import LiveEdit from "@/components/PatternLibrary/LiveEdit";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import { useFormik } from "formik-latest";
import * as Yup from "yup";

const background = {
  backgroundColor: "#F9FAFB",
  backgroundImage:
    "radial-gradient(#d3d3d3 0.5px, transparent 0.5px), radial-gradient(#d3d3d3 0.5px, #F9FAFB 0.5px)",
  backgroundSize: "12px 12px",
  backgroundPosition: "0 0, 6px 6px",
};

const ExampleSchema = Yup.object().shape({
  category: Yup.string().required("Category is required."),
});

const selectWithoutFormikCode = `
import Select from "@/components/shared/Select";

function Example({ options }) {
  const [selectedOption, setSelectedOption] = useState();

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-50 mx-auto">
        <Select
          selected={selectedOption}
          setSelected={setSelectedOption}
          options={options}
          placeholder="Select a category"
        />
      </div>
      <Select
        selected={selectedOption}
        setSelected={setSelectedOption}
        options={options}
        placeholder="Select a category"
      />
      <div className="w-50 mx-auto">
        <Select
          selected={selectedOption}
          setSelected={setSelectedOption}
          options={options}
          placeholder="Select a category"
          disabled
        />
      </div>
    </div>
  );
}
  
{/* Disclaimer this is not a live preview */}
`;

const selecteWithFormikCode = `
import { Formik, Form, Field } from "formik-latest";
import * as Yup from "yup";
import Select from "@/components/shared/Select";

const ExampleSchema = Yup.object().shape({
  category: Yup.string().required("Category is required."),
});

function SelectWithFormik({ options }) {
  const formik = useFormik({
    initialValues: { category: "" },
    validationSchema: ExampleSchema,
    onSubmit: () => {},
  });
  const { values, touched, errors, setFieldValue, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-50 mx-auto">
        <div className="flex flex-col">
          <label className="font-body-bold mb-1">Category</label>
          <Select
            selected={values.category}
            setSelected={value => setFieldValue("category", value)}
            options={options}
            errorMessage={touched.category ? errors.category : ""}
            placeholder="Select a category"
          />
        </div>
      </div>
      <button type="submit" className="mt-4 btn-md btn-primary">
        Submit
      </button>
    </form>
  );
}
  
{/* Disclaimer this is not a live preview */}
`;

const selectClassNames = [
  {
    Component: "Select",
    Props: "selected | setSelected | placeholder | errorMessage | className",
  },
];

function SelectWithoutFormik({ options }) {
  const [selectedOption, setSelectedOption] = useState();

  return (
    <div>
      <h3 className="font-h3 mb-2">Select without formik</h3>
      <div className="flex flex-col space-y-4 p-4" style={background}>
        <div className="w-50 mx-auto">
          <Select
            selected={selectedOption}
            setSelected={setSelectedOption}
            options={options}
            placeholder="Select a category"
          />
        </div>
        <Select
          selected={selectedOption}
          setSelected={setSelectedOption}
          options={options}
          placeholder="Select a category"
        />
        <div className="w-50 mx-auto">
          <Select
            selected={selectedOption}
            setSelected={setSelectedOption}
            options={options}
            placeholder="Select a category"
            disabled
          />
        </div>
      </div>

      <LiveEdit
        code={selectWithoutFormikCode}
        liveEditor={true}
        liveError={false}
        livePreview={false}
      />
    </div>
  );
}

function SelectWithFormik({ options }) {
  const formik = useFormik({
    initialValues: { category: "" },
    validationSchema: ExampleSchema,
    onSubmit: () => {},
  });
  const { values, touched, errors, setFieldValue, handleSubmit } = formik;

  return (
    <div>
      <h3 className="font-h3 mb-2">Select with formik</h3>
      <div className="flex flex-wrap justify-evenly p-4" style={background}>
        <form onSubmit={handleSubmit}>
          <div className="w-50 mx-auto">
            <div className="flex flex-col">
              <label className="font-body-bold mb-1">Category</label>
              <Select
                selected={values.category}
                setSelected={value => setFieldValue("category", value)}
                options={options}
                errorMessage={touched.category ? errors.category : ""}
                placeholder="Select a category"
              />
            </div>
          </div>
          <button type="submit" className="mt-4 btn-md btn-primary">
            Submit
          </button>
        </form>
      </div>
      <LiveEdit
        code={selecteWithFormikCode}
        liveEditor={true}
        liveError={false}
        livePreview={false}
      />
    </div>
  );
}

function SelectPage() {
  const { data: options } = useQuery(["inventions", "filters"], () =>
    fetcher("/api/inventions/filters"),
  );

  const categories = options?.applicationCategories;

  return (
    <div>
      <div>
        <h1 className="library-title">Select</h1>
        <p className="library-parraf">
          This Select component is going to be use across the application. You
          can find its implementation in some admin pages: Daily news feed,
          people, focus areas.
        </p>
      </div>

      <h2 className="library-subtitle">Attributes</h2>
      <p className="library-parraf">
        Currently we are using the following Attributes to manage PICI Select
        component:
      </p>
      <AttributesTable data={selectClassNames}></AttributesTable>
      <h2 className="library-subtitle">Demo</h2>
      <SelectWithoutFormik options={categories} />
      <SelectWithFormik options={categories} />
    </div>
  );
}

export default SelectPage;
