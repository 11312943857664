import * as React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import { humanize } from "@/utils/string";
import useCurrentUser from "@/hooks/useCurrentUser";
//import Timeline from "@/components/shared/Timeline";
//import Tooltip from "@/components/shared/Tooltip";
import AsideComponent from "@/components/shared/Aside";
//import PreviewText from "@/components/shared/PreviewText";

// const ReferenceMaterialItem = ({ referenceMaterial }) => {
//   const {
//     title,
//     materialType,
//     fileUrl,
//     linkUrl,
//   } = referenceMaterial.attributes;

//   const url = materialType == "file" ? fileUrl : linkUrl;

//   return (
//     <div className="flex items-center mb-3.5">
//       {materialType == "file" ? (
//         <i className="ri-file-line text-dark-50 ri-xl"></i>
//       ) : (
//         <i className="ri-links-line text-dark-50 ri-xl"></i>
//       )}
//       <a
//         href={url}
//         target="_blank"
//         className="link-primary font-body-bold pl-2.5 truncate"
//       >
//         {title}
//       </a>
//     </div>
//   );
// };

function Aside({ project }) {
  const currentUser = useCurrentUser();
  const {
    codename,
    identifier,
    trialStatus,
    updatedAt,
    salesforceId,
  } = project.attributes;
  const formattedUpdatedAt = format(parseISO(updatedAt), "MMMM dd, yyyy");
  const projectManagers = project.relationships.projectManagers.data;
  const members = project.relationships.members.data;
  // const referenceMaterials = project.relationships.referenceMaterials.data;
  const showPrivateSection =
    currentUser?.attributes.isStaff ||
    members.some(member => member.id === currentUser?.id);
  const truncatedIdentifier =
    identifier?.length > 8 ? `${identifier.slice(0, 8)}...` : identifier;

  return (
    <AsideComponent className="ml-0 mt-0 lg:mt-10 mb-7.5 lg:ml-7.5 lg:mb-0">
      <AsideComponent.Section>
        <AsideComponent.Content>
          {(identifier || codename) && (
            <AsideComponent.Title
              title={`${truncatedIdentifier}${identifier &&
                codename &&
                ": "}${codename}`}
              className="font-meta-bold"
            />
          )}
          <AsideComponent.Body className="mt-1 flex flex-col gap-[9px]">
            <p className="font-meta text-dark-75">
              Last updated {formattedUpdatedAt}
            </p>
            {trialStatus && (
              <span
                className={clsx(
                  "py-1 px-2.5 rounded-lg text-white font-label max-w-max",
                  "bg-secondary",
                )}
              >
                {humanize(trialStatus)}
              </span>
            )}
          </AsideComponent.Body>
        </AsideComponent.Content>
        {projectManagers.length > 0 && (
          <AsideComponent.Content>
            <AsideComponent.Title title="PICI Project Manager:" />
            <AsideComponent.Body className="mt-3.5 space-y-2">
              {projectManagers.map(manager => (
                <div key={manager.id} className="flex items-center">
                  <img
                    src={manager.links.avatar}
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="ml-3">
                    <Link to={manager.links.show} className="link">
                      <p className="font-meta-bold text-dark-100">
                        {manager.attributes.fullName}
                      </p>
                    </Link>
                    <p className="font-micro text-dark-75">
                      {manager.attributes.title}
                    </p>
                  </div>
                </div>
              ))}
            </AsideComponent.Body>
          </AsideComponent.Content>
        )}
      </AsideComponent.Section>
      {/* {timelineItems.length > 0 && (
          <AsideComponent.Content>
            <AsideComponent.Title title="Project Timeline:" />
            <AsideComponent.Body className="mt-3.5">
              <Timeline>
                {timelineItems.map((item, index) => (
                  <Timeline.Item
                    phase={item.title}
                    date={item.date}
                    notes={item.notes}
                    key={index}
                    index={index}
                  />
                ))}
              </Timeline>
            </AsideComponent.Body>
          </AsideComponent.Content>
        )} <AsideComponent.Content>
            <div className="flex items-center gap-2">
              <Tooltip>
                <Tooltip.Trigger className="h-5 w-5">
                  <i className="ri-lock-2-line ri-lg text-dark-50" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  This content is only viewable by staff and members of this
                  project team
                </Tooltip.Content>
              </Tooltip>
              <AsideComponent.Title title="Project Agreement" />
            </div>
            <AsideComponent.Body className="mt-3">
              {agreementUploadMetadata.byte_size > 0 ? (
                <a
                  href={agreementUploadUrl}
                  target="_blank"
                  className="text-primary font-meta flex items-start"
                >
                  <i className="ri-download-line mr-3" />
                  <span className="hover:underline">
                    {agreementUploadMetadata.filename}
                  </span>
                </a>
              ) : (
                <span className="py-1 px-2.5 rounded-lg text-white font-label bg-dark-50">
                  Not summited yet
                </span>
              )}
            </AsideComponent.Body>
          </AsideComponent.Content>
           {referenceMaterials.length > 0 && (
            <AsideComponent.Content>
              <AsideComponent.Title
                title="Reference Materials"
                className="ml-2"
              />
              <AsideComponent.Body className="mt-3 font-meta text-dark-75">
                {referenceMaterials.map(referenceMaterial => (
                  <ReferenceMaterialItem
                    {...{ referenceMaterial }}
                    key={referenceMaterial.id}
                  />
                ))}
              </AsideComponent.Body>
            </AsideComponent.Content>
          )}
          {notes && (
            <AsideComponent.Content>
              <div className="flex items-center gap-2">
                <Tooltip>
                  <Tooltip.Trigger className="h-5 w-5">
                    <i className="ri-lock-2-line ri-lg text-dark-50" />
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    This content is only viewable by staff and members of this
                    project team
                  </Tooltip.Content>
                </Tooltip>
                <AsideComponent.Title title="Notes" />
              </div>
              <AsideComponent.Body className="mt-3 font-meta text-dark-75">
                <PreviewText text={notes} />
              </AsideComponent.Body>
            </AsideComponent.Content>
          )}
          )} */}
    </AsideComponent>
  );
}

export default Aside;
