import * as React from "react";
import { Link } from "react-router-dom";
import Table from "@/components/shared/Table";

const MemberItem = ({ member }) => {
  const { fullName, name, centerNameOrInstitution, center } = member.attributes;
  const { show, avatar, centerShow } = member.links;

  return (
    <Table.Row>
      <Table.Cell className="pr-2">
        <div className="flex items-center">
          {show ? (
            <>
              <Link to={show} className="flex min-w-max">
                <img
                  src={avatar}
                  alt=""
                  className="h-10 w-10 rounded-full flex-shrink-0"
                />
              </Link>
              <Link to={show} className="ml-4 link truncate">
                <p className="truncate text-dark-100 font-body-bold">
                  {fullName}
                </p>
              </Link>
            </>
          ) : (
            <>
              <span className="flex min-w-max">
                <img
                  src={avatar}
                  alt=""
                  className="h-10 w-10 rounded-full flex-shrink-0"
                />
              </span>
              <span className="ml-4">
                <p className="truncate text-dark-100 font-body-bold">{name}</p>
              </span>
            </>
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        {centerShow ? (
          <Link to={member.links.centerShow} className="link">
            <p className="font-body text-dark-75">
              {centerNameOrInstitution || center}
            </p>
          </Link>
        ) : (
          <p className="font-body text-dark-75">
            {centerNameOrInstitution || center}
          </p>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

function MembersTab({ project }) {
  const members = project.relationships.members.data;
  const externalMembers = project.relationships.externalUserMembers.data;
  const allMembers = [...members, ...externalMembers];

  return (
    <div
      className="flex flex-col gap-10 overflow-auto"
      data-testid="members-table"
    >
      {allMembers.length > 0 && (
        <Table>
          <Table.Header>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {allMembers.map(member => (
              <MemberItem member={member} key={member.id} />
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

export default MembersTab;
