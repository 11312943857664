import * as React from "react";
import clsx from "clsx";
import { FlashType } from "./interfaces";

type FlashMessageProps = {
  className?: string;
  duration?: number;
  flash: FlashType;
  setFlash: (value: FlashType) => void;
};

function FlashMessage({
  className = "",
  duration = 5000,
  flash,
  setFlash,
}: FlashMessageProps) {
  const close = () => {
    setFlash({
      visible: false,
      success: true,
      message: "",
    });
  };
  setTimeout(() => {
    close();
  }, duration);

  return (
    <div
      className={clsx(
        "fixed bottom-5 left-4 right-4 sm:left-5 sm:right-auto sm:w-96 rounded text-white z-100",
        flash?.success ? "bg-secondary" : "bg-error",
        className,
      )}
    >
      <i
        data-testid="close-flash"
        className="ri-close-line text-right h-4 w-4 absolute top-2 right-2 cursor-pointer"
        onClick={() => close()}
      />
      <div className="flex py-6 px-6">
        <i className="ri-check-line" />
        <div className="pl-3 text-15 font-lato font-semibold">
          {flash?.message}
        </div>
      </div>
    </div>
  );
}

export default FlashMessage;
