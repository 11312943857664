import React from "react";
import { Form, Formik } from "formik-latest";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";
import * as Yup from "yup";

const resourceLinkSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Resource Name is required."),
  link: Yup.string()
    .trim()
    .required("Link is required")
    .url("Link needs to be a valid URL. Ex: https:// http://"),
});

function ResourceLinkFormModal({ isOpenModal, setOpenModal, handleSubmit }) {
  return (
    <ConfirmationModal
      title="New Resource Link"
      description={
        isOpenModal && (
          <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
            validationSchema={resourceLinkSchema}
          >
            {() => {
              return (
                <Form id="formResourceLink">
                  <FormBlock
                    label="Resource Name"
                    name="name"
                    className="mb-3"
                    labelClassName="font-body-bold"
                  >
                    <Input styleAs="small" className="w-full" />
                  </FormBlock>
                  <FormBlock
                    label="Link"
                    name="link"
                    className="mb-6"
                    labelClassName="font-body-bold"
                  >
                    <Input styleAs="small" className="w-full" />
                  </FormBlock>
                </Form>
              );
            }}
          </Formik>
        )
      }
      isOpen={isOpenModal}
      closeLabel="Cancel"
      className="w-125"
      onClose={() => setOpenModal(false)}
      setOpen={() => setOpenModal(false)}
      acceptLabel="Create Resource Link"
      typeAcceptButton="submit"
      formIdAcceptButton="formResourceLink"
    />
  );
}

export default ResourceLinkFormModal;
