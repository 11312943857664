import * as React from "react";
import clsx from "clsx";

type SuggestionsListProps = {
  filteredSuggestions: any[];
  activeSuggestionIndex: number;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const SuggestionsList = ({
  filteredSuggestions,
  activeSuggestionIndex,
  onClick,
}: SuggestionsListProps) => {
  return filteredSuggestions.length > 0 ? (
    <ul className="suggestions absolute rounded border-lines bg-white shadow w-full z-200 h-32 overflow-auto">
      {filteredSuggestions.map((suggestion, index) => {
        return (
          <li
            className={clsx(
              "hover:bg-gray-100 hover:text-indigo-700 cursor-pointer p-2",
              { "bg-indigo-700 text-white": index === activeSuggestionIndex },
            )}
            key={index}
            onClick={() => onClick(suggestion)}
          >
            {suggestion.name}
          </li>
        );
      })}
    </ul>
  ) : (
    <div className="suggestions absolute rounded border-lines bg-white shadow w-full z-200 h-32 overflow-auto">
      <p className="p-5 font-body">No results found. Try a different search</p>
    </div>
  );
};

export default SuggestionsList;
