import React from "react";
import clsx from "clsx";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import RichText from "@/components/shared/RichText";
import ButtonIcon from "@/components/shared/ButtonIcon";
import {
  KeySection,
  SectionMode,
} from "@/components/shared/Retreat/Article/defaults";
import useViewMode from "./useViewMode";

function TextSectionEditor({ values, section, onChange, className = "" }) {
  const {
    isEditMode,
    isPreviewMode,
    isNotViewMode,
    isViewOrPreview,
  } = useViewMode(section.viewMode);

  const handleDeleteSection = () => {
    const updatedSections = values.articleSections
      .filter(sectionItem => sectionItem.position !== section.position)
      .map((sectionItem, index) => ({ ...sectionItem, position: index }));
    onChange("articleSections", updatedSections);
  };
  const handlePreviewMode = () => {
    onChange(
      `articleSections[${section.position}].viewMode`,
      SectionMode.Preview,
    );
  };
  const handleEditMode = () => {
    onChange(`articleSections[${section.position}].viewMode`, SectionMode.Edit);
  };
  return (
    <div
      className={clsx(
        "relative group border-2 border-transparent",
        {
          "bg-tint border rounded-lg mb-3": isEditMode,
          "hover:border-2 hover:border-zinc-200 hover:rounded-lg hover:border-dashed p-2 mb-3": isPreviewMode,
        },
        className,
      )}
    >
      {isNotViewMode && (
        <div
          className={clsx(
            "absolute bg-slate-700 text-white right-1/2 -top-3",
            "px-4 rounded-lg hidden group-hover:block",
          )}
        >
          {section.keySection}
        </div>
      )}
      <div
        className={clsx("absolute right-4 top-1 group-hover:block", {
          hidden: isPreviewMode,
          block: isEditMode,
        })}
      >
        {isPreviewMode && (
          <ButtonIcon
            title="Set Edit mode"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-pencil-line"
            onClick={handleEditMode}
          />
        )}
        {isEditMode && (
          <ButtonIcon
            title="Set Preview mode"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-eye-line"
            onClick={handlePreviewMode}
          />
        )}
        {isNotViewMode && (
          <ButtonIcon
            title="Delete Section"
            className="btn-white btn-md h-8 px-2"
            iconClassName="ri-delete-bin-line"
            onClick={handleDeleteSection}
          />
        )}
      </div>
      {isEditMode && (
        <div className="px-4 py-3">
          <FormBlock
            label="Title"
            name={`articleSections[${section.position}].content.title`}
            className="w-full mb-6"
            labelClassName="font-body-bold"
            optional
          >
            <Input styleAs="small" />
          </FormBlock>
          <RichTextEditor
            value={section.content.description}
            onChange={value =>
              onChange(
                `articleSections[${section.position}].content.description`,
                value,
              )
            }
          />
        </div>
      )}
      {isViewOrPreview && (
        <div>
          {section.content.title && (
            <h1
              className={clsx("text-dark-100 font-bold pb-3", {
                "font-h3": section.keySection === KeySection.SmallHeader,
                "font-h2": section.keySection === KeySection.LargeHeader,
              })}
            >
              {section.content.title}
            </h1>
          )}
          <RichText
            className={clsx("text-dark-75 break-words", {
              "text-15 lg:font-body-large":
                section.keySection !== KeySection.ReferenceText,
              "font-micro pl-2":
                section.keySection === KeySection.ReferenceText,
            })}
            value={section.content.description}
          />
        </div>
      )}
    </div>
  );
}

export default TextSectionEditor;
