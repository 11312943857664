import * as React from "react";
import clsx from "clsx";

interface Slide {
  url: string;
  caption?: string;
  linkTo?: string;
}

type Slideshow = {
  slides: Slide[];
  classNames?: string;
  duration?: string;
  imgClassName?: string;
};

const DELAY = 2500;

function SlideShow({
  classNames,
  slides,
  duration = "300",
  imgClassName = "",
}: Slideshow) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef({});

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex(prevIndex =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1,
        ),
      DELAY,
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className={clsx("relative mx-auto overflow-hidden", classNames)}>
      <div
        className={`whitespace-nowrap ease-in duration-${duration}  h-auto`}
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {slides.map((slide, index) => (
          <div className="inline-flex h-auto w-full" key={index}>
            <img
              src={slide.url}
              className={clsx(
                "sm:object-cover object-center w-full aspect-[2/1] overflow-hidden",
                imgClassName,
              )}
            />
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() =>
          setIndex(prevIndex => (prevIndex === 0 ? 0 : prevIndex - 1))
        }
        className="text-white text-4xl focus:outline-none absolute top-1/2"
      >
        <i className="ri-arrow-left-s-line" />
      </button>
      <button
        type="button"
        onClick={() =>
          setIndex(() =>
            index === slides.length - 1 ? slides.length - 1 : index + 1,
          )
        }
        className="text-white text-4xl focus:outline-none absolute top-1/2 right-px"
      >
        <i className="ri-arrow-right-s-line" />
      </button>

      <div className="relative bottom-[25px] flex justify-center gap-2">
        {slides.map((_, idx) => (
          <button
            key={idx}
            className={clsx(
              "w-[25px] h-[3px] rounded-lg bg-white",
              index === idx ? " opacity-80" : " opacity-50",
            )}
            onClick={() => {
              setIndex(idx);
            }}
          ></button>
        ))}
      </div>
    </div>
  );
}

export default SlideShow;
