import * as React from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import clsx from "clsx";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import useFlashMessage from "@/hooks/useFlashMessage";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import Breadcrumb from "@/components/shared/Breadcrumb";
import FormBlock from "@/components/shared/FormBlock";
import TextArea from "@/components/shared/TextArea";
import Input from "@/components/shared/Input";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";

const publicationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Plucation title is required."),
  publicationYear: Yup.number()
    .typeError("Please enter a valid number")
    .min(1800, "A valid year is required (e.g. 2017).")
    .max(2500, "A valid year is required (e.g. 2017).")
    .nullable(),
  journal: Yup.string()
    .trim()
    .nullable(),
  authors: Yup.string()
    .trim()
    .nullable(),
  link: Yup.string()
    .trim()
    .url("Link needs to be a valid URL. Ex: https:// http://")
    .nullable(),
});

const fetchPublication = ({ queryKey }) => {
  const [_key, id] = queryKey;
  const url = `/api/publications/${id}`;
  return denormalizedFetcher(url);
};

const defaultValue = {
  title: "",
  authors: "",
  journal: "",
  link: "",
  publicationYear: null,
  userId: 0,
};

function ResearchDocumentForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  const isEdit = id ? true : false;
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data: userPublication }: { data: any } = useQuery(
    ["userPublication", id],
    fetchPublication,
    { enabled: isEdit },
  );

  const handleBackButtonPressed = isDirty => {
    isDirty ? setModalOpen(true) : navigate("/settings/publication-history");
  };

  const showFlash = useFlashMessage({
    message: `The publication has been ${isEdit ? "updated" : "added"}.`,
    errorMessage: `The publication couldn't be ${
      isEdit ? "updated" : "added"
    } due to an error.`,
  });
  const showDeleteFlash = useFlashMessage({
    message: "The publication has been removed.",
    errorMessage: "The publication couldn't be removed due to an error.",
  });

  const mapRequest = values => {
    const attributes = {
      title: values.title,
      authors: values.authors,
      journal: values.journal,
      link: values.link,
      publicationYear: values.publicationYear,
      userId: userPublication?.data.attributes.userId || currentUser.id,
    };

    return {
      data: {
        id: id,
        type: "publications",
        attributes,
      },
    };
  };

  const onSubmit = (values, { setSubmitting }) => {
    const request = mapRequest(values);

    saveResource("publications", request)
      .then(res => {
        showFlash({ success: res.ok });
        navigate("/settings/publication-history");
      })
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        queryClient.invalidateQueries(["userPublication"]);
      });
  };

  const handleDelete = () => {
    fetch(`/api/publications/${id}`, {
      method: "DELETE",
    })
      .then(res => {
        showDeleteFlash({ success: res.ok });
        navigate("/settings/publication-history");
      })
      .catch(() => showDeleteFlash({ success: false }));
  };

  return (
    <div
      className="bg-white flex flex-col"
      style={{ height: "calc(100vh - 64px)" }}
    >
      <Formik
        initialValues={userPublication?.data.attributes || defaultValue}
        onSubmit={onSubmit}
        validationSchema={publicationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, ...props }) => (
          <Form>
            <div className="margin-safe">
              <Breadcrumb
                onClick={() => handleBackButtonPressed(props.dirty)}
                text="Back to Publication History"
              />
            </div>
            <div className="overflow-y-auto h-full">
              <div className="margin-safe pt-7.5 sm:pt-10 pb-20">
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="max-w-3xl sm:px-12 w-full flex flex-col gap-5 md:gap-7.5">
                    <div className="border-b border-lines pb-5 md:pb-10">
                      <h1 className="font-h1 text-dark-100 mb-2.5">
                        {isEdit ? "Edit" : "New"} Publication
                      </h1>
                      <p className="font-body text-dark-75">
                        {isEdit
                          ? "Make Changes to published research that you have authored."
                          : "Please add content that is relevant to the network - avoid general interest or unrelated publications."}
                      </p>
                    </div>

                    <div className="space-y-5 sm:space-y-7.5 mt-2.5 md:mt-0">
                      <FormBlock
                        label="Publication Title"
                        name="title"
                        labelClassName="font-body-bold"
                      >
                        <TextArea rows={4} className="text-dark-100" />
                      </FormBlock>

                      <div>
                        <FormBlock
                          label="Journal or Publication Name"
                          name="journal"
                          optional
                          labelClassName="font-body-bold"
                        >
                          <Input />
                        </FormBlock>
                      </div>
                      <div>
                        <FormBlock
                          label="Year Published"
                          name="publicationYear"
                          optional
                          labelClassName="font-body-bold"
                        >
                          <Input />
                        </FormBlock>
                      </div>
                      <div>
                        <FormBlock
                          label="Co-authors"
                          name="authors"
                          optional
                          labelClassName="font-body-bold"
                        >
                          <Input />
                        </FormBlock>
                      </div>
                      <div>
                        <FormBlock
                          label="Link"
                          name="link"
                          optional
                          labelClassName="font-body-bold"
                        >
                          <Input />
                        </FormBlock>
                      </div>
                      <div className="flex flex-col md:flex-row flex-wrap gap-2.5 md:gap-3 w-full">
                        <button
                          type="submit"
                          disabled={
                            !props.dirty || !props.isValid || props.isSubmitting
                          }
                          className={clsx(
                            "btn-lg btn-primary w-full md:w-auto",
                            (!props.dirty ||
                              !props.isValid ||
                              props.isSubmitting) &&
                              "bg-dark-50 hover:bg-dark-50 cursor-default",
                          )}
                        >
                          {!isEdit ? "Add Publication" : "Save Changes"}
                        </button>
                        <button
                          className="btn-lg btn-white w-full md:w-auto"
                          type="button"
                          onClick={() => handleBackButtonPressed(props.dirty)}
                        >
                          Cancel
                        </button>
                        {isEdit && (
                          <button
                            type="button"
                            className="btn-lg btn-white w-full md:w-auto md:ml-auto"
                            onClick={() => {
                              setDeleteModalOpen(true);
                            }}
                          >
                            Delete Publication
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        isOpen={modalOpen}
        onAccept={() => navigate("/settings/publication-history")}
        onClose={() => setModalOpen(false)}
        setOpen={() => setModalOpen(false)}
      />
      <DeleteConfirmationModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onAccept={handleDelete}
        resource="publication"
        message="Members will no longer be able to see this publication once it's deleted."
        className="w-105"
      />
    </div>
  );
}

export default ResearchDocumentForm;
