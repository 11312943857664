import * as React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import projectPhotoPlaceholder2x from "@/images/project-photo-placeholder@2x.png";
import projectPhotoPlaceholder3x from "@/images/project-photo-placeholder@3x.png";
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

type featured = {
  id: string;
  attributes: {
    identifier: string;
    codename: string;
    name: string;
  };
  type: string;
  relationships: {
    focusAreas: any;
  };
  links: {
    slug: string;
    bannerImage: string;
  };
};

const Slide = ({
  item,
  unique = false,
}: {
  item: featured;
  unique?: boolean;
}) => {
  const { identifier, codename, name } = item.attributes;
  const { focusAreas } = item.relationships;
  const { slug, bannerImage } = item.links;
  const truncatedIdentifier =
    identifier?.length > 8 ? `${identifier.slice(0, 8)}...` : identifier;

  return (
    <div
      className={clsx(
        "relative h-full w-full bg-dark-100 rounded-lg overflow-hidden cursor-default",
        !unique ? "mr-4" : "",
      )}
    >
      <div
        className={clsx(
          "h-full transition-opacity duration-300",
          !unique ? "opacity-20" : "",
        )}
      >
        <div className="absolute inset-0 h-full">
          {bannerImage ? (
            <img
              src={bannerImage}
              className="object-cover w-full h-full min-h-full min-w-full"
            />
          ) : (
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={projectPhotoPlaceholder3x}
              />
              <img
                src={projectPhotoPlaceholder2x}
                className="object-fit h-full min-h-full min-w-full"
              />
            </picture>
          )}
          <div
            className={clsx(
              "absolute inset-0",
              bannerImage && "linear-gradient",
            )}
            aria-hidden="true"
          />
        </div>
        <div className="relative p-5 lg:p-10 text-white flex flex-col h-full">
          <div className="mb-auto">
            <span className="inline-flex items-center px-2.5 py-1 rounded-full uppercase font-label bg-dark-100 text-white">
              Featured
            </span>
          </div>
          <div className="mt-9 lg:mt-24">
            <div className="font-label flex items-center gap-2.5 mt-auto mb-2">
              <span>{truncatedIdentifier}</span>
              {codename && (
                <span className="bg-white w-1.5 h-1.5 rounded-full block" />
              )}
              <span>{codename}</span>
            </div>
            <Link to={slug} className="link">
              <h3 className="font-h2 mb-4 lg:font-h1">{name}</h3>
            </Link>
            <FocusAreaTags focusAreas={focusAreas.data} theme="dark" />
          </div>
        </div>
      </div>
    </div>
  );
};

function Slider({ featuredList }) {
  return featuredList.length > 1 ? (
    <div className="-mr-4 sm:-mr-8 xl:mr-0 relative rounded-tl-lg rounded-bl-lg overflow-hidden">
      <Carousel
        plugins={[
          "infinite",
          "clickToChange",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 1.15,
            },
          },
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: <></>,
              arrowRight: (
                <button className="hidden lg:block absolute ri-arrow-right-s-line right-5 xl:right-0 inset-y-0 text-5xl text-white z-20 font-bold m-auto w-1/12 md:w-32 xl:w-36 h-full" />
              ),
              addArrowClickHandler: true,
            },
          },
        ]}
      >
        {featuredList.map((featuredItem: featured) => (
          <Slide item={featuredItem} key={featuredItem.id} />
        ))}
      </Carousel>
    </div>
  ) : (
    <Slide item={featuredList[0]} unique={true} />
  );
}

export default Slider;
