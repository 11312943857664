import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow, parseISO } from "date-fns";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { Link } from "react-router-dom";

const fetchPeriodicUpdates = ({ queryKey }) => {
  const [key, projectId] = queryKey;
  const baseUrl = `/api/${key}`;
  const params = paramBuilder({
    fields: {
      periodicUpdates: ["highlights", "createdAt", "subject"],
    },
    sort: ["-createdAt"],
    filter: { projectId: [projectId], published: [true] },
  });

  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function UpdateSkeleton() {
  return (
    <div className="pt-6 first:pt-0 animate-pulse">
      <div className="mb-4">
        <div className="flex flex-wrap justify-between">
          <div className="flex">
            <div className="h-6 w-6 rounded-full bg-dark-25" />
            <div className="ml-3">
              <div className="h-4 w-48 rounded-full bg-dark-25 mb-1" />
              <div className="h-4 w-48 rounded-full bg-dark-25" />
            </div>
          </div>
          <div className="h-4 w-1/6 rounded-full bg-dark-25" />
        </div>
      </div>
      <div className="h-5 w-2/3 sm:w-1/3 rounded-full bg-dark-25 mb-2.5" />
      <div className="h-4 w-full sm:w-1/2 rounded-full bg-dark-25 mb-1" />
      <div className="h-4 w-full sm:w-1/2 rounded-full bg-dark-25 mb-1" />
      <div className="h-4 w-full sm:w-1/2 rounded-full bg-dark-25" />
    </div>
  );
}

function UpdateItem({ update, manager }) {
  const { subject, highlights, createdAt } = update.attributes;
  const formattedCreatedAt = formatDistanceToNow(parseISO(createdAt), {
    addSuffix: true,
  });

  return (
    <div className="pt-6 first:pt-0" data-testid={`update-item-${update.id}`}>
      <div className="flex">
        <img src={manager.links.avatar} className="h-6 w-6 rounded-full" />
        <div className="ml-3 w-full">
          <div className="flex flex-wrap justify-between">
            <div>
              <Link
                to={manager.links.show}
                className="link font-body-bold text-dark-100"
              >
                {manager.attributes.fullName}
              </Link>
              {manager.attributes.title && (
                <p className="font-meta text-dark-75 my-1">
                  {manager.attributes.title}
                </p>
              )}
            </div>
            <p className="font-body text-dark-75">{formattedCreatedAt}</p>
          </div>
          <h3 className="font-h3 mt-[19px] mb-2.5">
            <Link to={`updates/${update.id}`} className="link-primary">
              {subject}
            </Link>
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: highlights }}
            className="text-dark-75"
          />
        </div>
      </div>
    </div>
  );
}

function UpdatesTab({ project }) {
  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery(
    ["periodic-updates", project.id],
    fetchPeriodicUpdates,
  );
  const updates = data?.data;

  const projectManagers = project.relationships.projectManagers.data;
  const manager = projectManagers.length > 0 ? projectManagers[0] : null;

  return (
    <div className="divide-y divide-lines space-y-6">
      {isLoading && [1, 2, 3, 4, 5].map(x => <UpdateSkeleton key={x} />)}
      {!isLoading &&
        updates.map(update => (
          <UpdateItem key={update.id} update={update} manager={manager} />
        ))}
    </div>
  );
}

export default UpdatesTab;
