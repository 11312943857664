import * as React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import NewsItem from "@/components/shared/NewsItem";

function Skeleton() {
  return (
    <section>
      <div className="mb-5 w-1/3 h-7.5 bg-dark-50 rounded-full animate-pulse" />
      <div className="flex flex-col justify-between gap-5">
        {[1, 2, 3].map(x => (
          <div
            key={x}
            className="h-28 flex-1 bg-dark-100 rounded-lg animate-pulse"
          >
            <div className="opacity-20 flex flex-col gap-1 p-6">
              <div className="h-6 w-2/3 bg-dark-25 rounded-full"></div>
              <div className="h-6 w-1/3 bg-dark-25 rounded-full"></div>
              <div className="h-9 w-4/5 bg-dark-25 rounded-full"></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const NewsItemsList = ({ newsFeed }) => {
  const newsItems = newsFeed.attributes.newsItemsList.filter(
    newsItem => newsItem.categoryId !== null,
  );

  return newsItems
    .slice(0, 3)
    .map(news => (
      <NewsItem
        key={news.id}
        news={news}
        parentSlug={newsFeed.links.slug}
        prev={true}
      />
    ));
};

function NewsFeedPreview({ isLoading, newsFeed }) {
  const firstNews = newsFeed?.data;

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <section className="third-step">
          {!firstNews?.attributes.publishedAt && (
            <p className="bg-primary text-white text-center m-0 py-5.5 px-4 font-meta-bold">
              Preview Only: Daily News Feed Digest for{" "}
              {format(new Date(), "MMMM dd, YYYY")}
            </p>
          )}
          <article className="w-full font-lato">
            <header>
              <h1 className="text-dark-100 font-h3 lg:font-h2 flex items-center pb-5 border-b border-dark-25">
                {firstNews?.attributes.title}
              </h1>
            </header>
            <main className="divide-y divide-dark-25">
              <NewsItemsList newsFeed={firstNews} />
            </main>
            <footer className="w-full mt-6 flex justify-center">
              <Link
                to={firstNews?.links.slug}
                className="w-full btn btn-md btn-white"
              >
                View More
              </Link>
            </footer>
          </article>
        </section>
      )}
    </>
  );
}

export default NewsFeedPreview;
