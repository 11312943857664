import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { Formik, Form } from "formik";
import { saveResource } from "@/utils/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import clsx from "clsx";
import Checkbox from "@/components/shared/Checkbox";
import Select from "@/components/shared/Select";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";

const CommentSchema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .max(1500, "You have reached the character limit"),
});

const SidewideBannerActions = ({
  isValid,
  isSubmitting,
  cancelButtonRef,
  setOpen,
}) => {
  return (
    <div className="bg-gray-50 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense px-7 py-4">
      <button
        type="submit"
        disabled={!isValid || isSubmitting}
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
      >
        Update Banner
      </button>
      <button
        type="button"
        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
        onClick={() => setOpen(false)}
        ref={cancelButtonRef}
      >
        No, Cancel
      </button>
    </div>
  );
};

const SidewideBannerToggle = ({ enabled, setFieldValue }) => {
  return (
    <Switch
      checked={enabled}
      onChange={() => setFieldValue("active", !enabled)}
      className={clsx(
        enabled ? "bg-indigo-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={clsx(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
        )}
      >
        <span
          className={clsx(
            enabled
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-gray-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={clsx(
            enabled
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
          )}
          aria-hidden="true"
        >
          <svg
            className="h-3 w-3 text-indigo-600"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
};

function SitewideBanner({ open, setOpen, banner }) {
  const cancelButtonRef = useRef(null);

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      // Query Invalidations
      queryClient.invalidateQueries(["banner"]);
    },
  });

  const onSubmit = (values, { setSubmitting }) => {
    const request = {
      data: {
        id: "1",
        type: "banners",
        attributes: {
          ...values,
        },
      },
    };
    mutateAsync(saveResource("banners", request)).then(() => {
      setSubmitting(false);
      setOpen(false);
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-7 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-xl leading-6 font-medium text-gray-900"
                    >
                      Sitewide Banner
                    </Dialog.Title>
                    <div className="mt-6">
                      <p className="text-sm text-gray-500">
                        Staff members may create sitewide banners to be seen by
                        PICI members. These banner messages can be turned on for
                        a specific period of time or indefinitely.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{
                  active: banner?.attributes.active,
                  content: banner?.attributes.content,
                  isRepetitive: banner?.attributes.isRepetitive ?? false,
                  repetitionType: banner?.attributes.repetitionType ?? "7_days",
                }}
                enableReinitialize={true}
                validationSchema={CommentSchema}
                onSubmit={onSubmit}
              >
                {({ isValid, isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <div className="px-7">
                      <SidewideBannerToggle
                        enabled={values.active}
                        setFieldValue={setFieldValue}
                      />
                      <RichTextEditor
                        value={values.content}
                        onChange={value => setFieldValue("content", value)}
                        placeholder="Type here..."
                      />
                    </div>
                    <div className="px-7 mt-4 flex flex-wrap gap-5 items-center">
                      <Checkbox
                        className="max-w-max"
                        checked={values.isRepetitive}
                        onChange={() => {
                          setFieldValue("isRepetitive", !values.isRepetitive);
                        }}
                      >
                        Is repetitive?
                      </Checkbox>
                      {values.isRepetitive && (
                        <Select
                          selected={values.repetitionType}
                          setSelected={value => {
                            setFieldValue("repetitionType", value);
                          }}
                          options={[
                            { id: "daily", name: "Daily" },
                            { id: "7_days", name: "Weekly" },
                            { id: "monthly", name: "Monthly" },
                          ]}
                          className="font-meta-bold h-auto py-0.25"
                          classNameContainer={clsx(
                            "h-full flex flex-col justify-center",
                          )}
                        />
                      )}
                    </div>
                    <SidewideBannerActions
                      isValid={isValid}
                      isSubmitting={isSubmitting}
                      cancelButtonRef={cancelButtonRef}
                      setOpen={setOpen}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SitewideBanner;
