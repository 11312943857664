import * as Sentry from "@sentry/react";

function warn(message: any) {
  if (console.warn) {
    console.warn(message);
  }
}

function setupSentry() {
  const dsn = process.env.SENTRY_DSN;
  if (dsn !== null && dsn !== undefined && dsn !== "") {
    try {
      Sentry.init({ dsn });
    } catch (error) {
      warn(error);
    }
  }
}

export default setupSentry;
