import * as React from "react";
import { useState, useCallback } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik-latest";
import { saveResource } from "@/utils/api";
import useFlashMessage from "@/hooks/useFlashMessage";
import SlideOver from "@/components/shared/SlideOver/SlideOver";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import Input from "@/components/shared/Input";
import TextArea from "@/components/shared/TextArea";
import FormBlock from "@/components/shared/FormBlock";
import RadioButtonGroup from "@/components/shared/RadioButtonGroup";
import Select from "@/components/shared/Select";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import CenterOrInstitutionInput from "./CenterOrInstitutionInput/CenterOrInstitutionInput";
import { requestValidation } from "@/utils/requestValidation";

const STAFF_ROLES = ["Employee", "PiciAdmin"];
const PAPER_RELATED_ROLES = [
  "InvestigatorProject",
  "InvestigatorEMR",
  "InvestigatorFull",
  "BoardMember",
];
const INVESTIGATOR_ROLES = [
  "InvestigatorProject",
  "InvestigatorEMR",
  "InvestigatorFull",
];

const INVESTIGATOR_TYPE = ["InvestigatorProject", "InvestigatorEMR"];

const booleanOptions = [
  { id: true, name: "Yes" },
  { id: false, name: "No" },
];

const UserSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .trim()
      .required("First name is required."),
    lastName: Yup.string()
      .trim()
      .required("Last name is required."),
    email: Yup.string()
      .email("Please enter a valid e-mail.")
      .trim()
      .required("Email Address is required.")
      .test(
        "valid-email",
        "This email is already being used. Please enter a different email address.",
        function(value) {
          return requestValidation("users", "email", "unique", value);
        },
      ),
    title: Yup.string()
      .trim()
      .required("Title is required."),
    bio: Yup.string()
      .trim()
      .required("Bio is required."),
    type: Yup.string().required("Role is required."),
    center: Yup.string()
      .when(["type", "institution", "newInstitution"], {
        is: (type, institution, newInstitution) =>
          !STAFF_ROLES.includes(type) &&
          institution == null &&
          newInstitution == null,
        then: Yup.string().required("Center or Institution is required."),
      })
      .nullable(),
    institution: Yup.string()
      .when(["type", "center", "newInstitution"], {
        is: (type, center, newInstitution) =>
          !STAFF_ROLES.includes(type) &&
          center == null &&
          newInstitution == null,
        then: Yup.string().required("Center or Institution is required."),
      })
      .nullable(),
    newInstitution: Yup.string()
      .when(["type", "center", "institution"], {
        is: (type, center, institution) =>
          !STAFF_ROLES.includes(type) && center == null && institution == null,
        then: Yup.string().required("Center or Institution is required."),
      })
      .nullable(),
    project_id: Yup.string().nullable(),
    googleScholarUrl: Yup.string()
      .trim()
      .matches(
        /^https?:\/\/scholar\.google\.com\/citations\?(\w+=[\w\-]+&)*user=[\w\-]+(&\w+=[\w\-]+)*$/,
        "Enter a valid Google Scholar profile URL.",
      )
      .nullable(),
  },
  [
    ["institution", "newInstitution"],
    ["center", "newInstitution"],
    ["center", "institution"],
  ],
);

const mapRequest = item => {
  const request = {
    data: {
      type: "users",
      attributes: item,
    },
  };
  return request;
};
const mapCurrentItems = {
  firstName: "",
  lastName: "",
  email: "",
  title: "",
  type: "",
  bio: "",
  center: null,
  project_id: null,
  paperworkRequired: true,
  participationAgreement: null,
  participationAgreementMetadata: null,
  participationAgreementUrl: null,
  ipDisclosure: null,
  ipDisclosureMetadata: null,
  ipDisclosureUrl: null,
  hhmiInvestigator: false,
  focusAreas: [],
  googleScholarUrl: "",
  avatar: null,
  avatarMetadata: null,
  avatarUrl: null,
  notes: "",
  legalTeam: false,
};

function UserForm({
  open,
  setOpen,
  selectData,
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
  selectData: {};
}) {
  const { roles, projects, centers, focusAreas, institutions } = selectData;
  const [selectedRole, setSelectedRole] = useState(null);
  const queryClient = useQueryClient();

  const showFlash = useFlashMessage({
    message: `A new user was added!`,
    errorMessage: `Your user could not be added due to an error.`,
  });

  const close = useCallback(() => {
    setSelectedRole(null);
    setOpen(false);
  }, [setOpen]);

  const createContent = item => {
    const request = mapRequest(item);
    delete request.data.attributes["fileMetadata"];
    delete request.data.attributes["fileUrl"];
    delete request.data.attributes["participationAgreementMetadata"];
    delete request.data.attributes["participationAgreementUrl"];
    delete request.data.attributes["ipDisclosureMetadata"];
    delete request.data.attributes["ipDisclosureUrl"];
    delete request.data.attributes["avatarMetadata"];
    delete request.data.attributes["avatarUrl"];
    saveResource("users", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        close();
        queryClient.invalidateQueries(["admin_people"]);
      });
  };
  return (
    <SlideOver {...{ open, setOpen }}>
      <Formik
        initialValues={mapCurrentItems}
        onSubmit={createContent}
        validationSchema={UserSchema}
      >
        {({
          values,
          setFieldValue,
          dirty,
          isValid,
          isSubmitting,
          ...props
        }) => {
          const disableSubmit = !dirty || !isValid || isSubmitting;
          return (
            <Form>
              <SlideOver.Header>
                <span className="text-dark-100 font-medium">Add Account</span>
              </SlideOver.Header>

              <SlideOver.Body className="!px-0 space-y-6">
                <p className="font-meta text-dark-75 px-8">
                  Fill in the new member's details below. Account owners will be
                  able to view and contribute content to the PICI Network.
                </p>
                <div>
                  <p className="font-label bg-lines mb-6 p-1.5 text-dark-50 px-8">
                    Basic Info
                  </p>
                  <section className="flex flex-col gap-5.5 px-8">
                    <div className="flex flex-wrap gap-4.5 justify-between">
                      <FormBlock
                        label="First Name"
                        name="firstName"
                        className="flex-1"
                        labelClassName="font-body"
                      >
                        <Input value={values.firstName} styleAs="small" />
                      </FormBlock>
                      <FormBlock
                        label="Last Name"
                        name="lastName"
                        className="flex-1"
                        labelClassName="font-body"
                      >
                        <Input value={values.lastName} styleAs="small" />
                      </FormBlock>
                    </div>
                    <FormBlock
                      label="Email"
                      name="email"
                      labelClassName="font-body"
                    >
                      <Input value={values.email} styleAs="small" />
                    </FormBlock>
                    <FormBlock
                      label="Title"
                      name="title"
                      labelClassName="font-body"
                    >
                      <Input value={values.title} styleAs="small" />
                    </FormBlock>
                    <FormBlock
                      label="Bio"
                      name="bio"
                      labelClassName="font-body"
                    >
                      <TextArea
                        className={clsx(
                          "block w-full py-9px px-3.5 rounded-3px text-15 border border-dark-25 max-h-screen resize-none",
                          "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75 min-h-75px leading-5",
                        )}
                        value={values.bio}
                      />
                    </FormBlock>
                  </section>
                </div>
                <div>
                  <p className="font-label bg-lines mb-6 p-1.5 text-dark-50 px-8">
                    Role & Paperwork
                  </p>
                  <section className="flex flex-col gap-5.5 px-8">
                    <FormBlock
                      label="Role"
                      name="type"
                      labelClassName="font-body"
                    >
                      <Select
                        selected={values.type}
                        setSelected={(val = "") => {
                          setFieldValue("center", "");
                          setFieldValue("project_id", "");
                          setFieldValue("type", val);
                          setSelectedRole(val);
                        }}
                        options={roles}
                        placeholder="Select a role"
                        className="h-10 text-15 rounded font-body"
                        placement="top-end"
                      />
                    </FormBlock>
                    {selectedRole && !STAFF_ROLES.includes(selectedRole) && (
                      <FormBlock name="center">
                        <CenterOrInstitutionInput
                          values={values}
                          onChange={setFieldValue}
                          {...{ centers, institutions }}
                        />
                      </FormBlock>
                    )}
                    {INVESTIGATOR_TYPE.includes(selectedRole) && (
                      <FormBlock
                        label="Project"
                        name="project_id"
                        labelClassName="font-body"
                        optional
                      >
                        <Select
                          selected={values.project_id}
                          setSelected={(val = "") => {
                            setFieldValue("project_id", val);
                          }}
                          options={projects}
                          placeholder="Select a project"
                          className="h-10 text-15 rounded font-body"
                          placement="top-end"
                          classNameSelectGroup="max-h-100 overflow-auto"
                        />
                      </FormBlock>
                    )}
                    {selectedRole &&
                      PAPER_RELATED_ROLES.includes(selectedRole) && (
                        <div>
                          <fieldset>
                            <legend className="font-body mb-1">
                              Paperwork
                            </legend>
                            <p className="font-meta text-dark-75">
                              Is paperwork required for this member?
                            </p>
                            <div className="mt-2 flex space-x-8">
                              <RadioButtonGroup
                                options={booleanOptions}
                                selectedOption={
                                  booleanOptions.find(
                                    el => el.id === values.paperworkRequired,
                                  ) || booleanOptions[0]
                                }
                                setSelectedOption={val =>
                                  setFieldValue("paperworkRequired", val.id)
                                }
                                parentClass="flex gap-8"
                              />
                            </div>
                          </fieldset>
                          {values.paperworkRequired && (
                            <>
                              <div>
                                <label className="flex font-body mb-1">
                                  Participation Agreement
                                  <span className="font-meta ml-1 self-center text-gray-500">
                                    (Optional)
                                  </span>
                                </label>
                                <div className="mt-1">
                                  <FilePickerAdmin
                                    setSignedId={val =>
                                      setFieldValue(
                                        "participationAgreement",
                                        val,
                                      )
                                    }
                                    attachment={{
                                      signedId: values.participationAgreement,
                                      metadata:
                                        values.participationAgreementMetadata,
                                      imageUrl:
                                        values.participationAgreementUrl,
                                    }}
                                    name="participationAgreement"
                                  />
                                  {props.touched.participationAgreement &&
                                    props.errors.participationAgreement && (
                                      <p className="mt-1 text-sm text-red-400">
                                        {props.errors.participationAgreement}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div>
                                <label className="flex font-body mb-1">
                                  IP Disclosures and Encumbrances
                                  <span className="font-meta ml-1 self-center text-gray-500">
                                    (Optional)
                                  </span>
                                </label>
                                <div className="mt-1">
                                  <FilePickerAdmin
                                    setSignedId={val =>
                                      setFieldValue("ipDisclosure", val)
                                    }
                                    attachment={{
                                      signedId: values.ipDisclosure,
                                      metadata: values.ipDisclosureMetadata,
                                      imageUrl: values.ipDisclosureUrl,
                                    }}
                                    name="ipDisclosure"
                                  />
                                  {props.touched.ipDisclosure &&
                                    props.errors.ipDisclosure && (
                                      <p className="mt-1 text-sm text-red-400">
                                        {props.errors.ipDisclosure}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    {selectedRole && INVESTIGATOR_ROLES.includes(selectedRole) && (
                      <fieldset>
                        <legend className="font-body mb-1">
                          Howard Hughes Medical Institute (HHMI) Investigator
                          <span className="font-meta ml-1 self-center text-gray-500">
                            (Optional)
                          </span>
                        </legend>
                        <div className="mt-2 flex space-x-8">
                          <RadioButtonGroup
                            options={booleanOptions}
                            selectedOption={
                              booleanOptions.find(
                                el => el.id === values.hhmiInvestigator,
                              ) || false
                            }
                            setSelectedOption={val =>
                              setFieldValue("hhmiInvestigator", val.id)
                            }
                            parentClass="flex gap-8"
                          />
                        </div>
                      </fieldset>
                    )}
                  </section>
                </div>
                <div>
                  <p className="font-label bg-lines mb-6 p-1.5 text-dark-50 px-8">
                    Profile
                  </p>
                  <section className="flex flex-col gap-5.5 px-8">
                    <div>
                      <legend className="font-h3 font-body mb-1 text-dark-100">
                        Focus Areas{" "}
                        <span className="font-meta ml-1 self-center text-gray-500">
                          (Optional)
                        </span>
                      </legend>
                      <CheckboxGroup
                        options={focusAreas}
                        selectedOptions={values.focusAreas}
                        setSelectedOptions={areas =>
                          setFieldValue("focusAreas", areas)
                        }
                        className="flex flex-col h-auto flex-nowrap w-full sm:flex-wrap sm:h-[540px]"
                      />
                    </div>
                    <FormBlock
                      label="Google Scholar"
                      name="googleScholarUrl"
                      className="flex-1"
                      labelClassName="font-body"
                      optional
                    >
                      <Input
                        value={values.googleScholarUrl}
                        styleAs="small"
                        placeholder="Paste your Google Scholar URL profile"
                      />
                    </FormBlock>
                    <div>
                      <label className="flex font-body mb-1">
                        Avatar
                        <span className="font-meta ml-1 self-center text-gray-500">
                          (Optional)
                        </span>
                      </label>
                      <div className="mt-1">
                        <FilePickerAdmin
                          setSignedId={val => setFieldValue("avatar", val)}
                          attachment={{
                            signedId: values.avatar,
                            metadata: values.avatarMetadata,
                            imageUrl: values.avatarUrl,
                          }}
                          name="avatar"
                        />
                        {props.touched.avatar && props.errors.avatar && (
                          <p className="mt-1 text-sm text-red-400">
                            {props.errors.avatar}
                          </p>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
                <div>
                  <p className="font-label bg-lines mb-6 p-1.5 text-dark-50 px-8">
                    Admin Only
                  </p>
                  <section className="flex flex-col gap-5.5 px-8">
                    {selectedRole && STAFF_ROLES.includes(selectedRole) && (
                      <FormBlock
                        label="Legal Team"
                        name="legalTeam"
                        optional
                        labelClassName="font-body-bold pb-2"
                        classNameChildren="flex flex-row-reverse mr-auto items-center"
                        leftChildren={
                          <label className="text-dark-75 bg-tint-50 px-4 py-2">
                            This will allow Invention editing.
                          </label>
                        }
                      >
                        <input
                          type={"checkbox"}
                          className="h-4 w-4 rounded text-primary"
                        />
                      </FormBlock>
                    )}
                    <FormBlock
                      label="Notes"
                      name="notes"
                      labelClassName="font-body"
                      optional
                    >
                      <TextArea
                        className={clsx(
                          "block w-full py-9px px-3.5 rounded-3px text-15 border border-dark-25 max-h-screen resize-none",
                          "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75 min-h-75px leading-5",
                        )}
                        value={values.notes}
                      />
                    </FormBlock>
                  </section>
                </div>
              </SlideOver.Body>

              <SlideOver.Footer className="absolute bottom-0">
                <button
                  data-cy="btn-user-form-submit"
                  type="submit"
                  disabled={disableSubmit}
                  className={clsx(
                    "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                    disableSubmit
                      ? "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none"
                      : "bg-primary hover:bg-primary-dark",
                  )}
                >
                  Add Account
                </button>
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <div className="flex-grow" />
              </SlideOver.Footer>
            </Form>
          );
        }}
      </Formik>
    </SlideOver>
  );
}

export default UserForm;
