import React, { useState } from "react";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import Tabs from "@/components/shared/Tabs";
import { FilterOption } from "@/types";
import clsx from "clsx";
import { KeySection } from "@/components/shared/Retreat/Article/defaults";
import emptyImage from "@/images/empty_image.png";

enum SectionTabType {
  TextTab = "TextTab",
  ImageTab = "ImageTab",
  TableTab = "TableTab",
}

const SECTION_TABS: FilterOption[] = [
  {
    id: SectionTabType.TextTab,
    name: "Text",
  },
  {
    id: SectionTabType.ImageTab,
    name: "Images",
  },
  {
    id: SectionTabType.TableTab,
    name: "Tables",
  },
];

const SectionItem = ({
  keySection,
  title,
  titleClassName = "",
  children,
  isSelected = false,
  onSelect,
}: {
  keySection: KeySection;
  title: string;
  titleClassName?: string;
  children: React.ReactNode;
  isSelected?: boolean;
  onSelect: Function;
}) => {
  return (
    <button
      onClick={() => {
        onSelect(keySection);
      }}
      className={clsx(
        "relative flex h-[220px] w-[260px] flex-col items-start",
        "rounded border border-gray-300 bg-white p-6 text-left",
        "shadow hover:bg-gray-100  hover:shadow-lg",
        {
          "border-gray-400 bg-gray-100 shadow-xl hover:shadow-lg": isSelected,
        },
      )}
    >
      {isSelected && (
        <i className="ri-checkbox-circle-fill absolute top-2 right-2 text-4xl text-green-700" />
      )}
      <div className={clsx("font-bold pb-3", titleClassName)}>{title}</div>
      {children}
    </button>
  );
};

const TextTabContent = ({
  keySection,
  changeKeySection,
}: {
  keySection: undefined | KeySection;
  changeKeySection: Function;
}) => (
  <div className="flex flex-wrap gap-2">
    <SectionItem
      keySection={KeySection.SmallHeader}
      title="Small Header"
      onSelect={changeKeySection}
      isSelected={keySection === KeySection.SmallHeader}
    >
      <p className="text-sm">
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
        fugit, sed quia consequuntur magni.
      </p>
    </SectionItem>
    <SectionItem
      keySection={KeySection.LargeHeader}
      title="Large Header"
      titleClassName="text-2xl"
      onSelect={changeKeySection}
      isSelected={keySection === KeySection.LargeHeader}
    >
      <p className="text-sm">
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
        fugit, sed quia consequuntur magni.
      </p>
    </SectionItem>
    <SectionItem
      keySection={KeySection.ReferenceText}
      title="Reference Text"
      onSelect={changeKeySection}
      isSelected={keySection === KeySection.ReferenceText}
    >
      <ol className="text-xs list-decimal">
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
      </ol>
    </SectionItem>
  </div>
);

const ImageTabContent = ({
  keySection,
  changeKeySection,
}: {
  keySection: undefined | KeySection;
  changeKeySection: Function;
}) => (
  <div className="flex flex-wrap gap-2">
    <SectionItem
      keySection={KeySection.ImageFullWidth}
      title="Full Width Image"
      titleClassName="text-xl"
      onSelect={changeKeySection}
      isSelected={keySection === KeySection.ImageFullWidth}
    >
      <img src={emptyImage} className="h-16 w-full" />
      <p className="text-sm pt-3">
        Description Image Nemo enim ipsam voluptatem quia voluptas sit
        aspernatur aut odit aut
      </p>
    </SectionItem>
    <SectionItem
      keySection={KeySection.ImageTwoColumns}
      title="Two Columns Images"
      titleClassName="text-xl"
      onSelect={changeKeySection}
      isSelected={keySection === KeySection.ImageTwoColumns}
    >
      <div className="flex gap-2">
        <div>
          <img src={emptyImage} className="h-16 w-full" />
          <p className="text-sm pt-3">Description Image Nemo enim ipsam</p>
        </div>
        <div>
          <img src={emptyImage} className="h-16 w-full" />
          <p className="text-sm pt-3">Description Image Nemo enim ipsam</p>
        </div>
      </div>
    </SectionItem>
  </div>
);

const TableTabContent = ({
  keySection,
  changeKeySection,
}: {
  keySection: undefined | KeySection;
  changeKeySection: Function;
}) => (
  <div className="flex w-1/2">
    <SectionItem
      keySection={KeySection.BasicTable}
      title="Basic Table"
      titleClassName="text-xl"
      onSelect={changeKeySection}
      isSelected={keySection === KeySection.BasicTable}
    >
      <table className="text-11 border border-gray-200 rounded-lg bg-white w-full">
        <tbody>
          <tr className="bg-gray-100 border-b">
            <th className="pl-2">Text</th>
            <th>Text</th>
            <th>Text</th>
          </tr>
          <tr className="border-b">
            <td className="pl-2">Text</td>
            <td>Text</td>
            <td>Text</td>
          </tr>
          <tr className="border-b">
            <td className="pl-2">Text</td>
            <td>Text</td>
            <td>Text</td>
          </tr>
          <tr className="border-b">
            <td className="pl-2">Text</td>
            <td>Text</td>
            <td>Text</td>
          </tr>
          <tr className="border-b">
            <td className="pl-2">Text</td>
            <td>Text</td>
            <td>Text</td>
          </tr>
        </tbody>
      </table>
      <p className="pt-2 text-11">Description Table</p>
    </SectionItem>
  </div>
);

const TabSection = ({
  tab,
  selectedKeySection,
  setSelectedKeySection,
}: {
  tab: SectionTabType;
  selectedKeySection: undefined | KeySection;
  setSelectedKeySection: Function;
}) => {
  switch (tab) {
    case SectionTabType.TextTab:
      return (
        <TextTabContent
          keySection={selectedKeySection}
          changeKeySection={setSelectedKeySection}
        />
      );
    case SectionTabType.ImageTab:
      return (
        <ImageTabContent
          keySection={selectedKeySection}
          changeKeySection={setSelectedKeySection}
        />
      );
    case SectionTabType.TableTab:
      return (
        <TableTabContent
          keySection={selectedKeySection}
          changeKeySection={setSelectedKeySection}
        />
      );
    default:
      return null;
  }
};

function SectionSelectorsModal({ isOpenModal, setOpenModal, onAccept }) {
  const [selectedTab, setSelectedTab] = useState<FilterOption>(SECTION_TABS[0]);
  const [selectedKeySection, setSelectedKeySection] = useState<
    undefined | KeySection
  >();
  const content = (
    <>
      <p className="pb-5">Pick the section you want to display.</p>
      <Tabs
        tabs={SECTION_TABS}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        className="pb-5"
        tabClassName="!py-1"
      />
      {selectedTab && (
        <TabSection
          tab={SectionTabType[selectedTab.id.toString()]}
          selectedKeySection={selectedKeySection}
          setSelectedKeySection={setSelectedKeySection}
        />
      )}
    </>
  );

  return (
    <ConfirmationModal
      title="Select Section"
      description={content}
      isOpen={isOpenModal}
      closeLabel="Cancel"
      className="w-150"
      onAccept={() => {
        onAccept(selectedKeySection);
        setSelectedKeySection(undefined);
        setOpenModal(false);
      }}
      onClose={() => setOpenModal(false)}
      setOpen={() => setOpenModal(false)}
      acceptLabel="Add Section"
      disabledAcceptButton={!selectedKeySection}
    />
  );
}

export default SectionSelectorsModal;
