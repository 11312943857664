import * as React from "react";
import { useEffect } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { useURLFilter } from "@/hooks/useURLFilter";
import { FilterOption } from "@/types";

const palettes = {
  primary: {
    navBorder: "border-b border-lines",
    selectedTab: "border-primary text-primary",
    unselectedTab: "border-transparent text-dark-75 hover:text-dark-100",
    mobileDropdown: "border-dark-25 text-dark-100",
  },
  dark: {
    navBorder: "",
    selectedTab: "border-white text-white",
    unselectedTab: "border-transparent text-dark-25 hover:text-white",
    mobileDropdown: "border-dark-75 text-white bg-transparent",
  },
};

function Tabs({
  tabs,
  selectedTab,
  setSelectedTab,
  palette = "primary",
  className = "",
  tabClassName = "",
  navClassName = "",
  params = null,
  setSearchParams = () => {},
}: {
  tabs: FilterOption[];
  selectedTab: FilterOption;
  setSelectedTab: (_: FilterOption) => any;
  palette?: "primary" | "dark";
  className?: string;
  tabClassName?: string;
  navClassName?: string;
  params?: any;
  setSearchParams?: (_: any) => void;
}) {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  useURLFilter("tab", tabs, selectedTab, setSelectedTab);

  useEffect(() => {
    if (!selectedTab) setSelectedTab(tabs[0]);
  }, [selectedTab, setSelectedTab, tabs]);

  const changeTab = tab => {
    if (params !== null) {
      params["tab"] = tab.id;
      setSearchParams(params);
    } else {
      navigate(`${path}?tab=${tab.id}`);
    }
  };

  return (
    <div className={className}>
      <div className="sm:hidden mx-auto sm:px-4">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={event =>
            changeTab(tabs.find(tab => tab.id === event.target.value))
          }
          value={selectedTab?.id}
          className={clsx(
            "block w-full pl-3 pr-10 py-2 mt-0.5 font-body-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
            palettes[palette].mobileDropdown,
          )}
        >
          {tabs.map(tab => (
            <option key={tab.id} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block max-w-7xl">
        <div className={palettes[palette].navBorder}>
          <nav
            className={clsx("flex space-x-8", navClassName)}
            aria-label="Tabs"
          >
            {tabs.map(tab => (
              <button
                key={tab.id}
                type="button"
                onClick={() => changeTab(tab)}
                className={clsx(
                  tab.id === selectedTab?.id
                    ? palettes[palette].selectedTab
                    : palettes[palette].unselectedTab,
                  "whitespace-nowrap py-4 px-1 border-b-4 font-h3",
                  tabClassName,
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
