import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import Filter from "@/components/shared/Filter";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import FilterTags from "@/components/shared/FilterTags";
import { FilterOption } from "@/types";

const sortOptions = [
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "Date, Oldest First", id: "createdAt" },
  { name: "Title, A to Z", id: "title" },
  { name: "Title, Z to A", id: "-title" },
];

function Filters({ setFilters }) {
  const [selectedTypes, setSelectedTypes] = useState<FilterOption[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<FilterOption[]>([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  const [typesFocus, setTypesFocus] = useState(false);
  const [focusAreasFocus, setFocusAreasFocus] = useState(false);
  const [statusesFocus, setStatusesFocus] = useState(false);

  const { data: filtersData } = useQuery(["researchFilters"], () =>
    fetcher("/api/research-documents/filters"),
  );

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        researchType: selectedTypes.map(t => t.id),
        focusAreas: selectedFocusAreas.map(f => f.id),
        status: selectedStatuses.map(s => s.id),
      },
      sort: [selectedSort.id],
    });
    setFilters(filters);
  }, [
    setFilters,
    selectedTypes,
    selectedStatuses,
    selectedFocusAreas,
    selectedSort,
  ]);

  return (
    <>
      <div className="flex">
        <div className="sm:flex sm:space-x-4 w-full sm:w-auto">
          <div className="flex space-x-4 mb-4 sm:mb-0">
            <Filter
              name="research_type"
              items={filtersData?.types}
              selectedItems={selectedTypes}
              setSelectedItems={setSelectedTypes}
              highlight={typesFocus}
              setFocus={setTypesFocus}
              className="flex-1"
            >
              <Filter.Label>Research Type</Filter.Label>
              <Filter.Content>
                <CheckboxGroup
                  options={filtersData?.types}
                  selectedOptions={selectedTypes}
                  setSelectedOptions={setSelectedTypes}
                  className="filter-checkbox-group"
                />
              </Filter.Content>
            </Filter>
            <Filter
              name="status"
              items={filtersData?.statuses}
              selectedItems={selectedStatuses}
              setSelectedItems={setSelectedStatuses}
              highlight={statusesFocus}
              setFocus={setStatusesFocus}
              className="flex-1"
            >
              <Filter.Label>Status</Filter.Label>
              <Filter.Content>
                <CheckboxGroup
                  options={filtersData?.statuses}
                  selectedOptions={selectedStatuses}
                  setSelectedOptions={setSelectedStatuses}
                  className="filter-checkbox-group"
                />
              </Filter.Content>
            </Filter>
          </div>
          <Filter
            name="focus_area"
            items={filtersData?.focusAreas}
            selectedItems={selectedFocusAreas}
            setSelectedItems={setSelectedFocusAreas}
            highlight={focusAreasFocus}
            setFocus={setFocusAreasFocus}
            className="flex-1"
          >
            <Filter.Label>Focus Area</Filter.Label>
            <Filter.Content>
              <CheckboxGroup
                options={filtersData?.focusAreas}
                selectedOptions={selectedFocusAreas}
                setSelectedOptions={setSelectedFocusAreas}
                className="focus-area-checkbox-group"
                labelClassName="sm:w-325px"
              />
            </Filter.Content>
          </Filter>
        </div>
        <div className="hidden sm:flex sm:ml-auto sm:items-center">
          <span className="hidden md:inline-block text-right mx-4 text-dark-100 font-body-bold">
            Sort by:
          </span>
          <Filter
            name="sort"
            items={sortOptions}
            selectedItems={selectedSort}
            setSelectedItems={setSelectedSort}
            closeOnSelect
          >
            <Filter.Label>{selectedSort.name}</Filter.Label>
            <Filter.Content>
              <SelectGroup
                options={sortOptions}
                selectedOption={selectedSort}
                setSelectedOption={setSelectedSort}
              />
            </Filter.Content>
          </Filter>
        </div>
      </div>
      <div className="flex flex-wrap mt-4 mb-6">
        <FilterTags
          options={selectedTypes}
          setOptions={setSelectedTypes}
          isFocused={typesFocus}
          setFocus={setTypesFocus}
        />
        <FilterTags
          options={selectedStatuses}
          setOptions={setSelectedStatuses}
          isFocused={statusesFocus}
          setFocus={setStatusesFocus}
        />
        <FilterTags
          options={selectedFocusAreas}
          setOptions={setSelectedFocusAreas}
          isFocused={focusAreasFocus}
          setFocus={setFocusAreasFocus}
        />
      </div>
    </>
  );
}

export default Filters;
