import React from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import ReactTooltip from "react-tooltip";
import Table from "@/components/shared/Table";
import NoResults from "@/components/shared/NoResults";
import {
  ActionButtonsColumn,
  CentersColumn,
  LinkableNameColumn,
  MembersColumn,
  itemValue,
  render,
} from "@/components/shared/Admin/Columns";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import InventionSkeleton from "./InventionSkeleton";
import { InventionTableType } from "./InventionTableType";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { UPDATED_REVIEW_STATUS } from "@/components/shared/Inventions/defaults";

const ipTypeMap = {
  subject_ip: "Subject",
  peripheral_ip: "Peripheral",
  improvement: "Improvement",
  both: (
    <>
      Subject
      <br />
      Peripheral
    </>
  ),
};

const LABEL_STATUS_DATE = {
  accepted: "DATE ACCEPTED",
  declined: "DATE DECLINED",
  pending: "DATE DISCLOSED",
};

const REVIEW_STATUS = {
  pending: "Pending",
  accepted: "Accepted",
  declined: "Declined",
};

const ALL_HEADERS = [
  {
    name: "disclosure",
    th: {},
    td: { className: "!px-3 w-32" },
    label: "DATE DISCLOSED",
    render: value => (
      <div className="flex">
        {value.date && format(new Date(value.date), "MMM dd, yyyy")}
      </div>
    ),
  },
  {
    name: "review",
    th: {},
    td: { className: "!px-3" },
    label: "Review Date",
    render: (value, invention) => {
      const { updatedStatus } = invention.attributes;
      const isReverted = !isEmpty(updatedStatus);
      const hasDate = "date" in value && value.date.length > 0;

      const reviewDate = hasDate
        ? format(new Date(value.date), "MMM dd, yyyy")
        : "Pending";
      return isReverted
        ? format(new Date(updatedStatus.date), "MMM dd, yyyy")
        : reviewDate;
    },
  },
  {
    name: "review",
    th: {},
    td: { className: "!px-3" },
    label: "STATUS",
    render: (value, invention) => {
      const { updatedStatus } = invention.attributes;
      const isReverted = !isEmpty(updatedStatus);
      return (
        <span className="capitalize">
          {isReverted
            ? UPDATED_REVIEW_STATUS[updatedStatus.review_status]
            : REVIEW_STATUS[value.review_status]}
        </span>
      );
    },
  },
];

export type AllTabProps = {
  inventions: any[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  tabType: InventionTableType;
  setCurrentInvention: (any) => void;
  setIsOpenReviewModal: (boolean) => void;
};

function InventionsTable({
  inventions,
  isFetching,
  isFetchingNextPage,
  tabType,
  setCurrentInvention,
  setIsOpenReviewModal,
}: AllTabProps) {
  const OTHERS_HEADERS = [
    {
      name: tabType === "pending" ? "disclosure" : "review",
      th: {},
      td: { className: "!px-3 w-32" },
      label: LABEL_STATUS_DATE[tabType],
      render: (value, item) => {
        const { updatedStatus } = item.attributes;
        const isReverted = !isEmpty(updatedStatus);
        return (
          <>
            {isReverted && (
              <div className="relative">
                <span className="text-[8px] absolute -top-3.5">
                  Decision Reverted
                </span>
              </div>
            )}
            <div className="flex">
              {isReverted
                ? format(new Date(updatedStatus.date), "MMM dd, yyyy")
                : value.date && format(new Date(value.date), "MMM dd, yyyy")}
              <InformationCircleIcon
                className="h-5 w-5 ml-2 text-primary"
                data-tip={isReverted ? updatedStatus.notes : value.notes}
                data-for={item.id}
              />
              <ReactTooltip
                id={item.id}
                type="light"
                borderColor="gray"
                border
                data-html={true}
                insecure={true}
                multiline={true}
                className="max-w-md break-all break-words whitespace-normal"
              />
            </div>
          </>
        );
      },
    },
  ];
  const extraColumns = tabType === "all" ? ALL_HEADERS : OTHERS_HEADERS;

  const columns = [
    {
      name: "title",
      label: "TITLE",
      th: { width: "33%" },
      accessor: item =>
        `${item.attributes.identifier}: ${item.attributes.title}`,
      render: (value, item) => (
        <LinkableNameColumn
          text={value}
          to={`/inventions/${item.id}`}
          isBlank
        />
      ),
    },
    {
      name: "ipType",
      th: {},
      td: { className: "!px-3" },
      label: "IP TYPE",
      render: value => ipTypeMap[value],
    },
    {
      label: "Centers",
      th: {},
      td: { className: "!px-4" },
      render: (_, item) => {
        const centers = item.relationships.centers.data
          .concat(item.relationships.institutions.data)
          .map(center => center.attributes.shorthand || center.attributes.name);
        return <CentersColumn centerList={centers} />;
      },
    },
    {
      label: "Inventors",
      th: {},
      accessor: item => item.attributes.inventorsList,
      render: (value, item) => (
        <MembersColumn id={item.id} memberList={value} />
      ),
    },

    ...extraColumns,
    {
      label: "Actions",
      th: { width: tabType === "pending" ? "224" : "82" },
      td: { className: "py-9px !px-3" },
      accessor: item => item,
      render: value => {
        const extraButton: ActionButtonItemType[] = [];
        if (tabType === "pending") {
          extraButton.push({
            text: "Internal Review",
            variant: "btn-primary-outline",
            onClick: () => {
              setCurrentInvention(value);
              setIsOpenReviewModal(true);
            },
          });
        }
        const items: ActionButtonItemType[] = [
          ...extraButton,
          {
            text: "Edit",
            to: `/admin/inventions/${value.id}/edit`,
            name: value.attributes.name,
            variant: "btn-admin",
            isBlank: true,
          },
        ];
        return <ActionButtonsColumn items={items} />;
      },
    },
  ];
  return (
    <>
      {!isFetching && inventions.length === 0 ? (
        <NoResults entityName="inventions" icon="ri-search-line" />
      ) : (
        <Table className="border border-transparent mb-8">
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, i) => (
              <Table.HeaderCell
                key={i}
                width={column.th["width"]}
                className={clsx(column.td?.className, "text-11 pb-2")}
              >
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body>
            {inventions.map((item, index) => (
              <Table.Row key={index} className="divide-x divide-lines">
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={clsx(column.td?.className, "text-14")}
                    expand
                  >
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => (
                <InventionSkeleton key={x} tabType={tabType} />
              ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default InventionsTable;
