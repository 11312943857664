import { SectionMode } from "../defaults";

export default function useViewMode(viewMode) {
  const isEditMode = viewMode === SectionMode.Edit;
  const isPreviewMode = viewMode === SectionMode.Preview;
  const isNotViewMode = viewMode !== SectionMode.View;
  const isViewOrPreview =
    viewMode === SectionMode.Preview || viewMode === SectionMode.View;
  const isEditOrPreview =
    viewMode === SectionMode.Edit || viewMode === SectionMode.Preview;
  return {
    isEditMode,
    isPreviewMode,
    isNotViewMode,
    isViewOrPreview,
    isEditOrPreview,
  };
}
