import * as React from "react";
import { useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import useCurrentUser from "@/hooks/useCurrentUser";
import { denormalizedFetcher } from "@/utils/api";
import NotFoundPage from "@/components/App/NotFoundPage";
import { FilterOption } from "@/types";
import Header from "./Header";
import ProjectsTab from "./ProjectsTab/ProjectsTab";
import MembersTab from "./MembersTab";
import ResearchTab from "./ResearchTab";
import InventionsTab from "./InventionsTab";

const defaultTabs = [{ id: "members", name: "Members" }];

const fetchCenter = ({ queryKey }) => {
  const [key, slug] = queryKey;

  const params = paramBuilder({
    fields: {
      centers: [
        "name",
        "location",
        "shorthand",
        "description",
        "projectsCount",
        "researchCount",
        "inventionsCount",
      ],
    },
  });

  const url = `/api/${key}/${slug}?${params}`;
  return denormalizedFetcher(url);
};

const TabSection = props => {
  switch (props.tab) {
    case "members":
      return <MembersTab />;
    case "projects":
      return <ProjectsTab center={props.center} />;
    case "research":
      return <ResearchTab center={props.center} />;
    case "inventions":
      return <InventionsTab center={props.center} />;
    default:
      return null;
  }
};

function CenterSummary({ center }) {
  const { shorthand, location, description } = center?.attributes;

  return (
    <div className="bg-tint rounded-lg p-6 pb-7 xl:w-320px">
      <div className="border-b pb-6 mb-6">
        <h3 className="font-h3 text-dark-100 mb-1">PICI @ {shorthand}</h3>
        <p className="font-body text-dark-75">{description}</p>
      </div>
      <p className="text-dark-100 flex items-center">
        <i className="ri-map-pin-line ri-lg text-dark-50" />
        <span className="font-body-bold ml-2.5">{location}</span>
      </p>
    </div>
  );
}

function CenterDetail() {
  const { slug } = useParams();
  const currentUser = useCurrentUser();
  const [tabsLoading, setTabsLoading] = useState(true);
  const [tabs, setTabs] = useState<FilterOption[]>(defaultTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { data }: { data: any } = useQuery(["centers", slug], fetchCenter);

  const center = data?.data;
  const projectsCount = center?.attributes.projectsCount;
  const researchCount = center?.attributes.researchCount;
  const inventionsCount = center?.attributes.inventionsCount;
  const canSeeInventions =
    currentUser?.attributes.isStaff || currentUser?.attributes.isBoardMember;

  useLayoutEffect(() => {
    if (center) {
      const newTabs: FilterOption[] = [];
      if (projectsCount > 0) newTabs.push({ id: "projects", name: "Projects" });
      if (researchCount > 0) newTabs.push({ id: "research", name: "Research" });
      if (canSeeInventions && inventionsCount > 0)
        newTabs.push({ id: "inventions", name: "Inventions" });

      setTabs([...defaultTabs, ...newTabs]);
      setTabsLoading(false);
    }
  }, [center, projectsCount, researchCount, inventionsCount, canSeeInventions]);

  if (data?.errors) return <NotFoundPage />;

  return (
    <div className="font-lato bg-white min-h-full">
      {center && !tabsLoading && (
        <Header
          center={center}
          tabs={tabs}
          {...{ selectedTab, setSelectedTab }}
        />
      )}
      <div className="margin-safe xl:flex xl:flex-row-reverse pt-7.5 xl:pt-12 pb-4 sm:pb-8 xl:pb-12">
        {selectedTab?.id !== "inventions" && (
          <aside className="ml-0 mb-7.5 xl:ml-7.5 xl:mb-0">
            {center && <CenterSummary center={center} />}
          </aside>
        )}
        <section className="xl:flex-grow overflow-auto px-1 -mx-1 pt-1 -mt-1">
          {selectedTab && center && (
            <TabSection tab={selectedTab.id} center={center} />
          )}
        </section>
      </div>
    </div>
  );
}

export default CenterDetail;
