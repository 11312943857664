import React from "react";

const Tooltip = ({ active, payload }: { active?: boolean; payload?: any }) => {
  if (active && payload !== undefined) {
    const { name, percentage, count } = payload[0].payload;
    return (
      <div className="bg-tint py-2 px-3">
        <p className="font-meta-bold">{name}</p>
        <p className="font-meta">
          {count} - {(percentage * 100).toFixed(1)}%
        </p>
      </div>
    );
  }
  return null;
};

export default Tooltip;
