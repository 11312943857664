import * as React from "react";
import { fetcher } from "@/utils/api";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FocusAreaTable from "./FocusAreaTable";
import FocusAreaForm from "./FocusAreaForm";
import AdminHeader from "@/components/shared/Admin/AdminHeader";

const fetchFocusAreas = () => {
  const baseUrl = `/api/focus-areas`;
  return fetcher(baseUrl);
};

const defaultFocusArea = {
  attributes: {
    name: "",
    description: "",
  },
};

function FocusAreasPage() {
  const [showForm, setShowForm] = useState(false);
  const [resource, setResource] = useState(defaultFocusArea);
  const [editing, setEditing] = useState(false);
  const { data: focusAreas } = useQuery(["focus-areas"], fetchFocusAreas);

  const openForm = (resource = defaultFocusArea, editing = false) => {
    setResource(resource);
    setEditing(editing);
    setShowForm(true);
  };

  return (
    <div className="bg-tint h-screen">
      <AdminHeader
        title="Focus Areas"
        description="PICI focus areas help connect PICI Members to the projects and research they're most interested in."
        linkText="+ New Focus Area"
        linkTo={() => openForm()}
      />
      <article
        id="article-container"
        className="px-6 py-3 pb-4 overflow-x-hidden h-vh-60"
      >
        {focusAreas && (
          <FocusAreaTable data={focusAreas.data} openForm={openForm} />
        )}
      </article>
      <FocusAreaForm
        openForm={showForm}
        setOpenForm={setShowForm}
        resource={resource}
        editing={editing}
        setEditing={setEditing}
      />
    </div>
  );
}

export default FocusAreasPage;
