import React from "react";
import {
  ScatterChart,
  Scatter,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ZAxis,
} from "recharts";
import { format, parseISO } from "date-fns";

const dateFormat = date =>
  typeof date === "number" ? format(new Date(date), "yyyy") : "";

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { date, amount } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          Date:{" "}
          <span className="font-bold">
            {format(new Date(date), "LLLL, yyyy")}
          </span>
          <br></br>
          Amount: <span className="font-bold">{amount}</span>
        </p>
      </div>
    );
  }
  return null;
};

function InsightScatterChart({ data, XDataKey = "date", YDataKey = "amount" }) {
  const xTickList: number[] = [];
  const dataByMonth = data.map(item => {
    const dateMonth = parseISO(item.month);
    if (xTickList.length === 0) {
      xTickList.push(dateMonth.getTime());
    } else {
      const lastDate = xTickList[xTickList.length - 1];
      if (dateMonth.getFullYear() != new Date(lastDate).getFullYear()) {
        xTickList.push(dateMonth.getTime());
      }
    }
    return {
      date: dateMonth.getTime(),
      amount: item.amount,
    };
  });
  return (
    <ResponsiveContainer width="100%" height={202}>
      <ScatterChart margin={{ top: 0, left: -40, right: 0, bottom: 0 }}>
        <CartesianGrid stroke="#CFD3DA99" strokeDasharray="3 3" />
        <XAxis
          type="category"
          dataKey={XDataKey}
          stroke="#8590A1"
          interval="preserveStartEnd"
          ticks={xTickList}
          tickFormatter={dateFormat}
        />
        <YAxis
          type="number"
          dataKey={YDataKey}
          stroke="#8590A1"
          style={{ fontSize: 12 }}
        />
        <ZAxis range={[15, 15]} />
        <Tooltip content={<CustomTooltip />} />
        <Scatter data={dataByMonth} fill="#685BC7" />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export default InsightScatterChart;
