import * as React from "react";
import * as R from "ramda";
import { useEffect } from "react";
import { useField } from "formik-latest";
import useActiveStorage from "@/hooks/useActiveStorage";
import useActiveStoragePreview from "@/hooks/useActiveStoragePreview";
import blankAvatar from "@/images/blank_avatar.png";

function AvatarPicker({
  getStatus,
  labelButton = "Change",
  attachment,
  ...props
}) {
  const { name } = props;
  const [_field, _meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const { uploadFile, progress, metadata, file, status } = useActiveStorage({});
  const preview = useActiveStoragePreview(
    file,
    "h-12 w-12 rounded-full flex-shrink-0 bg-cover",
  );

  useEffect(() => {
    if (!R.isEmpty(metadata)) {
      getStatus(status);
      setValue(metadata.signedId);
      setTouched(true);
    }
  }, [metadata]);

  useEffect(() => {
    getStatus(status);
  }, [status]);

  return (
    <div className="flex gap-5 items-center">
      {status == "initial" || status === "uploading" ? (
        <img
          src={status === "uploading" ? blankAvatar : attachment}
          alt=""
          className="h-12 w-12 rounded-full flex-shrink-0"
        />
      ) : (
        preview
      )}
      {status != "uploading" && (
        <label className="btn-white btn-sm font-medium h-8.5 relative">
          {labelButton}
          <input
            type="file"
            name="avatar"
            id="avatarId"
            className="opacity-0 absolute h-full w-full cursor-pointer z-[-1]"
            onChange={uploadFile}
          />
        </label>
      )}
      {status === "uploading" && (
        <div className="flex-1 max-w-full ml-4 mb-1.5 p-0.5 h-2 border border-dark-25 rounded">
          <div
            className="h-0.5 rounded-sm bg-primary transition"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
}
export default AvatarPicker;
