import * as React from "react";
import { XIcon } from "@heroicons/react/outline";
import clsx from "clsx";

function FilterTags({
  options,
  setOptions,
  isFocused = false,
  setFocus = (val: boolean) => {},
}) {
  const isArray = Array.isArray(options);
  if (!isArray) {
    options = options ? [options] : [];
  }

  return (
    <>
      {options.map(option => (
        <div
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onMouseEnter={() => setFocus(true)}
          onMouseLeave={() => setFocus(false)}
          key={option.name}
          className={clsx(
            "flex items-center mr-4 py-0.25 pl-2.5 pr-2 rounded-xl font-label uppercase mb-2.5 border border-transparent bg-lines text-dark-75",
            {
              ["border-black"]: isFocused,
            },
          )}
        >
          <span>{option.name}</span>
          <button
            onClick={() =>
              isArray
                ? setOptions(options.filter(item => item !== option))
                : setOptions(null)
            }
            className="pl-0.5 cursor-pointer bg-transparent border-0"
            data-testid={`tag-${option.name}`}
          >
            <XIcon className="fill-current text-dark-50 w-3 h-3" />
          </button>
        </div>
      ))}
    </>
  );
}

export default FilterTags;
