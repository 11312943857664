import * as React from "react";
import { Fragment, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetcher, saveResource } from "@/utils/api";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import OutsideClickHandler from "@/utils/deps/react-outside-click-handler";
import usePageView from "@/hooks/usePageView";
import useCurrentUser from "@/hooks/useCurrentUser";
import clsx from "clsx";
import { TourProvider, useTour } from "@reactour/tour";
import GuidedSteps from "@/components/shared/GuidedTourSteps";
import piciLogoMC from "@/images/logo-mc@2x.png";
import createArrow from "@/utils/createArrow";
import { useDebounce } from "use-debounce";
import RichText from "@/components/shared/RichText";

const sections = [
  {
    name: "Members",
    path: "/directory",
    jewel: "activeMembersCount",
    tour: "first-step",
    restricted: false,
  },
  // {
  //   name: "Forum",
  //   path: `${process.env.VITE_DISCOURSE_APP_URL}/session/sso?return_path=${process.env.VITE_DISCOURSE_APP_URL}`,
  //   jewel: "questionsCount",
  //   restricted: false,
  //   external: true,
  // },
  {
    name: "Projects",
    path: "/projects",
    jewel: "projectsCount",
    tour: "last-step",
    restricted: false,
  },
  {
    name: "Resources",
    path: "/resources",
    jewel: "resourcesCount",
    restricted: false,
  },
  {
    name: "Research",
    path: "/research_documents",
    jewel: "researchDocumentsCount",
    restricted: false,
  },
  {
    name: "Inventions",
    path: "/inventions",
    restricted: true,
  },
];

const pageViewCounter = page => [
  { name: "Members", count: page.data.attributes.activeMembersCount },
  { name: "Projects", count: page.data.attributes.projectsCount },
  {
    name: "Resources",
    count: page.data.attributes.resourcesCount,
  },
  {
    name: "Research",
    count: page.data.attributes.researchDocumentsCount,
  },
];

function SuperLink({ external, href, children, ...props }) {
  return external ? (
    <a href={href} {...props}>
      {children}
    </a>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
}

function DesktopNavigation({ showSection, isCurrentLocation, pageView }) {
  const NotificationJewel = ({ section }) => {
    const jewelCount =
      pageView &&
      pageViewCounter(pageView).find(page => page.name === section.name)?.count;

    return (
      <>
        {jewelCount ? (
          <span
            className={clsx(
              isCurrentLocation(section.path)
                ? "bg-primary-light text-primary"
                : "bg-tint text-dark-100",
              "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block",
            )}
          >
            {jewelCount}
          </span>
        ) : null}
      </>
    );
  };

  return (
    <div className="hidden lg:block lg:ml-6">
      <div className="flex space-x-1">
        {sections.map(section => {
          const isVisible = !section.restricted || showSection;
          return (
            isVisible && (
              <SuperLink
                key={section.path}
                href={section.path}
                external={section.external}
                className={clsx(
                  isCurrentLocation(section.path)
                    ? "bg-black-tint text-white"
                    : "text-dark-25 hover:bg-gray-700 hover:text-white",
                  "px-3 py-2 rounded-md font-meta-bold",
                  section.tour,
                )}
              >
                {section.name}
                <NotificationJewel section={section} />
              </SuperLink>
            )
          );
        })}
      </div>
    </div>
  );
}

function GlobalSearchDropdown() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleKeyDownInputSearch = e => {
    if (e.key === "Enter" && searchTerm !== "") {
      navigate(`search?term=${searchTerm}`);
      setSearchTerm("");
    }
  };

  return (
    <div className="flex-1 flex px-2 lg:ml-3.5 justify-end">
      <div className="w-full max-w-177px lg:max-w-205px">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <i className="ri-search-line ri-lg leading-none text-dark-25" />
          </div>
          <input
            id="search"
            name="search"
            className={clsx(
              "block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 font-meta",
              "bg-black-tint text-dark-25 placeholder-dark-25",
              "focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-dark-100 font-mobile-body sm:font-body",
            )}
            placeholder="Search"
            type="search"
            onChange={event => {
              setSearchTerm(event.target.value);
            }}
            onKeyDown={handleKeyDownInputSearch}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
}

function ProfileSection({ currentUser, pathname }) {
  const { setIsOpen, setCurrentStep } = useTour();
  return (
    <div className="hidden lg:block lg:ml-0.5 z-20">
      <div className="flex items-center">
        {currentUser.attributes.isStaff && (
          <div className="flex-shrink-0">
            <a
              className={clsx(
                "font-meta-bold inline-flex items-center px-4 py-2 border border-dark-75",
                "shadow-sm rounded-md text-dark-25",
                "hover:text-white hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary",
              )}
              href="/admin"
            >
              <span className="hidden xl:inline pr-1">Go to</span>
              <span>Admin Center</span>
            </a>
          </div>
        )}

        {/* Profile dropdown */}
        <Menu as="div" className="ml-4 relative flex-shrink-0 second-step">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-800 rounded-full flex text-meta text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src={currentUser.links.avatar}
                    alt=""
                  />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <Menu.Item>
                    {({}) => (
                      <div className="block px-4 py-2 text-dark-75 border-b border-lines">
                        <h3 className="font-body-bold text-dark-100">
                          {currentUser.attributes.fullName}
                        </h3>
                        <p className="mt-1 font-meta text-dark-50">
                          {currentUser.attributes.digestedJobTitle}
                        </p>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={currentUser.links.show}
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings/basic-info"
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Account
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings/help-request"
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Help
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/logout"
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Log out
                      </a>
                    )}
                  </Menu.Item>
                  {pathname == "/" &&
                    !currentUser.attributes.guidedTourCompleted && (
                      <Menu.Item>
                        {({}) => (
                          <div className="block px-4 py-2 text-dark-75 border-t border-lines">
                            <button
                              onClick={() => {
                                setIsOpen(true);
                                setCurrentStep(0);
                              }}
                              className="font-body-bold text-dark-100"
                            >
                              Start Guided Tour
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    )}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}

function MobileNavigation({
  showSection,
  isCurrentLocation,
  currentUser,
  close,
}) {
  return (
    <div className="block lg:hidden">
      <OutsideClickHandler onOutsideClick={close}>
        <div className="bg-black">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {sections.map(section => {
              const isVisible = !section.restricted || showSection;
              return (
                isVisible && (
                  <SuperLink
                    key={section.path}
                    href={section.path}
                    external={section.external}
                    onClick={close}
                    className={clsx(
                      isCurrentLocation(section.path)
                        ? "bg-black-tint text-white"
                        : "text-dark-25 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md font-mobile-body",
                      "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary",
                    )}
                  >
                    {section.name}
                  </SuperLink>
                )
              );
            })}
          </div>
          {currentUser.attributes.isStaff && (
            <div className="ml-auto px-5 pb-5">
              <a
                className={clsx(
                  "py-9px flex justify-center rounded-md w-full bg-transparent border border-dark-75 font-meta-bold text-dark-25 text-center shadow-sm",
                  "hover:text-white hover:border-white",
                  "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary",
                )}
                href="/admin"
              >
                Go to Admin Center
              </a>
            </div>
          )}
          <div className="pt-4 pb-3 border-t border-dark-75">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={currentUser.links.avatar}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <div className="font-mobile-body-bold text-white">
                  {currentUser.attributes.fullName}
                </div>
                <div className="font-meta text-dark-25">
                  {currentUser.attributes.email}
                </div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <Link
                to={currentUser.links.show}
                onClick={close}
                className="block px-3 py-2 rounded-md font-mobile-body text-dark-25 hover:text-white hover:bg-dark-75"
              >
                Profile
              </Link>
              <Link
                to="/settings/basic-info"
                onClick={close}
                className="block px-3 py-2 rounded-md font-mobile-body text-dark-25 hover:text-white hover:bg-dark-75"
              >
                Account
              </Link>
              <Link
                to="/settings/help-request"
                onClick={close}
                className="block px-3 py-2 rounded-md font-mobile-body text-dark-25 hover:text-white hover:bg-dark-75"
              >
                Help
              </Link>
              <a
                href="/logout"
                className="block px-3 py-2 rounded-md font-mobile-body text-dark-25 hover:text-white hover:bg-dark-75"
              >
                Log out
              </a>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

const PageOutlet = ({
  pageView,
  isLoadingBanner,
  banner,
  displayBanner,
  handleCloseBanner,
  tourActive,
}) => {
  const { currentStep } = useTour();
  const [debouncedCurrentStep] = useDebounce(currentStep, 200);

  return (
    <>
      {pageView && (
        <>
          {!isLoadingBanner && displayBanner && (
            <div className="bg-primary py-4 px-12 flex justify-center text-white font-body-bold relative">
              <RichText
                value={banner.data.attributes.content}
                specialLinkColor={true}
              />
              <button
                type="button"
                onClick={handleCloseBanner}
                className="absolute text-white focus:outline-none top-5 right-5"
              >
                <i className="ri-close-line ri-lg" />
              </button>
            </div>
          )}
          <section
            id="section-router"
            className={clsx(
              "member-center-outlet",
              tourActive && debouncedCurrentStep === 2
                ? "overflow-hidden"
                : "overflow-x-hidden",
            )}
          >
            <Outlet />
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
          </section>
        </>
      )}
    </>
  );
};

function MemberCenterNavigation() {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { data: pageView } = usePageView();
  const {
    isLoading: isLoadingBanner,
    data: banner,
  }: { isLoading: boolean; data: any } = useQuery(["banners"], () =>
    fetcher(`/api/banners/last`),
  );

  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMobileNav = () => setOpenMobileNav(!openMobileNav);
  const closeMobileNav = () => setOpenMobileNav(false);

  const showSection =
    currentUser?.attributes.isStaff || currentUser?.attributes.isBoardMember;

  const isCurrentLocation = sectionPath => {
    return pathname.startsWith(sectionPath);
  };
  const displayBanner =
    banner?.data.attributes.active && !currentUser?.attributes.didCloseBanner;

  const [tourActive, setTourActive] = useState(false);

  const handleCloseBanner = () => {
    const request = {
      data: {
        id: currentUser.id,
        type: "users",
        attributes: {
          didCloseBanner: true,
        },
      },
    };
    saveResource("users", request).then(() => {
      queryClient.invalidateQueries(["current-user"]);
      queryClient.invalidateQueries(["banners"]);
    });
  };
  const updateUserRequest = () => {
    const request = {
      data: {
        id: currentUser?.id,
        type: "users",
        attributes: { guidedTourCompleted: true },
      },
    };
    return saveResource("users", request);
  };
  const mutation = useMutation(updateUserRequest, {
    onSettled: async () => {
      await queryClient.invalidateQueries(["current-user"]);
    },
  });

  const onOpenUpdateUserGuideStatus = () => {
    mutation.mutate();
  };

  return (
    <div className="min-h-screen h-screen flex flex-col">
      <TourProvider
        steps={GuidedSteps()}
        showCloseButton={false}
        showBadge={false}
        showNavigation={false}
        afterOpen={() => {
          setTourActive(true);
          onOpenUpdateUserGuideStatus();
        }}
        beforeClose={() => setTourActive(false)}
        styles={{
          maskWrapper: base => ({ ...base, color: "#1b2638", opacity: 0.8 }),
          maskArea: base => ({ ...base, rx: 6 }),
          popover: (base, state) => ({
            ...base,
            margin: "18px 0px",
            borderRadius: "4px",
            ...createArrow(
              state.position,
              state.verticalAlign,
              state.horizontalAlign,
            ),
          }),
        }}
        scrollSmooth
      >
        <nav className="bg-black h-16 flex-shrink-0 z-20">
          <div className="margin-safe-nav">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center space-x-5px">
                <div className="flex-shrink-0 lg:mr-5">
                  <Link to="/">
                    <img
                      className="block lg:hidden h-6.5 w-auto pl-1"
                      src={piciLogoMC}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-6.5 w-auto"
                      src={piciLogoMC}
                      alt="Workflow"
                    />
                  </Link>
                </div>
                <DesktopNavigation
                  showSection={showSection}
                  isCurrentLocation={isCurrentLocation}
                  pageView={pageView}
                />
              </div>
              <GlobalSearchDropdown />
              {currentUser && (
                <ProfileSection currentUser={currentUser} pathname={pathname} />
              )}
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <button
                  onClick={toggleMobileNav}
                  className="inline-flex items-center justify-center p-2 rounded-md text-dark-25 hover:text-white focus:outline-none focus:ring-0"
                >
                  <span className="sr-only">Open main menu</span>
                  {openMobileNav ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
          </div>
          {currentUser && openMobileNav && (
            <MobileNavigation
              showSection={showSection}
              isCurrentLocation={isCurrentLocation}
              currentUser={currentUser}
              close={closeMobileNav}
            />
          )}
        </nav>
        <PageOutlet
          {...{
            pageView,
            isLoadingBanner,
            banner,
            displayBanner,
            handleCloseBanner,
            tourActive,
          }}
        />
      </TourProvider>
    </div>
  );
}

export default MemberCenterNavigation;
