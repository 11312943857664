import * as React from "react";
import { Form, Formik } from "formik-latest";
import * as Yup from "yup";
import FocusSlider from "./FocusSlider";

const focusAreaSchema = Yup.object().shape({
  name: Yup.string()
    .required("Title is required.")
    .max(40, "This title is too long. It should have 40 characters or fewer."),
  description: Yup.string().max(
    140,
    "This description is too long. It should have 140 characters or fewer.",
  ),
});

function FocusAreaForm({
  openForm,
  setOpenForm,
  resource,
  editing,
  setEditing,
}) {
  return (
    <Formik
      initialValues={resource.attributes}
      validationSchema={focusAreaSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {formikProps => {
        return (
          <Form className="space-y-5">
            <FocusSlider
              formikProps={formikProps}
              openForm={openForm}
              setOpenForm={setOpenForm}
              editing={editing}
              setEditing={setEditing}
              resource={resource}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default FocusAreaForm;
