import React from "react";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import {
  LinkableNameColumn,
  ActionButtonsColumn,
  itemValue,
  MembersColumn,
  render,
} from "@/components/shared/Admin/Columns";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import NoResults from "@/components/shared/NoResults";
import ResearchCardSkeleton from "./ResearchCardSkeleton";

const columns = [
  {
    name: "title",
    label: "Research Title",
    th: { width: "29%" },
    td: { className: " pt-9px pb-9px" },
    render: (value, item) => (
      <LinkableNameColumn
        text={value}
        to={`/research_documents/${item.id}`}
        isBlank
      />
    ),
  },
  {
    name: "readableType",
    label: "Type",
    th: { width: "10%" },
    td: { className: "pt-9px pb-9px" },
    render: value => <span className="font-meta text-12">{value}</span>,
  },
  {
    name: "authors",
    label: "PICI Authors",
    td: { className: "pt-9px pb-9px" },
    accessor: item => item.relationships.authors.data,
    render: (value, item) => (
      <MembersColumn id={item.id} memberList={value} maxAvatar={3} />
    ),
  },
  {
    name: "status",
    label: "Status",
    th: { width: "10%" },
    td: { className: "pt-9px pb-9px !whitesspace-normal" },
    render: value => (
      <span className="font-meta text-11 uppercase">{value}</span>
    ),
  },
  {
    name: "readers",
    label: "Read By",
    td: { className: "pt-9px pb-9px" },
    accessor: item => item.relationships.readers.data,
    render: (value, item) => (
      <MembersColumn id={item.id} memberList={value} maxAvatar={3} />
    ),
  },
  {
    name: "createdAt",
    label: "Date Posted",
    td: { className: "pt-9px pb-9px" },
    render: value => (
      <span className="font-meta">
        {format(new Date(value), "MMM dd, yyyy")}
      </span>
    ),
  },
  {
    label: "Actions",
    td: { className: "max-w-[190px] pt-9px pb-9px pl-3" },
    accessor: item => item,
    render: value => {
      const items: ActionButtonItemType[] = [
        {
          text: "Edit",
          to: `/research_documents/${value.id}/edit`,
          name: value.attributes.name,
          variant: "btn-admin",
          isBlank: true,
        },
        {
          text: "View",
          to: `/research_documents/${value.id}/download`,
          name: value.attributes.name,
          variant: "btn-admin",
          isBlank: true,
        },
      ];
      return <ActionButtonsColumn items={items} />;
    },
  },
];

function ResearchTable({
  researchItems,
  isFetching,
  isFetchingNextPage,
}: {
  researchItems: any[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
}) {
  return (
    <>
      {!isFetching && researchItems.length === 0 ? (
        <NoResults entityName="research documents" icon="ri-search-line" />
      ) : (
        <Table className="border border-transparent mb-8">
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, i) => (
              <Table.HeaderCell key={i} className="text-11 pb-2" {...column.th}>
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body>
            {researchItems.map((item, index) => (
              <Table.Row key={index} className="divide-x divide-lines">
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={column.td?.className}
                    expand
                  >
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => <ResearchCardSkeleton key={x} />)}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default ResearchTable;
