import * as React from "react";
import { Link } from "react-router-dom";
import Table from "@/components/shared/Table";

const ViewerItem = ({ author }) => {
  const { fullName, centerNameOrInstitution } = author.attributes;
  const { show, avatar, centerShow } = author.links;
  return (
    <Table.Row key={author.id}>
      <Table.Cell className="pr-2">
        <div className="flex items-center">
          <Link to={show} className="flex min-w-max">
            <img
              src={avatar}
              alt=""
              className="h-10 w-10 rounded-full flex-shrink-0"
            />
          </Link>
          <Link to={show} className="ml-4 link">
            <p className="truncate text-dark-100 font-body-bold">{fullName}</p>
          </Link>
        </div>
      </Table.Cell>
      <Table.Cell>
        {centerShow ? (
          <Link to={author.links.centerShow} className="link">
            <p className="font-body text-dark-100">{centerNameOrInstitution}</p>
          </Link>
        ) : (
          <p className="font-body text-dark-100">{centerNameOrInstitution}</p>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

function AuthorsAndViewersTab({ research }) {
  const { readers } = research?.relationships;
  return (
    <div className="flex flex-col gap-10 overflow-auto">
      {readers.data.length > 0 && (
        <Table>
          <Table.Header>
            <Table.HeaderCell>Viewed by</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {readers?.data.map((author, index) => (
              <ViewerItem author={author} key={index} />
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

export default AuthorsAndViewersTab;
