import * as React from "react";
import { useState, useEffect } from "react";
import SuggestionsList from "./SuggestionsList";
import Input from "@/components/shared/Input";
import clsx from "clsx";
import OutsideClickHandler from "@/utils/deps/react-outside-click-handler";

const ENTER_KEYCODE = 13;
const UP_KEY_KEYCODE = 38;
const DOWN_KEY_KEYCODE = 40;

const Autocomplete = ({
  selected,
  setSelected,
  suggestions,
  className = "",
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(selected?.name || "");

  useEffect(() => {
    setInput(selected?.name || "");
  }, [selected]);

  const onChange = e => {
    const userInput = e.target.value;
    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(suggestion =>
      suggestion.name.toLowerCase().includes(userInput.toLowerCase()),
    );
    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = suggestion => {
    setFilteredSuggestions([]);
    setInput(suggestion.name);
    setSelected(suggestion);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = e => {
    if (e.keyCode === ENTER_KEYCODE) {
      setSelected(filteredSuggestions[activeSuggestionIndex]);
      setInput(filteredSuggestions[activeSuggestionIndex]["name"]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      e.preventDefault();
    } else if (e.keyCode === UP_KEY_KEYCODE) {
      if (activeSuggestionIndex === 0) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (e.keyCode === DOWN_KEY_KEYCODE) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  return (
    <div className={clsx("relative", className)}>
      <Input
        className="h-[40px]"
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClick={() => setShowSuggestions(true)}
        value={input}
        placeholder="Type member's name"
        icon={<i className="ri-search-line ri-lg leading-none text-dark-25" />}
      />
      <OutsideClickHandler onOutsideClick={() => setShowSuggestions(false)}>
        {showSuggestions && (
          <SuggestionsList
            filteredSuggestions={filteredSuggestions}
            activeSuggestionIndex={activeSuggestionIndex}
            onClick={onClick}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default Autocomplete;
