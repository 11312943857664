import React from "react";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import NoResults from "@/components/shared/NoResults";
import {
  LinkableNameColumn,
  ActionButtonsColumn,
  itemValue,
  render,
} from "@/components/shared/Admin/Columns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ToogleButton from "@/components/shared/ToogleButton";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import ArticleSkeletonTable from "./ArticleSkeletonTable";
import ReactTooltip from "react-tooltip";

const PublishColumn = ({ value }) => {
  const isPublished = value.attributes.published;
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admin_articles"]);
    },
    onMutate: async () => {
      const oldPagedData: any = queryClient.getQueryData(["admin_articles"]);
      oldPagedData.pages.forEach(page => {
        const updatedArticle = page.data.find(
          article => article.id === value.id,
        );
        if (updatedArticle) {
          updatedArticle.attributes.published = !isPublished;
          return true;
        }
      });
    },
  });
  const handlePublic = () => {
    mutateAsync(fetch(`/api/articles/${value.id}/toggle`));
  };
  return <ToogleButton active={isPublished} onClick={handlePublic} />;
};

function ArticlesTable({ articles, isFetching, isFetchingNextPage }) {
  const columns = [
    {
      name: "thumbnailImageUrl",
      label: "Thumbnail",
      td: { className: "w-[40px] pt-9px pb-9px" },
      render: value => (
        <img className="h-10 w-10 rounded-full ml-4" src={value} />
      ),
    },
    {
      name: "quarterName",
      label: "Quarter",
      td: { className: "pt-9px pb-9px" },
    },
    {
      name: "title",
      label: "TITLE",
      td: { className: "pt-9px pb-9px" },
      render: (value, item) => {
        const { attributes } = item;
        const isPublished =
          attributes.isRetreatPublished && attributes.published;

        const textTooltip = isPublished
          ? "This Article is able to seen on the Member Center."
          : "This Article can not be seen on the Member Center until their publication and Retreat is published.";

        return (
          <>
            <div className="flex" data-for={item.id} data-tip={textTooltip}>
              {isPublished ? (
                <i className="ri-checkbox-circle-line mr-2 text-green-700 text-base" />
              ) : (
                <i className="ri-error-warning-line mr-2 text-orange text-base" />
              )}
              <LinkableNameColumn
                text={value}
                to={`/retreats/article/${item.id}`}
                isBlank
              />
            </div>
            <ReactTooltip id={item.id} multiline={true} />
          </>
        );
      },
    },
    {
      name: "presentationDate",
      label: "Presentation Date",
      td: { className: "pt-9px pb-9px" },
      render: value => (
        <span className="font-meta">
          {format(new Date(value), "MMM dd, yyyy")}
        </span>
      ),
    },
    {
      name: "published",
      label: "Publish",
      td: { className: "w-100px pt-9px pb-9px" },
      accessor: item => item,
      render: value => <PublishColumn value={value} />,
    },
    {
      label: "Actions",
      td: { className: "w-[80px] pt-9px pb-9px pr-2 pl-3 text-center" },
      accessor: item => item,
      render: value => {
        const items: ActionButtonItemType[] = [
          {
            text: "Preview",
            to: `/retreats/article/${value.id}`,
            name: value.attributes.title,
            variant: "btn-admin",
            isBlank: false,
            isSPA: true,
          },
          {
            text: "Edit",
            to: `/admin/retreats/${value.attributes.retreatId}/articles/${value.id}/edit`,
            name: value.attributes.title,
            variant: "btn-admin",
            isBlank: false,
            isSPA: true,
          },
        ];
        return <ActionButtonsColumn items={items} />;
      },
    },
  ];

  return (
    <>
      {!isFetching && articles.length === 0 ? (
        <NoResults entityName="retreats" icon="ri-search-line" />
      ) : (
        <Table
          className="border border-transparent text-sm table-fixed mb-8"
          data-testid="initiatives-table"
        >
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, index) => (
              <Table.HeaderCell key={index} className="text-11 pb-2">
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body className="border border-lines">
            {articles.map((item, index) => (
              <Table.Row key={index} className="divide-x divide-lines">
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={column.td?.className}
                    expand
                  >
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => <ArticleSkeletonTable key={x} />)}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default ArticlesTable;
