function uniqueBy<T>(arr: T[], key: string): T[] {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}
function groupContiguousBy(items = [], key) {
  if (items.length === 0) return [];
  return items.reduce((acc, cur) => {
    const lastItem = acc.slice(-1)[0];
    if (!!lastItem && cur[key] === lastItem[key]) {
      lastItem.items.push(cur);
    } else {
      acc.push({
        [key]: cur[key],
        items: [cur],
      });
    }
    return acc;
  }, []);
}
function byId(items) {
  return items.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});
}

export { uniqueBy, groupContiguousBy, byId };
