import * as React from "react";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";

function VersionTab() {
  const { data: version, isLoading: isLoading } = useQuery(["version"], () =>
    fetcher("/api/version"),
  );

  return (
    <div>
      <h2 className="hidden md:block text-dark-100 font-h2 pb-5 border-b border-lines">
        Version
      </h2>
      <div className="pb-7.5 md:py-7.5">
        {isLoading ? (
          <div className="flex flex-col gap-2 animate-pulse">
            <div className="bg-dark-25 h-4 w-52 rounded-full" />
            <div className="bg-dark-25 h-4 w-32 rounded-full" />
          </div>
        ) : (
          <div>
            <h3 className="font-meta-bold text-dark-100">
              Release version {version.version_number}
            </h3>
            <p className="font-meta text-dark-75">
              {format(new Date(version.created_at), "MMM dd, yyyy")}
            </p>
          </div>
        )}
        <a
          target="_blank"
          href="https://www.notion.so/ableco/Member-Center-Release-Notes-0c7831ef1cb345b0bffadc6411e91b4f"
          className="btn-primary btn-md w-min mt-7.5"
        >
          See all versions
          <i className="ri-external-link-line ml-2.5"></i>
        </a>
      </div>
    </div>
  );
}
export default VersionTab;
