import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

function FocusAreaTags({ focusAreas, className = "", theme = "" }) {
  if (!focusAreas || focusAreas.length === 0) return null;

  return (
    <div className={clsx("flex flex-wrap gap-2.5", className)}>
      {focusAreas.map(focusArea => {
        return (
          <Link
            key={focusArea.id}
            to={`/search?term=${focusArea.attributes.name}&open_searchbar=true&focusAreas=${focusArea.id}&ignore_term=true`}
            className={clsx(
              "px-2.5 py-0.5 rounded font-bold text-13 hover:underline",
              theme === "dark"
                ? "text-white bg-white bg-opacity-20"
                : "bg-primary-light text-primary-dark",
            )}
          >
            {focusArea.attributes.name}
          </Link>
        );
      })}
    </div>
  );
}

export default FocusAreaTags;
