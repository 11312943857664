import React, { useMemo, useState } from "react";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import NotificationEmail from "./NotificationEmail";
import { denormalizedFetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import Tabs from "./Tabs";
import PCTPatentPending from "./Sections/PctPatentPending";
import NationalPatentPending from "./Sections/NationalPatentPending";
import PCTPatentExpired from "./Sections/PctPatentExpired";
import NationalPatentExpired from "./Sections/NationalPatentExpired";
import Tooltip from "@/components/shared/Tooltip";

const fetchPatentDeadline = () => {
  const baseUrl = `/api/inventions/notifications_deadlines`;
  return denormalizedFetcher(baseUrl);
};

const defaultTabs = [
  {
    id: "pctPatentPending",
    name: "Upcoming PCT Conversion Deadlines",
    amount: null,
    mailIncluded: null,
  },
  {
    id: "nationalPatentPending",
    name: "Upcoming National Phase Deadlines",
    amount: null,
    mailIncluded: null,
  },
  {
    id: "pctPatentExpired",
    name: "Missing Info on PCT Conversion",
    amount: null,
    mailIncluded: null,
  },
  {
    id: "nationalPatentExpired",
    name: "Missing Info on National Phase Conversion",
    amount: null,
    mailIncluded: null,
  },
];

const TabSection = ({ tab }) => {
  switch (tab.id) {
    case "pctPatentPending":
      return <PCTPatentPending id={tab.id} />;
    case "nationalPatentPending":
      return <NationalPatentPending id={tab.id} />;
    case "pctPatentExpired":
      return <PCTPatentExpired id={tab.id} />;
    case "nationalPatentExpired":
      return <NationalPatentExpired id={tab.id} />;
    default:
      return null;
  }
};

function PatentDeadlinesPage() {
  const [selectedTab, setSelectedTab] = useState(defaultTabs[0]);
  const { data, isFetching } = useQuery(
    ["patent_deadline_count"],
    fetchPatentDeadline,
  );

  const tabs = useMemo(() => {
    return defaultTabs.map(tab => {
      tab.amount = data?.data[tab.id].amount;
      tab.mailIncluded = data?.data[tab.id].mailIncluded;
      return tab;
    });
  }, [defaultTabs, data]);

  const showInfo = ["pctPatentPending", "nationalPatentPending"].includes(
    selectedTab?.id,
  );

  return (
    <>
      <AdminHeader
        title="Patent Deadlines"
        description={
          <span>
            Overview of upcoming Patent filing deadlines{" "}
            <span className="text-indigo-600">(in next 90 days)</span>
          </span>
        }
        linkText="New Invention"
        linkTo="/admin/inventions/new"
        linkReloadDocument
      />
      <div className="overflow-x-hidden h-vh-60 bg-primary-lighter">
        <div className="px-8 pt-4 pb-8">
          <Tabs
            tabs={tabs}
            isFetching={isFetching}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <div className="max-w-[936px]">
            <div className="bg-white border border-lines rounded shadow w-full  mt-4">
              <header className="px-5 py-3">
                <h2 className="text-dark-100 text-22 font-bold flex items-center">
                  Detailed Overview{" "}
                  {showInfo && (
                    <Tooltip>
                      <Tooltip.Trigger className="ml-2 text-11 h-4 w-4 bg-gray-200 rounded-full ">
                        <i className="ri-question-mark font-bold h-2 w-4 flex items-center justify-center"></i>
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        <span>
                          Condition: Invention has been acepted and is
                          unlicensed
                        </span>
                      </Tooltip.Content>
                    </Tooltip>
                  )}
                </h2>
              </header>
              <TabSection tab={selectedTab} />
            </div>
          </div>

          <div className="max-w-[936px]">
            <div className="bg-white border border-lines rounded shadow w-full  mt-4">
              <header className="px-5 py-3">
                <h2 className="text-dark-100 text-22 font-bold">
                  Notification Email Management
                  <p className="font-meta text-dark-50">
                    An automatic email is sent the first Monday in each month
                    listing the inventions that are both accepted and
                    unlicensed.
                  </p>
                </h2>
              </header>
              <NotificationEmail upcomingDate={data?.upcoming_email_date} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatentDeadlinesPage;
