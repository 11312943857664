import * as React from "react";
import * as R from "ramda";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { humanize } from "@/utils/string";
import Tooltip from "@/components/shared/Tooltip";
import Timeline from "@/components/shared/Timeline";
import AsideComponent from "@/components/shared/Aside";
import { UPDATED_REVIEW_STATUS } from "@/components/shared/Inventions/defaults";

const sortByDate = R.sortWith([R.ascend(R.prop("date"))]);

const REVIEW_STATUS = {
  accepted: "Accepted",
  declined: "Declined",
};

const DECLINED_STATUS = ["declined", "decision_reverted_declined"];

const isDeclined = status => !DECLINED_STATUS.includes(status);

const PublishedPatentApplication = ({ publishLink }) => {
  const { url } = publishLink;

  return (
    <li className="flex items-center">
      <i className="ri-global-line text-dark-50 ri-lg mt-0.5"></i>
      <a
        href={url}
        className="link ml-1.5 font-body-bold text-dark-100 truncate"
        target="_blank"
      >
        {url}
      </a>
    </li>
  );
};

const LicenseeItem = ({ licensee }) => {
  const { notes, company, date, status } = licensee;

  return (
    <li>
      <div className="flex">
        <p className="text-dark-100 font-body-bold">{company}</p>
        {notes && (
          <div className="ml-1.5">
            <Tooltip>
              <Tooltip.Trigger className="h-6 w-6">
                <i className="ri-information-line ri-xl text-dark-75" />
              </Tooltip.Trigger>
              <Tooltip.Content>{notes}</Tooltip.Content>
            </Tooltip>
          </div>
        )}
      </div>
      <p className="text-dark-75 font-meta capitalize">
        Status: {status || "N/A"}
      </p>
      {date !== "" && (
        <span className="text-dark-75 font-meta">
          {format(new Date(date), "MMM dd, yyyy")}
        </span>
      )}
    </li>
  );
};

function Aside({ invention }) {
  const {
    disclosure,
    review,
    patentLifecycle,
    licensees,
    publishedPatentApplication,
    abstractLink,
    manuscriptLink,
    updatedStatus,
  } = invention.attributes;

  const sortedPatentLifecycle = sortByDate(
    patentLifecycle,
  ).map((cycle, index) => ({ ...cycle, index: index }));
  let isPriorityDate = false;

  const hasMoreThanOneProvisionalFiling =
    sortedPatentLifecycle.filter(
      lifeCycle => lifeCycle.phase == "provisional_filing",
    ).length > 1;
  const firstValidProvisionalFillings = sortedPatentLifecycle.filter(
    lifeCycle =>
      lifeCycle.phase == "provisional_filing" && lifeCycle.tbd == false,
  )[0];
  return (
    <AsideComponent className="ml-0 mt-5 lg:mt-10 mb-7.5 lg:ml-7.5 lg:mb-0">
      <AsideComponent.Section>
        {licensees.length > 0 && (
          <AsideComponent.Content>
            <AsideComponent.Title
              title="Licensees"
              className="font-label text-dark-75 mb-1 uppercase"
            />
            <ul className="grid gap-6">
              {licensees.map((licensee, index) => (
                <LicenseeItem key={`licensee${index}`} {...{ licensee }} />
              ))}
            </ul>
          </AsideComponent.Content>
        )}
        {publishedPatentApplication.length > 0 && (
          <AsideComponent.Content className="truncate">
            <AsideComponent.Title
              title="Published Patent Application"
              className="font-label text-dark-75 mb-1 uppercase"
            />
            <ul>
              {publishedPatentApplication.map((publishLink, index) => (
                <PublishedPatentApplication
                  key={`publishLink${index}`}
                  {...{ publishLink }}
                />
              ))}
            </ul>
          </AsideComponent.Content>
        )}
        {abstractLink && (
          <AsideComponent.Content className="truncate">
            <AsideComponent.Title
              title="Published Marketing Abstract"
              className="font-label text-dark-75 mb-1 uppercase"
            />
            <ul>
              <PublishedPatentApplication
                key="abstractLink"
                publishLink={{ url: abstractLink }}
              />
            </ul>
          </AsideComponent.Content>
        )}
        {manuscriptLink && (
          <AsideComponent.Content className="truncate">
            <AsideComponent.Title
              title="Published Manuscripts"
              className="font-label text-dark-75 mb-1 uppercase"
            />
            <ul>
              <PublishedPatentApplication
                key="manuscriptLink"
                publishLink={{ url: manuscriptLink }}
              />
            </ul>
          </AsideComponent.Content>
        )}
        <AsideComponent.Content>
          <Timeline title="Parker Institute Review">
            <Timeline.Item
              phase="disclosed"
              date={disclosure.date}
              formatDate="MMMM d, yyyy"
              index={0}
            />
            {review.review_status !== "pending" && (
              <Timeline.Item
                phase={REVIEW_STATUS[review.review_status]}
                date={review.date}
                formatDate="MMMM d, yyyy"
                notes={review.notes}
                index={1}
                status={isDeclined(review.review_status)}
              />
            )}
            {!isEmpty(updatedStatus) && (
              <Timeline.Item
                phase={UPDATED_REVIEW_STATUS[updatedStatus.review_status]}
                phaseClassName="max-w-[130px]"
                date={updatedStatus.date}
                formatDate="MMMM d, yyyy"
                notes={updatedStatus.notes}
                index={1}
                status={isDeclined(updatedStatus.review_status)}
              />
            )}
          </Timeline>
        </AsideComponent.Content>
        {patentLifecycle.length > 0 && (
          <AsideComponent.Content>
            <Timeline title="Patent Cycle">
              {sortedPatentLifecycle.map(cycle => {
                const phase =
                  cycle.phase === "pct_filing" ? "PCT_filing" : cycle.phase;

                isPriorityDate =
                  hasMoreThanOneProvisionalFiling &&
                  firstValidProvisionalFillings.index == cycle.index;
                const isAbandoned = phase === "abandoned";
                return (
                  <Timeline.Item
                    phase={humanize(phase)}
                    date={cycle.date !== "" ? cycle.date : "TBD"}
                    formatDate="MMMM d, yyyy"
                    notes={cycle.notes}
                    key={cycle.index}
                    showPriorityDatelabel={isPriorityDate}
                    index={cycle.index}
                    status={!isAbandoned}
                  />
                );
              })}
            </Timeline>
          </AsideComponent.Content>
        )}
      </AsideComponent.Section>
    </AsideComponent>
  );
}

export default Aside;
