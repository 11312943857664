import * as React from "react";
import Modal from "@/components/shared/Modal";

function UpdateConfirmationModal({ open, setOpen, onAccept }) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Body>
        Editing a focus area that is already associated with project and
        research may result in the association being innacurate.
      </Modal.Body>
      <Modal.Footer className="w-full flex justify-between mt-5">
        <button
          type="button"
          className="btn-white btn-md rounded-3px"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button
          type="button"
          className="btn-primary btn-md rounded-3px"
          onClick={onAccept}
        >
          Yes, Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateConfirmationModal;
