import React, { createContext, useContext } from "react";
import clsx from "clsx";
import { Disclosure } from "@headlessui/react";

const AccordionContext = createContext({ open: false });

function Accordion({ children }) {
  return (
    <Disclosure
      as="div"
      className="py-4 border-solid border-b border-gray-200 last:border-b-0"
    >
      {({ open }) => (
        <AccordionContext.Provider value={{ open: open }}>
          {children}
        </AccordionContext.Provider>
      )}
    </Disclosure>
  );
}

Accordion.Label = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { open } = useContext(AccordionContext);

  return (
    <Disclosure.Button className="text-left w-full flex justify-between items-center">
      <span className="font-h3 text-dark-100">{children}</span>
      <span className="ml-6">
        <i
          className={clsx(
            open ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line",
            "ri-lg text-dark-50",
          )}
          aria-hidden="true"
        />
      </span>
    </Disclosure.Button>
  );
};

Accordion.Content = ({ children }) => {
  return (
    <Disclosure.Panel as="div" className="mt-3 font-body text-dark-100">
      {children}
    </Disclosure.Panel>
  );
};

export default Accordion;
