import React, { useState, useMemo } from "react";
import clsx from "clsx";
import Table from "@/components/shared/Table";
import { render } from "@/components/shared/Admin/Columns";

const ENROLLMENT_TYPE = {
  BY_CLINICAL: "By Clinical",
  BY_INSTITUTION: "By Institution",
  OTHERS: "Others",
};

export const itemValue = (item, column) => {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item[column.name];
  }
};

const ExpanderRows = ({ label, isExpanded, setIsExpanded }) => {
  const expander = isExpanded ? (
    <div className="flex cursor-pointer hover:text-primary text-14">
      <span className="my-auto">Less</span>
      <i className="ri-arrow-drop-up-line ri-2x font-normal ml-auto leading-none" />
    </div>
  ) : (
    <div className="flex cursor-pointer hover:text-primary text-14">
      <span className="my-auto">More</span>
      <i className="ri-arrow-drop-down-line ri-2x font-normal ml-auto leading-none" />
    </div>
  );

  return (
    <div className="flex" onClick={() => setIsExpanded(!isExpanded)}>
      {label}
      {expander}
    </div>
  );
};

function PatientEnrollmentByClinicalSite({
  className,
  enrollmentByCenter,
  enrollmentByInstitution,
  enrollmentAllInstitutions,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const othersItem = {
    type: ENROLLMENT_TYPE.OTHERS,
    enrolled: enrollmentAllInstitutions.enrolled,
    trials: enrollmentAllInstitutions.trials,
  };
  const wholeData = useMemo(
    () => [
      ...enrollmentByCenter,
      othersItem,
      ...enrollmentByInstitution.map(item => ({
        ...item,
        type: ENROLLMENT_TYPE.BY_INSTITUTION,
      })),
    ],
    [enrollmentByInstitution, enrollmentByCenter, othersItem],
  );
  const columns = [
    {
      name: "organization",
      label: "Site",
      render: (value, item) => {
        if (item.type === ENROLLMENT_TYPE.OTHERS) {
          return (
            <ExpanderRows
              label={
                <div className="mr-2 text-dark-100 font-bold text-14 my-auto">
                  Others
                </div>
              }
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          );
        }
        return (
          <span
            className={clsx({
              "text-dark-100 font-bold":
                item.type !== ENROLLMENT_TYPE.BY_INSTITUTION,
              "pl-2": item.type == ENROLLMENT_TYPE.BY_INSTITUTION,
            })}
          >
            {value}
          </span>
        );
      },
    },
    {
      name: "trials",
      label: "# Of Trials",
      render: (value, item) => (
        <span
          className={clsx({
            "font-bold": isExpanded && item.type === ENROLLMENT_TYPE.OTHERS,
          })}
        >
          {value}
        </span>
      ),
    },
    {
      name: "enrolled",
      label: "Patients Enrolled to Date",
      render: (value, item) => (
        <span
          className={clsx({
            "font-bold": isExpanded && item.type === ENROLLMENT_TYPE.OTHERS,
          })}
        >
          {value}
        </span>
      ),
    },
  ];

  return (
    <div
      className={clsx("bg-white border border-lines rounded shadow", className)}
    >
      <header className="px-5 py-3">
        <h2 className="text-dark-100 text-22">
          Patient Enrollment by Clinical Site{" "}
          <span className="text-dark-50 ml-1.5 text-14">(All time)</span>
        </h2>
      </header>
      <Table className="border border-transparent mb-8 px-5">
        <Table.Header className="bg-transparent text-dark-50">
          {columns.map((column, i) => (
            <Table.HeaderCell
              key={i}
              className="text-11 pb-2 border-b border-lines"
            >
              {column.label}
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body>
          {wholeData
            .filter(
              item =>
                item.type !== ENROLLMENT_TYPE.BY_INSTITUTION || isExpanded,
            )
            .map((item, index) => (
              <Table.Row
                key={index}
                className={clsx("!border-0", { "bg-tint-10": index % 2 })}
              >
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={clsx("!py-1", {
                      "border-lines border-b":
                        item.type === ENROLLMENT_TYPE.OTHERS && isExpanded,
                    })}
                    expand
                  >
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default PatientEnrollmentByClinicalSite;
