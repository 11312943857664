function useCSRFToken() {
  const csrfMeta: HTMLMetaElement | null = document.querySelector(
    "meta[name='csrf-token']",
  );

  const token = csrfMeta?.content;

  const setToken = token => {
    if (csrfMeta) {
      csrfMeta.setAttribute("content", token);
    }
  };

  return { token, setToken };
}

export default useCSRFToken;
