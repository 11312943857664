import { denormalizedFetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import DetailedOverview from "../DetailedOverview";
import { calculateOverdue } from "../calculateOverdue";
import * as React from "react";
import { sortBy } from "lodash";
import getFistFiling from "../getFirstFiling";

const TODAY = new Date();

const fetchNationalPatentExpired = () => {
  const baseUrl = "/api/inventions/patent_filters/?filters=national_expired";
  return denormalizedFetcher(baseUrl);
};

function NationalPatentExpired({ id }) {
  const { data, isFetching } = useQuery(
    ["patent_deadline", id],
    fetchNationalPatentExpired,
  );
  const patents = React.useMemo(() => {
    const calculdatedOverdue = calculateOverdue(data?.data, TODAY);
    return sortBy(calculdatedOverdue, invention => getFistFiling(invention));
  }, [data]);

  return <DetailedOverview data={patents} isFetching={isFetching} />;
}

export default NationalPatentExpired;
