import Table from "@/components/shared/Table";
import React from "react";

function EventSkeletonContent() {
  return (
    <article
      id="article-container"
      className="overflow-x-hidden h-vh-60 px-7.5"
    >
      <h2 className="flex mt-5 ml-2 text-22 font-medium text-dark-100">
        <i className="ri-calendar-todo-line text-dark-50 mr-3" />
        Active
      </h2>
      <Table className="border border-transparent text-sm table-fixed animate-pulse">
        <Table.Header className="bg-transparent text-dark-50">
          <Table.HeaderCell className="text-11 pb-2">Display</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Title</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Author</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">
            Date posted
          </Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Date(s)</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Actions</Table.HeaderCell>
        </Table.Header>
        <Table.Body className="border border-lines">
          {[1, 2, 3, 4].map(x => (
            <Table.Row key={x} className="divide-x divide-lines">
              <Table.Cell>
                <div className="grid grid-cols-2 gap-4">
                  <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
                  <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell className="w-[100px]">
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <h2 className="flex mt-8 ml-2 text-22 font-medium text-dark-100">
        <i className="ri-calendar-todo-line text-dark-50 mr-3" />
        Archived
      </h2>
      <Table className="border border-transparent text-sm table-fixed animate-pulse">
        <Table.Header className="bg-transparent text-dark-50">
          <Table.HeaderCell className="text-11 pb-2">Title</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Author</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">
            Date posted
          </Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Date(s)</Table.HeaderCell>
          <Table.HeaderCell className="text-11 pb-2">Actions</Table.HeaderCell>
        </Table.Header>
        <Table.Body className="border border-lines">
          {[1, 2, 3, 4].map(x => (
            <Table.Row key={x} className="divide-x divide-lines">
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell>
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
              <Table.Cell className="w-[100px]">
                <div className="h-2 bg-slate-200 bg-dark-25 rounded"></div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </article>
  );
}

export default EventSkeletonContent;
