import React from "react";
import {
  BarChart,
  Bar,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import { isEmpty } from "lodash";

const COLORS = [
  "#3ab5ae",
  "#014975",
  "#ff6c2f",
  "#edb837",
  "#f37a7a",
  "#81a130",
  "#4a4189",
  "#a6e2d7",
  "#ff966b",
  "#958cd8",
  "#f3d484",
  "#0277bd",
];

const YTick = props => {
  const {
    verticalAnchor,
    visibleTicksCount,
    tickFormatter,
    ...otherProps
  } = props;
  return (
    <text {...otherProps} className="!text-[12px]">
      <tspan dx="-10" dy="0.335em">
        {props.payload.value}
      </tspan>
    </text>
  );
};

const CustomTooltip = ({
  active,
  payload,
  multipleBar = false,
  multipleBarDataKeys = [],
  multipleBarDataLabels = [],
  multipleBarColorLabels = [],
}: {
  active?: boolean;
  payload?: any;
  multipleBar?: boolean;
  multipleBarDataKeys?: string[];
  multipleBarDataLabels?: string[];
  multipleBarColorLabels?: string[];
}) => {
  if (active && !isEmpty(payload)) {
    const barData = payload[0].payload;
    const { count } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        {multipleBar && (
          <ul className="">
            {multipleBarDataKeys.map((dataKeyItem, index) => (
              <li className="flex" key={index}>
                <span
                  className="mr-1"
                  style={{ color: multipleBarColorLabels[index] }}
                >
                  ●
                </span>
                {multipleBarDataLabels[index]}:
                <span className="ml-1 font-bold">{barData[dataKeyItem]}</span>
              </li>
            ))}
          </ul>
        )}
        {!multipleBar && (
          <p className="font-meta">
            <span className="font-bold">{count}</span>
          </p>
        )}
      </div>
    );
  }
  return null;
};

type InsightBarChartProps = {
  data: any[];
  dataKey: string;
  showList?: boolean;
  dataLines?: any[];
  colors?: any;
  tooltip?: any;
  barDataKey?: string;
  multipleBar?: boolean;
  multipleBarDataKeys?: string[];
  multipleBarDataLabels?: string[];
  multipleBarColorLabels?: string[];
  showXAxisLabel?: boolean;
};

function InsightBarChart({
  data,
  dataKey,
  showList = false,
  dataLines = [],
  colors = {},
  tooltip,
  barDataKey = "count",
  multipleBar = false,
  multipleBarDataKeys = [],
  multipleBarDataLabels = [],
  multipleBarColorLabels = [],
  showXAxisLabel = true,
}: InsightBarChartProps) {
  if (!tooltip) {
    tooltip = (
      <CustomTooltip
        multipleBar={multipleBar}
        multipleBarDataKeys={multipleBarDataKeys}
        multipleBarDataLabels={multipleBarDataLabels}
        multipleBarColorLabels={multipleBarColorLabels}
      />
    );
  }
  return (
    <>
      {showList && (
        <ul className="mt-4 mb-6 text-15">
          {dataLines.map((dataLine, index) => (
            <li key={index} className="flex justify-between mb-3">
              <div className="max-w-[277px]">
                <p className="overflow-hidden whitespace-nowrap overflow-ellipsis font-body-bold text-dark-75">
                  <span className="mr-1" style={{ color: COLORS[index] }}>
                    ●
                  </span>
                  {dataLine.dataLabel}
                </p>
              </div>
              <p className="font-body-bold text-dark-75">
                {dataLine.totalCount}
              </p>
            </li>
          ))}
        </ul>
      )}
      <ResponsiveContainer width={"100%"} height={202}>
        <BarChart
          width={340}
          height={177}
          data={data}
          margin={{ top: 0, left: -3, right: 0, bottom: 0 }}
        >
          <CartesianGrid stroke="#CFD3DA99" strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} stroke="#8590A1" tick={showXAxisLabel} />
          <YAxis tick={<YTick />} width={40} stroke="#8590A1" />
          {multipleBar && <Legend iconType="circle" iconSize={8} />}
          <Tooltip content={tooltip} cursor={{ fill: "#EAECF0" }} />
          {data &&
            (multipleBar ? (
              multipleBarDataKeys?.map((barKeyItem, index) => (
                <Bar
                  key={index}
                  fill={multipleBarColorLabels[index]}
                  dataKey={barKeyItem}
                  name={multipleBarDataLabels[index]}
                  barSize={72}
                />
              ))
            ) : (
              <Bar dataKey={barDataKey} barSize={72}>
                {data.map((el, index) => (
                  <Cell
                    key={el.id}
                    fill={
                      isEmpty(colors)
                        ? COLORS[index % COLORS.length]
                        : colors[el.name].color
                    }
                  />
                ))}
              </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default InsightBarChart;
