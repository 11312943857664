import * as React from "react";
import clsx from "clsx";
import logoLogin from "@/images/logo-login.png";

OnboardingLayout.Header = ({ children }) => {
  return (
    <header className="pb-6">
      <img className="mb-8 h-55px" src={logoLogin} alt="" />
      {children}
    </header>
  );
};

OnboardingLayout.Body = ({ children }) => {
  return (
    <main>
      <section>{children}</section>
    </main>
  );
};

function OnboardingLayout({ children }) {
  return (
    <div
      className={clsx(
        "w-full min-h-screen h-auto bg-login-image bg-cover bg-center",
        "flex flex-col justify-center md:justify-start items-center pt-0 md:pt-20",
      )}
    >
      <div className="max-w-574px bg-tint rounded p-10 mx-4 md:m-0">
        {children}
      </div>
    </div>
  );
}

export default OnboardingLayout;
