import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import CentersTable from "./CentersTable";
import CenterSkeletonTable from "./CenterSkeletonTable";
import AdminHeader from "@/components/shared/Admin/AdminHeader";

const fetchCenters = () => {
  const baseUrl = `/api/centers`;
  const params = paramBuilder({
    include: ["members"],
    fields: {
      centers: ["name", "slug", "location", "publicAt", "members"],
      users: ["fullName", "slug", "avatarUrl"],
    },
  });
  const sortParam = "&sort=name";
  const url = `${baseUrl}?${params}${sortParam}`;
  return denormalizedFetcher(url);
};

function CentersPage() {
  const {
    isLoading,
    data: centers,
  }: { isLoading: boolean; data: any } = useQuery(["centers"], fetchCenters);

  return (
    <div className="bg-tint h-screen">
      <AdminHeader
        title="Centers"
        description="All centers involved in PICI."
        linkText="+ New Center"
        linkTo="/admin/centers/new"
      />
      <article
        id="article-container"
        className="overflow-x-hidden h-vh-60 px-7.5"
      >
        {isLoading ? (
          <CenterSkeletonTable />
        ) : (
          <CentersTable data={centers.data} />
        )}
      </article>
    </div>
  );
}

export default CentersPage;
