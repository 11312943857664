import { useState, useLayoutEffect } from "react";

const useRerender = (timeout = 16) => {
  const [_, setForceRerender] = useState(false);
  useLayoutEffect(() => {
    const id = setTimeout(() => setForceRerender(true), timeout);
    return () => clearTimeout(id);
  }, []);
};

export default useRerender;
