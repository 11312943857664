import * as React from "react";
import clsx from "clsx";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";
import Select from "@/components/shared/Select";
import RadioButtonGroup from "@/components/shared/RadioButtonGroup";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import TextArea from "@/components/shared/TextArea";
import CenterOrInstitutionInput from "../CenterOrInstitutionInput/CenterOrInstitutionInput";
import { FilterOption } from "@/types";
import useCurrentUser from "@/hooks/useCurrentUser";

const STAFF_ROLES = ["Employee", "PiciAdmin"];
const PAPER_RELATED_ROLES = [
  "InvestigatorProject",
  "InvestigatorEMR",
  "InvestigatorFull",
  "BoardMember",
];
const INVESTIGATOR_ROLES = [
  "InvestigatorProject",
  "InvestigatorEMR",
  "InvestigatorFull",
];

const INVESTIGATOR_TYPE = ["InvestigatorProject", "InvestigatorEMR"];

const falseOption: FilterOption = { id: false, name: "No" };

const booleanOptions: FilterOption[] = [{ id: true, name: "Yes" }, falseOption];

function BasicInfo({
  user,
  setFieldValue,
  selectedRole,
  setSelectedRole,
  roles,
  centers,
  institutions,
  projects,
  ...props
}) {
  const currentUser = useCurrentUser();
  const disableIfnotPiciAdmin =
    user.type == "PiciAdmin" && currentUser.attributes.type != "PiciAdmin";
  const isNotPiciAdmin = currentUser.attributes.type !== "PiciAdmin";
  return (
    <div>
      <div>
        <section className="flex flex-col gap-5.5 px-8">
          <div className="flex flex-wrap gap-4.5 justify-between">
            <FormBlock
              label="First Name"
              name="firstName"
              className="flex-1"
              labelClassName="font-body-bold"
            >
              <Input value={user.firstName} styleAs="small" />
            </FormBlock>
            <FormBlock
              label="Last Name"
              name="lastName"
              className="flex-1"
              labelClassName="font-body-bold"
            >
              <Input value={user.lastName} styleAs="small" />
            </FormBlock>
          </div>
          <FormBlock label="Email" name="email" labelClassName="font-body-bold">
            <Input
              value={user.email}
              styleAs="small"
              disabled={isNotPiciAdmin}
              className={clsx({
                "opacity-40 cursor-default": isNotPiciAdmin,
              })}
            />
          </FormBlock>
          <FormBlock
            label="Phone Number"
            name="phoneNumber"
            labelClassName="font-body-bold"
            className="w-1/2"
            optional
          >
            <Input
              value={user.phoneNumber}
              styleAs="small"
              placeholder="(999) 999-9999"
            />
          </FormBlock>
        </section>
      </div>
      <div>
        <p className="font-label bg-lines my-6 p-1.5 text-dark-50 px-8">
          Role & Paperwork
        </p>
        <section className="flex flex-col gap-5.5 px-8">
          <FormBlock label="Role" name="type" labelClassName="font-body-bold">
            <Select
              selected={user.type}
              setSelected={(val = "") => {
                setFieldValue("center", "");
                setFieldValue("project_id", "");
                setFieldValue("type", val);
                setSelectedRole(val);
              }}
              options={roles}
              placeholder="Select a role"
              className="h-10 text-15 rounded font-body"
              placement="top-end"
              disabled={disableIfnotPiciAdmin}
            />
          </FormBlock>
          {selectedRole && !STAFF_ROLES.includes(selectedRole) && (
            <FormBlock name="center" labelClassName="font-body-bold">
              <CenterOrInstitutionInput
                values={user}
                onChange={setFieldValue}
                setTouched={props.setTouched}
                {...{ centers, institutions }}
              />
            </FormBlock>
          )}
          {INVESTIGATOR_TYPE.includes(selectedRole) && (
            <FormBlock
              label="Project"
              name="project_id"
              labelClassName="font-body-bold"
              optional
            >
              <Select
                selected={user.project_id}
                setSelected={(val = "") => {
                  setFieldValue("project_id", val);
                }}
                options={projects}
                placeholder="Select a project"
                className="h-10 text-15 rounded font-body"
                placement="top-end"
                classNameSelectGroup="max-h-100 overflow-auto"
              />
            </FormBlock>
          )}
          {PAPER_RELATED_ROLES.includes(selectedRole) && (
            <div>
              <fieldset>
                <legend className="font-body-bold mb-1">Paperwork</legend>
                <p className="font-meta text-dark-75">
                  Is paperwork required for this member?
                </p>
                <div className="mt-2 flex space-x-8">
                  <RadioButtonGroup
                    options={booleanOptions}
                    selectedOption={
                      booleanOptions.find(
                        el => el.id === user.paperworkRequired,
                      ) || booleanOptions[0]
                    }
                    setSelectedOption={val =>
                      setFieldValue("paperworkRequired", val.id)
                    }
                    parentClass="flex gap-8"
                  />
                </div>
              </fieldset>
              {user.paperworkRequired && (
                <div className="flex flex-col gap-5.5 pt-2">
                  <FormBlock
                    type="file"
                    label="Participation Agreement"
                    name="participationAgreement"
                    labelClassName="font-body-bold pb-2"
                    optional
                  >
                    <FilePickerAdmin
                      setSignedId={val =>
                        setFieldValue("participationAgreement", val)
                      }
                      attachment={{
                        signedId: user.participationAgreement,
                        metadata: user.participationAgreementMetadata,
                        imageUrl: user.participationAgreementUrl,
                      }}
                      name="participationAgreement"
                    />
                  </FormBlock>
                  <FormBlock
                    type="file"
                    label="IP Disclosures and Encumbrances"
                    name="ipDisclosure"
                    labelClassName="font-body-bold pb-2"
                    optional
                  >
                    <FilePickerAdmin
                      setSignedId={val => setFieldValue("ipDisclosure", val)}
                      attachment={{
                        signedId: user.ipDisclosure,
                        metadata: user.ipDisclosureMetadata,
                        imageUrl: user.ipDisclosureUrl,
                      }}
                      name="ipDisclosure"
                    />
                  </FormBlock>
                </div>
              )}
            </div>
          )}
          {INVESTIGATOR_ROLES.includes(selectedRole) && (
            <fieldset>
              <legend className="font-body-bold mb-1">
                Howard Hughes Medical Institute (HHMI) Investigator
                <span className="font-meta ml-1 self-center text-gray-500">
                  (Optional)
                </span>
              </legend>
              <div className="mt-2 flex space-x-8">
                <RadioButtonGroup
                  options={booleanOptions}
                  selectedOption={
                    booleanOptions.find(
                      el => el.id === user.hhmiInvestigator,
                    ) || falseOption
                  }
                  setSelectedOption={val =>
                    setFieldValue("hhmiInvestigator", val.id)
                  }
                  parentClass="flex gap-8"
                />
              </div>
            </fieldset>
          )}
        </section>
      </div>
      <div>
        <p className="font-label bg-lines my-6 p-1.5 text-dark-50 px-8">
          Profile
        </p>
        <section className="flex flex-col gap-5.5 px-8">
          <FormBlock
            type="file"
            label="Avatar"
            name="avatar"
            labelClassName="font-body-bold pb-2"
            optional
          >
            <FilePickerAdmin
              setSignedId={val => setFieldValue("avatar", val)}
              attachment={{
                signedId: user.avatar,
                metadata: user.avatarMetadata,
                imageUrl: user.avatarUrl,
              }}
              name="avatar"
            />
          </FormBlock>
        </section>
      </div>
      <div>
        <p className="font-label bg-lines my-6 p-1.5 text-dark-50 px-8">
          Admin Only
        </p>
        <section className="flex flex-col gap-5.5 px-8">
          {selectedRole && STAFF_ROLES.includes(selectedRole) && (
            <FormBlock
              label="Legal Team"
              name="legalTeam"
              optional
              labelClassName="font-body-bold pb-2"
              classNameChildren="flex flex-row-reverse mr-auto items-center"
              leftChildren={
                <label className="text-dark-75 bg-tint-50 px-4 py-2">
                  This will allow Invention editing.
                </label>
              }
            >
              <input
                type={"checkbox"}
                className="h-4 w-4 rounded text-primary"
              />
            </FormBlock>
          )}
          <FormBlock
            label="Notes"
            name="notes"
            labelClassName="font-body-bold"
            optional
          >
            <TextArea
              className={clsx(
                "block w-full py-9px px-3.5 rounded-3px text-15 border border-dark-25 max-h-20 resize-none",
                "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary !text-dark-100 min-h-75px leading-5",
              )}
              value={user.notes}
            />
          </FormBlock>
        </section>
      </div>
    </div>
  );
}
export default BasicInfo;
