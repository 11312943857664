import * as React from "react";
import Modal from "@/components/shared/Modal";

function ArchiveConfirmationModal({
  open,
  setOpen,
  onAccept,
  archived = false,
}) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        Are you sure you want to {!archived ? "archive" : "active"} this event?
      </Modal.Header>
      <Modal.Body>
        The event information will {!archived && "no longer"} be displayed in
        the Member Center. It will remain in the Admin Center.
      </Modal.Body>
      <Modal.Footer className="w-full flex justify-between mt-5">
        <button
          type="button"
          className="btn-white btn-md rounded-3px"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button
          type="button"
          className="btn-primary btn-md rounded-3px"
          onClick={onAccept}
        >
          Yes, {!archived ? "Archive" : "Active"} Event
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ArchiveConfirmationModal;
