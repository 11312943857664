import * as React from "react";
import { useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik-latest";
import { saveResource } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import UserOnboarding from "@/components/shared/OnboardingLayout";
import FormBlock from "@/components/shared/FormBlock";
import AvatarPicker from "@/components/shared/AvatarPicker";

const mapInitialValues = user => {
  return { avatar: user?.attributes.avatar };
};

function OnboardingPicture() {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [avatarPickerState, setAvatarPickerState] = useState("");

  const onSubmit = values => {
    const request = {
      data: {
        id: currentUser?.id,
        type: "users",
        attributes: { avatar: values.avatar },
      },
    };

    if (values.avatar !== currentUser.attributes.avatarUrl) {
      saveResource("users", request).then(response => {
        if (response.ok) {
          navigate("/user_onboarding/professional");
        }
      });
    } else {
      navigate("/user_onboarding/professional");
    }
  };

  return (
    <UserOnboarding>
      <UserOnboarding.Header>
        <h2 className="pt-0.25 text-dark-100 font-h2 pb-2">
          Add a Professional Headshot
        </h2>
        <p className="font-body text-dark-75">
          Upload a photo of yourself to allow other PICI members to easily
          recognize your contribution to the network.
        </p>
      </UserOnboarding.Header>
      <UserOnboarding.Body>
        <Formik
          initialValues={mapInitialValues(currentUser)}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid }) => {
            const isDisabled =
              isSubmitting || !isValid || avatarPickerState == "uploading";
            return (
              <Form>
                <div className="flex flex-wrap gap-x-2.5 gap-y-5 md:gap-y-6">
                  <div className="w-full">
                    <FormBlock
                      label="Add Your Profile Photo"
                      name="avatar"
                      className="w-full"
                      labelClassName="md:mb-2 font-body-bold"
                      optional
                    >
                      <AvatarPicker
                        attachment={currentUser?.attributes.avatarUrl}
                        getStatus={status => {
                          setAvatarPickerState(status);
                        }}
                        labelButton="Upload Photo"
                      />
                    </FormBlock>
                  </div>
                  <button
                    className={clsx(
                      "btn-xl btn-primary font-lato w-full h-9.5 mt-3.5",
                      {
                        "btn-disabled": isDisabled,
                      },
                    )}
                    type="submit"
                    disabled={isDisabled}
                  >
                    Continue
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </UserOnboarding.Body>
    </UserOnboarding>
  );
}

export default OnboardingPicture;
