import * as React from "react";
import { humanize } from "@/utils/string";
import useCurrentUser from "@/hooks/useCurrentUser";
import AuthorList from "@/components/shared/AuthorList";
import SimpleTags from "@/components/shared/SimpleTags";

const Header = ({ invention }) => {
  const currentUser = useCurrentUser();
  const users = invention.relationships.users.data;
  const externalUsers = invention.relationships.externalUsers.data;

  const {
    identifier,
    title,
    cellTherapySubtypeReadable,
    antibodySubtypeReadable,
    diseaseIndicationReadable,
    biomarkersReadable,
    biomarkerTechnologySubtypeReadable,
    therapeuticStrategyReadable,
    technologyTypeReadable,
  } = invention.attributes;
  const tags = technologyTypeReadable.concat(
    cellTherapySubtypeReadable,
    antibodySubtypeReadable,
    diseaseIndicationReadable,
    biomarkersReadable,
    biomarkerTechnologySubtypeReadable,
    therapeuticStrategyReadable,
  );

  const formattedIp =
    invention.attributes.formattedIp &&
    humanize(invention.attributes.formattedIp);
  const inventionClass =
    invention.attributes.inventionClass &&
    invention.attributes.inventionClass.map(i => humanize(i)).join(", ");
  const applicationCategory = humanize(
    invention.attributes.applicationCategory
      .map(applicationCategory => " " + applicationCategory)
      .toString(),
  );
  const ipTypesText =
    formattedIp +
    (inventionClass ? `, ${inventionClass}` : "") +
    (applicationCategory ? `, ${applicationCategory}` : "");
  const inventors = users.concat(externalUsers);
  const canEdit = currentUser.attributes.isLegal;

  return (
    <div className="flex flex-col">
      <div>
        <div className="flex flex-wrap gap-2.5 items-center">
          <p className="font-label text-dark-75 mb-1.5">{ipTypesText}</p>
        </div>
        <h1 className="font-h2 sm:font-h1 mb-4">
          {identifier}: {title}
        </h1>
        {inventors.length > 0 && (
          <p className="font-meta sm:font-body text-dark-75 mb-6">
            <AuthorList authors={inventors} noun="inventor" />
          </p>
        )}
        {tags?.length > 0 && (
          <SimpleTags tags={tags} className="mb-7.5 sm:mb-10" />
        )}
        {canEdit ? (
          <div className="flex flex-wrap gap-3 mb-7.5">
            <a href={invention.links.edit} className="btn-white btn-md">
              Edit Invention Details
            </a>
          </div>
        ) : (
          <hr className="border-b border-dark-25 border-opacity-60 border-t-0 p-t-[5px]" />
        )}
      </div>
    </div>
  );
};

function Overview({ invention }) {
  const { abstractNotes } = invention.attributes;

  return (
    <div className="flex flex-col gap-7.5">
      <Header invention={invention} />
      {abstractNotes && (
        <section>
          <h3 className="font-h3 text-dark-100 pb-3.5">
            Confidential Abstract
          </h3>
          <div
            dangerouslySetInnerHTML={{
              __html: abstractNotes,
            }}
            className="font-body sm:font-body-large text-dark-75 pr-0 sm:pr-28"
          />
        </section>
      )}
    </div>
  );
}

export default Overview;
