import * as React from "react";
import { useState, useEffect } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import Tabs from "./Tabs";
import { useLocation } from "react-router-dom";
import paramBuilder from "@/utils/network/paramBuilder";
import ResourcesTable from "./ResourceTable";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import ResourcesForm from "./ResourcesForm";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import { FilterOption } from "@/types";

function useQueryHash() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const fetchCategories = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const params = paramBuilder({
    include: ["mediaContents"],
    fields: {
      categories: ["name", "slug", "type", "mediaContents"],
      mediaContents: ["title", "type", "resourceDescription"],
    },
    filter: {
      type: "ResourceCategory",
      category: filters?.slug,
    },
    sort: ["name"],
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `/api/categories?${pagination}&${params}`;
  return denormalizedFetcher(url);
};

function MediaResourcesPage() {
  const { hash } = useLocation();
  const [selectedTab, setSelectedTab] = useState<FilterOption | undefined>();
  const [showForm, setShowForm] = useState(false);
  const [resource, setResource] = useState(null);
  const query = useQueryHash();

  const openForm = (currentResource = null) => {
    setResource(currentResource);
    setShowForm(true);
  };
  const { isLoading: isLoadingNames, data: categoriesName } = useQuery(
    ["categoriesName"],
    () => fetcher("/api/categories/names"),
  );

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["categories", selectedTab], fetchCategories, {
    getNextPageParam: apiCanFetch,
  });

  const { included: resources } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("resources-section-navigation");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (!isLoadingNames) {
      setSelectedTab(
        categoriesName.data.find(tab => tab.slug == hash.slice(1)),
      );
    }
  }, [isLoadingNames, hash]);

  useEffect(() => {
    if (query.get("actions") === "new") {
      openForm(null);
    }
  }, []);

  return (
    <div className="bg-tint h-screen overflow-hidden">
      {!isLoadingNames && (
        <>
          <AdminHeader
            title="Resources"
            description="Manage the library of resources available to members."
            className="!h-[106px]"
            linkText="+ New Resource"
            linkTo={() => openForm()}
          >
            <Tabs
              tabs={categoriesName.data}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              className="pb-0 px-7.5 border-t border-lines"
            />
          </AdminHeader>
          <article
            id="resources-section-navigation"
            className="block overflow-x-hidden overflow-y-auto h-vh-60 px-6 pt-4 pb-12 bg-primary-lighter"
          >
            <ResourcesTable
              tabs={categoriesName.data}
              {...{
                resources,
                openForm,
                isFetching,
                isFetchingNextPage,
              }}
            />
          </article>
        </>
      )}
      {!isLoadingNames && (
        <ResourcesForm
          open={showForm}
          category={categoriesName.data}
          setOpen={setShowForm}
          currentResource={resource}
        />
      )}
    </div>
  );
}

export default MediaResourcesPage;
