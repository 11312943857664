import * as React from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { FilterOption } from "@/types";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import Select from "@/components/shared/Select";

const palettes = {
  primary: {
    navBorder: "",
    selectedTab: "border-primary text-dark-100",
    unselectedTab: "border-transparent text-dark-50 hover:text-dark-100",
  },
};
const LARGE_WINDOW_SIZE = 1600;
const NORMAL_WINDOW_SIZE = 1460;
const TABLET_WINDOW_SIZE = 1240;

function Tabs({
  defaultTabLabel = "All",
  tabs,
  selectedTab,
  setSelectedTab,
  palette = "primary",
  className = "",
}: {
  defaultTabLabel?: String;
  tabs: Array<any>;
  selectedTab: FilterOption | undefined;
  setSelectedTab: (_: FilterOption | undefined) => any;
  palette?: "primary" | "dark";
  className?: string;
}) {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const { width: windowWidth } = useWindowDimensions();
  let partialTabs = tabs;
  let groupTabs: any[] = [];

  if (windowWidth < TABLET_WINDOW_SIZE) {
    partialTabs = tabs.slice(0, -6);
    groupTabs = tabs.slice(-6);
  } else if (
    windowWidth >= TABLET_WINDOW_SIZE &&
    windowWidth < NORMAL_WINDOW_SIZE
  ) {
    partialTabs = tabs.slice(0, -4);
    groupTabs = tabs.slice(-4);
  } else if (
    windowWidth >= NORMAL_WINDOW_SIZE &&
    windowWidth < LARGE_WINDOW_SIZE &&
    tabs.length > 4
  ) {
    partialTabs = tabs.slice(0, -2);
    groupTabs = tabs.slice(-2);
  } else {
    partialTabs = tabs;
    groupTabs = [];
  }

  const changeTab = tab => {
    setSelectedTab(tab);
    navigate(`${path}#${tab.slug}`);
  };
  return (
    <div className={className}>
      <div>
        <div className={clsx("overflow-x-auto", palettes[palette].navBorder)}>
          <nav className="flex space-x-8" aria-label="Tabs">
            <button
              onClick={() => {
                setSelectedTab(undefined);
                navigate(path);
              }}
              className={clsx(
                selectedTab == null
                  ? palettes[palette].selectedTab
                  : palettes[palette].unselectedTab,
                "whitespace-nowrap pt-4 pb-2 px-1 border-b-4 font-meta-bold",
              )}
            >
              {defaultTabLabel}
            </button>
            {partialTabs.map(tab => (
              <button
                key={tab.slug}
                onClick={() => changeTab(tab)}
                className={clsx(
                  tab.slug === selectedTab?.slug
                    ? palettes[palette].selectedTab
                    : palettes[palette].unselectedTab,
                  "whitespace-nowrap pt-4 pb-2 px-1 border-b-4 font-meta-bold",
                )}
              >
                {tab.name}
              </button>
            ))}
            {groupTabs.length > 0 && (
              <Select
                selected={selectedTab?.id}
                setSelected={tabID =>
                  changeTab(groupTabs.find(tab => tab.id == tabID))
                }
                options={groupTabs}
                accessor={tab => tab.name}
                placeholder="More"
                className="border-0 text-dark-50 hover:text-dark-100 font-meta-bold shadow-none h-auto"
                classNameContainer={clsx(
                  "h-full flex flex-col justify-center border-b-4 mt-2",
                  groupTabs.includes(selectedTab)
                    ? "border-primary"
                    : "border-transparent",
                )}
              />
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
