import moment from "moment";

export default function dateGroupFormat(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  const sameYear = start.year() === end.year();
  const sameMonth = start.month() === end.month();
  const sameDay = start.date() === end.date();

  if (sameYear && sameMonth && sameDay) {
    return `${start.format("MMMM")} ${start.format("D")}, ${start.format(
      "YYYY",
    )}`;
  } else if (sameYear && sameMonth) {
    return `${start.format("MMMM")} ${start.format("D")} - ${end.format(
      "D",
    )}, ${start.format("YYYY")}`;
  } else {
    return `${start.format("MMM, DD YYYY")} - ${end.format("MMM, DD YYYY")}`;
  }
}
