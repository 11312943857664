import * as React from "react";
import clsx from "clsx";
import { FilterOption } from "@/types";

const _accessor = option => option.name;

function SelectGroup({
  options,
  selectedOption,
  setSelectedOption,
  className = "",
  accessor = _accessor,
  onEditClick,
}: {
  options: FilterOption[];
  selectedOption: FilterOption | null;
  setSelectedOption: (option: any) => void;
  className?: string;
  accessor?: Function;
  onEditClick?: Function;
}) {
  return (
    <div className={clsx("flex flex-col py-1", className)}>
      {options?.map(option => (
        <div className="flex items-center" key={option.id.toString()}>
          <button
            type="button"
            className={clsx(
              "flex-1 text-left px-4 py-2 cursor-pointer",
              "font-body hover:bg-primary hover:text-white",
              option.className,
              selectedOption?.id === option.id
                ? "text-primary"
                : !option.className && "text-dark-100",
            )}
            onClick={() => setSelectedOption(option)}
          >
            {accessor(option)}
          </button>
          {onEditClick && option.id.toString() !== "0" && (
            <button
              type="button"
              className="text-xl mr-2 hover:bg-gray-50"
              onClick={() => onEditClick(option.id)}
            >
              <i className="ri-pencil-line" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default SelectGroup;
