import React from "react";
import clsx from "clsx";
import { RadioGroup } from "@headlessui/react";
import { FilterOption } from "@/types";

function RadioButtonGroup({
  options,
  selectedOption,
  setSelectedOption,
  parentClass = "",
}: {
  options: FilterOption[];
  selectedOption: FilterOption | null;
  setSelectedOption: (option: any) => void;
  parentClass?: string;
}) {
  return (
    <RadioGroup value={selectedOption} onChange={setSelectedOption}>
      <div className={parentClass}>
        {options?.map(option => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className="flex mb-3 last:mb-0 cursor-pointer"
          >
            {({ active, checked }) => (
              <>
                <span
                  className={clsx(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center ml-1.5",
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className="text-sm font-normal text-gray-700"
                  >
                    {option.name}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default RadioButtonGroup;
