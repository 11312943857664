import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";

function fetchBanner() {
  const fields: string[] = ["active", "content"];
  const options = "&preload=none";
  return fetcher(`/api/banners/1?${fields.join(",")}${options}`);
}

function useBanner() {
  const { data } = useQuery(["banner"], fetchBanner);
  return data?.data;
}

export default useBanner;
