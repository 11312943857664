import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

const AuthorItem = ({ author, className = "", ...props }) => {
  if (
    author &&
    author.attributes &&
    (author.attributes.fullName || author.attributes.name)
  ) {
    const { fullName, name, slug, projectsCount } = author?.attributes;
    const hasProjects = projectsCount > 0;
    if (slug) {
      return (
        <Link
          to={`/profile/${slug}${hasProjects ? "?tab=projects" : ""}`}
          className={clsx("link", className)}
          {...props}
        >
          {fullName || name}
        </Link>
      );
    }
    return <span {...props}>{fullName || name}</span>;
  } else {
    return <></>;
  }
};

function AuthorList({ authors, noun = "author" }) {
  if (authors.length === 1) {
    return (
      <>
        <AuthorItem author={authors[0]} />{" "}
        <span className="text-dark-50">is {noun}.</span>
      </>
    );
  }

  if (authors.length === 2) {
    return (
      <>
        <AuthorItem author={authors[0]} />{" "}
        <span className="text-dark-50">and</span>{" "}
        <AuthorItem author={authors[1]} />{" "}
        <span className="text-dark-50">are {noun}s.</span>
      </>
    );
  }

  const authorList = authors.slice(0, -1).map(author => (
    <>
      <AuthorItem author={author} />,{" "}
    </>
  ));

  authorList.push(
    <>
      <span className="text-dark-50">and</span>{" "}
      <AuthorItem author={authors.slice(-1)[0]} />{" "}
      <span className="text-dark-50">are {noun}s.</span>
    </>,
  );

  return authorList.map((name, index) => (
    <React.Fragment key={index}>{name}</React.Fragment>
  ));
}

export default AuthorList;
