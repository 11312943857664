import { format, parseISO } from "date-fns";
import React from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Scatter,
} from "recharts";

const parseDomain = data => {
  return [
    0,
    Math.max.apply(
      null,
      data.map(years =>
        Math.max.apply(
          null,
          years.months.map(el => el.count),
        ),
      ),
    ),
  ];
};
const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { amount, date } = payload[0].payload;
    const shortMonth = format(new Date(date), "LLL");

    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          <span className="font-bold">
            {shortMonth}: {amount}
          </span>
        </p>
      </div>
    );
  }
  return null;
};

const dateFormat = date =>
  typeof date === "number" ? format(new Date(date), "yyyy") : "";

function BubbleChart({ data, tooltip = CustomTooltip }) {
  const domain = data.length > 0 ? parseDomain(data) : [0, 0];
  const range = [0, 225];
  return (
    <div className="mt-3.75">
      {data.map(({ year, months }, index) => {
        const xTickList: number[] = [];
        const dataByMonth = months.map(item => {
          const dateMonth = parseISO(item.name);
          if (xTickList.length === 0) {
            xTickList.push(dateMonth.getTime());
          } else {
            const lastDate = xTickList[xTickList.length - 1];
            if (dateMonth.getMonth() != new Date(lastDate).getMonth()) {
              xTickList.push(dateMonth.getTime());
            }
          }
          return {
            index: 1,
            date: dateMonth.getTime(),
            amount: item.count,
          };
        });
        return (
          <ResponsiveContainer key={index} width={"100%"} height={60}>
            <ScatterChart
              margin={{
                top: 10,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              cx="50%"
              cy="50%"
              outerRadius="95%"
            >
              <XAxis
                type="category"
                dataKey="date"
                tick={{ fontSize: 0 }}
                tickLine={{ transform: "translate(0, -6)" }}
                interval="preserveStartEnd"
                ticks={xTickList}
                tickFormatter={dateFormat}
              />
              <YAxis
                type="number"
                dataKey="index"
                name={year}
                height={10}
                width={40}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{ value: year, position: "insideRight" }}
              />
              <ZAxis
                type="number"
                dataKey="amount"
                domain={domain}
                range={range}
              />
              <Tooltip wrapperStyle={{ zIndex: 100 }} content={tooltip} />
              <Scatter data={dataByMonth} fill="#8884d8" />
            </ScatterChart>
          </ResponsiveContainer>
        );
      })}
    </div>
  );
}

export default BubbleChart;
