const arrayToObject = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {},
  );

const convertJsonIntoString = value => {
  if (typeof value === "string") {
    return value;
  } else {
    return JSON.stringify(value);
  }
};

const convertStringIntoJson = value => {
  let content;
  try {
    content = JSON.parse(value);
  } catch (_) {
    content = value;
  }
  return content;
};

export { arrayToObject, convertJsonIntoString, convertStringIntoJson };
