import React, { useRef, useEffect } from "react";
import { isImage } from "@/utils/fileValidator";

const useActiveStoragePreview = (file, className = "", onLoadFile) => {
  const imageRef = useRef(null);

  useEffect(() => {
    if (file !== null) {
      extractImagePreview(file);
    }
  }, [file]);

  const extractImagePreview = file => {
    if (!isImage(file.type)) return;
    const fileReader = new FileReader();
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    fileReader.onload = evt => {
      if (imageRef.current) {
        imageRef.current.style.backgroundImage = `url(${evt.target.result})`;
      }
      if (onLoadFile) {
        onLoadFile(evt.target);
      }
    };
    fileReader.readAsDataURL(file);
  };

  return file === null ? null : <img ref={imageRef} className={className} />;
};

export default useActiveStoragePreview;
