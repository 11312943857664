import * as React from "react";
import clsx from "clsx";
import Modal from "@/components/shared/Modal";

function SuspendConfirmationModal({ open, setOpen, onAccept, user }) {
  const { fullName, firstName, suspended } = user.attributes;
  const text = !suspended
    ? `Suspending ${fullName}’s account will block access to the PICI Member
  center. However, ${firstName}’s profile and contributions will remain
  available on the network. You can reactivate this account at any time.`
    : `Activating ${fullName}’s account will reactivate access to the PICI Member
  center. ${firstName}’s will be able to log in, view and contribute to the network.`;
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        {`${!suspended ? "Suspend" : "Activate"} ${fullName}’s account?`}
      </Modal.Header>
      <Modal.Body className="text-dark-75 font-body-large">{text}</Modal.Body>
      <Modal.Footer className="w-full flex justify-between mt-5">
        <button
          type="button"
          className="btn-white btn-sm font-meta-bold"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button
          type="button"
          className={clsx(
            "btn-md text-white",
            suspended
              ? "bg-primary hover:bg-primary-dark"
              : "bg-red-350 hover:bg-red-450 active:bg-red-650",
          )}
          onClick={onAccept}
        >
          Yes, {!suspended ? "Suspend" : "Activate"} Account
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SuspendConfirmationModal;
