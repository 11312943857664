import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { saveResource } from "@/utils/api";
import SearchBar from "../SearchBar";
import Select from "../Select";

type UserType = {
  id: string;
  name: string;
};

type User = {
  id: string;
  attributes: any;
};

function UserPicker({
  users,
  userTypes,
  selectedUsers,
  setSelectedUsers,
  allowExternals = false,
  externalLocation = "",
}: {
  users: User[];
  userTypes: UserType[];
  selectedUsers: any[];
  setSelectedUsers: any;
  allowExternals?: boolean;
  externalLocation?: string;
}) {
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [currentItem, setCurrentItem] = useState<User | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState<UserType | null>(null);

  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const filteredUsers = users?.filter(user => {
      return !selectedUsers.find(
        selectedUser => selectedUser.userId === user.id,
      );
    });
    setFilteredUsers(filteredUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, users]);

  useEffect(() => {
    if (allowExternals) {
      const termIsEmpty = searchTerm.trim().length === 0;
      setCanSubmit(!termIsEmpty && !isSubmitting);
    } else {
      setCanSubmit(currentItem !== null && !isSubmitting);
    }
  }, [searchTerm, currentItem, allowExternals, isSubmitting]);

  const createExternalUser = async (name, currentType) => {
    return new Promise((resolve, reject) => {
      const request = {
        data: {
          type: "external-users",
          attributes: {
            location: externalLocation,
            name,
          },
        },
      };
      saveResource("external-users", request, () => {})
        .then(data => data.json())
        .then(user =>
          resolve({
            userId: user.data.id,
            name: user.data.attributes.name,
            type: currentType,
            external: true,
          }),
        )
        .catch(error => reject(error));
    });
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const currentType = selectedType || "mentioned";
    if (currentItem) {
      const newUser = {
        userId: currentItem.id,
        name: currentItem.attributes.fullName,
        type: currentType,
        external: false,
      };
      setSelectedUsers([...selectedUsers, newUser]);
    } else if (allowExternals) {
      const names = searchTerm
        .split(",")
        .map(n => n.trim())
        .filter(n => n.length > 0);
      for (let name of names) {
        const externalUser = await createExternalUser(name, currentType);
        setSelectedUsers([...selectedUsers, externalUser]);
      }
    }
    setCurrentItem(null);
    setSearchTerm("");
    setSelectedType(null);
    setIsSubmitting(false);
  };

  const removeUser = removedUser => {
    const remainingUsers = selectedUsers.filter(
      user =>
        user.external !== removedUser.external ||
        user.userId !== removedUser.userId,
    );
    setSelectedUsers(remainingUsers);
  };

  return (
    <>
      <div className="sm:flex sm:justify-between sm:space-x-4">
        <div className="w-full bg-white">
          <SearchBar
            options={filteredUsers}
            onSelect={item => {
              setSearchTerm(item.fullName);
              setCurrentItem(item);
            }}
            onTextChange={value => setSearchTerm(value)}
            selected={currentItem?.attributes.fullName}
            placeholder="PICI Member's name"
            accessor={item => item.attributes.fullName}
          >
            {({ item }) => (
              <div className="p-2 cursor-pointer flex flex-col">
                <span className="text-dark-100">
                  {item.attributes.fullName}
                </span>
                <span className="text-dark-50">
                  {item.attributes.centerNameOrInstitution}
                </span>
              </div>
            )}
          </SearchBar>
        </div>
        <div className="w-full mt-2 sm:mt-0">
          <Select
            selected={selectedType}
            setSelected={setSelectedType}
            options={userTypes}
            placeholder="Select author type..."
            className="font-normal"
          />
        </div>
      </div>

      <div className="mt-2">
        <button
          type="button"
          disabled={!canSubmit}
          onClick={() => onSubmit()}
          className={clsx(
            "inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white",
            canSubmit ? "btn-primary btn-sm " : "bg-dark-25 cursor-default",
          )}
        >
          Add Member
        </button>
      </div>

      <ul className="mt-2 w-full">
        {selectedUsers &&
          selectedUsers.map((user, index) => (
            <li
              key={`${user.userId}-${user.external}`}
              className="flex justify-between text-sm mb-1 py-2 px-4 bg-blue-50 rounded-md"
              data-testid={`selected-item-${index}`}
            >
              <div className="flex w-full">
                <div
                  className="w-1/2 pr-2 text-gray-900 overflow-hidden overflow-ellipsis whitespace-nowrap"
                  data-testid={`selected-item-${index}-name`}
                >
                  {user.userName || user.name}
                </div>
                <div
                  className="w-1/2 text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap"
                  data-testid={`selected-item-${index}-type`}
                >
                  {userTypes.find(t => t.id === user.type)?.name}
                </div>
              </div>

              <button
                type="button"
                data-testid={`remove-selected-item-${index}`}
                onClick={() => removeUser(user)}
              >
                <i className="ri-delete-bin-line"></i>
              </button>
            </li>
          ))}
      </ul>
    </>
  );
}

export default UserPicker;
