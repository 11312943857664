import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { Editable, withReact, Slate } from "slate-react";
import { createEditor, Text } from "slate";
import { withHistory } from "slate-history";
import isHotkey from "is-hotkey";
import Formattable from "./plugins/formattable";
import Linkable from "./plugins/linkable";

type ActionType = {
  name: string;
  Button: (props: any) => any;
  hotkey?: { [command: string]: any };
  isActive: (editor: any) => boolean;
};

type PluginType = {
  actions: ActionType[];
  renderElement: any;
  editorExtension?: any;
};

const Plugables: PluginType[] = [Formattable, Linkable];

const isEditorEmpty = value => {
  if (value.length > 1 || value[0].children.length > 1) return false;
  const node = value[0].children[0];
  return Text.isText(node) && node.text === "";
};

const withPlugins = editor => {
  for (const plugable of Plugables) {
    if (plugable.editorExtension) {
      editor = plugable.editorExtension(editor);
    }
  }
  return editor;
};

const Element = props => {
  for (const plugable of Plugables) {
    if (plugable.renderElement) {
      const plugableElement = plugable.renderElement(props);
      if (plugableElement) return plugableElement;
    }
  }

  return (
    <p className="font-body relative" {...props.attributes}>
      {props.children}
    </p>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <b>{children}</b>;
  }
  if (leaf.italic) {
    children = <i>{children}</i>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

const checkHotKeys = (editor, event) => {
  let hotkeys = {};
  for (const plugable of Plugables) {
    for (const action of plugable.actions) {
      if (action.hotkey) hotkeys = { ...hotkeys, ...action.hotkey };
    }
  }
  for (const hotkey in hotkeys) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const toggle = hotkeys[hotkey];
      toggle(editor);
    }
  }
};

function Toolbar({ actions, editor }) {
  return (
    <div className="flex px-2">
      {actions.map(action => (
        <div key={action.name}>
          <action.Button
            title={action.name}
            className={clsx(
              "cursor-pointer rounded-sm p-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
              action.isActive(editor) ? "text-indigo-600" : "text-gray-400",
            )}
          />
        </div>
      ))}
    </div>
  );
}

function RichTextEditor({
  value,
  onChange,
  placeholder = "",
  className = "",
  dataTestId = "",
}) {
  const [isFocus, setIsFocus] = useState(false);
  // Difference between elements and leafs: https://docs.slatejs.org/concepts/09-rendering
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withPlugins(withHistory(withReact(createEditor()))),
    [],
  );

  const deserializedValue =
    typeof value === "string" || value === null
      ? [{ type: "paragraph", children: [{ text: value || "" }] }]
      : value;

  return (
    <>
      {deserializedValue && (
        <div
          className={clsx(
            "bg-white block w-full shadow-sm border sm:text-sm text-gray-900 border-gray-300 rounded-md",
            isFocus && "ring-1 ring-indigo-500 border-indigo-500 outline-none",
            className,
          )}
        >
          <Slate
            editor={editor}
            value={deserializedValue}
            onChange={value => {
              if (isEditorEmpty(value)) {
                onChange("");
              } else {
                onChange(value);
              }
            }}
          >
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              spellCheck
              onKeyDown={event => checkHotKeys(editor, event)}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              className="px-4 pt-4 pb-1 min-h-75px"
              data-testid={dataTestId}
            />
            <Toolbar
              actions={[...Formattable.actions, ...Linkable.actions]}
              editor={editor}
            />
          </Slate>
        </div>
      )}
    </>
  );
}

export default RichTextEditor;
