import * as React from "react";

type HeaderCardsType = {
  title?: string;
  subtitle?: string | number;
  titleClassname?: string;
  subtitleClassname?: string;
};

function HeaderCards({
  title,
  subtitle,
  titleClassname = "",
  subtitleClassname = "",
}: HeaderCardsType) {
  return (
    <header>
      {title && <h1 className={`font-h3 ${titleClassname}`}>{title}</h1>}
      {subtitle && (
        <h2 className={`font-h1-xl mb-2 ${subtitleClassname}`}>{subtitle}</h2>
      )}
    </header>
  );
}

export default HeaderCards;
