import * as React from "react";

function ChartExample() {
  return (
    <svg
      width="240"
      height="241"
      viewBox="0 0 240 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.230774" width="33.3333" height="106" fill="#EAECF0" />
      <rect y="106.231" width="33.3333" height="134" fill="#685BC7" />
      <rect
        x="41.3334"
        y="0.230774"
        width="33.3333"
        height="153"
        fill="#EAECF0"
      />
      <rect
        x="41.3334"
        y="153.231"
        width="33.3333"
        height="87"
        fill="#3FB5AE"
      />
      <rect
        x="82.6666"
        y="0.230774"
        width="33.3333"
        height="173"
        fill="#EAECF0"
      />
      <rect
        x="82.6666"
        y="173.231"
        width="33.3333"
        height="67"
        fill="#FE6A36"
      />
      <rect x="124" y="0.230774" width="33.3333" height="95" fill="#EAECF0" />
      <rect x="124" y="95.2308" width="33.3333" height="145" fill="#207459" />
      <rect
        x="165.333"
        y="0.230774"
        width="33.3333"
        height="136"
        fill="#EAECF0"
      />
      <rect
        x="165.333"
        y="136.231"
        width="33.3333"
        height="104"
        fill="#014B74"
      />
      <rect
        x="206.667"
        y="0.230774"
        width="33.3333"
        height="54"
        fill="#EAECF0"
      />
      <rect
        x="206.667"
        y="54.2308"
        width="33.3333"
        height="186"
        fill="#EDB641"
      />
    </svg>
  );
}

export default ChartExample;
