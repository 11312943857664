import { fetcher } from "@/utils/api";
import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import NoResults from "../../NoResults";
import Select from "../../Select";

const COLORS = [
  "#EC4899",
  "#3B82F6",
  "#829F37",
  "#6366F1",
  "#67E8F9",
  "#494487",
  "#E45C53",
  "#3FB5AE",
  "#FE6A36",
  "#EF4444",
];

function InsightMultiLevelPieChart({
  data,
  centers,
  dataKey,
  customSearch,
  entity,
}) {
  const maxRadius = 150;
  const [displayData, setDisplayData] = useState(data?.group_data || []);
  const [displayTotal, setDisplayTotal] = useState(data?.total || 0);
  const displayCenters = [{ id: 0, name: "All Centers" }, ...centers];
  const [selectedCenterId, setSelectedCenterId] = useState();
  const dataLines = React.useMemo(
    () =>
      displayData?.map(type => ({
        dataKey: type.id,
        dataLabel: type.name,
        totalCount: type.count,
      })),
    [displayData],
  );

  const handleChange = async centerId => {
    if (centerId === 0) {
      setDisplayData(data?.group_data);
      setDisplayTotal(data?.total);
    } else {
      const { total, group_data } = await fetcher(
        `/api/centers/${centerId}/${customSearch}`,
      );
      setDisplayData(group_data);
      setDisplayTotal(total);
    }
  };

  const CustomTooltip = ({
    active,
    payload,
  }: {
    active?: boolean;
    payload?: any;
  }) => {
    if (active && payload !== undefined) {
      const { name, count } = payload[0].payload;
      const porcentual = Math.round((count * 100) / displayTotal);
      return name != "TotalDiff" ? (
        <div className="border flex bg-white border-bg-tinti py-2 px-3">
          <p className="font-meta">
            {name}:{" "}
            <span className="font-meta-bold">{`${count} (${porcentual}%)`}</span>
          </p>
        </div>
      ) : (
        <></>
      );
    }
    return null;
  };
  return (
    <div className="h-full">
      <Select
        selected={selectedCenterId}
        setSelected={centerId => {
          setSelectedCenterId(centerId);
          handleChange(centerId);
        }}
        options={displayCenters}
        placeholder="All Centers"
        className="h-10 text-15 rounded font-body"
        placement="top-end"
      />

      {displayData.length > 0 ? (
        <>
          <ul className="mt-4 text-15">
            {dataLines.map((dataLine, index) => (
              <li key={index} className="flex justify-between mb-3">
                <div className="flex flex-wrap gap-1">
                  <span style={{ color: COLORS[index] }}>●</span>
                  <div className="text-dark-75">
                    <p className="font-body-bold">{dataLine.dataLabel}</p>
                    <p className="font-micro">{`${dataLine.totalCount} ${entity}`}</p>
                  </div>
                </div>
                <p className="font-body-bold text-dark-75">{`${Math.round(
                  (dataLine.totalCount * 100) / displayTotal,
                )}%`}</p>
              </li>
            ))}
          </ul>
          {/* Warning: this graph only supports a limited amount (around 5) of elements be cautions about this  */}
          <div className="w-full h-[300px]">
            <ResponsiveContainer>
              <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                {displayData.map((row, index) => {
                  const radiusReduction = index * 30;
                  const maxCurrentOuterRadius = maxRadius - radiusReduction;
                  const data = [
                    row,
                    {
                      count: displayTotal - row.count,
                      id: "totalDiff",
                      name: "TotalDiff",
                    },
                  ];
                  return (
                    <Pie
                      key={index}
                      data={data}
                      dataKey={dataKey}
                      cx="50%"
                      cy="50%"
                      innerRadius={maxCurrentOuterRadius - 20}
                      outerRadius={maxCurrentOuterRadius}
                      startAngle={90}
                      endAngle={450}
                    >
                      {data.map((_el, idx) => (
                        <Cell
                          key={index}
                          fill={
                            idx == 0 ? COLORS[index % COLORS.length] : "#EAECF0"
                          }
                        />
                      ))}
                    </Pie>
                  );
                })}
                <Tooltip content={CustomTooltip} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <NoResults
          entityName={entity}
          icon="ri-search-line"
          className="mt-5 bg-transparent"
        />
      )}
    </div>
  );
}

export default InsightMultiLevelPieChart;
