import * as React from "react";
import { useState, useEffect } from "react";
import isUrl from "is-url";
import escapeHtml from "escape-html";
import clsx from "clsx";
import { useSlate } from "slate-react";
import { Editor, Transforms, Range } from "slate";
import OutsideClickHandler from "@/utils/deps/react-outside-click-handler";
import { LinkIcon } from "@/components/shared/RichTextEditor/icons";

const editorExtension = editor => {
  const { insertData, insertText, isInline } = editor;

  editor.insertData = data => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.isInline = element => {
    return element.type === "link" || isInline(element);
  };

  return editor;
};

const renderElement = ({ attributes, children, element }) => {
  switch (element.type) {
    case "link": {
      return (
        <a {...attributes} className="link-primary">
          {children}
        </a>
      );
    }
    default: {
      return null;
    }
  }
};

const normalizeUrl = url => {
  if (isUrl(url)) return url;

  const withHttps = `https://${url}`;
  if (isUrl(withHttps)) return withHttps;

  return "";
};

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === "link" });
  return !!link;
};

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === "link" });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const getLinkData = editor => {
  const [link] = Editor.nodes(editor, {
    match: node => node.type === "link",
  });

  return link ? link[0] : null;
};

function LinkForm({ setIsOpen }) {
  const editor = useSlate();
  const [selectedText, setSelectedText] = useState(editor.selection);

  const [url, setUrl] = useState("");
  const [isErrored, setIsErrored] = useState(false);

  const close = () => {
    if (selectedText) {
      Transforms.select(editor, selectedText);
      setSelectedText(null);
    }
    setUrl("");
    setIsOpen(false);
  };

  const onChange = event => {
    const url = event.target.value;
    setUrl(url);
    setIsErrored(false);
  };

  const onLink = () => {
    const normalizedUrl = normalizeUrl(url);

    if (isUrl(normalizedUrl)) {
      close();
      insertLink(editor, escapeHtml(url));
    } else if (normalizedUrl === "") {
      close();
    } else {
      setIsErrored(true);
    }
  };

  const onUnlink = () => {
    close();
    unwrapLink(editor);
  };

  useEffect(() => {
    setUrl(getLinkData(editor)?.url || "");
  }, [editor, setUrl]);

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <div
        style={{ top: "-50px" }}
        className="flex items-center justify-start rounded-md border border-gray-200 space-x-2 p-2 bg-white absolute"
      >
        <input
          onChange={onChange}
          value={url}
          autoFocus
          placeholder="Paste URL"
          className={clsx(
            "px-3 py-1 text-sm text-gray-900 border border-transparent rounded-lg outline-none w-72",
            isErrored && "border-red-400",
          )}
        />

        <button
          type="button"
          onClick={onUnlink}
          className="inline-flex items-center px-3 py-1 border border-gray-200 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-100 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Unlink
        </button>
        <button
          type="button"
          onClick={onLink}
          className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Link
        </button>
      </div>
    </OutsideClickHandler>
  );
}

function LinkButton({ ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button type="button" onMouseDown={() => setIsOpen(true)} {...props}>
        <LinkIcon />
      </button>
      {isOpen && <LinkForm {...{ setIsOpen }} />}
    </div>
  );
}

const Linkable = {
  actions: [
    {
      name: "Insert Link",
      Button: props => <LinkButton {...props} />,
      isActive: editor => isLinkActive(editor),
    },
  ],
  editorExtension,
  renderElement,
};

export default Linkable;
