import React from "react";
import { Draggable } from "react-beautiful-dnd";

function DraggableWrapper({ section, index, children, disabled = false }) {
  return (
    <Draggable
      draggableId={section.id || section.provisionalId}
      index={index}
      key={`article-${section.id || section.provisionalId}`}
      isDragDisabled={disabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            snapshot.isDragging
              ? "bg-yellow-100 border-2 border-yellow-300 border-dashed"
              : "bg-white"
          }
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}

export default DraggableWrapper;
