import * as React from "react";
import { useState, useCallback } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik-latest";
import { saveResource } from "@/utils/api";
import useFlashMessage from "@/hooks/useFlashMessage";
import SlideOver from "@/components/shared/SlideOver/SlideOver";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";
import RadioButtonGroup from "@/components/shared/RadioButtonGroup";
import Select from "@/components/shared/Select";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";
import UpdateConfirmationModal from "./UpdateConfirmationModal";
import { isEqual } from "lodash";

const typeOptions = [
  { id: "FileResource", name: "File" },
  { id: "LinkResource", name: "Link" },
];

const ResourceSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Title is required."),
  type: Yup.string().required(),
  categoryId: Yup.string().required("Category is required"),
  link: Yup.string().when("type", {
    is: "LinkResource",
    then: Yup.string()
      .required("Link is required.")
      .url("Link needs to be a valid URL."),
  }),
  file: Yup.mixed().when("type", {
    is: "FileResource",
    then: Yup.string()
      .required("File is required.")
      .nullable(true),
  }),
});

const mapRequest = (item, id = null) => {
  const request = {
    data: {
      type: "resources",
      attributes: item,
      ...(id && { id: id }),
    },
  };
  return request;
};
const mapCurrentItems = current => {
  return {
    categoryId: current?.categoryId || "",
    description: current?.description || "",
    resourceDescription: current?.resourceDescription || "",
    file: current?.file || null,
    fileMetadata: current?.fileMetadata || null,
    fileUrl: current?.fileUrl || null,
    link: current?.link || "",
    title: current?.title || "",
    type: current?.type || typeOptions[0].id,
  };
};

function ResourceForm({
  open,
  setOpen,
  category,
  currentResource = null,
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
  category: Array<any>;
  currentResource: any;
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryClient = useQueryClient();
  const initialData = mapCurrentItems(currentResource?.attributes);
  const isEdit = !!currentResource;

  const showFlash = useFlashMessage({
    message: `Your resource was ${isEdit ? "updated" : "added"}!`,
    errorMessage: `Your resource could not be ${
      isEdit ? "updated" : "added"
    } due to an error.`,
  });

  const close = useCallback(() => {
    setOpen(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
  }, [setOpen]);

  const showDeleteFlash = useFlashMessage({
    message: "The resource has been deleted.",
  });

  const createContent = item => {
    const request = mapRequest(item, currentResource?.id);
    delete request.data.attributes["fileMetadata"];
    delete request.data.attributes["fileUrl"];
    saveResource("resources", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        close();
        queryClient.invalidateQueries(["categories"]);
      });
  };

  const submit = (item, formiKProps) => {
    if (isEdit) {
      setShowEditModal(true);
      formiKProps.setSubmitting(false);
    } else {
      createContent(item);
    }
  };

  const onDelete = useCallback(() => {
    fetch(currentResource.links.self, { method: "DELETE" }).then(res => {
      showDeleteFlash({ success: res.ok });
      if (res.ok) {
        queryClient.invalidateQueries(["categories"]);
      }
      close();
    });
  }, [currentResource, close, queryClient]);

  return (
    <SlideOver {...{ open, setOpen }}>
      <Formik
        initialValues={initialData}
        onSubmit={submit}
        validationSchema={ResourceSchema}
      >
        {({
          values,
          setFieldValue,
          dirty,
          isValid,
          isSubmitting,
          setSubmitting,
          ...props
        }) => {
          const hasNoChanges = isEqual(values, initialData);
          const submitDisabled = !isValid || isSubmitting || hasNoChanges;
          return (
            <Form>
              <SlideOver.Header>
                <span className="text-dark-100 font-medium">
                  {currentResource ? "Edit Resource" : "New Resource"}
                </span>
              </SlideOver.Header>

              <SlideOver.Body>
                <div className="space-y-6">
                  <fieldset>
                    <legend className="font-body-bold mb-1">
                      Resource Type
                    </legend>
                    <div className="mt-2 flex space-x-8" data-cy="resourceType">
                      {currentResource ? (
                        <span>
                          {typeOptions.find(el => el.id == values.type)?.name}
                        </span>
                      ) : (
                        <RadioButtonGroup
                          options={typeOptions}
                          selectedOption={
                            typeOptions.find(el => el.id === values.type) ||
                            null
                          }
                          setSelectedOption={val =>
                            setFieldValue("type", val.id)
                          }
                          parentClass="flex gap-8"
                        />
                      )}
                    </div>
                  </fieldset>
                  <div>
                    <FormBlock
                      label="Title"
                      name="title"
                      labelClassName="font-body-bold"
                    >
                      <Input
                        value={values.title}
                        data-cy="item-title"
                        styleAs="small"
                      />
                    </FormBlock>
                  </div>
                  <div>
                    <FormBlock
                      label="Category"
                      name="categoryId"
                      labelClassName="font-body-bold"
                    >
                      <Select
                        selected={values.categoryId}
                        setSelected={(val = "") =>
                          setFieldValue("categoryId", val)
                        }
                        options={category}
                        placeholder="Select a category"
                      />
                    </FormBlock>
                  </div>
                  {values.type === "LinkResource" && (
                    <>
                      <div>
                        <FormBlock
                          label="Link"
                          name="link"
                          labelClassName="font-body-bold"
                        >
                          <Input
                            placeholder="e.g. http://www.example.com"
                            value={values.link}
                            styleAs="small"
                          />
                        </FormBlock>
                      </div>
                      <div>
                        <FormBlock
                          label="Description"
                          name="description"
                          labelClassName="font-body-bold"
                          optional
                        >
                          <textarea
                            className={clsx(
                              "block w-full py-9px px-3.5 rounded-3px text-15 mt-2.5 border border-dark-25 max-h-screen resize-none",
                              "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary text-dark-75 min-h-75px leading-5",
                            )}
                            value={values.link}
                          />
                        </FormBlock>
                      </div>
                    </>
                  )}
                  {values.type === "FileResource" && (
                    <div>
                      <label className="flex font-body-bold mb-1">
                        Upload File
                      </label>
                      <div className="mt-1">
                        <FilePickerAdmin
                          setSignedId={val => setFieldValue("file", val)}
                          attachment={{
                            signedId: values.file,
                            metadata: values.fileMetadata,
                            imageUrl: values.fileUrl,
                          }}
                        />
                        {props.touched.file && props.errors.file && (
                          <p className="mt-1 text-sm text-red-400">
                            {props.errors.file}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </SlideOver.Body>

              <SlideOver.Footer className="absolute bottom-0 !pb-4">
                <button
                  type="submit"
                  disabled={submitDisabled}
                  className={clsx(
                    "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-3px text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                    submitDisabled
                      ? "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none"
                      : "bg-primary hover:bg-primary-dark",
                  )}
                >
                  {currentResource ? "Save changes" : "Create resource"}
                </button>
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-3px shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <div className="flex-grow" />
                {currentResource && (
                  <button
                    className={clsx(
                      "border border-lines rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium",
                      "bg-tint text-dark-75 hover:bg-white py-9px px-7.5 font-lato active:bg-lines",
                    )}
                    type="button"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Resource
                  </button>
                )}
              </SlideOver.Footer>
              <UpdateConfirmationModal
                open={showEditModal}
                setOpen={setShowEditModal}
                onAccept={() => createContent(values)}
              />
              <DeleteConfirmationModal
                open={showDeleteModal}
                setOpen={setShowDeleteModal}
                onAccept={onDelete}
                resource="resource"
                message="The resource information will no longer be available to the PICI network. It will also be removed from the Admin Center."
                className="w-105"
              />
            </Form>
          );
        }}
      </Formik>
    </SlideOver>
  );
}

export default ResourceForm;
