import * as React from "react";
import { format, isBefore, parseISO } from "date-fns";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";

const ActiveICon = ({ status }) => {
  return (
    <div className="bg-dark-50 rounded-full h-9 w-9 flex flex-shrink-0 justify-center items-center z-10">
      <div className="text-xl flex">
        <i
          className={clsx(
            "ri-1x leading-none text-white",
            status ? "ri-check-line" : "ri-close-line",
          )}
        ></i>
      </div>
    </div>
  );
};

const InactiveIcon = () => {
  return (
    <div className="bg-white rounded-full h-9 w-9 flex flex-shrink-0 border-2 border-dark-25 z-10"></div>
  );
};

function Timeline({ children, title }: { children: any; title?: string }) {
  return (
    <div>
      {title && <p className="font-body-bold text-dark-100 mb-3">{title}:</p>}
      <ul className="space-y-9">{children}</ul>
    </div>
  );
}

const fixDateForAllBrowsers = dateString =>
  dateString.replace(/\s/, "T").replace(/\s/, "");

Timeline.Item = ({
  phase,
  phaseClassName = "",
  date = "TBD",
  formatDate = "MMMM, yyyy",
  notes = null,
  showPriorityDatelabel = false,
  index,
  status = true,
}: {
  phase: string;
  phaseClassName?: string;
  date: string;
  formatDate?: string;
  index: number;
  notes?: any;
  showPriorityDatelabel?: boolean;
  status?: boolean;
}) => {
  let formattedDate = "";
  let isBeforeNow = false;

  if (date && date !== "TBD") {
    // For Safari and Explorer the correct string should be YYYY-DD-MMTHH:MM:SS-HH:mm and we have this YYY-DD-MM HH:MM:SS - HH:mm
    const validDate = parseISO(fixDateForAllBrowsers(date));
    formattedDate = format(validDate, formatDate);
    isBeforeNow = isBefore(validDate, Date.now());
  } else {
    formattedDate = "TBD";
  }
  const notesWithPriority = showPriorityDatelabel
    ? "PRIORITY DATE<br/>" + notes
    : notes;

  return (
    <>
      <li className="flex items-center relative">
        {index !== 0 && (
          <div
            style={{
              left: "17px",
              width: "2px",
              height: "150%",
              bottom: "25px",
            }}
            className="absolute z-1 block none bg-dark-25"
          />
        )}
        {isBeforeNow ? <ActiveICon status={status} /> : <InactiveIcon />}
        <div className="ml-3">
          <div className="flex items-center">
            <span
              className={clsx(
                "font-body-bold text-dark-100 capitalize",
                phaseClassName,
              )}
            >
              {phase}
            </span>
            {(notes || showPriorityDatelabel) && (
              <div className="ml-1.5">
                <i
                  className="ri-information-line ri-xl text-dark-75 cursor-pointer"
                  data-tip={notesWithPriority}
                  data-for={index}
                />
                <ReactTooltip
                  id={index.toString()}
                  event="click"
                  multiline={true}
                  className="max-w-md break-all break-words whitespace-normal !pointer-events-auto"
                />
              </div>
            )}
          </div>
          <span className="font-meta text-dark-75">{formattedDate}</span>
        </div>
      </li>
    </>
  );
};

export default Timeline;
