import React from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import CustomTooltip from "@/components/shared/ChartsTooltip";
import COLORS from "./Colors";
import useWindowDimensions from "@/hooks/useWindowDimensions";

function ThumbnailPieChart({ data, openModal, title, countRequired = false }) {
  const { width } = useWindowDimensions();
  return (
    <ResponsiveContainer width={width > 640 ? 232 : "100%"} height={232}>
      <PieChart
        onClick={width > 640 ? openModal : null}
        className="bg-tint"
        cursor="pointer"
      >
        <text
          x={width > 640 ? "17" : "50%"}
          y="28"
          textAnchor={width > 640 ? "start" : "middle"}
          className="font-h3 sm:font-meta-bold"
        >
          {title}
        </text>
        {data && (
          <Pie
            data={data}
            dataKey="count"
            cx="50%"
            cy={126}
            outerRadius={85}
            isAnimationActive={false}
            stroke="rgba(0,0,0,0)"
          >
            {data.map((el, index) => (
              <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        )}
        <Tooltip content={<CustomTooltip countRequired={countRequired} />} />
      </PieChart>
    </ResponsiveContainer>
  );
}
export default ThumbnailPieChart;
