import React, { useState, useEffect } from "react";
import Filter from "@/components/shared/Filter";
import SelectGroup from "@/components/shared/SelectGroup";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import paramBuilder from "@/utils/network/paramBuilder";
import FilterToolbar from "@/components/shared/FilterToolbar";
import { useDebounce } from "use-debounce";
import Checkbox from "@/components/shared/Checkbox";

const status = [
  { name: "Active", id: "false" },
  { name: "Suspended", id: "true" },
];
const paperworkOptions = [
  { name: "Is Compliant", id: "true" },
  { name: "Is not Compliant", id: "false" },
];
const sortOptions = [
  { name: "First Name, A to Z", id: "firstName" },
  { name: "First Name, Z to A", id: "-firstName" },
  { name: "Last Name, A to Z", id: "lastName" },
  { name: "Last Name, Z to A", id: "-lastName" },
  { name: "Role", id: "role" },
  { name: "Center", id: "center" },
  { name: "Last Login, Newest", id: "-lastLoggedInAt" },
  { name: "Last Login, Oldest", id: "lastLoggedInAt" },
  { name: "Member since, Newest", id: "-createdAt" },
  { name: "Member since, Oldest", id: "createdAt" },
];

const PeopleFilter = ({
  total,
  isLoading,
  queryFilter,
  setFilters,
  selectedTab,
}) => {
  const [titleFilter, setTitleFilter] = useState("");
  const [debouncedTitleFilter] = useDebounce(titleFilter, 500);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedRoles, setSelectedRoles] = useState<FilterOption[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<FilterOption[]>([]);
  const [selectedPaperwork, setSelectedPaperwork] = useState<FilterOption[]>(
    [],
  );
  const [selectedCenters, setSelectedCenters] = useState<FilterOption[]>([]);
  const [legalTeam, setLegalTeam] = useState(false);
  const [rolesFocus, setRolesFocus] = useState(false);
  const [statusesFocus, setStatusesFocus] = useState(false);
  const [paperworkFocus, setPaperworkFocus] = useState(false);
  const [centerFocus, setCenterFocus] = useState(false);

  const { data: filtersData } = useQuery(["peopleFilters"], () =>
    fetcher("/api/users/filters"),
  );

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        name: debouncedTitleFilter,
        role_admin: selectedRoles.map(f => f.id),
        suspended: selectedStatuses.map(s => s.id),
        compliance: selectedPaperwork.map(s => s.id),
        centers: selectedCenters.map(s => s.id),
        legalTeam: legalTeam,
        archived: selectedTab?.slug == "archived",
      },
      sort: [selectedSort.id],
    });
    if (queryFilter !== filters) {
      setFilters(filters);
    }
  }, [
    queryFilter,
    setFilters,
    debouncedTitleFilter,
    selectedSort,
    selectedRoles,
    selectedStatuses,
    selectedPaperwork,
    selectedCenters,
    legalTeam,
    selectedTab,
  ]);

  return (
    <FilterToolbar
      total={total}
      filterText={titleFilter}
      setFilterText={setTitleFilter}
      isLoading={isLoading}
      placeHolderText="Find members by name"
    >
      <Filter
        name="role"
        items={filtersData?.roles_full}
        selectedItems={selectedRoles}
        setSelectedItems={setSelectedRoles}
        highlight={rolesFocus}
        setFocus={setRolesFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">Roles</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filtersData?.roles_full}
            selectedOptions={selectedRoles}
            setSelectedOptions={setSelectedRoles}
            className="filter-checkbox-group"
          ></CheckboxGroup>
        </Filter.Content>
      </Filter>
      <Filter
        name="status"
        items={status}
        selectedItems={selectedStatuses}
        setSelectedItems={setSelectedStatuses}
        highlight={statusesFocus}
        setFocus={setStatusesFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">Status</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={status}
            selectedOptions={selectedStatuses}
            setSelectedOptions={setSelectedStatuses}
            className="filter-checkbox-group"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="paperwork"
        items={paperworkOptions}
        selectedItems={selectedPaperwork}
        setSelectedItems={setSelectedPaperwork}
        highlight={paperworkFocus}
        setFocus={setPaperworkFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">Paperwork</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={paperworkOptions}
            selectedOptions={selectedPaperwork}
            setSelectedOptions={setSelectedPaperwork}
            className="filter-checkbox-group"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="centers"
        items={filtersData?.centers}
        selectedItems={selectedCenters}
        setSelectedItems={setSelectedCenters}
        highlight={centerFocus}
        setFocus={setCenterFocus}
        className="flex-1"
      >
        <Filter.Label size="sm">Centers</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filtersData?.centers}
            selectedOptions={selectedCenters}
            setSelectedOptions={setSelectedCenters}
            className="filter-checkbox-group"
          />
        </Filter.Content>
      </Filter>
      <i className="ri-arrow-up-down-line text-xl ml-5 mr-2.5 text-black-placeholder"></i>
      <Filter
        name="sort"
        items={sortOptions}
        selectedItems={selectedSort}
        setSelectedItems={setSelectedSort}
        closeOnSelect
      >
        <Filter.Label size="sm">{selectedSort.name}</Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={sortOptions}
            selectedOption={selectedSort}
            setSelectedOption={setSelectedSort}
          />
        </Filter.Content>
      </Filter>
      <Checkbox onChange={() => setLegalTeam(!legalTeam)} className="py-2 px-4">
        Legal Team
      </Checkbox>
    </FilterToolbar>
  );
};

export default PeopleFilter;
