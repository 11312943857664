import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import ScoreItem from "./ScoreItem";
import InventionsHistory from "./InventionsHistory";
import LicensedInventions from "./LicensedInventions";
import { isEmpty } from "lodash";

function SummaryTab() {
  const { data: summary, isFetching } = useQuery(["inventions-summary"], () =>
    fetcher("/api/inventions/summary"),
  );
  return (
    <div className="px-8 pt-4 pb-8">
      <div className="grid grid-cols-4 gap-2 max-w-[936px]">
        <ScoreItem
          status="pending"
          total={summary && summary.review_status_summary.pending}
          ipSummary={summary && summary.ip_summary.pending}
          isFetching={isFetching}
        />
        <ScoreItem
          status="accepted"
          total={summary && summary.review_status_summary.accepted}
          ipSummary={summary && summary.ip_summary.accepted}
          isFetching={isFetching}
        />
        <ScoreItem
          status="declined"
          total={summary && summary.review_status_summary.declined}
          ipSummary={summary && summary.ip_summary.declined}
          isFetching={isFetching}
        />
        <ScoreItem
          status="licensed"
          total={summary && summary.review_status_summary.licensed}
          ipSummary={summary && summary.ip_summary.licensed}
          isLinkeable={false}
          isFetching={isFetching}
        />
      </div>
      <InventionsHistory
        data={summary && summary.review_status_by_center}
        isFetching={isFetching}
        className="mt-4 max-w-[936px]"
      />
      {(isFetching ||
        (!isFetching && !isEmpty(summary.licensed_inventions))) && (
        <LicensedInventions
          data={summary && summary.licensed_inventions}
          isFetching={isFetching}
          className="mt-4 max-w-[936px]"
        />
      )}
    </div>
  );
}

export default SummaryTab;
