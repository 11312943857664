import * as React from "react";
import Table from "@/components/shared/Table";

function ResearchCardSkeleton() {
  return (
    <Table.Row className="animate-pulse divide-x divide-lines">
      <Table.Cell expand={false}>
        <div className="flex flex-col gap-2">
          <div className="bg-dark-25 h-2 rounded-full w-full" />
          <div className="bg-dark-25 h-2 w-3/4 rounded-full" />
          <div className="bg-dark-25 h-2 w-20 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell expand={false} style={{ minWidth: 144 }}>
        <div className="bg-dark-25 h-2 w-2/3 rounded-full" />
      </Table.Cell>
      <Table.Cell>
        <div className="flex">
          <div className="bg-dark-25 h-6 w-6 mr-1 rounded-full" />
          <div className="bg-dark-25 h-6 w-6 mr-1 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell expand={false} style={{ minWidth: 160 }}>
        <div className="flex">
          <div className="bg-dark-25 h-2 w-full rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex">
          <div className="bg-dark-25 h-6 w-6 mr-1 rounded-full" />
          <div className="bg-dark-25 h-6 w-6 mr-1 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="bg-dark-25 h-2 w-20 rounded-full" />
      </Table.Cell>
      <Table.Cell>
        <div className="flex">
          <div className="bg-dark-25 h-4 w-1/2 rounded-full mr-3" />
          <div className="bg-dark-25 h-4 w-1/2 rounded-full" />
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

export default ResearchCardSkeleton;
