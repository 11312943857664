import * as React from "react";
import { useRef } from "react";
import RichText from "../RichText";

type previewProps = {
  text: any;
  className?: string;
  textRef?: HTMLDivElement;
};

function PreviewText({ text, className = "", textRef }: previewProps) {
  let content;
  let isString = true;
  let contentRef = textRef;
  try {
    content = JSON.parse(text);
    isString = false;
  } catch (_) {
    content = text;
  }

  if (!textRef) {
    contentRef = useRef();
  }

  return (
    <>
      {isString ? (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <RichText value={content} textRef={contentRef} className={className} />
      )}
    </>
  );
}

export default PreviewText;
