import * as React from "react";
import { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { denormalizedFetcher } from "@/utils/api";
import { pluralize } from "@/utils/string";
import paramBuilder from "@/utils/network/paramBuilder";
import Filters from "./Filters";
import ResearchTable from "@/components/shared/ResearchTable/ResearchTable";

const fetchResearch = ({ pageParam = 0, queryKey }) => {
  const [_, centerId, filters] = queryKey;
  const params = paramBuilder({
    include: ["authors"],
    fields: {
      researchDocuments: [
        "title",
        "status",
        "createdAt",
        "authors",
        "sortedAuthors",
        "readableType",
        "publication",
      ],
      users: ["fullName", "slug"],
    },
  });

  const centerFilter = `filter[centers]=${centerId}`;
  const pagination = `page[number]=${pageParam + 1}&page[size]=5`;
  const url = `/api/research-documents?${pagination}&${params}&${centerFilter}&${filters}`;

  return denormalizedFetcher(url);
};

function ResearchTab({ center }) {
  const [filters, setFilters] = useState("");

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["centerResearch", center.id, filters], fetchResearch, {
    getNextPageParam: apiCanFetch,
  });
  const { data: researchDocuments, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("section-router");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const recordCount = isFetching ? "" : meta.recordCount || 0;

  return (
    <div>
      <Filters setFilters={setFilters} />
      <div className="mt-7">
        <h3 className="text-byte-dark font-h3 mb-5">
          {isFetching
            ? "Retrieving research items..."
            : `Displaying ${recordCount} ${pluralize(
                recordCount,
                "Research Item",
              )}`}
        </h3>
        <ResearchTable
          widthTitle={332}
          {...{ researchDocuments, isFetching, isFetchingNextPage }}
        />
      </div>
    </div>
  );
}

export default ResearchTab;
