const authorsToDisplay = (sortedAuthors, authors) => {
  const filterSortedAuthors = sortedAuthors.filter(el => el !== undefined);
  const filterAuthors = authors.filter(el => el !== undefined);
  if (filterSortedAuthors.length === 0) {
    return [];
  }
  const authorsObj = filterAuthors.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: curr }),
    {},
  );
  return filterSortedAuthors.map(author => {
    if (author.type === "users") {
      return authorsObj[author.id];
    }
    return author;
  });
};

export default authorsToDisplay;
