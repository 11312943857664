import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const YTick = props => {
  const {
    verticalAnchor,
    visibleTicksCount,
    tickFormatter,
    ...otherProps
  } = props;
  return (
    <text {...otherProps} className="!text-[12px]">
      <tspan dx="-10" dy="0.335em">
        {props.payload.value}
      </tspan>
    </text>
  );
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload !== undefined) {
    const { name, count } = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <p className="font-meta">
          {name}: <span className="font-meta-bold">{count}</span>
        </p>
      </div>
    );
  }
  return null;
};

function SimpleAreaChart({ data, dataKey }) {
  return (
    <ResponsiveContainer width={"100%"} height={202}>
      <AreaChart
        width={340}
        height={177}
        data={data}
        margin={{ top: 0, left: 0, right: 15, bottom: 0 }}
        cx="50%"
        cy="50%"
        outerRadius="95%"
      >
        <CartesianGrid stroke="#CFD3DA99" strokeDasharray="3 3" />
        <XAxis
          dataKey={dataKey}
          stroke="#8590A1"
          tick={{ fontSize: "11px" }}
          interval={0}
          height={20}
        />
        <YAxis tick={<YTick />} width={40} stroke="#8590A1" />
        <Tooltip content={<CustomTooltip />} />
        <Area dataKey="count" stroke="#17B583" fill="#17B58333" />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default SimpleAreaChart;
