import * as React from "react";
import { format, parseISO } from "date-fns";

const professionSort = (a, b) =>
  new Date(b.attributes.startDate).getTime() -
  new Date(a.attributes.startDate).getTime();

const educationSort = (a, b) =>
  new Date(b.attributes.startYear).getTime() -
  new Date(a.attributes.startYear).getTime();

function ProfessionalExperience({ experience }) {
  const { title, institution, startDate, endDate } = experience.attributes;
  const formattedStartDate = startDate
    ? format(parseISO(startDate), "MMMM, yyyy")
    : null;
  const formattedEndDate = endDate
    ? format(parseISO(endDate), "MMMM, yyyy")
    : null;

  return (
    <div className="px-6 py-4">
      <h3 className="font-h3 text-dark-100 mt-0.5">{title}</h3>
      <p className="font-body-bold text-dark-75">{institution}</p>
      {formattedStartDate && (
        <p className="font-body text-dark-75">
          {formattedStartDate} - {formattedEndDate || "Present"}
        </p>
      )}
    </div>
  );
}

function EducationalExperience({ experience }) {
  const { title, institution, startYear, endYear } = experience.attributes;

  return (
    <div className="px-6 py-4">
      <h3 className="font-h3 text-dark-100 mt-0.5">{institution}</h3>
      <p className="font-body-bold text-dark-75">{title}</p>
      {startYear && (
        <p className="font-body text-dark-75">
          {startYear} - {endYear || "Present"}
        </p>
      )}
    </div>
  );
}

function ExperienceTab({ user }) {
  const professionalExperiences =
    user.relationships.professionalExperiences.data;
  const educationalExperiences = user.relationships.educationalExperiences.data;

  professionalExperiences.sort(professionSort);
  educationalExperiences.sort(educationSort);

  return (
    <div className="space-y-10">
      {professionalExperiences.length > 0 && (
        <div>
          <h2 className="font-h2 mb-5 text-byte-dark">
            Professional Experience
          </h2>
          <div className="border border-lines rounded-md divide-y divide-lines shadow-sm">
            {professionalExperiences.map(experience => (
              <ProfessionalExperience
                key={experience.id}
                experience={experience}
              />
            ))}
          </div>
        </div>
      )}
      {educationalExperiences.length > 0 && (
        <div>
          <h2 className="font-h2 mb-5 text-byte-dark">Education</h2>
          <div className="border border-lines rounded-md divide-y divide-lines shadow-sm">
            {educationalExperiences.map(experience => (
              <EducationalExperience
                key={experience.id}
                experience={experience}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExperienceTab;
