import React from "react";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";

function SalesforceForm() {
  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Salesforce</h3>
        <p className="text-14 text-dark-75">Project's Salesforce information</p>
      </header>
      <section className="flex-1">
        <FormBlock
          label="Salesforce Contract ID"
          name="salesforceId"
          labelClassName="font-body-bold"
        >
          <Input styleAs="small" />
        </FormBlock>
      </section>
    </article>
  );
}

export default SalesforceForm;
