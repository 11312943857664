import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import dateGroupFormat from "./dateFormat";
import projectPhotoPlaceholder2x from "@/images/project-photo-placeholder@2x.png";

function Skeleton() {
  return (
    <section className="w-full flex flex-col lg:flex-row h-auto lg:h-[365px] ">
      <header className="bg-dark-100 h-auto lg:h-full w-full lg:w-2/4 rounded-t-md  lg:rounded-t-none lg:rounded-l-md p-[30px] flex items-center">
        <div>
          <div className="w-80 h-7.5 bg-dark-50 rounded-full animate-pulse mb-1" />
          <div className="w-64 h-7.5 bg-dark-50 rounded-full animate-pulse mb-6" />
          <div className="w-64 h-6 bg-dark-50 rounded-full animate-pulse mb-1" />
          <div className="w-24 h-3.5 bg-dark-50 rounded-full animate-pulse" />
          <div className="w-28 h-8 bg-primary rounded-full animate-pulse mt-4" />
        </div>
      </header>
      <HeroImage imageUrl={null} />
    </section>
  );
}

function HeroImage({ imageUrl }) {
  const url = !!imageUrl ? imageUrl : projectPhotoPlaceholder2x;
  return (
    <figure className="h-[240px] lg:h-full w-full lg:w-2/4">
      <img
        src={url}
        className={clsx(
          "bg-dark-100  h-full rounded-b-md lg:rounded-b-none lg:rounded-r-md",
          {
            "object-cover w-full": !!imageUrl,
            "object-fit min-h-full min-w-full": !imageUrl,
          },
        )}
      />
    </figure>
  );
}
function RetreatHero({ isLoading, retreat }) {
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        retreat !== undefined &&
        retreat?.data !== null && (
          <section className="w-full flex flex-col lg:flex-row h-auto lg:h-[365px] ">
            <header className="bg-dark-100 h-auto lg:h-full w-full lg:w-2/4 rounded-t-md  lg:rounded-t-none lg:rounded-l-md p-[30px] flex items-center">
              <div>
                <h2 className="font-h1 text-white mb-3">
                  {retreat.data.title}
                </h2>
                <time className="font-h3 text-white mb-2">
                  {dateGroupFormat(
                    retreat.data.start_date,
                    retreat.data.end_date,
                  )}
                </time>
                <address className="font-label text-dark-50 not-italic">
                  {retreat.data.location}
                </address>

                <Link
                  to={`/retreats/${retreat.data.id}`}
                  className="btn-round btn-secondary font-meta-bold btn-md mt-4"
                >
                  Learn More
                </Link>
              </div>
            </header>
            <HeroImage imageUrl={retreat.coverImgUrl} />
          </section>
        )
      )}
    </>
  );
}

export default RetreatHero;
