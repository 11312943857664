import * as React from "react";
import Table from "@/components/shared/Table";

function InventionCardSkeleton({ widthTitle }) {
  return (
    <Table.Row className="animate-pulse">
      <Table.Cell expand={false}>
        <div className="flex flex-col gap-2">
          <div
            className="bg-dark-25 h-4 rounded-full"
            style={{ width: widthTitle }}
          />
          <div className="bg-dark-25 h-4 w-3/4 rounded-full" />
          <div className="bg-dark-25 h-4 w-20 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell expand={false} style={{ minWidth: 144 }}>
        <div className="bg-dark-25 h-4 w-20 rounded-full" />
      </Table.Cell>
      <Table.Cell>
        <div className="bg-dark-25 h-4 w-20 rounded-full" />
      </Table.Cell>
      <Table.Cell expand={false} style={{ minWidth: 150 }}>
        <div className="flex justify-end">
          <div className="bg-dark-25 h-4 w-20 rounded-full" />
        </div>
      </Table.Cell>
      <Table.Cell expand={false} style={{ minWidth: 150 }}>
        <div className="flex justify-end">
          <div className="bg-dark-25 h-4 w-20 rounded-full" />
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

export default InventionCardSkeleton;
