import * as React from "react";
import { useState } from "react";
import { FilterOption } from "@/types";
import Tabs from "@/components/shared/Tabs";
import Inventions from "./Inventions";
import Insights from "./Insights";

const tabs: FilterOption[] = [
  { id: "inventions", name: "Inventions" },
  { id: "ip-dashboard", name: "IP Dashboard" },
];

const TabSection = props => {
  switch (props.tab) {
    case "inventions":
      return <Inventions />;
    case "ip-dashboard":
      return <Insights />;
    default:
      return null;
  }
};

function InventionsPage() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <div className="margin-safe bg-white py-5">
      <Tabs tabs={tabs} {...{ selectedTab, setSelectedTab }} />
      <section className="">
        {selectedTab && <TabSection tab={selectedTab.id} />}
      </section>
    </div>
  );
}

export default InventionsPage;
