import React, { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import ResearchStudiesFilter from "./ResearchStudiesFilter";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import ResearchStudiesTable from "./ResearchStudiesTable";

const fetchResearchStudies = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const baseUrl = `/api/research-studies`;
  const params = paramBuilder({
    include: ["projectLeads", "members", "focusAreas"],
    fields: {
      researchStudies: [
        "name",
        "compliant",
        "createdAt",
        "centersAndInstitutions",
        "members",
        "projectLeads",
        "focusAreas",
        "isPublic",
        "featured",
        "archived",
      ],
      users: ["fullName"],
    },
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `${baseUrl}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

function ResearchStudiesPage() {
  const [filters, setFilters] = useState("");
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["admin_research_studies", filters],
    fetchResearchStudies,
    {
      getNextPageParam: apiCanFetch,
    },
  );
  const { data: researchDocuments, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("research-section-navigation");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  return (
    <div className="bg-tint h-screen overflow-hidden">
      <AdminHeader
        title="Research Studies"
        description="All Research Study projects at PICI"
        className="!h-[106px]"
        linkText="+ New Research Study"
        linkTo="/admin/projects/new?type=research_study"
        linkReloadDocument
        exportText="Download CSV"
        exportLinkTo="/admin/projects/export.csv?type=ResearchStudy"
      >
        <ResearchStudiesFilter
          total={meta?.recordCount}
          isLoading={isFetching}
          setFilters={setFilters}
        />
      </AdminHeader>
      <article
        id="research-section-navigation"
        className="overflow-x-hidden h-vh-60 px-7.5 bg-primary-lighter"
      >
        {
          <ResearchStudiesTable
            researchItems={researchDocuments}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            filters={filters}
          />
        }
      </article>
    </div>
  );
}

export default ResearchStudiesPage;
