import { useContext } from "react";
import FlashMessageContext from "@/components/shared/FlashMessage/FlashMessageContext";

function useFlashMessage({
  message,
  errorMessage = "Uh oh, something went wrong. It's not your fault (it's the server), but please try again.",
}: {
  message: string;
  errorMessage?: string;
}) {
  const { setFlash } = useContext(FlashMessageContext);

  const showFlash = ({ success = true }: { success: boolean }) =>
    setFlash({
      visible: true,
      success: success,
      message: success ? message : errorMessage,
    });

  return showFlash;
}

export default useFlashMessage;
