import * as Yup from "yup";
import { format, parse } from "date-fns";
import { isEmpty } from "lodash";
import {
  SectionMode,
  KeySection,
} from "@/components/shared/Retreat/Article/defaults";

const articleSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Article title is required."),
  presentationDate: Yup.string()
    .required("Start Date is required.")
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/im, {
      message: "Date format is MM/DD/YYYY",
      excludeEmptyString: true,
    }),
  retreatId: Yup.string().required("Retreat is required."),
  thumbnailImage: Yup.string().required("Thumbnail Image is required."),
  description: Yup.string().required("Description is required."),
});

const mapRequest = request => {
  return {
    data: {
      ...(request.id ? { id: request.id } : {}),
      type: "articles",
      attributes: {
        title: request.title,
        retreatId: request.retreatId,
        published: request.published,
        sectionImages: request.sectionImages.map(
          sectionImage => sectionImage.signedId,
        ),
        slideshowImages: request.slideshowImages.filter(
          slide => slide !== null,
        ),
        thumbnailImage: request.thumbnailImage,
        presentationDate: parse(
          request.presentationDate,
          "MM/dd/yyyy",
          new Date(),
        ),
        articleSpeakers: request.articleSpeakers,
        articleSections: request.articleSections,
        resourceLinks: request.resourceLinks,
        description: request.description,
      },
    },
  };
};

const defaultContentByKeySection = (keySection: KeySection) => {
  switch (keySection) {
    case KeySection.SmallHeader:
    case KeySection.LargeHeader:
    case KeySection.ReferenceText:
      return { title: "", description: null };
    case KeySection.BasicTable:
      return {
        headers: ["Header 1", "Header 2", "Header 3"],
        body: [1, 2, 3].map(_ => ["", "", ""]),
        description: null,
      };
    case KeySection.ImageFullWidth:
      return { signedId1: null, description1: null };
    case KeySection.ImageTwoColumns:
      return {
        signedId1: null,
        description1: null,
        signedId2: null,
        description2: null,
      };
    default:
      return {};
  }
};

const mapResponse = response => {
  const attributes = response.data.attributes;
  const articleSpeakers = response.included
    ? response.included
        .filter(item => item.type === "articleSpeakers")
        .map(speaker => ({
          articleId: speaker.attributes.articleId,
          avatarUrl: speaker.attributes.avatarUrl,
          fullName: speaker.attributes.fullName,
          title: speaker.attributes.title,
          userId: speaker.attributes.userId,
        }))
    : [];
  const articleSections = response.included
    ? response.included
        .filter(item => item.type === "articleSections")
        .map(section => ({
          id: section.id,
          keySection: section.attributes.keySection,
          articleId: section.attributes.articleId,
          position: section.attributes.position,
          content: section.attributes.content,
          viewMode: SectionMode.Preview,
        }))
        .sort((a, b) => a.position - b.position)
    : [];

  return {
    id: response.data.id,
    title: attributes.title,
    retreatId: attributes.retreatId,
    published: attributes.published,
    sectionImages: attributes.sectionImages,
    slideshowImages: isEmpty(attributes.slideshowImages)
      ? [null]
      : attributes.slideshowImages,
    slideshowImagesUrl: isEmpty(attributes.slideshowImagesUrl)
      ? [null]
      : attributes.slideshowImagesUrl,
    thumbnailImage: attributes.thumbnailImage,
    thumbnailImageUrl: attributes.thumbnailImageUrl,
    thumbnailImageMetadata: attributes.thumbnailImageMetadata,
    presentationDate: format(
      new Date(attributes.presentationDate),
      "MM/dd/yyyy",
    ),
    resourceLinks: attributes.resourceLinks,
    description: attributes.description,
    articleSpeakers,
    articleSections,
  };
};

const parseUsers = (users, speakers) => {
  return users?.data
    .filter(
      user => speakers.findIndex(speaker => speaker.userId == user.id) === -1,
    )
    .map(user => {
      return {
        id: user.id,
        fullName: user.attributes.fullName,
        title: user.attributes.title,
        avatarUrl: user.attributes.avatarUrl,
      };
    });
};

const parseRetreatsFilter = retreats => {
  return retreats?.data.map(retreat => {
    return {
      id: retreat.id,
      title: retreat.attributes.title,
      quarter: retreat.attributes.quarter,
      coverImageUrl: retreat.attributes.coverImageUrl,
    };
  });
};

export {
  articleSchema,
  mapRequest,
  mapResponse,
  parseUsers,
  parseRetreatsFilter,
  defaultContentByKeySection,
};
