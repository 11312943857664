import * as React from "react";
import { useEffect, useState } from "react";
import { denormalizedFetcher } from "@/utils/api";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import paramBuilder from "@/utils/network/paramBuilder";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import { pluralize } from "@/utils/string";
import ResearchTable from "@/components/shared/ResearchTable/ResearchTable";
import Filters from "./Filters";

const fetchResearch = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const params = paramBuilder({
    include: ["authors"],
    fields: {
      researchDocuments: [
        "title",
        "status",
        "createdAt",
        "authors",
        "sortedAuthors",
        "readableType",
        "publication",
        "publicationLink",
      ],
      users: ["fullName", "slug", "projectsCount"],
    },
  });

  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `/api/research-documents?${pagination}&${params}&${filters}`;

  return denormalizedFetcher(url);
};

function ResearchDocumentsPage() {
  const [filters, setFilters] = useState("");
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["researchDocuments", filters], fetchResearch, {
    getNextPageParam: apiCanFetch,
  });

  const { data: researchDocuments, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("section-router");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const recordCount = isFetching ? "" : meta.recordCount || 0;

  return (
    <section className="margin-safe pb-4 sm:pb-8 xl:pb-12">
      <header className="lg:flex lg:items-center lg:justify-between py-5 lg:py-10">
        <div className="flex items-center">
          <h1 className="font-h2 xl:font-h1 font-bold text-dark-100">
            Research
          </h1>
        </div>
      </header>
      <div className="bg-tint rounded-lg p-6 flex flex-col justify-between items-start gap-4 sm:flex-row sm:items-center mb-10">
        <p className="text-dark-75 flex items-center gap-2">
          <i className="text-lg ri-draft-line heading-1 self-start" /> Working
          on something exciting? Join in and share it with the PICI network.
        </p>
        <a
          href="/research_documents/new"
          className="btn-primary btn-sm flex gap-2"
        >
          <i className="ri-add-line" />
          Upload Your Research
        </a>
      </div>

      <Filters setFilters={setFilters} />

      <h3 className="text-byte-dark font-h3 mb-5" data-testid="research-count">
        {isFetching
          ? "Retrieving research items..."
          : `Displaying ${recordCount} ${pluralize(
              recordCount,
              "Research Item",
            )}`}
      </h3>

      <ResearchTable
        widthTitle={640}
        {...{ researchDocuments, isFetching, isFetchingNextPage }}
      />
    </section>
  );
}

export default ResearchDocumentsPage;
