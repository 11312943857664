import React from "react";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";

function TimelineForm({ onChange }) {
  const handleOnChangeExpirationDate = event => {
    onChange("expirationDate", event.target.value);
  };
  const handleOnChangeStartDate = event => {
    onChange("startDate", event.target.value);
  };

  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Timeline</h3>
        <p className="text-14 text-dark-75">Project's Timeline information</p>
      </header>
      <section className="flex-1">
        <FormBlock
          label="Start Date"
          name="startDate"
          labelClassName="font-body-bold"
          optional
        >
          <Input
            id="date"
            styleAs="small"
            className="w-[240px] mb-6"
            placeholder="MM / DD / YYYY"
            onChange={handleOnChangeStartDate}
            autoComplete="off"
          />
        </FormBlock>
        <FormBlock
          label="Expiration Date"
          name="expirationDate"
          labelClassName="font-body-bold"
          optional
        >
          <Input
            id="date"
            styleAs="small"
            className="w-[240px]"
            placeholder="MM / DD / YYYY"
            onChange={handleOnChangeExpirationDate}
            autoComplete="off"
          />
        </FormBlock>
      </section>
    </article>
  );
}

export default TimelineForm;
