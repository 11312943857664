import * as React from "react";
import { useLayer } from "react-laag";
import CopyLink from "@/components/shared/CopyLink";

function PopoverMenu({ resource, showToast, children }) {
  const [isOpen, setOpen] = React.useState(false);
  const titleUrl = resource.attributes.titleUrl;

  // helper function to close the menu
  const close = () => setOpen(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: "top-end", // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });
  let newChildren = React.cloneElement(children, {
    ...triggerProps,
    onClick: () => setOpen(true),
  });

  return (
    <>
      {newChildren}
      {isOpen &&
        renderLayer(
          <CopyLink
            valueToCopy={titleUrl}
            close={close}
            layerProps={layerProps}
            onCopy={(_, result) => {
              close();
              showToast(result);
            }}
          />,
        )}
    </>
  );
}
export default PopoverMenu;
