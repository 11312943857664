import * as React from "react";
import { toast, Slide } from "react-toastify";

const renderToast = (iconClassName, text) => {
  function Toast() {
    return (
      <div className="flex items-center gap-4">
        <i className={`${iconClassName} text-xl`} />
        <p className="font-body-bold mr-4 ">{text}</p>
        <button className="ri-close-line text-xl" />
      </div>
    );
  }

  return () => <Toast />;
};

function useToast({
  successText,
  errorText = "An error has ocurred.",
  autoClose = 4000,
}: {
  successText: string | React.ReactElement;
  errorText?: string | React.ReactElement;
  autoClose?: number;
}) {
  const showSuccessToast = () =>
    toast.success(renderToast("ri-check-line", successText), {
      transition: Slide,
      closeButton: false,
      className: "bg-secondary",
      progressClassName: "bg-secondary-dark",
      autoClose: autoClose,
    });

  const showErrorToast = () =>
    toast.error(renderToast("ri-error-warning-line", errorText), {
      transition: Slide,
      closeButton: false,
      className: "bg-error",
      progressClassName: "bg-error-dark",
      autoClose: autoClose,
    });

  const showToast = (success: boolean = true) => {
    return success ? showSuccessToast() : showErrorToast();
  };

  return showToast;
}

export default useToast;
