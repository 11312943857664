import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import { useURLFilter } from "@/hooks/useURLFilter";
import Modal from "@/components/shared/Modal";
import Accordion from "@/components/shared/Accordion";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import RadioButtonGroup from "@/components/shared/RadioButtonGroup";

const licensedOptions = [
  { name: "Yes", id: "true" },
  { name: "No", id: "false" },
  { name: "Under Negotiation", id: "under_negotiation" },
];

// const hotTechOptions = [
//   { name: "Yes", id: "true" },
//   { name: "No", id: "false" },
// ];

// const interestStartupOptions = [
//   { name: "Yes", id: "true" },
//   { name: "No", id: "false" },
//   { name: "Unknown", id: "unknown" },
// ];

// const furtherDevelopingOptions = [
//   { name: "Yes", id: "true" },
//   { name: "No", id: "false" },
//   { name: "Unknown", id: "unknown" },
// ];

const caseAffiliatedOptions = [
  { name: "Active", id: "active" },
  { name: "Closed", id: "closed" },
  { name: "Unknown", id: "unknown" },
];

const filterCount = selectedItems => {
  const isArray = Array.isArray(selectedItems);
  if (isArray) {
    return selectedItems.length > 0 ? ` (${selectedItems.length})` : "";
  } else {
    return selectedItems ? ` (${selectedItems.name})` : "";
  }
};

function AdvancedFiltersModal({
  open,
  setOpen,
  showClearButton,
  onClear,
  filters,
  setFilters,
  setTechnologyTypes,
  setCellTherapySubtypes,
  showCenterFilter = false,
}) {
  const { data: filtersData } = useQuery(["inventionsFilters"], () =>
    fetcher("/api/inventions/filters"),
  );
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const setFilterKey = key => value =>
    setFilters(filters => ({ ...filters, [key]: value }));

  useURLFilter(
    "technology_type",
    filtersData?.technologyTypes,
    filters.technologyTypes,
    setFilterKey("technologyTypes"),
  );
  useURLFilter(
    "antibody_subtypes",
    filtersData?.antibodySubtypes,
    filters.antibodySubtypes,
    setFilterKey("antibodySubtypes"),
  );
  useURLFilter(
    "cell_therapy_subtypes",
    filtersData?.cellTherapySubtypes,
    filters.cellTherapySubtypes,
    setFilterKey("cellTherapySubtypes"),
  );
  useURLFilter(
    "disease_indication",
    filtersData?.diseaseIndications,
    filters.diseaseIndications,
    setFilterKey("diseaseIndications"),
  );
  useURLFilter(
    "biomarkers",
    filtersData?.biomarkers,
    filters.biomarkers,
    setFilterKey("biomarkers"),
  );
  useURLFilter(
    "biomarker_technology_subtype",
    filtersData?.biomarkerTechnologySubtypes,
    filters.biomarkerTechnologySubtypes,
    setFilterKey("biomarkerTechnologySubtypes"),
  );
  useURLFilter(
    "therapeutic_strategy",
    filtersData?.therapeuticStrategy,
    filters.therapeuticStrategy,
    setFilterKey("therapeuticStrategy"),
  );
  useURLFilter(
    "focus_area",
    filtersData?.focusAreas,
    filters.focusAreas,
    setFilterKey("focusAreas"),
  );
  useURLFilter(
    "center",
    filtersData?.centers,
    filters.centers,
    setFilterKey("centers"),
  );
  useURLFilter(
    "invention_class",
    filtersData?.inventionClasses,
    filters.inventionClasses,
    setFilterKey("inventionClasses"),
  );
  useURLFilter(
    "application_category",
    filtersData?.applicationCategories,
    filters.applicationCategories,
    setFilterKey("applicationCategories"),
  );
  useURLFilter(
    "development_stage",
    filtersData?.developmentStages,
    filters.developmentStages,
    setFilterKey("developmentStages"),
  );
  useURLFilter(
    "patent_stage",
    filtersData?.patentStages,
    filters.patentStages,
    setFilterKey("patentStages"),
  );
  useURLFilter(
    "licensed",
    licensedOptions,
    filters.licensed,
    setFilterKey("licensed"),
  );
  // useURLFilter(
  //   "hot_tech",
  //   hotTechOptions,
  //   filters.hotTech,
  //   setFilterKey("hotTech"),
  // );
  // useURLFilter(
  //   "interest_startup",
  //   interestStartupOptions,
  //   filters.interestInStartup,
  //   setFilterKey("interestInStartup"),
  // );
  // useURLFilter(
  //   "further_developing",
  //   furtherDevelopingOptions,
  //   filters.furtherDevelop,
  //   setFilterKey("furtherDevelop"),
  // );
  useURLFilter(
    "case_status_affiliated",
    caseAffiliatedOptions,
    filters.caseAffiliated,
    setFilterKey("caseAffiliated"),
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>Advanced Filters</Modal.Header>
      <Modal.Body className="pr-3 lg:pr-0">
        <Accordion>
          <Accordion.Label>
            Invention Class{filterCount(filters.inventionClasses)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.inventionClasses}
              selectedOptions={filters.inventionClasses}
              setSelectedOptions={setFilterKey("inventionClasses")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Biomarkers{filterCount(filters.biomarkers)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.biomarkers}
              selectedOptions={filters.biomarkers}
              setSelectedOptions={setFilterKey("biomarkers")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Biomarker Technology Subtypes
            {filterCount(filters.biomarkerTechnologySubtypes)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.biomarkerTechnologySubtypes}
              selectedOptions={filters.biomarkerTechnologySubtypes}
              setSelectedOptions={setFilterKey("biomarkerTechnologySubtypes")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Application Category{filterCount(filters.applicationCategories)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.applicationCategories}
              selectedOptions={filters.applicationCategories}
              setSelectedOptions={setFilterKey("applicationCategories")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Technology type{filterCount(filters.technologyTypes)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.technologyTypes}
              selectedOptions={filters.technologyTypes}
              setSelectedOptions={setTechnologyTypes}
              className="md:flex md:flex-col md:flex-wrap md:h-64"
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Antibody Subtype{filterCount(filters.antibodySubtypes)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.antibodySubtypes}
              selectedOptions={filters.antibodySubtypes}
              setSelectedOptions={setFilterKey("antibodySubtypes")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Cell Therapy Subtype{filterCount(filters.cellTherapySubtypes)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.cellTherapySubtypes}
              selectedOptions={filters.cellTherapySubtypes}
              setSelectedOptions={setCellTherapySubtypes}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Disease indication{filterCount(filters.diseaseIndications)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.diseaseIndications}
              selectedOptions={filters.diseaseIndications}
              setSelectedOptions={setFilterKey("diseaseIndications")}
              className="md:flex md:flex-col md:flex-wrap md:h-96"
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Therapeutic Strategy{filterCount(filters.therapeuticStrategy)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.therapeuticStrategy}
              selectedOptions={filters.therapeuticStrategy}
              setSelectedOptions={setFilterKey("therapeuticStrategy")}
            />
          </Accordion.Content>
        </Accordion>
        {/* <Accordion>
          <Accordion.Label>
            Focus areas{filterCount(filters.focusAreas)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.focusAreas}
              selectedOptions={filters.focusAreas}
              setSelectedOptions={setFilterKey("focusAreas")}
              className="md:flex md:flex-col md:flex-wrap md:h-100"
            />
          </Accordion.Content>
        </Accordion> */}
        {showCenterFilter && (
          <Accordion>
            <Accordion.Label>
              Center{filterCount(filters.centers)}
            </Accordion.Label>
            <Accordion.Content>
              <CheckboxGroup
                options={filtersData?.centers}
                selectedOptions={filters.centers}
                setSelectedOptions={setFilterKey("centers")}
              />
            </Accordion.Content>
          </Accordion>
        )}
        <Accordion>
          <Accordion.Label>
            Development stage{filterCount(filters.developmentStages)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.developmentStages}
              selectedOptions={filters.developmentStages}
              setSelectedOptions={setFilterKey("developmentStages")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Patent stage{filterCount(filters.patentStages)}
          </Accordion.Label>
          <Accordion.Content>
            <CheckboxGroup
              options={filtersData?.patentStages}
              selectedOptions={filters.patentStages}
              setSelectedOptions={setFilterKey("patentStages")}
            />
          </Accordion.Content>
        </Accordion>
        <Accordion>
          <Accordion.Label>
            Licensed / Optioned{filterCount(filters.licensed)}
          </Accordion.Label>
          <Accordion.Content>
            <RadioButtonGroup
              options={licensedOptions}
              selectedOption={filters.licensed}
              setSelectedOption={setFilterKey("licensed")}
            />
          </Accordion.Content>
        </Accordion>
        {/* Hiding these in favor https://linear.app/pici/issue/PIC-533/inventions-hide-the-fields-under-technology-development-details-from <Accordion>
          <Accordion.Label>
            Hot tech{filterCount(filters.hotTech)}
          </Accordion.Label>
          <Accordion.Content>
            <RadioButtonGroup
              options={hotTechOptions}
              selectedOption={filters.hotTech}
              setSelectedOption={setFilterKey("hotTech")}
            />
          </Accordion.Content>
        </Accordion> */}
        {/* <Accordion>
          <Accordion.Label>
            Inventor interest in start-up?
            {filterCount(filters.interestInStartup)}
          </Accordion.Label>
          <Accordion.Content>
            <RadioButtonGroup
              options={interestStartupOptions}
              selectedOption={filters.interestInStartup}
              setSelectedOption={setFilterKey("interestInStartup")}
            />
          </Accordion.Content>
        </Accordion> */}
        {/* <Accordion>
          <Accordion.Label>
            Inventor further developing in the lab?
            {filterCount(filters.furtherDevelop)}
          </Accordion.Label>
          <Accordion.Content>
            <RadioButtonGroup
              options={furtherDevelopingOptions}
              selectedOption={filters.furtherDevelop}
              setSelectedOption={setFilterKey("furtherDevelop")}
            />
          </Accordion.Content>
        </Accordion> */}
        <Accordion>
          <Accordion.Label>
            Case status at affiliated research institute?
            {filterCount(filters.caseAffiliated)}
          </Accordion.Label>
          <Accordion.Content>
            <RadioButtonGroup
              options={caseAffiliatedOptions}
              selectedOption={filters.caseAffiliated}
              setSelectedOption={setFilterKey("caseAffiliated")}
            />
          </Accordion.Content>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn-sm btn-primary"
          onClick={() => setOpen(false)}
        >
          Apply
        </button>
        {showClearButton && (
          <button
            type="button"
            className="btn-sm btn-white ml-2.5"
            onClick={onClear}
          >
            Clear
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AdvancedFiltersModal;
