import * as React from "react";
import moment from "moment";
import { range } from "lodash";
import { useState, useEffect } from "react";
import Input from "@/components/shared/Input";
import Select from "@/components/shared/Select";
import FormBlock from "@/components/shared/FormBlock";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";

const QUARTERS = [
  { id: "Q1", name: "Q1" },
  { id: "Q2", name: "Q2" },
  { id: "Q3", name: "Q3" },
  { id: "Q4", name: "Q4" },
];
const YEARS = () => {
  const currentYear = moment().year();
  const years = range(currentYear - 1, currentYear + 2);
  return years.reverse().map(year => {
    return { id: `${year}`, name: `${year}` };
  });
};

function BasicDetailForm({ values, onChange }) {
  const handleOnChangeStartDate = event => {
    onChange("startDate", event.target.value);
  };
  const handleOnChangeEndDate = event => {
    onChange("endDate", event.target.value);
  };
  const [period, year] = values?.quarter.split(", ");
  const [quarterPeriod, setQuarterPeriod] = useState(period);
  const [quarterYear, setQuarterYear] = useState(year);

  useEffect(() => {
    if (quarterPeriod && quarterYear) {
      onChange("quarter", `${quarterPeriod}, ${quarterYear}`);
    }
  }, [onChange, quarterPeriod, quarterYear]);

  return (
    <article className="flex p-7 pb-10 border-b">
      <header className="w-[260px] pr-20">
        <h3 className="text-17 font-bold text-neutral-1">Basic Details</h3>
        <p className="text-14 text-dark-75">
          General context around the retreat
        </p>
      </header>
      <section className="flex-1">
        <FormBlock
          label="Retreat Title"
          name="title"
          className="w-full mb-6"
          labelClassName="font-body-bold"
        >
          <Input styleAs="small" />
        </FormBlock>
        <FormBlock
          label="Location"
          name="location"
          className="w-full mb-6"
          labelClassName="font-body-bold"
        >
          <Input styleAs="small" />
        </FormBlock>
        <div className="flex gap-3">
          <FormBlock
            label="Start Date"
            name="startDate"
            labelClassName="font-body-bold"
          >
            <Input
              styleAs="small"
              className="w-[240px]"
              placeholder="MM / DD / YYYY"
              onChange={handleOnChangeStartDate}
              autoComplete="off"
            />
          </FormBlock>
          <FormBlock
            label="End Date"
            name="endDate"
            labelClassName="font-body-bold"
          >
            <Input
              styleAs="small"
              className="w-[240px]"
              placeholder="MM / DD / YYYY"
              onChange={handleOnChangeEndDate}
              autoComplete="off"
            />
          </FormBlock>
        </div>
        <div className="flex gap-3">
          <FormBlock label="Quarter" name="quarterPeriod" className="mt-6">
            <Select
              selected={quarterPeriod}
              setSelected={value => setQuarterPeriod(value)}
              options={QUARTERS}
              classNameContainer="w-[240px]"
              classNameSelectGroup="w-[240px]"
              placement="bottom-end"
            />
          </FormBlock>
          <FormBlock label="Year" name="quarterYear" className="mt-6">
            <Select
              selected={quarterYear}
              setSelected={value => setQuarterYear(value)}
              options={YEARS()}
              classNameContainer="w-[240px]"
              classNameSelectGroup="w-[240px]"
              placement="bottom-end"
            />
          </FormBlock>
        </div>
        <FormBlock
          label="Retreat Cover Image"
          name="coverImage"
          labelClassName="font-body-bold"
          className="mt-6"
        >
          <FilePickerAdmin
            name="coverImage"
            setSignedId={val => onChange("coverImage", val)}
            attachment={{
              signedId: values.coverImage,
              metadata: values.coverImageMetadata,
              imageUrl: values.coverImageUrl,
            }}
          />
        </FormBlock>
      </section>
    </article>
  );
}

export default BasicDetailForm;
