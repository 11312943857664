import * as React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import fetch from "cross-fetch";
import useCurrentUser from "@/hooks/useCurrentUser";
import useFlashMessage from "@/hooks/useFlashMessage";
import AuthorList from "@/components/shared/AuthorList";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import DeleteModal from "@/components/shared/Modal/DeleteConfirmationModal";
import authorsToDisplay from "@/utils/authorsToDisplay";

function Header({ research }) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const canEdit =
    currentUser.attributes.isStaff ||
    research.relationships.authors.data.includes(
      author => author.id === currentUser.id,
    );

  const {
    title,
    readableType,
    canDownload,
    sortedAuthors,
  } = research.attributes;
  const authors = research.relationships.authors?.data;
  const { download, edit } = research.links;
  const focusAreas = research.relationships.focusAreas.data;

  const showFlash = useFlashMessage({
    message: "The research has been deleted.",
  });

  const onDelete = () => {
    fetch(research.links.self, { method: "DELETE" }).then(res => {
      showFlash({ success: res.ok });
      navigate("/research_documents");
    });
  };

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  return (
    <div className="flex flex-col gap-10 mb-10">
      <div>
        <div className="flex flex-wrap gap-2.5 items-center">
          <i className="ri-article-line text-dark-75 text-17 leading-0" />
          <p className="font-label text-dark-75">{readableType}</p>
        </div>
        <h1 className="sm:font-h1 font-h2 mb-4">{title}</h1>
        {authors && sortedAuthors?.length > 0 && (
          <p className="font-nody text-dark-75">
            <AuthorList authors={authorsToDisplay(sortedAuthors, authors)} />
          </p>
        )}
        <FocusAreaTags focusAreas={focusAreas} className="mt-6" />
        <div className="flex flex-wrap gap-3 mt-7.5 sm:mt-10">
          {canDownload && (
            <a
              href={download}
              target="_blank"
              className="btn-primary btn-md capitalize"
            >
              Download {readableType}
            </a>
          )}
          {canEdit && (
            <Link to={edit} className="btn-white btn-md">
              Edit Research Details
            </Link>
          )}
          {canEdit && (
            <button
              className="btn-white btn-md flex gap-2.5"
              onClick={() => setDeleteModalIsOpen(true)}
            >
              <i className="ri-delete-bin-line text-15 leading-5 text-dark-50"></i>
              Delete
            </button>
          )}
        </div>
        <DeleteModal
          open={deleteModalIsOpen}
          onAccept={onDelete}
          setOpen={() => setDeleteModalIsOpen(false)}
          resource="research document"
          className="w-full min-w-min max-w-2xl"
          footerClassName="flex flex-wrap gap-2.5 pt-6 flex-col-reverse sm:flex-row-reverse justify-end"
        />
      </div>
    </div>
  );
}

export default Header;
