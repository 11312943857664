import React, { useState, useEffect } from "react";
import Filter from "@/components/shared/Filter";
import SelectGroup from "@/components/shared/SelectGroup";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import paramBuilder from "@/utils/network/paramBuilder";
import FilterToolbar from "@/components/shared/FilterToolbar";
import { useDebounce } from "use-debounce";

const sortOptions = [
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "Date, Oldest First", id: "createdAt" },
  { name: "Title, A to Z", id: "title" },
  { name: "Title, Z to A", id: "-title" },
];

const ResearchFilter = ({ total, isLoading, setFilters }) => {
  const [titleFilter, setTitleFilter] = useState("");
  const [debouncedTitleFilter] = useDebounce(titleFilter, 500);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedTypes, setSelectedTypes] = useState<FilterOption[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<FilterOption[]>([]);
  const [focusAreasFocus, setFocusAreasFocus] = useState(false);
  const [typesFocus, setTypesFocus] = useState(false);
  const [statusesFocus, setStatusesFocus] = useState(false);

  const { data: filtersData } = useQuery(["researchFilters"], () =>
    fetcher("/api/research-documents/filters"),
  );

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        name: debouncedTitleFilter,
        focusAreas: selectedFocusAreas.map(f => f.id),
        researchType: selectedTypes.map(t => t.id),
        status: selectedStatuses.map(s => s.id),
      },
      sort: [selectedSort.id],
    });
    setFilters(filters);
  }, [
    setFilters,
    debouncedTitleFilter,
    selectedSort,
    selectedFocusAreas,
    selectedTypes,
    selectedStatuses,
  ]);

  return (
    <FilterToolbar
      total={total}
      filterText={titleFilter}
      setFilterText={setTitleFilter}
      isLoading={isLoading}
    >
      <Filter
        name="focus_area"
        items={filtersData?.focusAreas}
        selectedItems={selectedFocusAreas}
        setSelectedItems={setSelectedFocusAreas}
        highlight={focusAreasFocus}
        setFocus={setFocusAreasFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">Focus Area</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filtersData?.focusAreas}
            selectedOptions={selectedFocusAreas}
            setSelectedOptions={setSelectedFocusAreas}
            className="focus-area-checkbox-group"
            labelClassName="sm:w-325px !py-1"
          >
            <div>
              <a
                href="/admin/focus_areas"
                className="p-2 btn-sm btn-white ml-3 mt-1 font-normal hover:text-white hover:bg-primary border-primary"
              >
                + Create New Focus Area
              </a>
            </div>
          </CheckboxGroup>
        </Filter.Content>
      </Filter>
      <Filter
        name="research_type"
        items={filtersData?.types}
        selectedItems={selectedTypes}
        setSelectedItems={setSelectedTypes}
        highlight={typesFocus}
        setFocus={setTypesFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">Research Type</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filtersData?.types}
            selectedOptions={selectedTypes}
            setSelectedOptions={setSelectedTypes}
            className="filter-checkbox-group"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="status"
        items={filtersData?.statuses}
        selectedItems={selectedStatuses}
        setSelectedItems={setSelectedStatuses}
        highlight={statusesFocus}
        setFocus={setStatusesFocus}
        className="flex-1"
      >
        <Filter.Label size="sm">Status</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filtersData?.statuses}
            selectedOptions={selectedStatuses}
            setSelectedOptions={setSelectedStatuses}
            className="filter-checkbox-group"
          />
        </Filter.Content>
      </Filter>
      <i className="ri-arrow-up-down-line text-xl ml-5 mr-2.5 text-black-placeholder"></i>
      <Filter
        name="sort"
        items={sortOptions}
        selectedItems={selectedSort}
        setSelectedItems={setSelectedSort}
        closeOnSelect
      >
        <Filter.Label size="sm">{selectedSort.name}</Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={sortOptions}
            selectedOption={selectedSort}
            setSelectedOption={setSelectedSort}
          />
        </Filter.Content>
      </Filter>
    </FilterToolbar>
  );
};

export default ResearchFilter;
