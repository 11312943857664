import * as React from "react";
import { useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ellipsis } from "ellipsed";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import projectPhotoPlaceholder2x from "@/images/project-photo-placeholder@2x.png";

function Skeleton() {
  return (
    <section data-testid="featuredProjectsSkeleton">
      <div className="w-1/3 h-7.5 bg-dark-50 rounded-full animate-pulse mt-9 mb-6" />
      <div className="flex flex-col lg:flex-row w-full gap-5">
        {[1, 2].map(x => (
          <div
            key={x}
            className="rounded-lg w-full lg:w-1/2 h-56 lg:h-80 animate-pulse"
          >
            <div className="h-full bg-dark-100 rounded-lg overflow-hidden">
              <div className="h-full flex flex-col items-start p-5 lg:p-10 opacity-20">
                <div className="inline-flex py-1 mb-9 lg:mb-24 bg-dark-25 h-4 w-20 rounded-full" />
                <div className="mt-auto mb-2 bg-dark-25 h-4 w-1/4 rounded-full" />
                <div className="bg-dark-25 h-9 w-full sm:w-3/4 rounded-full" />
                <div className="flex items-center w-full mt-2">
                  <div className="bg-dark-25 h-4 w-1/4 sm:w-1/5 rounded-full" />
                  <div className="ml-2 bg-dark-25 h-4 w-1/4 sm:w-1/5 rounded-full" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

function ProjectCard({ project, className = "" }) {
  const titleRef = useRef();

  const { id, identifier, codename, name, focusAreas, slug, banner } = project;
  const bannerImg = banner?.url || projectPhotoPlaceholder2x;
  const truncatedIdentifier =
    identifier?.length > 8 ? `${identifier.slice(0, 8)}...` : identifier;

  useLayoutEffect(() => {
    const title = titleRef.current;
    title && ellipsis(title, 4, { replaceStr: "..." });
  }, [titleRef]);

  return (
    <div
      className={clsx(
        "relative h-80 w-full bg-dark-100 rounded-lg overflow-hidden",
        className,
      )}
      key={id}
    >
      <div className="h-full transition-opacity duration-300">
        <div className="absolute inset-0 h-full">
          <img
            src={bannerImg}
            className={clsx(
              banner ? "object-cover w-full h-full" : "object-fit h-full",
              "min-h-full min-w-full",
            )}
          />
          <div
            className={clsx("absolute inset-0", banner && "linear-gradient")}
            aria-hidden="true"
          />
        </div>
        <div className="relative p-5 text-white flex flex-col h-full gap-5">
          <div className="">
            <span className="inline-flex items-center px-2.5 py-1 rounded-full uppercase font-label bg-dark-100 text-white">
              Featured
            </span>
          </div>
          <div className="flex-grow flex flex-col justify-between">
            <div className="flex-grow flex flex-col">
              <div className="font-label flex items-center gap-2.5 mt-auto mb-2">
                <span>{truncatedIdentifier}</span>
                {codename && (
                  <span className="bg-white w-1.5 h-1.5 rounded-full block" />
                )}
                <span>{codename}</span>
              </div>
              <Link to={`/projects/${slug}/?tab=overview`} className="link">
                <h3 className="font-h2 mb-4" ref={titleRef}>
                  {name}
                </h3>
              </Link>
            </div>
            <FocusAreaTags
              focusAreas={focusAreas.map(item => ({
                id: item.id,
                attributes: { name: item.name },
              }))}
              theme="dark"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function FeaturedProjects({ isLoading, projects }) {
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        projects.length > 0 && (
          <section>
            <h2 className="font-h3 lg:font-h2 text-byte-dark mb-5 lg:mb-6">
              Featured Projects
            </h2>
            <div className="flex-col lg:flex-row flex gap-5">
              {projects.length > 1 ? (
                projects.map(project => (
                  <ProjectCard
                    key={project.id}
                    {...{ project }}
                    className="lg:w-1/2"
                  />
                ))
              ) : (
                <ProjectCard key={projects[0].id} project={projects[0]} />
              )}
            </div>
          </section>
        )
      )}
    </>
  );
}

export default FeaturedProjects;
