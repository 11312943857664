import * as React from "react";
import { useState } from "react";
import * as R from "ramda";
import clsx from "clsx";
import paramBuilder from "@/utils/network/paramBuilder";
import { useParams, useNavigate } from "react-router-dom";
import { denormalizedFetcher, fetcher, saveResource } from "@/utils/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik, Form } from "formik-latest";
import { useAuthorsAndAuthorTypes } from "../ResearchDocumentForm/data";
import useFlashMessage from "@/hooks/useFlashMessage";
import FormBlock from "@/components/shared/FormBlock";
import Breadcrumb from "@/components/shared/Breadcrumb";
import Select from "@/components/shared/Select";
import Input from "@/components/shared/Input";
import AuthorPicker from "./AuthorPicker";
import ResearchDocumentSchema from "./ResearchDocumentSchema";
import ResearchResource from "./ResearchResource";
import RelatedProjectPicker from "./RelatedProjectPicker";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import FilePicker from "@/components/shared/FilePicker";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";

const fetchResearch = ({ queryKey }) => {
  const [_key, slug] = queryKey;

  const params = paramBuilder({
    include: [
      "poster",
      "focusAreas",
      "authors",
      "externalUsers",
      "relatedProjects",
    ],
    fields: {
      researchDocuments: [
        "title",
        "status",
        "researchType",
        "readableType",
        "focusAreas",
        "authors",
        "sortedAuthors",
        "externalUsers",
        "fileType",
        "relatedProjects",
        "publicationLink",
        "publication",
        "projects",
        "fileMetadata",
        "file",
      ],
      users: ["fullName", "slug"],
      focusAreas: ["name"],
      projects: ["name", "relatedFocusAreas", "identifier"],
    },
  });
  const url = `/api/research-documents/${slug}?${params}`;
  return denormalizedFetcher(url);
};

const mapAuthors = authors => {
  return authors.map(author => {
    return {
      authorType: author.author_type,
      id: author.id,
      userId: author.id,
      name: author.name,
    };
  });
};

const sanitizeRequestValues = (id, values) => {
  const focusAreas = values.focusAreas.map(area => ({
    type: "focus-areas",
    id: Number(area.id),
  }));

  const relatedProjects = values.relatedProjects.map(project => ({
    type: "projects",
    id: Number(project.id),
  }));

  return {
    id: id,
    type: "research-documents",
    attributes: {
      title: values.title,
      researchType: values.researchType,
      status: values.status,
      publication: values.publication,
      publicationLink: values.publicationLink,
      file: values.file,
      authors: mapAuthors(values.internalAuthors),
      externalUsers: mapAuthors(values.externalAuthors),
    },
    relationships: {
      focusAreas: { data: focusAreas },
      relatedProjects: { data: relatedProjects },
    },
  };
};

function ActionButtons({
  research,
  isValid,
  dirty,
  setModalOpen,
  isSubmitting,
}) {
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 py-5 bg-tint w-full flex items-center border-t">
      <section className="flex items-center justify-center w-full space-x-2.5">
        <button
          type="button"
          className="btn-white btn-sm"
          onClick={() => {
            isValid && dirty
              ? setModalOpen(true)
              : navigate(`/research_documents/${research.id}`);
          }}
        >
          Cancel
        </button>
        <button
          className={clsx(
            "btn-sm",
            isValid && dirty && !isSubmitting
              ? "btn-primary"
              : "bg-dark-25 text-white cursor-default",
          )}
          type="submit"
          data-cy="cy-save"
          disabled={!(isValid && dirty && !isSubmitting)}
        >
          Save Changes
        </button>
      </section>
    </div>
  );
}

function ResearchEditForm({ research }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);

  const { authors: users } = useAuthorsAndAuthorTypes();
  const { data: options } = useQuery(["researchFilters"], () =>
    fetcher("/api/research-documents/filters"),
  );

  const showFlash = useFlashMessage({
    message: "Your changes have been saved!",
    errorMessage: "Your changes could not be saved due to an error.",
  });

  const handleSubmit = (formikValues: any) => {
    const values = R.clone(formikValues);
    const sanitizedRequest = sanitizeRequestValues(research.id, values);
    const request = {
      data: sanitizedRequest,
    };

    saveResource("research-documents", request)
      .then((response: any) => {
        showFlash({ success: response.ok });
        if (response.ok) {
          queryClient.invalidateQueries(["research-document", research?.id]);
          navigate(`/research_documents/${research.id}`);
        }
      })
      .catch(() => {
        showFlash({ success: false });
        queryClient.invalidateQueries(["research-document", research?.id]);
      });
  };

  return (
    <div className="col-start-2 col-span-2 max-w-682px w-full">
      <header className="py-10 border-b border-dark-25 border-opacity-60">
        <h2 className="font-h1 text-dark-100">Edit Research</h2>
        <p className="font-body text-dark-75 pt-2.5">
          Make changes to the research you have authored or uploaded.
        </p>
      </header>
      {options && (
        <div className="py-7.5">
          <Formik
            initialValues={ResearchResource(
              research,
              users,
              options.focusAreas,
            )}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={ResearchDocumentSchema}
          >
            {({ values, setFieldValue, isValid, dirty, isSubmitting }) => (
              <Form>
                <section className="mb-20 space-y-7.5">
                  <FormBlock
                    label="Research Title"
                    name="title"
                    labelClassName="font-body text-dark-75"
                  >
                    <Input
                      placeholder="Title your research..."
                      data-cy="research-title"
                      value={values.title}
                    />
                  </FormBlock>
                  <FormBlock
                    label="Research Type"
                    name="researchType"
                    labelClassName="font-body text-dark-75"
                  >
                    <Select
                      selected={values.researchType}
                      setSelected={value =>
                        setFieldValue("researchType", value)
                      }
                      options={options.types}
                      data-cy="research-type"
                    />
                  </FormBlock>
                  <FormBlock
                    label="Current Status"
                    name="status"
                    labelClassName="font-body text-dark-75"
                  >
                    <Select
                      selected={values.status}
                      setSelected={value => setFieldValue("status", value)}
                      options={options.statuses}
                      data-cy="research-status"
                    />
                  </FormBlock>
                  {values.status === "Published or presented" && (
                    <>
                      <FormBlock
                        label="Publication Title"
                        name="publication"
                        labelClassName="font-body text-dark-75"
                        optional
                      >
                        <Input
                          placeholder="Add publication title..."
                          value={values.publication}
                        />
                      </FormBlock>
                      <FormBlock
                        label="Publication Link"
                        name="publicationLink"
                        labelClassName="font-body text-dark-75"
                        optional
                      >
                        <Input
                          placeholder="Add publication link..."
                          value={values.publicationLink}
                        />
                      </FormBlock>
                    </>
                  )}
                  <FormBlock
                    label="Add Authors"
                    name="authors"
                    optional
                    labelClassName="font-body-bold text-dark-100"
                  >
                    <AuthorPicker users={users} />
                  </FormBlock>
                  <FormBlock
                    label="Related Projects"
                    name="relatedProjects"
                    optional
                  >
                    <RelatedProjectPicker />
                  </FormBlock>
                  <FormBlock label="Focus Areas" name="focusAreas">
                    <CheckboxGroup
                      options={options.focusAreas}
                      selectedOptions={values.focusAreas}
                      setSelectedOptions={areas =>
                        setFieldValue("focusAreas", areas)
                      }
                      className="mt-5 py-1 flex flex-col h-auto flex-nowrap w-full sm:flex-wrap sm:h-150 border border-dark-25 rounded"
                    />
                  </FormBlock>
                  <FormBlock
                    label="Upload File"
                    name="file"
                    type="file"
                    labelClassName="font-body-bold"
                  >
                    <FilePicker attachment={values.fileMetadata} />
                  </FormBlock>
                </section>
                <ActionButtons
                  {...{
                    isValid,
                    dirty,
                    setModalOpen,
                    research,
                    isSubmitting,
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}

      <ConfirmationModal
        isOpen={modalOpen}
        onAccept={() => navigate(`/research_documents/${research.id}`)}
        onClose={() => setModalOpen(false)}
        setOpen={() => setModalOpen(false)}
      />
    </div>
  );
}

function ResearchDocumentEditForm() {
  const { id } = useParams();
  const { data }: { data: any } = useQuery(
    ["research-document", id],
    fetchResearch,
  );
  const research = data?.data;

  return (
    <div className="overflow-x-scroll member-center-outlet">
      <div className="margin-safe">
        <Breadcrumb to={`/research_documents/${id}`} text="Back to Research" />
      </div>
      {research && (
        <div className="flex items-center flex-col px-4 lg:px-0">
          <ResearchEditForm research={research} />
        </div>
      )}
    </div>
  );
}

export default ResearchDocumentEditForm;
