import Table from "@/components/shared/Table";
import * as React from "react";

const Avatar = ({ url, name, src, className }) => (
  <a href={url} className="flex items-center">
    <img alt={name} src={src} className={className} />
    <div className="hover:text-primary">{name}</div>
  </a>
);

function itemValue(item, column) {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item.attributes[column.name];
  }
}

function render(value, column, item) {
  if (column.render) {
    return column.render(value, item);
  } else {
    return value;
  }
}

function FocusAreaTable({ openForm, data }) {
  const columns = [
    {
      label: "Focus Area",
      name: "name",
      th: { width: "35%", className: "pt-1 pl-4" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden",
      },
      render: name => <p className="font-bold text-dark-75">{name}</p>,
    },
    {
      label: "Date Created",
      name: "formattedCreatedAt",
      th: { width: "15%", className: " pt-1 pl-4" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden text-dark-75",
      },
    },
    {
      label: "Last edited by",
      name: "lastUpdatedBy",
      th: { width: "20%", className: "pt-1 pl-4" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 whitespace-nowrap overflow-ellipsis overflow-hidden text-dark-75",
      },
      render: lastUpdatedBy =>
        lastUpdatedBy && (
          <Avatar {...lastUpdatedBy} className="h-6 w-6 rounded-full mr-5px" />
        ),
    },
    {
      label: "Objects tagged",
      name: "objectsTagged",
      th: { className: "pt-1 pl-4" },
      td: { className: "pt-[10px] pb-[10px] pl-4 text-dark-75" },
    },
    {
      label: "Actions",
      name: "actions",
      th: { className: "text-center pt-1 pl-4 w-[110px]" },
      td: {
        className:
          "pt-[10px] pb-[10px] pl-4 pr-4 whitespace-nowrap text-center",
      },
      render: (_, item) => (
        <button
          className="border border-lines text-dark-75 bg-tint font-bold hover:bg-white rounded-3px py-0.5 px-3.75"
          onClick={() => openForm(item, true)}
          data-testid={item.attributes.name}
        >
          Edit
        </button>
      ),
    },
  ];

  return (
    <Table className="border border-transparent text-sm">
      <Table.Header className="bg-transparent text-dark-50">
        {columns.map((column, i) => (
          <Table.HeaderCell
            {...column.th}
            key={i}
            className={column.th?.className}
          >
            {column.label}
          </Table.HeaderCell>
        ))}
      </Table.Header>
      <Table.Body className="border border-lines">
        {data.map((item, i) => (
          <Table.Row key={i} className="divide-x divide-lines">
            {columns.map((column, j) => (
              <Table.Cell
                key={j}
                className={column.td?.className}
                expand={j < columns.length - 1 ? true : false}
              >
                {render(itemValue(item, column), column, item)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default FocusAreaTable;
