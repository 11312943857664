import React from "react";
import clsx from "clsx";

function ButtonIcon({
  title = undefined,
  className = "",
  iconClassName,
  disabled = false,
  onClick,
}: {
  title?: string;
  className?: string;
  iconClassName: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  return (
    <button
      title={title}
      type="button"
      className={clsx("text-xl ml-1 font-normal", className, {
        "opacity-40 cursor-default": disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <i className={iconClassName} />
    </button>
  );
}

export default ButtonIcon;
