import * as React from "react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import Progress from "./Progress";
import stepsMachine from "./stepsMachine";
import { useMachine } from "@xstate/react";
import { useAuthorsAndAuthorTypes } from "./data";
import ResearchDocumentFormContext from "./ResearchDocumentFormContext";
import ResearchDocumentFormStep1 from "./ResearchDocumentFormStep1";
import ResearchDocumentFormStep2 from "./ResearchDocumentFormStep2";
import ResearchDocumentFormStep3 from "./ResearchDocumentFormStep3";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import FilePicker from "@/components/shared/FilePicker";
import ConfirmationModal from "@/components/shared/Modal/ConfirmationModal";
import CompletedForm from "./CompletedForm";
import Breadcrumb from "@/components/shared/Breadcrumb";
import FormBlock from "@/components/shared/FormBlock";

const fileFormSchema = Yup.object().shape({
  researchFile: Yup.string().required("Research file is required."),
});

function ResearchDocumentForm() {
  const navigate = useNavigate();
  const [currentStep, sendAction] = useMachine(stepsMachine);
  const steps = {
    step1: { value: 1, title: "Upload your research" },
    step2: { value: 2, title: "Who authored this research?" },
    step3: {
      value: 3,
      title: "What is your research related to?",
    },
    formCompleted: {
      value: 4,
      title: "You've succesfully added your new research!",
    },
  };
  const progressSteps = [1, 2, 3];
  const { authors, authorTypes } = useAuthorsAndAuthorTypes();
  const [researchValues, setResearchValues] = useState({
    researchType: "",
    researchFile: "",
    title: "",
    status: "",
    publication: "",
    publicationLink: "",
    authors: [],
    externalAuthors: [],
    focusAreas: [],
    projects: [],
  });
  const [newResearchURL, setNewResearchURL] = useState("");
  const [filePickerState, setFilePickerState] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const fileFormRef = useRef();

  const { data: filtersData } = useQuery(["researchFilters"], () =>
    fetcher("/api/research-documents/filters"),
  );

  const handleNext = () => sendAction("NEXT");
  const handlePrev = () => sendAction("PREV");

  const handleBackButtonPressed = () => {
    if (!currentStep.matches("formCompleted")) {
      setModalOpen(true);
    } else {
      navigate("/research_documents");
    }
  };

  return (
    <ResearchDocumentFormContext.Provider
      value={{
        handlePrev,
        handleNext,
        researchValues,
        setResearchValues,
        fileFormRef,
        filePickerState,
      }}
    >
      <div
        className="bg-white flex flex-col"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div className="margin-safe">
          <Breadcrumb
            onClick={handleBackButtonPressed}
            text="Back to Research"
          />
        </div>
        <div className="overflow-y-auto h-full">
          <div className="margin-safe pt-7.5 sm:pt-10 pb-20">
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex justify-center">
                <Progress
                  steps={progressSteps}
                  currentStep={steps[currentStep.value.toString()].value}
                />
              </div>
              <h1 className="text-dark-100 font-h2 sm:font-h1 text-center mt-5 sm:mt-7.5">
                {steps[currentStep.value.toString()].title}
              </h1>

              <div className="max-w-3xl mt-7.5 sm:mt-20 sm:px-12 w-full flex flex-col gap-7.5">
                {currentStep.value != "formCompleted" && (
                  <>
                    <Formik
                      innerRef={fileFormRef}
                      initialValues={{ researchFile: "" }}
                      onSubmit={values =>
                        setResearchValues(researchValues => ({
                          ...researchValues,
                          researchFile: values.researchFile,
                        }))
                      }
                      validationSchema={fileFormSchema}
                    >
                      {() => (
                        <Form>
                          <FormBlock name="researchFile" type="file">
                            <FilePicker
                              getStatus={status => {
                                setFilePickerState(status);
                              }}
                            />
                          </FormBlock>
                        </Form>
                      )}
                    </Formik>
                    {currentStep.matches("step1") && (
                      <ResearchDocumentFormStep1
                        researchTypes={filtersData?.types}
                        researchStatuses={filtersData?.statuses}
                        {...{
                          handleBackButtonPressed,
                        }}
                      />
                    )}
                    {currentStep.matches("step2") && (
                      <ResearchDocumentFormStep2
                        authorTypes={authorTypes.length > 0 ? authorTypes : []}
                        authors={authors.length > 0 ? authors : []}
                      />
                    )}
                    {currentStep.matches("step3") && (
                      <ResearchDocumentFormStep3
                        focusAreasOptions={filtersData?.focusAreas}
                        {...{
                          setNewResearchURL,
                        }}
                      />
                    )}
                  </>
                )}
                {currentStep.matches("formCompleted") && (
                  <CompletedForm {...{ newResearchURL }} />
                )}
              </div>
            </div>
          </div>
        </div>

        <ConfirmationModal
          isOpen={modalOpen}
          onAccept={() => navigate("/research_documents")}
          onClose={() => setModalOpen(false)}
          setOpen={() => setModalOpen(false)}
        />
      </div>
    </ResearchDocumentFormContext.Provider>
  );
}

export default ResearchDocumentForm;
