import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import { updateParams } from "@/hooks/useURLFilter";
import { fetcher, buildQueryParams } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import paramBuilder from "@/utils/network/paramBuilder";
import Filter from "@/components/shared/Filter";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import FilterTags from "@/components/shared/FilterTags";
import AdvancedFiltersModal from "./AdvancedFiltersModal";
import ExportModal from "./ExportModal";
import { FilterOption } from "@/types";

const statusOptions = [
  { name: "Pending", id: "pending" },
  { name: "Accepted", id: "accepted" },
  { name: "Declined", id: "declined" },
];

type AdvancedFiltersType = {
  technologyTypes: FilterOption[];
  antibodySubtypes: FilterOption[];
  cellTherapySubtypes: FilterOption[];
  diseaseIndications: FilterOption[];
  biomarkers: FilterOption[];
  biomarkerTechnologySubtypes: FilterOption[];
  therapeuticStrategy: FilterOption[];
  focusAreas: FilterOption[];
  developmentStages: FilterOption[];
  patentStages: FilterOption[];
  licensed: FilterOption | null;
  hotTech: FilterOption | null;
  inventionClasses: FilterOption[];
  applicationCategories: FilterOption[];
};

const sortOptions = [
  { name: "Disclosed, Newest first", id: "-disclosure" },
  { name: "Disclosed, Oldest first", id: "disclosure" },
  { name: "Review Date, Newest First", id: "-review" },
  { name: "Review Date, Oldest First", id: "review" },
  { name: "Title, A to Z", id: "title" },
  { name: "Title, Z to A", id: "-title" },
];

const cellTherapy = { id: "cellular_therapy", name: "Cellular therapy" };

const appendFilterName = (filterName, value) => {
  if (value) {
    return { ...value, name: `${filterName} - ${value.name}` };
  }
};

function Filters({
  setFilters,
  showCenterFilter = false,
  setSort = _el => {},
  recordCount = 0,
  filters,
}) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { pathname: path, search: queryParams } = useLocation();

  const [mainUrlParams, setMainUrlParams] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [selectedType, setSelectedType] = useState<FilterOption[]>([]);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedCenter, setSelectedCenter] = useState<FilterOption[]>([]);
  const [centerFocus, setCenterFocus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<FilterOption[]>([]);
  const [statusFocus, setStatusFocus] = useState(false);
  // More Filters
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFiltersType>({
    technologyTypes: [],
    antibodySubtypes: [],
    cellTherapySubtypes: [],
    diseaseIndications: [],
    biomarkers: [],
    biomarkerTechnologySubtypes: [],
    therapeuticStrategy: [],
    focusAreas: [],
    developmentStages: [],
    patentStages: [],
    licensed: null,
    hotTech: null,
    applicationCategories: [],
    inventionClasses: [],
  });

  const [typesFocus, setTypesFocus] = useState(false);

  const [moreFilterFocus, setMoreFilterFocus] = useState(false);
  const [showClear, setShowClear] = useState(false);

  const { data: filtersData } = useQuery(["inventionsFilters"], () =>
    fetcher("/api/inventions/filters"),
  );
  const filterOptions = useMemo(() => filtersData || {}, [filtersData]);

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        ipType: selectedType.map(x => x.id),
        center: selectedCenter.map(x => x.id),
        status: selectedStatus.map(t => t.id),
      },
      sort: [selectedSort?.id],
    });

    const moreFilters = paramBuilder({
      filter: {
        technologyType: advancedFilters.technologyTypes.map(x => x.id),
        antibodySubtype: advancedFilters.antibodySubtypes.map(x => x.id),
        cellTherapySubtype: advancedFilters.cellTherapySubtypes.map(x => x.id),
        diseaseIndication: advancedFilters.diseaseIndications.map(x => x.id),
        biomarkers: advancedFilters.biomarkers.map(x => x.id),
        biomarkerTechnologySubtype: advancedFilters.biomarkerTechnologySubtypes.map(
          x => x.id,
        ),
        therapeuticStrategy: advancedFilters.therapeuticStrategy.map(x => x.id),
        focusAreas: advancedFilters.focusAreas.map(x => x.id),
        developmentStage: advancedFilters.developmentStages.map(x => x.id),
        patentStage: advancedFilters.patentStages.map(x => x.id),
        licensed: advancedFilters.licensed?.id,
        caseStatusAtAffiliatedResearchInstitute:
          advancedFilters.caseAffiliated?.id,
        applicationCategory: advancedFilters.applicationCategories.map(
          c => c.id,
        ),
        inventionClass: advancedFilters.inventionClasses.map(c => c.id),
      },
    });
    const mainUrlParams = buildQueryParams({
      tab: "inventions",
      ip_type: selectedType.map(c => c.id),
      center: selectedCenter.map(c => c.id),
      status: selectedStatus.map(c => c.id),
    });

    setMainUrlParams(mainUrlParams);
    setShowClear(moreFilters !== "");
    setFilters(`${filters}${filters !== "" ? "&" : ""}${moreFilters}`);
  }, [
    setFilters,
    selectedType,
    selectedCenter,
    selectedStatus,
    selectedSort,
    advancedFilters,
  ]);

  const setTechnologyTypes = selectedOptions => {
    const techTypes = selectedOptions.some(t => t.id == cellTherapy.id)
      ? advancedFilters.cellTherapySubtypes
      : [];
    let params = updateParams(queryParams, selectedOptions, "technology_type");
    params = updateParams(params, techTypes, "cell_therapy_subtypes");
    navigate(`${path}${params}`);
  };

  const setCellTherapySubtypes = selectedOptions => {
    const techTypes =
      selectedOptions.length > 0
        ? [...new Set([...advancedFilters.technologyTypes, cellTherapy])]
        : advancedFilters.technologyTypes;
    let params = updateParams(queryParams, techTypes, "technology_type");
    params = updateParams(params, selectedOptions, "cell_therapy_subtypes");
    navigate(`${path}${params}`);
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const setAdvancedFilterKey = key => value =>
    setAdvancedFilters(filters => ({ ...filters, [key]: value }));

  const clearMoreFilters = () => {
    setShowClear(false);
    navigate(`${path}${mainUrlParams}`);
  };

  useEffect(() => {
    if (selectedSort?.id) {
      setSort(selectedSort.id);
    } else {
      setSelectedSort(sortOptions[0]);
    }
  }, [selectedSort, setSort]);

  return (
    <>
      <div className="flex">
        <div className="sm:flex w-full sm:w-auto">
          <div className="flex space-x-4 mb-4 sm:mb-0">
            <Filter
              name="ip_type"
              items={filterOptions.ipTypes}
              selectedItems={selectedType}
              setSelectedItems={setSelectedType}
              highlight={typesFocus}
              setFocus={setTypesFocus}
              className="flex-1"
            >
              <Filter.Label>IP Type</Filter.Label>
              <Filter.Content>
                <CheckboxGroup
                  options={filterOptions.ipTypes}
                  selectedOptions={selectedType}
                  setSelectedOptions={setSelectedType}
                  labelClassName="sm:w-325px"
                  className="filter-checkbox-group"
                />
              </Filter.Content>
            </Filter>
            <Filter
              name="center"
              items={filterOptions.centers}
              selectedItems={selectedCenter}
              setSelectedItems={setSelectedCenter}
              highlight={centerFocus}
              setFocus={setCenterFocus}
              className="flex-1 mr-2"
            >
              <Filter.Label>Center</Filter.Label>
              <Filter.Content>
                <CheckboxGroup
                  options={filterOptions.centers}
                  selectedOptions={selectedCenter}
                  setSelectedOptions={setSelectedCenter}
                  labelClassName="sm:w-325px"
                  className="filter-checkbox-group flex-nowrap overflow-auto"
                />
              </Filter.Content>
            </Filter>
            <Filter
              name="status"
              items={statusOptions}
              selectedItems={selectedStatus}
              setSelectedItems={setSelectedStatus}
              highlight={statusFocus}
              setFocus={setStatusFocus}
              className="flex-1 mr-2"
            >
              <Filter.Label>Status</Filter.Label>
              <Filter.Content>
                <CheckboxGroup
                  options={statusOptions}
                  selectedOptions={selectedStatus}
                  setSelectedOptions={setSelectedStatus}
                  labelClassName="sm:w-325px"
                  className="filter-checkbox-group"
                />
              </Filter.Content>
            </Filter>
          </div>

          <button
            className={clsx(
              "ml-0 sm:ml-4 w-full md:w-auto btn btn-lg bg-lines text-dark-100 font-body-bold md:font-h3",
              {
                "border border-dark-100": moreFilterFocus,
              },
            )}
            onClick={() => setOpenModal(true)}
            onFocus={() => setMoreFilterFocus(true)}
            onBlur={() => setMoreFilterFocus(false)}
            onMouseEnter={() => setMoreFilterFocus(true)}
            onMouseLeave={() => setMoreFilterFocus(false)}
          >
            <span className="hidden sm:inline-block lg:hidden">More...</span>
            <span className="inline-block sm:hidden lg:inline-block">
              Advanced Filters
              <span className="inline-block sm:hidden">...</span>
            </span>
          </button>
        </div>
        <div className="hidden lg:flex sm:ml-auto sm:items-center">
          <span className="hidden xl:inline-block text-dark-100 font-body-bold mx-4 text-right">
            Sort by:
          </span>
          <Filter
            name="sort"
            items={sortOptions}
            selectedItems={selectedSort}
            setSelectedItems={setSelectedSort}
            closeOnSelect
          >
            <Filter.Label>{selectedSort?.name}</Filter.Label>
            <Filter.Content>
              <SelectGroup
                options={sortOptions}
                selectedOption={selectedSort}
                setSelectedOption={setSelectedSort}
              />
            </Filter.Content>
          </Filter>
        </div>
        {currentUser.attributes.isStaff && (
          <div className="hidden xl:inline-block">
            <button
              type="button"
              disabled={recordCount === 0}
              onClick={() => setOpenExportModal(true)}
              className="ml-4 py-2 px-3 h-12 w-12 bg-white rounded-full border border-dark-25 focus:ring-primary focus:border-primary flex items-center text-center"
            >
              <i className="ri-download-line text-[22px]"></i>
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-wrap mt-4 mb-6">
        <FilterTags
          options={selectedType}
          setOptions={setSelectedType}
          isFocused={typesFocus}
          setFocus={setTypesFocus}
        />
        <FilterTags
          options={selectedCenter}
          setOptions={setSelectedCenter}
          isFocused={centerFocus}
          setFocus={setCenterFocus}
        />
        <FilterTags
          options={selectedStatus}
          setOptions={setSelectedStatus}
          isFocused={statusFocus}
          setFocus={setStatusFocus}
        />
        {/* More filters */}
        <FilterTags
          options={advancedFilters.applicationCategories}
          setOptions={setAdvancedFilterKey("applicationCategories")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.inventionClasses}
          setOptions={setAdvancedFilterKey("inventionClasses")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.technologyTypes}
          setOptions={setTechnologyTypes}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.antibodySubtypes}
          setOptions={setAdvancedFilterKey("antibodySubtypes")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.cellTherapySubtypes}
          setOptions={setCellTherapySubtypes}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.diseaseIndications}
          setOptions={setAdvancedFilterKey("diseaseIndications")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.biomarkers}
          setOptions={setAdvancedFilterKey("biomarkers")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.biomarkerTechnologySubtypes}
          setOptions={setAdvancedFilterKey("biomarkerTechnologySubtypes")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.therapeuticStrategy}
          setOptions={setAdvancedFilterKey("therapeuticStrategy")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.focusAreas}
          setOptions={setAdvancedFilterKey("focusAreas")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.developmentStages}
          setOptions={setAdvancedFilterKey("developmentStages")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={advancedFilters.patentStages}
          setOptions={setAdvancedFilterKey("patentStages")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={appendFilterName(
            "Licensed / Optioned",
            advancedFilters.licensed,
          )}
          setOptions={setAdvancedFilterKey("licensed")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        {/* <FilterTags
          options={appendFilterName("Hot tech", advancedFilters.hotTech)}
          setOptions={setAdvancedFilterKey("hotTech")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={appendFilterName(
            "Inventor interest in start-up?",
            advancedFilters.interestInStartup,
          )}
          setOptions={setAdvancedFilterKey("interestInStartup")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
        <FilterTags
          options={appendFilterName(
            "Inventor further developing in the lab?",
            advancedFilters.furtherDevelop,
          )}
          setOptions={setAdvancedFilterKey("furtherDevelop")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        /> */}
        <FilterTags
          options={appendFilterName(
            "Case status at affiliated research institute?",
            advancedFilters.caseAffiliated,
          )}
          setOptions={setAdvancedFilterKey("caseAffiliated")}
          isFocused={moreFilterFocus}
          setFocus={setMoreFilterFocus}
        />
      </div>
      <AdvancedFiltersModal
        open={openModal}
        setOpen={setOpenModal}
        showClearButton={showClear}
        onClear={clearMoreFilters}
        filters={advancedFilters}
        setFilters={setAdvancedFilters}
        showCenterFilter={showCenterFilter}
        {...{ setTechnologyTypes, setCellTherapySubtypes }}
      />
      <ExportModal
        open={openExportModal}
        filters={filters}
        setOpen={setOpenExportModal}
        recordCount={recordCount}
      />
    </>
  );
}

export default Filters;
