import * as React from "react";
import clsx from "clsx";

function Table({
  children,
  className = "",
  tableClassName = "",
  fixed = false,
  isOverflowY = false,
}) {
  return (
    <div
      className={clsx(
        "max-w-full overflow-x-auto",
        className || "border border-lines table-shadow sm:rounded-lg",
      )}
    >
      <div className="align-middle inline-block min-w-full">
        <div className={isOverflowY ? "overflow-y-auto" : "overflow-hidden"}>
          <table
            className={clsx("w-full", { "table-fixed": fixed }, tableClassName)}
          >
            {children}
          </table>
        </div>
      </div>
    </div>
  );
}

Table.Header = ({ children, className = "" }) => {
  return (
    <thead
      className={className || "bg-gray-50 text-dark-75 border-b border-lines"}
    >
      <tr>{children}</tr>
    </thead>
  );
};

Table.Body = ({ children, className = "" }) => {
  return (
    <tbody
      className={clsx("bg-white text-dark-75 divide-y divide-lines", className)}
    >
      {children}
    </tbody>
  );
};

Table.Row = ({ children, className = "", ...props }) => {
  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  );
};

Table.HeaderCell = ({ children, className = "", width = "", ...props }) => {
  return (
    <th
      width={width}
      scope="col"
      className={clsx(
        "px-6 py-3 text-left text-xs font-medium uppercase tracking-wider",
        className,
      )}
      {...props}
    >
      {children}
    </th>
  );
};

Table.Cell = ({ children, expand = true, className = "", ...props }) => {
  return (
    <td
      className={clsx(
        "px-6 py-4",
        expand ? "whitespace-nowrap" : "",
        className,
      )}
      {...props}
    >
      {children}
    </td>
  );
};

export default Table;
