import { format } from "date-fns";
const currentTimezone = "America/Los_Angeles";

const getCurrentTime = () => {
  return new Date();
};

const getCustomTime = (time, formatStr) => {
  if (typeof time === "string") {
    time += " ";
  }
  const dateObject = new Date(time);
  if (format == null) {
    return dateObject;
  }
  return format(dateObject, formatStr);
};

const getTimestamp = () => Math.round(new Date().getTime() / 1000);

const isInPeriodicUpdateMonth = (date, month) => {
  if (typeof date === "string") {
    date += " ";
  }
  if (typeof month === "string") {
    month += " ";
  }
  const dateMonth = new Date(month);
  const rangeBeginning = dateMonth.setDate(16);
  const rangeEnd = new Date(
    dateMonth.setMonth(dateMonth.getMonth() + 1),
  ).setDate(16);
  return (
    new Date(date) >= new Date(rangeBeginning) &&
    new Date(date) < new Date(rangeEnd)
  );
};

const eachMonthOfInterval = (dateStart, dateEnd) => {
  let interim = dateStart.clone();
  const timeValues = [];

  while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
    timeValues.push(interim.clone());
    interim.add(1, "month");
  }
  return timeValues;
};

const getPeriodicUpdateMonth = date => {
  const dateObj = new Date(date);
  if (isInPeriodicUpdateMonth(date, date)) {
    return dateObj;
  } else {
    return new Date(dateObj.setMonth(dateObj.getMonth()));
  }
};

const submittedDate = date => {
  if (date) return format(new Date(date), "MMMM dd, YYYY");
};

const periodicUpdateTitle = date => {
  if (date) return format(new Date(date), "MMMM YYYY");
};

const periodicUpdateSubtitle = date => {
  if (date) {
    return `Submitted on ${format(new Date(), "MMMM d")}`;
  } else {
    return "Past Due";
  }
};

const getCurrentTimeFormatted = () => {
  const timeWithTimeZone = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: currentTimezone,
    }),
  );
  return format(timeWithTimeZone, "MMMM do, yyyy");
};

const getHighlightDate = (current, periodDate) => {
  return current
    ? getCurrentTimeFormatted()
    : format(new Date(periodDate), "MMMM, YYYY");
};

// Time ago methods

const distanceOfTimeInWords = (_to, _from) => {
  const to = new Date(_to);
  const from = new Date(_from);
  const distance_in_seconds = (to - from) / 1000;
  let distance_in_minutes = Math.floor(distance_in_seconds / 60);
  const tense = distance_in_seconds < 0 ? " from now" : " ago";
  distance_in_minutes = Math.abs(distance_in_minutes);
  if (distance_in_minutes == 0) {
    return "less than a minute" + tense;
  }
  if (distance_in_minutes == 1) {
    return "a minute" + tense;
  }
  if (distance_in_minutes < 45) {
    return distance_in_minutes + " minutes" + tense;
  }
  if (distance_in_minutes < 120) {
    return "about an hour" + tense;
  }
  if (distance_in_minutes < 1440) {
    return "about " + Math.floor(distance_in_minutes / 60) + " hours" + tense;
  }
  if (distance_in_minutes < 2880) {
    return "a day" + tense;
  }
  if (distance_in_minutes < 43200) {
    return Math.floor(distance_in_minutes / 1440) + " days" + tense;
  }
  if (distance_in_minutes < 86400) {
    return "about a month" + tense;
  }
  if (distance_in_minutes < 525960) {
    return Math.floor(distance_in_minutes / 43200) + " months" + tense;
  }
  if (distance_in_minutes < 1051199) {
    return "about a year" + tense;
  }

  return "over " + Math.floor(distance_in_minutes / 525960) + " years";
};

const timeAgoInWords = from => {
  return distanceOfTimeInWords(new Date(), new Date(from));
};

const dateIsValid = date => {
  return !Number.isNaN(new Date(date).getTime());
};

export {
  timeAgoInWords,
  distanceOfTimeInWords,
  getCurrentTime,
  getCustomTime,
  currentTimezone,
  getTimestamp,
  isInPeriodicUpdateMonth,
  eachMonthOfInterval,
  getPeriodicUpdateMonth,
  submittedDate,
  periodicUpdateTitle,
  periodicUpdateSubtitle,
  getHighlightDate,
  getCurrentTimeFormatted,
  dateIsValid,
};
