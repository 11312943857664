import * as React from "react";
import { useState, useEffect } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import {
  apiCanFetch,
  useInfiniteQueryResult,
} from "@/hooks/useInfiniteQueryResult";
import PeopleFilter from "./PeopleFilter";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import PeopleTable from "./PeopleTable";
import UserForm from "./UserForm";
import EditFormat from "./UserEditForm";
import { useLocation, useSearchParams } from "react-router-dom";
import { FilterOption } from "@/types";
import Tabs from "../MediaResourcesPage/Tabs";
import AdminHeader from "@/components/shared/Admin/AdminHeader";

const fetchUsers = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const baseUrl = `/api/users`;
  const params = paramBuilder({
    include: [
      "focusAreas",
      "center",
      "projects",
      "institution",
      "projectMemberships",
    ],
    fields: {
      users: [
        "fullName",
        "firstName",
        "lastName",
        "title",
        "bio",
        "email",
        "role",
        "suspended",
        "center",
        "institution",
        "focusAreas",
        "createdAt",
        "publicAt",
        "lastLoggedInAgo",
        "ipDisclosure",
        "ipDisclosureMetadata",
        "ipDisclosureUrl",
        "participationAgreement",
        "participationAgreementMetadata",
        "participationAgreementUrl",
        "cvOrBiosketch",
        "cvOrBiosketchMetadata",
        "cvOrBiosketchUrl",
        "compliance",
        "type",
        "paperworkRequired",
        "hhmiInvestigator",
        "notes",
        "avatar",
        "avatarMetadata",
        "avatarUrl",
        "projects",
        "centerNameOrInstitution",
        "phoneNumber",
        "googleScholarUrl",
        "projectMemberships",
        "digestedJobTitle",
        "legalTeam",
        "archived",
        "partOfAnyProjectTeam",
        "partOfAnyResearch",
      ],
      focusAreas: ["name"],
      center: ["name"],
      projects: ["name", "createdAt"],
      institution: ["name"],
      projectMemberships: ["projectId", "timeAgo"],
    },
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `${baseUrl}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

const TABS: FilterOption[] = [
  {
    id: "1",
    name: "Archived",
    slug: "archived",
  },
];

function PeoplesPage() {
  const [selectedTab, setSelectedTab] = useState<FilterOption | undefined>(
    undefined,
  );
  const { hash } = useLocation();
  useEffect(() => {
    setSelectedTab(TABS.find(tab => tab.slug == hash.slice(1)));
  }, [hash]);

  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const userId = searchParams.get("userId");

  const [showNewForm, setShowNewForm] = useState(mode === "new");
  const [showEditForm, setShowEditForm] = useState(mode === "edit");

  const [filters, setFilters] = useState("sort=firstName");
  const [user, setUser] = useState(null);

  let params = {};
  searchParams.forEach((key, value) => {
    params[value] = key;
  });

  const openNewForm = isOpen => {
    isOpen ? (params["mode"] = "new") : delete params["mode"];
    setSearchParams(params);
    setShowNewForm(isOpen);
  };

  const openEditForm = (user, isOpen) => {
    if (user != null && isOpen) {
      params["mode"] = "edit";
      params["userId"] = user.id;
      setUser(user);
    } else {
      delete params["mode"];
      delete params["tab"];
      delete params["userId"];
    }
    setSearchParams(params);
    setShowEditForm(isOpen);
  };

  const handleCloseEditForm = isOpen => {
    if (!isOpen) {
      delete params["mode"];
      delete params["tab"];
      delete params["userId"];
      setSearchParams(params);
    }
    setShowEditForm(isOpen);
  };
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["admin_people", filters], fetchUsers, {
    getNextPageParam: apiCanFetch,
  });

  const { data: people, meta } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("people-section-navigation");

  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);

  const { isLoading: isLoadingSelectData, data: selectData } = useQuery(
    ["selectData"],
    () => fetcher("/api/users/select_data"),
  );

  return (
    <div className="bg-tint h-screen overflow-hidden">
      <AdminHeader
        title="People"
        className="!h-[106px]"
        linkText="+ New Account"
        linkTo={() => openNewForm(true)}
        exportText="Download CSV"
        exportLinkTo="/api/users/export.csv"
      >
        <Tabs
          tabs={TABS}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="pb-0 px-7.5 border-t border-lines"
        />
        <div className="w-full bg-transparent z-0">
          <PeopleFilter
            total={meta?.recordCount}
            isLoading={isFetching}
            queryFilter={filters}
            setFilters={setFilters}
            selectedTab={selectedTab}
          />
        </div>
      </AdminHeader>
      <div
        id="people-section-navigation"
        className="bg-tint px-6 pt-15 pb-3 overflow-x-hidden h-vh-100"
      >
        <PeopleTable
          openForm={openEditForm}
          {...{
            people,
            isFetching,
            isFetchingNextPage,
            filters,
          }}
        />
      </div>
      {!isLoadingSelectData && (
        <>
          {showNewForm && (
            <UserForm
              open={showNewForm}
              setOpen={openNewForm}
              selectData={selectData}
            />
          )}
          {showEditForm && (
            <EditFormat
              user={user}
              userId={userId}
              open={showEditForm}
              setOpen={handleCloseEditForm}
              selectData={selectData}
              params={params}
              setSearchParams={setSearchParams}
            />
          )}
        </>
      )}
    </div>
  );
}
export default PeoplesPage;
