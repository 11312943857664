import * as React from "react";

function ResourceListSkeleton() {
  return (
    <div className="animate-pulse">
      <article className="mt-10">
        <div className="mb-5">
          <div className="bg-dark-25 h-4 w-28 lg:w-40 rounded-full" />
        </div>
        <div className="border divide-y rounded-lg overflow-x-auto">
          {[1, 2, 3, 4].map(index => (
            <article
              key={index}
              className="flex px-6 py-5 border-lines w-full overflow items-center"
            >
              <div className="flex items-center flex-grow">
                <div className="flex-shrink-0 w-10 h-10 bg-dark-25 rounded-full mr-4"></div>
                <div className="flex-grow col-span-8 flex flex-col pr-4 items-start min-w-500px">
                  <div className="bg-dark-25 rounded-full h-4 w-1/4 mb-2"/>
                  <div className="bg-dark-25 h-4 rounded-full w-2/4" />
                </div>
                <div className="w-32 flex-shrink-0">
                  <div className="bg-dark-25 h-4 rounded-full w-1/2" />
                </div>
                <div className="w-32 flex-shrink-0 justify-self-center">
                  <div className="bg-dark-25 h-4 rounded-full w-20" />
                </div>
                <div className="flex-shrink-0 w-8 h-8 bg-dark-25 rounded-full mr-4" />
              </div>
            </article>
          ))}
        </div>
      </article>
    </div>
  );
}

export default ResourceListSkeleton;
