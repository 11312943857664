import * as React from "react";
import { useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { ellipsis } from "ellipsed";
import { saveResource } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import RichText from "@/components/shared/RichText";

function Announcement({ announcement }) {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  const contentRef = useRef();
  const { subject, content, createdAt, imageUrl } = announcement.attributes;
  const { show: showAnnouncement } = announcement.links;
  const {
    fullName,
    avatarUrl,
  } = announcement.relationships.user.data.attributes;
  const { show: showUser } = announcement.relationships.user.data.links;
  const formattedCreatedAt = format(
    parseISO(createdAt.slice(0, 10)),
    "MMM d, yyyy",
  );

  const replaceString = `... <a href=${showAnnouncement} class="text-dark-100 font-body-bold hover:underline">Read more</a>`;

  const handleDismiss = () => {
    const request = {
      data: {
        type: "dismissed-announcements",
        attributes: {
          announcementId: announcement.id,
          userId: currentUser.id,
        },
      },
    };
    saveResource("dismissed-announcements", request).then(() => {
      queryClient.invalidateQueries(["announcement"]);
    });
  };

  useEffect(() => {
    const contentParagraph = contentRef.current;
    contentParagraph &&
      ellipsis(contentParagraph, 5, {
        replaceStr: replaceString,
      });
  }, [replaceString]);

  return (
    <article className="divide-y divide-lines border border-lines rounded-lg">
      <div className="p-6 flex items-center">
        <Link to={showUser} className="flex min-w-max">
          <img
            src={avatarUrl}
            alt=""
            className="h-10 w-10 rounded-full flex-shrink-0"
          />
        </Link>
        <div className="pl-4 flex-grow">
          <p className="font-h3 text-primary-dark">
            <Link
              to={showAnnouncement}
              className="flex break-word link-primary"
            >
              {subject}
            </Link>
          </p>
          <span className="font-meta text-dark-75">
            Posted by{" "}
            <Link to={showUser} className="link-primary">
              {fullName}
            </Link>{" "}
            on {formattedCreatedAt}
          </span>
        </div>
        <button
          type="button"
          onClick={handleDismiss}
          className="text-dark-75 focus:outline-none self-baseline"
        >
          <i className="ri-close-line ri-lg text-dark-50" />
        </button>
      </div>
      <div className="p-6 font-body text-dark-75">
        <RichText value={content} textRef={contentRef} />
        {imageUrl !== null && (
          <div className="mt-5 text-primary-dark font-bold">
            <a href={imageUrl} target="_blank" className="link-primary">
              <i className="ri-download-line mr-2.5"></i>
              Download Attachment
            </a>
          </div>
        )}
      </div>
    </article>
  );
}

export default Announcement;
