import * as React from "react";
import { Routes, Route } from "react-router-dom";
import useCurrentUser from "@/hooks/useCurrentUser";

import AdminRouter from "@/components/App/AdminRouter";
import MemberCenterNavigation from "@/components/App/navigation/MemberCenterNavigation";
import PatternLibrary from "@/components/PatternLibrary";

import MembersPage from "@/components/pages/MembersPage";
import MemberDetail from "@/components/pages/MembersPage/MemberDetail";
import CenterDetail from "@/components/pages/CentersPage/CenterDetail";
import ResourcesPage from "@/components/pages/ResourcesPage/ResourcesPage";
import ResourceDetail from "@/components/pages/ResourcesPage/ResourceDetail";
import ResearchDocumentsPage from "@/components/pages/ResearchDocumentsPage";
import ResearchDocumentDetail from "@/components/pages/ResearchDocumentsPage/ResearchDocumentDetail";
import ResearchDocumentForm from "@/components/pages/ResearchDocumentsPage/ResearchDocumentForm";
import ResearchEditForm from "@/components/pages/ResearchDocumentsPage/ResearchDocumentEditForm";
import InventionsPage from "@/components/pages/InventionsPage";
import InventionDetail from "@/components/pages/InventionsPage/InventionDetail";
import ProjectsPage from "@/components/pages/ProjectsPage/ProjectsPage";
import ProjectDetail from "@/components/pages/ProjectsPage/ProjectDetail";
import UpdateDetail from "@/components/pages/ProjectsPage/ProjectDetail/UpdateDetail";
import AnnouncementPage from "@/components/pages/AnnouncementPage";
import GlobalSearchPage from "@/components/pages/GlobalSearchPage";

import DashboardPage from "@/components/pages/DashboardPage";
import DailyNewsPage from "@/components/pages/DailyNewsPage";

import AccountSettingsPage from "@/components/pages/AccountSettingsPage";
import SettingsBasicInfoPage from "@/components/pages/AccountSettingsPage/BasicInfoTab";
import SettingsProfessionalInfoPage from "@/components/pages/AccountSettingsPage/ProfessionalInfoTab";
import SettingsProjectsPage from "@/components/pages/AccountSettingsPage/ProjectsTab";
import SettingsResearchPage from "@/components/pages/AccountSettingsPage/ResearchTab";
import SettingsEmailNotificationsPage from "@/components/pages/AccountSettingsPage/EmailNotificationsTab";
import SettingsMemberCenterHelpPage from "@/components/pages/AccountSettingsPage/MemberCenterHelpTab";
import SettingsVersionPage from "@/components/pages/AccountSettingsPage/VersionTab";
import SettingsPublicationHistoryPage from "@/components/pages/AccountSettingsPage/PublicationHistoryTab";
import PublicationFormPage from "@/components/pages/AccountSettingsPage/PublicationHistoryTab/PublicationHistoryForm";

import InsightsPage from "@/components/pages/InsightsPage";
import InsightMembersPage from "@/components/pages/InsightsPage/InsightPage/Members";
import ResearchInsight from "@/components/pages/InsightsPage/InsightPage/ResearchInsight/ResearchInsight";
import ProjectsInsight from "@/components/pages/InsightsPage/InsightPage/ProjectsInsight";
import InsightInventionssPage from "@/components/pages/InsightsPage/InsightPage/Inventions";
import CentersInsight from "@/components/pages/InsightsPage/InsightPage/Centers";

import RetreatsPage from "@/components/pages/RetreatsPage";
import ArticlePage from "@/components/pages/RetreatsPage/ArticlePage";

import OnboardingStart from "@/components/pages/UserOnboarding/Start";
import OnboardingPicture from "@/components/pages/UserOnboarding/Picture";
import OnboardingProfessional from "@/components/pages/UserOnboarding/Professional";
import ConfidentialityPage from "../pages/ConfidentialityPage";

const isProduction = process.env.NODE_ENV === "production";

function AuthenticatedRouter() {
  const currentUser = useCurrentUser();
  const showInventions =
    currentUser?.attributes.isStaff || currentUser?.attributes.isBoardMember;

  return (
    <Routes>
      {currentUser && (
        <>
          <Route path="/" element={<MemberCenterNavigation />}>
            <Route index element={<DashboardPage />} />
            <Route path="confidentiality" element={<ConfidentialityPage />} />
            <Route path="daily-news">
              <Route path=":id" element={<DailyNewsPage />} />
              <Route path="preview/:hash" element={<DailyNewsPage />} />
            </Route>
            <Route path="directory" element={<MembersPage />} />
            <Route path="resources">
              <Route index element={<ResourcesPage />} />
              <Route path=":slug" element={<ResourceDetail />} />
            </Route>
            <Route path="centers">
              <Route path=":slug" element={<CenterDetail />} />
            </Route>
            <Route path="profile/:slug" element={<MemberDetail />} />
            <Route path="research_documents">
              <Route index element={<ResearchDocumentsPage />} />
              <Route path=":id">
                <Route index element={<ResearchDocumentDetail />} />
                <Route path="edit" element={<ResearchEditForm />} />
              </Route>
            </Route>
            <Route path="projects">
              <Route index element={<ProjectsPage />} />
              <Route path=":slug">
                <Route index element={<ProjectDetail />} />
                <Route path="updates/:id" element={<UpdateDetail />} />
              </Route>
            </Route>
            <Route path="announcements">
              <Route path=":id" element={<AnnouncementPage />} />
            </Route>
            <Route path="search" element={<GlobalSearchPage />} />
            {showInventions && (
              <Route path="inventions">
                <Route index element={<InventionsPage />} />
                <Route path=":id" element={<InventionDetail />} />
              </Route>
            )}
            <Route path="insights">
              <Route index element={<InsightsPage />} />
              <Route path="members" element={<InsightMembersPage />} />
              <Route path="research" element={<ResearchInsight />} />
              <Route path="projects" element={<ProjectsInsight />} />
              <Route path="inventions" element={<InsightInventionssPage />} />
              <Route path="centers" element={<CentersInsight />} />
            </Route>
            <Route path="settings" element={<AccountSettingsPage />}>
              <Route path="basic-info" element={<SettingsBasicInfoPage />} />
              <Route
                path="professional-info"
                element={<SettingsProfessionalInfoPage />}
              />
              <Route path="projects" element={<SettingsProjectsPage />} />
              <Route
                path="help-request"
                element={<SettingsMemberCenterHelpPage />}
              />
              <Route path="research" element={<SettingsResearchPage />} />
              <Route path="version" element={<SettingsVersionPage />} />
              <Route
                path="email-notifications"
                element={<SettingsEmailNotificationsPage />}
              />
              <Route path="publication-history">
                <Route index element={<SettingsPublicationHistoryPage />} />
              </Route>
            </Route>
            <Route path="settings/publication-history">
              <Route path="new" element={<PublicationFormPage />} />
              <Route path=":id">
                <Route path="edit" element={<PublicationFormPage />} />
              </Route>
            </Route>
            <Route path="retreats">
              <Route path=":id" element={<RetreatsPage />} />
              <Route path="article/:id" element={<ArticlePage />} />
            </Route>
          </Route>
          <Route
            path="/research_documents/new"
            element={<ResearchDocumentForm />}
          />
          <Route path="user_onboarding">
            <Route path="start" element={<OnboardingStart />} />
            <Route path="picture" element={<OnboardingPicture />} />
            <Route path="professional" element={<OnboardingProfessional />} />
          </Route>
          <Route path="*" element={<AdminRouter />} />
          {!isProduction && (
            <Route path="library/*" element={<PatternLibrary />} />
          )}
        </>
      )}
    </Routes>
  );
}

export default AuthenticatedRouter;
