import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Modal from "@/components/shared/Modal";
import { pluralize } from "@/utils/string";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { CSV_HEADERS, formatData } from "./CSVConfiguration";
import { isEmpty } from "lodash";
import useFlashMessage from "@/hooks/useFlashMessage";
import { format } from "date-fns";

const fetchInventions = ({ recordCount, filters }) => {
  const params = paramBuilder({
    include: ["users"],
    fields: {
      inventions: [
        "identifier",
        "title",
        "licensees",
        "review",
        "ipType",
        "inventorsList",
        "technologyType",
        "cellTherapySubtype",
        "inventionClass",
        "diseaseIndication",
        "applicationCategory",
        "centersInstitutions",
        "formattedIp",
        "developmentStage",
        "patentLifecycle",
        "publishedPatentApplication",
        "disclosure",
        "abstractLink",
      ],
      users: ["fullName"],
    },
  });

  const pagination = `page[size]=${recordCount}`;
  const url = `/api/inventions?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

function ExportModal({ open, setOpen, recordCount, filters }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const csvInstance = useRef<any | null>(null);
  const currentDate = format(new Date(), "MM-dd-yyyy");

  const showFlash = useFlashMessage({
    message: "Your export was exported succesfully",
    errorMessage: `Your export could not be done due to an error.`,
  });

  const downloadReport = async () => {
    setIsLoading(true);
    let response: any;
    try {
      response = await fetchInventions({ recordCount, filters });
    } catch (_) {
      showFlash({ success: false });
    }
    const dataForCSV = formatData(response.data);
    setData(dataForCSV);
  };

  useEffect(() => {
    if (
      !isEmpty(data) &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link &&
      isLoading
    ) {
      csvInstance.current.link.click();
      setIsLoading(false);
      setData([]);
      setOpen(false);
      showFlash({ success: true });
    }
  }, [data, isLoading, setOpen, showFlash]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        Export ({recordCount}) {pluralize(recordCount, "Invention")}
      </Modal.Header>
      <Modal.Body className="pr-3 mb-4  text-dark-75">
        Do you want to export all {recordCount}{" "}
        {pluralize(recordCount, "invention")}, viewable on this page?
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={() => downloadReport()}
          disabled={isLoading}
          className="btn-sm btn-primary"
        >
          {isLoading ? "Loading..." : "Export"}
        </button>
        <button
          type="button"
          className="btn-sm btn-white ml-2.5"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <CSVLink
          data={data}
          headers={CSV_HEADERS}
          filename={`Inventions-Export-${currentDate}.csv`}
          ref={csvInstance}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ExportModal;
