import { Events, SortMovement, PositionElement } from "./interfaces";
import csrfFetch from "@/utils/csrf-fetch";

const MOVE_UP_CLASSNAMES = ["-translate-y-full", "ease-in", "duration-250"];
const MOVE_DOWN_CLASSNAMES = ["translate-y-full", "ease-in", "duration-250"];

const animateElementMovement = (
  selectedRow: Element | null,
  selectedCloseRow: Element | null,
  movement: SortMovement,
) => {
  return new Promise((resolve, _) => {
    if (movement == "move_higher") {
      selectedRow?.classList.add(...MOVE_UP_CLASSNAMES);
      selectedCloseRow?.classList.add(...MOVE_DOWN_CLASSNAMES);
    } else {
      selectedRow?.classList.add(...MOVE_DOWN_CLASSNAMES);
      selectedCloseRow?.classList.add(...MOVE_UP_CLASSNAMES);
    }

    resolve({ selectedRow, selectedCloseRow });
  });
};

const sendPositions = async (positions: PositionElement) => {
  const request = {
    method: "POST",
    body: JSON.stringify({ positions }),
  };
  return csrfFetch("/api/events/update_associations", request);
};

const generateSortData = async (element: Events, movement: SortMovement) => {
  const { id, attributes } = element;
  const elementAId = parseInt(id);
  const positionA = parseInt(attributes.position);
  const positionB = movement == "move_higher" ? positionA - 1 : positionA + 1;
  const selectedRow = document.querySelector(
    `[data-sorted-item-id="${elementAId}"]`,
  );
  const selectedCloseRow = document.querySelector(
    `[data-sorted-item-position="${positionB}"]`,
  );
  const elementBId = selectedCloseRow?.getAttribute("data-sorted-item-id") || 0;

  const positions = {
    [elementAId]: { position: `${positionB}` },
    [elementBId]: { position: `${positionA}` },
  };
  return { positions, selectedRow, selectedCloseRow };
};

export { generateSortData, animateElementMovement, sendPositions };
