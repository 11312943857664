import React from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

type STATUS_TYPE = "accepted" | "declined" | "licensed" | "pending";

const STATUS_TYPE_LABEL = {
  accepted: (
    <>
      Accepted
      <br />
      Inventions
    </>
  ),
  declined: (
    <>
      Declined
      <br />
      Inventions
    </>
  ),
  licensed: (
    <>
      Licensed
      <br />
      Inventions
    </>
  ),
  pending: (
    <>
      Pending
      <br />
      Disclosures
    </>
  ),
};

const SkeletonScoreItem = () => (
  <div className="bg-white border border-lines flex rounded shadow font-bold py-4 px-6 animate-pulse">
    <h3 className="text-4xl mr-5 w-[40px] h-[40px]">
      <div className="h-full w-full bg-slate-200 bg-dark-25 rounded"></div>
    </h3>
    <h2 className="text-l text-l leading-normal font-normal">
      <div className="h-3 w-[110px] bg-slate-200 bg-dark-25 rounded mb-2"></div>
      <div className="h-3 w-[80px] bg-slate-200 bg-dark-25 rounded"></div>
    </h2>
  </div>
);

export type ScoreItemProps = {
  isLinkeable?: boolean;
  isFetching?: boolean;
  status: STATUS_TYPE;
  total: number;
  ipSummary: any;
};

function ScoreItem({
  isLinkeable = true,
  isFetching = false,
  status,
  total,
  ipSummary,
}: ScoreItemProps) {
  if (isFetching) {
    return <SkeletonScoreItem />;
  }
  const content = (
    <>
      <span className="text-3xl font-normal border-lines">{total}</span>
      <span className="text-15 leading-5 ml-2.5">
        {STATUS_TYPE_LABEL[status]}
      </span>
    </>
  );

  const tooltipContent = `${ipSummary.subject_ip} Subject IP<br />${ipSummary.peripheral_ip} Peripheral IP<br />${ipSummary.both} Subject & Peripheral`;
  return (
    <>
      <div
        className={clsx(
          "flex border-lines pl-6 pr-3 py-3.5 shadow-md rounded-sm",
          "bg-white text-dark-75",
          { "cursor-pointer": isLinkeable, "hover:text-primary": isLinkeable },
        )}
        data-tip={tooltipContent}
        data-for={status}
      >
        {isLinkeable ? (
          <Link to={`/admin/inventions#${status}`} className="flex">
            {content}
          </Link>
        ) : (
          content
        )}
      </div>
      <ReactTooltip id={status} multiline={true} className="text-left" />
    </>
  );
}

export default ScoreItem;
