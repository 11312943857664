import React from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ClinicalTrialsSkeletonTable from "./ClinicalTrialsSkeletonTable";
import Table from "@/components/shared/Table";
import Tooltip from "@/components/shared/Tooltip";
import { fetcher } from "@/utils/api";
import ToogleButton from "@/components/shared/ToogleButton";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import NoResults from "@/components/shared/NoResults";
import {
  LinkableNameColumn,
  ActionButtonsColumn,
  CentersColumn,
  itemValue,
  render,
} from "@/components/shared/Admin/Columns";

const PublicLabelHeader = () => {
  return (
    <div className="flex flex-nowrap justify-between items-center">
      <span className="mr-1">Public</span>
      <Tooltip>
        <Tooltip.Trigger>
          <i className="ri-question-mark bg-white rounded-full w-4 h-4 flex items-center justify-center"></i>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <span>
            If this is on, then this content will display on the PICI public
            site (www.parkerici.org). If it's off, then it will not display
            publicly.
          </span>
        </Tooltip.Content>
      </Tooltip>
    </div>
  );
};

const PublicAtColumn = ({ value, filters }) => {
  const isPublic = value.attributes.isPublic;
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["clinical-trials", filters]);
    },
    onMutate: async () => {
      const oldPagedData: any = queryClient.getQueryData([
        "clinical-trials",
        filters,
      ]);
      oldPagedData.pages.forEach(page => {
        const updatedClinicalTrial = page.data.find(
          clinicalTrial => clinicalTrial.id === value.id,
        );
        if (updatedClinicalTrial) {
          updatedClinicalTrial.attributes.isPublic = !isPublic;
          return true;
        }
      });
    },
  });

  const handlePublic = () => {
    mutateAsync(fetcher(`/api/clinical-trials/${value.id}/toggle`));
  };

  return (
    <ToogleButton
      dataCy={`clinical-public-toggle-${value.id}`}
      active={isPublic}
      onClick={handlePublic}
    />
  );
};

const FeaturedAtColumn = ({ value, filters }) => {
  const isFeatured = value.attributes.featured;
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["clinical-trials", filters]);
    },
    onMutate: async () => {
      const oldPagedData: any = queryClient.getQueryData([
        "clinical-trials",
        filters,
      ]);
      oldPagedData.pages.forEach(page => {
        const updatedClinicalTrial = page.data.find(
          clinicalTrial => clinicalTrial.id === value.id,
        );
        if (updatedClinicalTrial) {
          updatedClinicalTrial.attributes.featured = !isFeatured;
          return true;
        }
      });
    },
  });

  const handleFeatured = () => {
    mutateAsync(fetcher(`/api/projects/${value.id}/toggleFeatured`));
  };

  return (
    <ToogleButton
      dataCy={`clinical-featured-toggle-${value.id}`}
      active={isFeatured}
      onClick={handleFeatured}
    />
  );
};

function ClinicalTrialsTable({
  clinicalTrials,
  isFetching,
  isFetchingNextPage,
  filters,
}) {
  const columns = [
    {
      name: "id",
      label: "ID",
      td: { className: "pt-9px pb-9px !px-3 w-[30px] text-center" },
      accessor: item => item,
      render: value => (
        <span className="text-dark-75 font-meta-bold">{value.id}</span>
      ),
    },
    {
      name: "name",
      label: "Title",
      width: "24.5%",
      td: { className: "pt-9px pb-9px" },
      render: (value, item) => (
        <LinkableNameColumn
          text={value}
          to={item.links.slug}
          isBlank
          isArchived={item.attributes.archived}
        />
      ),
    },
    {
      name: "createdAt",
      label: "Date Added",
      td: { className: "pt-9px pb-9px !px-4" },
      render: value => (
        <span className="font-meta">
          {format(new Date(value), "MMM dd, yyyy")}
        </span>
      ),
    },
    {
      label: "PROJECT ID",
      name: "formattedIdentifier",
      td: { className: "min-w-[115px] !px-4" },
    },
    {
      label: "CODE NAME",
      name: "formattedCodename",
      td: { className: "!px-4" },
    },
    {
      label: "Centers",
      td: { className: "!px-4" },
      render: (_, item) => {
        const centers = item.attributes.centersAndInstitutions;
        return (
          <CentersColumn centerList={centers} testid="clinical-trial-centers" />
        );
      },
    },
    {
      label: "STATUS",
      name: "formattedTrialStatus",
      td: { "data-testid": "clinical-trial-status", className: "!px-4" },
    },
    {
      label: "PHASE",
      name: "formattedTrialPhase",
      td: { "data-testid": "clinical-trial-phase", className: "!px-4" },
    },
    {
      name: "public",
      label: <PublicLabelHeader />,
      td: { className: "w-100px pt-9px pb-9px !px-4" },
      accessor: item => item,
      render: value => <PublicAtColumn value={value} filters={filters} />,
    },
    {
      name: "featured",
      label: "Featured",
      td: { className: "w-100px pt-9px pb-9px !px-4" },
      accessor: item => item,
      render: value => <FeaturedAtColumn value={value} filters={filters} />,
    },
    {
      label: "Actions",
      td: { className: "w-[90px] pt-9px pb-9px !px-4" },
      accessor: item => item,
      render: value => {
        const items: ActionButtonItemType[] = [
          {
            text: "Edit",
            to: `${value.links.edit}`,
            name: value.attributes.name,
            variant: "btn-admin",
            isBlank: true,
          },
        ];
        return <ActionButtonsColumn items={items} />;
      },
    },
  ];

  return (
    <>
      {!isFetching && clinicalTrials.length === 0 ? (
        <NoResults entityName="clinical trials" icon="ri-search-line" />
      ) : (
        <Table className="border border-transparent mb-8">
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, i) => (
              <Table.HeaderCell
                key={i}
                className="text-11 pb-2"
                width={column.width}
              >
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body>
            {clinicalTrials.map((item, index) => (
              <Table.Row key={index} className="divide-x divide-lines">
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    data-testid={column.td?.[`data-testid`]}
                    className={clsx(column.td?.className, "text-14")}
                    expand
                  >
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => (
                <ClinicalTrialsSkeletonTable key={x} />
              ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default ClinicalTrialsTable;
