import { humanize } from "@/utils/string";
import { isEmpty } from "lodash";
import moment from "moment";
import { dateIsValid } from "@/utils/formik/dates";

// Changes here must also be done on invention_portfolio_decorator
const CSV_HEADERS = [
  {
    label: "Invention ID Number",
    key: "identifier",
  },
  { label: "Invention Title", key: "title" },
  { label: "Licensed", key: "invention_licensed" },
  { label: "Licensee", key: "licensees" },
  { label: "Technology Type", key: "technology_type" },
  { label: "Cell Therapy Subtype", key: "cell_therapy_subtype" },
  { label: "Invention Class", key: "invention_class" },
  { label: "Disease Indication", key: "disease_indication" },
  { label: "Application Category", key: "application_category" },
  { label: "PICI Affiliated Research Institute", key: "centers_institutions" },
  { label: "IP Type", key: "invention_ip_type" },
  { label: "Parker Institute Review Status", key: "review_status" },
  { label: "Parker Institute Review Date", key: "review_date" },
  { label: "Development Stage", key: "development_stage" },
  { label: "Inventors", key: "inventors" },
  { label: "Patent filed", key: "patent_filled" },
  { label: "Patent Aplication Status", key: "patent_aplication_status" },
  { label: "Date of Disclosure", key: "disclosure_date" },
  { label: "Priority Date", key: "priority_date" },
  { label: "Link to Published Patent Application", key: "link_published" },
  { label: "Marketing Link Abstract", key: "abstract_link" },
];

const PATENT_ORDER = [
  "provisional_filing",
  "pct_filing",
  "publication",
  "national_filing",
  "allowance",
  "issuance",
  "other",
];
const PATENT_STAGE = {
  provisional_filing: "Provisional Filing",
  pct_filing: "PCT Filing",
  publication: "Publication",
  national_filing: "National Filing",
  allowance: "Allowance",
  issuance: "Issuance",
  other: "Other",
  abandoned: "Abandoned",
  not_specified: "No Patent Filed",
};

function dateFormat(date) {
  return date && dateIsValid(date) ? moment.utc(date).format("L") : date;
}

function humanizeList(array) {
  return isEmpty(array) ? "N/A" : humanize(String(array));
}

function licensees(licensees) {
  return Array.isArray(licensees)
    ? licensees.map(license => license.company)
    : licensees;
}

function inventionLicensed(licensees) {
  let licensed = "Unlicensed";
  licensees.forEach((license: { type: any; grants: string | string[] }) => {
    if ("type" in license) {
      switch (license.type) {
        case "license":
          licensed = license.grants.includes("exclusive")
            ? "Fully Licensed"
            : "Partially Licensed";
          break;
        case "option":
          licensed = "Optioned";
      }
    }
  });
  return licensed;
}

function inventorList(inventors) {
  let names = "N/A";
  if (inventors.length > 0) {
    names = inventors.map(inventor => inventor.attributes.fullName);
  }
  return String(names);
}

function patentFilled(patentLifecycle) {
  let filled = "No";
  patentLifecycle.every(patent => {
    if (patent.phase === "provisional_filing") {
      filled = "Yes";
      return true;
    }
    return false;
  });
  return filled;
}

function link_published(publishedPatentApplication) {
  return publishedPatentApplication.length > 0
    ? publishedPatentApplication[0].url
    : "None";
}

function reviewDate(review) {
  let date = "";
  if ("date" in review && dateIsValid(review.date)) {
    date = dateFormat(review.date);
  }
  return date;
}

function reviewStatus(review) {
  let status = "TBD";
  if ("review_status" in review) {
    status = humanize(review.review_status);
  }
  return status;
}
function patentAplicationStatus(patentLifecycle) {
  const patentLifecycleWithDate = patentLifecycle.filter(phase =>
    phase.hasOwnProperty("date"),
  );
  const phase = patentLifecycleWithDate
    .sort((a, b) => {
      PATENT_ORDER.indexOf(a) - PATENT_ORDER.indexOf(b) || a.date - b.date;
    })
    .pop();
  if (phase !== undefined) {
    return PATENT_STAGE[phase.phase];
  } else {
    return "--";
  }
}

function disclosureDate(disclosure) {
  let date = "TBD";
  if ("date" in disclosure) {
    date = dateFormat(disclosure.date);
  }
  return date;
}

function priorityDate(patentLifecycle) {
  const provisionalFiling = patentLifecycle.filter(
    patent => patent.phase === "provisional_filing",
  );
  const phase = provisionalFiling.sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  )[0];
  let date = "--";
  if (phase !== undefined && "date" in phase) {
    date = dateFormat(phase.date);
  }
  return date;
}

function formatData(data) {
  return data.reduce((accum, current) => {
    const { attributes } = current;
    if (!isEmpty(attributes)) {
      accum.push({
        identifier: attributes.identifier,
        title: attributes.title,
        invention_licensed: inventionLicensed(attributes.licensees),
        licensees: humanizeList(licensees(attributes.licensees)),
        technology_type: humanizeList(attributes.technologyType),
        cell_therapy_subtype: humanizeList(attributes.cellTherapySubtype),
        invention_class: humanizeList(attributes.inventionClass),
        disease_indication: humanizeList(attributes.diseaseIndication),
        application_category: humanizeList(attributes.applicationCategory),
        centers_institutions: humanizeList(attributes.centersInstitutions),
        invention_ip_type: attributes.formattedIp,
        review_status: reviewStatus(attributes.review),
        review_date: reviewDate(attributes.review),
        development_stage: attributes.developmentStage,
        inventors: inventorList(attributes.inventorsList),
        patent_filled: patentFilled(attributes.patentLifecycle),
        patent_aplication_status: patentAplicationStatus(
          attributes.patentLifecycle,
        ),
        disclosure_date: disclosureDate(attributes.disclosure),
        priority_date: priorityDate(attributes.patentLifecycle),
        link_published: link_published(attributes.publishedPatentApplication),
        abstract_link: attributes.abstractLink || "None",
      });
    }
    return accum;
  }, []);
}

export { CSV_HEADERS, formatData };
