import * as React from "react";
import clsx from "clsx";
import Modal from "@/components/shared/Modal";

function ArchiveConfirmationModal({ open, setOpen, onAccept, user }) {
  const {
    fullName,
    firstName,
    archived,
    partOfAnyProjectTeam,
    partOfAnyResearch,
  } = user.attributes;
  let partOfTeamText = "";
  if (partOfAnyProjectTeam && partOfAnyResearch) {
    partOfTeamText = "Project and a Research";
  } else if (partOfAnyResearch) {
    partOfTeamText = "Reasearch";
  } else if (partOfAnyProjectTeam) {
    partOfTeamText = "Project";
  }
  const text = archived
    ? `Activating ${fullName}’s account will reactivate the display of the PICI Member on the Admin Center. ${firstName} will still remain Suspended.`
    : `Archiving ${fullName}’s account will hide the already Suspended PICI member on the Admin Center panels. ${firstName}'s profile and contributions will remain available on the network. You can Unarchive this account at any time, from the Archived Members panel.`;
  const dissallowingTextConditional =
    (partOfAnyProjectTeam || partOfAnyResearch) && !archived;

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        {`${archived ? "Unarchive" : "Archive"} ${fullName}’s account?`}
      </Modal.Header>
      <Modal.Body className="text-dark-75 font-body-large">
        {text}{" "}
        {dissallowingTextConditional && (
          <p className="font-body text-red-350">
            {fullName} is part of a {partOfTeamText} Team. Are you sure about
            this?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="w-full flex justify-between mt-5">
        <button
          type="button"
          className="btn-white btn-sm font-meta-bold"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button
          type="button"
          className={clsx(
            "btn-md text-white",
            archived
              ? "bg-primary hover:bg-primary-dark"
              : "bg-red-350 hover:bg-red-450 active:bg-red-650",
          )}
          onClick={onAccept}
        >
          Yes, {archived ? "Unarchive" : "Archive"} Account
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ArchiveConfirmationModal;
