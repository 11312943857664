import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { InView } from "react-intersection-observer";
import clsx from "clsx";
import * as R from "ramda";
import { useDebounce } from "use-debounce";
import {
  ZoomInIcon,
  ZoomOutIcon,
  DocumentTextIcon,
} from "@heroicons/react/solid";
import { ArrowsExpandIcon } from "@heroicons/react/outline";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function invalidFormat(fileType) {
  return (
    fileType === "" ||
    !["image/jpeg", "image/png", "image/gif", "application/pdf"].includes(
      fileType,
    )
  );
}

function validImage(fileType) {
  return ["image/jpeg", "image/png", "image/gif"].includes(fileType);
}

const PDFPreview = ({
  url,
  fullScreen,
  showPDFToolBar,
  loading,
  researchDocumentFullScreenPDF,
}) => {
  const [totalPageCount, setTotalPageCount] = useState(null);
  const [pages, setPages] = useState([1]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [debouncedCurrentPageNumber] = useDebounce(currentPageNumber, 100);
  const updatePage = entry => updateCurrentPageNumber(entry);
  const [firstLoad, setFirstLoad] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [threshold, setThreshold] = useState(1);
  const viewportWidth = window.innerWidth;

  function zoomIn() {
    setZoom(zoom + 0.25);
  }

  function zoomOut() {
    setZoom(zoom <= 0.25 ? 0.25 : zoom - 0.25);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPageCount(numPages);
    const pagesArray = R.range(1, numPages + 1);
    setPages(pagesArray);
  }

  function updateCurrentPageNumber(entry) {
    if (!firstLoad) {
      const number = entry?.target.firstElementChild.getAttribute(
        "data-page-number",
      );
      setCurrentPageNumber(number);
    }
  }
  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        className={clsx(
          "h-auto overflow-y-auto max-h-96 sm:max-h-full",
          pages.length === 1 ? "sm:max-h-full" : "sm:max-h-600px",
          fullScreen && "w-full max-h-96",
        )}
        loading={<Loading />}
        error={<Error />}
      >
        {pages.map(page => (
          <InView
            key={`preview${page}`}
            threshold={threshold}
            onChange={(inView, entry) => {
              if (inView) {
                updatePage(entry);
              }
            }}
          >
            {({ ref }) => (
              <div ref={ref}>
                <Page
                  pageNumber={page}
                  renderAnnotationLayer={false}
                  scale={zoom}
                  renderTextLayer={false}
                  width={fullScreen ? viewportWidth : null}
                  onLoadSuccess={() => {
                    if (page == pages.length) {
                      setThreshold(0.2);
                    }
                  }}
                ></Page>
              </div>
            )}
          </InView>
        ))}
      </Document>

      {totalPageCount && (
        <ToolBar
          {...{
            fullScreen,
            showPDFToolBar,
            loading,
            debouncedCurrentPageNumber,
            totalPageCount,
            zoomIn,
            zoomOut,
            researchDocumentFullScreenPDF,
          }}
        />
      )}
    </>
  );
};

const ToolBar = ({
  fullScreen,
  showPDFToolBar,
  loading,
  debouncedCurrentPageNumber,
  totalPageCount,
  zoomIn,
  zoomOut,
  researchDocumentFullScreenPDF,
}) => {
  return (
    <>
      <div
        className={clsx(
          "flex justify-center absolute bottom-0 left-2/4 transform -translate-x-2/4",
          fullScreen && "relative bottom-12",
        )}
      >
        <div
          className={clsx(
            "flex justify-center bg-gray-800 rounded h-12 text-white",
            showPDFToolBar && "transition duration-500 ease-in-out opacity-100",
            !showPDFToolBar &&
              !loading &&
              "transition duration-500 ease-in-out opacity-0",
          )}
        >
          <div className="flex justify-between w-30 items-center">
            <p className="text-xs tracking-wider uppercase m-auto px-6 text-white font-bold whitespace-nowrap">
              <span className="hidden sm:inline">Page </span>
              {debouncedCurrentPageNumber} of {totalPageCount}
            </p>
          </div>
          <div className="flex border-l border-gray-300 border-r">
            <div
              onClick={zoomIn}
              className="bg-gray-800 rounded py-1.5 px-2 sm:px-5 text-white flex items-center h-full"
            >
              <ZoomInIcon className="fill-current text-white w-5" />
            </div>
            <div
              onClick={zoomOut}
              className="bg-gray-800 rounded py-1.5 px-2 sm:px-5 text-white flex items-center h-full"
            >
              <ZoomOutIcon className="fill-current text-white w-5" />
            </div>
          </div>
          <div className="bg-gray-800 rounded py-1.5 px-5 text-white flex items-center  self-center justify-self-center h-full">
            {fullScreen ? (
              <ArrowsExpandIcon
                onClick={() => window.close()}
                className="fill-current text-white w-5"
              />
            ) : (
              <a href={researchDocumentFullScreenPDF} target="_blank">
                <ArrowsExpandIcon className="fill-current text-white w-5" />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Loading = () => {
  return (
    <div className="relative pt-1 w-full md:w-96 h-96 flex flex-col justify-center">
      <div className="flex mb-2 items-center justify-center">
        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-600 bg-purple-200">
          Loading
        </span>
      </div>
      <div className="animate-pulse overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
        <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500 w-full"></div>
      </div>
    </div>
  );
};

const refreshPage = () => {
  window.location.reload();
};

function Error() {
  return (
    <div className="flex flex-col items-center">
      <div className="rounded-full w-24 h-24 bg-gray-200 flex items-center justify-center">
        <DocumentTextIcon className="fill-current text-gray-500 w-9" />
      </div>
      <span>Something went wrong.</span>
      <button
        onClick={refreshPage}
        className="font-body-bold text-gray-900 tracking-wider"
      >
        Try reloading this preview
      </button>
    </div>
  );
}

function Preview({ fileType, fullScreen = false, viewLink, downloadLink }) {
  const [showPDFToolBar, setShowPDFToolBar] = useState(false);
  const researchDocumentFullScreenPDF = downloadLink;
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={clsx(
        "font-lato max-w-full relative w-full",
        !invalidFormat(fileType) && "md:w-auto",
      )}
      onMouseEnter={() => {
        setShowPDFToolBar(true);
        setLoading(false);
      }}
      onMouseLeave={() => setShowPDFToolBar(false)}
    >
      {fileType == "application/pdf" ? (
        <PDFPreview
          {...{
            url: viewLink,
            fullScreen,
            showPDFToolBar,
            loading,
            researchDocumentFullScreenPDF,
          }}
        />
      ) : (
        validImage(fileType) && (
          <img src={viewLink} className="h-auto text-center max-w-full"></img>
        )
      )}
      {invalidFormat(fileType) && (
        <div>
          <div className="bg-tint text-dark-75 rounded-lg shadow-sm font-body py-24 px-6 text-center">
            <i className="ri-article-line ri-3x leading-none text-dark-25" />
            <p className="mt-6">No preview available</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Preview;
