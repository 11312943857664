import React from "react";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";
import Select from "@/components/shared/Select";
import TextArea from "@/components/shared/TextArea";
import VanityText from "@/components/shared/VanityText";
import SectionGroup from "@/components/shared/Admin/SectionGroup";

const BasicDetails = ({ values, setFieldValue, timeZones }) => {
  const timeZoneOptions = timeZones.map(item => ({
    id: item.name,
    name: item.label,
  }));
  return (
    <SectionGroup
      title="Basic Details"
      description="General context around the center."
    >
      <FormBlock label="Center Name (Full)" name="name" className="mb-6">
        <Input />
      </FormBlock>
      <FormBlock
        label="Center Name (Short)"
        name="shorthand"
        className="mb-6 w-1/2 pr-6"
        hint="Note: 15 character limit"
      >
        <Input />
      </FormBlock>
      <FormBlock
        label="Description"
        name="description"
        className="mb-6"
        hint="Note: 175 character limit"
      >
        <TextArea className="h-20 max-h-20" />
      </FormBlock>
      <div className="flex justify-between mb-6">
        <FormBlock
          label="Location"
          name="location"
          className="w-1/2 mr-3"
          optional
        >
          <Input placeholder="e.g. Philadelphia, PA" />
        </FormBlock>
        <FormBlock label="Time Zone" name="timezone" className="w-1/2 ml-3">
          <Select
            selected={values.time_zone || timeZoneOptions[0].id}
            setSelected={value => setFieldValue("time_zone", value)}
            options={timeZoneOptions}
          />
        </FormBlock>
      </div>
      <VanityText
        label="Project Vanity URL"
        text="https://members.parkerici.org/centers/"
        placeholder="e.g. Center-One"
        name="slug"
      />
      <FormBlock
        label="Phone Number"
        name="telephone"
        className="w-1/2 pr-6 mt-6"
        optional
      >
        <Input placeholder="(123) 555-5555" />
      </FormBlock>
    </SectionGroup>
  );
};

export default BasicDetails;
