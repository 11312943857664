import React, { useEffect } from "react";
import AdminHeader from "@/components/shared/Admin/AdminHeader";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import ArticlesTable from "./ArticlesTable";

const fetchArticles = ({ pageParam = 0, queryKey }) => {
  const [_, filters] = queryKey;
  const baseUrl = "/api/articles";
  const params = paramBuilder({
    fields: {
      articles: [
        "title",
        "presentationDate",
        "published",
        "retreatId",
        "thumbnailImageUrl",
        "quarterName",
        "isRetreatPublished",
      ],
    },
  });
  const pagination = `page[number]=${pageParam + 1}&page[size]=20`;
  const url = `${baseUrl}?${pagination}&${params}&${filters}`;
  return denormalizedFetcher(url);
};

function ArticlesPage() {
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(["admin_articles"], fetchArticles, {
    getNextPageParam: apiCanFetch,
  });
  const { data: articles } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("article-section-navigation");
  useEffect(() => {
    if (isFetchingNextPage) return;
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [isAtBottom, isFetchingNextPage, fetchNextPage, hasNextPage]);
  return (
    <div className="bg-tint h-screen overflow-hidden">
      <AdminHeader
        title="Articles"
        linkText="+ New Article"
        linkTo="/admin/articles/new"
        description="Input the information for each Retreat Presentation or Article"
      />
      <article
        id="article-section-navigation"
        className="px-6 overflow-x-hidden h-vh-60 bg-primary-lighter"
      >
        <ArticlesTable
          articles={articles}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
        />
      </article>
    </div>
  );
}

export default ArticlesPage;
