import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { splitQueryParams, buildQueryParams } from "@/utils/api";

const updateParams = (queryParams, selectedItems, name) => {
  let newParam = "";
  if (Array.isArray(selectedItems)) {
    newParam = selectedItems.map(item => item.id).join(",");
  } else {
    newParam = selectedItems?.id || "";
  }

  let params = splitQueryParams(queryParams);
  params = { ...params, [name]: newParam };
  params = buildQueryParams(params);

  return params;
};

function useURLFilter(
  name,
  items,
  selectedItems,
  setSelectedItems,
  allowNull = true,
) {
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const { pathname: path, search: queryParams, hash } = useLocation();
  const navigate = useNavigate();
  const isArray = Array.isArray(selectedItems);

  useEffect(() => {
    const params = splitQueryParams(queryParams);
    if (name in params) {
      const ids = decodeURI(params[name]).split(",");
      const initialItems = items
        ? items.filter(item => ids.includes(String(item.id)))
        : [];
      setSelectedItems(isArray ? initialItems : initialItems[0]);
    } else if (name !== "sort" && allowNull) {
      setSelectedItems(isArray ? [] : null);
    }
    if (items) setItemsLoaded(true);
  }, [items, queryParams]);

  useEffect(() => {
    if (itemsLoaded) {
      const params = updateParams(queryParams, selectedItems, name);
      if (params !== queryParams) {
        navigate(`${path}${params}${hash}`, { replace: true });
      }
    }
  }, [navigate, selectedItems, name, itemsLoaded]);
}

export { useURLFilter, updateParams };
