import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import createCustomizedLabel from "@/components/shared/ChartsLabel";
import CustomTooltip from "@/components/shared/ChartsTooltip";
import COLORS from "./Colors";

const CHART_WIDTH = 878;
const CHART_HEIGHT = 578;

function FullsizePieChart({
  data,
  title,
  dataKey = "name",
  countRequired = false,
}) {
  const RenderCustomizedLabel = createCustomizedLabel(CHART_WIDTH);

  return (
    <>
      <h2 className="font-h2">{title}</h2>
      <PieChart width={CHART_WIDTH} height={CHART_HEIGHT}>
        {data && (
          <Pie
            dataKey="count"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius="95%"
            stroke="rgba(0,0,0,0)"
            labelLine={false}
            label={<RenderCustomizedLabel dataKey={dataKey} />}
          >
            {data.map((el, index) => (
              <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        )}
        <Tooltip content={<CustomTooltip countRequired={countRequired} />} />
      </PieChart>
    </>
  );
}

export default FullsizePieChart;
