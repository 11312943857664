import React from "react";
import FullWideForm from "@/components/shared/FullWideForm";

const Input = ({ className = "" }) => {
  return (
    <div className={className}>
      <div className="w-[100px] h-2 bg-slate-200 bg-dark-25 mb-2 rounded"></div>
      <div className="h-8 bg-slate-200 bg-dark-25 rounded"></div>
    </div>
  );
};
function SkeletonForm() {
  return (
    <FullWideForm className="animate-pulse">
      <FullWideForm.header>
        <button
          type="button"
          className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
        >
          <p className="h-2 bg-slate-200 bg-dark-25 block rounded w-[100px]"></p>
        </button>
        <div className="admin-intro ellipsis mx-auto w-full flex max-w-[964px] py-4">
          <h3 className="w-[160px] mb-2 h-2 bg-slate-200 bg-dark-25 rounded"></h3>
        </div>
      </FullWideForm.header>
      <FullWideForm.body>
        {[1, 2].map(index => {
          return (
            <div key={index}>
              <article className="flex p-7 pb-10 border-b">
                <header className="w-[260px] pr-20">
                  <h3 className=" w-[160px] mb-2 h-2 bg-slate-200 bg-dark-25 rounded"></h3>
                  <p className="h-2 bg-slate-200 mb-2 bg-dark-25 block rounded w-full"></p>
                  <p className="w-[180px] h-2 bg-slate-200 bg-dark-25 block rounded"></p>
                </header>
                <section className="flex-1">
                  <Input className="w-full mb-6" />
                  <div className="flex justify-between mb-6">
                    <Input className="w-1/2 mr-3" />
                    <Input className="w-1/2 ml-3" />
                  </div>
                  <Input className="mb-6" />
                  <Input className="mb-6" />
                  <Input className="mb-6" />
                </section>
              </article>
              <article className="flex p-7 pb-10 border-b">
                <header className="w-[260px] pr-20">
                  <h3 className="w-[160px] mb-2 h-2 bg-slate-200 bg-dark-25 rounded"></h3>
                  <p className="h-2 bg-slate-200 mb-2 bg-dark-25 block rounded w-full"></p>
                  <p className="w-[180px] h-2 bg-slate-200 bg-dark-25 block rounded"></p>
                </header>
                <section className="flex-1">
                  <Input className="w-full mb-6" />
                  <Input className="mb-6" />
                  <Input className="mb-6" />
                  <Input className="mb-6" />
                </section>
              </article>
            </div>
          );
        })}
      </FullWideForm.body>
      <FullWideForm.footer>
        <div className="mx-auto w-full max-w-[964px]">
          <div className="ml-72 flex">
            <p className="h-4 bg-slate-200 mb-2 bg-dark-25 block rounded w-[90px] mr-4"></p>
            <p className="h-4 bg-slate-200 mb-2 bg-dark-25 block rounded w-[90px]"></p>
            <p className="h-4 bg-slate-200 mb-2 bg-dark-25 block rounded w-[90px] ml-auto"></p>
          </div>
        </div>
      </FullWideForm.footer>
    </FullWideForm>
  );
}

export default SkeletonForm;
