import { fetcher } from "@/utils/api";
import React, { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import NoResults from "../../NoResults";
import Select from "../../Select";

const COLORS = [
  "#EC4899",
  "#3B82F6",
  "#829F37",
  "#6366F1",
  "#67E8F9",
  "#494487",
  "#E45C53",
  "#3FB5AE",
  "#FE6A36",
  "#EF4444",
];

function InsightPieChartWithSelectCenter({
  data,
  centers,
  dataKey,
  customSearch = "",
  innerRadius = 90,
  entity = "",
  showList = false,
  showTotal = false,
  showLabelPorcentajae = false,
}) {
  const [displayData, setDisplayData] = useState(data?.group_data || []);
  const [displayTotal, setDisplayTotal] = useState(data?.total || 0);
  const displayCenters = [{ id: 0, name: "All Centers" }, ...centers];
  const [selectedCenterId, setSelectedCenterId] = useState();

  const handleChange = async centerId => {
    if (centerId === 0) {
      setDisplayData(data?.group_data || []);
      setDisplayTotal(data?.total || 0);
    } else {
      const { total, group_data } = await fetcher(
        `/api/centers/${centerId}/${customSearch}`,
      );

      setDisplayData(group_data);
      setDisplayTotal(total);
    }
  };
  const dataLines = React.useMemo(
    () =>
      displayData?.map(type => ({
        dataKey: type.id,
        dataLabel: type.name,
        totalCount: type.count,
      })),
    [displayData],
  );

  const CustomTooltip = ({
    active,
    payload,
  }: {
    active?: boolean;
    payload?: any;
  }) => {
    if (active && payload !== undefined) {
      const { name, count } = payload[0].payload;
      const porcentaje = Math.round((count * 100) / displayTotal);
      return (
        <div className="border flex bg-white border-bg-tinti py-2 px-3">
          <p className="font-meta mr-2">
            {name}:{" "}
            <span className="font-meta-bold">
              {showLabelPorcentajae ? `${count} (${porcentaje}%)` : count}
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-2.5">
      {showTotal && (
        <h1 className="font-h1-xl text-dark-100">{displayTotal}</h1>
      )}
      <Select
        selected={selectedCenterId}
        setSelected={centerId => {
          setSelectedCenterId(centerId);
          handleChange(centerId);
        }}
        options={displayCenters}
        placeholder="All Centers"
        className="h-10 text-15 rounded font-body"
        placement="top-end"
      />
      {displayData.length > 0 ? (
        <div className="flex flex-col gap-6">
          {showList && (
            <ul className="text-15">
              {dataLines.map((dataLine, index) => (
                <li key={index} className="flex justify-between mb-3">
                  <div>
                    <span style={{ color: COLORS[index] }}>●</span>
                    <span className="ml-1">{dataLine.dataLabel}</span>
                  </div>
                  <div>{dataLine.totalCount}</div>
                </li>
              ))}
            </ul>
          )}
          <div className="w-full h-[300px]">
            <ResponsiveContainer>
              <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                <Pie
                  data={displayData}
                  dataKey={dataKey}
                  fill="#8884d8"
                  paddingAngle={1}
                  cx="50%"
                  cy="50%"
                  innerRadius={innerRadius}
                  outerRadius={150}
                >
                  {displayData.map((el, index) => (
                    <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={CustomTooltip} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <NoResults
          entityName={entity}
          icon="ri-search-line"
          className=" mt-5bg-transparent"
        />
      )}
    </div>
  );
}

export default InsightPieChartWithSelectCenter;
