import * as React from "react";

function ChartExampleThree() {
  return (
    <svg
      width="240"
      height="241"
      viewBox="0 0 240 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="240"
        height="240"
        transform="translate(0 0.230774)"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.730774"
        width="239"
        height="239"
        fill="#F8F8F9"
        stroke="#CFD3DA"
      />
      <path
        d="M0 135.249H238.868"
        stroke="#CFD3DA"
        stroke-width="1.13571"
        stroke-dasharray="2.27 2.27"
      />
      <path
        d="M0 68.2401H240"
        stroke="#CFD3DA"
        stroke-width="1.13571"
        stroke-dasharray="2.27 2.27"
      />
      <path
        d="M0 202.259H240"
        stroke="#CFD3DA"
        stroke-width="1.13571"
        stroke-dasharray="2.27 2.27"
      />
      <path
        d="M60.0005 1.23071L60.0005 240.231"
        stroke="#CFD3DA"
        stroke-width="1.13571"
        stroke-dasharray="2.27 2.27"
      />
      <path
        d="M120.001 1.23071L120.001 240.231"
        stroke="#CFD3DA"
        stroke-width="1.13571"
        stroke-dasharray="2.27 2.27"
      />
      <path
        d="M180 1.23071L180 240.231"
        stroke="#CFD3DA"
        stroke-width="1.13571"
        stroke-dasharray="2.27 2.27"
      />
      <path
        d="M0.699036 200.89L0.5 201.04V201.289V239.231V239.731H1H239H239.5V239.231V46.2307V44.9529L238.633 45.8914L179.438 109.949C178.995 110.428 178.476 110.831 177.902 111.142L121.236 141.782C120.757 142.041 120.245 142.233 119.714 142.352L62.2483 155.268C61.2865 155.484 60.3818 155.903 59.5945 156.496L0.699036 200.89Z"
        fill="#685BC7"
        fill-opacity="0.5"
        stroke="#685BC7"
      />
    </svg>
  );
}

export default ChartExampleThree;
