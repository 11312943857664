import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import Modal from "@/components/shared/Modal";
import DeleteConfirmationModal from "@/components/shared/Modal/DeleteConfirmationModal";
import NewsItemList from "./NewsItem/NewsItemList";
import NewsItemForm from "./NewsItem/NewsItemForm";
import DatePicker from "@/components/shared/DatePicker";

const sortNewsItems = (newsItems, newsItemPositions) => {
  if (newsItems === undefined) return [];
  return newsItems.sort(
    (a, b) => newsItemPositions.indexOf(a.id) - newsItemPositions.indexOf(b.id),
  );
};

const fetchNewsFeed = ({ queryKey }) => {
  const [key, newsFeedId] = queryKey;
  const baseUrl = `/api/${key}/${newsFeedId}`;
  const params = paramBuilder({
    include: ["newsItems", "lastEditor", "author"],
    fields: {
      newsFeeds: [
        "status",
        "title",
        "newsItems",
        "lastEditor",
        "author",
        "newsItemPositions",
        "canPublish",
        "newsItemsList",
        "sendNewsFeedDate",
      ],
      users: ["fullName"],
    },
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

const fetchUsers = ({ queryKey }) => {
  const [key] = queryKey;
  const baseUrl = `/api/${key}`;
  const params = paramBuilder({
    fields: { users: ["fullName", "centerNameOrInstitution"] },
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

const deleteNewsItemResquest = itemId => {
  return fetch(`/api/news-items/${itemId}`, {
    method: "DELETE",
  });
};

function Skeleton() {
  return (
    <div className="bg-tint h-full py-6 px-8">
      <div className="max-w-4xl m-auto">
        <div className="flex justify-between items-center py-2 mb-8">
          <div className="w-full">
            <div className="h-4 bg-dark-25 w-3/5 rounded-md mb-2" />
            <div className="h-4 bg-dark-25 w-1/5 rounded-md" />
          </div>
          <div className="w-28 h-9 bg-dark-25 rounded-md" />
        </div>
        <div className="shadow bg-white rounded-md text-sm divide-y divide-dashed text-dark-50 font-normal mb-6 animate-pulse">
          {[1, 2, 3, 4, 5].map(i => (
            <div className="p-8" key={i}>
              <div className="h-4 bg-dark-25 w-3/5 rounded-md mb-2" />
              <div className="h-4 bg-dark-25 w-20 rounded-md mb-4" />
              <div className="h-4 bg-dark-25 w-1/4 rounded-md" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function SchedulehModal({ open, setOpen, onAccept, date, setDate }) {
  const selected = date ? moment(date).toDate() : date;
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        Are you sure you want to schedule the sending of the daily news?
      </Modal.Header>
      <Modal.Body className="mt-4 text-center">
        <label className="mr-4 font-bold text-15 mb-1 text-dark-100">
          Enter schedule date
        </label>
        <DatePicker
          styleAs="small"
          dateFormat="MM/dd/yyyy"
          id="scheduleDate"
          placeholder="MM/DD/YYYY"
          onChange={date => setDate(date)}
          selected={selected}
        />

        <p className="my-14 text-left">
          A digest view and Dashboard module will be created in the Member
          Center. An Email containing this Daily News Feed content will also be
          sent on the scheduled day to all current PICI members.
        </p>
      </Modal.Body>
      <Modal.Footer className="flex justify-between mt-2">
        <button
          type="button"
          className="btn-white btn-md"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button type="button" className="btn-primary btn-md" onClick={onAccept}>
          Yes, Schedule and Send
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function PublishModal({ open, setOpen, onAccept }) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        Are you sure you want to publish and send <br />
        "Daily News Feed for {format(Date.now(), "MMM dd, yyyy")}"
      </Modal.Header>
      <Modal.Body>
        A digest view and Dashboard module will be created in the Member Center.
        An Email containing this Daily News Feed content will also be sent to
        all current PICI members.
      </Modal.Body>
      <Modal.Footer className="flex justify-between">
        <button
          type="button"
          className="btn-white btn-md"
          onClick={() => setOpen(false)}
        >
          No, Cancel
        </button>
        <button type="button" className="btn-primary btn-md" onClick={onAccept}>
          Yes, Publish and Send
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function UnpublishModal({ open, setOpen }) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        Unable to publish <br />
        "Daily News Feed for {format(Date.now(), "MMM dd, yyyy")}"
      </Modal.Header>
      <Modal.Body>
        A Daily News Feed has already been published for today. Please edit the
        most recent published post to make updates.
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn-white btn-md"
          onClick={() => setOpen(false)}
        >
          Back
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function DailyNewsFeedDetail() {
  const { id: newsFeedId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentNewsItem, setCurrentNewsItem] = useState(null);
  const [isReadyToPublish, setIsReadyToPublish] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);

  const {
    data: newsFeed,
    isLoading: newsFeedIsLoading,
  }: { data: any; isLoading: boolean } = useQuery(
    ["news-feeds", newsFeedId],
    fetchNewsFeed,
  );

  const { data: users } = useQuery(["users"], fetchUsers);
  const { data: options } = useQuery(["newsItems", "filters"], () =>
    fetcher("/api/news-items/filters"),
  );

  const publishNews = () => {
    fetch(`/api/news-feeds/${newsFeedId}/publish`, {
      method: "PATCH",
    }).then(() => navigate("/admin/daily-news"));
  };

  const scheduleNews = () => {
    let date = null;
    let isoDate = null;
    if (scheduleDate) {
      date = new Date(scheduleDate);
      isoDate = date.toISOString();
    }
    const scheduleNewsDate = date ? isoDate.substring(0, 10) : null;
    fetch(`/api/news-feeds/${newsFeedId}/schedule`, {
      method: "PATCH",
      headers: { "Content-Type": "application/vnd.api+json" },
      body: JSON.stringify({ schedule_date: scheduleNewsDate }),
    }).then(() => navigate("/admin/daily-news"));
  };

  const handlePublishClick = () => setShowPublishModal(true);
  const toggleShowScheduleModal = () =>
    setShowScheduleModal(prevState => !prevState);
  const handleConfirmPublishClick = () => publishNews();
  const handleConfirmScheduleClick = () => scheduleNews();

  const mutation = useMutation(deleteNewsItemResquest, {
    onSettled: async () => {
      await queryClient.invalidateQueries(["news-feeds", newsFeedId]);
    },
  });

  const handleDeleteNewsItem = () => {
    setShowDeleteModal(false);
    mutation.mutate(currentNewsItem);
  };

  const newsItems = sortNewsItems(
    newsFeed?.data.relationships.newsItems.data,
    newsFeed?.data.attributes.newsItemPositions,
  );

  useEffect(() => {
    const newsItems = newsFeed?.data.relationships.newsItems.data;
    const scheduleDate = newsFeed?.data.attributes.sendNewsFeedDate;
    if (scheduleDate) {
      setScheduleDate(scheduleDate);
    }
    const existEmptyCategory = newsItems?.some(newsItem => {
      return newsItem.attributes.categoryId === null;
    });
    setIsReadyToPublish(!existEmptyCategory);
  }, [newsFeed]);

  if (newsFeedIsLoading) return <Skeleton />;

  const isScheduled =
    newsFeed?.data.attributes.status === "draft" &&
    newsFeed?.data.attributes.sendNewsFeedDate;

  return (
    <div className="bg-tint py-6 px-8 min-h-screen">
      <div className="max-w-4xl m-auto">
        <div className="lg:flex lg:items-baseline mb-6 gap-3 items-center">
          <Link to="/admin/daily-news" className="btn-white btn-sm">
            <i className="ri-arrow-left-s-line"></i>
          </Link>
          <div>
            <p className="font-bold text-dark-100 text-2xl flex items-center">
              {newsFeed?.data.attributes.status === "published" ? (
                <>
                  <Link
                    to={newsFeed?.data.links.slug}
                    target="_blank"
                    className="link"
                  >
                    {newsFeed?.data.attributes.title}
                  </Link>
                </>
              ) : (
                <>
                  Daily News Feed for &nbsp;
                  <span className="text-yellow-800">
                    [{format(Date.now(), "MMM dd, yyyy")}]
                  </span>
                </>
              )}
            </p>
            <p className="text-sm text-dark-50">
              {newsFeed?.data.relationships.lastEditor.data
                ? `Last modified by ${newsFeed.data.relationships.lastEditor.data.attributes.fullName}
              `
                : `Created by ${newsFeed?.data.relationships.author.data.attributes.fullName}`}
            </p>
          </div>
          <div className="mt-3 w-full lg:w-auto lg:mt-0">
            <span
              className={clsx(
                "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium uppercase lg:ml-3",
                newsFeed?.data.attributes.status === "published"
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800",
              )}
            >
              {newsFeed?.data.attributes.status}
            </span>
            {isScheduled && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium uppercase lg:ml-3 bg-gray-200 text-black-800 ">
                Scheduled
              </span>
            )}
          </div>
          <button
            onClick={() => {
              setCurrentNewsItem(null);
              setFormIsOpen(true);
            }}
            className="btn-md btn-primary ml-auto mt-3 lg:mt-0"
          >
            + Content
          </button>
        </div>
        {newsFeed?.data.attributes.status === "published" && (
          <div className="mb-6">
            <Link to={newsFeed?.data.links.slug} className="link-primary">
              Go to the Published Digest
            </Link>
          </div>
        )}
        {newsItems.length > 0 ? (
          <>
            <NewsItemList
              {...{
                setShowDeleteModal,
                setCurrentNewsItem,
              }}
              newsFeed={newsFeed?.data}
              categories={options?.categories}
              itemList={newsItems}
              setFormIsOpen={setFormIsOpen}
            />
            {newsFeed?.data.attributes.status === "draft" && (
              <section className="flex items-center justify-between mt-8 text-sm text-dark-50">
                <p>
                  Tip: Content in the same category should be grouped together.
                </p>
                <div className="flex gap-4">
                  <a
                    target="_blank"
                    href={`/admin/daily-news/${newsFeedId}/publish_preview`}
                    className={clsx(
                      "btn-white btn-md",
                      !isReadyToPublish && "pointer-events-none opacity-50",
                    )}
                  >
                    Preview Email
                  </a>

                  <button
                    type="button"
                    disabled={
                      !isReadyToPublish ||
                      newsFeed?.data.attributes.sendNewsFeedDate
                    }
                    onClick={handlePublishClick}
                    className={clsx(
                      "btn-primary btn-sm flex gap-2",
                      !isReadyToPublish ||
                        (newsFeed?.data.attributes.sendNewsFeedDate &&
                          "pointer-events-none opacity-50"),
                    )}
                  >
                    Publish & Send
                  </button>
                  <button
                    type="button"
                    disabled={!isReadyToPublish}
                    onClick={toggleShowScheduleModal}
                    className={clsx(
                      "btn-primary btn-sm flex gap-2",
                      !isReadyToPublish && "pointer-events-none opacity-50",
                    )}
                  >
                    Schedule & Send
                  </button>
                </div>
              </section>
            )}
          </>
        ) : (
          <div className="shadow rounded-lg divide-y-2 divide-dashed bg-white p-12 grid place-content-center">
            <p className="text-dark-100">
              This is a new Daily News Feed. Get started by{" "}
              <button
                onClick={() => setFormIsOpen(true)}
                className="inline-block link-primary"
              >
                adding content
              </button>
              .
            </p>
          </div>
        )}
      </div>
      <DeleteConfirmationModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onAccept={handleDeleteNewsItem}
        resource="content"
      />
      {newsFeed?.data.attributes.canPublish ? (
        <PublishModal
          open={showPublishModal}
          setOpen={setShowPublishModal}
          onAccept={handleConfirmPublishClick}
        />
      ) : (
        <UnpublishModal open={showPublishModal} setOpen={setShowPublishModal} />
      )}
      <SchedulehModal
        open={showScheduleModal}
        setOpen={toggleShowScheduleModal}
        onAccept={handleConfirmScheduleClick}
        date={scheduleDate}
        setDate={setScheduleDate}
      />
      <NewsItemForm
        options={options}
        open={formIsOpen}
        setOpen={setFormIsOpen}
        users={users}
        currentNewsItem={currentNewsItem}
      />
    </div>
  );
}
export default DailyNewsFeedDetail;
