import Checkbox from "@/components/shared/Checkbox";
import FormBlock from "@/components/shared/FormBlock";
import SearchBar from "@/components/shared/SearchBar";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { Field } from "formik-latest";
import { filterByParam } from "../filters";
import ResultList from "@/components/shared/ResultList";
import ExternalMemberForm from "./ExternalMemberForm";

type Options = {
  id?: string;
  name: string;
};

type MemberOptions = {
  attributes?: any;
  id?: string;
  name: string;
  label?: string;
  isExternal?: boolean;
};

const parsedManagers = users => {
  return users?.data
    .filter(({ type }) => type === "employees")
    .map(user => {
      return {
        id: user.id,
        name: user.attributes.fullName,
      };
    });
};

const parsedMembers = users => {
  return users?.data.map(user => ({
    id: user.id,
    name: user.attributes.fullName,
    label: user.attributes.centerNameOrInstitution,
  }));
};

const membersAttributes = (member, isManager = false) => {
  return {
    attributes: {
      userId: Number(member.id),
      projectLead: false,
      projectManager: isManager ? true : false,
    },
    name: member.name,
    center: member?.label || undefined,
  };
};

function UserForm({ values, onChange, onTouched, users }) {
  const [manager, setManager] = useState<Options[]>(values.manager);
  const [members, setMembers] = useState<MemberOptions[]>(values.members);
  const [isNewMember, setIsNewMember] = useState<boolean>(false);
  const [memberText, setMemberText] = useState<string>("");

  const managerOptions: Options[] = React.useMemo(() => parsedManagers(users), [
    users,
  ]);

  const memberOptions: Options[] = React.useMemo(() => {
    return parsedMembers(users);
  }, [users]);

  const managerOptionsFiltered = React.useMemo(
    () => filterByParam(managerOptions, manager, "name"),
    [manager, managerOptions],
  );

  const memberOptionsFiltered = React.useMemo(
    () => filterByParam(memberOptions, [...manager, ...members], "name"),
    [members, memberOptions, manager],
  );

  const onManagerSelect = value => {
    const newMember = membersAttributes(value, true);
    setManager([newMember]);
    onTouched("manager", true);
  };

  const onMemberSelect = value => {
    if (!value.name) {
      setIsNewMember(true);
      setMemberText(value);
    } else {
      const newMember = membersAttributes(value);
      setMembers([...members, newMember]);
      isNewMember && setIsNewMember(false);
      setMemberText("");
    }
  };

  const removeMembers = index => {
    setMembers([...members.slice(0, index), ...members.slice(index + 1)]);
  };

  const removeManagers = () => {
    const removeManagerOnMemberships = values.projectMemberships.filter(
      project => project.attributes.projectManager == false,
    );
    onChange("projectMemberships", removeManagerOnMemberships);
    onChange("manager", []);
    setManager([]);
  };

  const onAddNewMember = (value, isExternal = false) => {
    setMembers([...members, { ...value, isExternal }]);
    setIsNewMember(false);
    setMemberText("");
  };

  const updateProjectLead = name => {
    const updatedProjectLead = members.map(project => {
      if (project.name === name) {
        project.attributes.projectLead = !project.attributes.projectLead;
      }
      return project;
    });
    setMembers(updatedProjectLead);
  };

  useEffect(() => {
    if (!isEqual(manager, values.manager)) {
      const memberships = values.projectMemberships.filter(
        project => project.attributes.projectManager == false,
      );

      onChange("manager", manager);
      onChange("projectMemberships", [...manager, ...memberships]);
    }
  }, [manager, onChange, values.manager, values.projectMemberships]);

  useEffect(() => {
    if (!isEqual(members, values.members)) {
      const memberships = [
        ...new Set([
          ...manager,
          ...members.filter(member => !member.isExternal),
        ]),
      ];
      const externalMemberships = members.filter(member => member.isExternal);
      onChange("members", members);
      onChange("projectMemberships", memberships);
      onChange("projectExternalMemberships", externalMemberships);
    }
  }, [manager, members, onChange, values.members]);

  return (
    <>
      <Field name="manager">
        {({ field, form }) => {
          const { touched, errors } = form;
          return (
            <>
              <label className="font-body-bold mb-2 mt-6 block">
                PICI Project Manager
                <span className="font-body ml-1 self-center text-gray-400">
                  The project team's main point of contact from the Parker
                  Institute
                </span>
              </label>
              {manager.length > 0 ? (
                <ResultList
                  list={manager}
                  onDelete={_ => removeManagers()}
                  attributeToShow="name"
                />
              ) : (
                <>
                  <SearchBar
                    options={managerOptionsFiltered || []}
                    placeholder="Type member's name"
                    accessor={item => item.name}
                    matchCriteria={(input, itemSearchable) =>
                      itemSearchable
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase())
                    }
                    onSelect={user => onManagerSelect(user)}
                    selected=""
                    {...field}
                  >
                    {({ item }) => (
                      <div className="px-6 py-4 cursor-pointer flex sm:space-x-5 hover:bg-gray-50 hover:text-indigo-500">
                        <span className="font-body max-w-md">{item.name}</span>
                      </div>
                    )}
                  </SearchBar>
                  {touched?.manager && errors?.manager && (
                    <div className="text-error text-14 mt-5px">
                      {errors?.manager}
                    </div>
                  )}
                </>
              )}
            </>
          );
        }}
      </Field>
      <FormBlock
        label="Project team"
        name="members"
        optional
        className="mt-6 mb-2"
        labelClassName="font-body-bold"
      >
        <SearchBar
          options={memberOptionsFiltered || []}
          placeholder="Type member's name"
          accessor={item => item.name}
          onTextChange={item => setMemberText(item)}
          matchCriteria={(input, itemSearchable) =>
            itemSearchable
              .toLocaleLowerCase()
              .includes(input.toLocaleLowerCase())
          }
          onSelect={user => onMemberSelect(user)}
          selected={memberText}
          displayNewExternal
        >
          {({ item, index }) => {
            return index === "external" ? (
              <div className="p-3 cursor-pointer flex flex-col">
                <span className="text-dark-100">
                  + Add "{item}" as a Project Member
                </span>
              </div>
            ) : (
              <div className="px-6 py-4 cursor-pointer flex-col hover:bg-gray-50 hover:text-indigo-500">
                <div className="font-body max-w-md">{item.name}</div>
                <div className="font-body text-gray-500">{item.label}</div>
              </div>
            );
          }}
        </SearchBar>
      </FormBlock>
      {isNewMember && (
        <div className="mb-4">
          <ExternalMemberForm
            memberName={memberText}
            onChange={values => onAddNewMember(values, true)}
            onCancel={() => setIsNewMember(false)}
          />
        </div>
      )}
      {members.length > 0 &&
        members.map((item, index) => {
          return (
            <div
              className="flex items-center bg-blue-2 px-3 py-2 mb-1"
              key={index}
            >
              <span className="flex-1 font-meta mr-4">{item.name}</span>
              <div className="font-meta mr-4">
                <Checkbox
                  checked={item.attributes?.projectLead}
                  onChange={() => updateProjectLead(item.name)}
                  childrenClassname="!font-meta"
                >
                  Project Lead
                </Checkbox>
              </div>
              <button
                type="button"
                onClick={() => removeMembers(index)}
                className="ml-auto"
              >
                <i className="ri-delete-bin-line font-body text-dark-50" />
              </button>
            </div>
          );
        })}
    </>
  );
}

export default UserForm;
