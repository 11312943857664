import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { fetcher } from "@/utils/api";
import AsideComponent from "@/components/shared/Aside";

const fetchEvents = ({ queryKey }) => {
  const baseUrl = `/api/${queryKey}`;
  const params = paramBuilder({
    fields: {
      events: [
        "title",
        "link",
        "location",
        "hostedAt",
        "userId",
        "deletedAt",
        "createdAt",
        "updatedAt",
        "archived",
        "position",
      ],
    },
    filter: {
      archived: [false],
    },
  });
  const url = `${baseUrl}?${params}`;

  return fetcher(url);
};

function Skeleton() {
  return (
    <div className="space-y-9">
      {[1, 2, 3].map(x => (
        <div key={x} className="flex">
          <div className="mr-3 animate-pulse">
            <div className="rounded-full h-4 w-4 bg-dark-25" />
          </div>
          <div className="animate-pulse w-full gap-1 flex flex-col">
            <div className="h-4 w-2/3 bg-dark-25 rounded-full" />
            <div className="h-4 w-5/6 bg-dark-25 rounded-full" />
            <div className="h-4 w-1/2 bg-dark-25 rounded-full" />
          </div>
        </div>
      ))}
    </div>
  );
}

function EventItem({ event }) {
  const { hostedAt, link, title, location } = event.attributes;

  return (
    <div className="flex">
      <div className="text-dark-50 mr-3 mt-0.5">
        <i className="ri-calendar-2-line ri-lg" />
      </div>
      <div>
        <p className="font-meta text-dark-75">{hostedAt}</p>
        {link ? (
          <>
            <a className="link font-body-bold" href={link} target="_blank">
              {title}
            </a>
            <i className="ri-external-link-line text-dark-50 ml-2"></i>
          </>
        ) : (
          <p className="font-body-bold">{title}</p>
        )}
        <p className="uppercase font-label text-dark-75">{location}</p>
      </div>
    </div>
  );
}

function Aside() {
  const { isLoading, data }: { isLoading: boolean; data: any } = useQuery(
    ["events"],
    fetchEvents,
  );
  const events = data?.data;

  return (
    <AsideComponent>
      <AsideComponent.Section className="sm:w-80 w-full p-6 bg-tint rounded-lg">
        <AsideComponent.Content>
          <AsideComponent.Title
            title="Upcoming Events"
            className="pb-5 mb-5 border-b border-dark-25 text-17"
          />
          <AsideComponent.Body>
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className="space-y-9">
                {events.length > 0 ? (
                  events.map(event => (
                    <EventItem key={event.id} event={event} />
                  ))
                ) : (
                  <p className="font-body">There are no upcoming events.</p>
                )}
              </div>
            )}
          </AsideComponent.Body>
        </AsideComponent.Content>
      </AsideComponent.Section>
    </AsideComponent>
  );
}

export default Aside;
