const pluralize = (count, string) => {
  return count === 1 ? string : `${string}s`;
};

const humanize = string => {
  return string
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[\s_]+/g, " ")
    .replace(/^[a-z]/, function(m) {
      return m.toUpperCase();
    });
};

const camelCaseToDash = string =>
  string.replace(/[A-Z]/g, m => "_" + m.toLowerCase());

export { pluralize, humanize, camelCaseToDash };
