import React, { useState, useRef } from "react";
import { Document, Page } from "react-pdf";

function PdfViewer({ pdfFilePath }) {
  const divRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div className="w-full border border-gray-300" ref={divRef}>
      <Document
        file={pdfFilePath}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {[...new Array(numPages)].map((_el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={divRef?.current?.offsetWidth}
          />
        ))}
      </Document>
    </div>
  );
}

export default PdfViewer;
