import * as React from "react";
import { Link } from "react-router-dom";
import notFoundImage from "@/images/404.png";
import clsx from "clsx";

function NotFoundPage() {
  return (
    <div className={clsx("mt-16 mx-auto max-w-2xl px-4", "font-lato")}>
      <div className="relative flex items-center">
        <img
          alt="404 - Page Not Found"
          src={notFoundImage}
          className="absolute -left-16"
        />
        <h1 className="font-h2">404 - Page Not Found</h1>
      </div>

      <div>
        <p className="font-h1 mt-5 mb-2.5">
          The page you're looking for cannot be found
        </p>
        <p className="mb-8">
          Check the web address and confirm that it appears correct
        </p>
        <Link to="/" className="btn-md btn-primary">
          Return to Dashboard
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
