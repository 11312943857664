import React from "react";
import Modal from "@/components/shared/Modal";
import FormBlock from "@/components/shared/FormBlock";
import TextArea from "@/components/shared/TextArea";
import Select from "@/components/shared/Select";
import { Form, Formik } from "formik-latest";
import Input from "@/components/shared/Input";
import clsx from "clsx";
import * as Yup from "yup";
import { format } from "date-fns";
import { saveResource } from "@/utils/api";
import { useNavigate } from "react-router-dom";

const statusOptions = [
  { name: "Pending", id: "pending" },
  { name: "Accepted", id: "accepted" },
  { name: "Declined", id: "declined" },
];

const INITIAL_VALUES = {
  status: "pending",
  date: format(new Date(), "MM/dd/yyyy"),
  reason: "",
};

const InternalReviewSchema = Yup.object().shape({
  status: Yup.string().oneOf(["pending", "accepted", "declined"]),
  date: Yup.date().required("Date is required"),
  reason: Yup.string()
    .required("Internal review reason is required.")
    .max(500, "This reason is too long. It must be less than 500 characters."),
});

function InternalReviewModal({
  isOpenReviewModal,
  setIsOpenReviewModal,
  invention,
}) {
  const navigate = useNavigate();
  const handleSubmit = values => {
    saveResource("inventions", {
      data: {
        id: invention.id,
        type: "inventions",
        attributes: {
          review: {
            notes: values.reason,
            review_status: values.status,
            date: new Date(values.date),
          },
        },
      },
    }).then(res => {
      if (res.status === 200) {
        setIsOpenReviewModal(false);
        navigate(`/admin/inventions_beta#${values.status}`);
      }
    });
  };

  return (
    <Modal
      open={isOpenReviewModal}
      setOpen={setIsOpenReviewModal}
      className="w-full min-w-min max-w-md"
    >
      <Modal.Header>
        <span className="font-normal">Internal Review</span>
      </Modal.Header>
      <Modal.Body>
        <p className="text-15 text-dark-75 font-lato">
          Outcome of Parker Institute's internal evaluation regarding the patent
          potential of "{invention.attributes.title}". The status can be updated
          at any point should future discussions result in a different outcome.
        </p>
        <Formik
          onSubmit={handleSubmit}
          initialValues={INITIAL_VALUES}
          validationSchema={InternalReviewSchema}
        >
          {({ values, isValid, isSubmitting, setFieldValue }) => {
            const isDisabledSubmit =
              values.status === "pending" || !isValid || isSubmitting;
            return (
              <Form>
                <FormBlock label="Status" name="status" className="mt-5 w-1/2">
                  <Select
                    selected={values.status || statusOptions[0].id}
                    setSelected={value => setFieldValue("status", value)}
                    options={statusOptions}
                  />
                </FormBlock>
                {values.status !== "pending" && (
                  <>
                    <FormBlock label="Date" name="date" className="w-1/2 mt-5">
                      <Input readOnly />
                    </FormBlock>
                    <FormBlock
                      label={`Reason for ${
                        values.status === "accepted" ? "Accepting" : "Declining"
                      }`}
                      name="reason"
                      className="w-full mt-5"
                    >
                      <TextArea className="h-20 max-h-20" />
                    </FormBlock>
                  </>
                )}
                <div className="pt-10 flex justify-between">
                  <button
                    type="button"
                    onClick={() => setIsOpenReviewModal(false)}
                    className="btn-white btn-sm font-meta-bold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={clsx("btn-primary btn-sm font-meta-bold", {
                      "bg-dark-25 hover:bg-dark-25 cursor-default": isDisabledSubmit,
                    })}
                    disabled={isDisabledSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default InternalReviewModal;
