import * as R from "ramda";

const normalizationCriteria = (related, included, stiMap) => {
  return (
    related.type === keyAsValue(stiMap, included.type) &&
    related.id === included.id
  );
};

const keyAsValue = function(obj, prop) {
  return prop in obj ? obj[prop] : prop;
};

const apply = (target, fn) => {
  if (Array.isArray(target)) {
    return target.map(fn);
  } else {
    return fn(target);
  }
};

const denormalizer = (response = {}, stiMap = {}) => {
  const denormalized = R.clone(response);
  if (response.included) {
    apply(denormalized.data, item => {
      if (item.hasOwnProperty("relationships")) {
        Object.keys(item.relationships).forEach(key => {
          const relation = item.relationships[key];
          if (relation.hasOwnProperty("data") && relation.data) {
            relation.data = apply(relation.data, related => {
              return response.included.find(includedItem =>
                normalizationCriteria(related, includedItem, stiMap),
              );
            });
          }
        });
      }
    });
  }
  return denormalized;
};

export default denormalizer;
