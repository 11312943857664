import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import Modal from "@/components/shared/Modal";
import AcceptedDeclined from "./AcceptedDeclined/Thumbnail";
import AcceptedDeclinedFullSize from "./AcceptedDeclined/FullSize";
import ThumbnailPieChart from "@/components/shared/ThumbnailPieChart";
import FullsizePieChart from "@/components/shared/FullsizePieChart";
import ThumbnailBarChart from "@/components/shared/ThumbnailBarChart";
import FullsizeBarChart from "@/components/shared/FullsizeBarChart";
import ThumbnailBarVerticalChart from "@/components/shared/ThumbnailBarVerticalChart";
import FullsizeBarVerticalChart from "@/components/shared/FullsizeBarVerticalChart";
import HorizontalStackedBarChart from "@/components/shared/HorizontalStackedBarChart";
import HorizontalStackedBarChartFullSize from "@/components/shared/HorizontalStackedBarChart/HorizontalStackedBarChartFullSize";

const SORTEDLIST = [
  "UCSF",
  "Stanford",
  "MD Anderson",
  "UPenn",
  "UCLA",
  "MSKCC",
];

const alternateLabels = labels => {
  const sorted = labels.sort((a, b) => a.count - b.count);
  const newLabels = [];
  let beginning = 0;
  let end = sorted.length - 1;
  let forwards = true;

  while (newLabels.length < sorted.length) {
    if (forwards) {
      newLabels.push(sorted[beginning++]);
    } else {
      newLabels.push(sorted[end--]);
    }
    forwards = !forwards;
  }

  return newLabels.reverse();
};

function Charts() {
  const { data: charts } = useQuery(["inventionsCharts"], () =>
    fetcher("/api/inventions/charts").then(json => {
      return {
        ...json,
        focusAreas: alternateLabels(json.focusAreas),
        institutions: alternateLabels(json.institutions),
      };
    }),
  );

  const shorthands = useMemo(
    () =>
      charts?.institutions
        .filter(x => x.id !== "other")
        .map(x => x.shorthand)
        .sort(
          (a, b) =>
            (SORTEDLIST.indexOf(a) >>> 0) - (SORTEDLIST.indexOf(b) >>> 0),
        ),
    [charts?.institutions],
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const [ModalBody, setModalBody] = useState(() => () => <></>);

  return (
    <div className="flex flex-wrap flex-col sm:flex-row gap-6 py-7.5 sm:px-5">
      {charts && (
        <>
          <ThumbnailPieChart
            data={charts.applicationCategories}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizePieChart
                  data={charts.applicationCategories}
                  title="Application Category"
                />
              ));
            }}
            title="Application Category"
          />
          <ThumbnailPieChart
            data={charts.inventionClasses}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizePieChart
                  data={charts.inventionClasses}
                  title="Invention Class"
                />
              ));
            }}
            title="Invention Class"
          />
          <ThumbnailPieChart
            data={charts.technologyTypes}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizePieChart
                  data={charts.technologyTypes}
                  title="Technology Type"
                />
              ));
            }}
            title="Technology Type"
          />
          <ThumbnailPieChart
            data={charts.cellTherapySubtypes}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizePieChart
                  data={charts.cellTherapySubtypes}
                  title="Cellular Therapy Subtype"
                />
              ));
            }}
            title="Cellular Therapy Subtype"
          />
          <ThumbnailBarVerticalChart
            data={charts.patentLifecycle.phases}
            title={"Patent Life Cycle"}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizeBarVerticalChart
                  data={charts.patentLifecycle.phases}
                  title={"Patent Life Cycle"}
                />
              ));
            }}
          />
          {/* Hide according to https://linear.app/pici/issue/PIC-778/ip-dashboard-remove-focus-area-graph 
          <ThumbnailPieChart
            data={charts.focusAreas}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizePieChart
                  data={charts.focusAreas}
                  title="Focus Area (Category)"
                />
              ));
            }}
            title="Focus Area (Category)"
          /> */}
          <ThumbnailPieChart
            data={charts.institutions}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizePieChart
                  data={charts.institutions}
                  title="Institutions"
                  dataKey="shorthand"
                />
              ));
            }}
            title="Institutions"
          />
          <AcceptedDeclined
            data={charts.acceptedDeclined}
            centers={shorthands}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <AcceptedDeclinedFullSize
                  data={charts.acceptedDeclined}
                  centers={shorthands}
                />
              ));
            }}
          />
          <ThumbnailBarChart
            data={charts.disclosureSteps.steps}
            title={"Inventions Disclosed"}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <FullsizeBarChart
                  data={charts.disclosureSteps.steps}
                  total={charts.disclosureSteps.total}
                  title={"Inventions Disclosed"}
                />
              ));
            }}
          />
          <HorizontalStackedBarChart
            title="Number of Licensed PICI Cases"
            data={charts.inventionLicenseByCompany}
            centers={shorthands}
            openModal={() => {
              setIsModalOpen(true);
              setModalBody(() => () => (
                <HorizontalStackedBarChartFullSize
                  data={charts.inventionLicenseByCompany}
                  centers={shorthands}
                  title="Number of Licensed PICI Cases"
                />
              ));
            }}
          />
          {charts.inventionLicenseByCenterInstitution.length > 0 && (
            <ThumbnailPieChart
              data={charts.inventionLicenseByCenterInstitution}
              openModal={() => {
                setIsModalOpen(true);
                setModalBody(() => () => (
                  <FullsizePieChart
                    data={charts.inventionLicenseByCenterInstitution}
                    title="Licensed by Center"
                    countRequired={true}
                  />
                ));
              }}
              title="Licensed by Center"
              countRequired={true}
            />
          )}
          <Modal
            open={isModalOpen}
            setOpen={() => setIsModalOpen(false)}
            className="font-lato"
          >
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute right-3"
            >
              <i className="ri-close-line ri-lg" />
            </button>
            <Modal.Body className="py-8 px-12">
              <ModalBody />
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Charts;
