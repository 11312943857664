import React, { useEffect } from "react";
import * as R from "ramda";
import formatBytes from "@/utils/formatBytes";
import useActiveStorage from "@/hooks/useActiveStorage";
import useActiveStoragePreview from "@/hooks/useActiveStoragePreview";
import clsx from "clsx";

const AttachmentDisplay = ({ metadata, cleanFile, children }) => {
  return (
    <div className="flex items-center">
      <div>{children}</div>
      <div className="ml-1 flex flex-col max-w-sm">
        <span
          title={metadata?.filename}
          className="overflow-ellipsis text-14 line-clamp-1"
        >
          {metadata?.filename}
        </span>
        <div className="text-dark-50 text-12">
          {formatBytes(
            metadata?.byteSize || metadata?.byte_size || metadata?.size,
          )}
        </div>
      </div>
      <div className="ml-2.5">
        <i className="ri-close-circle-line text-dark-50" onClick={cleanFile} />
      </div>
    </div>
  );
};

function FilePicker({
  name = "file",
  setSignedId,
  attachment = {},
  onLoadFile = undefined,
  hideButtonWhenUpload = false,
  className = "",
  showPreview = true,
}: {
  name?: string;
  setSignedId: Function;
  attachment: any;
  onLoadFile?: Function | undefined;
  hideButtonWhenUpload?: boolean;
  className?: string;
  showPreview?: boolean;
}) {
  const {
    uploadFile,
    progress,
    metadata,
    file,
    status,
    setStatus,
  } = useActiveStorage({});
  const preview = useActiveStoragePreview(
    file,
    "w-9 h-9 rounded-lg bg-cover mr-3.5",
    onLoadFile,
  );
  const cleanFile = () => {
    setStatus("initial");
    setSignedId(null);
  };
  useEffect(() => {
    if (!R.isEmpty(metadata)) {
      setSignedId(metadata["signedId"]);
      if (!showPreview) {
        cleanFile();
      }
    }
  }, [metadata]);
  const hideButton = hideButtonWhenUpload ? !!attachment.signedId : false;
  return (
    <div
      className={clsx(
        "flex items-center py-4.5 px-5 border border-dark-25 bg-white rounded-sm",
        className,
      )}
    >
      {!hideButton && (
        <label
          htmlFor={name}
          className="btn-white btn-sm border-primary text-primary hover:bg-primary hover:text-white hover:border-primary transition-all duration-250 delay-75 mr-5"
        >
          <i className="ri-upload-line mr-1"></i>
          Upload
        </label>
      )}
      {attachment.signedId && status === "initial" ? (
        <AttachmentDisplay metadata={attachment.metadata} cleanFile={cleanFile}>
          <img
            src={attachment.imageUrl}
            className="w-9 h-9 rounded-lg bg-cover mr-3.5"
          ></img>
        </AttachmentDisplay>
      ) : (
        <>
          {status === "initial" && (
            <span className="ml-5 text-dark-50 text-14">
              File size limit: 50MB
            </span>
          )}
          {status === "uploading" && (
            <div className="flex-1 max-w-full ml-4 mb-1.5 p-0.5 h-2 border border-dark-25 rounded">
              <div
                className="h-0.5 rounded-sm bg-primary transition"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
          {status === "uploaded" && (
            <AttachmentDisplay metadata={metadata} cleanFile={cleanFile}>
              {preview}
            </AttachmentDisplay>
          )}
        </>
      )}
      <input
        id={name}
        type="file"
        style={{ display: "none" }}
        onChange={uploadFile}
      />
    </div>
  );
}

export default FilePicker;
