import * as React from "react";
import { useContext } from "react";
import { Formik, Form } from "formik-latest";
import ResearchDocumentFormContext from "./ResearchDocumentFormContext";
import AuthorListCreator from "./AuthorListCreator";

function ResearchDocumentFormStep2({ authors, authorTypes }) {
  const {
    handlePrev,
    handleNext,
    researchValues,
    setResearchValues,
    fileFormRef,
    filePickerState,
  } = useContext(ResearchDocumentFormContext);

  const handleBackButton = values => {
    setResearchValues({ ...researchValues, ...values });
    handlePrev();
  };
  const handleSubmit = (values: any) => {
    const existFile =
      filePickerState === "uploading" || filePickerState === "uploaded";
    if (!existFile) {
      fileFormRef.current.handleSubmit();
    } else {
      setResearchValues({ ...researchValues, ...values });
      if (filePickerState === "uploading" || filePickerState === "uploaded") {
        handleNext();
      }
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={researchValues}>
      {({ values, setFieldValue }) => (
        <Form>
          <AuthorListCreator
            onAddAuthor={(author, previous) => {
              const valueKey = author.external ? "externalAuthors" : "authors";
              setFieldValue(valueKey, [
                ...previous.filter(x => x.external == author.external),
                author,
              ]);
            }}
            onRemoveAuthor={author => {
              const valueKey = author.external ? "externalAuthors" : "authors";
              const remainingAuthors = values[valueKey].filter(
                x => x.userId !== author.userId,
              );
              setFieldValue(valueKey, remainingAuthors);
            }}
            initialSelectedAuthors={researchValues["authors"].concat(
              researchValues["externalAuthors"],
            )}
            authors={authors}
            authorTypes={authorTypes}
          />
          <div className="fixed bottom-0 left-0 py-5 bg-tint w-full flex items-center border-t">
            <section className="flex items-center justify-center w-full space-x-2.5">
              <button
                type="button"
                className="btn-white btn-sm"
                onClick={() => handleBackButton(values)}
              >
                Back
              </button>
              <button
                className="btn-primary btn-sm"
                data-cy="cy-step-2"
                type="submit"
              >
                Next Step
              </button>
            </section>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResearchDocumentFormStep2;
