import React from "react";
import {
  LineChart,
  Line,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { isEmpty } from "lodash";

const COLORS = ["#685BC7", "#FBBF24", "#17B583", "#3B82F6", "#4D90B7"];

const CustomTooltip = ({
  active,
  payload,
  dataLines,
  xDataKey,
}: {
  active?: boolean;
  payload?: any;
  dataLines: any[];
  xDataKey: string;
}) => {
  if (active && !isEmpty(payload)) {
    const payloadItem = payload[0].payload;
    return (
      <div className="border flex bg-white border-bg-tinti py-2 px-3">
        <div className="font-meta">
          <p className="font-bold mb-2">{`Year ${payloadItem[xDataKey]}`}</p>
          {dataLines.map((dataLine, index) => (
            <p key={index}>
              {`${dataLine.dataLabel}: `}
              <span className="font-bold">{payloadItem[dataLine.dataKey]}</span>
            </p>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

function InsightLineChart({
  data,
  dataLines,
  xDataKey,
  showLegend = true,
}: {
  data: any[];
  dataLines: any[];
  xDataKey: string;
  showLegend?: boolean;
}) {
  return (
    <ResponsiveContainer width="100%" height={202}>
      <>
        {showLegend && (
          <ul className="mt-4 mb-6 text-15">
            {dataLines.map((dataLine, index) => (
              <li key={index} className="flex justify-between mb-3">
                <div>
                  <span style={{ color: COLORS[index] }}>●</span>
                  <span className="ml-1">{dataLine.dataLabel}</span>
                </div>
                <div>{dataLine.totalCount}</div>
              </li>
            ))}
          </ul>
        )}
        <LineChart
          width={340}
          height={200}
          data={data}
          margin={{ top: 0, left: -30, right: 10, bottom: 0 }}
        >
          <CartesianGrid stroke="#CFD3DA99" strokeDasharray="3 3" />
          <XAxis dataKey={xDataKey} stroke="#8590A1" />
          <YAxis stroke="#8590A1" style={{ fontSize: 12 }} />
          <Tooltip
            content={
              <CustomTooltip xDataKey={xDataKey} dataLines={dataLines} />
            }
          />
          {dataLines.map((dataLine, index) => (
            <Line
              key={index}
              dataKey={dataLine.dataKey}
              stroke={dataLine.color || COLORS[index]}
              isAnimationActive={false}
            />
          ))}
        </LineChart>
      </>
    </ResponsiveContainer>
  );
}

export default InsightLineChart;
