import * as React from "react";
import { format } from "date-fns";
import projectPhotoPlaceholder from "@/images/project-photo-placeholder.png";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import clsx from "clsx";
import { Link } from "react-router-dom";

function ProjectItem({ project, focusAreas }) {
  const banner = project.banner || projectPhotoPlaceholder;
  const focusAreasItem = focusAreas.filter(focusArea =>
    project.focus_areas?.includes(parseInt(focusArea.id)),
  );

  return (
    <div className="flex space-x-5 w-full">
      <div className="w-50 h-114px hidden lg:block">
        <img
          src={banner}
          className={clsx(
            project.banner ? "object-cover w-full" : "object-fit h-full",
            "rounded",
          )}
        />
      </div>
      <div className="space-y-1 flex flex-col max-w-lg">
        <div className="text-dark-75 font-label">PROJECT</div>
        <Link to={project.profile} className="font-h3 link-primary">
          {project.firstValue}
        </Link>
        <FocusAreaTags focusAreas={focusAreasItem} />
      </div>
    </div>
  );
}

function InventionItem({ invention }) {
  const formattedDate = invention.review_status_date
    ? ` ● ${format(new Date(invention.review_status_date), "LLL d, YYY")}`
    : " --";
  const formattedLicensedDate = invention.licensed_on
    ? ` ● ${format(new Date(invention.licensed_on), "LLL d, YYY")}`
    : "";
  return (
    <div>
      <div className="text-dark-75 font-label">INVENTION</div>
      <Link to={invention.profile} className="font-h3 mt-5px link-primary">
        {invention.firstValue}
      </Link>
      <div className="font-meta text-dark-75 mt-2.5">
        <span
          className={clsx(
            "inline-flex items-center px-3 py-0.5 rounded-full uppercase font-label",
            invention.review_status === "accepted"
              ? "bg-secondary-light text-secondary-dark"
              : "bg-lines text-dark-75",
          )}
        >
          {`${invention.review_status}${formattedDate}`}
        </span>
        <span className="inline-flex items-center px-3 py-0.5 rounded-full uppercase font-label bg-lines ml-2">
          {invention.is_licensed
            ? `Licensed${formattedLicensedDate}`
            : "Unlicensed"}
        </span>
      </div>
    </div>
  );
}

function ResearchItem({ research }) {
  return (
    <div>
      <div className="text-dark-75 font-label">RESEARCH</div>
      <Link to={research.profile} className="font-h3 mt-5px link-primary">
        {research.firstValue}
      </Link>
      <div className="font-meta text-dark-75 mt-2.5">
        <span
          className={clsx(
            "inline-flex items-center px-3 py-0.5 rounded-full uppercase font-label",
            research.status === "Published or presented"
              ? "bg-secondary-light text-secondary-dark"
              : "bg-lines text-dark-75",
          )}
        >
          {research.status}
        </span>
      </div>
    </div>
  );
}

function ResourceItem({ resource }) {
  return (
    <div className="flex space-x-4">
      <div className="text-white rounded-full w-10 h-10 bg-dark-50 items-center flex justify-center">
        {resource.type === "LinkResource" ? (
          <i className="ri-global-line text-xl"></i>
        ) : (
          <i className="ri-file-line text-xl"></i>
        )}
      </div>
      <div className="w-5/6">
        <Link
          to={resource.profile}
          className="link text-dark-100 font-body-bold"
        >
          {resource.firstValue}
        </Link>
        <div className="font-meta text-dark-75">{resource.description}</div>
      </div>
    </div>
  );
}

function MemberItem({ member }) {
  return (
    <div className="flex space-x-4">
      <img
        className="object-cover h-10 w-10 rounded-full"
        src={member.avatar}
      />
      <div>
        <Link to={member.profile} className="link font-body-bold text-dark-100">
          {member.firstValue}
        </Link>
        <div className="font-meta text-dark-75">{member.secondValue}</div>
      </div>
    </div>
  );
}

function DailyNewsItem({ newsItem }) {
  const publishedAt = format(new Date(newsItem.publishedAt), "LLL d, YYY");
  return (
    <div>
      <div className="text-dark-75 font-label">DAILY NEWS</div>
      <Link to={newsItem.profile} className="font-h3 mt-5px link-primary">
        {newsItem.firstValue}
      </Link>
      <div className="font-body text-dark-75 mt-5px">{publishedAt}</div>
    </div>
  );
}

function SearchItem({ item, type, focusAreas }) {
  switch (type) {
    case "members":
      return <MemberItem member={item} />;
    case "projects":
      return <ProjectItem project={item} focusAreas={focusAreas} />;
    case "research":
      return <ResearchItem research={item} />;
    case "resources":
      return <ResourceItem resource={item} />;
    case "inventions":
      return <InventionItem invention={item} />;
    case "daily_news_feed":
      return <DailyNewsItem newsItem={item} />;
    default:
      return <div />;
  }
}

export { SearchItem };
