import * as React from "react";

function ErrorFallback() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <p>Something really weird happend while loading PICI</p>
    </div>
  );
}

export default ErrorFallback;
