import * as React from "react";
import { createContext, useContext } from "react";
import { useLayer, Arrow, useHover } from "react-laag";
import clsx from "clsx";

type TooltipContextType = {
  open: boolean;
  triggerProps: any;
  renderLayer: any;
  layerProps: any;
  arrowProps: any;
  hoverProps: any;
};

const TooltipContext = createContext<TooltipContextType>({
  open: false,
  triggerProps: null,
  renderLayer: null,
  layerProps: null,
  arrowProps: null,
  hoverProps: null,
});

function Tooltip({ children }) {
  const [isOver, hoverProps] = useHover();

  const { renderLayer, triggerProps, arrowProps, layerProps } = useLayer({
    isOpen: isOver,
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: "top-center", // we prefer to place the menu "top-start"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });
  return (
    <TooltipContext.Provider
      value={{
        open: isOver,
        renderLayer,
        triggerProps,
        layerProps,
        arrowProps,
        hoverProps,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
}

function Content({ children, className = "" }) {
  const { open, renderLayer, arrowProps, layerProps } = useContext(
    TooltipContext,
  );
  return (
    <div>
      {open &&
        renderLayer(
          <div
            {...layerProps}
            className={clsx(
              "bg-dark-100 text-white font-meta rounded p-4 w-60",
              className,
            )}
          >
            {children}
            <Arrow {...arrowProps} backgroundColor="#1B2638" size={10} />
          </div>,
        )}
    </div>
  );
}

function Trigger({ children, className = "", ...props }) {
  const { triggerProps, hoverProps } = useContext(TooltipContext);

  return (
    <button
      className={clsx("flex items-center", className)}
      {...triggerProps}
      {...hoverProps}
      {...props}
    >
      {children}
    </button>
  );
}

Tooltip.Content = Content;
Tooltip.Trigger = Trigger;

export default Tooltip;
