import * as React from "react";
import { Link } from "react-router-dom";

const Skeleton = () => {
  return (
    <section data-testid="monthlyActivitySkeleton">
      <div className="mb-5 w-1/3 h-7.5 bg-dark-50 rounded-full animate-pulse" />
      <div className="flex flex-col lg:flex-row justify-between gap-5">
        {[1, 2, 3].map(x => (
          <div
            key={x}
            className="h-28 py-5 px-4 lg:p-6 flex-1 border border-dark-25 rounded-lg animate-pulse"
          >
            <div className="opacity-20 flex flex-col gap-1">
              <div className="h-6 w-1/3 bg-dark-25 rounded-full"></div>
              <div className="h-9 w-4/5 bg-dark-25 rounded-full"></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

function MonthlyActivity({ isLoading, activity }) {
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <section>
          <h2 className="font-h3 lg:font-h2 text-dark-100 mb-5">
            Activity This Month
          </h2>
          <div className="flex flex-col lg:flex-row gap-5 justify-between">
            {activity?.map((item, index) => (
              <div
                key={index}
                className="w-full py-5 px-4 lg:p-6 border border-dark-25 rounded-lg"
              >
                <Link
                  to={item.link}
                  className="link font-body text-dark-75 mb-1"
                >
                  {item.title}
                </Link>
                <p className="font-h1 text-dark-100">{item.count}</p>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

export default MonthlyActivity;
