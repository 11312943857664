import React from "react";
import {
  BarChart,
  Bar,
  Tooltip,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import CustomTooltip from "@/components/shared/ChartsTooltipWithTotal";
import COLORS from "./Colors";

const CHART_WIDTH = 900;
const CHART_HEIGHT = 500;

const YTick = props => {
  return (
    <text {...props} textAnchor="start">
      <tspan dx="-36px" dy="0.335em">
        {props.payload.value}
      </tspan>
    </text>
  );
};

function FullsizeBarVerticalChart({ data, title }) {
  const tickSize = data.length > 5 ? "13px" : "16px";
  return (
    <>
      <h2 className="font-h2 mb-2">{title}</h2>
      <BarChart
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        layout="horizontal"
        data={data}
        margin={{
          top: 18,
          bottom: 0,
          left: 36,
          right: 80,
        }}
      >
        <CartesianGrid vertical={false} stroke="#CFD3DA" />
        <YAxis axisLine={false} tickLine={false} tick={<YTick />} />
        <XAxis
          type="category"
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{
            fontFamily: "lato",
            fontWeight: 700,
            fontSize: tickSize,
            fill: "#1B2638",
            fontVariant: "all-small-caps",
          }}
          interval={0}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        {data && (
          <Bar
            dataKey="count"
            barSize={72}
            background={{ fill: "#eaecf0" }}
            isAnimationActive={false}
          >
            {data.map((el, index) => (
              <Cell key={el.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        )}
      </BarChart>
    </>
  );
}

export default FullsizeBarVerticalChart;
