import * as React from "react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { denormalizedFetcher } from "@/utils/api";
import UsersList from "@/components/shared/UsersList";
import Filters from "./Filters";
import paramBuilder from "@/utils/network/paramBuilder";
import NoResults from "@/components/shared/NoResults";

const fetchUsers = ({ queryKey }) => {
  const baseUrl = `/api/users`;
  const [_, centerSlug, filters] = queryKey;

  const params = paramBuilder({
    include: ["focusAreas"],
    fields: {
      users: [
        "firstName",
        "lastName",
        "createdAt",
        "updatedAt",
        "fullName",
        "role",
        "title",
        "centerId",
        "centerNameOrInstitution",
        "email",
        "focusAreas",
        "slug",
        "centerSlug",
        "isCenterContact",
        "centerContactRole",
        "suspended",
      ],
      focusAreas: ["name"],
    },
    filter: { centerSlug: [centerSlug], suspended: [false] },
  });
  const url = `${baseUrl}?${params}&${filters}`;
  return denormalizedFetcher(url);
};

const MembersSection = ({ members }) => {
  const leadershipTeam = members.filter(
    member => member.attributes.isCenterContact,
  );
  const users = members.filter(member => !member.attributes.isCenterContact);

  return (
    <section>
      {leadershipTeam.length > 0 && (
        <div className="mb-10">
          <h3 className="font-h2 mb-5">
            Leadership Team
            <span className="pl-1 font-h3 text-dark-50">
              ({leadershipTeam.length})
            </span>
          </h3>
          <UsersList members={leadershipTeam} showCenter={false} />
        </div>
      )}
      <div>
        <h3 className="font-h2 mb-5">
          Members
          <span className="pl-1 font-h3 text-dark-50">({users.length})</span>
        </h3>
        <UsersList members={users} showCenter={false} />
      </div>
    </section>
  );
};

const LoadingMember = () => {
  return (
    <div className="animate-pulse flex space-x-4 px-4 py-4 sm:px-6">
      <div className="rounded-full bg-dark-25 h-10 w-10"></div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
          <div className="h-4 bg-dark-25 rounded-full w-3/4 sm:w-1/2"></div>
          <div className="h-4 bg-dark-25 rounded-full w-1/3"></div>
        </div>
      </div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
          <div className="h-4 bg-dark-25 rounded-full w-full sm:w-2/3"></div>
          <div className="h-4 bg-dark-25 rounded-full w-3/5 sm:w-1/3"></div>
        </div>
      </div>
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2 flex flex-col items-end">
          <div className="h-4 bg-dark-25 rounded-full w-1/3"></div>
          <div className="h-4 bg-dark-25 rounded-full w-1/2"></div>
        </div>
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <>
      <h3 className="text-byte-dark font-h3 mb-5">Retrieving members...</h3>
      <div className="overflow-x-auto border border-lines rounded-lg">
        <div className="bg-white table-shadow overflow-hidden sm:rounded-lg w-585px sm:w-full">
          <div className="divide-y divide-gray-25 border-gray-25">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => (
              <LoadingMember key={x} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

function MembersTab() {
  const { slug } = useParams();
  const [filters, setFilters] = useState("");
  const {
    isLoading,
    data: members,
  }: { isLoading: boolean; data: any } = useQuery(
    ["users", slug, filters],
    fetchUsers,
  );

  return (
    <section className="font-lato flex flex-col">
      <div className="flex w-full flex-col lg:flex-row-reverse">
        <section className="w-full lg:mt-0">
          <section>
            <Filters setFilters={setFilters} />
          </section>
          <section>
            {isLoading && <Skeleton />}
            {members?.data.length > 0 && (
              <MembersSection members={members?.data} />
            )}
            {!isLoading && members?.data.length === 0 && (
              <NoResults entityName="members" icon="ri-user-3-line" />
            )}
          </section>
        </section>
      </div>
    </section>
  );
}

export default MembersTab;
