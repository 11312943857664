import React from "react";
import clsx from "clsx";
import Table from "@/components/shared/Table";
import {
  LinkableNameColumn,
  ActionButtonsColumn,
  render,
} from "@/components/shared/Admin/Columns";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import { format } from "date-fns";

const PROJECT_STATUS = {
  enrollment_complete: "Enrollment Complete",
  enrolling_patients: "Enrolling Patients",
  in_development: "In Development",
  pending_activation: "Pending Activation",
};

export const itemValue = (item, column) => {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item[column.name];
  }
};
const columns = [
  {
    name: "identifier",
    label: "Project ID",
    th: {
      width: "140px",
      className: "!px-3 border-b border-lines text-11 pb-2",
    },
    td: { className: "pt-9px pb-9px !px-5" },
    width: "140px",
    render: (value, item) => (
      <LinkableNameColumn
        text={value}
        classNameLink="text-dark-100 hover:text-primary font-bold"
        to={`/projects/${item.slug}`}
        isBlank
      />
    ),
  },
  {
    name: "codename",
    label: "Code Name",
    td: { className: "break-words tracking-wider !px-5" },
    width: "140px",
    render: (value, item) =>
      value ? (
        <LinkableNameColumn
          className="!inline"
          classNameLink="text-dark-100 hover:text-primary whitespace-normal"
          text={value}
          to={`/projects/${item.slug}`}
          isBlank
        />
      ) : (
        "-"
      ),
  },
  {
    label: "Status",
    name: "trial_status",
    td: { className: "break-words text-14 !px-5 text-dark-100" },
    width: "150px",
    render: value => (
      <div className="inline whitespace-normal">{PROJECT_STATUS[value]}</div>
    ),
  },
  {
    label: "Project Manager",
    name: "lead",
    td: { className: "!px-5 text-dark-100" },
    width: "200px",
    render: value =>
      value && (
        <a
          href={`/profile/${value?.slug}`}
          target="_BLANK"
          data-tip={value?.slug}
          className="whitespace-normal"
        >
          <div className="flex items-center">
            <img
              src={value?.avatar_url}
              className="h-6 w-6 rounded-full align-middle mr-2"
            />
            <span>{value?.full_name}</span>
          </div>
        </a>
      ),
  },
  {
    name: "updated_at",
    label: "Last Update",
    td: { className: "pt-9px pb-9px !px-5 text-dark-100" },
    width: "130px",
    render: (value, item) => (
      <span
        className={clsx("font-meta font-semibold", {
          "text-red-350": item.is_outdated,
        })}
      >
        {value && format(new Date(value), "MMMM yyyy")}
      </span>
    ),
  },
  {
    label: "Actions",
    td: { className: "pt-9px pb-9px !px-5" },
    accessor: item => item,
    width: "175px",
    render: value => {
      let buttonAction: ActionButtonItemType = {
        text: "Create Update",
        to: `/admin/projects/${value.slug}/periodic_updates/new`,
        name: value.slug,
        variant: "btn-primary",
        isBlank: true,
      };
      if (
        value.current_periodic_update &&
        value.current_periodic_update.send_at
      ) {
        buttonAction = {
          text: "View Update",
          to: `/admin/projects/${value.slug}/periodic_updates`,
          name: value.slug,
          variant: "btn-white",
          isBlank: true,
        };
      } else if (
        value.current_periodic_update &&
        !value.current_periodic_update.empty_draft
      ) {
        buttonAction = {
          text: "Edit Draft",
          to: `/admin/projects/${value.slug}/periodic_updates/new`,
          name: value.slug,
          variant: "btn-warning",
          isBlank: true,
        };
      }
      const items: ActionButtonItemType[] = [buttonAction];
      return <ActionButtonsColumn items={items} />;
    },
  },
];

function CurrentClinicalTrials({ data, className }) {
  return (
    <div
      className={clsx("bg-white border border-lines rounded shadow", className)}
    >
      <header className="px-5 py-3">
        <h2 className="text-dark-100 text-22">
          Current Clinical Trials{" "}
          <span className="text-dark-50 ml-1.5 text-14">
            (Trials that are not closed)
          </span>
        </h2>
      </header>
      <Table className="border border-transparent mb-8 px-5">
        <Table.Header className="bg-transparent text-dark-50">
          {columns.map((column, i) => (
            <Table.HeaderCell
              width={column.width}
              key={i}
              className={"text-11 pb-2 border-b border-lines"}
              {...column.th}
            >
              {column.label}
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body>
          {data.map((item, index) => (
            <Table.Row
              key={index}
              className={clsx("!border-0", { "bg-tint-10": index % 2 })}
            >
              {columns.map((column, columnIndex) => (
                <Table.Cell
                  key={columnIndex}
                  width={column.width}
                  className={column.td?.className}
                  expand
                >
                  {render(itemValue(item, column), column, item)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default CurrentClinicalTrials;
