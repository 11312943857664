import React, { useEffect, useState } from "react";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import clsx from "clsx";
import ButtonIcon from "@/components/shared/ButtonIcon";

function ArticlesSlideshow({ values, onChange, className = "" }) {
  const [currentIndexSlide, setCurrentIndexSlide] = useState<number>(0);
  const [currentImg, setCurrentImg] = useState<string>(
    values.slideshowImagesUrl[currentIndexSlide],
  );

  const handleAddNewSlide = () => {
    const currentSize = values.slideshowImages.length;
    onChange("slideshowImages", [...values.slideshowImages, null]);
    onChange("slideshowImagesUrl", [...values.slideshowImagesUrl, null]);
    setCurrentIndexSlide(currentSize);
  };

  const handleDeleteSlide = () => {
    if (values.slideshowImages[currentIndexSlide]) {
      const slideImagesEdited = [...values.slideshowImages];
      const slidesPreviewEdited = [...values.slideshowImagesUrl];
      slideImagesEdited[currentIndexSlide] = null;
      slidesPreviewEdited[currentIndexSlide] = null;
      onChange("slideshowImages", slideImagesEdited);
      onChange("slideshowImagesUrl", slidesPreviewEdited);
    } else {
      if (values.slideshowImages.length > 1) {
        const newIndex =
          currentIndexSlide === values.slideshowImages.length - 1
            ? currentIndexSlide - 1
            : currentIndexSlide;
        const slideImagesEdited = [
          ...values.slideshowImages.filter(
            (_, index) => index !== currentIndexSlide,
          ),
        ];
        const slideImagesUrlEdited = [
          ...values.slideshowImagesUrl.filter(
            (_, index) => index !== currentIndexSlide,
          ),
        ];
        onChange("slideshowImages", slideImagesEdited);
        onChange("slideshowImagesUrl", slideImagesUrlEdited);
        setCurrentIndexSlide(newIndex);
      }
    }
  };

  const handleAddSigned = signedId => {
    onChange("slideshowImages", [
      ...values.slideshowImages.slice(0, -1),
      signedId,
    ]);
  };

  const handlePreviousImage = () => {
    if (currentIndexSlide > 0) {
      setCurrentIndexSlide(currentIndexSlide - 1);
    }
  };

  const handleNexImage = () => {
    if (currentIndexSlide < values.slideshowImages.length - 1) {
      setCurrentIndexSlide(currentIndexSlide + 1);
    }
  };

  const isPendingAttachImage = values.slideshowImages.includes(null);
  const disableDelete =
    values.slideshowImages.length == 1 && values.slideshowImages[0] === null;
  const disablePrevious =
    currentIndexSlide === 0 || !values.slideshowImages[currentIndexSlide];
  const disableNext =
    currentIndexSlide === values.slideshowImages.length - 1 ||
    !values.slideshowImages[currentIndexSlide];

  useEffect(() => {
    setCurrentImg(values.slideshowImagesUrl[currentIndexSlide]);
  }, [currentIndexSlide, values.slideshowImagesUrl]);
  return (
    <div
      className={clsx(
        "slideshow bg-dark-100 h-[440px] p-3 bg-cover",
        className,
      )}
      style={{
        backgroundImage: `url("${currentImg}")`,
      }}
    >
      <div className="flex">
        <div className="flex-1">
          <ButtonIcon
            title="Add new Slide Image"
            className="btn-white btn-md h-8 px-2"
            disabled={isPendingAttachImage}
            onClick={handleAddNewSlide}
            iconClassName="ri-image-add-fill"
          />
          <ButtonIcon
            title="Remove Image/Slide"
            className="btn-white btn-md h-8 px-2"
            disabled={disableDelete}
            onClick={handleDeleteSlide}
            iconClassName="ri-delete-bin-line"
          />
        </div>
        <div className="flex">
          <ButtonIcon
            title="Previous Slide"
            className="btn-white btn-md h-8 px-2"
            disabled={disablePrevious}
            onClick={handlePreviousImage}
            iconClassName="ri-arrow-left-s-line"
          />
          <span className="bg-white my-1 mx-2 px-2 rounded-md font-meta-bold text-dark-100 text-base">
            {currentIndexSlide + 1}/{values.slideshowImages.length}
          </span>
          <ButtonIcon
            title="Next Slide"
            className="btn-white btn-md h-8 px-2"
            disabled={disableNext}
            onClick={handleNexImage}
            iconClassName="ri-arrow-right-s-line"
          />
        </div>
      </div>
      <div className="flex justify-center mt-36">
        {!values.slideshowImages[currentIndexSlide] && (
          <FilePickerAdmin
            className="w-80"
            setSignedId={signedId => handleAddSigned(signedId)}
            attachment={{
              signedId: values.coverImage,
              metadata: values.coverImageMetadata,
              imageUrl: values.coverImageUrl,
            }}
            onLoadFile={value => {
              const val = [...values.slideshowImagesUrl];
              val[currentIndexSlide] = value.result;
              onChange("slideshowImagesUrl", val);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ArticlesSlideshow;
