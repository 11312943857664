const preferences = ({ isStaff, centerId, centerNameOrInstitution }) => {
  return [
    {
      section: "General",
      visible: true,
      items: [
        { name: "notifyDailyNewsFeed", label: "Daily News Feed" },
        { name: "notifyNewAnnouncementPost", label: "PICI Announcements" },
        {
          name: "notifyMonthlyDigest",
          label: "Monthly summary of member center activity",
        },
      ],
    },
    {
      section: "Members",
      visible: isStaff,
      items: [
        {
          name: "notifyNewUserJoinedPici",
          label: "A new member joins the PICI network",
        },
        {
          name: "notifyNonStaffAcountsChanges",
          disabled: true,
          label:
            "Status changes to Non-Staff accounts (e.g. suspension or reactivation)",
        },
        {
          name: "notifyStaffAcountsChanges",
          disabled: true,
          label:
            "The status of a staff user's account is changed (e.g. suspension or reactivation)",
        },
        {
          name: "notifyCenterMemberAcountsChanges",
          label: `Status changes of ${centerNameOrInstitution} Researcher accounts (e.g. suspension or reactivation)`,
          hidden: !centerId,
        },
      ],
    },
    {
      section: "Projects",
      visible: true,
      items: [
        {
          name: "notifyNewResourceCreated",
          label: "A new resource is posted",
        },
        {
          name: "notifyAllClinicalUpdates",
          label: "All clinical trial updates",
          disabled: true,
        },
      ],
    },
    {
      section: "Research",
      visible: true,
      items: [
        {
          name: "notifyNewResearchDocumentUploaded",
          label: "A member posts new research",
        },
        {
          name: "notifyResearchDocumentDailyDigest",
          label: "Daily digest of who downloads your research",
        },
        {
          name: "notifyResearchDocumentComment",
          label: "A member comments on your research",
        },
        {
          name: "notifyResearchDocumentOtherCommunications",
          label: "Other research related Member Center communications",
        },
      ],
    },
    {
      section: "Inventions",
      visible: isStaff,
      items: [
        {
          name: "notifyNewInventionCreated",
          label: "A new invention is submitted",
        },
        {
          name: "notifyInventionReviewUpdated",
          label: "A new invention has been accepted or declined",
        },
        {
          name: "notifyWeeklyIpSummary",
          label: "Weekly IP Summary",
        },
      ],
    },
  ];
};

export default preferences;
