import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import paramBuilder from "@/utils/network/paramBuilder";
import { fetcher } from "@/utils/api";
import EnrollmentTable from "@/components/shared/EnrollmentTable/EnrollmentTable";

const fetchPeriodicUpdate = ({ queryKey }) => {
  const id = queryKey[1];
  if (id) {
    const baseUrl = `/api/periodic-updates/${id}`;
    const params = paramBuilder({
      fields: {
        periodicUpdates: [
          "highlights",
          "updatedAt",
          "currentSites",
          "currentPatientsEnrolled",
          "targetPatientsEnrolled",
          "screenFailRate",
          "totalCohorts",
        ],
      },
    });
    const url = `${baseUrl}?${params}`;
    return fetcher(url);
  }
};

function ClinicalProgressTab({ project }) {
  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery(
    ["periodicUpdate", project.attributes.lastPeriodicUpdate.id],
    fetchPeriodicUpdate,
  );

  const periodicUpdate = data?.data;
  const updatedAt = periodicUpdate?.attributes.updatedAt;
  const formattedUpdatedAt =
    updatedAt && format(parseISO(updatedAt), "MMMM dd, yyyy");
  const currentSites = periodicUpdate?.attributes.currentSites;
  const activeSites = currentSites?.filter(site =>
    site.status.includes("Active"),
  );
  const currentPatientsEnrolled =
    periodicUpdate?.attributes.currentPatientsEnrolled;
  const screenFailRate = periodicUpdate?.attributes.screenFailRate;

  return (
    !isLoading &&
    periodicUpdate && (
      <>
        <h3 className="font-h3 text-dark-100 mb-5">
          <span className="inline-block mr-1">Patient Enrollment</span>
          <span className="inline-block">
            (Last Updated: {formattedUpdatedAt})
          </span>
        </h3>
        <div className="sm:flex space-y-5 sm:space-y-0 sm:space-x-5 mb-7.5 sm:mb-10">
          <div className="border border-dark-25 rounded-lg px-4 py-5 sm:p-6 flex-1">
            <p className="font-body text-dark-75 mb-1">
              Active Sites (out of {currentSites.length})
            </p>
            <p className="font-h1 text-dark-100">{activeSites.length}</p>
          </div>
          <div className="border border-dark-25 rounded-lg px-4 py-5 sm:p-6 flex-1">
            <p className="font-body text-dark-75 mb-1">Patients Enrolled</p>
            <p className="font-h1 text-dark-100">{currentPatientsEnrolled}</p>
          </div>
          <div className="border border-dark-25 rounded-lg px-4 py-5 sm:p-6 flex-1">
            <p className="font-body text-dark-75 mb-1">Screen Failure Rate</p>
            <p className="font-h1 text-dark-100">
              {screenFailRate ? `${screenFailRate}%` : "---"}
            </p>
          </div>
        </div>
        <EnrollmentTable {...{ periodicUpdate }} />
      </>
    )
  );
}

export default ClinicalProgressTab;
