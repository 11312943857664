import * as React from "react";
import clsx from "clsx";
import { FilterOption } from "@/types";
import Checkbox from "@/components/shared/Checkbox";

const _accessor = option => option.name;

function CheckboxGroup({
  options,
  selectedOptions,
  setSelectedOptions,
  onCheckboxChange,
  accessor = _accessor,
  className = "",
  labelClassName = "",
  children = null,
}: {
  options: FilterOption[];
  selectedOptions: FilterOption[];
  setSelectedOptions: (options: any) => void;
  onCheckboxChange?: (option: FilterOption) => void;
  accessor?: (option: any) => any;
  className?: string;
  labelClassName?: string;
  children?: React.ReactNode;
}) {
  const isOptionSelected = option => {
    const selectedOptionsIds = selectedOptions.map(option => option.id);
    return selectedOptionsIds.includes(option.id);
  };

  const _onCheckboxChange = option => {
    if (isOptionSelected(option)) {
      setSelectedOptions(selectedOptions.filter(item => item.id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const onChange = onCheckboxChange || _onCheckboxChange;

  const handleKeyDown = (e, option) => {
    if (e.key === "Enter") {
      onChange(option);
    }
  };

  return (
    <div className={className}>
      {options?.map(option => (
        <Checkbox
          key={option.id}
          checked={isOptionSelected(option)}
          onChange={() => onChange(option)}
          onKeyDown={e => handleKeyDown(e, option)}
          className={clsx("py-2 px-4", labelClassName)}
        >
          {accessor(option)}
        </Checkbox>
      ))}
      {children}
    </div>
  );
}
export default CheckboxGroup;
