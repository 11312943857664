import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import useToast from "@/hooks/useToast";
import PopoverMenu from "@/components/shared/PopoverMenu";

const fetchResource: any = ({ queryKey = [] }) => {
  const slug = queryKey[1];
  const baseUrl = `/api/resources/${slug}`;
  const resourceParams = paramBuilder({
    include: ["category"],
    fields: {
      resources: [
        "title",
        "categoryId",
        "category",
        "fileSize",
        "fileMetadata",
        "fileUrl",
        "description",
        "type",
        "link",
        "titleUrl",
      ],
      categories: ["name", "slug", "type"],
    },
  });
  const url = `${baseUrl}?${resourceParams}`;
  return denormalizedFetcher(url);
};

function ResourceItem({ resource, showToast }) {
  const categoryName = resource?.relationships.category.data.attributes.name;
  const { title, description, fileMetadata, fileSize } = resource.attributes;
  const resourceLink =
    resource.type === "linkResources"
      ? resource.attributes.link
      : resource.attributes.fileUrl;

  return (
    <article className="max-w-screen-lg m-auto flex flex-col items-center">
      <figure className="flex-shrink-0 w-10 h-10 bg-dark-50 rounded-full text-white grid place-items-center">
        {resource.type === "linkResources" ? (
          <i className="ri-global-line text-xl"></i>
        ) : (
          <i className="ri-file-line text-xl"></i>
        )}
      </figure>
      <div className="mt-5 font-bold text-xs tracking-wider uppercase text-dark-75">
        {categoryName}
      </div>
      <div className="mt-1.5 font-bold text-xl">{title}</div>
      {description && (
        <div className="mt-2.5 max-w-screen-sm text-gray-400 px-2">
          {description}
        </div>
      )}
      {fileMetadata.formatedType && fileSize !== "N/A" && (
        <div className="uppercase text-dark-75 text-sm mt-2">
          <span>{fileMetadata.formatedType}</span> <span>{fileSize}</span>
        </div>
      )}
      <div className="mt-9 flex">
        <div>
          <a
            className="btn-white btn-sm flex gap-1"
            href={resourceLink}
            target="_blank"
          >
            {resource.type === "linkResources" ? (
              <>
                View <i className="ri-links-fill font-normal text-dark-50" />
              </>
            ) : (
              <>
                Download{" "}
                <i className="ri-download-line font-normal text-dark-50" />
              </>
            )}
          </a>
        </div>
        <div className="lg:relative">
          <PopoverMenu resource={resource} {...{ showToast }}>
            <button className="ml-2.5 btn-white btn-sm flex gap-1">
              Shared
              <i className="ri-links-fill font-normal text-dark-50" />
            </button>
          </PopoverMenu>
        </div>
      </div>
    </article>
  );
}

function ResourceDetail() {
  const { slug } = useParams();
  const {
    isLoading,
    data: resource,
  }: { isLoading: boolean; data: any } = useQuery(
    ["resource", slug],
    fetchResource,
  );
  const showToast = useToast({
    successText: (
      <>
        A link to the resource has been copied to your clipboard. <br />
        Click ⌘ + v or Ctrl + v to paste.
      </>
    ),
    errorText: "Could not copy the resource link to your clipboard.",
  });

  return (
    <section className="margin-safe font-lato h-full flex bg-white relative">
      {!isLoading && (
        <ResourceItem resource={resource.data} {...{ showToast }} />
      )}
    </section>
  );
}

export default ResourceDetail;
