import * as React from "react";
import * as Yup from "yup";
import clsx from "clsx";
import DatePicker from "react-datepicker";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik-latest";
import { saveResource } from "@/utils/api";
import { format } from "date-fns";
import useFlashMessage from "@/hooks/useFlashMessage";
import SlideOver from "@/components/shared/SlideOver/SlideOver";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";
import UserPicker from "@/components/shared/UserPicker";
import RichTextEditor from "@/components/shared/RichTextEditor/RichTextEditor";
import Input from "@/components/shared/Input";
import FormBlock from "@/components/shared/FormBlock";
import RadioButtonGroup from "@/components/shared/RadioButtonGroup";
import { isEqual } from "lodash";

const typeOptions = [
  { id: "NewsLink", name: "Link" },
  { id: "NewsFile", name: "File" },
  { id: "NewsStory", name: "Text" },
];

const ContentSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Title is required."),
  type: Yup.string().required(),
  link: Yup.string().when("type", {
    is: "NewsLink",
    then: Yup.string()
      .required("Link is required.")
      .url("Link needs to be a valid URL."),
  }),
  file: Yup.mixed().when("type", {
    is: "NewsFile",
    then: Yup.string()
      .required("File is required.")
      .nullable(true),
  }),
});

const mapRequest = (item, id = null) => {
  const request = {
    data: {
      type: "news_items",
      attributes: item,
      ...(id && { id: id }),
    },
  };
  return request;
};
const mapCurrentItems = (current, newsFeedId) => {
  return {
    newsFeedId: newsFeedId,
    categoryId: current?.categoryId || null,
    description: current?.description || "",
    file: current?.file || null,
    fileMetadata: current?.fileMetadata || null,
    fileUrl: current?.fileUrl || null,
    link: current?.link || "",
    newsItemMentions: current?.newsItemMentions || [],
    newsPostedAt: current?.newsPostedAt
      ? new Date(current.newsPostedAt)
      : undefined,
    newsSource: current?.newsSource || "",
    position: current?.position || 0,
    title: current?.title || "",
    type: current?.type || typeOptions[0].id,
  };
};

function NewsItemForm({
  options,
  open,
  setOpen,
  users,
  currentNewsItem = null,
}) {
  const { id: newsFeedId } = useParams();
  const queryClient = useQueryClient();
  const isEdit = !!currentNewsItem;

  const showFlash = useFlashMessage({
    message: `Your content was ${isEdit ? "updated" : "added"}!`,
    errorMessage: `Your content could not be ${
      isEdit ? "updated" : "added"
    } due to an error.`,
  });

  const createContent = (item, { setSubmitting }) => {
    const request = mapRequest(item, currentNewsItem?.id);
    delete request.data.attributes["fileMetadata"];
    delete request.data.attributes["fileUrl"];
    saveResource("news-items", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        setOpen(false);
        queryClient.invalidateQueries(["news-feeds", newsFeedId]);
      });
  };

  const initialValues = mapCurrentItems(
    currentNewsItem?.attributes,
    newsFeedId,
  );

  return (
    <SlideOver {...{ open, setOpen }}>
      <Formik
        initialValues={initialValues}
        onSubmit={createContent}
        validationSchema={ContentSchema}
      >
        {({ values, setFieldValue, ...props }) => {
          const hasNoChanges = isEqual(values, initialValues);
          const isDisabledButton =
            props.isSubmitting || !props.isValid || hasNoChanges;

          return (
            <Form>
              <SlideOver.Header>
                <span className="text-dark-100 font-medium">
                  {currentNewsItem ? "Edit Content" : "New Content"}
                </span>
              </SlideOver.Header>

              <SlideOver.Body>
                <div className="space-y-6">
                  <fieldset>
                    <legend className="font-h3 mb-1">Content Type</legend>
                    <div className="mt-2 flex space-x-8">
                      {currentNewsItem ? (
                        <span>
                          {typeOptions.find(el => el.id == values.type)?.name}
                        </span>
                      ) : (
                        <RadioButtonGroup
                          options={typeOptions}
                          selectedOption={
                            typeOptions.find(el => el.id === values.type) ||
                            null
                          }
                          setSelectedOption={val =>
                            setFieldValue("type", val.id)
                          }
                          parentClass="flex gap-8"
                        />
                      )}
                    </div>
                  </fieldset>
                  {values.type === "NewsLink" && (
                    <div>
                      <FormBlock label="Link" name="link">
                        <Input
                          placeholder="e.g. http://www.example.com"
                          value={values.link}
                        />
                      </FormBlock>
                    </div>
                  )}
                  {values.type === "NewsFile" && (
                    <div>
                      <label className="flex font-h3 mb-1">Upload File</label>
                      <div className="mt-1">
                        <FilePickerAdmin
                          setSignedId={val => setFieldValue("file", val)}
                          attachment={{
                            signedId: values.file,
                            metadata: values.fileMetadata,
                            imageUrl: values.fileUrl,
                          }}
                        />
                        {props.touched.file && props.errors.file && (
                          <p className="mt-1 text-sm text-red-400">
                            {props.errors.file}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    <FormBlock label="Title" name="title">
                      <Input value={values.title} data-cy="item-title" />
                    </FormBlock>
                  </div>
                  <div className="sm:flex sm:justify-between sm:space-x-4">
                    <div className="w-full mb-6 sm:mb-0">
                      <FormBlock label="Source" name="newsSource" optional>
                        <Input
                          value={values.newsSource}
                          data-cy="item-source"
                        />
                      </FormBlock>
                    </div>
                    <div className="w-full">
                      <FormBlock label="Date" name="newsPostedAt" optional>
                        <DatePicker
                          id="newsPostedAt"
                          dateFormat="MMM dd, yyyy"
                          selected={
                            values.newsPostedAt
                              ? new Date(values.newsPostedAt)
                              : ""
                          }
                          onChange={date =>
                            setFieldValue(
                              "newsPostedAt",
                              format(new Date(date), "MMM dd, yyyy"),
                            )
                          }
                          className={clsx(
                            "w-full h-12 px-3.5 py-2.5 border border-dark-25 rounded-md focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary",
                            props.errors.newsPostedAt
                              ? "focus:ring-error focus:border-error ring-error border-error"
                              : "focus:ring-primary focus:border-primary",
                          )}
                          wrapperClassName="w-full"
                          autoComplete="off"
                        />
                      </FormBlock>
                    </div>
                  </div>
                  <div>
                    <FormBlock
                      label="PICI Members Mentioned"
                      name="users"
                      optional
                    >
                      <UserPicker
                        users={users?.data}
                        userTypes={options?.mentionTypes}
                        selectedUsers={values.newsItemMentions}
                        setSelectedUsers={mentions =>
                          setFieldValue("newsItemMentions", mentions)
                        }
                      />
                    </FormBlock>
                  </div>
                  <div>
                    <FormBlock label="Description" name="description" optional>
                      <RichTextEditor
                        value={values.description}
                        onChange={value => setFieldValue("description", value)}
                        placeholder="Type here..."
                      />
                    </FormBlock>
                  </div>
                </div>
              </SlideOver.Body>

              <SlideOver.Footer className="absolute bottom-0">
                <button
                  type="submit"
                  disabled={isDisabledButton}
                  className={clsx(
                    "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                    isDisabledButton
                      ? "bg-dark-25"
                      : "bg-primary hover:bg-primary-dark",
                  )}
                >
                  {currentNewsItem ? "Save changes" : "Create content"}
                </button>
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </SlideOver.Footer>
            </Form>
          );
        }}
      </Formik>
    </SlideOver>
  );
}

export default NewsItemForm;
