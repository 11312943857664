import * as React from "react";
import { useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import NotFoundPage from "@/components/App/NotFoundPage";
import Header from "./Header";
import ProjectsTab from "./ProjectsTab";
import ExperienceTab from "./ExperienceTab";
import BiographyTab from "./BiographyTab";
import ResearchTab from "./ResearchTab";
import clsx from "clsx";

const defaultTabs = [{ id: "bio", name: "Biography" }];

const fetchMember = ({ queryKey }) => {
  const [key, slug] = queryKey;

  const params = paramBuilder({
    include: ["center", "educationalExperiences", "professionalExperiences"],
    fields: {
      users: [
        "firstName",
        "fullName",
        "title",
        "centerShorthandOrInstitution",
        "bio",
        "email",
        "phoneNumber",
        "cvOrBiosketchUrl",
        "googleScholarUrl",
        "linkedinUrl",
        "projectsCount",
        "researchCount",
        "experienceCount",
        "center",
        "educationalExperiences",
        "professionalExperiences",
      ],
      centers: ["shorthand"],
      educationalExperiences: ["title", "institution", "startYear", "endYear"],
      professionalExperiences: ["title", "institution", "startDate", "endDate"],
    },
  });

  const url = `/api/${key}/${slug}?${params}`;
  return denormalizedFetcher(url);
};

function MemberSummary({ member }) {
  const {
    email,
    phoneNumber,
    cvOrBiosketchUrl,
    googleScholarUrl,
    linkedinUrl,
  } = member.attributes;

  const linkExists = cvOrBiosketchUrl || googleScholarUrl || linkedinUrl;

  return (
    <div className="font-body-bold bg-tint text-dark-100 rounded-lg p-6 pb-7 xl:w-320px xl:min-w-max">
      <div className="space-y-3">
        <p className="flex items-center">
          <i className="ri-mail-line ri-lg text-dark-50 font-body" />
          <a href={`mailto:${email}`} className="link ml-2.5">
            {email}
          </a>
        </p>
        {phoneNumber && (
          <p className="flex items-center">
            <i className="ri-phone-line ri-lg text-dark-50 font-body" />
            <span className="ml-2.5">{phoneNumber}</span>
          </p>
        )}
      </div>
      {linkExists && (
        <div className="space-y-3 border-t border-dark-25 pt-7 mt-7 ">
          {cvOrBiosketchUrl && (
            <p className="flex items-center">
              <i className="ri-download-line ri-lg text-dark-50 font-body" />
              <a
                href={cvOrBiosketchUrl}
                target="_blank"
                className="link ml-2.5"
              >
                CV / Biosketch
              </a>
            </p>
          )}
          {googleScholarUrl && (
            <p className="flex items-center">
              <i className="ri-external-link-line ri-lg text-dark-50 font-body" />
              <a
                href={googleScholarUrl}
                target="_blank"
                className="link ml-2.5"
              >
                Google Scholar
              </a>
            </p>
          )}
          {linkedinUrl && (
            <p className="flex items-center">
              <i className="ri-external-link-line ri-lg text-dark-50 font-body" />
              <a href={linkedinUrl} target="_blank" className="link ml-2.5">
                Linkedin Profile
              </a>
            </p>
          )}
        </div>
      )}
    </div>
  );
}

const TabSection = props => {
  switch (props.tab) {
    case "projects":
      return <ProjectsTab user={props.user} />;
    case "research":
      return <ResearchTab user={props.user} />;
    case "experience":
      return <ExperienceTab user={props.user} />;
    case "bio":
      return <BiographyTab user={props.user} />;
    default:
      return null;
  }
};

function MemberDetail() {
  const { slug } = useParams();
  const [tabsLoading, setTabsLoading] = useState(true);
  const [tabs, setTabs] = useState<FilterOption[]>(defaultTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { data }: { data: any } = useQuery(["users", slug], fetchMember);

  const member = data?.data;
  const projectsCount = member?.attributes.projectsCount;
  const researchCount = member?.attributes.researchCount;
  const experienceCount = member?.attributes.experienceCount;

  useLayoutEffect(() => {
    if (member) {
      const newTabs: FilterOption[] = [];
      if (projectsCount > 0) newTabs.push({ id: "projects", name: "Projects" });
      if (researchCount > 0) newTabs.push({ id: "research", name: "Research" });
      if (experienceCount > 0)
        newTabs.push({ id: "experience", name: "Experience & Education" });

      setTabs([...newTabs, ...defaultTabs]);
      setTabsLoading(false);
    }
  }, [member, projectsCount, researchCount, experienceCount]);

  if (data?.errors) return <NotFoundPage />;

  return (
    <div className="font-lato font-body bg-white min-h-full">
      {member && !tabsLoading && (
        <Header
          user={member}
          tabs={tabs}
          {...{ selectedTab, setSelectedTab }}
        />
      )}
      <div className="margin-safe xl:flex xl:flex-row-reverse pb-4 sm:pb-8 xl:pb-12 pt-7.5 xl:pt-12">
        <aside
          className={clsx(
            "ml-0 mb-7.5 hidden xl:block xl:ml-7.5 xl:mb-0",
            selectedTab?.id === "bio" && "!block",
          )}
        >
          {member && <MemberSummary member={member} />}
        </aside>
        <section className="xl:flex-grow sm:mb-0 overflow-auto px-1 -mx-1 pt-1 -mt-1">
          {selectedTab && member && (
            <TabSection tab={selectedTab.id} user={member} />
          )}
        </section>
      </div>
    </div>
  );
}

export default MemberDetail;
