import * as React from "react";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import paramBuilder from "@/utils/network/paramBuilder";
import { byId, groupContiguousBy } from "@/utils/array";
import { denormalizedFetcher } from "@/utils/api";
import NewsItem from "@/components/shared/NewsItem";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const titleDigest = (title, size) => {
  if (size < 1024) {
    return format(new Date(title.split("for")[1].trim()), "MMM dd,yyyy");
  }
  return title;
};

const fetchNewsFeed = async ({ queryKey }) => {
  const [_key, id, hash] = queryKey;
  const baseUrl = `/api/news-feeds/${id || hash}`;
  const params = paramBuilder({
    fields: {
      newsFeeds: [
        "title",
        "newsItemsList",
        "categories",
        "previousItem",
        "nextItem",
        "publishedAt",
      ],
    },
  });
  const url = `${baseUrl}?${params}`;
  const newsFeed = await denormalizedFetcher(url);
  return newsFeed;
};

const GroupedNewsItems = ({ categories, group }) => {
  const categoryName = categories[group.categoryId];

  return (
    categoryName && (
      <article>
        <h3 className="font-h3 border-b border-lines pb-5 text-dark-100">
          {categoryName.name}
        </h3>
        <section className="flex flex-col gap-5 divide-y divide-lines">
          {group.items.map(news => (
            <NewsItem news={news} key={news.id} />
          ))}
        </section>
      </article>
    )
  );
};

const NewsFeedSection = ({ newsFeed }) => {
  const currentUser = useCurrentUser();
  const screenSize = useWindowDimensions();
  const newsItems = newsFeed.attributes.newsItemsList;
  const categoriesN = newsFeed.attributes.categories;
  const result = groupContiguousBy(newsItems, "categoryId");
  const result1 = byId(categoriesN);

  return result?.length > 0 ? (
    <>
      <section className="flex flex-col gap-15 lg:gap-20 mt-15">
        {result?.map(
          (group, index) =>
            result1[group.categoryId] && (
              <GroupedNewsItems
                {...{ categories: result1, group, index }}
                key={index}
              />
            ),
        )}
      </section>
      <footer className="flex justify-between border-t border-lines py-4 mt-12">
        {newsFeed.attributes.previousItem.title && (
          <Link
            to={newsFeed.attributes.previousItem.url}
            className="text-dark-75 font-meta"
          >
            <div className="flex items-center space-x-14px">
              <i className="ri-arrow-left-line"></i>
              <span className="link">
                {titleDigest(
                  newsFeed.attributes.previousItem.title,
                  screenSize.width,
                )}
              </span>
            </div>
          </Link>
        )}
        {newsFeed.attributes.nextItem.title && (
          <Link
            to={newsFeed.attributes.nextItem.url}
            className="text-dark-75 font-meta"
          >
            <div className="flex items-center space-x-14px">
              <span className="link">
                {titleDigest(
                  newsFeed.attributes.nextItem.title,
                  screenSize.width,
                )}
              </span>
              <i className="ri-arrow-right-line"></i>
            </div>
          </Link>
        )}
      </footer>
    </>
  ) : (
    <div className="mt-5.5 py-14 border-y border-lines text-dark-75 text-center font-meta">
      There's nothing here yet.{" "}
      {currentUser.attributes.isStaff && (
        <Link to={newsFeed.links.edit} className="link-primary">
          Click here to add content.
        </Link>
      )}
    </div>
  );
};

function DailyNewsPage() {
  const { id, hash } = useParams();
  const { isLoading, data: newsFeed } = useQuery(
    ["newsfeed", id, hash],
    fetchNewsFeed,
  );

  return (
    <>
      {!isLoading && !newsFeed.data.attributes.publishedAt && (
        <p className="bg-primary text-white text-center m-0 py-5.5 px-4 font-meta-bold">
          Preview Only: Daily News Feed Digest for{" "}
          {format(new Date(), "MMMM dd, yyyy")}
        </p>
      )}
      <div className="margin-safe max-w-3xl">
        {!isLoading && (
          <section className="pb-24 w-full font-lato mt-7.5 lg:mt-15">
            <header>
              <h1 className="text-dark-100 font-h3 lg:font-h1 flex items-center pb-5 border-b border-dark-25">
                {newsFeed.data.attributes.title}
              </h1>
            </header>
            <main>
              <NewsFeedSection newsFeed={newsFeed.data} />
            </main>
          </section>
        )}
      </div>
    </>
  );
}

export default DailyNewsPage;
