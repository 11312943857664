import React from "react";
import clsx from "clsx";

const EnrollmentBlock = ({ title, byDate, byTarget, className }) => (
  <div className={className}>
    <header className="px-5 bg-tint-10">
      <h3 className="uppercase text-dark-100 text-11 font-bold py-1.5">
        {title}
      </h3>
    </header>
    <div className="px-5">
      <div className="flex flex-col text-dark-75 pt-2.5">
        <em className="text-dark-100 text-17 font-bold not-italic">{byDate}</em>
        <span>Patients enrolled to date</span>
      </div>
      <div className="flex flex-col text-dark-75 pt-2.5 pb-4">
        <em className="text-dark-100 text-17 font-bold not-italic">
          {byTarget}
        </em>
        <span>Target enrollment</span>
      </div>
    </div>
  </div>
);

function SummaryPatientEnrollment({
  enrollingPatients,
  className,
  enrollments,
}) {
  return (
    <div
      className={clsx("bg-white border border-lines rounded shadow", className)}
    >
      <header className="px-5 py-3 border-b border-lines">
        <h2 className="text-dark-100">
          <span className="text-17 font-bold">Patient Enrollment</span>
        </h2>
      </header>
      <div className="flex w-full">
        <EnrollmentBlock
          title="All Time"
          byDate={enrollments.all_time_enrolled}
          byTarget={enrollments.all_time_target}
          className="w-1/2 border-r border-lines"
        />
        <EnrollmentBlock
          title={`Currently Enrolling Trials (${enrollingPatients})`}
          byDate={enrollments.active_enrolled}
          byTarget={enrollments.active_target}
          className="w-1/2"
        />
      </div>
    </div>
  );
}

export default SummaryPatientEnrollment;
