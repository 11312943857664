import * as React from "react";
import { useState, Fragment } from "react";
import { NavLink, Outlet, Link } from "react-router-dom";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import useCurrentUser from "@/hooks/useCurrentUser";
import useBanner from "@/hooks/useBanner";
import SitewideBanner from "./SitewideBanner";
import piciLogoAC from "@/images/logo-ac@2x.png";

const NAVIGATION = [
  {
    name: "Dashboard",
    href: "/admin",
    end: true,
  },
  {
    name: "NETWORK",
    children: [
      { name: "People", href: "/admin/people" },
      { name: "Centers", href: "/admin/centers", spa: true },
      { name: "Focus Areas", href: "/admin/focus_areas", spa: true },
    ],
  },
  {
    name: "PROJECTS",
    current: true,
    children: [
      { name: "Clinical Trials", href: "/admin/clinical_trials", spa: true },
      {
        name: "Research Studies",
        href: "/admin/research_studies?sort=-createdAt",
        spa: true,
      },
      {
        name: "Initiatives",
        href: "/admin/initiatives?order=Date%7Cdesc&tab=all",
        spa: true,
      },
    ],
  },
  {
    name: "INTELLECTUAL PROPERTY",
    children: [{ name: "Inventions", href: "/admin/inventions", spa: true }],
  },
  {
    name: "NETWORK RESOURCES",
    children: [
      { name: "Resources", href: "/admin/media_resources", spa: true },
      { name: "Research", href: "/admin/research_documents", spa: true },
    ],
  },
  {
    name: "COMMUNICATIONS",
    children: [
      { name: "Daily News Feed", href: "/admin/daily-news", spa: true },
      { name: "Announcements", href: "/admin/announcements", spa: true },
      { name: "Upcoming Events", href: "/admin/events", spa: true },
      { name: "Sitewide Banner", href: "#" },
    ],
  },
  {
    name: "RETREATS",
    children: [
      { name: "Retreats", href: "/admin/retreats", spa: true },
      { name: "Articles", href: "/admin/articles", spa: true },
    ],
  },
  {
    name: "LEGAL",
    children: [{ name: "Compliance", href: "/admin/compliance" }],
  },
  // TODO: Example to pages from the SPA (include: {spa: true})
  // {
  //   name: "TEST",
  //   children: [{ name: "Test Page", href: "/admin/test", spa: true }],
  // },
];

const ISLEGAL_NAVIGATION = [
  {
    name: "Dashboard",
    href: "/admin",
    end: true,
  },
  {
    name: "NETWORK",
    children: [
      { name: "People", href: "/admin/people", spa: true },
      { name: "Centers", href: "/admin/centers", spa: true },
      { name: "Focus Areas", href: "/admin/focus_areas", spa: true },
    ],
  },
  {
    name: "PROJECTS",
    current: true,
    children: [
      { name: "Clinical Trials", href: "/admin/clinical_trials", spa: true },
      {
        name: "Research Studies",
        href: "/admin/research_studies?sort=-createdAt",
        spa: true,
      },
      {
        name: "Initiatives",
        href: "/admin/initiatives?order=Date%7Cdesc&tab=all",
        spa: true,
      },
    ],
  },
  {
    name: "INTELLECTUAL PROPERTY",
    children: [
      { name: "Inventions", href: "/admin/inventions", spa: true },
      {
        name: "Patent Deadlines",
        href: "/admin/patent_deadlines?tab=pctPatentPending",
        spa: true,
      },
      {
        name: "Top Inventors",
        href: "/admin/top_inventors",
        spa: true,
      },
    ],
  },
  {
    name: "NETWORK RESOURCES",
    children: [
      { name: "Resources", href: "/admin/media_resources", spa: true },
      { name: "Research", href: "/admin/research_documents", spa: true },
    ],
  },
  {
    name: "COMMUNICATIONS",
    children: [
      { name: "Daily News Feed", href: "/admin/daily-news", spa: true },
      { name: "Announcements", href: "/admin/announcements", spa: true },
      { name: "Upcoming Events", href: "/admin/events", spa: true },
      { name: "Sitewide Banner", href: "#" },
    ],
  },
  {
    name: "RETREATS",
    children: [
      { name: "Retreats", href: "/admin/retreats", spa: true },
      { name: "Articles", href: "/admin/articles", spa: true },
    ],
  },
  {
    name: "LEGAL",
    children: [{ name: "Compliance", href: "/admin/compliance" }],
  },
];

const ProfileSection = ({ user }) => {
  const { fullName, role } = user.attributes;
  const { avatar, show } = user.links;
  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center flex-shrink-0 px-4">
          <img src={piciLogoAC} alt="Workflow" width="111" />
        </div>
        {/* Profile dropdown */}
        <Menu as="div" className="mx-4 relative flex-shrink-0">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  <img className="h-8 w-8 rounded-full" src={avatar} alt="" />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <Menu.Item>
                    {({}) => (
                      <div className="block px-4 py-2 border-b border-lines">
                        <h3 className="font-body-bold text-dark-100">
                          {fullName}
                        </h3>
                        <p className="mt-1 font-meta text-dark-50">{role}</p>
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={show}
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings/basic-info"
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Account
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings/help-request"
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Help
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/logout"
                        className={clsx(
                          active ? "bg-tint text-primary" : "text-dark-75",
                          "block px-4 py-2 font-body",
                        )}
                      >
                        Log out
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
      <div className="mt-4 px-4 w-full">
        <Link
          to="/"
          className="btn btn-md w-full border border-dark-25 shadow-sm text-dark-25 bg-transparent hover:text-white hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          Go to Member Center
        </Link>
      </div>
    </>
  );
};

function AdminCenterNavigation() {
  const currentUser = useCurrentUser();
  const banner = useBanner();
  const [openSidewideBanner, setOpenSidewideBanner] = useState(false);
  const { isLegal, isStaff } = currentUser.attributes;
  const isStaffAndLegal = isLegal && isStaff;

  const navigation = isStaffAndLegal ? ISLEGAL_NAVIGATION : NAVIGATION;

  return (
    <>
      <div className="flex flex-col flex-grow border-gray-200 pt-3 pb-3 sidebar w-250 h-screen fixed font-lato overflow-y-auto">
        <ProfileSection user={currentUser} />
        <div className="mt-5 flex-grow flex flex-col overflow-x-hidden scrollbar">
          <nav
            className="flex-1 px-4 space-y-1 sidebar text-primary-gray"
            aria-label="Sidebar"
          >
            {navigation.map(item =>
              !item.children ? (
                <div key={item.name}>
                  <NavLink
                    to="/admin"
                    className={({ isActive }) =>
                      clsx(
                        "group w-full flex items-center pl-4 pr-2 py-1 text-sm font-medium rounded-md sidebarItem hover:text-white",
                        isActive && "sidebarItemActive text-white",
                      )
                    }
                    end={true}
                  >
                    {item.name}
                  </NavLink>
                </div>
              ) : (
                <Disclosure
                  as="div"
                  defaultOpen={true}
                  key={item.name}
                  className="space-y-1"
                >
                  <>
                    <Disclosure.Button className="text-gray-600 group w-full flex items-center pl-2 pr-1 py-1.5 text-left text-xs font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary">
                      <span className="flex-1 text-size-11 tracking-wider">
                        {item.name}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className="space-y-1 text-primary-gray"
                      static={true}
                    >
                      {item.children.map(subItem => (
                        <div key={subItem.name}>
                          {subItem.name === "Sitewide Banner" && (
                            <button
                              onClick={() => setOpenSidewideBanner(true)}
                              className="group w-full flex justify-between items-center pl-4 pr-2 py-1 text-sm font-medium rounded-md hover:bg-gray-900 hover:text-white"
                            >
                              {subItem.name}
                              <>
                                {banner?.attributes?.active && (
                                  <span className="px-2.5 rounded bg-purple-600 text-xs text-white">
                                    ON
                                  </span>
                                )}
                                <PencilAltIcon className="w-5 h-5" />
                              </>
                            </button>
                          )}
                          {subItem.name != "Sitewide Banner" && subItem.spa && (
                            <NavLink
                              to={subItem.href}
                              className={({ isActive }) =>
                                clsx(
                                  "group w-full flex justify-between items-center pl-4 pr-2 py-1 text-sm font-medium rounded-md",
                                  isActive
                                    ? "sidebarItemActive text-white"
                                    : "sidebarItem hover:text-white",
                                )
                              }
                              end={false}
                            >
                              {subItem.name}
                            </NavLink>
                          )}
                          {subItem.name != "Sitewide Banner" && !subItem.spa && (
                            <a
                              href={subItem.href}
                              className="group w-full flex justify-between items-center pl-4 pr-2 py-1 text-sm font-medium rounded-md sidebarItem hover:text-white"
                            >
                              {subItem.name}
                            </a>
                          )}
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </>
                </Disclosure>
              ),
            )}
          </nav>
        </div>
      </div>
      {banner && (
        <SitewideBanner
          open={openSidewideBanner}
          setOpen={setOpenSidewideBanner}
          banner={banner}
        />
      )}
      <section
        id="admin-section-navigation"
        className={clsx(
          "relative ml-250 flex flex-grow flex-col w-auto bg-white min-h-screen overflow-x-hidden",
          "font-lato",
        )}
      >
        <Outlet />
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      </section>
    </>
  );
}

export default AdminCenterNavigation;
