import React from "react";
import { BarChart, Bar, Tooltip, XAxis, YAxis, CartesianGrid } from "recharts";
import StackedTooltip from "./StackedTooltip";
import COLORS from "./Colors";
import * as R from "ramda";

const CHART_WIDTH = 846;
const CHART_HEIGHT = 449;
const rowItems = 5;

const XTick = props => {
  return (
    <text {...props} className="font-label">
      {props.payload.value}
    </text>
  );
};

const CustomTick = ({ y, payload }: { y?: any; payload?: any }) => {
  const label = payload.value.split(" ");
  const dy = 18;
  const labelY = y - dy * (0.5 + label.length / 2);

  return (
    <text x={0} y={labelY} dominantBaseline="central" className="font-label">
      {label.map((part, index) => (
        <tspan key={index} x={0} dy={dy}>
          {part}
        </tspan>
      ))}
    </text>
  );
};

const Legend = ({ centers }) => {
  return (
    <div className="w-150 box-content ml-120px mt-9">
      {R.splitEvery(rowItems, centers).map((row, i) => (
        <ul className="flex list-none p-0 mt-0 mb-3.5 gap-10" key={i}>
          {row.map((center, index) => (
            <li className="flex items-center font-meta gap-1" key={index}>
              <div
                className="w-6 h-1 inline-block"
                style={{ backgroundColor: COLORS[i * rowItems + index] }}
              ></div>
              {center}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

function  HorizontalStackedBarChartFullSize({ data, centers, title }) {
  return (
    <>
      <h2 className="font-h2">{title}</h2>
      <BarChart
        width={CHART_WIDTH}
        height={CHART_HEIGHT}
        data={data}
        margin={{
          top: 56,
          bottom: 12,
          left: 36,
          right: 80,
        }}
        layout="vertical"
      >
        <CartesianGrid vertical={false} stroke="#CFD3DA" />
        <Tooltip content={<StackedTooltip />} />
        <XAxis
          axisLine={false}
          tickLine={false}
          tick={<XTick />}
          type="number"
        />
        <YAxis type="category" dataKey="name" tick={<CustomTick />} />
        {centers.map((center, index) => (
          <Bar
            dataKey={center}
            key={center}
            stackId="center"
            fill={COLORS[index]}
            barSize={72}
          />
        ))}
      </BarChart>
      <Legend centers={centers} />
    </>
  );
}

export default HorizontalStackedBarChartFullSize;
