import React, { useState, useEffect, useMemo } from "react";
import Filter from "@/components/shared/Filter";
import SelectGroup from "@/components/shared/SelectGroup";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import paramBuilder from "@/utils/network/paramBuilder";
import FilterToolbar from "@/components/shared/FilterToolbar";
import { useDebounce } from "use-debounce";

const statusOptions = [
  { name: "Pending", id: "pending" },
  { name: "Accepted", id: "accepted" },
  { name: "Declined", id: "declined" },
];

const sortOptions = [
  { name: "Disclosed, Newest first", id: "-disclosure" },
  { name: "Disclosed, Oldest first", id: "disclosure" },
  { name: "Review Date, Newest First", id: "-review" },
  { name: "Review Date, Oldest First", id: "review" },
  { name: "Title, A to Z", id: "title" },
  { name: "Title, Z to A", id: "-title" },
];

function InventionsFilter({
  total,
  isLoading,
  queryFilter,
  setFilters,
  tabType,
}) {
  const [titleFilter, setTitleFilter] = useState("");
  const [debouncedTitleFilter] = useDebounce(titleFilter, 500);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedCenter, setSelectedCenter] = useState<FilterOption[]>([]);
  const [centerFocus, setCenterFocus] = useState(false);
  const [selectedIpType, setSelectedIpType] = useState<FilterOption[]>([]);
  const [ipTypeFocus, setIpTypeFocus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<FilterOption[]>([]);
  const [statusFocus, setStatusFocus] = useState(false);
  const { data: filtersData } = useQuery(["inventionsFilters"], () =>
    fetcher("/api/inventions/filters"),
  );
  const filterOptions = useMemo(() => filtersData || {}, [filtersData]);

  useEffect(() => {
    const status =
      tabType === "all"
        ? []
        : statusOptions.filter(statusOption => statusOption.id === tabType);
    setSelectedStatus(status);
  }, [tabType]);

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        name: debouncedTitleFilter,
        ipType: selectedIpType.map(t => t.id),
        center: selectedCenter.map(t => t.id),
        status: selectedStatus.map(t => t.id),
      },
      sort: [selectedSort?.id],
    });
    if (queryFilter !== filters) {
      setFilters(filters);
    }
  }, [
    setFilters,
    debouncedTitleFilter,
    selectedSort.id,
    selectedIpType,
    selectedCenter,
    selectedStatus,
    queryFilter,
  ]);

  return (
    <FilterToolbar
      total={total}
      filterText={titleFilter}
      setFilterText={setTitleFilter}
      isLoading={isLoading}
      placeHolderText="Find by identifier, title, inventor, or licensee"
      inputClassName="min-w-[310px]"
    >
      <Filter
        name="ipTypes"
        items={filterOptions.ipTypes}
        selectedItems={selectedIpType}
        setSelectedItems={setSelectedIpType}
        highlight={ipTypeFocus}
        setFocus={setIpTypeFocus}
        className="flex-1 mr-2 min-w"
      >
        <Filter.Label size="sm">All IP Types</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filterOptions.ipTypes}
            selectedOptions={selectedIpType}
            setSelectedOptions={setSelectedIpType}
            labelClassName="sm:w-325px !py-1"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="center"
        items={filterOptions.centers}
        selectedItems={selectedCenter}
        setSelectedItems={setSelectedCenter}
        highlight={centerFocus}
        setFocus={setCenterFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">All Centers</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={filterOptions.centers}
            selectedOptions={selectedCenter}
            setSelectedOptions={setSelectedCenter}
            labelClassName="sm:w-325px !py-1"
          />
        </Filter.Content>
      </Filter>
      {tabType === "all" && (
        <Filter
          name="status"
          items={statusOptions}
          selectedItems={selectedStatus}
          setSelectedItems={setSelectedStatus}
          highlight={statusFocus}
          setFocus={setStatusFocus}
          className="flex-1 mr-2"
        >
          <Filter.Label size="sm">All Statuses</Filter.Label>
          <Filter.Content>
            <CheckboxGroup
              options={statusOptions}
              selectedOptions={selectedStatus}
              setSelectedOptions={setSelectedStatus}
              labelClassName="sm:w-325px !py-1"
            />
          </Filter.Content>
        </Filter>
      )}
      <i className="ri-arrow-up-down-line text-xl ml-5 mr-2.5 text-black-placeholder"></i>
      <Filter
        name="sort"
        items={sortOptions}
        selectedItems={selectedSort}
        setSelectedItems={setSelectedSort}
        closeOnSelect
      >
        <Filter.Label size="sm">{selectedSort.name}</Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={sortOptions}
            selectedOption={selectedSort}
            setSelectedOption={setSelectedSort}
          />
        </Filter.Content>
      </Filter>
    </FilterToolbar>
  );
}

export default InventionsFilter;
