import * as React from "react";
import { useContext } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik-latest";
import { requestValidation } from "@/utils/requestValidation";
import ResearchDocumentFormContext from "./ResearchDocumentFormContext";
import Input from "@/components/shared/Input";
import Select from "@/components/shared/Select";
import FormBlock from "@/components/shared/FormBlock";

const ResearchDocumentSchemaStep1 = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Research title is required.")
    .max(
      280,
      "The Research title entered has exceeded the 280 character limit.",
    )
    .test("valid-title", "The Research title is taken.", function(value) {
      return requestValidation("research_documents", "title", "unique", value);
    }),
  researchType: Yup.string().required("Research type is required."),
  status: Yup.string().required("Research status is required."),
  publicationLink: Yup.string().url(
    "A valid URL is required. Make sure you've included http:// or https://.",
  ),
});

function ResearchDocumentFormStep1({
  researchTypes,
  researchStatuses,
  handleBackButtonPressed,
}) {
  const {
    handleNext,
    researchValues,
    setResearchValues,
    fileFormRef,
    filePickerState,
  } = useContext(ResearchDocumentFormContext);

  const handleSubmit = (values: any) => {
    const existFile =
      filePickerState === "uploading" || filePickerState === "uploaded";
    if (!existFile) {
      fileFormRef.current.handleSubmit();
    } else {
      setResearchValues({ ...researchValues, ...values });
      if (filePickerState === "uploading" || filePickerState === "uploaded") {
        handleNext();
      }
    }
  };

  return (
    <Formik
      initialValues={researchValues}
      onSubmit={handleSubmit}
      validationSchema={ResearchDocumentSchemaStep1}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="flex flex-col gap-7.5">
            <FormBlock label="Add Title" name="title">
              <Input
                placeholder="Title your research..."
                data-cy="research-title"
                value={values.title}
              />
            </FormBlock>
            <FormBlock name="researchType">
              <Select
                placeholder="Select Research Type"
                selected={values.researchType}
                setSelected={value => setFieldValue("researchType", value)}
                options={researchTypes}
                data-cy="research-type"
              />
            </FormBlock>
            <FormBlock name="status">
              <Select
                placeholder="Select Current Status"
                selected={values.status}
                setSelected={value => setFieldValue("status", value)}
                options={researchStatuses}
                data-cy="research-status"
              />
            </FormBlock>
            {values.status === "Published or presented" && (
              <>
                <FormBlock
                  label="Add publication title"
                  name="publication"
                  optional
                >
                  <Input
                    placeholder="Add publication title..."
                    value={values.publication}
                  />
                </FormBlock>
                <FormBlock
                  label="Add publication link"
                  name="publicationLink"
                  optional
                >
                  <Input
                    placeholder="Add publication link..."
                    value={values.publicationLink}
                  />
                </FormBlock>
              </>
            )}
            <div className="fixed bottom-0 left-0 py-5 bg-tint w-full flex items-center border-t">
              <section className="flex items-center justify-center w-full space-x-2.5">
                <button
                  type="button"
                  className="btn-white btn-sm"
                  onClick={handleBackButtonPressed}
                >
                  Back
                </button>
                <button
                  className="btn-primary btn-sm"
                  data-cy="cy-step-1"
                  type="submit"
                >
                  Next Step
                </button>
              </section>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResearchDocumentFormStep1;
