import React, { useMemo } from "react";

const Tooltip = React.memo(
  ({ active, payload }: { active?: boolean; payload?: any }) => {
    const total = useMemo(
      () =>
        payload
          ?.map(({ value }) => value)
          .reduce((accum, current) => accum + current, 0),
      [payload],
    );
    if (active && payload !== undefined) {
      return (
        <div className="bg-tint py-2 px-3">
          <p className="font-meta-bold">{payload[0].payload.name}</p>
          {payload.reverse().map(({ dataKey, color, value }) => (
            <p className="font-meta-bold" key={dataKey}>
              <span style={{ color }}>■</span>&nbsp;
              {`${dataKey} - ${((value / total) * 100).toFixed(1)}% - ${value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  },
);

export default Tooltip;
