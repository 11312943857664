import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Table from "@/components/shared/Table";
import Tooltip from "@/components/shared/Tooltip";
import {
  LinkableNameColumn,
  MembersColumn,
  ActionButtonsColumn,
  itemValue,
  render,
} from "@/components/shared/Admin/Columns";
import { ActionButtonItemType } from "@/components/shared/Admin/Columns/Columns";
import ToogleButton from "@/components/shared/ToogleButton";
import { fetcher } from "@/utils/api";

const PublicAtHeader = () => {
  return (
    <div className="flex flex-nowrap justify-between items-center">
      <span>Public </span>
      <Tooltip>
        <Tooltip.Trigger>
          <i className="ri-question-mark bg-white rounded-full w-4 h-4 flex items-center justify-center"></i>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <span>
            If this is on, then this content will display on the PICI public
            site (www.parkerici.org). If it's off, then it will not display
            publicly.
          </span>
        </Tooltip.Content>
      </Tooltip>
    </div>
  );
};

function PublicAtColumn({
  centerId,
  value,
  lastCenterUpdated,
  setLastCenterUpdated,
}) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["centers"]).then(_ => {
        setLastCenterUpdated({ isUpdating: false, centerId: centerId });
      });
    },
    onMutate: async () => {
      await queryClient.cancelQueries(["centers"]);

      const previousCenters: any = queryClient.getQueryData(["centers"]);

      const updateCenter = previousCenters.data.find(
        center => center.id === centerId,
      );
      if (updateCenter.attributes.publicAt) {
        updateCenter.attributes.publicAt = null;
      } else {
        updateCenter.attributes.publicAt = new Date();
      }
    },
  });

  const handlePublic = () => {
    setLastCenterUpdated({ isUpdating: true, centerId: centerId });
    mutateAsync(fetcher(`/api/centers/${centerId}/toggle`));
  };

  let isActiveToogle = !!value;
  if (lastCenterUpdated.centerId === centerId && lastCenterUpdated.isUpdating) {
    isActiveToogle = !value;
  }

  return (
    <ToogleButton
      dataCy={`center-public-toggle-${centerId}`}
      active={isActiveToogle}
      onClick={handlePublic}
    />
  );
}

function CentersTable({ data }: { data: any }) {
  const [lastCenterUpdated, setLastCenterUpdated] = useState({
    isUpdating: false,
    centerId: 0,
  });
  const columns = [
    {
      name: "name",
      label: "Center name",
      td: { className: "min-w-400px max-w-574px pt-9px pb-9px" },
      render: (value, item) => (
        <LinkableNameColumn
          text={value}
          to={`/centers/${item.attributes.slug}`}
          isBlank
        />
      ),
    },
    {
      name: "location",
      label: "Location",
      td: { className: "pt-9px pb-9px" },
      render: value => <span className="font-meta">{value}</span>,
    },
    {
      name: "members",
      label: "Members",
      td: { className: "pt-9px pb-9px" },
      accessor: item => item.relationships.members.data,
      render: (value, item) => (
        <MembersColumn id={item.id} memberList={value} />
      ),
    },
    {
      name: "publicAt",
      label: <PublicAtHeader />,
      td: { className: "w-100px pt-9px pb-9px" },
      render: (value, item) => (
        <PublicAtColumn
          centerId={item.id}
          value={value}
          lastCenterUpdated={lastCenterUpdated}
          setLastCenterUpdated={setLastCenterUpdated}
        />
      ),
    },
    {
      name: "actions",
      label: "Actions",
      td: { className: "w-[90px] pt-9px pb-9px" },
      accessor: item => item,
      render: value => {
        const items: ActionButtonItemType[] = [
          {
            text: "Edit",
            to: `centers/${value.attributes.slug}/edit`,
            name: value.attributes.name,
            variant: "btn-admin",
            isBlank: true,
          },
        ];
        return <ActionButtonsColumn items={items} />;
      },
    },
  ];

  return (
    <Table className="border border-transparent mb-8">
      <Table.Header className="bg-transparent text-dark-50">
        {columns.map((column, i) => (
          <Table.HeaderCell key={i} className="text-11 pb-2">
            {column.label}
          </Table.HeaderCell>
        ))}
      </Table.Header>
      <Table.Body className="border border-lines">
        {data.map((item, i) => (
          <Table.Row key={i} className="divide-x divide-lines">
            {columns.map((column, j) => (
              <Table.Cell
                key={j}
                className={column.td?.className}
                expand={true}
              >
                {render(itemValue(item, column), column, item)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default CentersTable;
