import * as React from "react";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Formik, Form } from "formik-latest";
import * as Yup from "yup";
import clsx from "clsx";
import { saveResource } from "@/utils/api";
import useCurrentUser from "@/hooks/useCurrentUser";
import useFlashMessage from "@/hooks/useFlashMessage";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";
import AvatarPicker from "@/components/shared/AvatarPicker";
const phoneRegExp = /^\(\d{1,4}\) *(\d{3}) *-*(\d{4})$/;

const UserBasicInfoSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  email: Yup.string()
    .email("Please enter a valid e-mail.")
    .required("E-mail is required."),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
  password: Yup.string()
    .min(8, "This value is too short. It should have 8 characters or more.")
    .nullable(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Both password need to be the same")
    .when("password", {
      is: val => val && val.length,
      then: Yup.string().required(
        "You have to enter the password confirmation",
      ),
    }),
});

const mapInitialValues = item => {
  return {
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    phoneNumber: item.phoneNumber || "",
    avatar: item.avatar,
    password: "",
    passwordConfirmation: "",
  };
};

const mapRequest = (values, id) => {
  const attributes = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneNumber: values.phoneNumber,
    avatar: values.avatar,
  };
  if (values.password?.length > 0) {
    attributes.password = values.password;
  }

  return {
    data: {
      id: id,
      type: "users",
      attributes,
    },
  };
};

function BasicInfoPage() {
  const [avatarPickerState, setAvatarPickerState] = useState("");
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const showFlash = useFlashMessage({
    message: "Your changes have been saved!",
    errorMessage: "Your changes could not be saved due to an error.",
  });

  const updateInfo = (item, { setSubmitting }) => {
    const request = mapRequest(item, currentUser?.id);

    saveResource("users", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => showFlash({ success: false }))
      .finally(() => {
        setSubmitting(false);
        queryClient.invalidateQueries(["current-user"]);
      });
  };

  return (
    <div>
      <h2 className="hidden md:block text-dark-100 font-h2 pb-5 border-b border-lines">
        Basic Info
      </h2>
      <div className="pb-7.5 md:py-7.5">
        <Formik
          initialValues={mapInitialValues(currentUser?.attributes)}
          onSubmit={updateInfo}
          validationSchema={UserBasicInfoSchema}
          enableReinitialize
        >
          {formikProps => {
            const isDisabled =
              formikProps.isSubmitting ||
              !formikProps.isValid ||
              !formikProps.dirty ||
              avatarPickerState == "uploading";
            return (
              <Form>
                <div className="space-y-5 sm:space-y-7.5">
                  <div className="grid grid-rows-2 lg:grid-rows-1 grid-flow-col gap-5 lg:gap-7.5">
                    <FormBlock
                      label="First Name"
                      name="firstName"
                      className="w-full lg:max-w-412px"
                      labelClassName="mb-2.5 md:mb-3 font-body-bold"
                    >
                      <Input
                        placeholder="First Name"
                        data-cy="user-firstName"
                      />
                    </FormBlock>
                    <FormBlock
                      label="Last Name"
                      name="lastName"
                      className="w-full lg:max-w-412px"
                      labelClassName="mb-2.5 md:mb-3 font-body-bold"
                    >
                      <Input placeholder="Last Name" data-cy="user-lastName" />
                    </FormBlock>
                  </div>
                  <div className="grid grid-rows-2 lg:grid-rows-1 grid-flow-col gap-5 lg:gap-7.5">
                    <FormBlock
                      label="Email Address"
                      name="email"
                      className="w-full lg:max-w-412px"
                      labelClassName="mb-2.5 md:mb-3 font-body-bold"
                    >
                      <Input
                        placeholder="Email Address"
                        data-cy="user-email"
                        className="opacity-40 cursor-default"
                        disabled={true}
                      />
                    </FormBlock>
                    <FormBlock
                      label="Phone Number"
                      name="phoneNumber"
                      optional
                      className="w-full lg:max-w-412px"
                      labelClassName="mb-2.5 md:mb-3 font-body-bold"
                    >
                      <Input
                        placeholder="(999) 999-9999"
                        data-cy="user-phoneNumber"
                      />
                    </FormBlock>
                  </div>
                  <FormBlock
                    label="Avatar"
                    name="avatar"
                    className="w-full"
                    labelClassName="md:mb-2 font-body-bold"
                    optional
                  >
                    <AvatarPicker
                      attachment={currentUser?.attributes.avatarUrl}
                      getStatus={status => {
                        setAvatarPickerState(status);
                      }}
                      labelButton="Change"
                    />
                  </FormBlock>
                  <FormBlock
                    label="Change Password"
                    name="password"
                    optional
                    className="w-full"
                    labelClassName="mb-2.5 md:mb-3 font-body-bold"
                  >
                    <Input
                      placeholder="Change Password"
                      data-cy="user-password"
                      type="password"
                      autoComplete="off"
                    />
                  </FormBlock>
                  <FormBlock
                    label="Confirm Password"
                    name="passwordConfirmation"
                    optional
                    className="w-full"
                    labelClassName="mb-2.5 md:mb-3 font-body-bold"
                  >
                    <Input
                      placeholder="Confirm Password"
                      data-cy="user-passwordConfirmation"
                      type="password"
                      autoComplete="off"
                    />
                  </FormBlock>
                  <button
                    className={clsx(
                      "btn-xl btn-primary font-lato w-full md:w-auto",
                      { "btn-disabled": isDisabled },
                    )}
                    type="submit"
                    disabled={isDisabled}
                  >
                    Save Changes
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default BasicInfoPage;
