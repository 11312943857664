import * as React from "react";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import FlashMessage from "@/components/shared/FlashMessage";
import FlashMessageContext from "@/components/shared/FlashMessage/FlashMessageContext";
import AuthenticatedRouter from "@/components/App/AuthenticatedRouter";

import LoginPage from "@/components/pages/LoginPage";
import ForgotPasswordPage from "@/components/pages/ForgotPasswordPage/ForgotPasswordPage";
import CheckYourInboxPage from "@/components/pages/ForgotPasswordPage/CheckYourInboxPage";
import ResetPasswordPage from "@/components/pages/ForgotPasswordPage/ResetPasswordPage";

function SiteRouter() {
  const [flash, setFlash] = useState({
    visible: false,
    success: true,
    message: "",
  });

  return (
    <div id="site-router" className="min-h-screen antialiased font-body">
      <FlashMessageContext.Provider value={{ setFlash: setFlash }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/check-your-inbox" element={<CheckYourInboxPage />} />
          <Route path="/users/:id/password" element={<ResetPasswordPage />} />
          <Route path="*" element={<AuthenticatedRouter />} />
        </Routes>
      </FlashMessageContext.Provider>

      {flash.visible && <FlashMessage flash={flash} setFlash={setFlash} />}
    </div>
  );
}

export default SiteRouter;
