import * as React from "react";
import { Link } from "react-router-dom";
import LoginLayout from "@/components/shared/LoginLayout";

function ForgotPasswordPage() {
  return (
    <LoginLayout>
      <h2 className="text-dark-100 font-h2 mb-2">Check Your Inbox</h2>
      <p className="font-body text-dark-75 mb-8.5">
        Instructions to reset your password have been emailed to the address you
        provided.
      </p>
      <Link to="/login" className="mt-0.25 btn-md btn-primary w-full">
        Back to login
      </Link>
    </LoginLayout>
  );
}

export default ForgotPasswordPage;
