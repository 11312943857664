import * as React from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import GlobalSearchContent from "./GlobalSearchContent";

const getUrlSearchParams = (
  search: string,
): { plainQuery: string; entries: any } => {
  if (!search) {
    return { plainQuery: "", entries: {} };
  }
  const params = search.match(/[^&?][^&]+/g);
  const queryArray: string[] = [];
  const entries =
    params &&
    params.map(param => {
      const [key, value] = param.split("=");
      const encodedValue = encodeURIComponent(decodeURI(value));
      queryArray.push(`${key}=${encodedValue}`);
      return [key, encodedValue];
    });
  const plainQuery = queryArray.join("&");
  return { plainQuery, entries: entries && Object.fromEntries(entries) };
};

function GlobalSearchPage() {
  const { search: searchParams } = useLocation();
  const urlSearchParams = getUrlSearchParams(searchParams);
  const term = urlSearchParams.entries.term
    ? decodeURIComponent(urlSearchParams.entries.term)
    : urlSearchParams.entries.term;

  if (term) {
    return (
      <GlobalSearchContent term={term} urlSearchParams={urlSearchParams} />
    );
  } else {
    return <Navigate to="/" />;
  }
}

export default GlobalSearchPage;
