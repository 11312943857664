import * as React from "react";
import { Link } from "react-router-dom";

function CompletedForm({ newResearchURL }) {
  return (
    <section className="flex flex-col items-center bg-tint py-10 px-6.5 text-center rounded-md">
      <h2 className="font-h2 text-dark-100">Thank you!</h2>
      <p className="text-dark-75 font-body-large mt-2.5">
        This is a great addition to the PICI Member Center. Other investigators
        rely on contributions like yours to get the latest insights on
        immunotherapy.
      </p>
      <Link to={newResearchURL} className="btn-secondary btn-lg mt-4">
        View Your Research
      </Link>
    </section>
  );
}

export default CompletedForm;
