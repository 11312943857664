import { createContext, MutableRefObject } from "react";

interface ResearchDocumentFormContextType {
  handlePrev: () => void;
  handleNext: () => void;
  researchValues: any;
  setResearchValues: (values: any) => any;
  fileFormRef: MutableRefObject<any>;
  filePickerState: string;
}

const ResearchDocumentFormContext = createContext<
  ResearchDocumentFormContextType
>(undefined);

export default ResearchDocumentFormContext;
