import React, { useState } from "react";
import FormBlock from "@/components/shared/FormBlock";
import Input from "@/components/shared/Input";
import ButtonIcon from "@/components/shared/ButtonIcon";
import clsx from "clsx";
import { SectionMode } from "@/components/shared/Retreat/Article/defaults";

function ArticleTitle({ values, viewMode = SectionMode.View, className = "" }) {
  const isViewMode = viewMode === SectionMode.View;
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(
    !isViewMode && !values.title,
  );
  return (
    <>
      {isEditingTitle ? (
        <div className="flex">
          <FormBlock
            label="Article Title"
            name="title"
            className="w-full mb-6"
            labelClassName="font-body-bold"
          >
            <Input styleAs="small" />
          </FormBlock>
          <ButtonIcon
            title="Preview Title"
            disabled={!values.title}
            className="btn-white btn-md h-10 text-xl ml-1 font-normal mt-7.5"
            iconClassName="ri-eye-line"
            onClick={() => setIsEditingTitle(false)}
          />
        </div>
      ) : (
        <div
          className={clsx(
            "flex relative",
            {
              "group hover:border-2 hover:rounded-lg hover:border-dashed p-2": !isViewMode,
            },
            className,
          )}
        >
          <h1 className="font-h1 lg:font-h1-xl">{values.title}</h1>
          {!isViewMode && (
            <div className="absolute right-4 top-6 group-hover:block hidden">
              <ButtonIcon
                className="text-xl ml-1 font-normal btn-white btn-md h-8 px-2"
                title="Edit Title"
                iconClassName="ri-pencil-line"
                onClick={() => setIsEditingTitle(true)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ArticleTitle;
