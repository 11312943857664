import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { denormalizedFetcher, fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import useCurrentUser from "@/hooks/useCurrentUser";
import FeaturedProjects from "./FeaturedProjects";
import MonthlyActivity from "./MonthlyActivity";
import NewsFeedPreview from "./NewsFeedPreview";
import Announcement from "./Announcement";
import RetreatHero from "@/components/shared/Retreat/RetreatHero";
import Aside from "./Aside";
import { useTour } from "@reactour/tour";

const fetchAnnouncement = () => {
  const baseUrl = "/api/announcements/featured";
  const params = paramBuilder({
    include: ["user"],
    fields: {
      announcements: ["subject", "content", "createdAt", "imageUrl", "user"],
      users: ["fullName", "avatarUrl"],
    },
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

const fetchNewsFeedPreview = () => {
  const baseUrl = `/api/news-feeds/last`;
  const params = paramBuilder({
    fields: {
      newsFeeds: [
        "title",
        "newsItemsList",
        "categories",
        "previousItem",
        "nextItem",
        "publishedAt",
      ],
    },
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function DashboardPage() {
  const currentUser = useCurrentUser();
  const {
    isLoading: isLoadingAnnouncement,
    data: announcement,
  }: { isLoading: boolean; data: any } = useQuery(
    ["announcement"],
    fetchAnnouncement,
  );
  const { isLoading: isLoadingProjects, data: projects } = useQuery(
    ["featured"],
    () => fetcher(`/api/projects/featured`),
  );
  const { isLoading: isLoadingActivity, data: activity } = useQuery(
    ["activity", "stats"],
    () => fetcher(`/api/activities/stats`),
  );
  const { isLoading: isLoadingRetreat, data: retreat } = useQuery(
    ["retreat", "latest"],
    () => fetcher(`/api/retreats/latest_active_retreat`),
  );
  const {
    isLoading: isLoadingNewsFeed,
    data: newsFeed,
  }: { isLoading: boolean; data: any } = useQuery(
    ["news-feeds", "last"],
    fetchNewsFeedPreview,
  );
  const isLoading =
    isLoadingRetreat ||
    isLoadingProjects ||
    isLoadingActivity ||
    isLoadingNewsFeed ||
    isLoadingAnnouncement;
  const hasCompletedGuidedTour = currentUser?.attributes.guidedTourCompleted;
  const { setIsOpen } = useTour();

  useEffect(() => {
    if (!isLoading && !hasCompletedGuidedTour) {
      setIsOpen(true);
    }
  }, [isLoading, hasCompletedGuidedTour, setIsOpen]);

  return (
    <div className="margin-safe bg-white pb-10">
      <header className="border-b border-line py-7.5 lg:py-10">
        <h1 className="font-h2 lg:font-h1 text-dark-100">
          Welcome, {currentUser?.attributes.fullName}!
        </h1>
      </header>
      <div className="mt-6.5 lg:mt-10 flex flex-col lg:flex-row gap-7.5 lg:gap-6">
        <main className="max-w-4xl flex flex-col gap-7.5 lg:gap-15 lg:flex-grow">
          {!isLoadingAnnouncement && announcement?.data && (
            <Announcement announcement={announcement.data} />
          )}
          <RetreatHero isLoading={isLoadingRetreat} retreat={retreat} />
          <FeaturedProjects isLoading={isLoadingProjects} projects={projects} />
          <MonthlyActivity isLoading={isLoadingActivity} activity={activity} />
          <NewsFeedPreview isLoading={isLoadingNewsFeed} newsFeed={newsFeed} />
        </main>
        <Aside />
      </div>
    </div>
  );
}

export default DashboardPage;
