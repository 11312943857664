import * as React from "react";
import clsx from "clsx";

function InsightCard({ children, size = "", className = "" }) {
  const classNames = clsx(
    "w-full break-inside inline-block mb-8 border border-gray-200 card-shadow p-[20px] text-[12px]",
    size == "xxxl" && "h-[650px]",
    size == "xxl" && "h-[610px]",
    size == "xl" && "h-[560px]",
    size == "lg" && "h-[475px]",
    size == "l" && "h-[460px]",
    size == "xmd" && "h-[400px]",
    size == "md" && "h-[378px]",
    size == "sm" && "h-[324px]",
    size == "xs" && "h-[275px]",
    className,
  );
  return <div className={classNames}>{children}</div>;
}

export default InsightCard;
