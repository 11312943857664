import React from "react";
import clsx from "clsx";

export function SkeletonArticle({ showInMobile }) {
  return (
    <article
      className={clsx("bg-white h-auto lg:h-[390px] animate-pulse", {
        "hidden md:block": showInMobile,
      })}
    >
      <div className="h-[215px] w-full bg-dark-25 w-full rounded-t-md " />
      <header className="p-[30px]">
        <div className="h-4 w-2/3 bg-dark-25  mb-1 rounded-full" />
        <div className="h-4 w-full bg-dark-25  mb-1 rounded-full" />
        <div className="h-4 w-full bg-dark-25 rounded-full" />
      </header>
    </article>
  );
}

function RetreatSkeletonPage() {
  return (
    <div className="animate-pulse margin-safe py-6 lg:py-10 font-lato text-dark-100 min-h-full mb-8">
      <header className="flex flex-col items-start md:items-end gap-4 md:flex-row justify-between pb-4 mb-10">
        <div>
          <div className="h-8 w-[300px] bg-dark-25  mb-1 rounded-md"></div>
          <div className="h-4 w-2/3 bg-dark-25  mt-1 rounded-full" />
        </div>
        <div className="h-[48px] w-[170px] bg-dark-25  mb-1 rounded-md" />
      </header>
      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px]">
        {[0, 1, 2, 3, 4, 5].map(element => (
          <SkeletonArticle key={element} showInMobile={element > 1} />
        ))}
      </section>
    </div>
  );
}

export default RetreatSkeletonPage;
