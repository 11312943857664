import React from "react";
import clsx from "clsx";
import Table from "@/components/shared/Table";
import { LinkableNameColumn, render } from "@/components/shared/Admin/Columns";
import { Link } from "react-router-dom";

function ListItems({ items, lastLength, field }) {
  return (
    <ul className="inline-block">
      {items?.map((item, index) => {
        const conjunction =
          index + 1 === lastLength && lastLength > 1 ? "and" : "";
        const separator = index + 1 < lastLength ? "," : "";
        const content = item.slug ? (
          <Link to={item.slug} className="link">
            {item[field]}
          </Link>
        ) : (
          item[field]
        );
        return (
          <li key={index} className="inline-block mr-1">
            {conjunction} {content}
            {separator}
          </li>
        );
      })}
    </ul>
  );
}
const itemValue = (item, column) => {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item[column.name];
  }
};

const RowSkeleton = ({ className = "" }) => (
  <tr className={clsx("!border-0", className)}>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 !whitespace-normal font-bold text-dark-75 w-[240px]">
      <div className="h-2 w-full bg-slate-200 bg-dark-25 rounded mb-2"></div>
      <div className="h-2 w-40 bg-slate-200 bg-dark-25 rounded"></div>
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75 flex">
      <div className="h-2 w-40 bg-slate-200 bg-dark-25 rounded mr-1" />
      <div className="h-2 w-40 bg-slate-200 bg-dark-25 rounded" />
    </td>
    <td className="px-6 py-4 pt-[10px] pb-[10px] pl-4 font-bold text-dark-75">
      <div className="h-2 w-[80px] bg-slate-200 bg-dark-25 rounded ml-auto"></div>
    </td>
  </tr>
);

const columns = [
  {
    name: "name",
    label: "Title",
    width: "500",
    render: (value, item) => {
      let end = "";
      if (item?.inventors.length > 0) {
        end = item?.inventors.length == 1 ? "is inventor" : "are inventors";
      }
      return (
        <div className="flex flex-col">
          <LinkableNameColumn
            text={value}
            classNameLink="text-dark-100 hover:text-primary font-bold text-14"
            to={`/inventions/${item.id}`}
            isBlank
          />
          {item?.inventors.length > 0 && (
            <span className="font-meta text-dark-75">
              <ListItems
                items={item?.inventors}
                lastLength={item?.inventors.length}
                field="name"
              />
              {end}
            </span>
          )}
        </div>
      );
    },
    td: { className: "!py-1.5 font-bold !whitespace-normal" },
    th: { className: "" },
  },
  {
    name: "licensee",
    label: "Licensee",
    width: "200",
    render: value => {
      return (
        <span className="font-meta text-dark-75">
          <ListItems items={value} lastLength={value?.length} field="company" />
        </span>
      );
    },
    td: { className: "!whitespace-normal" },
  },
  {
    name: "formated_latest_license",
    label: "Licensed Date",
    width: "145",
    td: { className: "text-right" },
  },
];

function LicensedInventions({ data, className, isFetching }) {
  return (
    <div
      className={clsx("bg-white border border-lines rounded shadow", className)}
    >
      <header className="px-5 pt-3.75">
        <h2 className="text-dark-100 text-22">Licensed Inventions</h2>
      </header>
      <Table className="border border-transparent mb-3 px-5" fixed>
        <Table.Header className="bg-transparent text-dark-50">
          {columns.map((column, i) => (
            <Table.HeaderCell
              key={i}
              className={clsx(
                "text-11 pb-1 border-b border-lines",
                column.th?.className,
              )}
              width={column.width}
            >
              {column.label}
            </Table.HeaderCell>
          ))}
        </Table.Header>
        <Table.Body>
          {isFetching ? (
            <>
              <RowSkeleton />
              <RowSkeleton className="bg-tint-10" />
              <RowSkeleton />
              <RowSkeleton className="bg-tint-10" />
            </>
          ) : (
            data &&
            data.map((item, rowIndex) => (
              <Table.Row
                key={rowIndex}
                className={clsx("!border-0", { "bg-tint-10": rowIndex % 2 })}
              >
                {columns.map((column, columnIndex) => (
                  <Table.Cell
                    key={columnIndex}
                    className={column.td?.className}
                    expand
                  >
                    {render(itemValue(item, column), column, item, rowIndex)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

export default LicensedInventions;
