import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik-latest";
import { denormalizedFetcher, saveResource } from "@/utils/api";
import useFlashMessage from "@/hooks/useFlashMessage";
import SlideOver from "@/components/shared/SlideOver/SlideOver";
import UpdateConfirmationModal from "./UpdateConfirmationModal";
import BasicInfo from "./EditFormParts/BasicInfo";
import Tabs from "@/components/shared/Tabs";
import { FilterOption } from "@/types";
import ProfessionalInfo from "./EditFormParts/ProfessionalInfo";
import ProjectsInfo from "./EditFormParts/ProjectsInfo";
import EmailPreferences from "./EditFormParts/EmailPreferences";
import SuspendConfirmationModal from "./SuspendConfirmationModal";
import paramBuilder from "@/utils/network/paramBuilder";
import Loading from "@/components/shared/Loading";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";
import useCurrentUser from "@/hooks/useCurrentUser";

const STAFF_ROLES = ["Employee", "PiciAdmin"];
const ARCHIVABLE_ROLES = ["Guest", "Employee", "PiciAdmin", "CenterAdmin"];

const fetchUsers = ({ queryKey }) => {
  const [_, id] = queryKey;
  const baseUrl = `/api/users`;
  const params = paramBuilder({
    include: [
      "focusAreas",
      "center",
      "projects",
      "institution",
      "projectMemberships",
    ],
    fields: {
      users: [
        "fullName",
        "firstName",
        "lastName",
        "title",
        "bio",
        "email",
        "role",
        "suspended",
        "center",
        "centerId",
        "institution",
        "focusAreas",
        "createdAt",
        "publicAt",
        "lastLoggedInAgo",
        "ipDisclosure",
        "ipDisclosureMetadata",
        "ipDisclosureUrl",
        "participationAgreement",
        "participationAgreementMetadata",
        "participationAgreementUrl",
        "cvOrBiosketch",
        "cvOrBiosketchMetadata",
        "cvOrBiosketchUrl",
        "compliance",
        "type",
        "paperworkRequired",
        "hhmiInvestigator",
        "notes",
        "avatar",
        "avatarMetadata",
        "avatarUrl",
        "projects",
        "centerNameOrInstitution",
        "phoneNumber",
        "googleScholarUrl",
        "projectMemberships",
        "legalTeam",
        "archived",
        "partOfAnyProjectTeam",
        "partOfAnyResearch",
        "isStaff",
      ],
      focusAreas: ["name"],
      center: ["name"],
      projects: ["name", "createdAt"],
      institution: ["name"],
      projectMemberships: ["projectId", "timeAgo"],
    },
  });
  const url = `${baseUrl}/${id}?${params}`;
  return denormalizedFetcher(url);
};

const phoneRegExp = /^\(\d{1,4}\) *(\d{3}) *-*(\d{4})$/;

const defaultTabs = type =>
  [
    { id: "basicInfo", name: "Basic Info" },
    { id: "professionalInfo", name: "Professional Info" },
    { id: "projectsInfo", name: "Projects" },
    type == "PiciAdmin" && {
      id: "emailPreferences",
      name: "Email Preferences",
    },
  ].filter(Boolean);

const TabSection = ({
  tab,
  user,
  roles,
  centers,
  institutions,
  projects,
  focusAreas,
  setFieldValue,
  selectedRole,
  setSelectedRole,
  userById,
  ...props
}) => {
  switch (tab) {
    case "basicInfo":
      return (
        <BasicInfo
          {...{
            user,
            roles,
            centers,
            institutions,
            projects,
            setFieldValue,
            selectedRole,
            setSelectedRole,
            ...props,
          }}
        />
      );
    case "professionalInfo":
      return <ProfessionalInfo {...{ user, focusAreas, setFieldValue }} />;
    case "projectsInfo":
      return <ProjectsInfo {...{ user }} />;
    case "emailPreferences":
      return <EmailPreferences {...{ user }} />;
    default:
      return null;
  }
};

const UserSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .trim()
      .required("First name is required."),
    lastName: Yup.string()
      .trim()
      .required("Last name is required."),
    email: Yup.string()
      .email("Please enter a valid e-mail.")
      .trim()
      .required("Email Address is required."),
    type: Yup.string().required("Role is required."),
    center: Yup.string()
      .when(["type", "institution", "newInstitution"], {
        is: (type, institution, newInstitution) =>
          !STAFF_ROLES.includes(type) &&
          institution == null &&
          newInstitution == null,
        then: Yup.string().required("Center or Institution is required."),
      })
      .nullable(),
    institution: Yup.string()
      .when(["type", "center", "newInstitution"], {
        is: (type, center, newInstitution) =>
          !STAFF_ROLES.includes(type) &&
          center == null &&
          newInstitution == null,
        then: Yup.string().required("Center or Institution is required."),
      })
      .nullable(),
    newInstitution: Yup.string()
      .when(["type", "center", "institution"], {
        is: (type, center, institution) =>
          !STAFF_ROLES.includes(type) && center == null && institution == null,
        then: Yup.string().required("Center or Institution is required."),
      })
      .nullable(),
    project_id: Yup.string().nullable(),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable(),
    googleScholarUrl: Yup.string()
      .trim()
      .matches(
        /^https?:\/\/scholar\.google\.com\/citations\?(\w+=[\w\-]+&)*user=[\w\-]+(&\w+=[\w\-]+)*$/,
        "Enter a valid Google Scholar profile URL.",
      )
      .nullable(),
  },
  [
    ["institution", "newInstitution"],
    ["center", "newInstitution"],
    ["center", "institution"],
  ],
);

const mapRequest = (item, id = null) => {
  const request = {
    data: {
      type: "users",
      attributes: item,
      ...(id && { id: id }),
    },
  };
  return request;
};
const mapCurrentItems = current => {
  return {
    id: current?.id || "",
    fullName: current?.attributes.fullName || "",
    firstName: current?.attributes.firstName || "",
    lastName: current?.attributes.lastName || "",
    email: current?.attributes.email || "",
    title: current?.attributes.title || "",
    type: current?.attributes.type || "",
    bio: current?.attributes.bio || "",
    phoneNumber: current?.attributes.phoneNumber || "",
    center: parseInt(current?.relationships.center.data?.id) || null,
    institution: parseInt(current?.relationships.institution.data?.id) || null,
    paperworkRequired: current?.attributes.paperworkRequired || true,
    participationAgreement: current?.attributes.participationAgreement || null,
    participationAgreementMetadata:
      current?.attributes.participationAgreementMetadata || null,
    participationAgreementUrl:
      current?.attributes.participationAgreementUrl || null,
    ipDisclosure: current?.attributes.ipDisclosure || null,
    ipDisclosureMetadata: current?.attributes.ipDisclosureMetadata || null,
    ipDisclosureUrl: current?.attributes.ipDisclosureUrl || null,
    cvOrBiosketch: current?.attributes.cvOrBiosketch || null,
    cvOrBiosketchMetadata: current?.attributes.cvOrBiosketchMetadata || null,
    centerNameOrInstitution:
      current?.attributes.centerNameOrInstitution || null,
    cvOrBiosketchUrl: current?.attributes.cvOrBiosketchUrl || null,
    hhmiInvestigator: current?.attributes.hhmiInvestigator || false,
    focusAreas:
      current?.relationships.focusAreas.data?.map(focusArea => {
        return {
          id: parseInt(focusArea.id),
          name: focusArea.attributes.name,
        };
      }) || [],
    googleScholarUrl: current?.attributes.googleScholarUrl || "",
    avatar: current?.attributes.avatar || null,
    avatarMetadata: current?.attributes.avatarMetadata || null,
    avatarUrl: current?.attributes.avatarUrl || null,
    notes: current?.attributes.notes || "",
    project_id:
      parseInt(current?.relationships.projects.data?.slice(-1)[0]?.id) || null,
    projectsMembership:
      current?.relationships.projectMemberships.data?.map(projectMembership => {
        return {
          id: parseInt(projectMembership.id),
          name: current?.relationships.projects.data.find(
            el => el.id == projectMembership.attributes.projectId,
          )?.attributes.name,
          timeAgo: projectMembership.attributes.timeAgo,
        };
      }) || [],
    legalTeam: current?.attributes.legalTeam || false,
  };
};

function EditFormat({
  open,
  setOpen,
  selectData,
  user = null,
  userId = null,
  params = null,
  setSearchParams = () => {},
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
  selectData: {};
  user: any;
  userId: string | null;
  params: any;
  setSearchParams: (_: any) => void;
}) {
  const currentUser = useCurrentUser();
  const [userToEdit, setUserToEdit] = useState(user);
  const { isLoading, data: userById } = useQuery(
    ["userById", userId],
    fetchUsers,
    {
      enabled: userId !== null,
    },
  );
  const { roles, centers, focusAreas, projects, institutions } = selectData;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [tabs, _setTabs] = useState<FilterOption[]>(
    defaultTabs(currentUser.attributes.type),
  );
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const queryClient = useQueryClient();

  const showFlash = useFlashMessage({
    message: "Your user was updated",
    errorMessage: "Your user could not be updated due to an error.",
  });

  const close = useCallback(() => {
    setUserToEdit(null);
    setSelectedRole(null);
    setOpen(false);
    setShowEditModal(false);
    setShowSuspendModal(false);
  }, [setOpen]);

  const showSuspendFlash = useFlashMessage({
    message: `${userToEdit?.attributes.fullName}'s account has been ${
      userToEdit?.attributes.suspended ? "activated" : "suspended"
    }.`,
  });

  const createContent = item => {
    setIsSavingUser(true);
    const request = mapRequest(item, userToEdit?.id);
    delete request.data.attributes["fileMetadata"];
    delete request.data.attributes["fileUrl"];
    delete request.data.attributes["participationAgreementMetadata"];
    delete request.data.attributes["participationAgreementUrl"];
    delete request.data.attributes["ipDisclosureMetadata"];
    delete request.data.attributes["ipDisclosureUrl"];
    delete request.data.attributes["avatarMetadata"];
    delete request.data.attributes["avatarUrl"];
    saveResource("users", request)
      .then(res => showFlash({ success: res.ok }))
      .catch(() => {
        showFlash({ success: false });
        setIsSavingUser(false);
      })
      .finally(() => {
        queryClient.invalidateQueries(["selectData"]);
        queryClient.invalidateQueries(["admin_people"]);
        close();
      });
  };
  const submit = item => {
    if (userToEdit) {
      setShowEditModal(true);
    } else {
      createContent(item);
    }
  };

  const { mutateAsync } = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: () => {
      close();
      showSuspendFlash({ success: true });
      queryClient.invalidateQueries(["admin_people"]);
    },
  });

  const onSuspend = () => {
    mutateAsync(fetch(`/api/users/${userToEdit.id}/toogleActiveStatus`));
  };

  const onArchive = () => {
    mutateAsync(fetch(`/api/users/${userToEdit.id}/toogleArchiveStatus`));
  };

  useEffect(() => {
    if (userToEdit?.attributes.type) {
      setSelectedRole(userToEdit?.attributes.type);
    }
  }, [userToEdit]);

  useEffect(() => {
    if (user == null && !isLoading) {
      setUserToEdit(userById.data);
    }
  }, [userById, user, isLoading]);

  const userArchived = userToEdit?.attributes.archived;
  const userSuspended = userToEdit?.attributes.suspended;

  const archiveConditionals =
    currentUser &&
    currentUser.attributes.type == "PiciAdmin" &&
    userSuspended &&
    ARCHIVABLE_ROLES.includes(userToEdit?.attributes.type);

  return (
    <SlideOver {...{ open, setOpen }}>
      {userToEdit ? (
        <Formik
          initialValues={mapCurrentItems(userToEdit)}
          onSubmit={submit}
          validationSchema={UserSchema}
        >
          {({ values, setFieldValue, dirty, isSubmitting, ...props }) => {
            const disabledSaveButton = isSavingUser || !dirty;
            return (
              <Form>
                <SlideOver.Header
                  className="pb-0 border-transparent !px-8"
                  buttonClassName="mb-6"
                >
                  <span className="text-dark-100 font-medium">
                    Edit "{userToEdit?.attributes.fullName}'s" Profile
                  </span>
                  {userSuspended && (
                    <p className="font-label text-[10px] leading-[10px] px-1.5 py-0.5 bg-gold-1 text-gold-2 max-w-max rounded-3px">
                      {`Suspended ${userToEdit.attributes.role}`}
                    </p>
                  )}
                </SlideOver.Header>
                {!isSavingUser && (
                  <SlideOver.Body className="!px-0 space-y-6 pt-0">
                    <Tabs
                      {...{
                        tabs,
                        selectedTab,
                        setSelectedTab,
                        params,
                        setSearchParams,
                      }}
                      tabClassName="font-meta pb-3"
                      className="bg-white sticky top-0 z-50"
                      navClassName="px-8"
                    />
                    {selectedTab && (
                      <TabSection
                        tab={selectedTab.id}
                        user={values}
                        {...{
                          roles,
                          centers,
                          institutions,
                          setFieldValue,
                          projects,
                          focusAreas,
                          selectedRole,
                          setSelectedRole,
                          userById,
                          ...props,
                        }}
                      />
                    )}
                  </SlideOver.Body>
                )}
                <SlideOver.Footer className="absolute bottom-0 pt-0 flex flex-wrap justify-between px-4 sm:px-6 max-w-[768px]">
                  <div className="flex flex-wrap gap-3">
                    <button
                      type="submit"
                      disabled={disabledSaveButton}
                      className={clsx(
                        "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                        disabledSaveButton
                          ? "bg-dark-25 border-dark-25 text-white cursor-default pointer-events-none"
                          : "bg-primary hover:bg-primary-dark",
                      )}
                    >
                      Save changes
                    </button>
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="flex gap-2">
                    {archiveConditionals && (
                      <button
                        className={clsx(
                          "border rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium px-7.5 font-lato",
                          userArchived
                            ? "border-primary bg-white text-primary hover:bg-primary hover:text-white"
                            : "border-red-350 bg-white text-red-350 hover:bg-red-350 hover:text-white",
                          "transition-all duration-250 delay-75",
                        )}
                        type="button"
                        onClick={() => setShowArchiveModal(true)}
                      >
                        {userArchived ? "Unarchive Account" : "Archive Account"}
                      </button>
                    )}
                    {!userArchived && (
                      <button
                        className={clsx(
                          "border rounded-3px cursor-pointer inline-flex items-center justify-center text-sm font-medium px-7.5 font-lato",
                          userToEdit?.attributes.suspended
                            ? "border-primary bg-white text-primary hover:bg-primary hover:text-white"
                            : "border-lines bg-tint text-dark-75 hover:bg-white active:bg-lines",
                          "transition-all duration-250 delay-75",
                        )}
                        type="button"
                        onClick={() => setShowSuspendModal(true)}
                      >
                        {userToEdit?.attributes.suspended
                          ? "Activate Account"
                          : "Suspend Account"}
                      </button>
                    )}
                  </div>
                </SlideOver.Footer>
                <UpdateConfirmationModal
                  open={showEditModal}
                  setOpen={setShowEditModal}
                  onAccept={() => createContent(values)}
                />
                <SuspendConfirmationModal
                  open={showSuspendModal}
                  setOpen={setShowSuspendModal}
                  onAccept={onSuspend}
                  user={userToEdit}
                />
                <ArchiveConfirmationModal
                  open={showArchiveModal}
                  setOpen={setShowArchiveModal}
                  onAccept={onArchive}
                  user={userToEdit}
                />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Loading />
      )}
    </SlideOver>
  );
}

export default EditFormat;
