import * as React from "react";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  useInfiniteQueryResult,
  apiCanFetch,
} from "@/hooks/useInfiniteQueryResult";
import { useScrollPosition } from "@/hooks/useScrollPosition";
import Tabs from "./Tabs";
import ActivityList from "./ActivityList";
import clsx from "clsx";

const tabs = [
  { id: "all", name: "All" },
  { id: "account", name: "Account" },
  { id: "engagement", name: "Engagement" },
  { id: "project", name: "Project" },
  { id: "research", name: "Research" },
];

const fetchActivities = ({ pageParam = 0, queryKey }) => {
  const [key, hash] = queryKey;
  const visibility = hash.slice(1) || "all";

  const baseUrl = `api/${key}`;
  const params = paramBuilder({
    filter: {
      visibility: [visibility],
    },
  });
  const url = `${baseUrl}?page[size]=10&page[number]=${pageParam +
    1}&${params}`;
  return fetcher(url);
};

const SummaryActivity = ({ stats }) => {
  return (
    <section>
      <div className="flex space-x-4 mt-4.5 mb-1 h-20">
        {stats?.map((item, index) => (
          <div
            key={index}
            className={clsx(
              "w-[120px] py-2.5 px-5 rounded-3px transition-all duration-300",
              "border border-lines bg-tint hover:bg-white",
            )}
          >
            <a href={item.path} className="text-dark-75">
              <p className="text-4xl">{item.count}</p>
              <span className="text-dark-50 font-meta capitalize">
                {item.title}
              </span>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

const DashboardHeader = ({ tabs, selectedTab, setSelectedTab }) => {
  const { isLoading: isLoadingStats, data: stats } = useQuery(
    ["dashboard-stats"],
    () => fetcher("admin/dashboard_stats"),
  );
  return (
    <header className="w-full bg-white shadow-sm pt-3 px-7.5 inset-0 sticky">
      <div className="flex flex-1 items-baseline flex-wrap">
        <h1 className="text-dark-100 font-h2 m-0 pr-4">Dashboard</h1>
        <p className="font-meta m-0 text-dark-50">PICI Community Activity.</p>
      </div>
      {!isLoadingStats && <SummaryActivity stats={stats} />}
      <Tabs {...{ tabs, selectedTab, setSelectedTab }} className="pb-0" />
    </header>
  );
};

function AdminDashboardPage() {
  const { hash } = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    tabs.find(tab => tab.id == hash.slice(1)),
  );
  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["activities", hash], fetchActivities, {
    getNextPageParam: apiCanFetch,
  });

  const { data: activities } = useInfiniteQueryResult(data?.pages);
  const { isAtBottom } = useScrollPosition("article-container");

  useEffect(() => {
    if (isFetching) return;
    if (isAtBottom && hasNextPage) fetchNextPage();
  }, [isAtBottom, isFetching, fetchNextPage, hasNextPage]);

  return (
    <div className="bg-tint h-screen">
      <DashboardHeader tabs={tabs} {...{ selectedTab, setSelectedTab }} />
      <article id="article-container" className="overflow-x-hidden h-vh-192">
        <div className="flex-1 h-full pt-7.5 pb-3.75 px-7.5 bg-tint">
          {!isLoading && <ActivityList activities={activities} />}
        </div>
      </article>
    </div>
  );
}

export default AdminDashboardPage;
