import React, { InputHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  type?: string;
  styleAs?: "base" | "small"; // We can't put size as a props because size is an existing props of HTML Input Element
  wrapperClassName?: string;
  icon?: ReactNode | null;
}

function Input({
  error = false,
  type = "text",
  styleAs = "base",
  wrapperClassName = "",
  icon = null,
  ...props
}: InputProps) {
  return (
    <div className={clsx("flex flex-col gap-1", wrapperClassName)}>
      {icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {icon}
        </div>
      )}
      <input
        type={type}
        {...props}
        className={clsx(
          "border placeholder-dark-50 text-dark-100 border-dark-25 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary",
          styleAs === "base" && "h-12 px-3.5 py-2.5 text-17 rounded-md ",
          styleAs === "small" && "h-10 text-15 rounded",
          props.className,
          error
            ? "focus:ring-error focus:border-error ring-error border-error"
            : "focus:ring-primary focus:border-primary",
          { "pl-10": !!icon },
        )}
      />
    </div>
  );
}

export default Input;
