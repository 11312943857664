import * as React from "react";
import { useEffect } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { FilterOption } from "@/types";

const palettes = {
  primary: {
    navBorder: "",
    selectedTab: "border-primary text-dark-100",
    unselectedTab: "border-transparent text-dark-50 hover:text-dark-100",
  },
};

function Tabs({
  tabs,
  selectedTab,
  setSelectedTab,
  palette = "primary",
  className = "",
}: {
  tabs: FilterOption[];
  selectedTab: FilterOption;
  setSelectedTab: (_: FilterOption) => any;
  palette?: "primary" | "dark";
  className?: string;
}) {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  useEffect(() => {
    if (!selectedTab) setSelectedTab(tabs[0]);
  }, [selectedTab, setSelectedTab, tabs]);

  const changeTab = tab => {
    setSelectedTab(tab);
    navigate(`${path}#${tab.id}`);
  };

  return (
    <div className={className}>
      <div className="block max-w-7xl">
        <div className={palettes[palette].navBorder}>
          <nav className="flex space-x-8 h-12" aria-label="Tabs">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => changeTab(tab)}
                className={clsx(
                  tab.id === selectedTab?.id
                    ? palettes[palette].selectedTab
                    : palettes[palette].unselectedTab,
                  "whitespace-nowrap py-4 px-1 border-b-4 font-meta-bold",
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
