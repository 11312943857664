const colors = [
  "#3ab5ae",
  "#014975",
  "#ff6c2f",
  "#edb837",
  "#f37a7a",
  "#81a130",
  "#4a4189",
  "#a6e2d7",
  "#ff966b",
  "#958cd8",
  "#f3d484",
  "#0277bd",
];

export default colors;
