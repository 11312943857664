import React, { useState } from "react";
import * as R from "ramda";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { denormalizedFetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import EventSkeletonContent from "./EventSkeletonContent";
import EventsTable from "./EventsTable";
import { Events, SortMovement } from "./interfaces";
import {
  animateElementMovement,
  sendPositions,
  generateSortData,
} from "./sortEvents";
import EventsForm from "./EventsForm";
import AdminHeader from "@/components/shared/Admin/AdminHeader";

const REMOVE_CLASSNAMES = [
  "-translate-y-full",
  "translate-y-full",
  "ease-in",
  "duration-250",
];

const DEFAULT_EVENT = {
  attributes: {
    title: "",
    link: "",
    location: "",
    hostedAt: "",
    archived: false,
  },
};

const fetchEvents = () => {
  const baseUrl = `/api/events`;
  const params = paramBuilder({
    include: ["user"],
    fields: {
      events: [
        "position",
        "createdAt",
        "hostedAt",
        "id",
        "title",
        "archived",
        "link",
        "user",
        "location",
      ],
      user: ["fullName", "avatarUrl"],
    },
  });
  const url = `${baseUrl}?${params}`;
  return denormalizedFetcher(url);
};

function EventsByTpe(event: { attributes: { archived: boolean } }) {
  const eventArchived = event.attributes?.archived;
  return eventArchived ? "archived" : "active";
}

function EventsPage() {
  const [isSorting, setIsSorting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [resource, setResource] = useState(DEFAULT_EVENT);
  const [editing, setEditing] = useState(false);

  const openForm = (resource = DEFAULT_EVENT, editing = false) => {
    setResource(resource);
    setEditing(editing);
    setShowForm(true);
  };
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: events,
  }: { isLoading: boolean; data: any } = useQuery(["events"], fetchEvents);

  const byArchived = R.groupBy(EventsByTpe);
  const { active, archived } = byArchived(events?.data || []);

  const handleSortingEvents = async (
    element: Events,
    movement: SortMovement,
  ) => {
    setIsSorting(true);
    generateSortData(element, movement).then(
      ({ positions, selectedRow, selectedCloseRow }) => {
        sendPositions(positions).then(() => {
          animateElementMovement(selectedRow, selectedCloseRow, movement).then(
            () => {
              queryClient.invalidateQueries(["events"]).then(() => {
                selectedRow?.classList.remove(...REMOVE_CLASSNAMES);
                selectedCloseRow?.classList.remove(...REMOVE_CLASSNAMES);
              });
              setIsSorting(false);
            },
          );
        });
      },
    );
  };

  return (
    <div className="bg-tint h-screen overflow-hidden">
      <AdminHeader
        title="Upcoming Events"
        description="Manage upcoming events that are relevant to PICI members."
        linkText="+ New Event"
        linkTo={() => openForm()}
      />
      {isLoading ? (
        <EventSkeletonContent />
      ) : (
        <article
          id="article-container"
          className="overflow-x-hidden h-vh-60 px-7.5"
        >
          <EventsTable
            events={active}
            tableTitle="Active"
            showDisplay
            displayActions={handleSortingEvents}
            disabledSorting={isSorting}
            openForm={openForm}
          />
          <EventsTable
            events={archived}
            tableTitle="Archived"
            openForm={openForm}
          />
        </article>
      )}
      <EventsForm
        openForm={showForm}
        setOpenForm={setShowForm}
        resource={resource}
        editing={editing}
        setEditing={setEditing}
      />
    </div>
  );
}
export default EventsPage;
