import React, { useState, useEffect, useMemo } from "react";
import Filter from "@/components/shared/Filter";
import SelectGroup from "@/components/shared/SelectGroup";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import paramBuilder from "@/utils/network/paramBuilder";
import FilterToolbar from "@/components/shared/FilterToolbar";

import { useDebounce } from "use-debounce";

const sortOptions = [
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "Date, Oldest First", id: "createdAt" },
  { name: "Title, A to Z", id: "name" },
  { name: "Title, Z to A", id: "-name" },
];

const compliantOptions = [
  { name: "All Research Studies", id: "all" },
  { name: "Is Compliant", id: "true" },
  { name: "Is Not Compliant", id: "false" },
];

const statusOptions = [
  { name: "Archived", id: "true" },
  { name: "Not Archived", id: "false" },
  { name: "All", id: "all" },
];

const makeDataToOptions = (data, name) => {
  return data?.map(item => {
    return { name: item.attributes[name], id: item.id };
  });
};

function ResearchStudiesFilter({ total, isLoading, setFilters }) {
  const [titleFilter, setTitleFilter] = useState("");
  const [debouncedTitleFilter] = useDebounce(titleFilter, 500);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedCompliant, setSelectedCompliant] = useState(
    compliantOptions[0],
  );
  const [selectedCenter, setSelectedCenter] = useState<FilterOption[]>([]);
  const [centerFocus, setCenterFocus] = useState(false);

  const [selectedFocusArea, setSelectedFocusArea] = useState<FilterOption[]>(
    [],
  );
  const [focusAreaFocus, setFocusAreaFocus] = useState(false);

  const { data: centersData } = useQuery(["centerFilters"], () =>
    fetcher("/api/centers"),
  );
  const centerOptions = useMemo(
    () => makeDataToOptions(centersData?.data, "shorthand"),
    [centersData],
  );

  const { data: focusAreaData } = useQuery(["focusAreasFilter"], () =>
    fetcher("/api/focus-areas?fields[centers]=shorthand"),
  );

  const focusAreaOptions = useMemo(
    () => makeDataToOptions(focusAreaData?.data, "name"),
    [focusAreaData],
  );

  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        name: debouncedTitleFilter,
        compliant: selectedCompliant?.id,
        focus_areas: selectedFocusArea.map(t => t.id),
        centers: selectedCenter.map(s => s.id),
        ...(selectedStatus &&
          selectedStatus.id !== "all" && { archived: selectedStatus.id }),
      },
      sort: [selectedSort?.id],
    });
    setFilters(filters);
  }, [
    setFilters,
    debouncedTitleFilter,
    selectedSort,
    selectedCompliant,
    selectedFocusArea,
    selectedCenter,
    selectedStatus,
  ]);

  return (
    <FilterToolbar
      total={total}
      filterText={titleFilter}
      setFilterText={setTitleFilter}
      isLoading={isLoading}
    >
      <Filter
        name="focusArea"
        items={focusAreaOptions}
        selectedItems={selectedFocusArea}
        setSelectedItems={setSelectedFocusArea}
        highlight={focusAreaFocus}
        setFocus={setFocusAreaFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">Focus Area</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={focusAreaOptions}
            selectedOptions={selectedFocusArea}
            setSelectedOptions={setSelectedFocusArea}
            className="focus-area-checkbox-group"
            labelClassName="sm:w-325px !py-1"
          >
            <div>
              <a
                href="/admin/focus_areas"
                className="p-2 btn-sm btn-white ml-3 mt-1 font-normal hover:text-white hover:bg-primary border-primary"
              >
                + Create New Focus Area
              </a>
            </div>
          </CheckboxGroup>
        </Filter.Content>
      </Filter>
      <Filter
        name="compliant"
        items={compliantOptions}
        selectedItems={selectedCompliant}
        setSelectedItems={setSelectedCompliant}
        className="flex-1 mr-2"
        closeOnSelect
      >
        <Filter.Label size="sm">
          {selectedCompliant?.name || compliantOptions[0].name}
        </Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={compliantOptions}
            selectedOption={selectedCompliant}
            setSelectedOption={setSelectedCompliant}
          />
        </Filter.Content>
      </Filter>

      <Filter
        name="center"
        items={centerOptions}
        selectedItems={selectedCenter}
        setSelectedItems={setSelectedCenter}
        highlight={centerFocus}
        setFocus={setCenterFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">All Centers</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={centerOptions}
            selectedOptions={selectedCenter}
            setSelectedOptions={setSelectedCenter}
            labelClassName="sm:w-325px !py-1"
          />
        </Filter.Content>
      </Filter>

      <Filter
        name="archived"
        items={statusOptions}
        selectedItems={selectedStatus}
        setSelectedItems={setSelectedStatus}
        closeOnSelect
      >
        <Filter.Label className="!h-[30px] !text-14 !font-light mr-5">
          Visibility
        </Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={statusOptions}
            selectedOption={selectedStatus}
            setSelectedOption={setSelectedStatus}
          />
        </Filter.Content>
      </Filter>

      <i className="ri-arrow-up-down-line text-xl ml-5 mr-2.5 text-black-placeholder"></i>
      <Filter
        name="sort"
        items={sortOptions}
        selectedItems={selectedSort}
        setSelectedItems={setSelectedSort}
        closeOnSelect
      >
        <Filter.Label size="sm">{selectedSort.name}</Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={sortOptions}
            selectedOption={selectedSort}
            setSelectedOption={setSelectedSort}
          />
        </Filter.Content>
      </Filter>
    </FilterToolbar>
  );
}

export default ResearchStudiesFilter;
