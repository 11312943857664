import * as React from "react";
import clsx from "clsx";

type AsideProps = {
  className?: string;
  children: React.ReactNode;
};
function Aside({ children, className = "" }: AsideProps) {
  return <aside className={clsx("space-y-7.5", className)}>{children}</aside>;
}
Aside.Section = ({ children, className = "" }: AsideProps) => {
  return (
    <section
      className={clsx(
        "bg-tint rounded-lg p-6 divide-y divide-dark-25 lg:w-320px flex flex-col gap-6",
        className,
      )}
    >
      {children}
    </section>
  );
};
Aside.Content = ({ children, className = "" }: AsideProps) => {
  return <div className={clsx("pt-6 first:pt-0", className)}>{children}</div>;
};
Aside.Title = ({
  title,
  className = "",
}: {
  title: React.ReactNode;
  className?: String;
}) => {
  return (
    <p className={clsx("font-body-bold text-dark-100", className)}>{title}</p>
  );
};
Aside.Body = ({ children, className = "" }: AsideProps) => {
  return <div className={clsx("", className)}>{children}</div>;
};

export default Aside;
