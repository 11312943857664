import React, { useState } from "react";
import clsx from "clsx";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { uniqueId } from "lodash";
import SectionSelectorsModal from "./SectionSelectorsModal";
import {
  KeySection,
  SectionMode,
} from "@/components/shared/Retreat/Article/defaults";
import { defaultContentByKeySection } from "../defaults";
import TextSectionEditor from "@/components/shared/Retreat/Article/SectionEditors/TextSectionEditor";
import TableSectionEditor from "@/components/shared/Retreat/Article/SectionEditors/TableSectionEditor";
import ImageSectionEditor from "@/components/shared/Retreat/Article/SectionEditors/ImageSectionEditor";
import DraggableWrapper from "@/components/shared/DraggableWrapper";

const SectionBuilder = ({ section, values, onChange, index }) => {
  switch (section.keySection) {
    case KeySection.SmallHeader:
    case KeySection.LargeHeader:
    case KeySection.ReferenceText:
      return (
        <DraggableWrapper
          section={section}
          index={index}
          key={section.position}
        >
          <TextSectionEditor
            section={section}
            values={values}
            onChange={onChange}
          />
        </DraggableWrapper>
      );
    case KeySection.BasicTable:
      return (
        <DraggableWrapper
          section={section}
          index={index}
          key={section.position}
        >
          <TableSectionEditor
            section={section}
            values={values}
            onChange={onChange}
          />
        </DraggableWrapper>
      );
    case KeySection.ImageFullWidth:
    case KeySection.ImageTwoColumns:
      return (
        <DraggableWrapper
          section={section}
          index={index}
          key={section.position}
        >
          <ImageSectionEditor
            section={section}
            values={values}
            onChange={onChange}
          />
        </DraggableWrapper>
      );
    default:
      return <code key={section.position}>{JSON.stringify(section)}</code>;
  }
};

const reorder = (list, startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((section, index) => {
    section.position = index;
  });

  return result;
};

function ArticleSections({ values, onChange }) {
  const [openSelectorModal, setOpenSelectorModal] = useState<boolean>(false);

  const handleAddSection = (keySection: KeySection) => {
    onChange("articleSections", [
      ...values.articleSections,
      {
        keySection: keySection,
        position: values.articleSections.length,
        content: defaultContentByKeySection(keySection),
        viewMode: SectionMode.Edit,
        provisionalId: uniqueId("dragable_"),
      },
    ]);
    setOpenSelectorModal(false);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedArticleSections = reorder(
      values.articleSections,
      result.source.index,
      result.destination.index,
    );

    onChange("articleSections", reorderedArticleSections);
  };

  return (
    <>
      <div className="flex-1 text-right py-3">
        <button
          type="button"
          className="btn-white btn-md top-4 left-4 text-14 h-8 px-3"
          onClick={() => setOpenSelectorModal(true)}
        >
          <i className="ri-add-line ri-lg text-dark-75 mr-1"></i>
          <span className="text-14 font-lato">Add Section</span>
        </button>
      </div>
      <SectionSelectorsModal
        isOpenModal={openSelectorModal}
        setOpenModal={setOpenSelectorModal}
        onAccept={handleAddSection}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={clsx(
                snapshot.isDraggingOver ? "bg-gray-200" : "bg-white",
              )}
            >
              {values?.articleSections.map((section, index) => (
                <SectionBuilder
                  key={section.position}
                  index={index}
                  section={section}
                  values={values}
                  onChange={onChange}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default ArticleSections;
