import * as React from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import * as R from "ramda";
import moment from "moment";
import useTrackVisit from "@/hooks/useTrackVisit";
import useCurrentUser from "@/hooks/useCurrentUser";
import ErrorFallback from "./ErrorFallback";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import useToast from "@/hooks/useToast";
import Table from "@/components/shared/Table";
import ResourceListSkeleton from "./ResourceListSkeleton";
import { Link } from "react-router-dom";
import PopoverMenu from "@/components/shared/PopoverMenu";

const isRecently = dateString => {
  return moment().diff(moment(dateString), "days") <= 30;
};

const fetchCategories = () => {
  const baseUrl = "/api/categories";
  const categoriesParams = paramBuilder({
    include: ["mediaContents"],
    fields: {
      categories: ["name", "slug", "type", "mediaContents"],
      mediaContents: ["title", "type", "description"],
    },
    filter: {
      type: "ResourceCategory",
    },
  });
  const url = `${baseUrl}?${categoriesParams}`;
  return denormalizedFetcher(url);
};

function ResourceCard({ resource, showToast }) {
  const resourceLink =
    resource.type === "linkResources"
      ? resource.attributes.link
      : resource.attributes.fileUrl;

  const fileSize =
    resource.attributes.fileSize !== "N/D" && resource.attributes.fileSize;

  const recently = isRecently(resource.attributes.updatedAt);

  return (
    <Table.Row>
      <Table.Cell expand={false} className="pr-2 align-top">
        <a
          className="w-10 h-10 bg-dark-50 rounded-full text-white cursor-pointer grid place-items-center self-start"
          href={resourceLink}
          target="_blank"
        >
          {resource.type === "linkResources" ? (
            <i className="ri-global-line text-xl"></i>
          ) : (
            <i className="ri-file-line text-xl"></i>
          )}
        </a>
      </Table.Cell>
      <Table.Cell expand={false} className="pl-2 w-full min-w-500px">
        <a
          className="group inline-flex items-center whitespace-nowrap"
          href={resourceLink}
          target="_blank"
        >
          <h4 className="link text-dark-100 font-body-bold group-hover:underline">
            {resource.attributes.title}
          </h4>
          {resource.type === "linkResources" && (
            <i className="ri-external-link-line text-base font-thin text-dark-75 ml-2" />
          )}
        </a>

        {resource.type === "linkResources" && (
          <p className="font-meta text-dark-75">
            {resource.attributes.description}
          </p>
        )}
      </Table.Cell>
      <Table.Cell className="px-2 text-center min-w-100px">
        {resource.type === "fileResources" && (
          <p className="text-sm text-dark-75">{fileSize}</p>
        )}
      </Table.Cell>
      <Table.Cell className="px-2 min-w-180px">
        {recently && (
          <span className="w-max rounded bg-secondary-light text-secondary-dark font-label py-0.5 px-2.5 truncate block m-auto">
            Recently Added
          </span>
        )}
      </Table.Cell>
      <Table.Cell className="pl-2 text-right">
        <PopoverMenu resource={resource} {...{ showToast }}>
          <button className="btn-icon btn-icon-sm">
            <i className="ri-links-line"></i>
          </button>
        </PopoverMenu>
      </Table.Cell>
    </Table.Row>
  );
}

function CategoryCard({ category, showToast }) {
  const byUpdateAt = R.comparator(
    (a, b) => b.attributes.updatedAt < a.attributes.updatedAt,
  );

  const resources = R.sort(
    byUpdateAt,
    category.relationships.mediaContents.data,
  );

  const resourceCount = resources.length;

  if (resourceCount == 0) {
    return null;
  }

  return (
    <article className="mt-7 md:mt-10">
      <h3 className="mb-5 font-bold text-xl">
        <span className="text-dark-100 font-h2">
          {category.attributes.name}{" "}
        </span>
        <span className="font-h3 text-dark-50">({resourceCount})</span>
      </h3>
      <Table>
        <Table.Body>
          {resources.map(resource => (
            <ResourceCard
              resource={resource}
              key={resource.id}
              {...{ showToast }}
            />
          ))}
        </Table.Body>
      </Table>
    </article>
  );
}

function ResourcesPageCategories({ showToast }) {
  const {
    isLoading,
    data: categories,
  }: { isLoading: boolean; data: any } = useQuery(
    ["categories"],
    fetchCategories,
  );
  return (
    <main>
      <section>
        <div className="pt-7.5 md:pt-10 max-w-4xl">
          <h2 className="pb-1 font-h3 text-dark-100">
            Parker Institute Resources and Templates at Your Fingertips
          </h2>
          <p className="text-dark-75 font-body">
            We’ve created resources to support the work you do with PICI. Find
            everything from information on discounts for supplies and equipment
            to PICI-branded presentation templates.
          </p>
        </div>
        {!isLoading ? (
          <section className="flex flex-col">
            {categories.data.map(category => (
              <CategoryCard
                category={category}
                key={category.id}
                {...{ showToast }}
              />
            ))}
          </section>
        ) : (
          <div className="mt-10 md:mt-14">
            <ResourceListSkeleton />
            <ResourceListSkeleton />
          </div>
        )}
      </section>
    </main>
  );
}

function ResourcesPageContent() {
  useTrackVisit("resources");
  const currentUser = useCurrentUser();
  const showToast = useToast({
    successText: (
      <>
        A link to the resource has been copied to your clipboard. <br />
        Click ⌘ + v or Ctrl + v to paste.
      </>
    ),
    errorText: "Could not copy the resource link to your clipboard.",
  });

  return (
    <div className="margin-safe py-6 lg:py-10 font-lato text-dark-100 min-h-full">
      <header className="flex flex-col items-start gap-4 md:flex-row justify-between pb-7.5 md:pb-10 border-b border-lines">
        <h1 className="font-h2 md:font-h1 text-dark-100 font-bold">
          Resources
        </h1>
        {currentUser?.attributes.isStaff && (
          <Link
            to="/admin/media_resources?actions=new"
            className="btn-sm btn-primary"
          >
            <span className="mr-3">+</span> New Resource
          </Link>
        )}
      </header>
      <ResourcesPageCategories {...{ showToast }} />
    </div>
  );
}

function ResourcesPage() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ResourcesPageContent />
    </Sentry.ErrorBoundary>
  );
}

export default ResourcesPage;
