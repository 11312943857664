import * as React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import AsideComponent from "@/components/shared/Aside";

function Aside({ research }) {
  const { createdAt, status } = research.attributes;
  const formatedCreatedAt = format(new Date(createdAt), "MMM dd, yyyy");

  const posterAttributes = research.relationships.poster.data?.attributes;
  const posterLinks = research.relationships.poster.data?.links;
  const centerName = `${posterAttributes?.centerNameOrInstitution} ${
    posterAttributes?.centerNameOrInstitution !==
    posterAttributes?.centerShorthandOrInstitution
      ? `(${posterAttributes?.centerShorthandOrInstitution})`
      : ""
  }`;

  return (
    <AsideComponent className="ml-0 mt-0 lg:mt-10 mb-7.5 lg:ml-7.5 lg:mb-0">
      <AsideComponent.Section className="sm:w-80 w-full p-6 bg-tint rounded-lg">
        <AsideComponent.Content>
          <AsideComponent.Title title={status} />
          <AsideComponent.Body>
            <p className="font-meta text-dark-75 mt-1">{formatedCreatedAt}</p>
          </AsideComponent.Body>
        </AsideComponent.Content>
        <AsideComponent.Content>
          <AsideComponent.Title title="Posted by:" className="pb-3.5" />
          <AsideComponent.Body className="flex gap-3">
            <Link to={posterLinks?.show} className="min-w-max">
              <img
                src={posterLinks?.avatar}
                alt="avatar"
                className="rounded-full w-10 h-10"
              />
            </Link>
            <div className="flex flex-col">
              <Link
                to={posterLinks?.show}
                className="link font-meta-bold text-dark-100"
              >
                {posterAttributes?.fullName}
              </Link>
              {posterLinks?.centerShow ? (
                <Link
                  to={posterLinks?.centerShow}
                  className="link font-micro text-dark-75"
                >
                  {centerName}
                </Link>
              ) : (
                <p className="font-micro text-dark-75">{centerName}</p>
              )}
            </div>
          </AsideComponent.Body>
        </AsideComponent.Content>
      </AsideComponent.Section>
    </AsideComponent>
  );
}

export default Aside;
