import * as React from "react";
import { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";
import { saveResource } from "@/utils/api";
import {
  DotsHorizontalIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import Select from "@/components/shared/Select";

function NewsItemCard({
  newsItem,
  categories,
  newsFeedId,
  setShowDeleteModal,
  setCurrentNewsItem,
  isDraft,
  setFormIsOpen,
}) {
  const queryClient = useQueryClient();
  const { title, categoryId, shortType } = newsItem.attributes;
  const [updatingCategory, setUpdatingCategory] = useState(false);

  const updateCategoryRequest = categoryId => {
    const request = {
      data: {
        type: "news-items",
        id: newsItem.id,
        attributes: { categoryId: categoryId },
      },
    };
    return saveResource("news-items", request);
  };

  const mutation = useMutation(updateCategoryRequest, {
    onSettled: async () => {
      await queryClient.invalidateQueries(["news-feeds", newsFeedId]);
      setUpdatingCategory(false);
    },
  });

  const handleUpdateCategory = categoryId => {
    mutation.mutate(categoryId);
  };

  const handleDeleteNewsItem = () => {
    setCurrentNewsItem(newsItem.id);
    setShowDeleteModal(true);
  };

  const handleEdit = () => {
    setCurrentNewsItem(newsItem);
    setFormIsOpen(true);
  };

  return (
    <div
      className={clsx(
        "py-6 px-6 flex gap-1",
        updatingCategory && "bg-yellow-50",
      )}
    >
      <div className="-ml-2 -mt-1 p-2 rounded-md text-gray-600 w-8 h-8 grid place-content-center hover:bg-gray-100 transition-colors">
        <span className="sr-only">Drag items</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 1024 1024"
          className="fill-current"
        >
          <path d="M273.066 204.8c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM750.933 204.8c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM273.066 614.4c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM750.933 614.4c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM273.066 1024c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4zM750.933 1024c-56.554 0-102.4-45.846-102.4-102.4s45.846-102.4 102.4-102.4c56.554 0 102.4 45.846 102.4 102.4s-45.846 102.4-102.4 102.4z"></path>
        </svg>
      </div>
      <div className="flex-grow">
        <div className="flex space-x-3 justify-between mb-4">
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-gray-600 ">{title}</h2>
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              {shortType}
            </span>
          </div>
          <Menu as="div" className="relative z-30 inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button
                    data-cy={`news-item-options-${newsItem.id}`}
                    className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600 border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Open options</span>
                    <DotsHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1 flex flex-col">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={clsx(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "flex px-4 py-2 text-sm",
                            )}
                            onClick={handleEdit}
                          >
                            <PencilAltIcon
                              className="mr-3 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Edit</span>
                          </button>
                        )}
                      </Menu.Item>
                      {isDraft && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleDeleteNewsItem}
                              className={clsx(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "flex px-4 py-2 text-sm",
                              )}
                            >
                              <TrashIcon
                                className="mr-3 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Delete</span>
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        <div className="max-w-xs mt-4">
          <Select
            options={categories}
            selected={categoryId}
            setSelected={categoryId => {
              setUpdatingCategory(true);
              handleUpdateCategory(categoryId);
            }}
            accessor={option => option.name}
            data-cy={`item-${title.split(" ").join("-")}-categories`}
          />
        </div>
      </div>
    </div>
  );
}

export default NewsItemCard;
