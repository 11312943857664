import * as React from "react";
import { Link } from "react-router-dom";

function BreadcrumbContent({ text }) {
  return (
    <>
      <i className="ri-arrow-left-s-line ri-lg text-dark-75 mr-1" />
      <span className="font-meta text-dark-100 hover:underline">{text}</span>
    </>
  );
}

function Breadcrumb({
  text,
  to,
  onClick,
}: {
  text: string;
  to?: string;
  onClick?: () => void;
}) {
  return (
    <div className="py-5 border-b border-dark-25 border-opacity-60">
      <div className="w-max">
        {to && (
          <Link to={to} className="flex items-center">
            <BreadcrumbContent text={text} />
          </Link>
        )}
        {onClick && (
          <button type="button" onClick={onClick} className="flex items-center">
            <BreadcrumbContent text={text} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Breadcrumb;
