import * as React from "react";
import { useState } from "react";
import SearchBar from "@/components/shared/SearchBar";
import Select from "@/components/shared/Select";

function CenterOrInstitutionInput({
  values,
  onChange,
  centers,
  institutions,
  setTouched,
}) {
  const [selectedCenters, setSelectedCenters] = useState(values.center);
  const [selectedInstitutions, setSelectedInstitutions] = useState(
    values.institution,
  );
  const [showInstitutions, setShowInstitutions] = useState<boolean>(
    selectedCenters == "others",
  );

  const [institutionText, setInstitutionText] = useState<string>(
    institutions.find(el => el.id == selectedInstitutions)?.name,
  );

  const onCenterSelect = value => {
    if (value === "others") {
      setSelectedCenters("others");
      setSelectedInstitutions(null);
      setShowInstitutions(true);
    } else {
      setSelectedCenters(value);
      showInstitutions && setShowInstitutions(false);
    }
    nullInstitution();
    onChange("center", value != "others" ? value : null);
  };

  const onInstitutionSelect = value => {
    if (!value.name) {
      onChange("newInstitution", value);
      onChange("institution", null);
    } else {
      onChange("newInstitution", null);
      onChange("institution", value.id);
      setSelectedInstitutions(value.id);
      setInstitutionText(value.name);
    }
    onChange("center", null);
  };

  const nullInstitution = () => {
    setInstitutionText("");
    onChange("newInstitution", null);
    onChange("institution", null);
  };
  return (
    <>
      <label className="font-body-bold">Center</label>
      <Select
        selected={selectedCenters}
        setSelected={(centerId: number) => onCenterSelect(centerId)}
        options={centers}
        placeholder="Select a Center"
        className="h-10 text-15 rounded font-body"
        placement="top-end"
      />
      {showInstitutions && (
        <>
          <SearchBar
            className="mt-4 bg-white"
            options={institutions || []}
            matchCriteria={(input, itemSearchable) =>
              itemSearchable
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            onTextChange={item => {
              setInstitutionText(item);
              onChange("institution", null);
              onChange("newInstitution", item);
              setTouched(true);
            }}
            placeholder="Type Institutions name"
            accessor={item => item.name}
            onSelect={institute => onInstitutionSelect(institute)}
            displayNewExternal
            selected={institutionText}
          >
            {({ item, index }) => {
              return index === "external" ? (
                <div className="p-3 cursor-pointer flex flex-col">
                  <span className="text-dark-100">
                    + Add "{item}" Insitutions
                  </span>
                </div>
              ) : (
                <div className="px-6 py-4 cursor-pointer flex sm:space-x-5 hover:bg-gray-50 hover:text-indigo-500">
                  <span className="font-body max-w-md">{item.name}</span>
                </div>
              );
            }}
          </SearchBar>
        </>
      )}
    </>
  );
}

export default CenterOrInstitutionInput;
