import * as React from "react";
import clsx from "clsx";

function Label({
  htmlFor = "",
  disabled = false,
  name = "",
  optional = false,
  hint = "(Optional)",
  className = "",
}: {
  htmlFor?: string;
  name: string;
  disabled?: boolean;
  optional?: boolean;
  hint?: string;
  className?: string;
}) {
  return (
    <>
      <label
        htmlFor={htmlFor}
        className={clsx("block mb-0 font-bold text-15", {
          "text-dark-100": !disabled,
          "text-dark-50": disabled,
          [className]: !!className,
        })}
      >
        {name}{" "}
        {optional && (
          <span
            className={clsx("text-14 inline-block font-normal ml-1.5", {
              "text-dark-75": !disabled,
              "text-dark-50": disabled,
            })}
          >
            {hint}
          </span>
        )}
      </label>
    </>
  );
}

export default Label;
