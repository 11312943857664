import * as R from "ramda";

const compare = (x, y) => x.id === y.id && x.type === y.type;

function diff(included, newData) {
  return R.differenceWith(compare, newData, included);
}

function useInfiniteQueryResult(response) {
  let data: any[] = [];
  let included: any[] = [];
  let meta: { [_: string]: any } = {};
  if (response) {
    response.map(group => {
      if (group.data.length > 0) {
        data = data.concat(group.data);
        if (group.included) {
          included = included.concat(diff(included, group.included));
        }
        meta = group.meta;
      }
    });
  }

  return { data, included, meta };
}

const apiCanFetch = (lastPage, pages) => {
  return lastPage.links?.next === undefined ? false : pages.length;
};

export { useInfiniteQueryResult, apiCanFetch };
