import React, { useMemo } from "react";
import Hero from "../../shared/Hero";
import ChartPage from "../../Examples/ChartPage";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import InsightCard from "../../shared/InsightCard";
import SimpleAreaChart from "@/components/shared/Charts/SimpleAreaChart";
import PieChart from "@/components/shared/Charts/PieChart";
import HeaderCards from "../../shared/HeaderCard/HeaderCards";
import InsightBarChart from "@/components/shared/Charts/BarChart/InsightBarChart";
import InsightScatterChart from "@/components/shared/Charts/ScatterChart";
import InsightLineChart from "@/components/shared/Charts/LinesChart";
import InsightSkeleton from "@/components/pages/InsightsPage/InsightPage/InsightSkeleton";

const calculateResearchesByYearAggregate = researchByYear =>
  researchByYear.reduce((acumulator, currentValue) => {
    const lastItem =
      acumulator.length > 0 ? acumulator[acumulator.length - 1] : { count: 0 };
    acumulator.push({
      ...currentValue,
      count: currentValue.count + lastItem.count,
    });
    return acumulator;
  }, []);

const calculateResearchTypeTotal = researchTypes =>
  researchTypes.reduce(
    (acumulator, currentValue) => ({
      researchPaperTotal:
        acumulator.researchPaperTotal + currentValue.research_paper_count,
      abstractTotal: acumulator.abstractTotal + currentValue.abstract_count,
      posterTotal: acumulator.posterTotal + currentValue.poster_count,
      slideDeckTotal: acumulator.slideDeckTotal + currentValue.slide_deck_count,
      otherTotal: acumulator.otherTotal + currentValue.other_count,
    }),
    {
      researchPaperTotal: 0,
      abstractTotal: 0,
      posterTotal: 0,
      slideDeckTotal: 0,
      otherTotal: 0,
    },
  );

function ResearchInsight() {
  const { data, isLoading } = useQuery(["researchInsight"], () =>
    fetcher("/api/research-documents/insights").then(json => {
      return json;
    }),
  );

  const researchTypeTotal = useMemo(
    () => calculateResearchTypeTotal(data?.researches_by_type || []),
    [data],
  );
  const researchesByYearAggregate = useMemo(
    () => calculateResearchesByYearAggregate(data?.researches_by_year || []),
    [data],
  );

  const dataLinesResearchByType = [
    {
      dataKey: "research_paper_count",
      dataLabel: "Research Paper",
      totalCount: researchTypeTotal?.researchPaperTotal,
    },
    {
      dataKey: "abstract_count",
      dataLabel: "Abstract",
      totalCount: researchTypeTotal?.abstractTotal,
    },
    {
      dataKey: "poster_count",
      dataLabel: "Poster",
      totalCount: researchTypeTotal?.posterTotal,
    },
    {
      dataKey: "slide_deck_count",
      dataLabel: "Slide Deck",
      totalCount: researchTypeTotal?.slideDeckTotal,
    },
    {
      dataKey: "other_count",
      dataLabel: "Other",
      totalCount: researchTypeTotal?.otherTotal,
    },
  ];

  return (
    <>
      <Hero
        title="Research"
        description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore
        nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus
        omnis, molestiae esse placeat, exercitationem magnam quod
        molestias quia aspernatur venenatis diam."
      >
        <ChartPage />
      </Hero>
      {isLoading ? (
        <InsightSkeleton />
      ) : (
        <div className="margin-safe p-6 lg:p-10 lg:pb-10 min-h-full">
          <div className="masonry md:masonry-md xl:masonry-xl">
            <InsightCard size="sm">
              <HeaderCards
                title="Total Numbers of Research Documents"
                subtitle={data?.researches_size || "--"}
              />
              <SimpleAreaChart
                dataKey="name"
                data={researchesByYearAggregate || []}
              />
            </InsightCard>
            <InsightCard size="md">
              <HeaderCards title="Research Items by Focus Area" />
              <PieChart
                data={data?.researches_by_focus_area || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>
            <InsightCard size="lg">
              <HeaderCards title="Research Type" />
              <InsightLineChart
                data={data?.researches_by_type || []}
                dataLines={dataLinesResearchByType}
                xDataKey="created_year"
              />
            </InsightCard>

            <InsightCard size="xs">
              <HeaderCards
                title="Research Totals by Date Uploaded"
                titleClassname="mb-4"
              />
              <SimpleAreaChart
                dataKey="name"
                data={data?.researches_by_year || []}
              />
            </InsightCard>

            <InsightCard size="md">
              <HeaderCards title="Research by Status" />
              <PieChart
                data={data?.researches_by_status || []}
                dataKey="count"
                innerRadius={0}
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards
                title="Average Number of Authors"
                titleClassname="mb-4"
              />
              <InsightBarChart
                data={data?.researches_by_authors}
                dataKey="name"
              />
            </InsightCard>
            <InsightCard size="xs">
              <HeaderCards
                title="Average Number of Viewers"
                titleClassname="mb-4"
              />
              <InsightScatterChart
                data={data?.research_viewers_by_month || []}
              />
            </InsightCard>
          </div>
        </div>
      )}
    </>
  );
}

export default ResearchInsight;
