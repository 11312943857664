import * as React from "react";
import FormBlock from "@/components/shared/FormBlock";
import TextArea from "@/components/shared/TextArea";
import clsx from "clsx";
import Input from "@/components/shared/Input";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import FilePickerAdmin from "@/components/shared/FilePickerAdmin";

function ProfessionalInfo({ user, focusAreas, setFieldValue }) {
  return (
    <section className="flex flex-col gap-5.5 px-8">
      <FormBlock label="Title" name="title" labelClassName="font-body" optional>
        <Input value={user.title} styleAs="small" />
      </FormBlock>
      <FormBlock label="Bio" name="bio" labelClassName="font-body" optional>
        <TextArea
          className={clsx(
            "block w-full py-9px px-3.5 rounded-3px text-15 border border-dark-25 max-h-20 resize-none",
            "focus:shadow-sm focus:outline-none focus:ring-0 focus:border-primary !text-dark-100 min-h-75px leading-5",
          )}
          value={user.bio}
        />
      </FormBlock>
      <FormBlock
        label="Focus Areas"
        name="focusAreas"
        labelClassName="font-body"
        optional
      >
        <CheckboxGroup
          options={focusAreas}
          selectedOptions={user.focusAreas}
          setSelectedOptions={areas => setFieldValue("focusAreas", areas)}
          className="flex flex-col h-auto flex-nowrap w-full sm:flex-wrap sm:h-[550px]"
        />
      </FormBlock>
      <FormBlock
        type="file"
        label="CV / Biosketch"
        name="cvOrBiosketch"
        labelClassName="font-body pb-2"
        optional
      >
        <FilePickerAdmin
          setSignedId={val => setFieldValue("cvOrBiosketch", val)}
          attachment={{
            signedId: user.cvOrBiosketch,
            metadata: user.cvOrBiosketchMetadata,
            imageUrl: user.cvOrBiosketchUrl,
          }}
          name="cvOrBiosketch"
        />
      </FormBlock>
      <FormBlock
        label="Google Scholar"
        name="googleScholarUrl"
        className="flex-1"
        labelClassName="font-body"
        optional
      >
        <Input
          value={user.googleScholarUrl}
          styleAs="small"
          placeholder="Paste your Google Scholar URL profile"
        />
      </FormBlock>
    </section>
  );
}

export default ProfessionalInfo;
