import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import paramBuilder from "@/utils/network/paramBuilder";
import Filter from "@/components/shared/Filter";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import SelectGroup from "@/components/shared/SelectGroup";
import FilterTags from "@/components/shared/FilterTags";
import { FilterOption } from "@/types";

const sortOptions = [
  { name: "Date, Last Updated", id: "-updatedAt" },
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "Date, Oldest First", id: "createdAt" },
  { name: "Title, A to Z", id: "name" },
  { name: "Title, Z to A", id: "-name" },
];

const FiltersSkeleton = () => (
  <div className="animate-pulse pr-3.5 mb-10">
    <div className="flex gap-4">
      <div className="bg-dark-25 h-10.5 lg:h-12.5 w-1/3 md:w-1/6 rounded-md" />
      <div className="bg-dark-25 h-10.5 lg:h-12.5 w-1/3 md:w-1/6 rounded-md" />
      <div className="bg-dark-25 h-10.5 lg:h-12.5 w-1/3 md:w-1/6 rounded-md mr-auto" />
      <div className="bg-dark-25 h-10.5 lg:h-12.5 sm:w-1/3 md:w-1/5 rounded-md hidden sm:block" />
    </div>
  </div>
);

function Filters({ setFilters }) {
  const { isLoading, data: filtersData } = useQuery(["projectsFilters"], () =>
    fetcher("/api/projects/filters"),
  );
  const [selectedCenters, setSelectedCenters] = useState<FilterOption[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<FilterOption[]>([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState<FilterOption[]>(
    [],
  );
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  const [centerFocus, setCenterFocus] = useState(false);
  const [typeFocus, setTypeFocus] = useState(false);
  const [focusAreaFocus, setFocusAreaFocus] = useState(false);

  const selectedFilters =
    selectedCenters.length + selectedTypes.length + selectedFocusAreas.length;

  const params = paramBuilder({
    filter: {
      centers: selectedCenters.map(x => x.id),
      type: selectedTypes.map(x => x.id),
      focusAreas: selectedFocusAreas.map(x => x.id),
      archived: false,
    },
    sort: [selectedSort?.id],
  });

  useEffect(() => {
    setFilters(params);
  }, [params, setFilters]);

  return (
    <>
      {isLoading ? (
        <FiltersSkeleton />
      ) : (
        <div className="grid gap-10 mb-10">
          <div className="flex">
            <div className="sm:flex sm:space-x-4 w-full sm:w-auto">
              <div className="flex space-x-4 mb-4 sm:mb-0">
                <Filter
                  name="center"
                  items={filtersData?.centers}
                  selectedItems={selectedCenters}
                  setSelectedItems={setSelectedCenters}
                  setFocus={setCenterFocus}
                  className="flex-1"
                >
                  <Filter.Label>Centers</Filter.Label>
                  <Filter.Content>
                    <CheckboxGroup
                      options={filtersData?.centers}
                      selectedOptions={selectedCenters}
                      setSelectedOptions={setSelectedCenters}
                      className="filter-checkbox-group"
                    />
                  </Filter.Content>
                </Filter>
                <Filter
                  name="projectType"
                  items={filtersData?.types}
                  selectedItems={selectedTypes}
                  setSelectedItems={setSelectedTypes}
                  setFocus={setTypeFocus}
                  className="flex-1"
                >
                  <Filter.Label>Project Type</Filter.Label>
                  <Filter.Content>
                    <CheckboxGroup
                      options={filtersData?.types}
                      selectedOptions={selectedTypes}
                      setSelectedOptions={setSelectedTypes}
                      className="filter-checkbox-group"
                    />
                  </Filter.Content>
                </Filter>
              </div>
              <Filter
                name="focusArea"
                items={filtersData?.focusAreas}
                selectedItems={selectedFocusAreas}
                setSelectedItems={setSelectedFocusAreas}
                setFocus={setFocusAreaFocus}
                className="flex-1"
              >
                <Filter.Label>Focus Area</Filter.Label>
                <Filter.Content>
                  <CheckboxGroup
                    options={filtersData?.focusAreas}
                    selectedOptions={selectedFocusAreas}
                    setSelectedOptions={setSelectedFocusAreas}
                    className="focus-area-checkbox-group"
                    labelClassName="sm:w-325px"
                  />
                </Filter.Content>
              </Filter>
            </div>
            <div className="hidden sm:flex sm:ml-auto sm:items-center">
              <span className="hidden md:inline-block text-right mx-4 text-dark-100 font-body-bold">
                Sort by:
              </span>

              <Filter
                name="sort"
                items={sortOptions}
                selectedItems={selectedSort}
                setSelectedItems={setSelectedSort}
                closeOnSelect
              >
                <Filter.Label>{selectedSort?.name}</Filter.Label>
                <Filter.Content>
                  <SelectGroup
                    options={sortOptions}
                    selectedOption={selectedSort}
                    setSelectedOption={setSelectedSort}
                  />
                </Filter.Content>
              </Filter>
            </div>
          </div>
          {selectedFilters > 0 && (
            <div className="flex flex-wrap -mb-2.5">
              <FilterTags
                options={selectedCenters}
                setOptions={setSelectedCenters}
                isFocused={centerFocus}
              />
              <FilterTags
                options={selectedTypes}
                setOptions={setSelectedTypes}
                isFocused={typeFocus}
              />
              <FilterTags
                options={selectedFocusAreas}
                setOptions={setSelectedFocusAreas}
                isFocused={focusAreaFocus}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Filters;
