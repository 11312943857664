import * as Yup from "yup";

const ResearchDocumentSchema = Yup.object().shape({
  file: Yup.string().required("Research file is required."),
  title: Yup.string()
    .trim()
    .required("Research title is required.")
    .max(
      280,
      "The Research title entered has exceeded the 280 character limit.",
    ),
  researchType: Yup.string().required("Research type is required."),
  status: Yup.string().required("Research status is required."),
  publicationLink: Yup.string()
    .when("status", {
      is: "Published or presented",
      then: Yup.string().url(
        "A valid URL is required. Make sure you've included http:// or https://.",
      ),
    })
    .nullable(),
  focusAreas: Yup.array().min(1, "At least one focus area is required."),
});

export default ResearchDocumentSchema;
