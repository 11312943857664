import React from "react";
import FullWideForm from "@/components/shared/FullWideForm";

const Input = ({ className = "" }) => {
  return (
    <div className={className}>
      <div className="w-[100px] h-2 bg-dark-25 mb-2 rounded"></div>
      <div className="h-8 bg-dark-25 rounded"></div>
    </div>
  );
};
function SkeletonForm() {
  return (
    <FullWideForm className="animate-pulse">
      <FullWideForm.header>
        <button
          type="button"
          className="absolute btn-white btn-md top-4 left-4 text-14 h-8 px-3"
        >
          <p className="h-2 bg-dark-25 block rounded w-[100px]"></p>
        </button>
        <div className="admin-intro ellipsis mx-auto w-full flex justify-between max-w-[964px] py-4">
          <h3 className="w-[160px] my-2 h-4 bg-dark-25 rounded"></h3>
          <button type="button" className="btn-white btn-md text-14 h-8 px-3">
            <p className="h-2 bg-dark-25 block rounded w-[100px]"></p>
          </button>
        </div>
      </FullWideForm.header>
      <FullWideForm.body>
        <div>
          <article className="flex p-7 pb-10 border-b">
            <header className="w-[260px] pr-20">
              <h3 className=" w-[160px] mb-2 h-2 bg-dark-25 rounded"></h3>
              <p className="h-2 mb-2 bg-dark-25 block rounded w-full"></p>
              <p className="w-[180px] h-2 bg-dark-25 block rounded"></p>
            </header>
            <section className="flex-1">
              <Input className="w-full mb-6" />
              <Input className="mb-6" />
              <div className="flex justify-between mb-6">
                <Input className="w-1/2 mr-3" />
                <Input className="w-1/2 ml-3" />
              </div>
              <Input className="mb-6" />
              <Input className="mb-6" />
            </section>
          </article>
          <article className="flex p-7 pb-10 border-b">
            <header className="w-[260px] pr-20">
              <h3 className="w-[160px] mb-2 h-2 bg-dark-25 rounded"></h3>
              <p className="h-2 mb-2 bg-dark-25 block rounded w-full"></p>
              <p className="w-[180px] h-2 bg-dark-25 block rounded"></p>
            </header>
            <section className="flex-1 flex flex-col">
              <button
                type="button"
                className="btn-white btn-md top-4 left-4 text-14 h-8 px-3 max-w-max mb-6 ml-auto"
              >
                <p className="h-2 bg-dark-25 block rounded w-[100px]"></p>
              </button>
              <div className="flex gap-5">
                {[1, 2].map(index => (
                  <div key={index} className="bg-white rounded shadow-lg w-52">
                    <div className="h-48 p-3 overflow-hidden rounded bg-dark-25 animate-pulse"></div>
                    <div className="p-3 h-">
                      <div className="flex flex-col mt-2">
                        <div className="h-8 bg-dark-25 rounded animate-pulse mb-6"></div>
                        <div className="h-8 bg-dark-25 rounded animate-pulse w-2/3"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </article>
        </div>
      </FullWideForm.body>
      <FullWideForm.footer>
        <div className="mx-auto w-full max-w-[964px]">
          <div className="ml-72 flex">
            <p className="h-4 mb-2 bg-dark-25 block rounded w-[90px] mr-4"></p>
            <p className="h-4 mb-2 bg-dark-25 block rounded w-[90px]"></p>
            <p className="h-4 mb-2 bg-dark-25 block rounded w-[90px] ml-auto"></p>
          </div>
        </div>
      </FullWideForm.footer>
    </FullWideForm>
  );
}

export default SkeletonForm;
