import React from "react";

type PillProps = {
  children: React.ReactNode;
  classNames?: string;
  testid?: string;
};

function Pill({ children, classNames, testid = "" }: PillProps) {
  return (
    <div
      className={`text-14 px-2 bg-blue-100 text-gray-800 rounded-full my-auto font-bold min-w-40px min-h-20px h-auto text-center ${classNames}`}
      data-testid={testid}
    >
      {children}
    </div>
  );
}

export default Pill;
