import * as React from "react";
import clsx from "clsx";

function NoResults({
  entityName,
  icon,
  fullText,
  className,
}: {
  entityName?: string;
  icon: string;
  fullText?: string;
  className?: string;
}) {
  return (
    <article
      className={clsx(
        "bg-tint rounded-lg flex flex-col items-center text-center py-20 px-6",
        className,
      )}
    >
      <i className={clsx(icon, "ri-3x leading-none text-dark-25")}></i>
      <span className="font-h2 mt-8 mb-1 text-dark-100">No Results</span>
      <p className="font-body text-dark-75">
        {fullText
          ? fullText
          : `We couldn't find any ${entityName} matching your criteria`}
      </p>
    </article>
  );
}

export default NoResults;
