import clsx from "clsx";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Card = ({ amount, mailIncluded, title, onClick, tab, selectedTab }) => {
  const cardClassnames = clsx(
    "bg-white border-b-2 border-lines border-b-white text-left flex rounded shadow w-1/3 font-bold pt-4 pb-3 px-6 hover:border-b-indigo-600",
    { "!border-b-indigo-600 border": tab.id === selectedTab.id },
  );
  return (
    <button onClick={() => onClick(tab)} className={cardClassnames}>
      <h3 className="text-4xl mr-5 mt-2.5">{mailIncluded}</h3>
      {/* hide per request 
        "can you tweak that PR to only show the 5 (active)? 
        We don't need to show the full number of all the shown ones, I've fully confirmed this with the Partner."
        https://linear.app/pici/issue/PIC-810/counter-number-change */}
      {/* <div className="divide-y-2 divide-black-tint">
        <h3 className="text-4xl mr-5 min-w-40px text-center">{amount}</h3>
      </div> */}
      <h2 className="text-base leading-normal font-normal">{title}</h2>
    </button>
  );
};

const SkeletonCard = () => (
  <button className="bg-white border border-lines flex rounded shadow w-1/3 font-bold py-4 px-6 animate-pulse">
    <h3 className="text-4xl mr-5 w-[40px] h-[40px]">
      <div className="h-full w-full bg-slate-200 rounded"></div>
    </h3>
    <h2 className="text-lg leading-normal font-normal">
      <div className="h-3 w-[110px] bg-slate-200 rounded mb-2"></div>
      <div className="h-3 w-[80px] bg-slate-200 rounded"></div>
    </h2>
  </button>
);

function Tabs({ tabs, isFetching, selectedTab, setSelectedTab }) {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  function onHandleClick(tab) {
    navigate(`${path}?tab=${tab.id}`);
    setSelectedTab(tab);
  }

  return (
    <div className="flex max-w-[936px] space-x-4">
      {isFetching
        ? [1, 2, 3, 4].map(index => <SkeletonCard key={index} />)
        : tabs.map(tab => (
            <Card
              key={tab.id}
              amount={tab.amount}
              mailIncluded={tab.mailIncluded}
              title={tab.name}
              onClick={onHandleClick}
              tab={tab}
              selectedTab={selectedTab}
            />
          ))}
    </div>
  );
}

export default Tabs;
