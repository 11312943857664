import * as React from "react";

function ChartExampleTwo() {
  return (
    <svg
      width="243"
      height="242"
      viewBox="0 0 243 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.33337" width="240" height="240" rx="120" fill="white" />
      <circle
        cx="121.333"
        cy="120"
        r="120"
        fill="#EDB641"
        stroke="white"
        strokeWidth="2.4"
      />
      <path
        d="M241.333 120C241.333 147.763 231.707 174.666 214.095 196.127C196.482 217.588 171.973 232.278 144.744 237.694C117.515 243.11 89.2502 238.918 64.7658 225.831C40.2813 212.743 21.0921 191.571 10.4678 165.922C-0.156468 140.273 -1.55853 111.733 6.50053 85.1658C14.5596 58.5987 31.5812 35.6477 54.6649 20.2237C77.7487 4.79956 105.466 -2.14338 133.095 0.577831C160.724 3.29904 186.555 15.516 206.186 35.1472L121.333 120H241.333Z"
        fill="#3FB5AE"
        stroke="white"
        strokeWidth="2.4"
      />
      <path
        d="M241.333 120C241.333 144.229 233.999 167.891 220.295 187.872C206.591 207.854 187.159 223.219 164.556 231.946C141.953 240.673 117.237 242.353 93.6612 236.766C70.085 231.178 48.7523 218.585 32.4697 200.643C16.1871 182.7 5.71729 160.249 2.43773 136.243C-0.841843 112.236 3.22243 87.7996 14.0959 66.1473C24.9693 44.4949 42.1425 26.6412 63.3562 14.935C84.57 3.22882 108.83 -1.7815 132.946 0.563207L121.333 120H241.333Z"
        fill="#014B74"
        stroke="white"
        strokeWidth="2.4"
      />
      <path
        d="M241.333 120C241.333 139.069 236.789 157.864 228.077 174.826C219.364 191.788 206.735 206.43 191.235 217.538C175.735 228.646 157.812 235.901 138.949 238.7C120.087 241.499 100.829 239.763 82.7709 233.635C64.7133 227.507 48.376 217.164 35.113 203.463C21.85 189.762 12.0432 173.097 6.50527 154.85C0.967329 136.603 -0.142263 117.298 3.26845 98.5367C6.67916 79.7751 14.512 62.0965 26.1177 46.9659L121.333 120H241.333Z"
        fill="#685BC7"
        stroke="white"
        strokeWidth="2.4"
      />
      <path
        d="M241.333 120C241.333 137.855 237.349 155.485 229.671 171.604C221.993 187.724 210.814 201.927 196.95 213.178C183.086 224.429 166.886 232.444 149.531 236.64C132.176 240.836 114.104 241.105 96.6314 237.43L121.333 120H241.333Z"
        fill="#FE6A36"
        stroke="white"
        strokeWidth="2.4"
      />
    </svg>
  );
}

export default ChartExampleTwo;
