import React from "react";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import { Link, useLocation } from "react-router-dom";
import DetailedOverviewSkeleton from "./DetailedOverviewSkeleton";
import NoResults from "@/components/shared/NoResults";
import ToogleButton from "@/components/shared/ToogleButton";
import clsx from "clsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function itemValue(item, column) {
  if (column.accessor) {
    return column.accessor(item, column.name);
  } else {
    return item[column.name];
  }
}

function render(value, column, item) {
  if (column.render) {
    return column.render(value, item);
  } else {
    return value;
  }
}

const ShowInMailing = ({ currentInvention }) => {
  let query = useQuery();
  const type = query.get("tab");

  const { showInPatentMailing } = currentInvention;
  const queryClient = useQueryClient();

  const mutation = useMutation((mutationFn: any) => mutationFn, {
    onSuccess: async () => {
      const oldPagedData: any = queryClient.getQueryData([
        "patent_deadline",
        type,
      ]);
      oldPagedData?.data.forEach(invention => {
        if (invention.id === currentInvention.id) {
          invention.showInPatentMailing = !showInPatentMailing;
          return true;
        }
      });
      queryClient.invalidateQueries(["patent_deadline_count"]);
    },
  });

  const handlePublic = () => {
    mutation.mutateAsync(
      fetch(
        `/api/inventions/${currentInvention.id}/toggle_inclusion_in_mailing`,
      ),
    );
  };

  return (
    <ToogleButton
      active={showInPatentMailing}
      disabled={mutation.isLoading}
      onClick={handlePublic}
    />
  );
};

const Registered = () => (
  <a className="flex items-center text-green-450">
    Registered{" "}
    <div className="ml-2 bg-green-450 rounded-full h-4 w-4 flex justify-center items-center">
      <div className="text-xs flex">
        <i className="ri-check-line ri-1x leading-none text-white"></i>
      </div>
    </div>
  </a>
);

const Warning = ({ id }) => (
  <a href={`/admin/inventions/${id}/edit`} className="ml-2" target="_blank">
    <i className="ri-alert-fill text-lg h-6 w-6 leading-none"></i>
  </a>
);

const OverdueDate = ({ date, overdue, id }) => {
  return (
    <div
      className={clsx(
        "text-sm flex",
        overdue ? "text-indigo-600" : "text-dark-50",
      )}
    >
      {format(new Date(date), "MMM dd, yyyy")}
      {overdue && <Warning id={id} />}
    </div>
  );
};

const columns = [
  {
    label: "Name",
    name: "title",
    th: { className: "pt-1 pl-4 text-11 " },
    td: {
      className:
        "pt-[10px] pb-[10px] pl-4 !whitespace-normal font-bold text-dark-75 w-[300px]",
    },
    accessor: item => item,
    render: value => (
      <Link to={`/inventions/${value.id}`} className="link">
        {value.identifier.concat(": ", value.title)}
      </Link>
    ),
  },
  {
    label: "Provisional filing",
    name: "patentLifecycle",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 whitespace-nowrap",
    },
    render: lifeCycle => {
      const date = lifeCycle
        .sort((a, b) => parseFloat(a.date) - parseFloat(b.date))
        .find(item => item["phase"] == "provisional_filing").date;
      return (
        <span className="text-sm text-dark-50">
          {date && format(new Date(date), "MMM dd, yyyy")}
        </span>
      );
    },
  },
  {
    label: "PCT filing",
    name: "pctConversionDeadline",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 whitespace-nowrap",
    },
    accessor: item => item,
    render: value => {
      return (
        <>
          {value.pctConversionDeadline ? (
            <OverdueDate
              date={value.pctConversionDeadline}
              overdue={value.pctOverdue}
              id={value.id}
            />
          ) : (
            <Registered />
          )}
        </>
      );
    },
  },
  {
    label: "National filing",
    name: "nationalStageDeadline",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 whitespace-nowrap",
    },
    accessor: item => item,
    render: value => {
      return (
        <>
          {value.nationalStageDeadline ? (
            <OverdueDate
              date={value.nationalStageDeadline}
              overdue={value.nationalOverdue}
              id={value.id}
            />
          ) : (
            <Registered />
          )}
        </>
      );
    },
  },
  {
    label: "Include in Mailing",
    name: "nationalStageDeadline",
    th: { className: "pt-1 pl-4 text-11" },
    td: {
      className: "pt-[10px] pb-[10px] pl-4 whitespace-nowrap",
    },
    accessor: item => item,
    render: value => {
      return <ShowInMailing currentInvention={value} />;
    },
  },
];

function DetailedOverview({ data, isFetching }) {
  return (
    <>
      {isFetching && data.length == 0 && <DetailedOverviewSkeleton />}
      {!isFetching && data.length == 0 && (
        <NoResults
          className="!bg-white"
          fullText="There are no records for this section"
          icon="ri-search-line"
        />
      )}
      {!isFetching && data.length > 0 && (
        <Table className="border border-transparent text-sm  mb-8 px-5">
          <Table.Header className="bg-transparent text-dark-50">
            {columns.map((column, i) => (
              <Table.HeaderCell
                {...column.th}
                width={column["width"]}
                key={i}
                className={column.th?.className}
              >
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Header>
          <Table.Body className="border border-lines">
            {data.map((item, i) => (
              <Table.Row key={i} className="divide-x divide-lines">
                {columns.map((column, j) => (
                  <Table.Cell key={j} className={column.td?.className}>
                    {render(itemValue(item, column), column, item)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default DetailedOverview;
