import React, { ReactNode } from "react";
import Input from "../Input";
import clsx from "clsx";
import Pill from "@/components/shared/Pill";

type FilterToolbarProps = {
  total?: number;
  children: ReactNode;
  placeHolderText?: string;
  filterText?: string;
  setFilterText?: (text: string) => void;
  isLoading?: boolean;
  className?: string;
  inputClassName?: string;
};

function FilterToolbar({
  total = 0,
  children,
  placeHolderText = "Find by title",
  filterText = "",
  setFilterText = _ => {},
  isLoading = false,
  className = "",
  inputClassName = "",
}: FilterToolbarProps) {
  const handleOnChangeTextFilter = event => {
    setFilterText(event.target.value);
  };

  return (
    <div
      className={clsx(
        "flex justify-between text-14 shadow-md",
        "bg-tint h-12 my-auto py-2.5 px-8",
        className,
      )}
    >
      <div className="flex">
        <Pill classNames="mr-5 flex-none" testid="clinical-trial-count">
          {isLoading ? (
            <i className="animate-spin absolute ri-loader-line left-11"></i>
          ) : (
            total
          )}
        </Pill>
        <i className="ri-filter-line text-xl mr-2.5 text-black-placeholder"></i>
        {children}
      </div>
      <div className="float-right relative">
        <Input
          placeholder={placeHolderText}
          className={clsx(
            "h-30px !text-14 rounded-2xl pr-7 min-w-224px",
            inputClassName,
          )}
          onChange={handleOnChangeTextFilter}
          value={filterText}
        />
        <i className="ri-search-line absolute right-3 top-1 text-black-placeholder"></i>
      </div>
    </div>
  );
}

export default FilterToolbar;
