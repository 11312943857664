import React from "react";
import clsx from "clsx";

type SectionGroupProps = {
  title: string;
  description: string;
  className?: string;
  children: React.ReactNode;
};

function SectionGroup({
  title,
  description,
  className,
  children,
}: SectionGroupProps) {
  return (
    <article className={clsx("p-6", className)}>
      <header className="float-left pr-20 w-64">
        <h3 className="text-17 font-bold text-neutral-1">{title}</h3>
        <p className="text-14 text-dark-75">{description}</p>
      </header>
      <section className="ml-64">{children}</section>
    </article>
  );
}

export default SectionGroup;
