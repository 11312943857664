import * as React from "react";
import { useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { denormalizedFetcher } from "@/utils/api";
import { FilterOption } from "@/types";
import paramBuilder from "@/utils/network/paramBuilder";
import Overview from "./Overview";
import Inventors from "./Inventors";
import ReferenceMaterials from "./ReferenceMaterials";
import Tabs from "@/components/shared/Tabs";
import Aside from "./Aside";
import NotFoundPage from "@/components/App/NotFoundPage";
import Breadcrumb from "@/components/shared/Breadcrumb";

const defaultTabs = [{ id: "overview", name: "Overview" }];

const fetchInvention = ({ queryKey }) => {
  const id = queryKey[1];
  if (id) {
    const baseUrl = `/api/inventions/${id}`;
    const params = paramBuilder({
      include: ["users", "externalUsers"],
      fields: {
        inventions: [
          "formattedIp",
          "inventionClass",
          "applicationCategory",
          "identifier",
          "title",
          "abstractNotes",
          "abstractLink",
          "referenceMaterial",
          "referenceMaterialFiles",
          "users",
          "externalUsers",
          "patentLifecycle",
          "disclosure",
          "review",
          "licensees",
          "publishedPatentApplication",
          "identificationNumbers",
          "notes",
          "technologyTypeReadable",
          "antibodySubtypeReadable",
          "cellTherapySubtypeReadable",
          "diseaseIndicationReadable",
          "biomarkersReadable",
          "biomarkerTechnologySubtypeReadable",
          "therapeuticStrategyReadable",
          "manuscriptLink",
          "updatedStatus",
        ],
        focusAreas: ["name"],
        users: ["fullName", "centerNameOrInstitution", "slug", "projectsCount"],
        externalUsers: ["name", "center"],
      },
    });
    const url = `${baseUrl}?${params}`;
    return denormalizedFetcher(url);
  }
  return null;
};

const TabSection = ({ tab, invention }: { tab: string; invention: any }) => {
  switch (tab) {
    case "overview":
      return <Overview invention={invention} />;
    case "inventors":
      return <Inventors invention={invention} />;
    case "referenceMaterials":
      return <ReferenceMaterials invention={invention} />;
    default:
      return null;
  }
};

function InventionDetail() {
  const { id } = useParams();
  const [tabsLoading, setTabsLoading] = useState(true);
  const [tabs, setTabs] = useState<FilterOption[]>(defaultTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { isLoading, data }: { isLoading: boolean; data: any } = useQuery(
    ["invention", id],
    fetchInvention,
  );

  const invention = data?.data;
  const inventorsCount =
    invention?.relationships.users.data.length +
    invention?.relationships.externalUsers.data.length;
  const displayReferencialMaterialTab =
    invention?.attributes.referenceMaterial.length > 0 ||
    invention?.attributes.notes ||
    invention?.attributes.identificationNumbers.length > 0;

  useLayoutEffect(() => {
    if (invention) {
      const newTabs: FilterOption[] = [];
      if (inventorsCount > 0) {
        newTabs.push({ id: "inventors", name: "Inventors" });
      }
      if (displayReferencialMaterialTab) {
        newTabs.push({ id: "referenceMaterials", name: "Reference Materials" });
      }
      setTabs([...defaultTabs, ...newTabs]);
      setTabsLoading(false);
    }
  }, [invention, inventorsCount, displayReferencialMaterialTab]);

  if (data?.errors) return <NotFoundPage />;

  return (
    <div className="margin-safe mb-12">
      <Breadcrumb to="/inventions" text="Back to Inventions" />
      {!isLoading && (
        <div className="flex lg:flex-row flex-col gap-0 md:gap-7.5">
          <div className="flex-grow overflow-auto px-1 -mx-1 py-1 -my-1">
            {!tabsLoading && (
              <Tabs
                {...{ tabs, selectedTab, setSelectedTab }}
                className="mt-7.5 sm:mt-4.5 mb-7.5 sm:mb-10"
              />
            )}
            <section className="">
              {selectedTab && invention && (
                <TabSection tab={selectedTab.id} invention={invention} />
              )}
            </section>
          </div>
          <Aside invention={invention} />
        </div>
      )}
    </div>
  );
}

export default InventionDetail;
