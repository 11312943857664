import * as React from "react";
import Table from "@/components/shared/Table";
import { format } from "date-fns";
import clsx from "clsx";
import { Link } from "react-router-dom";
import InventionCardSkeleton from "./InventionCardSkeleton";
import AuthorList from "@/components/shared/AuthorList";
import NoResults from "@/components/shared/NoResults";
import { isEmpty } from "lodash";
import { UPDATED_REVIEW_STATUS } from "@/components/shared/Inventions/defaults";

const REVIEW_STATUS = {
  pending: "Pending",
  accepted: "Accepted",
  declined: "Declined",
};

function InventionCard({ invention, widthTitle }) {
  const {
    identifier,
    title,
    review,
    updatedAt,
    disclosure,
    updatedStatus,
  } = invention.attributes;
  const formattedDisclosureDate = format(
    new Date(disclosure.date),
    "MMM dd, yyyy",
  );
  const inventors = [
    ...invention.relationships.users?.data,
    ...invention.relationships.externalUsers?.data,
  ];
  const hasDate = "date" in review && review.date.length > 0;
  const reviewDate = hasDate
    ? format(new Date(review.date), "MMM dd, yyyy")
    : "Pending";

  const isReverted = !isEmpty(updatedStatus);

  const finalStatus = isReverted
    ? updatedStatus.review_status === "decision_reverted_accepted"
    : review.review_status === "accepted";
  return (
    <>
      <Table.Row>
        <Table.Cell expand={false}>
          <div className="max-w-lg" style={{ minWidth: widthTitle }}>
            <h3 className="mb-1">
              <Link to={invention.links.show} className="font-h3 link-primary">
                {title}
              </Link>
            </h3>
            {inventors.length > 0 && (
              <p className="font-meta text-dark-75 mb-2.5">
                <AuthorList authors={inventors} noun="inventor" />
              </p>
            )}
          </div>
        </Table.Cell>
        <Table.Cell expand={false} style={{ minWidth: 220 }}>
          <div className="font-meta text-dark-75">
            <span
              className={clsx(
                "inline-flex items-center px-3 py-0.5 rounded-full uppercase font-label text-center",
                finalStatus
                  ? "bg-secondary-light text-secondary-dark"
                  : "bg-lines text-dark-75",
              )}
            >
              {isReverted
                ? UPDATED_REVIEW_STATUS[updatedStatus.review_status]
                : REVIEW_STATUS[review.review_status]}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell expand={false} style={{ minWidth: 144 }}>
          <div className="font-meta text-dark-75">
            <span>{identifier || "--"}</span>
          </div>
        </Table.Cell>
        <Table.Cell
          expand={false}
          className="font-meta text-dark-75 text-right"
          style={{ minWidth: 150 }}
        >
          {formattedDisclosureDate}
        </Table.Cell>
        <Table.Cell
          expand={false}
          className="font-meta text-dark-75 text-right"
          style={{ minWidth: 150 }}
        >
          {isReverted
            ? format(new Date(updatedStatus.date), "MMM dd, yyyy")
            : reviewDate}
        </Table.Cell>
      </Table.Row>
    </>
  );
}

function InventionTable({
  inventions,
  isFetching,
  isFetchingNextPage,
  widthTitle,
}) {
  return (
    <>
      {!isFetching && inventions.length === 0 ? (
        <NoResults entityName="inventions" icon="ri-search-line" />
      ) : (
        <Table>
          <Table.Header>
            <Table.HeaderCell>title</Table.HeaderCell>
            <Table.HeaderCell>status</Table.HeaderCell>
            <Table.HeaderCell>invention id</Table.HeaderCell>
            <Table.HeaderCell className="text-right !pr-5">
              disclosure date
            </Table.HeaderCell>
            <Table.HeaderCell className="text-right">
              review date
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {inventions.length > 0 &&
              inventions.map(invention => (
                <InventionCard
                  widthTitle={widthTitle}
                  key={invention.id}
                  invention={invention}
                />
              ))}
            {(isFetching || isFetchingNextPage) &&
              [1, 2, 3, 4, 5, 6].map(x => (
                <InventionCardSkeleton key={x} widthTitle={widthTitle} />
              ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default InventionTable;
