import { differenceWith } from "lodash";

const mapResponseToCenter = data => ({
  id: data.id,
  name: data.attributes.name,
  shorthand: data.attributes.shorthand,
  description: data.attributes.description || "",
  location: data.attributes.location,
  time_zone: data.attributes.timeZone || "Pacific Time (US & Canada)",
  slug: data.attributes.slug,
  telephone: data.attributes.telephone,
  logo_image: data.links.logo,
  banner_image: data.links.banner,
  center_image: data.links.background,
  users: data.attributes.users,
});

const mapCenterToRequest = (
  data,
  id = null,
  userList = [],
  contactList = [],
) => {
  const attributes = {
    name: data.name,
    shorthand: data.shorthand,
    description: data.description,
    location: data.location,
    timeZone: data.time_zone,
    telephone: data.telephone,
    slug: data.slug,
    logoImage: data.logo_image?.signedId || null,
    bannerImage: data.banner_image?.signedId || null,
    centerImage: data.center_image?.signedId || null,
    centersContact: contactsToCenterContacts(userList, contactList),
  };

  const fullData = {
    type: "centers",
    attributes,
  };

  return id ? { id, ...fullData } : fullData;
};

type ContactInfoType = {
  id: number;
  role: string;
  sort_position: number;
  user: { id: number };
};

const contactsToCenterContacts = (userList, contactList) => {
  const toDelete = differenceWith(
    userList,
    contactList,
    (arr1: ContactInfoType, arr2: ContactInfoType) => arr1.id === arr2.id,
  ).map(({ role, user, id, sort_position }) => ({
    id: isNaN(id) ? null : id,
    user_id: user.id,
    role: role,
    sort_position: sort_position,
    _destroy: true,
  }));
  const toAdd = contactList.map(({ role, user, id }, index) => {
    if (
      role === null ||
      role === "" ||
      role.trim() === "" ||
      user === null ||
      user.id === ""
    )
      return null;
    return {
      id: isNaN(id) ? null : id,
      user_id: user.id,
      role: role,
      sort_position: index,
    };
  });
  return [...toDelete, ...toAdd];
};

export { mapResponseToCenter, mapCenterToRequest };
