import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/api";
import SummaryStatus from "./SummaryStatus";
import SummaryPatientEnrollment from "./SummaryPatientEnrollment";
import CurrentClinicalTrials from "./CurrentClinicalTrials";
import PatientEnrollmentByClinicalSite from "./PatientEnrollmentByClinicalSite";
import Loading from "@/components/shared/Loading";

function SummaryTab() {
  const { data: summary, isFetching } = useQuery(
    ["clinical-trials-summary"],
    () => fetcher("/api/clinical-trials/summary"),
  );
  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && (
        <div className="px-8 pt-4 pb-8">
          <div className="flex max-w-[936px]">
            <SummaryStatus statuses={summary.status} className="w-1/3" />
            <SummaryPatientEnrollment
              enrollingPatients={summary.status.enrolling_patients}
              enrollments={summary.enrollment}
              className="ml-4 w-2/3"
            />
          </div>
          <CurrentClinicalTrials
            data={summary.current_clinical_trials}
            className="mt-4 max-w-[936px]"
          />
          <PatientEnrollmentByClinicalSite
            className="mt-4 max-w-[936px]"
            enrollmentByCenter={summary.enrollment_by_center}
            enrollmentByInstitution={summary.enrollment_by_institution}
            enrollmentAllInstitutions={summary.enrollment_all_institutions}
          />
        </div>
      )}
    </>
  );
}

export default SummaryTab;
