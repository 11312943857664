import * as React from "react";
import { useContext, useState } from "react";
import ResearchDocumentFormContext from "./ResearchDocumentFormContext";
import * as Yup from "yup";
import { Formik, Form } from "formik-latest";
import Label from "@/components/shared/Label";
import Table from "@/components/shared/Table";
import SearchBar from "@/components/shared/SearchBar";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import { saveResource } from "@/utils/api";
import clsx from "clsx";
import { mapRequest, useProjectOptions } from "./data";
import projectPhotoPlaceholder from "@/images/project-photo-placeholder.png";

const ResearchDocumentSchemaStep3 = Yup.object().shape({
  focusAreas: Yup.array().min(1, "At least one focus area is required."),
});

function ProjectsTable({ projects, setFieldValue }) {
  return (
    <div className="mt-7.5 sm:mt-10">
      <Table>
        <Table.Header>
          <Table.HeaderCell>Related Studies/Trials</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {projects.map((project, index) => (
            <Table.Row key={project.id}>
              <Table.Cell className="flex py-4 px-6 justify-between">
                <div className="flex gap-5 w-full">
                  <div className="relative hidden sm:block w-22 h-12 rounded bg-dark-100 overflow-hidden">
                    <img
                      src={project.banner || projectPhotoPlaceholder}
                      className={clsx(
                        project.banner
                          ? "object-cover w-full"
                          : "object-fit h-full",
                        "min-h-full min-w-full",
                      )}
                    />
                    <div
                      className={clsx(
                        "absolute inset-0",
                        project.banner && "linear-gradient",
                      )}
                      aria-hidden="true"
                    />
                  </div>
                  <span className="text-dark-100 text-15 font-bold whitespace-pre-line max-w-md">
                    {project.content}
                  </span>
                  <button
                    type="button"
                    onClick={() =>
                      setFieldValue("projects", [
                        ...projects.slice(0, index),
                        ...projects.slice(index + 1),
                      ])
                    }
                    className="ml-auto"
                  >
                    <i className="ri-delete-bin-line ri-lg text-dark-50" />
                  </button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

function ResearchDocumentFormStep3({ focusAreasOptions, setNewResearchURL }) {
  const {
    handlePrev,
    handleNext,
    researchValues,
    setResearchValues,
    filePickerState,
    fileFormRef,
  } = useContext(ResearchDocumentFormContext);
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const projectOptions = useProjectOptions(projectNameFilter);

  const handleBackButton = values => {
    setResearchValues({ ...researchValues, ...values });
    handlePrev();
  };

  const createResearch = (values: any, handleNext: any) => {
    const request = mapRequest(values);
    if (request.data) {
      saveResource("research-documents", request).then(res =>
        res.json().then(response => {
          setNewResearchURL(response.data.links.show);
          handleNext();
        }),
      );
    }
  };

  const projectOptionsFiltered = values => {
    return projectOptions?.filter(project => {
      const projectNames = values.projects.map(({ content }) => content);
      return !projectNames.includes(project.content);
    });
  };

  return (
    <Formik
      validationSchema={ResearchDocumentSchemaStep3}
      onSubmit={values => {
        const fileValues = fileFormRef?.current?.values;
        const researchValuesComplete = {
          ...researchValues,
          ...values,
          ...fileValues,
        };
        setResearchValues(researchValuesComplete);
        createResearch(researchValuesComplete, handleNext);
      }}
      initialValues={researchValues}
    >
      {formikProps => {
        const cantSubmit =
          formikProps.values.focusAreas.length === 0 ||
          filePickerState != "uploaded";

        return (
          <Form>
            <div className="mb-40">
              <Label name="Select Focus Areas" />
              <CheckboxGroup
                options={focusAreasOptions}
                selectedOptions={formikProps.values.focusAreas}
                setSelectedOptions={areas =>
                  formikProps.setFieldValue("focusAreas", areas)
                }
                className="mt-5 py-1 flex flex-col h-auto flex-nowrap w-full sm:flex-wrap sm:h-150 border border-dark-25 rounded"
              />
              <div className="mt-7.5 space-y-1">
                <Label
                  name="Add PICI Studies or Trials Related to this Research"
                  optional
                />
                <SearchBar
                  options={projectOptionsFiltered(formikProps.values)}
                  placeholder="Search by project name"
                  accessor={item => item.content}
                  matchCriteria={(input, itemSearchable) =>
                    itemSearchable
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onSelect={project =>
                    formikProps.setFieldValue("projects", [
                      ...formikProps.values.projects,
                      project,
                    ])
                  }
                  onTextChange={item => setProjectNameFilter(item)}
                >
                  {({ item }) => (
                    <div className="px-6 py-4 cursor-pointer flex sm:space-x-5">
                      <div className="relative hidden sm:block w-22 h-12 rounded bg-dark-100 overflow-hidden">
                        <img
                          src={item.banner || projectPhotoPlaceholder}
                          className={clsx(
                            item.banner
                              ? "object-cover w-full"
                              : "object-fit h-full",
                            "rounded",
                          )}
                        />
                        <div
                          className={clsx(
                            "absolute inset-0",
                            item.banner && "linear-gradient",
                          )}
                          aria-hidden="true"
                        />
                      </div>
                      <span className="text-dark-100 text-15 font-bold max-w-md">
                        {item.content}
                      </span>
                    </div>
                  )}
                </SearchBar>
              </div>
              {formikProps.values.projects.length > 0 && (
                <ProjectsTable
                  projects={formikProps.values.projects}
                  setFieldValue={formikProps.setFieldValue}
                />
              )}
            </div>

            <div className="fixed bottom-0 left-0 py-5 bg-tint w-full flex items-center border-t">
              <section className="flex items-center justify-center w-full space-x-2.5">
                <button
                  type="button"
                  className="btn-white btn-sm"
                  onClick={() => handleBackButton(formikProps.values)}
                >
                  Back
                </button>
                <button
                  className={clsx("btn-sm", {
                    "btn-primary": !cantSubmit,
                    "bg-dark-25 text-white cursor-default": cantSubmit,
                  })}
                  type="submit"
                  disabled={cantSubmit}
                >
                  Submit
                </button>
              </section>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ResearchDocumentFormStep3;
