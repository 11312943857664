import * as React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow, parseISO } from "date-fns";
import paramBuilder from "@/utils/network/paramBuilder";
import { denormalizedFetcher } from "@/utils/api";
import FocusAreaTags from "@/components/shared/FocusAreaTags";
import Breadcrumb from "@/components/shared/Breadcrumb";

const fetchPeriodicUpdate = ({ queryKey }) => {
  const slug = queryKey[1];
  if (slug) {
    const baseUrl = `/api/periodic-updates/${slug}`;
    const params = paramBuilder({
      include: ["project", "readers"],
      fields: {
        periodicUpdates: [
          "highlights",
          "createdAt",
          "subject",
          "project",
          "readers",
        ],
        users: ["fullName", "title"],
      },
    });
    const url = `${baseUrl}?${params}`;
    return denormalizedFetcher(url);
  }
};

const fetchProject = ({ queryKey }) => {
  const slug = queryKey[1];
  if (slug) {
    const baseUrl = `/api/projects/${slug}`;
    const params = paramBuilder({
      include: ["focusAreas", "projectManagers"],
      fields: {
        projects: ["codename", "focusAreas", "identifier", "projectManagers"],
        focusAreas: ["name"],
        users: ["fullName", "title"],
      },
    });
    const url = `${baseUrl}?${params}`;
    return denormalizedFetcher(url);
  }
};

function Aside({ periodicUpdate }) {
  const readers = periodicUpdate.relationships.readers.data;

  return (
    <aside className="ml-0 md:ml-7.5 mb-7.5 md:mb-0 mt-4 md:mt-0">
      <section className="bg-tint rounded-lg p-6 md:w-320px">
        {readers.length > 0 && (
          <div>
            <h4 className="font-body-bold text-dark-100">Read by:</h4>
            <div className="mt-3.5 space-y-5">
              {readers.map(reader => (
                <div key={reader.id} className="flex items-center">
                  <img
                    src={reader.links.avatar}
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="ml-3">
                    <Link to={reader.links.show} className="link">
                      <p className="font-meta-bold text-dark-100">
                        {reader.attributes.fullName}
                      </p>
                    </Link>
                    <p className="font-micro text-dark-75">
                      {reader.attributes.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </aside>
  );
}

function UpdateDescription({ project, periodicUpdate }) {
  const { createdAt, highlights, subject } = periodicUpdate.attributes;
  const managers = project?.relationships.projectManagers.data;
  const manager = managers?.length > 0 ? managers[0] : null;
  const formattedCreatedAt = periodicUpdate
    ? formatDistanceToNow(parseISO(createdAt), {
        addSuffix: true,
      })
    : null;

  return (
    <section>
      <h3 className="font-h3 text-byte-dark border-b border-lines pb-5">
        {periodicUpdate?.attributes.subject}
      </h3>
      <div className="pt-6">
        <div className="flex">
          <img src={manager.links.avatar} className="h-6 w-6 rounded-full" />
          <div className="ml-3 w-full">
            <div className="flex flex-wrap justify-between">
              <div>
                <Link
                  to={manager.links.show}
                  className="link font-body-bold text-dark-100"
                >
                  {manager.attributes.fullName}
                </Link>
                {manager.attributes.title && (
                  <p className="font-meta text-dark-75 my-1">
                    {manager.attributes.title}
                  </p>
                )}
              </div>
              <p className="font-body text-dark-75">{formattedCreatedAt}</p>
            </div>
            <h3 className="font-h3 text-primary-dark mt-[19px] mb-2.5">
              {subject}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: highlights,
              }}
              className="text-dark-75"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function UpdateDetail() {
  const { slug: projectSlug, id: updateId } = useParams();
  const { data: updateData }: { data: any } = useQuery(
    ["periodic-updates", updateId],
    fetchPeriodicUpdate,
  );
  const { data: projectData }: { data: any } = useQuery(
    ["periodic-updates-project", projectSlug],
    fetchProject,
  );

  const project = projectData?.data;
  const periodicUpdate = updateData?.data;
  const focusAreas = project?.relationships.focusAreas.data;
  const projectIdentifier = project?.attributes.identifier;
  const truncatedIdentifier =
    projectIdentifier?.length > 8
      ? `${projectIdentifier.slice(0, 8)}...`
      : projectIdentifier;
  const projectCodename = project?.attributes.codename;
  const projectName = project
    ? `${truncatedIdentifier}
      ${projectIdentifier && projectCodename && ": "}
      ${projectCodename}`
    : null;

  return (
    <div className="margin-safe">
      {projectName && (
        <Breadcrumb
          to={`/projects/${projectSlug}`}
          text={`Back to ${projectName}`}
        />
      )}
      <div className="py-7.5 sm:py-10 md:flex">
        <div className="pb-10 md:flex-grow">
          {project && (
            <header className="mb-7.5 md:mb-10">
              <h1 className="font-h2 sm:font-h1 text-dark-100 mb-5">
                {projectName}
              </h1>
              <FocusAreaTags {...{ focusAreas }} />
            </header>
          )}
          {project && periodicUpdate && (
            <UpdateDescription {...{ project, periodicUpdate }} />
          )}
        </div>
        {periodicUpdate && <Aside {...{ periodicUpdate }} />}
      </div>
    </div>
  );
}

export default UpdateDetail;
