import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function CopyLink({
  valueToCopy = "",
  close = () => {},
  layerProps,
  onCopy,
}: {
  valueToCopy: string;
  layerProps: any;
  close: () => any;
  onCopy: (text: string, result: boolean) => any;
}) {
  return (
    <div
      {...layerProps}
      className="border border-dark-25 p-5 bg-white rounded-lg w-11/12 sm:w-439px shadow-md"
    >
      <div className="flex items-start justify-between">
        <p className="font-body-bold leading-none text-dark-100">
          Copy Link to Share
        </p>
        <button
          className=" hover:bg-tint rounded transition-colors px-1 -mt-2 -mr-2 ri-close-line text-xl"
          onClick={() => close()}
        />
      </div>
      <div className="mt-5 flex flex-col lg:flex-row gap-3">
        <input
          className="flex-grow border border-dark-25  py-2 px-4 text-sm rounded-md w-full lg:w-max"
          value={valueToCopy}
          id="input"
          readOnly
        />
        <CopyToClipboard
          onCopy={onCopy}
          text={valueToCopy}
          className="btn-white btn-md"
        >
          <button>Copy</button>
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default CopyLink;
