import * as Yup from "yup";
import {
  arrayToObject,
  convertJsonIntoString,
  convertStringIntoJson,
} from "@/components/pages/admin/ProjectPage/shared/lib";
import { format } from "date-fns";

const INITIAL_VALUES = {
  attributes: {
    name: "",
    identifier: "",
    codename: "",
    subtitle: "",
    readableType: "Initiative",
    description: "",
    focusAreas: [],
    relatedProjects: [],
    centers: [],
    institutions: [],
    manager: [],
    members: [],
    projectMemberships: [],
    projectExternalMemberships: [],
    banner: "",
    bannerMetadata: {
      byte_size: 0,
      filename: "",
      content_type: "",
    },
    bannerUrl: "",
    featured: false,
    salesforceId: "",
    expirationDate: "",
    startDate: "",
    archived: false,
  },
};

const InitiativeSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Project title is required.")
    .max(
      280,
      "The project title entered has exceeded the 280 character limit.",
    ),
  identifier: Yup.string()
    .required("Project ID is required.")
    .max(100, "The project ID entered has exceeded the 100 character limit.")
    .test("regex", "The ID can only contain letters or numbers.", value =>
      /^[\dA-Za-z]*$/.test(value),
    ),
  codename: Yup.string()
    .max(
      100,
      "The project code name entered has exceeded the 100 character limit.",
    )
    .test(
      "regex",
      "The code name can only contain letters or numbers.",
      value => /^[\dA-Za-z]*$/.test(value),
    ),
  subtitle: Yup.string().required("Project aim is required."),
  description: Yup.string().required("Project Description is required."),
  salesforceId: Yup.string()
    .trim()
    .nullable()
    .required("Salesforce Contract ID is required."),
  focusAreas: Yup.array().min(1, "At least one focus area is required."),
  centers: Yup.mixed().when("readableType", {
    is: value => ["Research Study", "Initiative"].includes(value),
    then: Yup.array().test(
      "centers-or-institutions",
      "Center or institution is required.",
      function(value) {
        const { institutions } = this.parent;
        return value.length > 0 || institutions.length > 0;
      },
    ),
  }),
  manager: Yup.array().min(1, "PICI Project Manager is required."),
  expirationDate: Yup.string()
    .nullable()
    .notRequired()
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/im, {
      message: "Date format is MM/DD/YYYY",
      excludeEmptyString: true,
    }),
  startDate: Yup.string()
    .nullable()
    .notRequired()
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/im, {
      message: "Date format is MM/DD/YYYY",
      excludeEmptyString: true,
    }),
});

const mapInitiativeRequest = (values, editing, id) => {
  const focusAreas = values.focusAreas.map(area => Number(area.id));
  const centers = values.centers.map(center => Number(center));
  const institutions = values.institutions.map(institution =>
    typeof institution.id === "string" ? Number(institution.id) : institution,
  );
  const relatedProjects = values.relatedProjects.map(project =>
    Number(project.id),
  );
  const memberships = values.projectMemberships.reduce(
    (filtered, membership) => {
      if (membership.attributes.userId) {
        filtered.push(membership.attributes);
      }
      return filtered;
    },
    [],
  );
  const externalMemberships = values.projectExternalMemberships.reduce(
    (filtered, membership) => {
      if (membership.attributes.userId === undefined) {
        filtered.push({
          ...membership.attributes,
          projectManager: membership.attributes.projectManager,
          projectLead: membership.attributes.projectLead,
        });
      }
      return filtered;
    },
    [],
  );
  const request = {
    data: {
      type: "initiatives",
      attributes: {
        type: "Initiative",
        name: values.name,
        identifier: values.identifier,
        codename: values.codename,
        subtitle: convertJsonIntoString(values.subtitle),
        trialPhase: values.trialPhase,
        trialStatus: values.trialStatus,
        description: convertJsonIntoString(values.description),
        focusAreas: focusAreas,
        centers: centers,
        institutions: institutions,
        projectMemberships: memberships,
        projectExternalMemberships: externalMemberships,
        relatedProject: relatedProjects,
        banner: values.banner,
        featured: values.featured,
        salesforceId: values.salesforceId,
        expirationDate: new Date(values.expirationDate),
        startDate: new Date(values.startDate),
      },
    },
  };
  if (editing) request["data"]["id"] = id;
  return request;
};

const mapResponse = (res, users, externalUsers) => {
  const { included = [], data } = res;
  const { attributes } = data;
  const usersAsObject = arrayToObject(users?.data, "id");
  const externalUsersAsObject = arrayToObject(externalUsers?.data, "id");

  const filteredData = included.reduce(
    (prevValue, current) => {
      switch (current.type) {
        case "focusAreas":
          prevValue["focusAreas"].push(current);
          break;
        case "projectMemberships":
          prevValue["projectMemberships"].push(current);
          break;
        case "projectExternalMemberships":
          prevValue["projectExternalMemberships"].push(current);
          break;
        case "centers":
          prevValue["centers"].push(current.id);
          break;
        case "institutions":
          prevValue["institutions"].push({
            id: current.id,
            name: current.attributes.name,
          });
          break;
        default:
          break;
      }
      return prevValue;
    },
    {
      focusAreas: [],
      projectMemberships: [],
      projectExternalMemberships: [],
      centers: [],
      institutions: [],
    },
  );

  const {
    focusAreas,
    projectMemberships,
    projectExternalMemberships,
    centers,
    institutions,
  } = filteredData;

  const relatedProjects = included
    .filter(({ type }) =>
      ["clinicalTrials", "researchStudies", "initiative"].includes(type),
    )
    .map(project => {
      return {
        id: project.id,
        content: project.attributes.name,
      };
    });

  const managers = projectMemberships
    .filter(({ attributes: { projectManager } }) => projectManager === true)
    .map(manager => {
      const userId = manager.attributes.userId;
      return {
        id: userId,
        attributes: manager.attributes,
        name: usersAsObject[userId].attributes.fullName,
      };
    });

  const selectedMembers = projectMemberships
    .filter(({ attributes: { projectManager } }) => projectManager === false)
    .map(member => {
      const { attributes } = member;
      const { userId } = attributes;
      return {
        attributes: {
          ...attributes,
        },
        name: usersAsObject[userId].attributes.fullName,
        center: usersAsObject[userId].attributes.centerNameOrInstitution,
      };
    });

  const selectedExternalMembers = projectExternalMemberships
    .filter(({ attributes: { projectManager } }) => projectManager === false)
    .map(external => {
      const user = externalUsersAsObject[external.attributes.externalUserId];
      return {
        attributes: {
          ...external.attributes,
        },
        name: user.attributes.name,
        center: user.attributes.center,
        isExternal: true,
      };
    });

  return {
    ...data,
    attributes: {
      name: attributes.name,
      identifier: attributes.identifier,
      codename: attributes.codename,
      subtitle: convertStringIntoJson(attributes.subtitle),
      readableType: "Initiative",
      description: convertStringIntoJson(attributes.description),
      focusAreas: focusAreas,
      relatedProjects: relatedProjects,
      centers: centers,
      institutions: institutions,
      projectMemberships: projectMemberships,
      projectExternalMemberships: projectExternalMemberships,
      manager: managers,
      members: [...selectedMembers, ...selectedExternalMembers],
      banner: attributes.banner,
      bannerMetadata: attributes.bannerMetadata,
      bannerUrl: attributes.bannerUrl,
      featured: attributes.featured,
      salesforceId: attributes.salesforceId,
      expirationDate:
        attributes.expirationDate &&
        format(new Date(attributes.expirationDate), "MM/dd/yyyy"),
      startDate:
        attributes.startDate &&
        format(new Date(attributes.startDate), "MM/dd/yyyy"),
      archived: attributes.archived,
    },
  };
};

export { INITIAL_VALUES, InitiativeSchema, mapInitiativeRequest, mapResponse };
