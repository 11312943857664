import React, { useState, useEffect, useMemo } from "react";
import Filter from "@/components/shared/Filter";
import SelectGroup from "@/components/shared/SelectGroup";
import CheckboxGroup from "@/components/shared/CheckboxGroup";
import FilterToolbar from "@/components/shared/FilterToolbar";
import { useDebounce } from "use-debounce";
import paramBuilder from "@/utils/network/paramBuilder";
import { fetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { FilterOption } from "@/types";

const sortOptions = [
  { name: "Date, Newest First", id: "-createdAt" },
  { name: "Date, Oldest First", id: "createdAt" },
  { name: "Title, A to Z", id: "name" },
  { name: "Title, Z to A", id: "-name" },
];

const compliantOptions = [
  { name: "All Clinical Trials", id: "all" },
  { name: "Is Compliant", id: "true" },
  { name: "Is Not Compliant", id: "false" },
];

const statusOptions = [
  { name: "In Development", id: "in_development" },
  { name: "Pending Activation", id: "pending_activation" },
  { name: "Enrolling Patients", id: "enrolling_patients" },
  { name: "Enrollment Complete", id: "enrollment_complete" },
];

const phasesOptions = [
  { name: "Phase 1", id: "phase_1" },
  { name: "Phase 1B", id: "phase_1b" },
  { name: "Phase 2", id: "phase_2" },
];
const statusArchiveOptions = [
  { name: "Archived", id: "true" },
  { name: "Not Archived", id: "false" },
  { name: "All", id: "all" },
];

const makeDataToOptions = (data, name) => {
  return data?.map(item => {
    return { name: item.attributes[name], id: item.id };
  });
};

function ClinicalTrialsFilter({ total, isLoading, queryFilter, setFilters }) {
  const [titleFilter, setTitleFilter] = useState("");
  const [debouncedTitleFilter] = useDebounce(titleFilter, 500);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedCompliant, setSelectedCompliant] = useState<
    FilterOption | undefined
  >();
  const [selectedCenter, setSelectedCenter] = useState<FilterOption[]>([]);
  const [centerFocus, setCenterFocus] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<FilterOption[]>([]);
  const [statusesFocus, setStatusesFocus] = useState(false);
  const [selectedPhases, setSelectedPhases] = useState<FilterOption[]>([]);
  const [phasesFocus, setPhasesFocus] = useState(false);

  const { data: centersData } = useQuery(["centerFilters"], () =>
    fetcher("/api/centers"),
  );
  const centerOptions = useMemo(
    () => makeDataToOptions(centersData?.data, "shorthand"),
    [centersData],
  );
  useEffect(() => {
    const filters = paramBuilder({
      filter: {
        name: debouncedTitleFilter,
        compliant: selectedCompliant?.id,
        trialStatus: selectedStatuses.map(s => s.id),
        trialPhase: selectedPhases.map(s => s.id),
        centers: selectedCenter.map(s => s.id),
        ...(selectedStatus &&
          selectedStatus.id !== "all" && { archived: selectedStatus.id }),
      },
      sort: [selectedSort?.id],
    });
    if (queryFilter !== filters) {
      setFilters(filters);
    }
  }, [
    setFilters,
    debouncedTitleFilter,
    selectedCompliant,
    selectedStatuses,
    selectedPhases,
    selectedSort,
    selectedCenter,
    selectedStatus,
    queryFilter,
  ]);

  return (
    <FilterToolbar
      total={total}
      filterText={titleFilter}
      setFilterText={setTitleFilter}
      isLoading={isLoading}
      placeHolderText="Find by title, ID or code name"
    >
      <Filter
        name="compliant"
        items={compliantOptions}
        selectedItems={selectedCompliant}
        setSelectedItems={setSelectedCompliant}
        className="flex-1 mr-2"
        closeOnSelect
      >
        <Filter.Label size="sm">
          {selectedCompliant?.name || compliantOptions[0].name}
        </Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={compliantOptions}
            selectedOption={selectedCompliant || null}
            setSelectedOption={setSelectedCompliant}
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="center"
        items={centerOptions}
        selectedItems={selectedCenter}
        setSelectedItems={setSelectedCenter}
        highlight={centerFocus}
        setFocus={setCenterFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">All Centers</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={centerOptions}
            selectedOptions={selectedCenter}
            setSelectedOptions={setSelectedCenter}
            labelClassName="sm:w-325px !py-1"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="status"
        items={statusOptions}
        selectedItems={selectedStatuses}
        setSelectedItems={setSelectedStatuses}
        highlight={statusesFocus}
        setFocus={setStatusesFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">All Statuses</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={statusOptions}
            selectedOptions={selectedStatuses}
            setSelectedOptions={setSelectedStatuses}
            labelClassName="sm:w-325px !py-1"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="phase"
        items={phasesOptions}
        selectedItems={selectedPhases}
        setSelectedItems={setSelectedPhases}
        highlight={phasesFocus}
        setFocus={setPhasesFocus}
        className="flex-1 mr-2"
      >
        <Filter.Label size="sm">All Phases</Filter.Label>
        <Filter.Content>
          <CheckboxGroup
            options={phasesOptions}
            selectedOptions={selectedPhases}
            setSelectedOptions={setSelectedPhases}
            labelClassName="sm:w-325px !py-1"
          />
        </Filter.Content>
      </Filter>
      <Filter
        name="archived"
        items={statusArchiveOptions}
        selectedItems={selectedStatus}
        setSelectedItems={setSelectedStatus}
        closeOnSelect
      >
        <Filter.Label className="!h-[30px] !text-14 !font-light mr-5">
          Visibility
        </Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={statusArchiveOptions}
            selectedOption={selectedStatus}
            setSelectedOption={setSelectedStatus}
          />
        </Filter.Content>
      </Filter>
      <i className="ri-arrow-up-down-line text-xl ml-5 mr-2.5 text-black-placeholder"></i>
      <Filter
        name="sort"
        items={sortOptions}
        selectedItems={selectedSort}
        setSelectedItems={setSelectedSort}
        closeOnSelect
      >
        <Filter.Label size="sm">{selectedSort.name}</Filter.Label>
        <Filter.Content>
          <SelectGroup
            options={sortOptions}
            selectedOption={selectedSort}
            setSelectedOption={setSelectedSort}
          />
        </Filter.Content>
      </Filter>
    </FilterToolbar>
  );
}

export default ClinicalTrialsFilter;
