import * as React from "react";
import { useMemo } from "react";
import { fetcher } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { pluralize } from "@/utils/string";
import Filters from "./Filters";
import { SearchItem } from "./SearchItems";

const categoriesOptions = [
  { name: "Member", id: "members" },
  { name: "Project", id: "projects" },
  { name: "Research Item", id: "research" },
  { name: "Resource", id: "resources" },
  { name: "Invention", id: "inventions" },
  { name: "Daily News Item", id: "daily_news_feed" },
];

function mapFocusAreas(focusAreas) {
  return focusAreas?.data.map(areas => {
    return {
      id: areas.id,
      name: areas.attributes.name,
    };
  });
}

function Skeleton() {
  return (
    <div className="bg-white animate-pulse">
      <div className="bg-dark-25 h-5 w-28" />
      <div className="flex space-x-3 items-center py-5">
        <div className="h-7 w-7 rounded-full bg-dark-25" />
        <div className="bg-dark-25 h-5 w-28" />
      </div>
      <div className="flex space-x-3 items-center pb-8">
        <div className="h-7 w-7 rounded-full bg-dark-25" />
        <div className="bg-dark-25 h-5 w-28" />
      </div>
      <div className="space-y-5">
        <div className="bg-dark-25 h-5 w-28" />
        <div className="bg-dark-25 h-5 w-60 sm:w-120" />
        <div className="bg-dark-25 h-5 w-44 sm:w-80" />
      </div>
      <div className="space-y-5 pt-8">
        <div className="bg-dark-25 h-5 w-28" />
        <div className="bg-dark-25 h-5 w-44 sm:w-80" />
      </div>
    </div>
  );
}

function NoResults() {
  return (
    <div>
      <div className="font-lato text-17 font-bold text-dark-75">
        Suggestions
      </div>
      <ul className="text-sm text-dark-75">
        <li>Make sure all work are spelled correctly</li>
        <li>Try different keywords</li>
        <li>Try more general keywords</li>
      </ul>
    </div>
  );
}

function GlobalSearchContent({ term, urlSearchParams }) {
  const { isLoading, data: searchResults } = useQuery(
    ["all_results_search", urlSearchParams.plainQuery],
    () => fetcher(`/api/search?${urlSearchParams.plainQuery}`),
  );
  const { isLoading: isLoadingFocusAreas, data: focusAreas } = useQuery(
    ["focusAreas"],
    () => fetcher("/api/focus-areas?fields[focus-areas]=name"),
  );

  const thereAreResults =
    searchResults && Object.keys(searchResults.data).length > 0;

  const focusAreaOptions = useMemo(() => mapFocusAreas(focusAreas), [
    focusAreas,
  ]);

  return (
    <div className="w-full max-w-834px mx-auto px-4 sm:px-8 xl:px-12 pt-10 pb-4 sm:pb-8 xl:pb-12">
      <h1 className="font-h1">Search results for "{term}"</h1>
      <Filters focusAreaOptions={focusAreaOptions} />
      {!isLoading && !isLoadingFocusAreas ? (
        !thereAreResults ? (
          <NoResults />
        ) : (
          <div className="space-y-15 pb-8">
            {categoriesOptions.map(category => {
              const matches = searchResults.data[category.id];
              if (matches) {
                const count = matches.data.length;
                return (
                  <div key={category.id}>
                    <div className="font-h3 border-b border-dark-25 pb-5 mb-6">{`${count} ${pluralize(
                      count,
                      category.name,
                    )}`}</div>
                    <div className="space-y-7">
                      {matches.data.map((item, i) => (
                        <SearchItem
                          key={i}
                          item={item}
                          type={category.id}
                          focusAreas={focusAreas.data}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )
      ) : (
        <Skeleton />
      )}
    </div>
  );
}

export default GlobalSearchContent;
